import React, { useEffect, useRef, useState } from 'react'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
interface Props {
    node: Node
    color: string
    openNodeFunction: (node: Node, color: string) => Promise<any>
}

export interface Node {
    Id?: number
    IdHierarquiaPai?: number
    Status?: number
    Nome?: string
    IdEmpresa?: number | null
    IdRegiao?: number | null
    IdFilial?: number | null
    IdSetor?: number | null
    color?: string
    children?: Array<Node>
}

export const TreeNode = ({ node, color, openNodeFunction }: Props) => {
    const [show, setShow] = useState(false)
    const hasChild = Boolean(node?.children?.length)
    const [dotColor] = useState(() => {
        return `#${Math.floor(Math.random() * 16777215).toString(16)}`
    })

    return (
        <li className="d-tree-node border-0">
            <div
                className="d-flex cursor-pointer"
                style={{ margin: '0.3rem 0' }}
                id="btn-first"
                onClick={async e => {
                    openNodeFunction(node, color).then(res => {
                        node.children = res
                        setShow(v => !v)
                    })
                }}
            >
                {hasChild && (
                    <div
                        className={`d-inline d-tree-toggler ${
                            show ? 'active' : ''
                        }`}
                    ></div>
                )}
                <FontAwesomeIcon icon={faCircle} color={color} />
                <div
                    className="col d-tree-head"
                    style={{
                        marginLeft: '10px',
                        textAlign: 'left',
                        fontWeight: show ? 600 : 'normal'
                    }}
                >
                    <p>{node.Nome}</p>
                </div>
            </div>
            {hasChild && show && (
                <div className="d-tree-content">
                    <ul className="d-flex d-tree-container flex-column">
                        {node?.children?.map((tree: Node, index: number) => (
                            <TreeNode
                                key={index}
                                node={tree}
                                color={dotColor}
                                openNodeFunction={openNodeFunction}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </li>
    )
}

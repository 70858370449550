import React, { useEffect, useState, useRef, useContext } from 'react'
import Switch from 'react-switch'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Flex } from '../../../core/components/Flex'
import { Header } from '../../../core/components/Header/Header'
import { Empty } from '../../../core/components/Empty'
import styled from 'styled-components'
import { PoliticaViagemProp } from '../../../contexts/interfaces/politicaviagem'
import { Button } from '../../../core/components/Button'
import './politica.css'
import { get, patch, post, put } from '../../../services/http'
import { ENDPOINTS } from '../../../services/config-http'
import { ModalPoliticas } from './ModalPoliticas'
import { useHistory } from 'react-router-dom'
import { Toast } from '../../../core/components/Toast'
import { ContainerPadding } from '../../../core/components/ContainerPadding'
import { UserContext } from '../../../contexts/UserContext'
import { Alert } from '../../../core/components/Alert'
// import { Modal } from '../../../core/components/Modal/Modal'
// import { Form } from '@unform/web'
// import { Input } from '../../../core/components/Form/Input'
// import { FormHandles, SubmitHandler } from '@unform/core'
// import * as Yup from 'yup'
// import { MapFormErrors } from '../../../core/utils/MapFormErrors'

export const Politicas = () => {
    const [politicas, setPoliticas] = useState<PoliticaViagemProp[]>([])
    const [showModal, setShowModal] = useState(false)
    const history = useHistory()
    const [id, setId] = useState(0)
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    const [administrador, setAdministrador] = useState(false)
    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        get(ENDPOINTS.politicaViagem).then(({ data: { Model } }) => {
            setPoliticas(Model)
        })
    }, [])

    const handlePoliticaStatus = async (index: number) => {
        await patch(ENDPOINTS.politicaViagem, {
            Id: politicas[index].Id,
            Status: !politicas[index].Status
        })
            .then(data => {
                const newPoliticas = [...politicas]
                newPoliticas[index].Status = !newPoliticas[index].Status
                setPoliticas(newPoliticas)
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    message: err,
                    type: 'error'
                })
            })
    }

    const handlePoliticaPadrao = async (index: number) => {
        await patch(ENDPOINTS.politicaViagem, {
            Id: politicas[index].Id,
            PolicitaViagemPadrao: true
        })
            .then(data => {
                const newPoliticas = [...politicas]
                newPoliticas.forEach(p => {
                    p.PoliticaViagemPadrao = false
                })
                newPoliticas[index].PoliticaViagemPadrao = true
                setPoliticas(newPoliticas)
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    message: err,
                    type: 'error'
                })
            })
    }

    return (
        <>
            <BlankBoard>
                <Header
                    title="Políticas de viagem"
                    actionArrowClick="/solicitacoes"
                ></Header>
                <ContainerPadding>
                    {administrador ? (
                        politicas.length > 0 ? (
                            <>
                                <Flex justifyContent="flex-end">
                                    <Button
                                        icon="plus"
                                        colorType="quaternary"
                                        onClick={() => {
                                            setShowModal(true)
                                            setId(0)
                                        }}
                                    >
                                        Nova política
                                    </Button>
                                </Flex>
                                <div
                                    className="row"
                                    style={{ marginTop: '3rem' }}
                                >
                                    <div className="col-12">
                                        <label htmlFor=""></label>
                                        <div
                                            className="table-responsive"
                                            style={{
                                                border: 'solid 1px lightgray',
                                                borderRadius: '15px'
                                            }}
                                        >
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            style={{
                                                                width: '12rem',
                                                                textAlign:
                                                                    'left',
                                                                borderBottom:
                                                                    'solid 1px lightgray',
                                                                verticalAlign:
                                                                    'middle'
                                                            }}
                                                        >
                                                            <TextTitle>
                                                                Nome
                                                            </TextTitle>
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '12rem',
                                                                textAlign:
                                                                    'left',
                                                                borderBottom:
                                                                    'solid 1px lightgray',
                                                                verticalAlign:
                                                                    'middle'
                                                            }}
                                                        >
                                                            <TextTitle>
                                                                Descrição
                                                            </TextTitle>
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '5rem',
                                                                textAlign:
                                                                    'center',
                                                                borderBottom:
                                                                    'solid 1px lightgray',
                                                                verticalAlign:
                                                                    'middle'
                                                            }}
                                                        >
                                                            <TextTitle>
                                                                Situação
                                                            </TextTitle>
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '5rem',
                                                                borderBottom:
                                                                    'solid 1px lightgray',
                                                                verticalAlign:
                                                                    'middle'
                                                            }}
                                                        ></th>
                                                        <th
                                                            style={{
                                                                width: '2rem',
                                                                borderBottom:
                                                                    'solid 1px lightgray'
                                                            }}
                                                        ></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {politicas.map(
                                                        (
                                                            politica: PoliticaViagemProp,
                                                            index: number
                                                        ) => {
                                                            return (
                                                                <>
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <td
                                                                            style={{
                                                                                width: '12rem',
                                                                                marginLeft:
                                                                                    '1rem',
                                                                                textAlign:
                                                                                    'left',
                                                                                verticalAlign:
                                                                                    'middle'
                                                                            }}
                                                                        >
                                                                            <Text>
                                                                                {
                                                                                    politica.Nome
                                                                                }
                                                                            </Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '12rem',
                                                                                textAlign:
                                                                                    'left',
                                                                                verticalAlign:
                                                                                    'middle'
                                                                            }}
                                                                        >
                                                                            <Text>
                                                                                {
                                                                                    politica.Descricao
                                                                                }
                                                                            </Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '5rem',
                                                                                textAlign:
                                                                                    'center',
                                                                                verticalAlign:
                                                                                    'middle'
                                                                            }}
                                                                        >
                                                                            <Text>
                                                                                {politica.Status
                                                                                    ? 'Ativo'
                                                                                    : 'Inativo'}
                                                                            </Text>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '5rem',
                                                                                verticalAlign:
                                                                                    'middle'
                                                                            }}
                                                                        >
                                                                            {politica.PoliticaViagemPadrao ? (
                                                                                <PolicitaViagemPadraoInfo>
                                                                                    Política
                                                                                    padrão
                                                                                </PolicitaViagemPadraoInfo>
                                                                            ) : null}
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '2rem',
                                                                                verticalAlign:
                                                                                    'middle'
                                                                            }}
                                                                        >
                                                                            <button
                                                                                className="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={`#flush-collapse${index}`}
                                                                                aria-expanded="false"
                                                                                aria-controls={`flush-collapse${index}`}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <div
                                                                        id={`flush-collapse${index}`}
                                                                        className="accordion-collapse collapse"
                                                                        aria-labelledby="flush-headingOne"
                                                                        data-bs-parent="#accordionFlushExample"
                                                                    >
                                                                        <tr
                                                                            style={{
                                                                                height: '3rem',
                                                                                display:
                                                                                    'flex',
                                                                                borderBottom:
                                                                                    'solid 1px lightgray'
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    width: '69%',
                                                                                    display:
                                                                                        'flex',
                                                                                    justifyContent:
                                                                                        'flex-end',
                                                                                    alignItems:
                                                                                        'center'
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            'flex',
                                                                                        alignItems:
                                                                                            'center',
                                                                                        marginRight:
                                                                                            '.5rem'
                                                                                    }}
                                                                                >
                                                                                    <Switch
                                                                                        onChange={e =>
                                                                                            handlePoliticaStatus(
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        checked={
                                                                                            politicas[
                                                                                                index
                                                                                            ]
                                                                                                .Status
                                                                                        }
                                                                                        onColor="#24538B"
                                                                                        checkedIcon={
                                                                                            false
                                                                                        }
                                                                                        uncheckedIcon={
                                                                                            false
                                                                                        }
                                                                                    />
                                                                                    <div
                                                                                        style={{
                                                                                            marginLeft:
                                                                                                '.2rem'
                                                                                        }}
                                                                                    >
                                                                                        <Text>
                                                                                            Política
                                                                                            ativa
                                                                                        </Text>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            'flex',
                                                                                        alignItems:
                                                                                            'center',
                                                                                        marginRight:
                                                                                            '.5rem'
                                                                                    }}
                                                                                >
                                                                                    {politica.PoliticaViagemPadrao ? (
                                                                                        <div
                                                                                            style={{
                                                                                                width: '12.5rem'
                                                                                            }}
                                                                                        ></div>
                                                                                    ) : (
                                                                                        <PolicitaViagemNormal
                                                                                            type="button"
                                                                                            onClick={e =>
                                                                                                handlePoliticaPadrao(
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Definir
                                                                                            como
                                                                                            Padrão
                                                                                        </PolicitaViagemNormal>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    width: '1%',
                                                                                    margin: '.5rem 0',
                                                                                    borderRight:
                                                                                        '1px solid #BDBDBD'
                                                                                }}
                                                                            ></div>
                                                                            <div
                                                                                style={{
                                                                                    width: '30%',
                                                                                    display:
                                                                                        'flex',
                                                                                    justifyContent:
                                                                                        'space-around'
                                                                                }}
                                                                            >
                                                                                <button
                                                                                    className="btn-politicas"
                                                                                    onClick={e =>
                                                                                        history.push(
                                                                                            `/solicitacoes/configuracao/permissao/${politica.Id}`
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Gerenciar
                                                                                    Permissões
                                                                                </button>
                                                                                <button
                                                                                    className="btn-politicas"
                                                                                    onClick={e => {
                                                                                        setShowModal(
                                                                                            true
                                                                                        )
                                                                                        setId(
                                                                                            politica.Id
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    Alterar
                                                                                    Política
                                                                                </button>
                                                                            </div>
                                                                        </tr>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Empty text="Nenhuma política adicionada" />
                        )
                    ) : (
                        <Alert
                            fixed={true}
                            icon="exclamation-circle"
                            type="primary"
                            message="Você não tem permissão de administrador"
                        ></Alert>
                    )}
                </ContainerPadding>
                {showModal ? (
                    <ModalPoliticas
                        id={id}
                        show={showModal}
                        setShow={setShowModal}
                    />
                ) : null}
            </BlankBoard>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </>
    )
}

const PolicitaViagemPadraoInfo = styled.div`
    background-color: white;
    padding: 0.8vh;
    color: #10478a;
    font-size: 10pt;
    border-radius: 1.6vh;
    border: none;
    width: 15vh;
    font-weight: 600;
`

const PolicitaViagemNormal = styled.button`
    padding: 0.8vh;
    color: #fff;
    background-color: #c5dbff;
    font-size: 8pt;
    border-radius: 0.7vh;
    border: none;
    width: 15vh;
    margin: 0 2vh !important;
`

const Text = styled.p``

const TextTitle = styled.p`    
font-size: 0.9rem;
font-weight: 800;
color: '#6C7480',
font: 'Montserrat, sas-serif'
`

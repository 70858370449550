export const formataValor = (objeto: any) => {
    try {
        if (typeof objeto === 'number') return objeto
        if (objeto) {
            return parseFloat(
                objeto
                    .replaceAll('.', '')
                    .replaceAll(',', '.')
                    .replaceAll('R$', '') || '0'
            )
        }
        return 0
    } catch (err) {
        console.log(err)
        return 0
    }
}

import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Center } from '../../core/components/Center'
import { Header } from '../../core/components/Header/Header'
import { ENDPOINTS } from '../../services/config-http'
import { Button } from '../../core/components/Button'
import { post } from '../../services/http'
import { CotacaoHospedagem } from './Hospedagem/CotacaoHospedagem'
import { CotacaoAereo } from './Aereo/CotacaoAereo'
import { CotacaoServico } from './Servico/CotacaoServico'
import { Toast } from '../../core/components/Toast'
import { BlankBoard } from '../../core/components/BlankBoard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSpinner } from '../../hooks/Spinner'

export const CotacaoViagem = () => {
    const history = useHistory()
    const { setLoading } = useSpinner()
    const { Id } = useParams() as any
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    const finish = async () => {
        await post(`${ENDPOINTS.cotarSolicitarViagem.replace(':Id', Id)}`)
            .then(data => {
                setShowToast(true)
                setToast({
                    message: 'Solicitação cotada com sucesso',
                    type: 'success'
                })
                setTimeout(() => {
                    history.push('/listagem-cotacao')
                }, 500)
            })
            .catch(err => {
                const { data } = err
                setShowToast(true)
                setToast({
                    message: data,
                    type: 'error'
                })
                setLoading(false)
                return err
            })
    }

    return (
        <BlankBoard>
            <Header
                title="Cotação"
                actionArrowClick={() => history.push('/listagem-cotacao')}
            ></Header>
            <div style={{ marginTop: '3rem' }}>
                <ul
                    className="nav nav-tabs"
                    id="myTab"
                    role="tablist"
                    style={{ margin: '0vh 5vh' }}
                >
                    <li
                        className="nav-item"
                        style={navItem}
                        role="presentation"
                    >
                        <button
                            className="nav-link active"
                            id="servico-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#servico"
                            type="button"
                            role="tab"
                            aria-controls="servico"
                            aria-selected="true"
                        >
                            <FontAwesomeIcon icon="wrench" />
                        </button>
                    </li>
                    <li
                        className="nav-item"
                        style={navItem}
                        role="presentation"
                    >
                        <button
                            className="nav-link"
                            id="aereo-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#aereo"
                            type="button"
                            role="tab"
                            aria-controls="aereo"
                            aria-selected="true"
                        >
                            <FontAwesomeIcon icon="plane" />
                        </button>
                    </li>
                    <li
                        className="nav-item"
                        style={navItem}
                        role="presentation"
                    >
                        <button
                            className="nav-link"
                            id="hospedagem-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#hospedagem"
                            type="button"
                            role="tab"
                            aria-controls="hospedagem"
                            aria-selected="true"
                        >
                            <FontAwesomeIcon icon="hotel" />
                        </button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div
                        className="tab-pane fade show active"
                        id="servico"
                        role="tabpanel"
                        aria-labelledby="servico-tab"
                    >
                        <CotacaoServico />
                    </div>
                    <div
                        className="tab-pane fade show"
                        id="aereo"
                        role="tabpanel"
                        aria-labelledby="aereo-tab"
                    >
                        <CotacaoAereo />
                    </div>
                    <div
                        className="tab-pane fade show"
                        id="hospedagem"
                        role="tabpanel"
                        aria-labelledby="hospedagem-tab"
                    >
                        <CotacaoHospedagem />
                    </div>
                </div>
            </div>
            <Center>
                <div
                    style={{
                        fontSize: '12pt',
                        display: 'flex',
                        color: '#5787D0'
                    }}
                >
                    <FontAwesomeIcon
                        icon="info"
                        style={{ marginRight: '1rem' }}
                    />
                    <p>Ao clicar em salvar todas as cotações serão salvas</p>
                </div>
            </Center>
            <Center>
                <Button
                    colorType="quaternary"
                    type="submit"
                    onClick={() => finish()}
                >
                    Salvar
                </Button>
            </Center>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </BlankBoard>
    )
}

const navItem = {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2.2vh'
}

import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react'
import axios from 'axios'
import { decode } from 'jsonwebtoken'
import { apiClient, post } from '../services/http'
import { ENDPOINTS } from '../services/config-http'

type User = {
    nome: string
    codigo: string
    id: string
    avatar?: string
    role: string
}

type AuthContextData = {
    signOut(): void
    user: User
    signIn(token: string, refresh: string): void
    handleRefresh(): Promise<void>
    time: {
        minutes: number
        seconds: number
    }
}

type AuthState = {
    token: string
    user: User
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: React.FC = ({ children }) => {
    let validacaoEntrada = true
    const [time, setTime] = useState({
        minutes: 9,
        seconds: 59
    })
    const getValidityToken = useCallback((token: string) => {
        if (token) {
            const { exp } = decode(token) as { exp: number }
            const dataAtual = new Date().getTime() / 1000
            const validadeToken = exp - dataAtual - 15
            return validadeToken
        }
        return 0
    }, [])

    const calculateTimeLeft = useCallback((validityToken: number) => {
        if (validityToken > 0) {
            let timeLeft = {
                minutes: 0,
                seconds: 0
            }
            timeLeft = {
                minutes: Math.floor(validityToken / 60),
                seconds: Math.floor(validityToken % 60)
            }
            return timeLeft
        }
        return { minutes: 0, seconds: 0 }
    }, [])

    const [data, setData] = useState<AuthState>(() => {
        const token = localStorage.getItem('@ViagensCorp:token')
        const user = localStorage.getItem('@ViagensCorp:user')

        if (token && user) {
            apiClient.defaults.headers.Authorization = `Bearer ${token}`
            return {
                token,
                user: JSON.parse(user)
            }
        }

        return {} as AuthState
    })

    const signOut = useCallback(() => {
        localStorage.removeItem('@ViagensCorp:token')
        localStorage.removeItem('@ViagensCorp:refreshToken')
        localStorage.removeItem('@ViagensCorp:user')

        setData({} as AuthState)
    }, [])

    const countdown = useCallback(() => {
        if (time.seconds > 0) {
            setTime({
                minutes: time.minutes,
                seconds: (time.seconds -= 1)
            })
        }

        if (time.minutes >= 1 && time.seconds === 0) {
            setTime({
                minutes: (time.minutes -= 1),
                seconds: (time.seconds = 59)
            })
        }
    }, [time])

    useEffect(() => {
        if (data.token) {
            const validity = getValidityToken(data.token)
            setTime(calculateTimeLeft(validity))
        }
    }, [calculateTimeLeft, getValidityToken, data])

    useEffect(() => {
        const timer = setTimeout(() => {
            countdown()
        }, 1000)

        return () => clearTimeout(timer)
    }, [countdown])

    const updateToken = useCallback(
        (token, refreshToken) => {
            if (token && refreshToken) {
                getValidityToken(token)
                apiClient.defaults.headers = {
                    Authorization: `Bearer ${token}`
                }
                localStorage.setItem('@ViagensCorp:token', token)
                localStorage.setItem('@ViagensCorp:refreshToken', refreshToken)
            }
            if (validacaoEntrada) {
                post(ENDPOINTS.autenticarUsuario)
                    .then(data => null)
                    .catch(err => console.error(err))
                // eslint-disable-next-line react-hooks/exhaustive-deps
                validacaoEntrada = false
            }
        },
        [getValidityToken]
    )

    const handleRefresh = useCallback(async () => {
        const refreshToken = localStorage.getItem('@ViagensCorp:refreshToken')
        const token = localStorage.getItem('@ViagensCorp:token')

        if (token && refreshToken) {
            const validity = getValidityToken(token)
            const timeLeft = calculateTimeLeft(validity)
            if (timeLeft.minutes <= 5) {
                const bodyReq = `grant_type=refresh_token&refresh_token=${refreshToken}`
                return axios
                    .post(`${process.env.REACT_APP_AUTH}oauth/token`, bodyReq, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                    .then(req => {
                        // eslint-disable-next-line camelcase
                        const { access_token, refresh_token } = req.data
                        const validity = getValidityToken(access_token)
                        setTime(calculateTimeLeft(validity))
                        localStorage.setItem('@ViagensCorp:token', access_token)
                        localStorage.setItem(
                            '@ViagensCorp:refreshToken',
                            refresh_token
                        )
                        updateToken(access_token, refresh_token)
                        return Promise.resolve()
                    })
                    .catch(() => {
                        signOut()
                    })
            }
        }
        return Promise.resolve()
    }, [calculateTimeLeft, updateToken, getValidityToken, signOut])

    const signIn = useCallback(
        (token: string, refresh: string) => {
            const { nom, sub, id, role } = decode(token) as {
                nom: string
                sub: string
                id: string
                role: string
            }

            const user = {
                nome: nom.trim(),
                codigo: sub.trim(),
                id,
                role
            }

            updateToken(token, refresh)
            localStorage.setItem('@ViagensCorp:user', JSON.stringify(user))

            setData({ token, user })
        },
        [updateToken]
    )

    return (
        <AuthContext.Provider
            value={{
                signOut,
                user: data.user,
                signIn,
                time,
                handleRefresh
            }}
        >
            {/* <RefreshAlert /> */}
            {children}
        </AuthContext.Provider>
    )
}

function useAuth(): AuthContextData {
    const context = useContext(AuthContext)

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }

    return context
}

export { AuthProvider, useAuth }

import { FormHandles, SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ENDPOINTS } from '../../../services/config-http'
import { get, post, put } from '../../../services/http'
import { SelectMapper } from '../../../core/utils/SelectMapper'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Header } from '../../../core/components/Header/Header'
import { Center } from '../../../core/components/Center'
import { Subtitle } from '../../../core/components/Subtitle'
import { Alert } from '../../../core/components/Alert'
import { Input } from '../../../core/components/Form/Input'
import { Modal } from '../../../core/components/Modal'
import { Select } from '../../../core/components/Form/Select'
import { Switch } from '../../../core/components/Switch'
import { Space } from '../../../core/components/Space'
import { Button } from '../../../core/components/Button'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { SelectRequester } from '../../../core/components/Form/SelectWithRequest'
import { Toast } from '../../../core/components/Toast'
import { UserContext } from '../../../contexts/UserContext'
import { ListaRegiao } from '../Regiao/ListaRegiao'
import { DisableForMobile, IsMobile } from '../../Viagem/Solicitacao/stepMobile'

export const FilialForm = () => {
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })
    const { Id } = useParams() as any // parâmetro da url
    const [Filial, setFilial] = useState({ status: false })
    const [idEmpresa, setIdEmpresa] = useState(0)
    const [idRegiao, setIdRegiao] = useState(0)
    const [listRegiao, setListRegiao] = useState<Array<any>>([])
    const [listaEmpresa, setListaEmpresa] = useState<Array<any>>([])

    const [ModalConfig, setModalConfig] = useState({
        status: false,
        title: 'Pronto!',
        text: ''
    })

    const [ShowAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })
    const [administrador, setAdministrador] = useState(false)
    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        // caso for para edição
        if (Id) {
            getFilial()
        }
        get(`${ENDPOINTS.regiao}`).then(res => {
            setListRegiao(
                SelectMapper(res?.data?.Items, 'Codigo', 'Id', 'Nome')
            )
        })

        get(`${ENDPOINTS.pesquisarEmpresa}`).then(res => {
            setListaEmpresa(
                SelectMapper(res?.data?.Items, 'Codigo', 'Id', 'Nome')
            )
        })
    }, [])

    useEffect(() => {
        console.log(listRegiao)
    }, [listRegiao])

    function getFilial() {
        return get(`${ENDPOINTS.getFilialById}${Id}`)
            .then(({ data: { Model } }) => {
                formRef.current?.setData(Model)
                setFilial({ status: Model.Status })
                setIdRegiao(Model?.IdRegiao)
                setIdEmpresa(Model?.IdEmpresa)
            })
            .catch(err => {
                setShowAlert({
                    status: true,
                    message: `${err.status} - Erro ao buscar os dados da Filial`,
                    type: 'danger'
                })
            })
    }

    const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
        try {
            let schema
            if (Id) {
                schema = Yup.object().shape({
                    Nome: Yup.string().required('O Campo Nome é obrigatório'),
                    Codigo: Yup.string().required(
                        'O Campo Código é obrigatório'
                    )
                })
            } else {
                schema = Yup.object().shape({
                    Nome: Yup.string().required('O Campo Nome é obrigatório'),
                    Codigo: Yup.string().required(
                        'O Campo Código é obrigatório'
                    ),
                    IdEmpresa: Yup.string().required(
                        'O Campo Empresa é obrigatório'
                    ),
                    IdRegiao: Yup.string().required(
                        'O Campo Regiao é obrigatório'
                    )
                })
            }
            await schema.validate(data, { abortEarly: false })

            formRef.current?.setErrors({})
            if (Id) {
                put(ENDPOINTS.filial, {
                    id: Id,
                    codigo: data?.Codigo,
                    nome: data.Nome,
                    status: Filial.status ? 1 : 0,
                    idEmpresa: data?.IdEmpresa ? data?.IdEmpresa : idEmpresa,
                    idRegiao: data?.IdRegiao ? data?.IdRegiao : idRegiao
                })
                    .then(() => {
                        setShowToast(true)
                        setToast({
                            message: 'Filial alterada!',
                            type: 'success'
                        })
                        history.push(`/cadastros/filial`)
                    })
                    .catch(({ data }) => {
                        setShowToast(true)
                        setToast({
                            message: 'Erro ao tentar alterar dados filial',
                            type: 'error'
                        })
                    })
            } else {
                post(ENDPOINTS.filial, {
                    codigo: data?.Codigo,
                    nome: data.Nome,
                    status: Filial.status ? 1 : 0,
                    idEmpresa: data?.IdEmpresa ? data?.IdEmpresa : idEmpresa,
                    idRegiao: data?.IdRegiao ? data?.IdRegiao : idRegiao
                })
                    .then(() => {
                        setShowToast(true)
                        setToast({
                            message: 'Filial cadastrada!',
                            type: 'success'
                        })
                        history.push(`/cadastros/filial`)
                    })
                    .catch(({ data }) => {
                        setShowToast(true)
                        setToast({
                            message: 'Erro ao tentar cadastrar nova filial',
                            type: 'error'
                        })
                    })
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                formRef.current?.setErrors(erros)
            }
        }
    }
    return (
        <BlankBoard>
            <Header
                title={Id ? 'Alterar Filial' : 'Cadastar Filial'}
                actionArrowClick="/cadastros/filial"
            ></Header>
            <Center>
                <Subtitle>Dados da Filial</Subtitle>
            </Center>
            <Alert
                message={ShowAlert.message}
                status={ShowAlert.status}
                type={ShowAlert.type as any}
            ></Alert>
            {administrador ? (
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col12 col-sm-4">
                            <Input
                                name="Codigo"
                                label="Código"
                                required={Id}
                                disabled={Id}
                                placeholder="Digite o código"
                            ></Input>
                        </div>
                        <div className="col12 col-sm-8">
                            <Input
                                name="Nome"
                                label="Nome"
                                required={true}
                                placeholder="Digite o nome"
                            ></Input>
                        </div>
                        <div className="col12 col-sm-6">
                            {/* <SelectRequester
                                required={true}
                                items={true}
                                request={{
                                    endpoint: ENDPOINTS.pesquisarEmpresa,
                                    labelToSelect: 'Nome',
                                    valueToSelect: 'Id'
                                }}
                                name="IdEmpresa"
                                label="Empresa"
                            ></SelectRequester> */}

                            <Select
                                required={true}
                                options={listaEmpresa}
                                name="IdEmpresa"
                                label="Empresa"
                                placeholder="Selecione a Regiao"
                                value={listaEmpresa[idEmpresa - 1]}
                                onChange={e => setIdEmpresa(e)}
                                defaultOption={listaEmpresa[idEmpresa - 1]}
                            ></Select>
                        </div>
                        <div className="col12 col-sm-6">
                            <Select
                                required={true}
                                options={listRegiao}
                                name="IdRegiao"
                                label="Regiao"
                                placeholder="Selecione a Regiao"
                                value={listRegiao[idRegiao - 1]}
                                onChange={e => setIdRegiao(e)}
                                defaultOption={listRegiao[idRegiao - 1]}
                            ></Select>
                        </div>
                        <div className="col-12">
                            <Switch
                                required={true}
                                defaultChecked={Filial.status}
                                checked={Filial.status}
                                onChange={() => {
                                    if (Filial.status) {
                                        setFilial({ status: false })
                                    } else {
                                        setFilial({ status: true })
                                    }
                                }}
                                name="Status"
                                label="Status"
                            ></Switch>
                        </div>
                        <Space height="3rem"></Space>
                        <Center>
                            <DisableForMobile>
                                <Button
                                    type={'submit'}
                                    colorType="quaternary"
                                    icon={Id ? 'save' : 'plus'}
                                >
                                    {Id ? 'Salvar' : 'Cadastrar'}
                                </Button>
                            </DisableForMobile>
                            <IsMobile>
                                <Button
                                    type={'submit'}
                                    colorType="btnMobile"
                                    icon={Id ? 'save' : 'plus'}
                                >
                                    {Id ? 'Salvar' : 'Cadastrar'}
                                </Button>
                            </IsMobile>
                        </Center>
                    </div>
                </Form>
            ) : (
                <>
                    <br />
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                </>
            )}
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </BlankBoard>
    )
}

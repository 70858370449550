import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TipoSolicitacaoProp } from '../../../contexts/interfaces/tipoSolicitacao'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button'
import { Empty } from '../../../core/components/Empty'
import { Header } from '../../../core/components/Header/Header'
import { ModalTipoSolicitacao } from './ModalTipoSolicitacao'
import { ENDPOINTS } from '../../../services/config-http'
import Switch from 'react-switch'
import { ModalConfirmacao } from './ModalTipoSolicitacao/modalConfirmacao'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { get } from '../../../services/http'
import { Alert } from '../../../core/components/Alert'
import { UserContext } from '../../../contexts/UserContext'

export const TipoSolicitacao = () => {
    const history = useHistory()
    const [modalTipoSolicitacao, setModalTipoSolicitacao] = useState({
        tipo: 0,
        idTipoSolicitacao: 0,
        nomeTipoSolicitacao: '',
        statusTipoSolicitacao: false
    })
    const [showModalTipoSolicitacao, setShowModalTipoSolicitacao] =
        useState(false)
    const [showModalConfirmacao, setShowModalConfirmacao] = useState(false)
    const [inativarTipo, setInativarTipo] = useState({
        Id: 0,
        Nome: '',
        Status: false
    })
    const [administrador, setAdministrador] = useState(false)

    const [tiposSolicitacao, setTiposSolicitacao] = useState<
        TipoSolicitacaoProp[]
    >([])

    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        get(ENDPOINTS.tipoSolicitacao)
            .then(({ data: { Model } }) => {
                setTiposSolicitacao(Model)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <>
            <BlankBoard>
                <Header
                    title="Tipos de solicitação"
                    actionArrowClick="/solicitacoes/"
                ></Header>
                {administrador && tiposSolicitacao.length > 0 ? (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '1rem'
                            }}
                        >
                            <Button
                                type="submit"
                                colorType="quintiary"
                                icon="plus"
                                onClick={() => {
                                    setModalTipoSolicitacao({
                                        tipo: 1,
                                        idTipoSolicitacao: 0,
                                        nomeTipoSolicitacao: '',
                                        statusTipoSolicitacao: true
                                    })
                                    setShowModalTipoSolicitacao(true)
                                }}
                            >
                                Novo Tipo Solicitação
                            </Button>
                        </div>
                        <div className="row" style={{ marginTop: '3rem' }}>
                            <div className="col-12">
                                <label htmlFor=""></label>
                                <div
                                    className="table-responsive"
                                    style={{
                                        border: 'solid 1px lightgray',
                                        borderRadius: '15px'
                                    }}
                                >
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th
                                                    style={{
                                                        width: '10rem',
                                                        paddingLeft: '4rem',
                                                        textAlign: 'left',
                                                        verticalAlign: 'middle',
                                                        borderBottom:
                                                            'solid 1px lightgray'
                                                    }}
                                                >
                                                    Id
                                                </th>
                                                <th
                                                    style={{
                                                        width: '10rem',
                                                        textAlign: 'left',
                                                        verticalAlign: 'middle',
                                                        borderBottom:
                                                            'solid 1px lightgray'
                                                    }}
                                                >
                                                    Nome
                                                </th>
                                                <th
                                                    style={{
                                                        width: '5rem',
                                                        textAlign: 'center',
                                                        verticalAlign: 'middle',
                                                        borderBottom:
                                                            'solid 1px lightgray'
                                                    }}
                                                >
                                                    Status
                                                </th>
                                                <th
                                                    style={{
                                                        width: '5rem',
                                                        textAlign: 'center',
                                                        verticalAlign: 'middle',
                                                        borderBottom:
                                                            'solid 1px lightgray'
                                                    }}
                                                >
                                                    Alterar
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tiposSolicitacao.map(
                                                (tipo, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td
                                                                style={{
                                                                    width: '10rem',
                                                                    paddingLeft:
                                                                        '4rem',
                                                                    marginLeft:
                                                                        '1rem',
                                                                    verticalAlign:
                                                                        'middle',
                                                                    textAlign:
                                                                        'left'
                                                                }}
                                                            >
                                                                {tipo.Id}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '10rem',
                                                                    marginLeft:
                                                                        '1rem',
                                                                    verticalAlign:
                                                                        'middle',
                                                                    textAlign:
                                                                        'left'
                                                                }}
                                                            >
                                                                {tipo.Nome}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '5rem',
                                                                    marginLeft:
                                                                        '1rem',
                                                                    verticalAlign:
                                                                        'middle',
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                <Switch
                                                                    onColor="#24538B"
                                                                    checkedIcon={
                                                                        false
                                                                    }
                                                                    uncheckedIcon={
                                                                        false
                                                                    }
                                                                    checked={
                                                                        tipo.Status
                                                                    }
                                                                    onChange={() => {
                                                                        setShowModalConfirmacao(
                                                                            true
                                                                        )
                                                                        setInativarTipo(
                                                                            {
                                                                                Id: tipo.Id,
                                                                                Nome: tipo.Nome,
                                                                                Status: tipo.Status
                                                                            }
                                                                        )
                                                                    }}
                                                                ></Switch>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '5rem',
                                                                    marginLeft:
                                                                        '1rem',
                                                                    verticalAlign:
                                                                        'middle',
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{
                                                                        fontSize:
                                                                            '1rem',
                                                                        color: '#6C7480',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    icon={
                                                                        faPencilAlt
                                                                    }
                                                                    onClick={() => {
                                                                        setModalTipoSolicitacao(
                                                                            {
                                                                                tipo: 2,
                                                                                idTipoSolicitacao:
                                                                                    tipo.Id,
                                                                                nomeTipoSolicitacao:
                                                                                    tipo.Nome,
                                                                                statusTipoSolicitacao:
                                                                                    tipo.Status
                                                                            }
                                                                        )
                                                                        setShowModalTipoSolicitacao(
                                                                            true
                                                                        )
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                ) : !administrador ? (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                ) : (
                    <Empty text="Nenhum tipo de solicitação adicionada" />
                )}
            </BlankBoard>
            <ModalTipoSolicitacao
                tipo={modalTipoSolicitacao.tipo}
                show={showModalTipoSolicitacao}
                setShow={setShowModalTipoSolicitacao}
                idTipoSolicitacao={modalTipoSolicitacao.idTipoSolicitacao}
                nomeTipoSolicitacao={modalTipoSolicitacao.nomeTipoSolicitacao}
            />
            <ModalConfirmacao
                id={inativarTipo.Id}
                nome={inativarTipo.Nome}
                statusTipoSolicitacao={inativarTipo.Status}
                show={showModalConfirmacao}
                setShow={setShowModalConfirmacao}
            />
        </>
    )
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Button } from '../../../core/components/Button'
import { Center } from '../../../core/components/Center'
import { Empty } from '../../../core/components/Empty'
import { Input } from '../../../core/components/Form/Input'
import { SelectRequester } from '../../../core/components/Form/SelectWithRequest'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Space } from '../../../core/components/Space'
import { ENDPOINTS } from '../../../services/config-http'
import { destroy, get, post } from '../../../services/http'
import * as Yup from 'yup'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { useAuth } from '../../../contexts/Auth'
import { Servico } from '../../../contexts/interfaces/servico'
import { Deletar } from '../../../core/components/Deletar'
import { Usuario } from '../../../contexts/interfaces/usuario'
import { Toast } from '../../../core/components/Toast'
import { Modal } from '../../../core/components/Modal/Modal'
import { MoneyFormat } from '../../../core/utils/MoneyFormat'
import { ModalII } from '../../../core/components/ModalII/Modal'
import { UserContext } from '../../../contexts/UserContext'
import { Alert } from '../../../core/components/Alert'
import { InputCurrency } from '../../../core/components/Form/InputCurrency'
import { IntlRealFormat } from '../../../core/utils/IntlFormat'
import styled from 'styled-components'

export const CotacaoServico = () => {
    const { user } = useAuth()
    const formRef = useRef<FormHandles>(null)
    const { Id } = useParams() as any
    const [valor, setValor] = useState(0)
    const [servicosSolicitacao, setServicosSolicitacao] = useState<Servico[]>(
        []
    )
    const [showModal, setShowModal] = useState(false)
    const [servicoSolicitacao, setServicoSolicitacao] = useState<Servico>()
    const [usuario, setUsuario] = useState<Usuario>()
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    const [administrador, setAdministrador] = useState(false)
    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        const pesquisar = async () => {
            await get(`${ENDPOINTS.servicoSolicitacao}/${Id}`)
                .then(async ({ data: { Model } }) => {
                    setServicosSolicitacao(Model)
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao buscar serviços',
                        type: 'success'
                    })
                    return err
                })
            await get(
                `${ENDPOINTS.usuarioByCodigo.replace(':Codigo', user.codigo)}`
            )
                .then(({ data: { Model } }) => {
                    setUsuario(Model)
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao buscar usuário',
                        type: 'success'
                    })
                    return err
                })
        }
        pesquisar()
    }, [])

    const handleSubmit: SubmitHandler = async (data, { reset }) => {
        try {
            const schema = Yup.object().shape({
                idServico: Yup.string().required(
                    'O Campo Serviço é obrigatório!'
                ),
                observacao: Yup.string().required(
                    'O Campo Observação é obrigatório!'
                )
            })
            await schema.validate(data, { abortEarly: false })
            formRef.current?.setErrors({})

            const { idServico, observacao } = data
            const newServicoSolicitacao = {
                idServico: idServico,
                idSolicitacaoViagem: Id,
                observacao: observacao
            }
            post(ENDPOINTS.servicoSolicitacao, newServicoSolicitacao)
                .then(res => {
                    window.location.reload()
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao cadastrar serviço',
                        type: 'success'
                    })
                    return err
                })
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                formRef.current?.setErrors(erros)
            }
        }
    }

    const handleSubmitCotacao: SubmitHandler = async (data: any) => {
        try {
            const schema = Yup.object().shape({
                valor: Yup.string().required('O Campo Valor é obrigatório!'),
                observacao: Yup.string().required(
                    'O Campo Observação é obrigatório!'
                )
            })
            await schema.validate(data, { abortEarly: false })
            formRef.current?.setErrors({})
            const { observacao } = data

            const cotacao = {
                IdServicoSolicitacao: servicoSolicitacao?.Id,
                IdUsuarioCotacao: usuario?.Id,
                DataCotacao: new Date(),
                Observacao: observacao,
                ValorCotado: valor,
                Status: true
            }

            console.log(MoneyFormat(cotacao.ValorCotado))
            post(ENDPOINTS.cotacaoServico, cotacao)
                .then(res => {
                    window.location.reload()
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao cadastrar cotação',
                        type: 'success'
                    })
                    return err
                })
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                formRef.current?.setErrors(erros)
            }
            // setShowModal(false)
        }
    }

    function handleDeleteCotacao(posicaoServico: number) {
        const newServico = servicosSolicitacao.splice(posicaoServico, 1)[0]
        destroy(`${ENDPOINTS.cotacaoServico}/${newServico.Cotacao?.IdCotacao}`)
            .then(res => {
                setShowToast(true)
                setToast({
                    message: 'Cotação deletada com sucesso',
                    type: 'success'
                })

                newServico.Cotacao = undefined
                setServicosSolicitacao([...servicosSolicitacao, newServico])
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    message: err.data.Text,
                    type: 'error'
                })
            })
    }

    const handleDeleteServico = (posicaoServico: number) => {
        const newServico = servicosSolicitacao.splice(posicaoServico, 1)[0]
        if (newServico.Cotacao) {
            destroy(
                `${ENDPOINTS.cotacaoServico}/${newServico.Cotacao?.IdCotacao}`
            )
                .then(res => {
                    destroy(`${ENDPOINTS.servicoSolicitacao}/${newServico.Id}`)
                        .then(res => {
                            setShowToast(true)
                            setToast({
                                message: 'Serviço deletado com sucesso',
                                type: 'success'
                            })

                            setServicosSolicitacao([...servicosSolicitacao])
                        })
                        .catch(err => {
                            setShowToast(true)
                            setToast({
                                message: err.data.Text,
                                type: 'error'
                            })
                        })
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: err.data.Text,
                        type: 'error'
                    })
                })
        } else {
            destroy(`${ENDPOINTS.servicoSolicitacao}/${newServico.Id}`)
                .then(res => {
                    setShowToast(true)
                    setToast({
                        message: 'Serviço deletado com sucesso',
                        type: 'success'
                    })

                    setServicosSolicitacao([...servicosSolicitacao])
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: err.data.Text,
                        type: 'error'
                    })
                })
        }
    }

    return (
        <>
            {administrador ? (
                <>
                    <Form
                        onSubmit={handleSubmit}
                        ref={formRef}
                        style={{ padding: 0 }}
                    >
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <SelectRequester
                                    name="idServico"
                                    label="Qual Serviço?"
                                    placeholder="Escolha um serviço"
                                    required={true}
                                    request={{
                                        endpoint: ENDPOINTS.servico,
                                        labelToSelect: 'Descricao',
                                        valueToSelect: 'Id'
                                    }}
                                ></SelectRequester>
                            </div>
                            <div className="col-12 col-md-5">
                                <Input
                                    name="observacao"
                                    label="Observação"
                                    placeholder="Por que você precisa deste serviço?"
                                    required={true}
                                ></Input>
                            </div>
                            <div
                                className="col-12 col-sm-3 d-flex align-items-center"
                                style={{ paddingTop: '15px' }}
                            >
                                <Button
                                    type="submit"
                                    style={{
                                        width: '100%',
                                        minWidth: 'auto',
                                        justifyContent: 'flex-end',
                                        margin: '-1px'
                                    }}
                                    colorType="quaternary"
                                    icon="plus"
                                >
                                    Adicionar
                                </Button>
                            </div>
                        </div>
                    </Form>
                    <Space height="2rem"></Space>
                    <div className="row">
                        {servicosSolicitacao?.length ? (
                            servicosSolicitacao?.map(
                                (servico: Servico, index) => {
                                    return (
                                        <div key={index} className="content">
                                            <div
                                                className="col-12"
                                                style={{
                                                    padding: '1rem',
                                                    border: 'solid 1px lightgray',
                                                    borderRadius: '7px',
                                                    marginBottom: '2rem'
                                                }}
                                            >
                                                <div
                                                    className="row"
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <div className="col-4">
                                                        <label>
                                                            <Text>Serviço</Text>
                                                        </label>
                                                        <p>
                                                            {
                                                                servico.DescricaoServico
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="col-4">
                                                        <label>
                                                            <Text>
                                                                Observações
                                                            </Text>
                                                        </label>
                                                        <p>
                                                            {servico.Observacao}
                                                        </p>
                                                    </div>
                                                    <div className="col-1">
                                                        {servico.Cotacao ===
                                                            null ||
                                                        servico.Cotacao ===
                                                            undefined ? (
                                                            <button
                                                                onClick={e => {
                                                                    setShowModal(
                                                                        true
                                                                    )
                                                                    setServicoSolicitacao(
                                                                        servico
                                                                    )
                                                                }}
                                                                style={{
                                                                    backgroundColor:
                                                                        '#002a5d',
                                                                    color: '#fff',
                                                                    border: 0,
                                                                    padding:
                                                                        '0.3rem 0.8rem',
                                                                    borderRadius:
                                                                        '15px'
                                                                }}
                                                            >
                                                                Cotar{' '}
                                                                <FontAwesomeIcon icon="plus" />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                style={{
                                                                    backgroundColor:
                                                                        '#c2c2c2',
                                                                    color: '#fff',
                                                                    border: 0,
                                                                    padding:
                                                                        '0.3rem 0.8rem',
                                                                    borderRadius:
                                                                        '15px'
                                                                }}
                                                            >
                                                                Cotar{' '}
                                                                <FontAwesomeIcon icon="plus" />
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="col-1">
                                                        <button
                                                            style={{
                                                                backgroundColor:
                                                                    '#FFF',
                                                                color: '#6C7480',
                                                                border: 0,
                                                                padding:
                                                                    '0.3rem 0.8rem',
                                                                borderRadius:
                                                                    '15px'
                                                            }}
                                                        ></button>
                                                    </div>
                                                    <div className="col-2">
                                                        <Deletar
                                                            remover={() => {
                                                                handleDeleteServico(
                                                                    index
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <LineSeparator
                                                    style={{
                                                        margin: '.3rem 0'
                                                    }}
                                                ></LineSeparator>
                                                <Center>
                                                    <h4
                                                        style={{
                                                            color: '#6C7480'
                                                        }}
                                                    >
                                                        Cotação
                                                    </h4>
                                                </Center>
                                                {servico.Cotacao !== null &&
                                                servico.Cotacao !==
                                                    undefined ? (
                                                    <div className="row">
                                                        <div className="col col-sm-8">
                                                            <label>
                                                                <Text>
                                                                    Observação
                                                                    da cotação
                                                                </Text>
                                                            </label>
                                                            <p>
                                                                {
                                                                    servico
                                                                        .Cotacao
                                                                        ?.ObservacaoCotacao
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col col-sm-2">
                                                            <label>
                                                                <Text>
                                                                    Valor Total
                                                                </Text>
                                                            </label>
                                                            <p>
                                                                {IntlRealFormat(
                                                                    Number(
                                                                        servico
                                                                            .Cotacao
                                                                            ?.ValorCotado
                                                                    )
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="col col-sm-2">
                                                            <Deletar
                                                                remover={() => {
                                                                    handleDeleteCotacao(
                                                                        index
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <Center>
                                                        <Empty text="Não há cotação cadastrada" />
                                                    </Center>
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        ) : (
                            <Center>
                                <div
                                    className="alert alert-info d-flex align-items-center mb-3 mt-2"
                                    role="alert"
                                >
                                    Não foi encontrado registro !<div></div>
                                </div>
                            </Center>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <br />
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão para cadastrar conferencia "
                    ></Alert>
                </>
            )}
            {showModal ? (
                <Modal
                    title={'Nova cotação'}
                    setShow={setShowModal}
                    height={'16rem'}
                >
                    <Form
                        onSubmit={handleSubmitCotacao}
                        ref={formRef}
                        style={{ padding: 0 }}
                    >
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <InputCurrency
                                    onChange={(e: any) => {
                                        const valorReplace = Number(
                                            e.currentTarget.value
                                                .replaceAll('.', '')
                                                .replaceAll(',', '.')
                                                .replaceAll('R$', '')
                                        )
                                        setValor(valorReplace)
                                    }}
                                    value={IntlRealFormat(valor || 0)}
                                    width="col-12"
                                    label="Valor"
                                    name="valor"
                                    mask="999.999,99"
                                    required={true}
                                    defaultValue={MoneyFormat(0)}
                                />
                            </div>
                            <div className="col-12 col-md-8">
                                <Input
                                    name="observacao"
                                    label="Observação"
                                    placeholder="Descreva esta cotação"
                                    required={true}
                                ></Input>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                icon="save"
                                type="submit"
                                colorType="quaternary"
                            >
                                Gravar
                            </Button>
                        </div>
                    </Form>
                </Modal>
            ) : null}

            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </>
    )
}

const Text = styled.p``

import React, { useContext, useEffect, useRef, useState } from 'react'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Header } from '../../../core/components/Header/Header'
import { Button } from '../../../core/components/Button'
import { useHistory } from 'react-router-dom'
import { Flex } from '../../../core/components/Flex'
import { ENDPOINTS } from '../../../services/config-http'
import { SubmitHandler, FormHandles } from '@unform/core'
import { Input } from '../../../core/components/Form/Input'
import { Form } from '@unform/web'
import { Alert } from '../../../core/components/Alert'
import {
    TableRequester,
    Table,
    ConfigTable
} from './../../../core/components/Table/v2/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserContext } from '../../../contexts/UserContext'
import { Center } from '../../../core/components/Center'
import { Subtitle } from '../../../core/components/Subtitle'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { DisableForMobile, IsMobile } from '../../Viagem/Solicitacao/stepMobile'

export const ListarUsuario = () => {
    const history = useHistory()
    const [administrador, setAdministrador] = useState(false)

    const [payloadSearch, setPayloadSearch] = useState<TableRequester>({
        endpoint: ENDPOINTS.pesquisarUsuarios,
        method: 'GET',
        payload: {}
    })

    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })

    const MapperItem = [
        { field: 'Codigo', replaceTo: 'Código', position: 1 },
        { field: 'Nome', position: 2 },
        {
            field: 'TipoUsuario',
            replaceTo: 'Tipo do Usuário',
            visualCustom: _pipeTipoUsuario,
            position: 3
        },
        { field: 'Email', position: 4 },
        { field: 'Status', visualCustom: _pipeStatus, position: 5 },
        { field: 'Id', delete: true },
        { field: 'IdHierarquia', delete: true },
        { field: 'IdFilial', delete: true },
        { field: 'IdPoliticaViagem', delete: true },
        { field: 'AprovaViagem', delete: true },
        { field: 'AprovaPrestacao', delete: true },
        { field: 'Administrador', delete: true }
    ]

    const tableConfig: ConfigTable = {
        edit: true,
        delete: false,
        editAction: item => {
            if (item.Codigo === null || item.Codigo === '') {
                history.push(`/cadastros/convidado/${item.Id}/editar`)
            } else {
                history.push(`/cadastros/usuario/${item.Id}/editar`)
            }
        }
    }

    function _pipeTipoUsuario(value: any): JSX.Element {
        if (value === 0) {
            return <span>Convidado</span>
        }
        return <span>Colaborador</span>
    }

    function _pipeStatus(value: boolean): JSX.Element {
        if (value) {
            return (
                <span
                    className="badge status-success"
                    style={{ width: '75px', margin: 'auto' }}
                >
                    <FontAwesomeIcon icon="circle" />
                    &ensp; {'ATIVO'}
                </span>
            )
        } else {
            return (
                <span
                    className="badge status-danger"
                    style={{ width: '75px', margin: 'auto' }}
                >
                    <FontAwesomeIcon icon="circle" />
                    &ensp; {'INATIVO'}
                </span>
            )
        }
    }

    const FormRef = useRef<FormHandles>(null)

    const handleSubmit: SubmitHandler<FormData> = (data: any) => {
        Object.keys(data).forEach(
            k => !data[k] && data[k] !== undefined && delete data[k] // eliminando valores não preenchidos para envio da query de busca
        )
        data.page = 0
        data.lastCount = 0
        data.totalRegisterPage = 10
        const newPayloadSearch = { ...payloadSearch, payload: data }
        console.log(data)
        setPayloadSearch(newPayloadSearch)
    }

    return (
        <BlankBoard>
            <Alert
                message={showAlert.message}
                status={showAlert.status}
                type={showAlert.type as any}
            ></Alert>
            <Header title="Usuários" actionArrowClick="/solicitacoes"></Header>
            <Center>
                <Subtitle>Dados usuários</Subtitle>
            </Center>
            {administrador ? (
                <>
                    <Flex justifyContent="flex-end">
                        <DisableForMobile>
                            <Button
                                style={{
                                    marginRight: '-3.2rem'
                                }}
                                icon="plus"
                                colorType="quaternary"
                                onClick={() =>
                                    history.push('/cadastros/convidado/incluir')
                                }
                            >
                                Cadastrar
                            </Button>
                        </DisableForMobile>
                    </Flex>
                    <Center>
                        <IsMobile>
                            <Button
                                icon="plus"
                                colorType="btnMobile"
                                onClick={() =>
                                    history.push('/cadastros/convidado/incluir')
                                }
                            >
                                Cadastrar
                            </Button>
                        </IsMobile>
                    </Center>
                    <LineSeparator></LineSeparator>
                    <Form
                        ref={FormRef}
                        onSubmit={handleSubmit}
                        style={{ padding: '0px' }}
                    >
                        <div className="row">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '1rem'
                                }}
                            >
                                <Button
                                    style={{
                                        margin: '0 .2rem',
                                        padding: '0 15px'
                                    }}
                                    onlyIcon={true}
                                    icon="search"
                                    title="Pesquisar"
                                    colorType="primary"
                                ></Button>
                            </div>
                            <div className="col-12 col-sm-3">
                                <Input
                                    name="Nome"
                                    label="Nome"
                                    placeholder="Digite o nome"
                                ></Input>
                            </div>
                            <div className="col-12 col-sm-3">
                                <Input
                                    name="Codigo"
                                    label="Código"
                                    placeholder="Digite o código"
                                ></Input>
                            </div>
                            <div className="col-12 col-sm-3">
                                <Input
                                    name="CodigoFilial"
                                    label="Código da Filial"
                                    placeholder="Digite o código da filial"
                                ></Input>
                            </div>
                            <div className="col-12 col-sm-3">
                                <Input
                                    name="CPF"
                                    label="CPF"
                                    placeholder="Digite o número do CPF"
                                ></Input>
                            </div>
                        </div>
                    </Form>
                    <Table
                        requester={payloadSearch}
                        mapper={MapperItem}
                        config={tableConfig}
                    ></Table>
                </>
            ) : (
                <Alert
                    fixed={true}
                    icon="exclamation-circle"
                    type="primary"
                    message="Você não tem permissão de administrador"
                ></Alert>
            )}
        </BlankBoard>
    )
}

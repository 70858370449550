import { useLocation } from 'react-router-dom'
import { useAuth } from '../../contexts/Auth'
import { getCookie } from '../../core/utils/GetCookie'

export const Login: React.FC = () => {
    const query = new URLSearchParams(useLocation().search)
    const { signIn } = useAuth()

    const token =
        query.get('access_token') || getCookie('HavanAuthenticationCookie')
    const refreshToken =
        (query.get('refresh_token') ||
            getCookie('HavanAuthenticationCookie')) ??
        ''

    if (!token) {
        const webUrl = process.env.REACT_APP_WEB
        window.location.href = `${process.env.REACT_APP_AUTH}Entrar?ReturnUrl=${webUrl}/&RedirectUrl=${webUrl}/solicitacoes`
        return null
    }
    signIn(token, refreshToken)
    return null
}

import React, { useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import { Select } from '../../../../core/components/Form/Select'
import { SelectMapper } from '../../../../core/utils/SelectMapper'
import { ENDPOINTS } from '../../../../services/config-http'
import { get, put } from '../../../../services/http'
import { FormHandles, SubmitHandler } from '@unform/core'
import Switch from 'react-switch'
import { RegraPoliticaViagem } from '../../../../contexts/interfaces/regrasPoliticaViagem'
import { Button } from '../../../../core/components/Button'
import styled from 'styled-components'
import { Center } from '../../../../core/components/Center'

export const Configuracao = ({
    nomePolitica,
    idPolitica
}: {
    nomePolitica: string
    idPolitica: number
}) => {
    const [tiposSolicitacao, setTiposSolicitacao] = useState([])
    const [regraPolitica, setRegraPolitica] = useState<RegraPoliticaViagem>(
        {} as RegraPoliticaViagem
    )
    const formRef = useRef<FormHandles>(null)
    useEffect(() => {
        const politicas = async () => {
            await get(
                `${ENDPOINTS.regrasPoliticaViagem}/BuscarPorIdPolitica/${idPolitica}`
            )
                .then(({ data: { Model } }) => {
                    setTiposSolicitacao(
                        SelectMapper(Model, 'TipoSolicitacao', 'Id')
                    )
                })
                .catch(err => {
                    console.error(err)
                })
        }
        politicas()
    }, [])

    const handleSubmit: SubmitHandler = async (data: any) => {
        console.log(data)
    }

    const buscaRegra = async (id: number) => {
        await get(`${ENDPOINTS.regrasPoliticaViagem}/${id}`)
            .then(({ data: { Model } }) => {
                setRegraPolitica(Model)
            })
            .catch(err => {
                console.error(err)
            })
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: '1.5rem', marginTop: '2rem' }}>
                    {nomePolitica}
                </div>
                <div style={{ width: '15rem' }}>
                    <Form
                        onSubmit={handleSubmit}
                        ref={formRef}
                        style={{ padding: 0, margin: 0 }}
                    >
                        <Select
                            name="tipoSolicitacao"
                            label="Tipo de solicitação"
                            placeholder="Selecione o tipo"
                            required={true}
                            options={tiposSolicitacao}
                            onChange={e => {
                                buscaRegra(e?.value)
                            }}
                        ></Select>
                    </Form>
                </div>
            </div>
            <div className="container-permissoes">
                {regraPolitica?.Id ? (
                    <>
                        <div className="row linha-permissao">
                            <div
                                className="col-7"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Switch
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={
                                        regraPolitica.BloquearAdiantamentoComDoisAdiantamentosPendentes
                                    }
                                    onChange={() => {
                                        const newRegra = { ...regraPolitica }
                                        newRegra.BloquearAdiantamentoComDoisAdiantamentosPendentes =
                                            !regraPolitica.BloquearAdiantamentoComDoisAdiantamentosPendentes
                                        setRegraPolitica(newRegra)
                                    }}
                                ></Switch>
                                <Text>
                                    Bloquear adiantamento com dois adiantamentos
                                    pendentes
                                </Text>
                            </div>
                            <div className="col-5" style={{ display: 'flex' }}>
                                <Switch
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={
                                        regraPolitica.BloquearAutorizacaoRetroativa
                                    }
                                    onChange={() => {
                                        const newRegra = { ...regraPolitica }
                                        newRegra.BloquearAutorizacaoRetroativa =
                                            !regraPolitica.BloquearAutorizacaoRetroativa
                                        setRegraPolitica(newRegra)
                                    }}
                                ></Switch>
                                <Text>Bloquear autorização retroativa</Text>
                            </div>
                        </div>
                        <div className="row linha-permissao">
                            <div className="col-7" style={{ display: 'flex' }}>
                                <Switch
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={
                                        regraPolitica.NaoConsiderarColaboradorNasAprovacoes
                                    }
                                    onChange={() => {
                                        const newRegra = { ...regraPolitica }
                                        newRegra.NaoConsiderarColaboradorNasAprovacoes =
                                            !regraPolitica.NaoConsiderarColaboradorNasAprovacoes
                                        setRegraPolitica(newRegra)
                                    }}
                                ></Switch>
                                <Text>
                                    Não considerar o colaborador nas aprovações
                                </Text>
                            </div>
                            <div className="col-5" style={{ display: 'flex' }}>
                                <Switch
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={
                                        regraPolitica.SolicitacaoSemDespesasReembolso
                                    }
                                    onChange={() => {
                                        const newRegra = { ...regraPolitica }
                                        newRegra.SolicitacaoSemDespesasReembolso =
                                            !regraPolitica.SolicitacaoSemDespesasReembolso
                                        setRegraPolitica(newRegra)
                                    }}
                                ></Switch>
                                <Text>
                                    Solicitações sem despesas de reembolso
                                </Text>
                            </div>
                        </div>
                        <div className="row linha-permissao">
                            <div className="col-7" style={{ display: 'flex' }}>
                                <Switch
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={
                                        regraPolitica.UtilizarAdiantamentos
                                    }
                                    onChange={() => {
                                        const newRegra = { ...regraPolitica }
                                        newRegra.UtilizarAdiantamentos =
                                            !regraPolitica.UtilizarAdiantamentos
                                        setRegraPolitica(newRegra)
                                    }}
                                ></Switch>
                                <Text>Utilizar adiantamentos</Text>
                            </div>
                            <div className="col-5" style={{ display: 'flex' }}>
                                <Switch
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={regraPolitica.UtilizarAereo}
                                    onChange={() => {
                                        const newRegra = { ...regraPolitica }
                                        newRegra.UtilizarAereo =
                                            !regraPolitica.UtilizarAereo
                                        setRegraPolitica(newRegra)
                                    }}
                                ></Switch>
                                <Text>Utilizar aéreo</Text>
                            </div>
                        </div>
                        <div className="row linha-permissao">
                            <div className="col-7" style={{ display: 'flex' }}>
                                <Switch
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={regraPolitica.UtilizarHospedagem}
                                    onChange={() => {
                                        const newRegra = { ...regraPolitica }
                                        newRegra.UtilizarHospedagem =
                                            !regraPolitica.UtilizarHospedagem
                                        setRegraPolitica(newRegra)
                                    }}
                                ></Switch>
                                <Text>Utilizar hospedagem</Text>
                            </div>
                            <div className="col-5" style={{ display: 'flex' }}>
                                <Switch
                                    disabled={true}
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={regraPolitica.UtilizarRoteiro}
                                ></Switch>
                                <Text>Utilizar roteiro</Text>
                            </div>
                        </div>
                        <div className="row linha-permissao">
                            <div className="col-7" style={{ display: 'flex' }}>
                                <Switch
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={regraPolitica.UtilizarServicos}
                                    onChange={() => {
                                        const newRegra = { ...regraPolitica }
                                        newRegra.UtilizarServicos =
                                            !regraPolitica.UtilizarServicos
                                        setRegraPolitica(newRegra)
                                    }}
                                ></Switch>
                                <Text>Utilizar serviços</Text>
                            </div>
                            <div className="col-5" style={{ display: 'flex' }}>
                                <Switch
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={
                                        regraPolitica.ValidarMenorPrecoAereo
                                    }
                                    onChange={() => {
                                        const newRegra = { ...regraPolitica }
                                        newRegra.ValidarMenorPrecoAereo =
                                            !regraPolitica.ValidarMenorPrecoAereo
                                        setRegraPolitica(newRegra)
                                    }}
                                ></Switch>
                                <Text>
                                    Validar menor preço na escolha de aéreo
                                </Text>
                            </div>
                        </div>
                        <div className="row linha-permissao">
                            <div className="col-7" style={{ display: 'flex' }}>
                                <Switch
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={
                                        regraPolitica.ValidarPrazoAntecedenciaParaRequisacaoDeAereo
                                    }
                                    onChange={() => {
                                        const newRegra = { ...regraPolitica }
                                        newRegra.ValidarPrazoAntecedenciaParaRequisacaoDeAereo =
                                            !regraPolitica.ValidarPrazoAntecedenciaParaRequisacaoDeAereo
                                        setRegraPolitica(newRegra)
                                    }}
                                ></Switch>
                                <Text>
                                    Validar prazo de antecedência para
                                    requisição de aéreo(10 dias)
                                </Text>
                            </div>
                            <div className="col-5" style={{ display: 'flex' }}>
                                <Switch
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={
                                        regraPolitica.ValidarPrazoAntecedenciaParaRequisacaoDeSolicitacao
                                    }
                                    onChange={() => {
                                        const newRegra = { ...regraPolitica }
                                        newRegra.ValidarPrazoAntecedenciaParaRequisacaoDeSolicitacao =
                                            !regraPolitica.ValidarPrazoAntecedenciaParaRequisacaoDeSolicitacao
                                        setRegraPolitica(newRegra)
                                    }}
                                ></Switch>
                                <Text>
                                    Validar prazo de antecedência para
                                    requisição de solicitações(1 dia)
                                </Text>
                            </div>
                        </div>
                        <div className="row linha-permissao">
                            <div className="col-7" style={{ display: 'flex' }}>
                                <Switch
                                    onColor="#10478A"
                                    offColor="#E9ECEF"
                                    onHandleColor="#E9ECEF"
                                    offHandleColor="#ADB5BD"
                                    handleDiameter={15}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    width={48}
                                    height={21}
                                    checked={
                                        regraPolitica.ValidarSobreposicaoPeriodoNoRoteiro
                                    }
                                    onChange={() => {
                                        const newRegra = { ...regraPolitica }
                                        newRegra.ValidarSobreposicaoPeriodoNoRoteiro =
                                            !regraPolitica.ValidarSobreposicaoPeriodoNoRoteiro
                                        setRegraPolitica(newRegra)
                                    }}
                                ></Switch>
                                <Text>
                                    Validar sobreposição de período no roteiro
                                </Text>
                            </div>
                            <div
                                className="col-5"
                                style={{ display: 'flex' }}
                            ></div>
                        </div>
                    </>
                ) : (
                    <>
                        <Center>
                            <h2>Escolha o tipo de solicitação!</h2>
                        </Center>
                    </>
                )}
            </div>
            <div className="row mt-5">
                <div className="col-5"></div>
                <div
                    className="col-4"
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    {regraPolitica?.Id && (
                        <Button
                            icon="save"
                            colorType="quaternary"
                            onClick={() => {
                                put(
                                    ENDPOINTS.regrasPoliticaViagem,
                                    regraPolitica
                                )
                                    .then(data => {
                                        console.log(data)
                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })
                            }}
                        >
                            Salvar
                        </Button>
                    )}
                </div>
                <div className="col-3"></div>
            </div>
        </>
    )
}

const Text = styled.p`
    margin-left: 0.5rem;
    font-style: normal;
    color: #06347a;
    font-size: 0.9rem;
    font-weight: 500;
`

export enum SOLICITACAO_VIAGEM {
    REDUCER_NAME = 'SOLICITACAO_VIAGEM_REDUCER',

    ACTION_ADD_SOLICITACAO = 'ACTION_ADD_SOLICITACAO',
    ACTION_ADD_INFO_VIAGEM = 'ACTION_ADD_INFO_VIAGEM',
    ACTION_ADD_NOVA_ETAPA = 'ACTION_ADD_NOVA_ETAPA',
    ACTION_ADD_ROTEIRO = 'ACTION_ADD_ROTEIRO',
    ACTION_ADD_ADIANTAMENTO = 'ACTION_ADD_ADIANTAMENTO',
    ACTION_ADD_AEREO = 'ACTION_ADD_AEREO',
    ACTION_ADD_HOSPEDAGEM = 'ACTION_ADD_HOSPEDAGEM',
    ACTION_ADD_SERVICO = 'ACTION_ADD_SERVICO',
    ACTION_ADD_RATEIO = 'ACTION_ADD_RATEIO',
    ACTION_ADD_VIAJANTES = 'ACTION_ADD_VIAJANTES',
    ACTION_ADD_VIAJANTE_PRINCIPAL = 'ACTION_ADD_VIAJANTE_PRINCIPAL',
    ACTION_ADD_STEP_BY_STEP_ITEM = 'ACTION_ADD_STEP_BY_STEP_ITEM'
}

import React, { useEffect, useRef, useState } from 'react'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Header } from '../../../../core/components/Header/Header'
import { StepByStep } from '../../../../core/components/StepByStep'
import { useHistory } from 'react-router-dom'
import { StepByStepItem } from '../../../../contexts/interfaces/_stepByStep'
import { Button } from '../../../../core/components/Button'
import { Space } from '../../../../core/components/Space'
import { SOLICITACAO_VIAGEM } from '../../../../state/SolicitacaoViagem/Actions'
import { useGlobalStates } from '../../../../state/InitialState'
import { Solicitacao } from '../../../../contexts/interfaces/solicitacao'
import { Aereo } from '../../../../contexts/interfaces/aereo'
import { get } from '../../../../services/http'
import { CadastroAereo } from '../../../Aereo/CadastroAereo'
import styled from 'styled-components'
import { Center } from '../../../../core/components/Center'
import { DisableForMobile, IsMobile } from '../stepMobile'

export const AereoForm = () => {
    const history = useHistory()
    const {
        setGlobalState,
        state: { solicitacao }
    } = useGlobalStates()
    const [stepByStep, setStepByStep] = useState<StepByStepItem[]>([])
    const indexActiveStep = useRef<number>(0)
    const [idSolicitacao, setIdSolicitacao] = useState(0)
    const [reservas, setReservas] = useState<Aereo[]>([])
    const [changeLabel, setChangeLabel] = useState(false)

    useEffect(() => {
        const stepItem = localStorage.getItem('stepByStep')
        if (stepItem !== null) {
            const stepItemObj = JSON.parse(stepItem) as StepByStepItem[]
            indexActiveStep.current = stepItemObj.findIndex(
                s => s.name === 'Aéreo'
            )
            stepItemObj[indexActiveStep.current].active = true
            for (let index = 0; index < indexActiveStep.current; index++) {
                stepItemObj[index].completed = true
            }
            setStepByStep(stepItemObj)
        }

        const sol = localStorage.getItem('solicitacao')
        if (sol !== null) {
            const solicitacaoStorage = JSON.parse(sol) as Solicitacao
            setGlobalState(
                SOLICITACAO_VIAGEM.REDUCER_NAME,
                SOLICITACAO_VIAGEM.ACTION_ADD_SOLICITACAO,
                solicitacaoStorage
            )
        }
        const idSolicitacao = parseInt(
            localStorage.getItem('idSolicitacao') || '0'
        )
        setIdSolicitacao(idSolicitacao)
    }, [])

    return (
        <BlankBoard>
            <Header
                title="Solicitação de viagem"
                actionArrowClick={() => {
                    if (indexActiveStep.current === 0) {
                        history.push('/solicitar-viagem/info')
                    } else {
                        history.push(
                            `/solicitar-viagem/${
                                stepByStep[indexActiveStep.current - 1].caminho
                            }`
                        )
                    }
                }}
            ></Header>
            <StepByStep items={stepByStep}></StepByStep>
            <Space height="1rem"></Space>

            <div className="row">
                <div className="col-12 col-sm-10">
                    <h2 className="fw-bolder">Reserve seu Voo!</h2>
                    <p className="gray-text f-size-09rem">
                        Se você vai precisar viajar de avião preencha os dados
                        abaixo e selecione seu vôo.
                    </p>
                </div>
                <div
                    className="col-12 col-sm-2"
                    style={{ justifyContent: 'flex-end' }}
                >
                    {changeLabel ? (
                        <>
                            <DisableForMobile>
                                <Button
                                    colorType="quaternary"
                                    type="submit"
                                    onClick={() =>
                                        history.push(
                                            `/solicitar-viagem/${
                                                stepByStep[
                                                    indexActiveStep.current + 1
                                                ].caminho
                                            }`
                                        )
                                    }
                                >
                                    Próximo
                                </Button>
                            </DisableForMobile>
                            <Center>
                                <IsMobile>
                                    <Button
                                        colorType="btnMobile"
                                        type="submit"
                                        onClick={() =>
                                            history.push(
                                                `/solicitar-viagem/${
                                                    stepByStep[
                                                        indexActiveStep.current +
                                                            1
                                                    ].caminho
                                                }`
                                            )
                                        }
                                    >
                                        Próximo
                                    </Button>
                                </IsMobile>
                            </Center>
                        </>
                    ) : (
                        <>
                            <DisableForMobile>
                                <Button
                                    style={{
                                        width: '75%',
                                        minWidth: 'auto'
                                    }}
                                    colorType="quintiary"
                                    icon="arrow-right"
                                    onClick={() => {
                                        history.push(
                                            `/solicitar-viagem/${
                                                stepByStep[
                                                    indexActiveStep.current + 1
                                                ].caminho
                                            }`
                                        )
                                    }}
                                >
                                    Pular
                                </Button>
                            </DisableForMobile>
                            <Center>
                                <IsMobile>
                                    <Button
                                        colorType="btnMobile"
                                        type="submit"
                                        onClick={() => {
                                            history.push(
                                                `/solicitar-viagem/${
                                                    stepByStep[
                                                        indexActiveStep.current +
                                                            1
                                                    ].caminho
                                                }`
                                            )
                                        }}
                                    >
                                        Pular
                                    </Button>
                                </IsMobile>
                            </Center>
                        </>
                    )}
                </div>
            </div>
            <CadastroAereo
                idSolicitacao={idSolicitacao}
                qtdViajantes={solicitacao?.viajantes?.length || 1}
                changeLabel={setChangeLabel}
                isEtapa={true}
            />
        </BlankBoard>
    )
}

export function SelectMapper(
    payload: Array<any>,
    firstLabel: string,
    value: string,
    secoundLabel = ''
): any {
    return payload.map(item => {
        return {
            label: `${item[firstLabel]} ${
                secoundLabel ? ' - ' + item[secoundLabel] : ''
            }`,
            value: item[value]
        }
    })
}

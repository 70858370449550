import React from 'react'
import { ThemeProvider } from 'styled-components'
import { AuthProvider } from '../contexts/Auth'
import { GlobalStateProviderV2 } from '../state/InitialState'
import light from '../styles/themes/light'
import { AlertProvider } from './AlertToast'
import { InterceptorProvider } from './Inteceptor'
import { SpinnerProvider } from './Spinner'

const Hooks: React.FC = ({ children }) => (
    <ThemeProvider theme={light}>
        <AuthProvider>
            <GlobalStateProviderV2>
                <AlertProvider>
                    <SpinnerProvider>
                        <InterceptorProvider>{children}</InterceptorProvider>
                    </SpinnerProvider>
                </AlertProvider>
            </GlobalStateProviderV2>
        </AuthProvider>
    </ThemeProvider>
)

export default Hooks

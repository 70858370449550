import React, { useEffect, useState } from 'react'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Header } from '../../../../core/components/Header/Header'
import styled from 'styled-components'
import { useGlobalStates } from '../../../../state/InitialState'
import { useHistory } from 'react-router-dom'
import { Space } from '../../../../core/components/Space'
import { get, post, put } from '../../../../services/http'
import { ENDPOINTS } from '../../../../services/config-http'
import { Button } from '../../../../core/components/Button'
import { Center } from '../../../../core/components/Center'
import { UrlMountQuery } from '../../../../core/utils/UrlMountQuery'
import { Solicitacao } from '../../../../contexts/interfaces/solicitacao'
import { SOLICITACAO_VIAGEM } from '../../../../state/SolicitacaoViagem/Actions'
import { useAuth } from '../../../../contexts/Auth'
import { Usuario } from '../../../../contexts/interfaces/usuario'
import { Toast } from '../../../../core/components/Toast'
import { useSpinner } from '../../../../hooks/Spinner'
import GolImg from '../../../../core/assets/Gol.jpg'
import AzulImg from '../../../../core/assets/Azul.png'
import LatamImg from '../../../../core/assets/Latam.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { ContainerPadding } from '../../../../core/components/ContainerPadding'
import { SelectMapper } from '../../../../core/utils/SelectMapper'
import { LineSeparator } from '../../../../core/components/LineSeparator'
import { Aereo } from '../../../../contexts/interfaces/aereo'
import { Alert } from '../../../../core/components/Alert'
import { MoneyFormat } from '../../../../core/utils/MoneyFormat'
import {
    Box,
    getCampo,
    getNotFound,
    getReservaMobile,
    Info,
    Lista,
    Quadro,
    Separador,
    TrMobile
} from '../../../../core/components/Mobile'
import useWindowDimensions from '../../../../core/components/GetDimensions'
import { IntlRealFormat } from '../../../../core/utils/IntlFormat'

export const ReviewForm = () => {
    const {
        setGlobalState,
        state: { solicitacao }
    } = useGlobalStates()
    const { setLoading } = useSpinner()
    const history = useHistory()
    const { user } = useAuth()
    const [finished, setFinished] = useState<boolean>(false)
    const [filiais, setFilial] = useState<Array<any>>([])
    const [centroDeCusto, setCentroDeCusto] = useState<Array<any>>([])
    const [servicos, setServicos] = useState<Array<any>>([])
    const [motivos, setMotivos] = useState<Array<any>>([])
    const [tiposSolicitacao, setTiposSolicitacao] = useState<Array<any>>([])
    const [reservas, setReservas] = useState<Aereo[]>([])
    const [idaVolta, setIdaVolta] = useState(true)
    const [abaAtual, setAbaAtual] = useState('Solicitação')
    const [novaEtapa, setNovaEtapa] = useState(0)
    const [showToast, setShowToast] = useState(false)
    const { width } = useWindowDimensions()
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    useEffect(() => {
        get(ENDPOINTS.filial)
            .then(res => {
                setFilial(res?.data?.Model)
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    message: `Não foi possível buscar filiais`,
                    type: 'error'
                })
                return err
            })
        get(ENDPOINTS.servico)
            .then(({ data: { Model } }) => {
                setServicos(Model)
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    message: `Não foi possível buscar os serviços`,
                    type: 'error'
                })
                return err
            })
        get(ENDPOINTS.motivoViagemPesquisar)
            .then(({ data: { Items } }) => {
                setMotivos(Items)
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    message: `Não foi possível buscar o motivo da viagem`,
                    type: 'error'
                })
                return err
            })
        const pesquisa = async () => {
            if (user?.codigo) {
                await get(
                    `${ENDPOINTS.usuarioByCodigo.replace(
                        ':Codigo',
                        user.codigo
                    )}/`
                ).then(async ({ data: { Model } }) => {
                    const { IdPoliticaViagem } = Model
                    await get(
                        `${ENDPOINTS.regrasPoliticaViagem}/BuscarPorIdPolitica/${IdPoliticaViagem}`
                    )
                        .then(({ data: { Model } }) => {
                            setTiposSolicitacao(
                                SelectMapper(Model, 'TipoSolicitacao', 'Id')
                            )
                        })
                        .catch(err => {
                            setShowToast(true)
                            setToast({
                                message: `'Erro ao buscar tipo de solicitação'`,
                                type: 'error'
                            })
                            return err
                        })
                })
            }
        }
        pesquisa()

        get(
            UrlMountQuery(
                { TotalRegisterPage: 500 },
                ENDPOINTS.pesquisarContasContabeis
            )
        )
            .then(({ data: { Items } }) => {
                setCentroDeCusto(Items)
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    message: `'Não foi possível buscar a conta contábil'`,
                    type: 'error'
                })
                return err
            })
        const getUsuarioLogado = async () => {
            let usuarioLogado: Usuario = {}
            if (user.codigo) {
                if (user?.codigo) {
                    await get(
                        `${ENDPOINTS.usuarioByCodigo.replace(
                            ':Codigo',
                            user.codigo
                        )}/`
                    )
                        .then(({ data: { Model } }) => {
                            usuarioLogado = Model
                        })
                        .catch(err => {
                            setShowToast(true)
                            setToast({
                                message: `Não foi possível buscar politicas de viagem`,
                                type: 'error'
                            })
                            return err
                        })
                }
            }

            if (solicitacao === undefined) {
                const sol = localStorage.getItem('solicitacao')
                if (sol !== null) {
                    const solicitacaoStorage = JSON.parse(sol) as Solicitacao
                    solicitacaoStorage.idUsuarioSolicitante = usuarioLogado.Id
                    setGlobalState(
                        SOLICITACAO_VIAGEM.REDUCER_NAME,
                        SOLICITACAO_VIAGEM.ACTION_ADD_SOLICITACAO,
                        solicitacaoStorage
                    )
                }
            } else {
                const novaSolicitacao: Solicitacao = solicitacao
                novaSolicitacao.idUsuarioSolicitante = usuarioLogado?.Id
                setGlobalState(
                    SOLICITACAO_VIAGEM.REDUCER_NAME,
                    SOLICITACAO_VIAGEM.ACTION_ADD_SOLICITACAO,
                    novaSolicitacao
                )
            }
        }
        getUsuarioLogado()

        const buscaPassagensReservadas = async () => {
            setLoading(true)
            const idSolicitacao = parseInt(
                localStorage.getItem('idSolicitacao') || '0'
            )
            await get(
                `${ENDPOINTS.aereoResumido.replace(
                    ':idSolicitacao',
                    idSolicitacao.toString()
                )}`
            )
                .then(({ data: { Model } }) => {
                    setLoading(false)
                    Model?.forEach((element: any) => {
                        const {
                            DataIdaChegada,
                            DataIdaSaida,
                            DataVoltaChegada,
                            DataVoltaSaida,
                            Companhia,
                            QtdEscalas,
                            AeroportoDestino,
                            AeroportoOrigem,
                            IdaVolta,
                            Valor
                        } = element
                        const newAereo: Aereo = {
                            DataIdaChegada: new Date(DataIdaChegada),
                            DataIdaSaida: new Date(DataIdaSaida),
                            DataVoltaChegada: new Date(DataVoltaChegada),
                            DataVoltaSaida: new Date(DataVoltaSaida),
                            Companhia: Companhia,
                            QtdEscalas: QtdEscalas,
                            AeroportoDestino: AeroportoDestino,
                            AeroportoOrigem: AeroportoOrigem,
                            Valor: Valor,
                            IdaVolta: IdaVolta
                        }
                        setIdaVolta(IdaVolta)
                        setReservas(reservas => [...reservas, newAereo])
                    })
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                    setShowToast(true)
                    setToast({
                        message: `Erro ao buscar passagens reservadas`,
                        type: 'error'
                    })
                })
        }
        buscaPassagensReservadas()
        setNovaEtapa(parseInt(localStorage.getItem('NovaEtapa') || '0'))
    }, [])

    const finish = () => {
        if (solicitacao?.idViagemComEtapas) {
            post(ENDPOINTS.novaEtapaViagem, {
                idSolicitacao: solicitacao.idViagemComEtapas
            })
                .then(({ data: { Model } }) => {
                    solicitacao.idViagemComEtapas = Model
                    console.log(solicitacao)
                    post(ENDPOINTS.solicitarViagem, solicitacao)
                        .then(() => setFinished(true))
                        .catch(() => {
                            setShowToast(true)
                            setToast({
                                message: `Não foi possível salvar nova etapa`,
                                type: 'error'
                            })
                        })
                })
                .catch(() => {
                    setShowToast(true)
                    setToast({
                        message: `Não foi possível salvar nova etapa`,
                        type: 'error'
                    })
                    setLoading(false)
                })
        } else {
            const idSolicitacao = parseInt(
                localStorage.getItem('idSolicitacao') || '0'
            )
            const payloadSolicitacao = { ...solicitacao }
            payloadSolicitacao.idSituacao = 1
            payloadSolicitacao.status = 1
            payloadSolicitacao.valorViagem = 0
            payloadSolicitacao.id = idSolicitacao
            payloadSolicitacao.idViagemComEtapas = novaEtapa
            put(ENDPOINTS.solicitarViagem, payloadSolicitacao)
                .then(() => {
                    localStorage.removeItem('solicitacao')
                    localStorage.removeItem('idSolicitacao')
                    localStorage.removeItem('NovaEtapa')
                    setFinished(true)
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: `${err.data}`,
                        type: 'error'
                    })
                    setLoading(false)
                })
        }
    }

    function _ShowTipoSolicitacao(value: any) {
        const item = tiposSolicitacao?.filter(item => item?.value === value)[0]
        return item?.label
    }
    function _ShowMotivo(value: any) {
        const item = motivos?.filter(item => item?.Id === value)[0]
        return item?.Descricao
    }

    function _ShowFilial(value: any) {
        const item = filiais?.filter(item => item?.Id === value)[0]
        return <>{item?.Nome}</>
    }

    function _ShowCentroDeCusto(value: any) {
        const item = centroDeCusto?.filter(item => item?.Id === value)[0]
        return <>{item?.Nome}</>
    }

    function _ShowServicos(value: any) {
        const item = servicos?.filter(item => item?.Id === value?.idServico)[0]
        return item?.Descricao
    }

    function _ShowCustomDestinoDataInicio(value: any) {
        const data = value?.dataInicio
        return <>{data}</>
    }

    function _ShowCustomDestinoDataSaida(value: any) {
        const data = value?.dataTermino
        return <>{data}</>
    }

    function _ShowValorTotalAdiantamento() {
        let valorTotal = 0
        solicitacao?.adiantamentos?.forEach((item: any) => {
            valorTotal += item.valor
        })
        return <>{IntlRealFormat(valorTotal)}</>
    }

    const renderLogoCia = (cia: string) => {
        switch (cia) {
            case 'G3':
                return (
                    <ContainerLogo>
                        <img src={GolImg} alt="Gol"></img>
                    </ContainerLogo>
                )
            case 'AD':
                return (
                    <ContainerLogo>
                        <img src={AzulImg} alt="Azul"></img>
                    </ContainerLogo>
                )
            case 'JJ':
                return (
                    <ContainerLogo>
                        <img src={LatamImg} alt="Latam"></img>
                    </ContainerLogo>
                )

            default:
                break
        }
    }

    function notFound(param: string) {
        return (
            <div
                style={{
                    height: 'auto',
                    margin: '5vh 7vh',
                    borderRadius: '1vh',
                    padding: '3.5vh',
                    boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.05)'
                }}
            >
                <p>{param}</p>
            </div>
        )
    }

    function styleTextLine(title: string, param: any) {
        return (
            <p
                style={{
                    marginBottom: '20px'
                }}
            >
                <b
                    style={{
                        marginRight: '5px'
                    }}
                >
                    {title}:
                </b>
                {param}
            </p>
        )
    }

    return (
        <BlankBoard>
            {finished ? (
                <>
                    <Wrapper>
                        <CheckMark
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 52 52"
                        >
                            <CheckmarkCircle cx="26" cy="26" r="25" />
                            <CheckMarkCheck
                                fill="none"
                                d="M14.1 27.2l7.1 7.2 16.7-16.8"
                            />
                        </CheckMark>
                        <h3 className="text-center">Solicitação Enviada!</h3>
                    </Wrapper>
                    <Center>
                        <Button
                            colorType="quaternary"
                            type="submit"
                            onClick={() => {
                                history.push('/solicitacoes')
                                window.location.reload()
                            }}
                        >
                            Início
                        </Button>
                    </Center>
                </>
            ) : (
                <>
                    <Header
                        title="Detalhes"
                        actionArrowClick={() => {
                            history.goBack()
                        }}
                    ></Header>
                    <Space height="2rem"></Space>
                    <ContainerPadding>
                        {novaEtapa !== 0 ? (
                            <Alert
                                type="primary"
                                fixed={true}
                                icon="info"
                                message={`Essa solicitação será incluída como uma nova etapa da solicitação ${novaEtapa}`}
                            />
                        ) : null}
                        <Lista
                            className="nav nav-tabs"
                            id="myTab"
                            role="tablist"
                        >
                            <li
                                className="nav-item"
                                style={navItem}
                                role="presentation"
                            >
                                <button
                                    className="nav-link active"
                                    id="info-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#info"
                                    type="button"
                                    role="tab"
                                    aria-controls="info"
                                    aria-selected="true"
                                    onClick={e => setAbaAtual('Solicitação')}
                                >
                                    <FontAwesomeIcon icon="bars" />
                                </button>
                            </li>
                            <li
                                className="nav-item"
                                style={navItem}
                                role="presentation"
                            >
                                <button
                                    className="nav-link"
                                    id="viajantes-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#viajantes"
                                    type="button"
                                    role="tab"
                                    aria-controls="viajantes"
                                    aria-selected="true"
                                    onClick={e => setAbaAtual('Viajantes')}
                                >
                                    <FontAwesomeIcon icon="users" />
                                </button>
                            </li>
                            <li
                                className="nav-item"
                                style={navItem}
                                role="presentation"
                            >
                                <button
                                    className="nav-link"
                                    id="roteiros-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#roteiros"
                                    type="button"
                                    role="tab"
                                    aria-controls="roteiros"
                                    aria-selected="true"
                                    onClick={e => setAbaAtual('Roteiros')}
                                >
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                </button>
                            </li>
                            <li
                                className="nav-item"
                                style={navItem}
                                role="presentation"
                            >
                                <button
                                    className="nav-link"
                                    id="aereo-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#aereo"
                                    type="button"
                                    role="tab"
                                    aria-controls="aereo"
                                    aria-selected="true"
                                    onClick={e => setAbaAtual('Aéreos')}
                                >
                                    <FontAwesomeIcon icon="plane" />
                                </button>
                            </li>
                            <li
                                className="nav-item"
                                style={navItem}
                                role="presentation"
                            >
                                <button
                                    className="nav-link"
                                    id="hospedagem-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#hospedagem"
                                    type="button"
                                    role="tab"
                                    aria-controls="hospedagem"
                                    aria-selected="true"
                                    onClick={e => setAbaAtual('Hospedagens')}
                                >
                                    <FontAwesomeIcon icon="hotel" />
                                </button>
                            </li>
                            <li
                                className="nav-item"
                                style={navItem}
                                role="presentation"
                            >
                                <button
                                    className="nav-link"
                                    id="servicos-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#servicos"
                                    type="button"
                                    role="tab"
                                    aria-controls="servicos"
                                    aria-selected="true"
                                    onClick={e => setAbaAtual('Serviços')}
                                >
                                    <FontAwesomeIcon icon="list-ul" />
                                </button>
                            </li>
                            <li
                                className="nav-item"
                                style={navItem}
                                role="presentation"
                            >
                                <button
                                    className="nav-link"
                                    id="adiantamento-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#adiantamento"
                                    type="button"
                                    role="tab"
                                    aria-controls="adiantamento"
                                    aria-selected="true"
                                    onClick={e => setAbaAtual('Adiantamentos')}
                                >
                                    <FontAwesomeIcon icon="dollar-sign" />
                                </button>
                            </li>
                            <li
                                className="nav-item"
                                style={navItem}
                                role="presentation"
                            >
                                <button
                                    className="nav-link"
                                    id="rateio-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#rateio"
                                    type="button"
                                    role="tab"
                                    aria-controls="rateio"
                                    aria-selected="true"
                                    onClick={e => setAbaAtual('Rateios')}
                                >
                                    <FontAwesomeIcon icon="random" />
                                </button>
                            </li>
                            <div
                                style={{
                                    fontSize: '1.2rem',
                                    display: 'flex',
                                    width: '62%',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    color: '#5F8DD3'
                                }}
                            >
                                {abaAtual}
                            </div>
                        </Lista>
                        <article className="tab-content" id="myTabContent">
                            <Infos
                                id="info"
                                className="tab-pane fade show active"
                                role="tabpanel"
                                aria-labelledby="info-tab"
                            >
                                {width && width > 768 ? (
                                    <>
                                        {styleTextLine(
                                            'Tipo de Solicitação',
                                            _ShowTipoSolicitacao(
                                                solicitacao?.idTipoSolicitacao
                                            )
                                        )}

                                        {styleTextLine(
                                            'Motivo da Viagem',
                                            _ShowMotivo(
                                                solicitacao?.idMotivoViagem
                                            )
                                        )}

                                        {styleTextLine(
                                            'Descrição',
                                            solicitacao?.descricao ||
                                                'Não preenchido'
                                        )}

                                        {styleTextLine(
                                            'Viajante Principal',
                                            solicitacao?.viajantes?.find(
                                                value => {
                                                    return (
                                                        value.Convidado ===
                                                        false
                                                    )
                                                }
                                            )?.Nome
                                        )}
                                    </>
                                ) : (
                                    <Center>
                                        <Box>
                                            <Quadro>
                                                <table
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    <TrMobile>
                                                        {getCampo(
                                                            1,
                                                            'Tipo de Solicitação:',
                                                            _ShowTipoSolicitacao(
                                                                solicitacao?.idTipoSolicitacao
                                                            )
                                                        )}

                                                        {getCampo(
                                                            1,
                                                            'Motivo da Viagem:',
                                                            _ShowMotivo(
                                                                solicitacao?.idMotivoViagem
                                                            )
                                                        )}
                                                        {getCampo(
                                                            1,
                                                            'Descrição:',
                                                            solicitacao?.descricao ||
                                                                'Não preenchido'
                                                        )}
                                                        {getCampo(
                                                            1,
                                                            'Viajante Principal:',
                                                            solicitacao?.viajantes?.find(
                                                                value => {
                                                                    return (
                                                                        value.Convidado ===
                                                                        false
                                                                    )
                                                                }
                                                            )?.Nome
                                                        )}
                                                    </TrMobile>
                                                </table>
                                            </Quadro>
                                        </Box>
                                    </Center>
                                )}
                            </Infos>
                            <div
                                id="roteiros"
                                className="tab-pane fade show"
                                role="tabpanel"
                                aria-labelledby="roteiros-tab"
                            >
                                <Infos
                                    id="info"
                                    className="tab-pane fade show active"
                                    role="tabpanel"
                                    aria-labelledby="info-tab"
                                >
                                    {width && width > 768 ? (
                                        <table
                                            className="table"
                                            style={{ color: '#6C7480' }}
                                        >
                                            <thead
                                                style={{
                                                    background: '#FFFFFF',
                                                    border: 'none !important'
                                                }}
                                            >
                                                <tr>
                                                    <th
                                                        style={{
                                                            width: '3rem'
                                                        }}
                                                    >
                                                        <p>Destino</p>
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: '2rem'
                                                        }}
                                                    >
                                                        <p>Início</p>
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: '2rem'
                                                        }}
                                                    >
                                                        <p>Término</p>
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: '2rem'
                                                        }}
                                                    >
                                                        <p>Objetivo</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                style={{
                                                    background: '#FFFFFF',
                                                    borderTop: '#DFDFDF 1px'
                                                }}
                                            >
                                                {solicitacao?.destinos?.map(
                                                    (destino: any, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td
                                                                    style={{
                                                                        width: '3rem'
                                                                    }}
                                                                >
                                                                    <p>
                                                                        {
                                                                            destino.destino
                                                                        }
                                                                    </p>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: '2rem'
                                                                    }}
                                                                >
                                                                    <p>
                                                                        {_ShowCustomDestinoDataInicio(
                                                                            destino
                                                                        )}
                                                                    </p>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: '2rem'
                                                                    }}
                                                                >
                                                                    <p>
                                                                        {' '}
                                                                        {_ShowCustomDestinoDataSaida(
                                                                            destino
                                                                        )}
                                                                    </p>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: '2rem'
                                                                    }}
                                                                >
                                                                    <p>
                                                                        {
                                                                            destino.objetivo
                                                                        }
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <>
                                            <Center>
                                                {solicitacao?.destinos?.length
                                                    ? solicitacao?.destinos?.map(
                                                          (
                                                              destino: any,
                                                              key
                                                          ) => {
                                                              return (
                                                                  <Box
                                                                      key={key}
                                                                  >
                                                                      <Quadro>
                                                                          <table
                                                                              style={{
                                                                                  width: '100%'
                                                                              }}
                                                                          >
                                                                              <TrMobile>
                                                                                  {getCampo(
                                                                                      key,
                                                                                      'Destino:',
                                                                                      destino.destino
                                                                                  )}

                                                                                  {getCampo(
                                                                                      key,
                                                                                      'Início:',
                                                                                      _ShowCustomDestinoDataInicio(
                                                                                          destino
                                                                                      )
                                                                                  )}
                                                                                  {getCampo(
                                                                                      key,
                                                                                      'Término:',
                                                                                      _ShowCustomDestinoDataSaida(
                                                                                          destino
                                                                                      )
                                                                                  )}
                                                                                  {getCampo(
                                                                                      key,
                                                                                      'Objetivo:',
                                                                                      destino.objetivo
                                                                                  )}
                                                                              </TrMobile>
                                                                          </table>
                                                                      </Quadro>
                                                                  </Box>
                                                              )
                                                          }
                                                      )
                                                    : getNotFound(
                                                          'Não possui roteiros cadastradas'
                                                      )}
                                            </Center>
                                        </>
                                    )}
                                </Infos>
                            </div>
                            <div
                                id="viajantes"
                                className="tab-pane fade show"
                                role="tabpanel"
                                aria-labelledby="viajantes-tab"
                            >
                                <Infos
                                    id="info"
                                    className="tab-pane fade show active"
                                    role="tabpanel"
                                    aria-labelledby="info-tab"
                                >
                                    {width && width > 768 ? (
                                        <table
                                            className="table"
                                            style={{ color: '#6C7480' }}
                                        >
                                            <thead
                                                style={{
                                                    background: '#FFFFFF',
                                                    border: 'none !important'
                                                }}
                                            >
                                                <tr
                                                    style={{
                                                        border: 'none !important'
                                                    }}
                                                >
                                                    <th
                                                        style={{
                                                            width: '2rem',
                                                            border: 'none !important'
                                                        }}
                                                    >
                                                        <p>Viajante</p>
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: '5rem'
                                                        }}
                                                    >
                                                        <p>Tipo de viajante</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                style={{
                                                    background: '#FFFFFF',
                                                    borderTop: '#DFDFDF 1px'
                                                }}
                                            >
                                                {solicitacao?.viajantes?.map(
                                                    (viajante, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td
                                                                    style={{
                                                                        width: '2rem'
                                                                    }}
                                                                >
                                                                    <p>{`${viajante.Codigo} - ${viajante.Nome}`}</p>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: '5rem'
                                                                    }}
                                                                >
                                                                    <p>
                                                                        {viajante.Convidado
                                                                            ? 'Convidado'
                                                                            : 'Principal'}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    ) : solicitacao?.viajantes?.length ? (
                                        solicitacao?.viajantes?.map(
                                            (viajante, key) => {
                                                return (
                                                    <Box key={key}>
                                                        <Center>
                                                            <Quadro>
                                                                <table
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                >
                                                                    <TrMobile>
                                                                        {getCampo(
                                                                            key,
                                                                            'Viajante:',
                                                                            `${viajante.Codigo} - ${viajante.Nome}`
                                                                        )}

                                                                        {getCampo(
                                                                            key,
                                                                            'Tipo de viajante:',
                                                                            viajante.Convidado
                                                                                ? 'Convidado'
                                                                                : 'Principal'
                                                                        )}
                                                                    </TrMobile>
                                                                </table>
                                                            </Quadro>
                                                        </Center>
                                                    </Box>
                                                )
                                            }
                                        )
                                    ) : (
                                        getNotFound(
                                            'Não possui viajantes cadastrados'
                                        )
                                    )}
                                </Infos>
                            </div>
                            <div
                                id="adiantamento"
                                className="tab-pane fade show"
                                role="tabpanel"
                                aria-labelledby="adiantamento-tab"
                            >
                                {width && width > 768 ? (
                                    solicitacao?.adiantamentos?.length ? (
                                        <Infos
                                            id="info"
                                            className="tab-pane fade show active"
                                            role="tabpanel"
                                            aria-labelledby="info-tab"
                                        >
                                            <table
                                                className="table"
                                                style={{ color: '#6C7480' }}
                                            >
                                                <thead
                                                    style={{
                                                        background: '#FFFFFF',
                                                        border: 'none !important'
                                                    }}
                                                >
                                                    <th
                                                        style={{
                                                            width: '1rem',
                                                            border: '0 none !important',
                                                            textAlign: 'left',
                                                            borderColor: '#fff'
                                                        }}
                                                    >
                                                        <p>Motivo</p>
                                                    </th>

                                                    <th
                                                        style={{
                                                            width: '1rem',
                                                            border: 'none !important',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <p>Tipo de divisão</p>
                                                    </th>

                                                    <th
                                                        style={{
                                                            width: '1rem',
                                                            border: 'none !important',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <p>Valor Total</p>
                                                    </th>
                                                </thead>
                                                <tbody
                                                    style={{
                                                        borderTop: '#DFDFDF 0px'
                                                    }}
                                                >
                                                    <tr
                                                        style={{
                                                            borderColor: '#fff'
                                                        }}
                                                    >
                                                        <td
                                                            style={{
                                                                width: '1rem',
                                                                textAlign:
                                                                    'left'
                                                            }}
                                                        >
                                                            <p>
                                                                {
                                                                    solicitacao
                                                                        ?.adiantamentos[0]
                                                                        .descricao
                                                                }
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '1rem',
                                                                textAlign:
                                                                    'left'
                                                            }}
                                                        >
                                                            <p>
                                                                {solicitacao
                                                                    ?.adiantamentos[0]
                                                                    .tipoDivisaoAdiantamento ===
                                                                '1'
                                                                    ? 'Adiantamento parcial'
                                                                    : 'Adiantamento personalizado'}
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '1rem',
                                                                textAlign:
                                                                    'left'
                                                            }}
                                                        >
                                                            <p>
                                                                {_ShowValorTotalAdiantamento()}
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <LineSeparator
                                                        style={{
                                                            margin: '.3rem 0'
                                                        }}
                                                    ></LineSeparator>
                                                    <Center>
                                                        <h4
                                                            style={{
                                                                color: '#6C7480'
                                                            }}
                                                        >
                                                            Divisão
                                                        </h4>
                                                    </Center>
                                                    {solicitacao?.adiantamentos?.map(
                                                        (
                                                            adiantamento: any,
                                                            key
                                                        ) => {
                                                            return (
                                                                <tr
                                                                    key={key}
                                                                    style={{
                                                                        borderColor:
                                                                            '#fff'
                                                                    }}
                                                                >
                                                                    <td
                                                                        style={{
                                                                            width: '1rem',
                                                                            textAlign:
                                                                                'left'
                                                                        }}
                                                                    >
                                                                        <p>{`${adiantamento.codigo} - ${adiantamento.nome}`}</p>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: '1rem',
                                                                            textAlign:
                                                                                'left'
                                                                        }}
                                                                    ></td>
                                                                    <td
                                                                        style={{
                                                                            width: '1rem',
                                                                            textAlign:
                                                                                'left'
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {IntlRealFormat(
                                                                                adiantamento.valor
                                                                            )}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </Infos>
                                    ) : (
                                        <Infos>
                                            Não possui adiantamento cadastrado
                                        </Infos>
                                    )
                                ) : solicitacao?.adiantamentos?.length ? (
                                    <Box>
                                        <Quadro>
                                            <table
                                                style={{
                                                    width: '100%'
                                                }}
                                            >
                                                <TrMobile>
                                                    {getCampo(
                                                        1,
                                                        'Motivo:',
                                                        solicitacao
                                                            ?.adiantamentos[0]
                                                            .descricao
                                                    )}

                                                    {getCampo(
                                                        1,
                                                        'Tipo de divisão:',
                                                        solicitacao
                                                            ?.adiantamentos[0]
                                                            .tipoDivisaoAdiantamento ===
                                                            '1'
                                                            ? 'Adiantamento parcial'
                                                            : 'Adiantamento personalizado'
                                                    )}
                                                    {getCampo(
                                                        1,
                                                        'Valor total:',
                                                        _ShowValorTotalAdiantamento()
                                                    )}

                                                    <Center>
                                                        <Info>Divisão</Info>
                                                    </Center>

                                                    {solicitacao?.adiantamentos?.map(
                                                        (
                                                            adiantamento: any,
                                                            index: any
                                                        ) => {
                                                            return (
                                                                <TrMobile
                                                                    key={index}
                                                                >
                                                                    <Separador>
                                                                        {getCampo(
                                                                            index,
                                                                            'Viajante',
                                                                            ``
                                                                        )}
                                                                        {getCampo(
                                                                            index,
                                                                            'Valor',
                                                                            ''
                                                                        )}
                                                                    </Separador>
                                                                    <Separador>
                                                                        {getCampo(
                                                                            index,
                                                                            '',
                                                                            `${adiantamento.codigo} - ${adiantamento.nome}`
                                                                        )}
                                                                        {getCampo(
                                                                            index,
                                                                            '',
                                                                            MoneyFormat(
                                                                                adiantamento.valor
                                                                            )
                                                                        )}
                                                                    </Separador>
                                                                </TrMobile>
                                                            )
                                                        }
                                                    )}
                                                </TrMobile>
                                            </table>
                                        </Quadro>
                                    </Box>
                                ) : (
                                    getNotFound(
                                        'Não possui adiantamento cadastrado'
                                    )
                                )}
                            </div>
                            <div
                                id="aereo"
                                className="tab-pane fade show"
                                role="tabpanel"
                                aria-labelledby="aereo-tab"
                            >
                                {
                                    <>
                                        {reservas.length > 0 ? (
                                            width && width > 768 ? (
                                                <Infos>
                                                    {reservas.map(
                                                        (reserva, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        border: '1px solid #BDBDBD',
                                                                        margin: '1rem 1rem',
                                                                        padding:
                                                                            '1rem'
                                                                    }}
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-3 title-passagem">
                                                                            Origem
                                                                        </div>
                                                                        <div className="col-3 title-passagem">
                                                                            Destino
                                                                        </div>
                                                                        <div className="col-2 title-passagem">
                                                                            Escalas
                                                                        </div>
                                                                        <div className="col-2 title-passagem">
                                                                            Companhia
                                                                        </div>
                                                                        <div className="col-2 title-passagem">
                                                                            Valor
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            color: '#5F8DD3',
                                                                            fontSize:
                                                                                'medium'
                                                                        }}
                                                                    >
                                                                        <div className="col-3">
                                                                            {idaVolta ? (
                                                                                <div>
                                                                                    <div className="row">
                                                                                        <div className="col-2">
                                                                                            {
                                                                                                reserva.AeroportoOrigem
                                                                                            }
                                                                                        </div>
                                                                                        <div
                                                                                            className="col"
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    '.5rem'
                                                                                            }}
                                                                                        >
                                                                                            {reserva.DataIdaSaida?.toLocaleDateString(
                                                                                                'pt-BR',
                                                                                                {
                                                                                                    hour: '2-digit',
                                                                                                    minute: '2-digit'
                                                                                                }
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-2">
                                                                                            {
                                                                                                reserva.AeroportoDestino
                                                                                            }
                                                                                        </div>
                                                                                        <div
                                                                                            className="col"
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    '.5rem'
                                                                                            }}
                                                                                        >
                                                                                            {reserva.DataVoltaSaida?.toLocaleDateString(
                                                                                                'pt-BR',
                                                                                                {
                                                                                                    hour: '2-digit',
                                                                                                    minute: '2-digit'
                                                                                                }
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="row">
                                                                                    <div className="col-2">
                                                                                        {
                                                                                            reserva.AeroportoOrigem
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        className="col"
                                                                                        style={{
                                                                                            marginLeft:
                                                                                                '.5rem'
                                                                                        }}
                                                                                    >
                                                                                        {reserva.DataIdaSaida?.toLocaleDateString(
                                                                                            'pt-BR',
                                                                                            {
                                                                                                hour: '2-digit',
                                                                                                minute: '2-digit'
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-3">
                                                                            {idaVolta ? (
                                                                                <div>
                                                                                    <div className="row">
                                                                                        <div className="col-2">
                                                                                            {
                                                                                                reserva.AeroportoDestino
                                                                                            }
                                                                                        </div>
                                                                                        <div
                                                                                            className="col"
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    '.5rem'
                                                                                            }}
                                                                                        >
                                                                                            {reserva.DataIdaChegada?.toLocaleDateString(
                                                                                                'pt-BR',
                                                                                                {
                                                                                                    hour: '2-digit',
                                                                                                    minute: '2-digit'
                                                                                                }
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-2">
                                                                                            {
                                                                                                reserva.AeroportoOrigem
                                                                                            }
                                                                                        </div>
                                                                                        <div
                                                                                            className="col"
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    '.5rem'
                                                                                            }}
                                                                                        >
                                                                                            {reserva.DataVoltaChegada?.toLocaleDateString(
                                                                                                'pt-BR',
                                                                                                {
                                                                                                    hour: '2-digit',
                                                                                                    minute: '2-digit'
                                                                                                }
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="row">
                                                                                    <div className="col-2">
                                                                                        {
                                                                                            reserva.AeroportoDestino
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        className="col"
                                                                                        style={{
                                                                                            marginLeft:
                                                                                                '.5rem'
                                                                                        }}
                                                                                    >
                                                                                        {reserva.DataIdaChegada?.toLocaleDateString(
                                                                                            'pt-BR',
                                                                                            {
                                                                                                hour: '2-digit',
                                                                                                minute: '2-digit'
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className="col-2"
                                                                            style={{
                                                                                alignContent:
                                                                                    'center',
                                                                                display:
                                                                                    'flex',
                                                                                alignItems:
                                                                                    'center'
                                                                            }}
                                                                        >
                                                                            {reserva.QtdEscalas ===
                                                                            1 ? (
                                                                                <div>
                                                                                    {
                                                                                        reserva.QtdEscalas
                                                                                    }{' '}
                                                                                    PARADAS
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    {
                                                                                        reserva.QtdEscalas
                                                                                    }{' '}
                                                                                    PARADA
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className="col-2"
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                alignItems:
                                                                                    'center',
                                                                                justifyContent:
                                                                                    'center'
                                                                            }}
                                                                        >
                                                                            {renderLogoCia(
                                                                                reserva.Companhia ||
                                                                                    ''
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className="col-2"
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                alignItems:
                                                                                    'center',
                                                                                fontWeight:
                                                                                    'bold',
                                                                                color: '#002A5D'
                                                                            }}
                                                                        >
                                                                            {/* {
                                                                            reserva.Valor ||
                                                                                0
                                                                        )} */}
                                                                            {MoneyFormat(
                                                                                reserva.Valor ||
                                                                                    0
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                                </Infos>
                                            ) : (
                                                reservas.length > 0 &&
                                                getReservaMobile(reservas)
                                            )
                                        ) : width && width > 768 ? (
                                            notFound(
                                                'Não possui Aereo cadastradas'
                                            )
                                        ) : (
                                            <Center>
                                                {getNotFound(
                                                    'Não possui Aereo cadastradas'
                                                )}
                                            </Center>
                                        )}
                                    </>
                                }
                            </div>
                            <div
                                id="hospedagem"
                                className="tab-pane fade show"
                                role="tabpanel"
                                aria-labelledby="hospedagem-tab"
                            >
                                {solicitacao?.hospedagem?.length ? (
                                    <Infos
                                        id="info"
                                        className="tab-pane fade show active"
                                        role="tabpanel"
                                        aria-labelledby="info-tab"
                                    >
                                        {width && width > 768 ? (
                                            <table
                                                className="table"
                                                style={{ color: '#6C7480' }}
                                            >
                                                <thead
                                                    style={{
                                                        background: '#FFFFFF',
                                                        border: 'none !important'
                                                    }}
                                                >
                                                    <tr
                                                        style={{
                                                            border: 'none !important'
                                                        }}
                                                    >
                                                        <th
                                                            style={{
                                                                width: '3rem',
                                                                border: 'none !important'
                                                            }}
                                                        >
                                                            <p>Cidade</p>
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '5rem'
                                                            }}
                                                        >
                                                            <p>
                                                                Ponto de
                                                                referência
                                                            </p>
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '2rem'
                                                            }}
                                                        >
                                                            <p>Check-in</p>
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '2rem'
                                                            }}
                                                        >
                                                            <p>Check-out</p>
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '5rem'
                                                            }}
                                                        >
                                                            <p>Observação</p>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody
                                                    style={{
                                                        background: '#FFFFFF',
                                                        borderTop: '#DFDFDF 1px'
                                                    }}
                                                >
                                                    {solicitacao?.hospedagem?.map(
                                                        (hospedagem, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td
                                                                        style={{
                                                                            width: '3rem'
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                hospedagem.Cidade
                                                                            }
                                                                        </p>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: '5rem'
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                hospedagem.Referencia
                                                                            }
                                                                        </p>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: '2rem'
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                hospedagem.DataEntrada
                                                                            }
                                                                        </p>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: '2rem'
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                hospedagem.DataSaida
                                                                            }
                                                                        </p>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: '5rem'
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                hospedagem.Observacao
                                                                            }
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <>
                                                <Center>
                                                    {solicitacao?.hospedagem?.map(
                                                        (hospedagem, key) => (
                                                            <Box key={key}>
                                                                <Quadro>
                                                                    <table
                                                                        style={{
                                                                            width: '100%'
                                                                        }}
                                                                    >
                                                                        <TrMobile>
                                                                            {getCampo(
                                                                                key,
                                                                                'Cidade:',
                                                                                hospedagem.Cidade
                                                                            )}
                                                                            {getCampo(
                                                                                key,
                                                                                'Ponto de referência:',
                                                                                hospedagem.Referencia
                                                                            )}
                                                                            {getCampo(
                                                                                key,
                                                                                'Check-in:',
                                                                                hospedagem.DataEntrada
                                                                            )}
                                                                            {getCampo(
                                                                                key,
                                                                                'Check-out:',
                                                                                hospedagem.DataSaida
                                                                            )}
                                                                            {getCampo(
                                                                                key,
                                                                                'Observação:',
                                                                                hospedagem.Observacao
                                                                            )}
                                                                        </TrMobile>
                                                                    </table>
                                                                </Quadro>
                                                            </Box>
                                                        )
                                                    )}
                                                </Center>
                                            </>
                                        )}
                                    </Infos>
                                ) : width && width > 768 ? (
                                    <Infos>
                                        Não possui Hospedagens cadastradas
                                    </Infos>
                                ) : (
                                    getNotFound(
                                        'Não possui Hospedagens cadastradas'
                                    )
                                )}
                            </div>
                            <div
                                id="servicos"
                                className="tab-pane fade show"
                                role="tabpanel"
                                aria-labelledby="servicos-tab"
                            >
                                {solicitacao?.servico?.length ? (
                                    <Infos
                                        className="tab-pane fade show active"
                                        role="tabpanel"
                                        aria-labelledby="info-tab"
                                    >
                                        {width && width > 768 ? (
                                            <table
                                                className="table"
                                                style={{ color: '#6C7480' }}
                                            >
                                                <thead
                                                    style={{
                                                        background: '#FFFFFF',
                                                        border: 'none !important'
                                                    }}
                                                >
                                                    <tr
                                                        style={{
                                                            border: 'none !important'
                                                        }}
                                                    >
                                                        <th
                                                            style={{
                                                                width: '1rem',
                                                                border: 'none !important',
                                                                textAlign:
                                                                    'left'
                                                            }}
                                                        >
                                                            <p>Serviço</p>
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '1rem',
                                                                border: 'none !important',
                                                                textAlign:
                                                                    'left'
                                                            }}
                                                        >
                                                            <p>Observação</p>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody
                                                    style={{
                                                        background: '#FFFFFF',
                                                        borderTop: '#DFDFDF 1px'
                                                    }}
                                                >
                                                    {solicitacao?.servico?.map(
                                                        (servico: any, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td
                                                                        style={{
                                                                            width: '1rem',
                                                                            textAlign:
                                                                                'left'
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {_ShowServicos(
                                                                                servico
                                                                            )}
                                                                        </p>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: '1rem',
                                                                            textAlign:
                                                                                'left'
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                servico.observacao
                                                                            }
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <Center>
                                                {solicitacao?.servico?.map(
                                                    (servico: any, key) => (
                                                        <Box key={key}>
                                                            <Quadro>
                                                                <table
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                >
                                                                    <TrMobile>
                                                                        {getCampo(
                                                                            key,
                                                                            'Serviço:',
                                                                            _ShowServicos(
                                                                                servico
                                                                            )
                                                                        )}
                                                                        {getCampo(
                                                                            key,
                                                                            'Observação:',
                                                                            servico.observacao
                                                                        )}
                                                                    </TrMobile>
                                                                </table>
                                                            </Quadro>
                                                        </Box>
                                                    )
                                                )}
                                            </Center>
                                        )}
                                    </Infos>
                                ) : width && width > 768 ? (
                                    <Infos>
                                        Não possui Serviços cadastrados
                                    </Infos>
                                ) : (
                                    getNotFound(
                                        'Não possui Serviços cadastrados'
                                    )
                                )}
                            </div>
                            <div
                                id="rateio"
                                className="tab-pane fade show"
                                role="tabpanel"
                                aria-labelledby="rateio-tab"
                            >
                                <Infos
                                    className="tab-pane fade show active"
                                    role="tabpanel"
                                    aria-labelledby="info-tab"
                                >
                                    {width && width > 768 ? (
                                        <table
                                            className="table"
                                            style={{ color: '#6C7480' }}
                                        >
                                            <thead
                                                style={{
                                                    background: '#FFFFFF',
                                                    border: 'none !important'
                                                }}
                                            >
                                                <tr
                                                    style={{
                                                        border: 'none !important'
                                                    }}
                                                >
                                                    <th
                                                        style={{
                                                            width: '1rem',
                                                            border: 'none !important',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <p>Filial</p>
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: '1rem',
                                                            border: 'none !important',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <p>Conta Contábil</p>
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: '1rem',
                                                            border: 'none !important'
                                                        }}
                                                    >
                                                        <p>Percentual (%)</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                style={{
                                                    background: '#FFFFFF',
                                                    borderTop: '#DFDFDF 1px'
                                                }}
                                            >
                                                {solicitacao?.rateio?.map(
                                                    (rateio: any, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td
                                                                    style={{
                                                                        width: '1rem',
                                                                        textAlign:
                                                                            'left'
                                                                    }}
                                                                >
                                                                    <p>
                                                                        {_ShowFilial(
                                                                            rateio.IdFilial
                                                                        )}
                                                                    </p>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: '1rem',
                                                                        textAlign:
                                                                            'left'
                                                                    }}
                                                                >
                                                                    <p>
                                                                        {_ShowCentroDeCusto(
                                                                            rateio.IdContasContabeis
                                                                        )}
                                                                    </p>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: '1rem'
                                                                    }}
                                                                >
                                                                    <p>
                                                                        {' '}
                                                                        {
                                                                            rateio.Percentual
                                                                        }
                                                                        %
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    ) : solicitacao?.rateio?.length ? (
                                        <Center>
                                            {solicitacao?.rateio?.map(
                                                (rateio: any, key) => {
                                                    return (
                                                        <Box key={key}>
                                                            <Quadro>
                                                                <table
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                >
                                                                    <TrMobile>
                                                                        {getCampo(
                                                                            key,
                                                                            'Filial:',
                                                                            _ShowFilial(
                                                                                rateio.IdFilial
                                                                            )
                                                                        )}
                                                                        <Center>
                                                                            {getCampo(
                                                                                key,
                                                                                'Conta Contábil',
                                                                                ''
                                                                            )}
                                                                        </Center>

                                                                        {getCampo(
                                                                            key,
                                                                            '',
                                                                            _ShowCentroDeCusto(
                                                                                rateio.IdContasContabeis
                                                                            )
                                                                        )}
                                                                        {getCampo(
                                                                            key,
                                                                            'Percentual (%):',
                                                                            `${rateio.Percentual} %`
                                                                        )}
                                                                    </TrMobile>
                                                                </table>
                                                            </Quadro>
                                                        </Box>
                                                    )
                                                }
                                            )}
                                        </Center>
                                    ) : (
                                        getNotFound(
                                            'Não possui rateio cadastrado'
                                        )
                                    )}
                                </Infos>
                            </div>
                        </article>
                        <Space height="3rem"></Space>
                        <Center>
                            <DisableForMobile>
                                <Button
                                    colorType="quaternary"
                                    type="submit"
                                    onClick={() => finish()}
                                >
                                    Enviar
                                </Button>
                            </DisableForMobile>
                            <IsMobile>
                                <Button
                                    colorType="btnMobile"
                                    type="submit"
                                    onClick={() => finish()}
                                >
                                    Enviar
                                </Button>
                            </IsMobile>
                        </Center>
                    </ContainerPadding>
                </>
            )}
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </BlankBoard>
    )
}

const navItem = {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2.0vh'
}

const Infos = styled.div`
    height: auto;
    margin: 5vh 7vh;
    padding: 3.5vh;
    border-radius: 9px;
    box-shadow: 0px 0px 6px 0px #00000026;
    font-size: 1.8vh;
    @media (max-width: 768px) {
        box-shadow: 0px 0px 6px 0px #fff;
    }
`

const Wrapper = styled.div`
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    flex-direction: column;
    margin-bottom: 5vh;
`

const CheckmarkCircle = styled.circle`
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: ${props => props.theme.colors.primary};
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
`

const CheckMark = styled.svg`
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px ${props => props.theme.colors.primary};
    animation: fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
`

const CheckMarkCheck = styled.path`
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
`

const ButtonAereo = styled.button`
    padding: 0 2.5vh !important;
    margin: 0;
`

const ContainerLogo = styled.div`
    width: 80px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: end;
    align-items: left;
    margin: 0 auto;

    img {
        width: 100%;
    }
`
const IsMobile = styled.span`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`
const DisableForMobile = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`

import { Form } from '@unform/web'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button'
import { Center } from '../../../core/components/Center'
import { Input } from '../../../core/components/Form/Input'
import { Header } from '../../../core/components/Header/Header'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Subtitle } from '../../../core/components/Subtitle'
import { Alert } from '../../../core/components/Alert'
import { UserContext } from '../../../contexts/UserContext'
import { ENDPOINTS } from '../../../services/config-http'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Flex } from '../../../core/components/Flex'
import {
    TableRequester,
    MapperItem,
    ConfigTable,
    Table
} from './../../../core/components/Table/v2/Table'

import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DisableForMobile, IsMobile } from '../../Viagem/Solicitacao/stepMobile'

const ContaContabil = () => {
    const FormRef = useRef<FormHandles>(null)
    const history = useHistory()
    const [payloadSearch, setPayloadSearch] = useState<TableRequester>({
        endpoint: ENDPOINTS.pesquisarContasContabeis,
        method: 'GET',
        payload: {}
    })

    const mapper: MapperItem[] = [
        { field: 'Id', position: 1, delete: true },
        { field: 'Nome', position: 2 },
        {
            field: 'ContaPadrao',
            replaceTo: 'Conta Padrão',
            visualCustom: _listStage,
            position: 3
        }
    ]
    const tableConfig: ConfigTable = {
        edit: false,
        delete: false,
        moreOptions: [
            {
                label: ' Editar',
                icon: 'file-signature',
                color: '#002A5D',
                action: (item: any) => {
                    history.push(
                        `/cadastros/contas-contabeis/${item.Id}/editar`
                    )
                }
            }
        ]
    }

    const [administrador, setAdministrador] = useState(false)

    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    const handleFilterSubmit: SubmitHandler<FormData> = (data: any) => {
        Object.keys(data).forEach(
            k => !data[k] && data[k] !== undefined && delete data[k] // eliminando valores não preenchidos para envio da query de busca
        )
        data.page = 0
        data.lastCount = 0
        data.totalRegisterPage = 10
        data.IdSituacao = 6
        const newPayloadSearch = { ...payloadSearch, payload: data }
        setPayloadSearch(newPayloadSearch)
    }

    function _listStage(value: number): JSX.Element {
        if (value === 1) {
            return (
                <span
                    className="badge status-success"
                    style={{ width: '100px', margin: 'auto' }}
                >
                    <FontAwesomeIcon icon="circle" />
                    &ensp; {'Conta padrão'}
                </span>
            )
        } else {
            return <>-</>
        }
    }

    return (
        <div>
            <BlankBoard>
                <Header
                    title="Conta contábeis"
                    actionArrowClick="/solicitacoes"
                ></Header>
                <Center>
                    <Subtitle>Dados conta contábeis</Subtitle>
                </Center>
                <Flex justifyContent="flex-end">
                    <DisableForMobile>
                        <Button
                            style={{
                                marginRight: '-3.2rem'
                            }}
                            icon={'plus'}
                            colorType="quaternary"
                            onClick={() =>
                                history.push(
                                    '/cadastros/contas-contabeis/Cadastro'
                                )
                            }
                        >
                            Cadastrar
                        </Button>
                    </DisableForMobile>
                </Flex>
                <Center>
                    <IsMobile>
                        <Button
                            icon={'plus'}
                            colorType="btnMobile"
                            onClick={() =>
                                history.push(
                                    '/cadastros/contas-contabeis/Cadastro'
                                )
                            }
                        >
                            Cadastrar
                        </Button>
                    </IsMobile>
                </Center>
                {administrador ? (
                    <>
                        <LineSeparator></LineSeparator>
                        <Form
                            onSubmit={handleFilterSubmit}
                            ref={FormRef}
                            style={{ padding: 0 }}
                        >
                            <div className="row">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: '1rem'
                                    }}
                                >
                                    <Button
                                        style={{
                                            margin: '0 .2rem',
                                            padding: '0 15px'
                                        }}
                                        onlyIcon={true}
                                        icon="search"
                                        title="Pesquisar"
                                        colorType="primary"
                                    ></Button>
                                </div>
                                <div className="col-12 col-sm-3">
                                    <Input
                                        placeholder="Nome"
                                        label="Nome"
                                        name="Nome"
                                        maxLength={50}
                                    ></Input>
                                </div>
                            </div>
                        </Form>
                        <Table
                            requester={payloadSearch}
                            mapper={mapper}
                            config={tableConfig}
                        ></Table>
                    </>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                )}
            </BlankBoard>
        </div>
    )
}

export default ContaContabil

import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import {
    CotacaoHospedagem as ICotacaoHospedagem,
    Hospedagem
} from '../../../contexts/interfaces/hospedagem'
import { Space } from '../../../core/components/Space'
import { destroy, get, post, put } from '../../../services/http'
import { ENDPOINTS } from '../../../services/config-http'
import { useParams } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Center } from '../../../core/components/Center'
import { Empty } from '../../../core/components/Empty'
import { useAuth } from '../../../contexts/Auth'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { Deletar } from '../../../core/components/Deletar'
import { Toast } from '../../../core/components/Toast'
import { Modal } from '../../../core/components/Modal/Modal'
import { Input } from '../../../core/components/Form/Input'
import { Form } from '@unform/web'
import { InputPlaces } from '../../../core/components/InputPlace'
import { DatePicker } from '../../../core/components/DatePicker'
import { MoneyFormat } from '../../../core/utils/MoneyFormat'
import { Roteiro } from '../../../contexts/interfaces/roteiro'
import { UserContext } from '../../../contexts/UserContext'
import { Alert } from '../../../core/components/Alert'
import { Button } from '../../../core/components/Button'
import { InputCurrency } from '../../../core/components/Form/InputCurrency'
import { IntlRealFormat } from '../../../core/utils/IntlFormat'
import styled from 'styled-components'

export const CotacaoHospedagem = () => {
    const { user } = useAuth()
    const formRef = useRef<FormHandles>(null)
    const [valor, setValor] = useState(0)
    const [idUser, setIdUser] = useState<number>()
    const { Id } = useParams() as any
    const [hospedagens, setHospedagens] = useState<Hospedagem[]>([])
    const [roteiro, setRoteiro] = useState<Roteiro[]>([
        {
            DataInicio: new Date().toLocaleDateString(),
            DataTermino: new Date().toLocaleDateString()
        }
    ])
    const [qtdViajantes, setQtdViajantes] = useState(0)
    const [showToast, setShowToast] = useState(false)
    const [posicaoHospedagem, setPosicaoHospedagem] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [cotando, setCotando] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })
    const formCotacaoRef = useRef<FormHandles>(null)
    const formHospedagemRef = useRef<FormHandles>(null)

    const [administrador, setAdministrador] = useState(false)
    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        setShowModal(false)
        get(
            `${ENDPOINTS.hospedagemByIdSolicitacao.replace(
                ':idSolicitacao',
                Id
            )}`
        )
            .then(({ data: { Model } }) => {
                setHospedagens(Model)
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    message: 'Erro ao buscar hospedagens',
                    type: 'success'
                })
                return err
            })
        get(`${ENDPOINTS.roteiroByIdSolicitacao}?IdSolicitacaoViagem=${Id}`)
            .then(({ data }) => {
                setRoteiro(data)
                // console.log(data)
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    message: 'Erro ao buscar roteiro',
                    type: 'success'
                })
                return err
            })
        get(`${ENDPOINTS.viajanteByIdSolicitacao}/${Id}`)
            .then(({ data: { Model } }) => {
                setQtdViajantes(Model.length)
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    message: 'Erro ao buscar quantidade de viajantes',
                    type: 'success'
                })
                return err
            })
        if (user?.codigo) {
            get(`${ENDPOINTS.usuarioByCodigo.replace(':Codigo', user.codigo)}`)
                .then(({ data: { Model } }) => {
                    const { Id } = Model
                    setIdUser(Id)
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao buscar usuário',
                        type: 'success'
                    })
                    return err
                })
        }
    }, [])

    const handleSubmitHospedagem: SubmitHandler = async (data: any) => {
        try {
            const schema = Yup.object().shape({
                cidade: Yup.string().required('O Campo Cidade é Obrigatório!'),
                referencia: Yup.string().required(
                    'O Campo Ponto de referencia é Obrigatório!'
                ),
                dataEntrada: Yup.date()
                    .nullable()
                    .required('O Campo Check-in é Obrigatório!'),
                dataSaida: Yup.date()
                    .nullable()
                    .required('O Campo Check-out é Obrigatório!')
            })

            await schema.validate(data, { abortEarly: false })
            const newHospedagem: Hospedagem = {
                Cidade: data.cidade,
                Referencia: data.referencia,
                DataEntrada: data.dataEntrada.toLocaleDateString(),
                DataSaida: data.dataSaida.toLocaleDateString(),
                Observacao: data.observacao,
                IdSolicitacaoViagem: Id
            }

            post(ENDPOINTS.hospedagem, newHospedagem)
                .then(({ data: { Model } }) => {
                    formHospedagemRef.current?.reset()
                    newHospedagem.Id = Model
                    setHospedagens([...hospedagens, newHospedagem])
                    setShowModal(false)
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao incluir hospedagem',
                        type: 'error'
                    })
                    return err
                })
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                formHospedagemRef.current?.setErrors(erros)
            }
        }
    }

    const handleSubmitDeleteHospedagem = (positionHospedagem: number) => {
        const newHospedagem = hospedagens.splice(positionHospedagem, 1)[0]
        if (
            newHospedagem.CotacaoHospedagem !== null &&
            newHospedagem.CotacaoHospedagem !== undefined
        ) {
            destroy(
                `${ENDPOINTS.cotacaoHospedagem}/${newHospedagem.CotacaoHospedagem?.Id}`
            )
                .then(() => {
                    destroy(`${ENDPOINTS.hospedagem}/${newHospedagem.Id}`)
                        .then(() => {
                            setHospedagens([...hospedagens])
                        })
                        .catch(err => {
                            setShowToast(true)
                            setToast({
                                message: 'Erro ao deletar hospedagem',
                                type: 'error'
                            })
                            return err
                        })
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao deletar cotação',
                        type: 'error'
                    })
                    return err
                })
        } else {
            destroy(`${ENDPOINTS.hospedagem}/${newHospedagem.Id}`)
                .then(() => {
                    setHospedagens([...hospedagens])
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao deletar hospedagem',
                        type: 'error'
                    })
                    return err
                })
        }
    }

    const handleSubmitDeleteCotacoesHospedagem = (
        positionHospedagem: number
    ) => {
        const newHospedagem = hospedagens.splice(positionHospedagem, 1)[0]
        destroy(
            `${ENDPOINTS.cotacaoHospedagem}/${newHospedagem.CotacaoHospedagem?.Id}`
        )
            .then(() => {
                newHospedagem.CotacaoHospedagem = undefined
                setHospedagens([...hospedagens, newHospedagem])
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    message: 'Erro ao deletar cotação',
                    type: 'error'
                })
                return err
            })
    }

    const handleSubmitCotacao: SubmitHandler = async (data, { reset }) => {
        try {
            const schema = Yup.object().shape({
                valor: Yup.string().required('O Valor é obrigatório'),
                endereco: Yup.string().required('O Endereço é obrigatório'),
                nomeHotel: Yup.string().required(
                    'O Nome do hotel é obrigatório'
                ),
                ddd: Yup.string().required('O DDD é obrigatório'),
                telefoneHotel: Yup.string().required(
                    'O Telefone é obrigatório'
                ),
                codigoReserva: Yup.string().required(
                    'O Código da reserva é obrigatório'
                )
            })

            // Verificação - Caso exista algum erro, o fluxo cai no catch que fará a amostragem dos erros na tela
            await schema.validate(data, { abortEarly: false })
            formRef.current?.reset()
            const {
                codigoReserva,
                ddd,
                endereco,
                nomeHotel,
                observacao,
                telefoneHotel
            } = data
            const newHospedagem = hospedagens.splice(posicaoHospedagem, 1)[0]
            const cotacao: ICotacaoHospedagem = {
                IdHospedagem: newHospedagem.Id,
                Observacao: observacao,
                CodigoReserva: codigoReserva,
                DDD: ddd,
                Endereco: endereco,
                NomeHotel: nomeHotel,
                TelefoneHotel: telefoneHotel,
                ValorCotado: valor,
                IdUsuarioCotacao: idUser
            }
            post(`${ENDPOINTS.cotacaoHospedagem}`, cotacao)
                .then(({ data: { Model } }) => {
                    cotacao.Id = Model
                    newHospedagem.CotacaoHospedagem = cotacao
                    setHospedagens([...hospedagens, newHospedagem])
                    setShowModal(false)
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao buscar quantidade de viajantes',
                        type: 'success'
                    })
                    return err
                })
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                formCotacaoRef.current?.setErrors(erros)
            }
        }
    }

    return (
        <>
            {administrador ? (
                <>
                    <div
                        className="col-12 d-flex justify-content-end"
                        style={{ padding: '15px 0' }}
                    >
                        <Button
                            type="button"
                            onClick={e => {
                                setShowModal(true)
                                setCotando(false)
                            }}
                            colorType="quaternary"
                            icon="plus"
                        >
                            Adicionar
                        </Button>
                    </div>
                    <Space height="2rem"></Space>
                    <div className="row">
                        {hospedagens?.length ? (
                            hospedagens?.map((hospedagem, index) => (
                                <div key={index} className="content">
                                    <div
                                        className="col-12"
                                        style={{
                                            padding: '1rem',
                                            border: 'solid 1px lightgray',
                                            borderRadius: '7px',
                                            marginBottom: '2rem'
                                        }}
                                    >
                                        <div
                                            className="row"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div className="col-4">
                                                <label>
                                                    <Text>Cidade</Text>
                                                </label>
                                                <p>{hospedagem.Cidade}</p>
                                            </div>
                                            <div className="col-4">
                                                <label>
                                                    <Text>Referência</Text>
                                                </label>
                                                <p>{hospedagem.Referencia}</p>
                                            </div>
                                            <div className="col-1">
                                                {hospedagem.CotacaoHospedagem ===
                                                    null ||
                                                hospedagem.CotacaoHospedagem ===
                                                    undefined ? (
                                                    <button
                                                        onClick={e => {
                                                            setShowModal(true)
                                                            setCotando(true)
                                                            setPosicaoHospedagem(
                                                                index
                                                            )
                                                        }}
                                                        style={{
                                                            backgroundColor:
                                                                '#002a5d',
                                                            color: '#fff',
                                                            border: 0,
                                                            padding:
                                                                '0.3rem 0.8rem',
                                                            borderRadius: '15px'
                                                        }}
                                                    >
                                                        Cotar{' '}
                                                        <FontAwesomeIcon icon="plus" />
                                                    </button>
                                                ) : (
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                '#c2c2c2',
                                                            color: '#fff',
                                                            border: 0,
                                                            padding:
                                                                '0.3rem 0.8rem',
                                                            borderRadius: '15px'
                                                        }}
                                                    >
                                                        Cotar{' '}
                                                        <FontAwesomeIcon icon="plus" />
                                                    </button>
                                                )}
                                            </div>
                                            <div
                                                className="col-3"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <Deletar
                                                    remover={() => {
                                                        handleSubmitDeleteHospedagem(
                                                            index
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div className="col-2">
                                                <label>
                                                    <Text>Data Entrada</Text>
                                                </label>
                                                <p>
                                                    {new Date(
                                                        hospedagem.DataEntrada ||
                                                            ''
                                                    ).toLocaleDateString()}
                                                </p>
                                            </div>
                                            <div className="col-2">
                                                <label>
                                                    <Text>Data Saida</Text>
                                                </label>
                                                <p>
                                                    {new Date(
                                                        hospedagem.DataSaida ||
                                                            ''
                                                    ).toLocaleDateString()}
                                                </p>
                                            </div>
                                            <div className="col-4">
                                                <label>
                                                    <Text>Observação</Text>
                                                </label>
                                                <p>{hospedagem.Observacao}</p>
                                            </div>
                                            <div className="col-2">
                                                <label>
                                                    <Text>Qtd. Pessoas</Text>
                                                </label>
                                                <p>{qtdViajantes}</p>
                                            </div>
                                        </div>
                                        <LineSeparator
                                            style={{ margin: '.3rem 0' }}
                                        ></LineSeparator>
                                        <Center>
                                            <h4 style={{ color: '#6C7480' }}>
                                                Cotação
                                            </h4>
                                        </Center>
                                        {hospedagem.CotacaoHospedagem !==
                                            null &&
                                        hospedagem.CotacaoHospedagem !==
                                            undefined ? (
                                            <>
                                                <div className="row">
                                                    <div className="col col-sm-2">
                                                        <label>
                                                            <Text>
                                                                Cód reserva
                                                            </Text>
                                                        </label>
                                                        <p>
                                                            {
                                                                hospedagem
                                                                    .CotacaoHospedagem
                                                                    ?.CodigoReserva
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="col col-sm-5">
                                                        <label>
                                                            <Text>
                                                                Observação da
                                                                cotação
                                                            </Text>
                                                        </label>
                                                        <p>
                                                            {
                                                                hospedagem
                                                                    .CotacaoHospedagem
                                                                    ?.Observacao
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="col col-sm-2">
                                                        <label>
                                                            <Text>
                                                                Valor Total
                                                            </Text>
                                                        </label>
                                                        <p>
                                                            {IntlRealFormat(
                                                                Number(
                                                                    hospedagem
                                                                        .CotacaoHospedagem
                                                                        ?.ValorCotado
                                                                )
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className="col col-sm-3"
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <Deletar
                                                            remover={() => {
                                                                handleSubmitDeleteCotacoesHospedagem(
                                                                    index
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col col-sm-2">
                                                        <label>
                                                            <Text>
                                                                Telefone
                                                            </Text>
                                                        </label>
                                                        <p>
                                                            {` (${hospedagem.CotacaoHospedagem?.DDD})${hospedagem.CotacaoHospedagem?.TelefoneHotel}`}
                                                        </p>
                                                    </div>
                                                    <div className="col col-sm-5">
                                                        <label>
                                                            <Text>
                                                                Endereço
                                                            </Text>
                                                        </label>
                                                        <p>
                                                            {
                                                                hospedagem
                                                                    .CotacaoHospedagem
                                                                    ?.Endereco
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="col col-sm-4">
                                                        <label>
                                                            <Text>Hotel</Text>
                                                        </label>
                                                        <p>
                                                            {
                                                                hospedagem
                                                                    .CotacaoHospedagem
                                                                    ?.NomeHotel
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <Center>
                                                <Empty text="Não há cotação cadastrada" />
                                            </Center>
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <Center>
                                <div
                                    className="alert alert-info d-flex align-items-center mb-3 mt-2"
                                    role="alert"
                                >
                                    Nenhuma hospedagem registrada<div></div>
                                </div>
                            </Center>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <br />
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                </>
            )}
            {showModal ? (
                <Modal
                    title={cotando ? 'Nova cotação' : 'Nova hospedagem'}
                    setShow={setShowModal}
                    height={cotando ? '29rem' : '32rem'}
                >
                    {cotando ? (
                        <Form
                            onSubmit={handleSubmitCotacao}
                            ref={formCotacaoRef}
                            style={{ padding: 0 }}
                        >
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <InputCurrency
                                        onChange={(e: any) => {
                                            const valorReplace = Number(
                                                e.currentTarget.value
                                                    .replaceAll('.', '')
                                                    .replaceAll(',', '.')
                                                    .replaceAll('R$', '')
                                            )
                                            setValor(valorReplace)
                                        }}
                                        value={IntlRealFormat(valor || 0)}
                                        width="col-12"
                                        label="Valor"
                                        name="valor"
                                        mask="999.999,99"
                                        required={true}
                                        defaultValue={MoneyFormat(0)}
                                    />
                                </div>
                                <div className="col-12 col-md-5">
                                    <Input
                                        name="nomeHotel"
                                        label="Nome do Hotel"
                                        placeholder="Nome do hotel"
                                        required={true}
                                    ></Input>
                                </div>
                                <div className="col-12 col-md-1">
                                    <Input
                                        name="ddd"
                                        label="DDD"
                                        required={true}
                                    ></Input>
                                </div>
                                <div className="col-12 col-md-3">
                                    <Input
                                        name="telefoneHotel"
                                        label="Telefone Hotel"
                                        required={true}
                                    ></Input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-8">
                                    <Input
                                        name="endereco"
                                        label="Endereço"
                                        placeholder="Descreva o endereço"
                                        required={true}
                                    ></Input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <Input
                                        name="codigoReserva"
                                        label="Código Reserva"
                                        placeholder="Descreva o código"
                                        required={true}
                                    ></Input>
                                </div>
                            </div>
                            <div className="col-12">
                                <Input
                                    name="observacao"
                                    label="Observação"
                                    placeholder="Observação da cotação..."
                                ></Input>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '2rem'
                                }}
                            >
                                <Button
                                    type="submit"
                                    style={{
                                        width: '20%',
                                        minWidth: 'auto'
                                    }}
                                    colorType="quaternary"
                                    icon="save"
                                >
                                    Gravar
                                </Button>
                            </div>
                        </Form>
                    ) : (
                        <Form
                            onSubmit={handleSubmitHospedagem}
                            ref={formHospedagemRef}
                            style={{ padding: 0 }}
                        >
                            <div className="row">
                                <div
                                    className="col-12 col-sm-6"
                                    style={{ paddingTop: '1rem' }}
                                >
                                    <InputPlaces
                                        name="cidade"
                                        label="Cidade"
                                        required={true}
                                        placeholder="Em que cidade?"
                                    ></InputPlaces>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Input
                                        name="referencia"
                                        label="Ponto de referencia"
                                        required={true}
                                        placeholder="Ex. Próximo a havan vila velha"
                                    ></Input>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <DatePicker
                                        name="dataEntrada"
                                        label="Check-in"
                                        minDate={
                                            new Date(
                                                roteiro[0]?.DataInicio as string
                                            )
                                        }
                                        maxDate={
                                            new Date(
                                                roteiro[roteiro?.length - 1]
                                                    ?.DataTermino as string
                                            )
                                        }
                                        placeholderText="Data da entrada"
                                    ></DatePicker>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <DatePicker
                                        name="dataSaida"
                                        label="Check-out"
                                        minDate={
                                            new Date(
                                                roteiro[0]?.DataInicio as string
                                            )
                                        }
                                        maxDate={
                                            new Date(
                                                roteiro[roteiro?.length - 1]
                                                    ?.DataTermino as string
                                            )
                                        }
                                        placeholderText="Data da saída"
                                    ></DatePicker>
                                </div>
                                <div className="col-12">
                                    <Input
                                        style={{ height: '7.5rem' }}
                                        name="observacao"
                                        label="Observação"
                                        type="textarea"
                                        placeholder="Digite a observação..."
                                        required={false}
                                    ></Input>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Button
                                    type="submit"
                                    style={{
                                        width: '20%',
                                        minWidth: 'auto'
                                    }}
                                    colorType="quaternary"
                                    icon="save"
                                >
                                    Gravar
                                </Button>
                            </div>
                        </Form>
                    )}
                </Modal>
            ) : null}

            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </>
    )
}

const Text = styled.p``

import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { Label, Input as InputBs } from 'reactstrap'
import styled from 'styled-components'

export type Props = {
    name?: string
    required?: boolean
    hide?: boolean
    placeholder?: string
    label?: string
    withoutLabel?: string
}
type InputProps = JSX.IntrinsicElements['input'] & Props

export function Input({
    name = '',
    required = false,
    hide = false,
    placeholder = '',
    label = '',
    withoutLabel = '',
    ...tagProps
}: InputProps) {
    const inputRef = useRef<HTMLInputElement>(null)
    const { fieldName, registerField, defaultValue, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        })
    }, [fieldName, registerField])

    return (
        <div style={{ margin: '1rem 0' }}>
            {withoutLabel === '' ? (
                <Label for={label}>
                    <Text>
                        {label} {required ? '*' : ''}
                    </Text>
                </Label>
            ) : (
                <></>
            )}

            <InputBs
                style={{
                    fontSize: '0.9375rem;',
                    fontFamily: 'Montserrat, sas-serif',
                    color: '#6c7480'
                }}
                className={`customInput ${error ? 'input-danger' : ''}`}
                defaultValue={defaultValue}
                innerRef={inputRef}
                name={name}
                id={name}
                placeholder={placeholder}
                hidden={hide}
                {...(tagProps as any)}
            />
            {error && <span className="error-text">{error}</span>}
        </div>
    )
}

const Text = styled.p`    
font-size: 0.9rem;
font-weight: 800;
color: '#6C7480',
font: 'Montserrat, sas-serif'
`

import React, { useRef, useEffect, useState, useContext } from 'react'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { DatePicker } from '../../../../core/components/DatePicker'
import { Input } from '../../../../core/components/Form/Input'
import { Header } from '../../../../core/components/Header/Header'
import { useHistory, useParams } from 'react-router'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { Center } from '../../../../core/components/Center'
import { Button } from '../../../../core/components/Button'
import { Space } from '../../../../core/components/Space'
import { Subtitle } from '../../../../core/components/Subtitle'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UrlMountQuery } from '../../../../core/utils/UrlMountQuery'
import { get, post, put, destroy } from '../../../../services/http'
import { ENDPOINTS } from '../../../../services/config-http'
import { Flex } from '../../../../core/components/Flex'
import { RoteiroDto } from '../../../../contexts/interfaces/roteiroDto'
import { UserContext } from '../../../../contexts/UserContext'
import { Alert } from '../../../../core/components/Alert'
import { DisableForMobile, IsMobile } from '../stepMobile'
import {
    Box,
    Quadro,
    TrMobile,
    getCampo
} from '../../../../core/components/Mobile'
import { Deletar } from '../../../../core/components/Deletar'

export const EditarRoteirosForm = () => {
    const history = useHistory()
    const { Id } = useParams() as any
    const formRef = useRef<FormHandles>(null)
    const [messageError, setMessageError] = useState<string>('')
    const [roteiros, setRoteiros] = useState<Array<RoteiroDto>>([])
    const [roteirosNovos, setRoteirosNovos] = useState<Array<RoteiroDto>>([])
    const [roteirosAlterados, setRoteirosAlterados] = useState<
        Array<RoteiroDto>
    >([])
    const [regrasPoliticaViagem, setRegrasPoliticaViagem] = useState<any>()
    const [roteirosRemovidos, setRoteirosRemovidos] = useState<
        Array<RoteiroDto>
    >([])
    const [multiplosDestinos, setMultiplosDestinos] = useState(false)
    const [editing, setEditing] = useState(false)
    const [editingKey, setEditingKey] = useState(-1)
    const [msgErro, setMsgErro] = useState('')
    const [msgErro2, setMsgErro2] = useState('')
    const [showRoteiros, setShowRoteiros] = useState(false)
    const [solicitacao, setSolicitacao] = useState<any>()

    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })

    useEffect(() => {
        get(`${ENDPOINTS.solicitacaoViagemPorId}/${Id}`).then(
            async ({ data: { Model } }) => {
                setSolicitacao(Model)
            }
        )
    }, [])

    useEffect(() => {
        if (showRoteiros) {
            get(
                UrlMountQuery(
                    { IdSolicitacaoViagem: Id },
                    ENDPOINTS.roteiroByIdSolicitacao
                )
            )
                .then(({ data }) => {
                    setRoteiros(data)
                    setMultiplosDestinos(data[0].MultiplosDestinos)
                })
                .catch(err => {
                    setShowAlert({
                        status: true,
                        message: err.data,
                        type: 'danger'
                    })
                })
            getRegra(solicitacao)
        }
    }, [showRoteiros])

    useEffect(() => {
        if (solicitacao?.TipoSolicitacao) {
            buscaRegra(
                solicitacao.TipoSolicitacao === 'Viagem Completa' ? 1 : 2
            )
        }
    }, [solicitacao])

    const buscaRegra = async (id: number) => {
        if (id !== 0) {
            await get(`${ENDPOINTS.regrasPoliticaViagem}/${id}`)
                .then(({ data: { Model } }) => {
                    const { UtilizarRoteiro } = Model
                    setShowRoteiros(UtilizarRoteiro)
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    const getRegra = async (param: any) => {
        if (param) {
            const viajantePrincipal = param?.Viajantes.filter(
                (item: { Convidado: boolean }) => item.Convidado === false
            )[0]
            get(
                `${ENDPOINTS.regrasPoliticaViagem}/BuscarPorIdPolitica/${viajantePrincipal?.IdPoliticaViagem}`
            )
                .then(({ data: { Model } }) => {
                    const regras: Array<any> = Model
                    setRegrasPoliticaViagem(
                        regras.filter(
                            item =>
                                item?.IdTipoSolicitacao ===
                                param.IdTipoSolicitacao
                        )[0]
                    )
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    function _PipeCustomDate(value: string) {
        const date = new Date(value)
        return <>{date.toLocaleDateString()}</>
    }

    function _PipeCustomDestino(value: any) {
        const destino = value.Destino
        return <>{destino}</>
    }

    function _UpdateFormRef(value: any) {
        formRef.current?.setFieldValue('Destino', value.Destino)
        formRef.current?.setFieldValue('DataInicio', value.DataInicio)
        formRef.current?.setFieldValue('DataTermino', value.DataTermino)
        formRef.current?.setFieldValue('Objetivo', value.Objetivo)
    }

    function ChecarSeExiste(
        listaRoteiros: RoteiroDto[],
        roteiroParaChecar: RoteiroDto
    ) {
        let existe = -1
        listaRoteiros?.map((e: any, key: number) => {
            if (
                listaRoteiros[key].Id === roteiroParaChecar.Id ||
                (listaRoteiros[key].Destino === roteiroParaChecar.Destino &&
                    listaRoteiros[key].DataInicio ===
                        roteiroParaChecar.DataInicio &&
                    listaRoteiros[key].DataTermino ===
                        roteiroParaChecar.DataTermino &&
                    listaRoteiros[key].Objetivo === roteiroParaChecar.Objetivo)
            ) {
                existe = key
            }
        })
        return existe // retorna -1 caso não exista
    }

    function _adicionarRoteiro(roteiroRecebido: RoteiroDto) {
        const existeRemovidos = ChecarSeExiste(
            roteirosRemovidos,
            roteiroRecebido
        )

        if (existeRemovidos === -1) {
            // caso nao seja uma hospedagem removida
            const existeAlterados = ChecarSeExiste(
                roteirosAlterados,
                roteiroRecebido
            )
            if (existeAlterados === -1) {
                // caso nao seja uma hospedagem alterada
                roteirosNovos.push(roteiroRecebido)
                setRoteirosNovos(roteirosNovos)
            } else {
                roteirosAlterados.splice(existeAlterados, 1)
                setRoteirosAlterados(roteirosAlterados)
            }
        } else {
            const existeAlterados = ChecarSeExiste(
                roteirosAlterados,
                roteiroRecebido
            )
            if (existeAlterados === -1) {
                // caso nao seja uma hospedagem alterada
                roteirosRemovidos.splice(existeAlterados, 1)
                setRoteirosRemovidos(roteirosRemovidos)
            } else {
                roteirosRemovidos?.map((e: any, key: number) => {
                    if (
                        roteirosRemovidos[key].Destino ===
                            roteiroRecebido.Destino &&
                        roteirosRemovidos[key].DataInicio?.toLocaleString() ===
                            roteiroRecebido.DataInicio?.toLocaleString() &&
                        roteirosRemovidos[key].DataTermino?.toLocaleString() ===
                            roteiroRecebido.DataTermino?.toLocaleString() &&
                        roteirosRemovidos[key].Objetivo ===
                            roteiroRecebido.Objetivo
                    ) {
                        roteirosAlterados.splice(existeRemovidos, 1)
                        setRoteirosAlterados(roteirosAlterados)
                        roteirosRemovidos.splice(existeRemovidos, 1)
                        setRoteirosRemovidos(roteirosRemovidos)
                    }
                })
            }
        }
        setRoteiros(roteiros)
    }

    function _alterarRoteiro(roteiroRecebido: RoteiroDto) {
        const existeNovos = ChecarSeExiste(roteirosNovos, roteiroRecebido)

        if (existeNovos === -1) {
            // se não for um roteiro nova
            const existeAlterados = ChecarSeExiste(
                roteirosAlterados,
                roteiroRecebido
            )
            if (existeAlterados === -1) {
                // se nao for um roteiro alterada
                roteiros[editingKey].Destino = roteiroRecebido.Destino
                roteiros[editingKey].DataInicio = roteiroRecebido.DataInicio
                roteiros[editingKey].DataTermino = roteiroRecebido.DataTermino
                roteiros[editingKey].Objetivo = roteiroRecebido.Objetivo
                roteirosAlterados.push(roteiroRecebido)
                setRoteirosAlterados(roteirosAlterados)
            } else {
                // caso ja exista, só atualiza os dados na tabela e nos alterados
                roteiros[editingKey].Destino = roteiroRecebido.Destino
                roteiros[editingKey].DataInicio = roteiroRecebido.DataInicio
                roteiros[editingKey].DataTermino = roteiroRecebido.DataTermino
                roteiros[editingKey].Objetivo = roteiroRecebido.Objetivo
                roteirosAlterados[existeAlterados].Destino =
                    roteiroRecebido.Destino
                roteirosAlterados[existeAlterados].DataInicio =
                    roteiroRecebido.DataInicio
                roteirosAlterados[existeAlterados].DataTermino =
                    roteiroRecebido.DataTermino
                roteirosAlterados[existeAlterados].Objetivo =
                    roteiroRecebido.Objetivo
            }
        }
    }

    function _removerHospedagem(roteiroRecebido: RoteiroDto) {
        const existeNovos = ChecarSeExiste(roteirosNovos, roteiroRecebido)

        setEditing(false)
        setEditingKey(-1)
        formRef.current?.reset()

        if (existeNovos === -1) {
            // se nao for uma hospedagem nova
            const hasHospedagem = roteirosRemovidos.some(
                i => i.Id === roteiroRecebido.Id
            )
            if (hasHospedagem === false) {
                roteirosRemovidos.push(roteiroRecebido)
                setRoteirosRemovidos(roteirosRemovidos)
            }
        } else {
            roteirosNovos.splice(existeNovos, 1)
            setRoteirosNovos(roteirosNovos)
        }

        const existeAlterados = ChecarSeExiste(
            roteirosAlterados,
            roteiroRecebido
        )
        if (existeAlterados !== -1) {
            // caso seja uma hospedagem alterada
            roteirosAlterados.splice(existeAlterados, 1)
            setRoteirosAlterados(roteirosAlterados)
        }
    }

    const handleSubmit: SubmitHandler = async (data, { reset }) => {
        try {
            const schema = Yup.object().shape({
                Destino: Yup.string().required(
                    'O Campo Destino é obrigatório!'
                ),
                DataInicio: Yup.date()
                    .nullable()
                    .required('O Campo Data de Início é obrigatório!'),
                DataTermino: Yup.date()
                    .nullable()
                    .required('O Campo Data de Término é obrigatório!'),
                Objetivo: Yup.string().required(
                    'O Campo Objetivo é obrigatório!'
                )
            })
            await schema.validate(data, { abortEarly: false })
            const newRoteiros = [...roteiros]
            const roteiroData: RoteiroDto = {
                Destino: formRef.current?.getFieldValue('Destino'),
                DataInicio: formRef.current?.getFieldValue('DataInicio'),
                DataTermino: formRef.current?.getFieldValue('DataTermino'),
                Objetivo: formRef.current?.getFieldValue('Objetivo'),
                IdSolicitacaoViagem: Id
            }
            const dtInicio = roteiroData.DataInicio
            if (
                dtInicio &&
                new Date(dtInicio).toLocaleDateString() ===
                    new Date().toLocaleDateString() &&
                regrasPoliticaViagem?.ValidarPrazoAntecedenciaParaRequisacaoDeSolicitacao
            ) {
                setMsgErro2(
                    'A política não permite fazer a solicitação para viagem sem ao menos 1 dia de antecedência'
                )
            } else {
                setMsgErro2('')
                if (editing && editingKey !== -1) {
                    roteiroData.Id = roteiros[editingKey].Id
                    _alterarRoteiro(roteiroData)
                } else {
                    newRoteiros.push(roteiroData)
                    _adicionarRoteiro(roteiroData)
                }

                setRoteiros([...newRoteiros])
                formRef.current?.setErrors({})
                reset()
                setEditing(false)
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                formRef.current?.setErrors(erros)
            }
        }
    }

    const SubmitInformation = async () => {
        try {
            let valido = false
            setMsgErro('')
            roteirosNovos.forEach(element => {
                post(ENDPOINTS.roteiro, {
                    idSolicitacaoViagem: element.IdSolicitacaoViagem,
                    destino: element.Destino,
                    objetivo: element.Objetivo,
                    dataInicio: element.DataInicio,
                    dataTermino: element.DataTermino,
                    multiplosDestinos: true
                })
                    .then(res => {
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
            })
            roteirosAlterados.forEach(element => {
                put(ENDPOINTS.roteiro, {
                    id: element.Id,
                    idSolicitacaoViagem: element.IdSolicitacaoViagem,
                    destino: element.Destino,
                    objetivo: element.Objetivo,
                    dataInicio: element.DataInicio,
                    dataTermino: element.DataTermino,
                    multiplosDestinos: true
                })
                    .then(res => {
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
            })
            roteirosRemovidos.forEach(element => {
                destroy(`${ENDPOINTS.roteiro}/${element.Id}`)
                    .then(res => {
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
            })
            valido = msgNext(roteiros)
            if (valido) {
                history.push(`/solicitacoes/editar/${Id}`)
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                formRef.current?.setErrors(erros)
            }
        }
    }

    const msgNext = (dados: any[]) => {
        let valido = true
        dados.forEach(destino => {
            if (
                regrasPoliticaViagem?.ValidarPrazoAntecedenciaParaRequisacaoDeSolicitacao
            ) {
                if (
                    new Date(destino.DataInicio).toLocaleDateString() <
                        new Date().toLocaleDateString() ||
                    new Date(destino.DataInicio).toLocaleDateString() ===
                        new Date().toLocaleDateString()
                ) {
                    valido = false
                    setMsgErro(
                        'A política não permite fazer a solicitação para viagem sem ao menos 1 dia de antecedência'
                    )
                }
            }
        })
        return valido
    }
    function getTabelaMobile() {
        return (
            <IsMobile>
                <Center>
                    <Box>
                        {roteiros.map((tableItem, index) => {
                            if (tableItem) {
                                return (
                                    <Quadro>
                                        <table style={{ width: '100%' }}>
                                            <TrMobile>
                                                {getCampo(
                                                    index,
                                                    'Destino',
                                                    tableItem.Destino
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Início',
                                                    tableItem.DataInicio !==
                                                        undefined
                                                        ? _PipeCustomDate(
                                                              tableItem.DataInicio.toString()
                                                          )
                                                        : 'Não possui data de entrada'
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Fim',

                                                    tableItem.DataTermino !==
                                                        undefined
                                                        ? _PipeCustomDate(
                                                              tableItem.DataTermino.toString()
                                                          )
                                                        : 'Não possui data de termino'
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Objetivo',
                                                    tableItem.Objetivo
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Remover',
                                                    <Deletar
                                                        remover={() => {
                                                            setEditingKey(index)
                                                            const newHospedagens =
                                                                [...roteiros]

                                                            const hospedagemRemovida =
                                                                newHospedagens.splice(
                                                                    index,
                                                                    1
                                                                )

                                                            _removerHospedagem(
                                                                hospedagemRemovida[0]
                                                            )

                                                            setRoteiros(
                                                                newHospedagens
                                                            )
                                                        }}
                                                    />
                                                )}
                                            </TrMobile>
                                        </table>
                                    </Quadro>
                                )
                            }
                            return null
                        })}
                    </Box>
                </Center>
            </IsMobile>
        )
    }

    return (
        <BlankBoard>
            <Header
                title={
                    roteiros.length > 0
                        ? 'Alteração de Roteiros'
                        : 'Criação de Roteiros'
                }
                actionArrowClick={() =>
                    history.push(`/solicitacoes/editar/${Id}`)
                }
            ></Header>
            <Space height="2rem"></Space>
            <Subtitle
                style={{
                    textAlign: 'center',
                    margin: '1rem 0'
                }}
            >
                Dados do Roteiro
            </Subtitle>
            {showRoteiros ? (
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <>
                        <Space height="1.3em"></Space>
                        <div className="row">
                            <Input
                                name="Destino"
                                label="Localize o destino"
                                type="text"
                                required={true}
                            ></Input>
                            <div className="col-12 col-sm-6">
                                <DatePicker
                                    name="DataInicio"
                                    label="Quando você vai para este destino?"
                                    placeholderText="Escolha a data que você iniciará no destino.."
                                    minDate={new Date()}
                                    required={true}
                                ></DatePicker>
                                <p
                                    style={{
                                        color: 'red'
                                    }}
                                >
                                    {msgErro2}
                                </p>
                            </div>
                            <div className="col-12 col-sm-6">
                                <DatePicker
                                    name="DataTermino"
                                    label="Quando você retorna deste destino?"
                                    placeholderText="Escolha a data que você terminará no destino.."
                                    minDate={new Date()}
                                    required={true}
                                ></DatePicker>
                            </div>

                            <Input
                                name="Objetivo"
                                label="Objetivo"
                                placeholder="Ex. Apoio a inauguração da loja 200"
                                type="text"
                                required={true}
                            ></Input>
                            <Space height="1.5rem"></Space>
                            <div className="d-flex justify-content-end align-items-center">
                                {editing ? (
                                    <div className="col-12 col-sm-3">
                                        <Flex justifyContent="flex-end">
                                            <Button
                                                style={{
                                                    width: '80%',
                                                    minWidth: 'auto'
                                                }}
                                                colorType="secondary"
                                                icon="ban"
                                                onClick={() => {
                                                    setEditing(false)
                                                    setEditingKey(-1)
                                                    formRef.current?.reset()
                                                }}
                                            >
                                                <b>Cancelar</b>
                                            </Button>
                                        </Flex>
                                    </div>
                                ) : null}
                                <div className="col-12 col-sm-3">
                                    <Flex justifyContent="flex-end">
                                        <DisableForMobile>
                                            <Button
                                                style={{
                                                    width: '80%',
                                                    minWidth: 'auto'
                                                }}
                                                colorType="quintiary"
                                                icon={editing ? 'save' : 'plus'}
                                            >
                                                {editing ? (
                                                    <b>Salvar</b>
                                                ) : (
                                                    <b>Adicionar</b>
                                                )}
                                            </Button>
                                        </DisableForMobile>
                                        <Center>
                                            <IsMobile>
                                                <Button
                                                    colorType="btnMobile"
                                                    icon={
                                                        editing
                                                            ? 'save'
                                                            : 'plus'
                                                    }
                                                >
                                                    {editing ? (
                                                        <b>Salvar</b>
                                                    ) : (
                                                        <b>Adicionar</b>
                                                    )}
                                                </Button>
                                            </IsMobile>
                                        </Center>
                                    </Flex>
                                </div>
                            </div>
                            <Space height="1rem"></Space>
                            <div className="col-6">
                                {messageError ? (
                                    <p
                                        className="danger"
                                        style={{ marginTop: '15px' }}
                                    >
                                        {messageError}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <DisableForMobile>
                            <div className="row" style={{ marginTop: '3rem' }}>
                                <div className="col-12">
                                    <label htmlFor=""></label>
                                    <div
                                        className="table-responsive"
                                        style={{
                                            border: 'solid 1px lightgray',
                                            borderRadius: '15px'
                                        }}
                                    >
                                        <table className="table">
                                            <thead
                                                style={{
                                                    borderBottom:
                                                        'solid 1px lightgray'
                                                }}
                                            >
                                                <tr
                                                    style={{
                                                        border: '0 !important'
                                                    }}
                                                >
                                                    <Th
                                                        className="col-1"
                                                        scope="col"
                                                    >
                                                        <Text>#</Text>
                                                    </Th>
                                                    <Th
                                                        scope="col"
                                                        className="col-2"
                                                    >
                                                        <Text>Destino</Text>
                                                    </Th>
                                                    <Th
                                                        scope="col"
                                                        className="col-2"
                                                    >
                                                        <Text>
                                                            Data do Início
                                                        </Text>
                                                    </Th>
                                                    <Th
                                                        scope="col"
                                                        className="col-2"
                                                    >
                                                        <Text>
                                                            Data do Término
                                                        </Text>
                                                    </Th>
                                                    <Th
                                                        scope="col"
                                                        className="col-2"
                                                    >
                                                        <Text>Objetivo</Text>
                                                    </Th>

                                                    <Th
                                                        scope="col"
                                                        className="col-1"
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <Text>Editar</Text>
                                                    </Th>
                                                    <Th
                                                        scope="col"
                                                        className="col-1"
                                                    >
                                                        <Text>Deletar</Text>
                                                    </Th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {roteiros.map((item, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td className="col-1">
                                                                <Text>
                                                                    {key + 1}
                                                                </Text>
                                                            </td>
                                                            <td className="col-2">
                                                                <Text>
                                                                    {_PipeCustomDestino(
                                                                        item
                                                                    )}
                                                                </Text>
                                                            </td>
                                                            <td className="col-2">
                                                                <Text>
                                                                    {item.DataInicio !==
                                                                    undefined
                                                                        ? _PipeCustomDate(
                                                                              item.DataInicio.toString()
                                                                          )
                                                                        : 'Não possui data de entrada'}
                                                                </Text>
                                                            </td>
                                                            <td className="col-2">
                                                                <Text>
                                                                    {item.DataTermino !==
                                                                    undefined
                                                                        ? _PipeCustomDate(
                                                                              item.DataTermino.toString()
                                                                          )
                                                                        : 'Não possui data de saída'}
                                                                </Text>
                                                            </td>
                                                            <td className="col-2">
                                                                <Text>
                                                                    {
                                                                        item.Objetivo
                                                                    }
                                                                </Text>
                                                            </td>
                                                            <td
                                                                className="col-1 cursor-pointer"
                                                                style={{
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                <span
                                                                    onClick={() => {
                                                                        setEditing(
                                                                            true
                                                                        )
                                                                        setEditingKey(
                                                                            key
                                                                        )
                                                                        _UpdateFormRef(
                                                                            roteiros[
                                                                                key
                                                                            ]
                                                                        )
                                                                        formRef.current?.setErrors(
                                                                            {}
                                                                        )
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon="edit"></FontAwesomeIcon>
                                                                </span>
                                                            </td>
                                                            <td className="col-1 danger cursor-pointer">
                                                                <span
                                                                    onClick={() => {
                                                                        setEditingKey(
                                                                            key
                                                                        )
                                                                        const newHospedagens =
                                                                            [
                                                                                ...roteiros
                                                                            ]

                                                                        const hospedagemRemovida =
                                                                            newHospedagens.splice(
                                                                                key,
                                                                                1
                                                                            )

                                                                        _removerHospedagem(
                                                                            hospedagemRemovida[0]
                                                                        )

                                                                        setRoteiros(
                                                                            newHospedagens
                                                                        )
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </DisableForMobile>
                        <IsMobile>{getTabelaMobile()}</IsMobile>
                        <p
                            style={{
                                color: 'red'
                            }}
                        >
                            {msgErro}
                        </p>
                    </>
                    <Space height="2rem"></Space>
                    <Center>
                        <DisableForMobile>
                            <Button
                                colorType="quaternary"
                                icon="check"
                                type="button"
                                onClick={() => {
                                    SubmitInformation()
                                }}
                            >
                                Salvar
                            </Button>
                        </DisableForMobile>
                        <IsMobile>
                            <Button
                                colorType="btnMobile"
                                icon="check"
                                type="button"
                                onClick={() => {
                                    SubmitInformation()
                                }}
                            >
                                Salvar
                            </Button>
                        </IsMobile>
                    </Center>
                </Form>
            ) : (
                <Alert
                    fixed={true}
                    icon="exclamation-circle"
                    type="primary"
                    message="Consulte as politicas de viagens"
                ></Alert>
            )}
        </BlankBoard>
    )
}

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
    text-align: 'center';
`
const Text = styled.p``

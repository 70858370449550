import React, {
    createContext,
    useState,
    useContext,
    Dispatch,
    SetStateAction
} from 'react'
import { GlobalStateInterface } from './interfaces/global'

const GlobalStateContext = createContext({
    state: {} as Partial<GlobalStateInterface>,
    setState: {} as Dispatch<SetStateAction<Partial<GlobalStateInterface>>>
})

const GlobalStateProvider = ({
    children,
    value = {} as GlobalStateInterface
}: {
    children: React.ReactNode
    value?: Partial<GlobalStateInterface>
}) => {
    const [state, setState] = useState(value)
    return (
        <GlobalStateContext.Provider value={{ state, setState }}>
            {children}
        </GlobalStateContext.Provider>
    )
}

const useGlobalState = () => {
    return useContext(GlobalStateContext)
}

export { GlobalStateProvider, useGlobalState }

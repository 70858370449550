import React, { useContext, useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'

import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { UserContext, UserProvider } from './contexts/UserContext'

import { Routes } from './Routes/Routes'
import GlobalStyles from './styles/global-styles'
import light from './styles/themes/light'
import {
    Footer,
    Navbar,
    Profile,
    Sidebar,
    PageBody,
    ThemeProvider as ThemeProviderHavan
} from '@havan/react-components'
import { SidebarItem } from '@havan/react-components/dist/components/layout/Sidebar'
import {
    FaBusinessTime,
    FaClipboardList,
    FaHome,
    FaSignOutAlt,
    FaDonate,
    FaCog
} from 'react-icons/fa'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { GlobalStateProvider } from './contexts/GlobalStateProvider'
import { MetaDecorator } from './MetaDecorator'
import { useAuth } from './contexts/Auth'
import './App.css'

import { userInfo } from 'os'
import { array } from 'yup'
import { Alert } from 'reactstrap'
import { useAlert } from './hooks/AlertToast'
import { ENDPOINTS } from './services/config-http'
import { get, patch, post, put } from './services/http'
import { Usuario } from './contexts/interfaces/usuario'

library.add(fab, fas)

const sidebarItems: SidebarItem[] = [
    {
        id: 1,
        icon: FaHome,
        label: 'Solicitacoes',
        to: '/solicitacoes',
        administrador: false,
        agenteViagem: false,
        aprovaPrestacao: false
    },
    {
        id: 2,
        icon: FaDonate,
        label: 'Cotações',
        to: '/listagem-cotacao',
        administrador: true,
        agenteViagem: true,
        aprovaPrestacao: false
    },
    {
        id: 3,
        icon: FaBusinessTime,
        label: 'Conferência',
        to: '/solicitacoes/listar-conferencia',
        administrador: true,
        agenteViagem: false,
        aprovaPrestacao: true
    },
    {
        id: 4,
        icon: FaClipboardList,
        label: 'Cadastros',
        administrador: true,
        agenteViagem: false,
        aprovaPrestacao: false,
        items: [
            {
                id: 1,
                label: 'Conta contábil',
                to: '/cadastros/contas-contabeis',
                administrador: true,
                agenteViagem: false,
                aprovaPrestacao: false
            },
            {
                id: 2,
                label: 'Empresa',
                to: '/cadastros/empresas',
                administrador: true,
                agenteViagem: false,
                aprovaPrestacao: false
            },
            {
                id: 3,
                label: 'Filial',
                to: '/cadastros/filial',
                administrador: true,
                agenteViagem: false,
                aprovaPrestacao: false
            },
            {
                id: 4,
                label: 'Motivo Viagem',
                to: '/cadastros/motivo-viagem/',
                administrador: true,
                agenteViagem: false,
                aprovaPrestacao: false
            },
            {
                id: 5,
                label: 'Região',
                to: '/cadastros/regiao/',
                administrador: true,
                agenteViagem: false,
                aprovaPrestacao: false
            },
            {
                id: 6,
                label: 'Servico',
                to: '/cadastros/Servico',
                administrador: true,
                agenteViagem: false,
                aprovaPrestacao: false
            },
            {
                id: 7,
                label: 'Tipo de Despesa',
                to: '/cadastros/tipos-despesa',
                administrador: true,
                agenteViagem: false,
                aprovaPrestacao: false
            },
            {
                id: 8,
                label: 'Usuários',
                to: '/cadastros/usuario',
                administrador: true,
                agenteViagem: false,
                aprovaPrestacao: false
            }
        ]
    },
    {
        id: 5,
        icon: FaCog,
        label: 'Configurações',
        administrador: true,
        agenteViagem: false,
        aprovaPrestacao: false,
        items: [
            {
                id: 1,
                label: 'Políticas',
                to: '/solicitacoes/configuracao/politicas',
                administrador: true,
                agenteViagem: false,
                aprovaPrestacao: false
            },
            {
                id: 2,
                label: 'Hierarquias',
                to: '/hierarquia',
                administrador: true,
                agenteViagem: false,
                aprovaPrestacao: false
            }
        ]
    }
]
const App = () => {
    const [administrador, setAdministrador] = useState(false)
    const { signOut, user } = useAuth()

    const [dadosUsuario, setDadosUsuario] = useState<Usuario>({})
    useEffect(() => {
        if (user?.codigo) {
            get(
                `${ENDPOINTS.usuarioByCodigo.replace(':Codigo', user?.codigo)}`
            ).then(({ data: { Model } }) => {
                setDadosUsuario(Model)
            })
        }
    }, [user])

    function itensMenu(itens: Array<SidebarItem>) {
        const retorno = new Array<SidebarItem>()
        itens.forEach(element => {
            if (validarItemMenu(element)) {
                const newItem = {
                    id: element.id,
                    icon: element.icon,
                    label: element.label,
                    to: element.to,
                    administrador: element.administrador,
                    items:
                        element.items !== undefined
                            ? itensMenu(element.items)
                            : undefined
                }
                retorno.push(newItem)
            }
        })
        return retorno
    }

    function validarItemMenu(item: SidebarItem) {
        if (item.administrador || item.agenteViagem || item.aprovaPrestacao) {
            if (
                (item.administrador && dadosUsuario?.Administrador) ||
                (item.agenteViagem && dadosUsuario?.AgenteViagem) ||
                (item.aprovaPrestacao && dadosUsuario?.AprovaPrestacao)
            ) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    if (!user) {
        return (
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        )
    }

    return (
        <ThemeProviderHavan>
            <ThemeProvider theme={light}>
                <GlobalStyles />
                <PageBody>
                    <BrowserRouter>
                        <GlobalStateProvider>
                            <UserProvider>
                                <Navbar
                                    linkLogo="/solicitacoes"
                                    signOut={signOut}
                                >
                                    <Profile user={user}>
                                        <button type="button" onClick={signOut}>
                                            <FaSignOutAlt /> Logout
                                        </button>
                                    </Profile>
                                </Navbar>
                                <Sidebar list={itensMenu(sidebarItems)} />
                                <Routes />
                                <Footer />
                                <MetaDecorator></MetaDecorator>
                            </UserProvider>
                        </GlobalStateProvider>
                    </BrowserRouter>
                </PageBody>
            </ThemeProvider>
        </ThemeProviderHavan>
    )
}

export default App

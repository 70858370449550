import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import { StepByStepItem } from '../../../contexts/interfaces/_stepByStep'

const StepContainer = styled.div`
    width: 100%;
    height: 100px;
    padding: 0 5rem;
    display: flex;
    justify-content: center;
`

const Item = styled.div`
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 720px) {
        height: 50px;
    }
`

const Circle = styled.div`
    background-color: ${props => props.theme.colors.placeholder};
    width: 55px;
    border-radius: 50px;
    height: 55px;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    position: relative;

    @media (max-width: 720px) {
        width: 40px;
        height: 40px;
        font-size: 0.9rem;
    }
`
const Line = styled.div`
    border: solid 1px ${props => props.theme.colors.placeholder};
    width: 65px;
    @media (max-width: 720px) {
        width: 15px;
    }
`

const LabelCircle = styled.h4`
    position: absolute;
    bottom: -40px;
    font-size: 1.2rem;
    color: ${props => props.theme.colors.primary};
    @media (max-width: 720px) {
        bottom: -34px;
        font-size: 0.9rem;
    }
`
interface StepByStepProps {
    items?: StepByStepItem[]
}

export const StepByStep = ({ items = [] }: StepByStepProps) => {
    const listItems = items.map((item, i) => (
        <Item key={item.name}>
            <Circle
                style={
                    item.active
                        ? { backgroundColor: '#002a5d' }
                        : item.completed
                        ? { backgroundColor: '#5787D0' }
                        : {}
                }
            >
                <FontAwesomeIcon icon={item.icon} />
                {item.active ? <LabelCircle>{item.name}</LabelCircle> : ''}
            </Circle>
            {i === items.length - 1 ? (
                ''
            ) : (
                <Line
                    style={item.completed ? { borderColor: '#5787D0' } : {}}
                ></Line>
            )}
        </Item>
    ))

    return <StepContainer>{listItems}</StepContainer>
}

import React, { useRef, useEffect, useState } from 'react'
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select'
import { useField } from '@unform/core'
import { Label } from 'reactstrap'
import { get } from '../../../services/http'
import { SelectMapper } from '../../utils/SelectMapper'
import styled from 'styled-components'

interface Props extends SelectProps<OptionTypeBase> {
    name: string
    required?: boolean
    request: RequestOptions
}

export type RequestOptions = {
    endpoint: string
    labelToSelect: string
    valueToSelect: string
}

export const SelectRequester = ({
    name,
    required = false,
    label,
    request,
    ...rest
}: Props) => {
    const { fieldName, defaultValue, registerField, error } = useField(name)
    const [options, setOptions] = useState([])
    const selectRef = useRef(null)
    const [textPlaceholder, setTextPlaceholder] = useState('Carregando...')

    useEffect(() => {
        get(request.endpoint)
            .then(({ data }) => {
                const response = data.Model ? data.Model : data.Items // verificando qual tipo de nomeclatura do retorno, Model ou Items.
                const dados = []
                if (rest?.valueDefault && response[0]?.Nome) {
                    dados.push({
                        Nome: rest?.valueDefault
                    })
                }

                response?.forEach((element: any) => {
                    dados.push(element)
                })
                setOptions(
                    SelectMapper(
                        dados,
                        request.labelToSelect,
                        request.valueToSelect
                    )
                )
                setTextPlaceholder(`Selecione`)
                initSelectRegister()
            })
            .catch(err => {
                console.log(err)
                setTextPlaceholder(`Erro ao requisitar opções de ${name}`)
                initSelectRegister()
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldName, registerField, rest.isMulti])

    function initSelectRegister() {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref: any) => {
                if (rest.isMulti) {
                    if (!ref?.state?.value) {
                        return []
                    }
                    return ref.state.value.map(
                        (option: OptionTypeBase) => option.value
                    )
                } else {
                    if (!ref?.state?.value) {
                        return ''
                    }
                    if (Array.isArray(ref?.state?.value)) {
                        return ref?.state?.value[0]?.value
                    }
                    return ref?.state?.value?.value
                }
            },
            setValue: (ref: any, value: any) => {
                if (rest.isMulti && Array.isArray(value)) {
                    const items = ref?.props?.options?.filter((option: any) =>
                        value.includes(option.value)
                    )
                    ref?.select.setValue(items)
                } else {
                    const item = ref?.props?.options?.filter(
                        (option: any) => option.value === value
                    )
                    if (item && item.length > 0) {
                        ref?.select?.setValue(item)
                    }
                }
            },
            clearValue: ref => {
                ref?.select?.setValue('')
            }
        })
    }

    return (
        <div style={{ margin: '1rem 0' }}>
            <Label for={name}>
                <Text>
                    {label} {required ? '*' : ''}
                </Text>
            </Label>
            <ReactSelect
                menuPortalTarget={document.body}
                placeholder={textPlaceholder}
                name={name}
                id={name}
                options={options}
                defaultValue={
                    defaultValue &&
                    options.length &&
                    options.find(
                        (option: OptionTypeBase) =>
                            option.value === defaultValue
                    )
                }
                ref={selectRef}
                classNamePrefix="react-select"
                styles={{
                    control: props => ({
                        ...props,
                        border: error ? 'solid 1px #F66D65 !important' : ''
                    })
                }}
                theme={theme => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary: '#73A4EE'
                    }
                })}
                {...rest}
            />
            <span className="danger">{error}</span>
        </div>
    )
}

const Text = styled.p`    
font-size: 0.9rem;
font-weight: 800;
color: '#6C7480',
font: 'Montserrat, sas-serif'
`

import React, { useRef } from 'react'
import { Form } from '@unform/web'
import { FormHandles, SubmitHandler } from '@unform/core'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Input } from '../../../core/components/Form/Input'
import { Description } from '../../../core/components/Description'
import { Switch } from '../../../core/components/Switch'
import { Center } from '../../../core/components/Center'
import * as Yup from 'yup'
import { Header } from './../../../core/components/Header/Header'
import { Subtitle } from './../../../core/components/Subtitle/index'
import { LineSeparator } from './../../../core/components/LineSeparator/index'
import { Space } from './../../../core/components/Space/index'
import { Button } from './../../../core/components/Button/index'
import { Select } from './../../../core/components/Form/Select'
import { MapFormErrors } from './../../../core/utils/MapFormErrors'
import { post } from '../../../services/http'
import { ENDPOINTS } from '../../../services/config-http'

export const CadastroAgencia = () => {
    const formRef = useRef<FormHandles>(null)

    const handleSubmit: SubmitHandler<FormHandles> = async (
        data,
        { reset }
    ) => {
        try {
            // Esquema de Validação
            const schema = Yup.object().shape({
                Nome: Yup.string()
                    .required('O Campo nome é obrigatório')
                    .max(15, 'O Campo precisa ter no máximo 15 caractéres')
            })
            // Verificação - Caso exista algum erro, o fluxo cai no catch que fará a amostragem dos erros na tela
            await schema.validate(data, { abortEarly: false })

            // Caso exista erros, mas passou na validação do schema, ele limpa o objeto
            formRef.current?.setErrors({})

            // reinicia o form com campos vazios
            reset()

            // post(ENDPOINTS.usuario, {
            //     nome: data.Nome,
            //     codigo: data.Codigo,
            //     status: data.Status
            // })
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                // Caso exista erros, o MapFormErrors vai mapear o objeto para enviarmos ao unform
                const errors = MapFormErrors(err.inner)
                // Mostrar erros na tela
                formRef.current?.setErrors(errors)
            }
        }
    }
    return (
        <BlankBoard>
            <Header title="Cadastrar Agência" actionArrowClick="/home"></Header>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <div className="row">
                    <Center>
                        <Subtitle>Dados Iniciais</Subtitle>
                    </Center>
                    <Input
                        name="Nome"
                        label="Nome"
                        required={true}
                        placeholder="Digite o nome"
                    />
                    <Input
                        name="CNPJ"
                        label="CNPJ"
                        required={true}
                        placeholder="Digite o CNPJ"
                    ></Input>
                    <LineSeparator></LineSeparator>
                    <Center>
                        <Subtitle>Dados de Contato</Subtitle>
                    </Center>
                    <Input
                        name="Email"
                        label="Email"
                        required={true}
                        placeholder="Digite o email"
                    />
                    <Input
                        name="Telefone"
                        label="Telefone"
                        required={true}
                        placeholder="(00) 0000-0000"
                    ></Input>
                    <LineSeparator></LineSeparator>
                    <Center>
                        <Subtitle>Dados de Endereço</Subtitle>
                    </Center>
                    <Input
                        name="Cidade"
                        label="Cidade"
                        required={true}
                        placeholder="Digite a Cidade"
                    />
                    <Input
                        name="Endereço"
                        label="Endereço"
                        required={true}
                        placeholder="Ex. Av Manoel Tavares 256, Alto Branco"
                    ></Input>
                    <LineSeparator></LineSeparator>
                    <Center>
                        <Subtitle>Dados de Pagamento e tipo</Subtitle>
                    </Center>
                    <Select
                        name="Tipo de Pagamento"
                        placeholder="Selecione o tipo de pagamento"
                        options={[
                            {
                                value: 'debito-credito',
                                label: 'Débito/Crédito'
                            }
                        ]}
                    ></Select>
                    <label>Tipo de Agência</label>
                    <div>
                        <div
                            className="col-12"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <div className="col12 col-sm-11">
                                <Description>Aéreo</Description>
                            </div>

                            <div className="col12 col-sm-1">
                                <Switch name="aereo"></Switch>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            className="col-12"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <div className="col12 col-sm-11">
                                <Description>Hotel</Description>
                            </div>
                            <div className="col12 col-sm-1">
                                <Switch name="hotel"></Switch>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            className="col-12"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <div className="col12 col-sm-11">
                                <Description>Serviço</Description>
                            </div>
                            <div className="col12 col-sm-1">
                                <Switch name="servico"></Switch>
                            </div>
                        </div>
                    </div>
                    <Space height="3rem"></Space>
                    <Center>
                        <Button colorType="primary">Cadastrar</Button>
                    </Center>
                </div>
            </Form>
        </BlankBoard>
    )
}

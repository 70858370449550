import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Form } from '@unform/web'
import { useHistory } from 'react-router-dom'
import { FormHandles, SubmitHandler } from '@unform/core'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button'
import { DatePicker } from '../../../core/components/DatePicker'
import { ENDPOINTS, BASE_URL_AEREO } from '../../../services/config-http'
import {
    TableRequester,
    Table,
    MapperItem,
    ConfigTable
} from './../../../core/components/Table/v2/Table'
import { SelectRequester } from '../../../core/components/Form/SelectWithRequest'
import { Input } from '../../../core/components/Form/Input'
import { STATUS_SOLICITACAO } from '../../../contexts/enums/StatusSolicitacao'
import CleanIcon from '../../../core/assets/filter_white.svg'
import { date } from 'yup/lib/locale'
import { Header } from '../../../core/components/Header/Header'
import { Flex } from '../../../core/components/Flex'
import { destroy, get, patch } from '../../../services/http'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { Modal } from '../../../core/components/Modal/Modal'
import { Hospedagem as HospedagemProp } from '../../../contexts/interfaces/hospedagem'
import { Servico } from '../../../contexts/interfaces/servico'
import { useAuth } from '../../../contexts/Auth'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Toast } from '../../../core/components/Toast'
import { text } from '@fortawesome/fontawesome-svg-core'
import { Legenda } from '../../../util/legenda'
import { Solicitacao } from '../../../contexts/interfaces/solicitacao'
import { Alert } from '../../../core/components/Alert'
import axios from 'axios'
import { useSpinner } from '../../../hooks/Spinner'
import { DisableForMobile } from '../Solicitacao/stepMobile'
import { Center } from '../../../core/components/Center'

export const ListagemSolicitacoes = () => {
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const [flagFilter, setFlagFilter] = useState(false)
    const [buttonRef, setButtonOpen] = useState<HTMLButtonElement>()
    const [idSolicitacao, setIdSolicitacao] = useState(0)
    const [approving, setApproving] = useState(true)
    const [hospedagens, setHospedagens] = useState<Array<HospedagemProp>>([])
    const [servicos, setServicos] = useState<Array<Servico>>([])
    const [idUsuario, setIdUsuario] = useState()
    const { user } = useAuth()
    const [showModal, setShowModal] = useState(false)
    const [id, setId] = useState(0)
    const [viajantes, setViajantes] = useState([])
    const [possuiRascunho, setPossuiRascunho] = useState(false)
    const [payloadSearch, setPayloadSearch] = useState<TableRequester>({
        endpoint: ENDPOINTS.pesquisaSolicitacaoViagem,
        method: 'GET',
        payload: {}
    })
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })
    const { setLoading } = useSpinner()

    useEffect(() => {
        get(
            `${ENDPOINTS.usuarioByCodigo.replace(':Codigo', user.codigo)}`
        ).then(({ data: { Model } }) => {
            if (Model) {
                setIdUsuario(Model?.id)
            }
        })
    }, [])

    useEffect(() => {
        if (id !== 0) {
            const buscarPrestacaoIndividual = async () => {
                await get(`${ENDPOINTS.statusPrestacaoIndividual}${id}`)
                    .then(({ data: { Model } }) => {
                        setViajantes(Model)
                    })
                    .catch(err => {
                        setShowToast(true)
                        setToast({
                            message: err.statusText,
                            type: 'error'
                        })
                    })
            }
            buscarPrestacaoIndividual()
        }
    }, [id])

    useEffect(() => {
        const sol = localStorage.getItem('solicitacao')
        if (sol !== null) {
            if (
                new Date(JSON.parse(sol).dtCreate).toLocaleDateString() ===
                new Date().toLocaleDateString()
            ) {
                setPossuiRascunho(true)
            } else {
                limparStorageSolicitacaoViagem()
            }
        }
    }, [])

    const mapper: MapperItem[] = [
        { field: 'IdSolicitacao', replaceTo: 'ID', position: 1 },

        { field: 'Viajantes', visualCustom: _PipelistPeople, position: 2 },
        {
            field: 'DataCadastro',
            replaceTo: 'Data do Cadastro',
            visualCustom: _PipeCustomDate,
            position: 3
        },
        { field: 'Tipo', position: 4 },
        {
            field: 'Vinculo',
            replaceTo: 'Vínculo',
            visualCustom: _listStage,
            position: 5
        },
        {
            field: 'Situacao',
            replaceTo: 'Situação',
            visualCustom: _PipeCustomStatus,
            position: 6
        },
        { field: 'IdSolicitacaoViagem', delete: true },
        { field: 'Viajante', delete: true },
        { field: 'ViajantePrincipal', delete: true },
        { field: 'ValorViagem', delete: true },
        { field: 'FilialViajantePrincipal', delete: true },
        { field: 'CotacaoSolicitacaoViagem', delete: true },
        { field: 'Etapas', delete: true }
    ]

    const tableConfig: ConfigTable = {
        edit: false,
        delete: false,
        moreOptions: [
            {
                label: ' Aprovação',
                icon: 'file-signature',
                color: '#002A5D',
                conditional: (option: string, item: any) => {
                    return (
                        option === ' Aprovação' &&
                        item.Situacao === 'Autorização pendente'
                    )
                },
                action: (item: any) => {
                    history.push(`/solicitacoes/aprovar/${item.IdSolicitacao}`)
                }
            },
            {
                label: 'Editar',
                icon: 'pencil-alt',
                conditional: (option: string, item: any) => {
                    return (
                        option === 'Editar' &&
                        item.Situacao === 'Autorização pendente'
                    )
                },
                action: (item: any) => {
                    history.push(`/solicitacoes/editar/${item.IdSolicitacao}`)
                }
            },
            {
                label: 'Status dos Viajantes',
                icon: 'user-check',
                conditional: (option: string, item: any) => {
                    return (
                        option === 'Status dos Viajantes' &&
                        (item.Situacao === 'Aguardando prestação de contas' ||
                            item.Situacao ===
                                'Reprovado: prestação de contas' ||
                            item.Situacao === 'Aguardando conferência')
                    )
                },
                action: (item: any) => {
                    setId(item.IdSolicitacao)
                    setShowModal(true)
                }
            },
            {
                label: 'Prestar Contas',
                icon: 'receipt',
                conditional: (option: string, item: any) => {
                    return (
                        option === 'Prestar Contas' &&
                        (item.Situacao === 'Aguardando prestação de contas' ||
                            item.Situacao === 'Reprovado: prestação de contas')
                    )
                },
                action: (item: any) => {
                    history.push(`/prestar-contas/${item.IdSolicitacao}/0`)
                }
            },
            {
                label: 'Nova Etapa',
                icon: 'plus',
                conditional: (option: string, item: any) => {
                    return (
                        item.Situacao !== 'Cancelada' &&
                        item.Situacao !== 'Finalizada' &&
                        item.Situacao !== 'Aguardando finalização na tesouraria'
                    )
                },
                action: (item: any) => {
                    history.push(`/nova-etapa/${item.IdSolicitacao}`)
                }
            },
            {
                label: 'Histórico',
                icon: 'history',
                action: (item: any) => {
                    history.push(`/historico/${item.IdSolicitacao}`)
                }
            },
            {
                label: 'Imprimir',
                icon: 'print',
                action: (item: any) => {
                    history.push(`/impressao/${item.IdSolicitacao}`)
                }
            },
            {
                label: 'Detalhes',
                icon: 'info',
                action: (item: any) => {
                    history.push(`/solicitacoes/detalhes/${item.IdSolicitacao}`)
                }
            }
        ]
    }

    const alterarSolicitacao = () => {
        let data = 0
        if (approving) {
            get(`${ENDPOINTS.solicitacaoViagemPorId}/${idSolicitacao}`).then(
                async ({ data: { Model } }) => {
                    const aereos = Model.Aereos
                    const hospedagem = Model.Hospedagem
                    const servico = Model.Servico
                    if (aereos.length || hospedagem.length || servico.length) {
                        data = 6 // Em Cotação
                    } else {
                        data = 3 // Aguardando Prestação de Contas
                    }
                    patch(
                        `${ENDPOINTS.solicitarViagem}?Id=${idSolicitacao}&idSituacao=${data}`
                    )
                        .then(({ data: { Model } }) => {
                            window.location.reload()
                        })
                        .catch(err => {
                            setShowAlert({
                                status: true,
                                message: err.data,
                                type: 'danger'
                            })
                        })
                }
            )
        } else {
            patch(
                `${
                    ENDPOINTS.solicitarViagem
                }?Id=${idSolicitacao}&idSituacao=${8}`
            )
                .then(({ data: { Model } }) => {
                    window.location.reload()
                })
                .catch(err => {
                    setShowAlert({
                        status: true,
                        message: err.data,
                        type: 'danger'
                    })
                })
        }
    }

    const handleSubmit: SubmitHandler<FormData> = (data: any) => {
        Object.keys(data).forEach(
            k => !data[k] && data[k] !== undefined && delete data[k] // eliminando valores não preenchidos para envio da query de busca
        )
        data.page = 0
        data.lastCount = 0
        data.totalRegisterPage = 10
        const newPayloadSearch = { ...payloadSearch, payload: data }
        setPayloadSearch(newPayloadSearch)
    }

    function _PipelistPeople(
        value: Array<any> | string,
        index: number
    ): JSX.Element {
        if (typeof value == 'object' && value.length) {
            return (
                <>
                    <div className="btn-group">
                        <button
                            type="button"
                            className="form-select removed-focus"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                                border: 'none',
                                paddingLeft: '0'
                            }}
                        >
                            <Text>{String(value[0].Nome)}</Text>
                        </button>
                        <ul
                            className="dropdown-menu"
                            style={{ fontSize: '0.7777rem' }}
                        >
                            {value.map((i: any, index) => (
                                <li key={index}>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        style={{
                                            pointerEvents: 'none',
                                            cursor: 'default'
                                        }}
                                    >
                                        {i.Nome}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )
        } else {
            return <>{value}</>
        }
    }

    function _listStage(value: number, index: number): JSX.Element {
        if (value) return <Text>{`Etapa Sol. ${value}`}</Text>
        return <>-</>
    }

    function _PipeCustomStatus(value: string): JSX.Element {
        switch (value) {
            case STATUS_SOLICITACAO.FINALIZADA:
                return <>{Legenda(4, value)}</>
            case STATUS_SOLICITACAO.AUTORIZACAO_PENDENTE:
            case STATUS_SOLICITACAO.AGUARDANDO_CONFERENCIA:
            case STATUS_SOLICITACAO.AGUARDANDO_FINALIZAR_NA_TESOURARIA:
            case STATUS_SOLICITACAO.EM_COTACAO:
            case STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS:
                return (
                    <Text>
                        {value === 'Aguardando prestação de contas'
                            ? Legenda(2, 'Aguardando prest. contas')
                            : value === 'Aguardando finalização na tesouraria'
                            ? Legenda(2, 'Finalização tesouraria')
                            : Legenda(2, value)}
                    </Text>
                )
            case STATUS_SOLICITACAO.REPROVADO:
            case STATUS_SOLICITACAO.REPROVADO_PRESTACAO_DE_CONTAS:
                return (
                    <>
                        {value === 'Reprovado: prestação de contas'
                            ? Legenda(3, 'Reprovado: prest. contas')
                            : Legenda(3, value)}
                    </>
                )
            case STATUS_SOLICITACAO.CANCELADO:
                return <>{Legenda(5, value)}</>
            default:
                return (
                    <>
                        {value === 'Rascunho' ? (
                            Legenda(1, value)
                        ) : (
                            <i
                                className="text-danger"
                                style={{ margin: 'auto' }}
                            >
                                {value}
                            </i>
                        )}
                    </>
                )
        }
    }

    function _PipeCustomDate(value: string) {
        const date = new Date(value)
        return <Text>{date.toLocaleDateString()}</Text>
    }

    const limparStorageSolicitacaoViagem = () => {
        try {
            const id = localStorage.getItem('idSolicitacao')
            setLoading(true)
            deletarDadosAncoradouro(id || '').then(() => {
                destroy(`${ENDPOINTS.deletarSolicitacaoViagemTemp}/${id}`).then(
                    async ({ data: { Model } }) => {
                        localStorage.removeItem('solicitacao')
                        localStorage.removeItem('idSolicitacao')
                        setPossuiRascunho(false)
                        window.location.reload()
                    }
                )
            })
        } catch (error) {
            setLoading(false)
        }
    }

    const deletarDadosAncoradouro = async (aereo: string) => {
        const newAereo: any = {
            idSolicitacao: parseInt(aereo)
        }
        if (aereo) {
            const token = localStorage.getItem('@ViagensCorp:token')
            await axios
                .post(ENDPOINTS.cancelarReserva, newAereo, {
                    baseURL: BASE_URL_AEREO,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then()
                .catch((error: any) => {
                    return error
                })
        }
    }

    function conditionalFilters(flagFilter: boolean) {
        if (flagFilter) {
            return (
                <div className="row">
                    <div className="col-12 col-sm-3">
                        {' '}
                        <SelectRequester
                            className="scale-in-ver-top"
                            request={{
                                endpoint: ENDPOINTS.situacao,
                                labelToSelect: 'Nome',
                                valueToSelect: 'Id'
                            }}
                            name="idSituacao"
                            label="Situação"
                            nomeCampoDefault="Nome"
                            valueDefault="Selecionar todos"
                        ></SelectRequester>
                    </div>
                    <div className="col-12 col-sm-3">
                        {' '}
                        <SelectRequester
                            className="scale-in-ver-top"
                            items={true}
                            request={{
                                endpoint: ENDPOINTS.filial,
                                labelToSelect: 'Nome',
                                valueToSelect: 'Id'
                            }}
                            name="idFilial"
                            label="Filial"
                            valueDefault="Selecionar todos"
                        ></SelectRequester>
                    </div>
                    <div className="col-12 col-sm-3">
                        {' '}
                        <SelectRequester
                            className="scale-in-ver-top"
                            request={{
                                endpoint: ENDPOINTS.setor,
                                labelToSelect: 'Nome',
                                valueToSelect: 'Id'
                            }}
                            name="idSetor"
                            label="Setor"
                            valueDefault="Selecionar todos"
                        ></SelectRequester>
                    </div>
                    <div className="col-12 col-sm-3">
                        <SelectRequester
                            className="scale-in-ver-top"
                            request={{
                                endpoint: ENDPOINTS.regiaoListar,
                                labelToSelect: 'Nome',
                                valueToSelect: 'Id'
                            }}
                            name="idRegiao"
                            label="Região"
                            valueDefault="Selecionar todos"
                        ></SelectRequester>
                    </div>
                    <div className="col-12 col-sm-3">
                        <SelectRequester
                            request={{
                                endpoint: ENDPOINTS.tipoSolicitacao,
                                labelToSelect: 'Nome',
                                valueToSelect: 'Id'
                            }}
                            name="idTipoSolicitacao"
                            label="Tipo de Solicitação"
                            valueDefault="Selecionar todos"
                        ></SelectRequester>
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }

    return (
        <>
            <BlankBoard>
                <Header title="Solicitações"></Header>
                {possuiRascunho && (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="exclamation"
                        message="Você possui uma solicitação em rascunho, clique no
                        botão ao lado para continuar"
                    ></Alert>
                )}

                <Center>
                    <Mobile>
                        {possuiRascunho ? (
                            <>
                                <Button
                                    icon={possuiRascunho ? 'play' : 'plus'}
                                    colorType="btnMobile"
                                    onClick={() =>
                                        history.push(
                                            '/solicitar-viagem/incluir-viajantes'
                                        )
                                    }
                                >
                                    {possuiRascunho
                                        ? 'Continuar solicitação'
                                        : 'Criar Solicitação'}
                                </Button>
                                <Button
                                    icon="trash-alt"
                                    colorType="btnMobile"
                                    onClick={() =>
                                        limparStorageSolicitacaoViagem()
                                    }
                                >
                                    Deletar
                                </Button>
                            </>
                        ) : (
                            <Center>
                                <Button
                                    icon="plus"
                                    colorType="btnMobile"
                                    onClick={() =>
                                        history.push(
                                            '/solicitar-viagem/incluir-viajantes'
                                        )
                                    }
                                >
                                    Criar Solicitação
                                </Button>
                            </Center>
                        )}
                    </Mobile>
                </Center>
                <Flex justifyContent="flex-end">
                    <Desktop>
                        {possuiRascunho ? (
                            <>
                                <Button
                                    style={{
                                        marginRight: '-4rem'
                                    }}
                                    icon={possuiRascunho ? 'play' : 'plus'}
                                    colorType="quaternary"
                                    onClick={() =>
                                        history.push(
                                            '/solicitar-viagem/incluir-viajantes'
                                        )
                                    }
                                >
                                    {possuiRascunho
                                        ? 'Continuar solicitação'
                                        : 'Criar Solicitação'}
                                </Button>

                                <Button
                                    style={{
                                        marginRight: '-3.8rem'
                                    }}
                                    icon="trash-alt"
                                    colorType="oitotiary"
                                    onClick={() =>
                                        limparStorageSolicitacaoViagem()
                                    }
                                >
                                    Deletar
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    icon="plus"
                                    colorType="quaternary"
                                    style={{
                                        marginRight: '-1.9rem'
                                    }}
                                    onClick={() =>
                                        history.push(
                                            '/solicitar-viagem/incluir-viajantes'
                                        )
                                    }
                                >
                                    Criar Solicitação
                                </Button>
                            </>
                        )}
                    </Desktop>
                </Flex>
                <Form
                    onSubmit={handleSubmit}
                    ref={formRef}
                    style={{ padding: 0 }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '1rem 0'
                            }}
                        >
                            <Button
                                icon="filter"
                                colorType="primary"
                                onlyIcon={true}
                                style={
                                    flagFilter
                                        ? {
                                              backgroundColor: 'darkGray',
                                              padding: '0 15px'
                                          }
                                        : {}
                                }
                                onClick={() => setFlagFilter(!flagFilter)}
                            ></Button>
                            <LabelFilterText>Filtros Avançados</LabelFilterText>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                margin: '1rem 0'
                            }}
                        >
                            <Button
                                style={{
                                    margin: '0 .2rem',
                                    padding: '0 15px'
                                }}
                                onlyIcon={true}
                                icon="search"
                                title="Pesquisar"
                                colorType="primary"
                            ></Button>
                            <Button
                                onlyIcon={true}
                                customIcon={CleanIcon}
                                colorType="danger"
                                title="Limpar Filtros"
                                type="button"
                                onClick={() => {
                                    formRef.current?.reset()
                                }}
                            ></Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-3">
                            <Input
                                placeholder="Digite o id da solicitacao"
                                label="Id Solicitacão"
                                name="id"
                                maxLength={10}
                            ></Input>
                        </div>
                        <div className="col-12 col-sm-3">
                            <Input
                                placeholder="Digite o código do viajante"
                                label="Código"
                                name="CodigoUsuario"
                                maxLength={10}
                            ></Input>
                        </div>
                        <div className="col-6 col-sm-3">
                            <DatePicker
                                name="dataInicial"
                                label="Data Início"
                            ></DatePicker>
                        </div>
                        <div className="col-6 col-sm-3">
                            <DatePicker
                                name="dataFinal"
                                label="Data Final"
                            ></DatePicker>
                        </div>
                    </div>
                    {conditionalFilters(flagFilter)}
                </Form>
                <Table
                    requester={payloadSearch}
                    mapper={mapper}
                    config={tableConfig}
                ></Table>
            </BlankBoard>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
            {showModal ? (
                <Modal
                    height={'30rem'}
                    setShow={setShowModal}
                    title={'Status de solicitação individual'}
                >
                    {
                        <div
                            className="table-responsive"
                            style={{
                                border: 'solid 1px lightgray',
                                borderRadius: '15px',
                                marginTop: '3rem'
                            }}
                        >
                            <table className="table">
                                <thead
                                    style={{
                                        borderBottom: 'solid 1px lightgray'
                                    }}
                                >
                                    <tr
                                        style={{
                                            border: '0 !important'
                                        }}
                                    >
                                        <Th
                                            style={{
                                                width: '12rem'
                                            }}
                                            scope="col"
                                        >
                                            Viajante
                                        </Th>
                                        <Th
                                            style={{
                                                width: '12rem',
                                                textAlign: 'center'
                                            }}
                                            scope="col"
                                        >
                                            Situação
                                        </Th>
                                        <Th
                                            style={{
                                                width: '12rem'
                                            }}
                                            scope="col"
                                        >
                                            Prestar Contas
                                        </Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {viajantes.map((object: any, key) => {
                                        return (
                                            <tr key={key}>
                                                <td
                                                    style={{
                                                        width: '12rem',
                                                        verticalAlign: 'middle'
                                                    }}
                                                >
                                                    {object.Nome}
                                                </td>
                                                <td
                                                    style={{
                                                        width: '12rem',
                                                        verticalAlign: 'middle'
                                                    }}
                                                >
                                                    {_PipeCustomStatus(
                                                        object.Status
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        width: '12rem',
                                                        verticalAlign: 'middle'
                                                    }}
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            history.push(
                                                                `/prestar-contas/${id}/${object.Id}`
                                                            )
                                                        }
                                                        disabled={
                                                            object.Status !==
                                                                STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS &&
                                                            object.Status !==
                                                                STATUS_SOLICITACAO.REPROVADO_PRESTACAO_DE_CONTAS
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon="receipt" />
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                </Modal>
            ) : null}
        </>
    )
}

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
`

const LabelFilterText = styled.p`
    margin: 0 1rem;
    color: ${props => props.theme.colors.primary};
    font-size: 0.9rem;
    font-weight: 600;
    font: 'Montserrat, sas-serif';
`
const TextLegend = styled.div`
    position: relative;
    width: 45rem;
    padding: 1rem 1rem;
    color: #896701;
    border-radius: 0.5rem;z
    text-align: start;
    font-weight: 500;
    background: #ffd75e;
    justify-content: center;
    font-size: 0.9375rem;    
    font: 'Montserrat, sas-serif';
    
    
    @media (max-width: 800px) {
        width: 20rem;
        padding: 10px;
    }
`

const Desktop = styled.div`
    display: flex;
    @media (max-width: 800px) {
        display: none;
    }
`

const Desktopx = styled.div`
    display: flex;
    justify-content: 'flex-end';
    @media (max-width: 768px) {
        display: none;
    }
`

const Mobile = styled.div`
    display: flex;
    justify-content: 'center';
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`
const Text = styled.p``

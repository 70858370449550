import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button as ButtonBs } from 'reactstrap'
import styled from 'styled-components'

interface Props {
    children?: React.ReactNode
    colorType?: string
    style?: React.CSSProperties
    type?: 'reset' | 'button' | 'submit' | undefined
    icon?: IconProp
    onClick?: () => void
    onlyIcon?: boolean
    customIcon?: string
}
type ButtonProps = JSX.IntrinsicElements['button'] & Props

enum ColorType {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    option = 'option',
    quaternary = 'quaternary',
    quintiary = 'quintiary',
    sextiary = 'sextiary',
    setetiary = 'setetiary',
    oitotiary = 'oitotiary',
    circular = 'circular',
    btnMobile = 'btnMobile'
}

export const Button = ({
    children,
    colorType,
    type = 'submit',
    onClick,
    style,
    icon = 'angle-right',
    onlyIcon = false,
    customIcon = undefined,
    ...tagProps
}: ButtonProps) => {
    style = onlyIcon
        ? {
              ...style,
              borderRadius: '5px',
              minWidth: 'unset',
              minHeight: '2.3rem'
          }
        : style
    return (
        <ButtonBs
            type={type}
            className={colorType === 'option' ? 'btn-no-focus' : ''}
            style={style}
            color={colorType}
            onClick={onClick}
            {...(tagProps as any)}
        >
            {(() => {
                if (onlyIcon) {
                    if (customIcon)
                        return (
                            <CustomIconStyle>
                                <img src={customIcon} />
                            </CustomIconStyle>
                        )
                    return (
                        <>
                            <FontAwesomeIcon icon={icon} />
                        </>
                    )
                } else {
                    // Botão com texto e ícone
                    switch (colorType) {
                        case ColorType.primary:
                            return (
                                <>
                                    <span style={{ marginLeft: '-8px' }}>
                                        {children}
                                    </span>
                                    <span className="rightBallPrimary">
                                        <span className="chevronRight">
                                            <FontAwesomeIcon
                                                icon={icon}
                                                color="#FFF"
                                            />
                                        </span>
                                    </span>
                                </>
                            )
                        case ColorType.secondary:
                            return (
                                <>
                                    <span style={{ marginLeft: '-8px' }}>
                                        {children}
                                    </span>
                                    <span className="rightBallSecundary">
                                        <span className="chevronRight">
                                            <FontAwesomeIcon icon={icon} />
                                        </span>
                                    </span>
                                </>
                            )
                        case ColorType.tertiary:
                            return (
                                <>
                                    <span
                                        style={{
                                            marginLeft: '-8px',
                                            color: '',
                                            backgroundColor: 'red',
                                            boxShadow:
                                                '0px 2px 4px rgba(0, 0, 0, 0.25)'
                                        }}
                                    >
                                        {children}
                                    </span>
                                    <span className="rightBallTertiary">
                                        <span className="chevronRight">
                                            <FontAwesomeIcon icon={icon} />
                                        </span>
                                    </span>
                                </>
                            )
                        case ColorType.option:
                            return (
                                <>
                                    <span
                                        style={{
                                            padding: '5px 10px',
                                            borderRadius: '50px',
                                            backgroundColor: '#002A5D',
                                            color: 'white'
                                        }}
                                    >
                                        {children}

                                        <span
                                            style={{
                                                color: '#fff',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </span>
                                    </span>
                                </>
                            )
                        case ColorType.quaternary:
                            return (
                                <>
                                    <span
                                        style={{
                                            background: '#002A5D',
                                            backgroundColor: '#002A5D',
                                            color: '#fff',
                                            borderRadius: '0.8rem',
                                            padding: '1rem 1rem',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {children}

                                        <span
                                            style={{
                                                color: '#fff',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </span>
                                    </span>
                                </>
                            )
                        case ColorType.quintiary:
                            return (
                                <>
                                    <span
                                        style={{
                                            background: '#fff',
                                            color: '#002A5D',
                                            borderRadius: '0.8rem',
                                            padding: '1rem 1rem',
                                            border: '1px solid #002A5D',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {children}

                                        <span
                                            style={{
                                                color: '#002A5D',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </span>
                                    </span>
                                </>
                            )
                        case ColorType.sextiary:
                            return (
                                <>
                                    <span
                                        style={{
                                            background: '#002A5D',
                                            backgroundColor: '#002A5D',
                                            color: '#fff',
                                            borderRadius: '0.8rem',
                                            padding: '1rem 1rem',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: '#fff',
                                                marginRight: '0.6rem'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </span>

                                        {children}

                                        <span
                                            style={{
                                                color: '#fff',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </span>
                                    </span>
                                </>
                            )
                        case ColorType.setetiary:
                            return (
                                <>
                                    <span
                                        style={{
                                            background: '#fff',
                                            color: '#002A5D',
                                            borderRadius: '0.8rem',
                                            padding: '1rem 1rem',
                                            border: '1px solid #002A5D',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: '#002A5D',
                                                marginRight: '0.6rem'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </span>
                                        {children}

                                        <span
                                            style={{
                                                color: '#002A5D',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </span>
                                    </span>
                                </>
                            )
                        case ColorType.oitotiary:
                            return (
                                <>
                                    <span
                                        style={{
                                            background: '#C82333',
                                            color: '#fff',
                                            borderRadius: '0.8rem',
                                            padding: '1rem 1rem',
                                            border: '1px solid #C82333',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {/* <span
                                            style={{
                                                color: '#fff',
                                                marginRight: '0.6rem'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </span> */}
                                        {children}

                                        <span
                                            style={{
                                                color: '#fff',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </span>
                                    </span>
                                </>
                            )
                        case ColorType.circular:
                            return (
                                <>
                                    <span
                                        style={{
                                            background: '#002A5D',
                                            backgroundColor: '#002A5D',
                                            color: '#fff',
                                            borderRadius: '100%',
                                            padding: '1rem 1.2rem',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: '#fff'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </span>
                                    </span>
                                </>
                            )
                        case ColorType.btnMobile:
                            return (
                                <>
                                    <span
                                        style={{
                                            background: '#002A5D',
                                            backgroundColor: '#002A5D',
                                            color: '#fff',
                                            fontWeight: 'bold',

                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '0.8rem',
                                            width: '21.3rem',
                                            height: '2.3rem'
                                        }}
                                    >
                                        {children}

                                        <span
                                            style={{
                                                color: '#fff',
                                                paddingLeft: '10px'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </span>
                                    </span>
                                </>
                            )
                        default:
                            return <span>{children}</span>
                    }
                }
            })()}
        </ButtonBs>
    )
}

const CustomIconStyle = styled.span`
    color: ${props => props.theme.colors.primary};
    font-size: 0.9rem;
    font-weight: 600;
    img {
        width: 1rem;
    }
`

import React, { useEffect, useRef, useState, useContext } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Header } from '../../../../core/components/Header/Header'
import { Form } from '@unform/web'
import { Button } from '../../../../core/components/Button'
import { Space } from '../../../../core/components/Space'
import { Input } from '../../../../core/components/Form/Input'
import { Select } from '../../../../core/components/Form/Select'
import { useHistory, useParams } from 'react-router'
import { Center } from '../../../../core/components/Center'
import * as Yup from 'yup'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { get, post, put } from '../../../../services/http'
import { ENDPOINTS } from '../../../../services/config-http'
import { Subtitle } from '../../../../core/components/Subtitle'
import { UrlMountQuery } from '../../../../core/utils/UrlMountQuery'
import { Adiantamento } from '../../../../contexts/interfaces/adiantamento'
import { Viajante } from '../../../../contexts/interfaces/viajante'
import { Alert } from '../../../../core/components/Alert'
import styled from 'styled-components'
import { Empty } from '../../../../core/components/Empty'
import Switch from 'react-switch'
import moment from 'moment'
import { LineSeparator } from '../../../../core/components/LineSeparator'
import { formataValor } from '../../../../util/legenda/util'
import { InputCurrency } from '../../../../core/components/Form/InputCurrency'
import { MoneyFormat } from '../../../../core/utils/MoneyFormat'
import { IntlRealFormat } from '../../../../core/utils/IntlFormat'
import { UserContext } from '../../../../contexts/UserContext'
import { DisableForMobile, IsMobile } from '../stepMobile'
import {
    Box,
    Quadro,
    TrMobile,
    getCampo
} from '../../../../core/components/Mobile'

export const EditarAdiantamentoForm = () => {
    const FormRef = useRef<FormHandles>(null)
    const history = useHistory()
    const { Id } = useParams() as any
    const [adiantamento, setAdiantamento] = useState<Array<Adiantamento>>([])
    const [viajantes, setViajantes] = useState<Array<Viajante>>([])
    const [tipoDivisao, setTipoDivisao] = useState<any>('')
    const [valorTotal, setValorTotal] = useState(0)
    const [solicitacao, setSolicitacao] = useState<any>()
    const [valorMaximo, setValorMaximo] = useState(0)
    const [valorPersonalizado, setValorPersonalizado] = useState<any>([])
    const [dados, setDados] = useState<any>([])
    const [dadosUsuario, setDadosUsuario] = useState<any>([])
    const user = useContext(UserContext)
    const [requerAdiantamento, setRequerAdiantamento] = useState<boolean>(false)
    const [showAdiantamentos, setShowAdiantamentos] = useState(false)

    const [descAdiantamentoPrincipal, setDescAdiantamentoPrincipal] =
        useState<any>()
    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'primary'
    })

    useEffect(() => {
        get(`${ENDPOINTS.solicitacaoViagemPorId}/${Id}`).then(
            async ({ data: { Model } }) => {
                const solicitacao = Model
                setSolicitacao(solicitacao)
            }
        )
    }, [])

    useEffect(() => {
        if (showAdiantamentos) {
            carregarAdiantamento()
            carregarViajantes()
            carregarSolicitacaoViagemPorId()
        }
    }, [showAdiantamentos])

    useEffect(() => {
        const dados: { id: any; telefone: string }[] = []
        if (!dadosUsuario.length && solicitacao) {
            solicitacao?.Viajantes?.map(item => {
                get(
                    UrlMountQuery(
                        { Codigo: item.Codigo },
                        ENDPOINTS.usuarioByCodigo
                    )
                ).then(({ data: { Model } }) => {
                    dados.push({
                        id: Model?.Codigo,
                        telefone: `(${Model?.DDD}) ${Model?.Telefone}`
                    })
                })
            })
            setDadosUsuario(dados)
        }
    }, [solicitacao])

    useEffect(() => {
        if (valorTotal > 0) {
            trataTipoDivisao()
        }
    }, [valorTotal])

    useEffect(() => {
        if (solicitacao?.TipoSolicitacao) {
            buscaRegra(
                solicitacao.TipoSolicitacao === 'Viagem Completa' ? 1 : 2
            )
        }
    }, [solicitacao])

    const buscaRegra = async (id: number) => {
        if (id !== 0) {
            await get(`${ENDPOINTS.regrasPoliticaViagem}/${id}`)
                .then(({ data: { Model } }) => {
                    const { UtilizarAdiantamentos } = Model
                    setShowAdiantamentos(UtilizarAdiantamentos)
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    const configuraAdiantamento = (
        solicitacao: any,
        valorTotalAdiantamento: number
    ) => {
        if (solicitacao) {
            const paramValor: { idViajante: number; valor: number }[] = []
            if (solicitacao?.Adiantamentos.length) {
                solicitacao?.Adiantamentos?.map(item => {
                    paramValor.push({
                        idViajante: item.IdUsuario,
                        valor: item.Valor
                    })
                })
                viajantes.map(viajante => {
                    if (
                        adiantamento?.filter(
                            ad => ad?.IdUsuario === viajante.IdUsuario
                        ).length === 0
                    ) {
                        paramValor.push({
                            idViajante: viajante.IdUsuario,
                            valor: 0
                        })
                    }
                })
            } else {
                setTipoDivisao('1')
                solicitacao?.Viajantes?.map(item => {
                    paramValor.push({
                        idViajante: item.IdUsuario,
                        valor: item.Valor
                    })
                })
            }
            setValorPersonalizado(paramValor)
            get(
                `${ENDPOINTS.usuarioByCodigo.replace(
                    ':Codigo',
                    solicitacao?.Viajantes?.filter(
                        (s: Viajante) => s.Convidado === false
                    )[0]?.Codigo
                )}/`
            ).then(({ data: { Model } }) => {
                let dataInicio
                let dataTermino
                let days = 0

                solicitacao?.Destinos?.forEach((item: any) => {
                    dataInicio = new Date(
                        `${String(
                            new Date(item.DataInicio).getMonth() + 1
                        )}-${new Date(item.DataInicio)
                            .getUTCDate()
                            .toString()}-${new Date(item.DataInicio)
                            .getFullYear()
                            .toString()}`
                    )
                    dataTermino = new Date(
                        `${String(
                            new Date(item.DataTermino).getMonth() + 1
                        )}-${new Date(item.DataTermino)
                            .getUTCDate()
                            .toString()}-${new Date(item.DataTermino)
                            .getFullYear()
                            .toString()}`
                    )
                    const dataInicioDestino = moment(dataInicio)
                    const dataTerminoDestino = moment(dataTermino)
                    const duration = moment.duration(
                        dataInicioDestino.diff(dataTerminoDestino)
                    )

                    days = parseInt(duration.asDays().toString())
                })

                const numeroDias = Math.abs(days) + 1
                politicaPermissaoPorIdPolitica(
                    numeroDias,
                    Model.IdPoliticaViagem,
                    solicitacao
                )
            })
        }
    }

    const trataTipoDivisao = () => {
        if (solicitacao?.Adiantamentos?.length > 0) {
            setRequerAdiantamento(true)
            const valorTotalDividido =
                valorTotal / solicitacao.Adiantamentos.length
            let qtdIguais = 0
            solicitacao?.Adiantamentos.forEach(item => {
                if (valorTotalDividido === item.Valor) {
                    qtdIguais += 1
                }
            })
            setTipoDivisao(
                qtdIguais === solicitacao?.Adiantamentos.length ? '1' : '2'
            )
            if (qtdIguais !== solicitacao?.Adiantamentos.length) {
                const newValorPersonalizado: Array<any> = []
                solicitacao?.Adiantamentos?.forEach((adiantamento: any) => {
                    newValorPersonalizado.push({
                        idViajante: adiantamento.IdUsuario,
                        valor: adiantamento.Valor
                    })
                })

                viajantes.map(viajante => {
                    if (
                        adiantamento?.filter(
                            ad => ad?.IdUsuario === viajante.IdUsuario
                        ).length === 0
                    ) {
                        newValorPersonalizado.push({
                            idViajante: viajante.IdUsuario,
                            valor: 0
                        })
                    }
                })
                setValorPersonalizado(newValorPersonalizado)
            } else {
                const newValorPersonalizado: Array<any> = []
                solicitacao?.Viajantes?.forEach((viajante: any) => {
                    newValorPersonalizado.push({
                        idViajante: viajante.IdUsuario,
                        valor: 0
                    })
                })
                setValorPersonalizado(newValorPersonalizado)
            }
        }
    }

    const politicaPermissaoPorIdPolitica = async (
        numeroDias: number,
        idPoliticaViagem: number,
        solicitacao: any
    ) => {
        await get(
            `${ENDPOINTS.politicaPermissaoPorIdPolitica}/${idPoliticaViagem}`
        )
            .then(({ data: { Model } }) => {
                let valorCalculo = 0
                Model.forEach((e: any) => {
                    if (e.ParticipaCalculo === true) {
                        valorCalculo += e.ValorMaximo
                    }
                })
                const numeroViajantes = solicitacao?.Viajantes?.length
                const calculoValor =
                    valorCalculo * numeroDias * numeroViajantes || 0
                setValorMaximo(calculoValor)
                setValorTotal(calculoValor)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const carregarAdiantamento = () => {
        get(`${ENDPOINTS.adiantamentoByIdSolicitacao}${Id}/IdSolicitacao`)
            .then(res => {
                setAdiantamento(res.data.Model)
                setDescAdiantamentoPrincipal(res.data.Model[0].Descricao)
                FormRef.current?.setData({
                    Descricao: res.data.Model[0].Descricao,
                    Valor: res.data.Valor
                })
            })
            .catch(err => {
                setShowAlert([
                    {
                        status: true,
                        message: err.data,
                        type: 'primary'
                    }
                ])
            })
    }

    const carregarViajantes = () => {
        get(`${ENDPOINTS.viajanteByIdSolicitacao}/${Id}`)
            .then(({ data: { Model } }) => {
                if (Model && Model?.length) {
                    setViajantes(Model)
                }
            })
            .catch(err => {
                setShowAlert({
                    status: true,
                    message: err.data,
                    type: 'primary'
                })
            })
    }

    useEffect(() => {
        let valor = 0
        if (valorPersonalizado.length > 1) {
            valorPersonalizado.map((item: { valor: any }) => {
                if (item?.valor) {
                    valor += formataValor(item?.valor)
                    let msg = ''
                    let valorMsg
                    if (valorTotal && valor != valorTotal) {
                        valorMsg = valorTotal - valor
                        if (valor > valorTotal) {
                            msg = `(você ultrapassou o valor total do adiantamento em ${IntlRealFormat(
                                valorMsg
                            )})`
                        } else if (valor < valorTotal) {
                            msg = `(Esta faltando ${IntlRealFormat(
                                valorMsg
                            )}) para o valor total do adiantamento)`
                        }
                        // setHabilitarBtn(false)
                        setShowAlert({
                            status: true,
                            message: `A soma do adiantamento  para cada viajante deve ter o mesmo valor do adiantamento solicitado para a viagem ${
                                msg && msg
                            }.`,
                            type: 'primary'
                        })
                    } else {
                        // setHabilitarBtn(true)
                        setShowAlert({
                            status: false,
                            message: '',
                            type: 'primary'
                        })
                    }
                }
            })
        }
    }, [valorPersonalizado, valorTotal])

    const handleSubmit: SubmitHandler<Adiantamento> = async (
        data,
        { reset }
    ) => {
        try {
            const schema = Yup.object().shape({
                ValorTotal: Yup.string().required(
                    'O Campo valor é obrigatório'
                ),
                Descricao: Yup.string().required(
                    'O Campo descrição é obrigatório'
                )
            })

            await schema.validate(data, { abortEarly: false })
            let valorTratado: number
            FormRef.current?.setErrors({})
            let valorTotalFormatado = 0
            if (tipoDivisao === '2') {
                valorPersonalizado.forEach((e: any) => {
                    valorTotalFormatado += formataValor(e.valor)
                })

                if (valorMaximo < valorTotalFormatado) {
                    msgValidacao()
                    return
                }
                await schema.validate(data, { abortEarly: false })
                FormRef.current?.setErrors({})
            } else {
                valorTotalFormatado = valorTotal
                if (valorMaximo < valorTotalFormatado) {
                    msgValidacao()
                    return
                }
                await schema.validate(data, { abortEarly: false })
                FormRef.current?.setErrors({})
            }

            viajantes.map(viajante => {
                if (
                    adiantamento?.filter(
                        ad => ad?.IdUsuario === viajante.IdUsuario
                    ).length === 0
                ) {
                    let valor

                    if (tipoDivisao === '2') {
                        valorPersonalizado.forEach(param => {
                            if (param.idViajante === viajante.IdUsuario) {
                                valor = formataValor(param.valor)
                            }
                        })
                    } else {
                        valor = valorTotal / viajantes.length
                    }

                    cadastrarAdiantamento(
                        data.Descricao,
                        valor,
                        viajante.IdUsuario
                    )
                }

                if (adiantamento.length) {
                    adiantamento?.map((item: any, index: number) => {
                        if (viajante.IdUsuario === item?.IdUsuario) {
                            if (tipoDivisao === '1') {
                                valorTratado = valorTotal / viajantes.length
                            } else {
                                if (
                                    valorPersonalizado[index].idViajante ===
                                    item.IdUsuario
                                ) {
                                    valorTratado = formataValor(
                                        valorPersonalizado[index].valor
                                    )
                                }
                            }
                            alterarAdiantamento(
                                item,
                                valorTratado,
                                descAdiantamentoPrincipal
                            )
                        }
                    })
                }
            })
            history.push(`/solicitacoes/editar/${Id}`)
        } catch (err) {
            console.log(err)
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                FormRef.current?.setErrors(erros)
            }
        }
    }

    const msgValidacao = () => {
        setShowAlert({
            status: true,
            message: `O valor informado é maior que o máximo permitido ${IntlRealFormat(
                valorMaximo
            )}`,
            type: 'primary'
        })
    }

    const cadastrarAdiantamento = (
        Descricao: string,
        Valor: number,
        IdUsuario: number
    ) => {
        post(ENDPOINTS.adiantamento, {
            Descricao,
            Valor,
            IdSolicitacaoViagem: Id,
            IdUsuario
        })
            .then(res => {
                setShowAlert({
                    status: true,
                    message: res.data,
                    type: 'success'
                })
            })
            .catch(err => {
                setShowAlert({
                    status: true,
                    message: err.data.Text,
                    type: 'primary'
                })
            })
    }

    const alterarAdiantamento = (
        item: any,
        valor: number,
        descricao: string
    ) => {
        put(ENDPOINTS.adiantamento, {
            id: item.Id,
            idSolicitacao: item.IdSolicitacaoViagem,
            idViajante: item?.IdUsuario,
            valor,
            descricao: descricao,
            status: true,
            adiantamentoRecebido: false
        })
            .then(res => {
                setShowAlert({
                    status: true,
                    message: res.data,
                    type: 'success'
                })
            })
            .catch(err => {
                setShowAlert({
                    status: true,
                    message: err.data.Text,
                    type: 'primary'
                })
            })
    }

    const campoTabela = param => {
        return (
            <Th scope="col" colSpan={2}>
                <Text>{param}</Text>
            </Th>
        )
    }
    const campoTabela2 = param => {
        return (
            <td>
                <Text>{param}</Text>
            </td>
        )
    }

    const carregarSolicitacaoViagemPorId = () => {
        setViajantes(solicitacao?.Viajantes)
        // obterDescViagem(Model?.IdMotivoViagem)
        solicitacao?.Viajantes?.map((e: any, key: number) => {
            if (solicitacao.Viajantes[key].Convidado === false) {
                const IdAtual = solicitacao.Viajantes[key].Id
                if (solicitacao?.Adiantamentos) {
                    solicitacao?.Adiantamentos?.map((e: any, key: number) => {
                        if (
                            solicitacao.Adiantamentos[key].IdViajante ===
                            IdAtual
                        ) {
                            setDescAdiantamentoPrincipal(
                                solicitacao?.Adiantamentos[key]?.Descricao
                            )
                            setDados(solicitacao?.Adiantamentos[key])
                        }
                    })
                    let somaAdiantamentos = 0
                    solicitacao?.Adiantamentos?.map((e: any, key: number) => {
                        somaAdiantamentos +=
                            solicitacao?.Adiantamentos[key]?.Valor
                    })
                    // setValorTotal(somaAdiantamentos)
                    configuraAdiantamento(solicitacao, somaAdiantamentos)
                }
            }
        })
    }

    const apresentarTabela = () => {
        return (
            <>
                <label htmlFor="">
                    <Text>Lista de Viajantes</Text>
                </label>
                <div
                    className="table-responsive"
                    style={{
                        border: 'solid 1px lightgray',
                        borderRadius: '15px'
                    }}
                >
                    <table className="table">
                        <thead
                            style={{
                                borderBottom: 'solid 1px lightgray'
                            }}
                        >
                            <tr
                                style={{
                                    border: '0 !important'
                                }}
                            >
                                {campoTabela('Cod')}
                                {campoTabela('Nome')}
                                {campoTabela('')}
                                {campoTabela('Adiantamento')}
                            </tr>
                        </thead>
                        <tbody>
                            {solicitacao?.Viajantes.map((object, key) => {
                                return (
                                    <tr key={key}>
                                        {campoTabela2(object.Codigo)}
                                        {campoTabela2(object.Nome)}
                                        {campoTabela2(
                                            !object.Convidado && (
                                                <ViajantePrincipalDiv
                                                    disabled={true}
                                                >
                                                    Viajante Principal
                                                </ViajantePrincipalDiv>
                                            )
                                        )}
                                        {campoTabela2(
                                            getAdiantamento(solicitacao, key)
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    function getAdiantamento(solicitacao: any, key: number) {
        return (
            <div
                className="input-area-adiantamento"
                style={{
                    display: 'flex !important',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {tipoDivisao === '2' ? (
                    <>
                        <InputCurrency
                            style={{
                                border: 0,
                                borderBottom: '#DFDFDF80 1px solid'
                            }}
                            onChange={(e: any) => {
                                const newValorPersonalizado = [
                                    ...valorPersonalizado
                                ]
                                newValorPersonalizado[key].valor = Number(
                                    e.currentTarget.value
                                        .replaceAll('.', '')
                                        .replaceAll(',', '.')
                                        .replaceAll('R$', '')
                                )
                                setValorPersonalizado([
                                    ...newValorPersonalizado
                                ])
                            }}
                            value={IntlRealFormat(
                                valorPersonalizado[key]?.valor || 0
                            )}
                            width="col-12"
                            label=""
                            name={`input-adiantamento-${key}`}
                            mask="999.999,99"
                            required={true}
                            defaultValue={MoneyFormat(0)}
                        />
                    </>
                ) : (
                    <p
                        className="valor-adiantamento"
                        style={{
                            padding: '0 !important',
                            marginBottom: '0 !important'
                        }}
                    >
                        {requerAdiantamento && solicitacao.Viajantes ? (
                            <>
                                {IntlRealFormat(
                                    valorTotal > 0
                                        ? valorTotal /
                                              solicitacao?.Viajantes?.length
                                        : 0
                                )}
                            </>
                        ) : (
                            <Text>R$ 0,00</Text>
                        )}
                    </p>
                )}
            </div>
        )
    }

    const carregarDadosUsuario = (id: any) => {
        let dados: any
        dadosUsuario.filter(
            (item: { id: string | undefined; telefone: any }) => {
                if (item?.id === id) {
                    dados = item?.telefone
                }
                if (dados === '(null) ') {
                    dados = '-'
                }
            }
        )
        return dados || ''
    }

    function getTabelaMobile() {
        return (
            <Center>
                <Box>
                    {solicitacao?.Viajantes &&
                        solicitacao?.Viajantes.map((tableItem, index) => {
                            if (tableItem) {
                                return (
                                    <Quadro>
                                        <table style={{ width: '100%' }}>
                                            <TrMobile>
                                                {getCampo(
                                                    index,
                                                    'Cod',
                                                    tableItem.Codigo
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Nome',
                                                    tableItem.Nome
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Contato',
                                                    dadosUsuario.length &&
                                                        carregarDadosUsuario(
                                                            tableItem.Codigo
                                                        )
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Adiantamento',
                                                    getAdiantamento(
                                                        solicitacao,
                                                        index
                                                    )
                                                )}
                                            </TrMobile>
                                        </table>
                                    </Quadro>
                                )
                            }
                            return null
                        })}
                </Box>
            </Center>
        )
    }

    function formEditar() {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Switch
                        onColor="#24538B"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        checked={requerAdiantamento}
                        onChange={() => {
                            setRequerAdiantamento(!requerAdiantamento)
                        }}
                    ></Switch>
                    <p className="label-switch">Solicitar adiantamento</p>
                </div>
                <LineSeparator style={{ margin: '2rem 0' }}></LineSeparator>
                <Form ref={FormRef} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            {requerAdiantamento ? (
                                <InputCurrency
                                    value={IntlRealFormat(valorTotal || 0)}
                                    width="col-12"
                                    label="Valor *"
                                    name="ValorTotal"
                                    mask="999.999,99"
                                    required={true}
                                    defaultValue={MoneyFormat(0)}
                                />
                            ) : (
                                <Input
                                    name="Valor"
                                    label="Valor"
                                    value={`R$ ${valorTotal}`}
                                    onChange={(e: any) => {
                                        setValorTotal(formataValor(e))
                                    }}
                                    required={true}
                                    disabled={true}
                                />
                            )}
                        </div>
                        <div className="col-12 col-sm-4">
                            {tipoDivisao && (
                                <Select
                                    name="tipoDivisaoAnditamento"
                                    label="Divisão de adiantamento por viajante"
                                    placeholder="Selecione o tipo de pagamento"
                                    options={[
                                        {
                                            value: '1',
                                            label: 'Adiantamento parcial'
                                        },
                                        {
                                            value: '2',
                                            label: 'Adiantamento personalizado'
                                        }
                                    ]}
                                    defaultOption={tipoDivisao}
                                    onChange={e => {
                                        e?.value && setTipoDivisao(e.value)
                                    }}
                                    isDisabled={!requerAdiantamento}
                                ></Select>
                            )}
                        </div>
                        <div className="col-12">
                            <Input
                                style={{ height: '7rem' }}
                                name="Descricao"
                                label="Motivo"
                                type="textarea"
                                value={descAdiantamentoPrincipal}
                                disabled={!requerAdiantamento}
                                onChange={e =>
                                    setDescAdiantamentoPrincipal(e.target.value)
                                }
                                required={true}
                            ></Input>
                        </div>
                    </div>

                    {viajantes ? (
                        <div className="row" style={{ marginTop: '3rem' }}>
                            <div className="col-12">
                                {showAlert.status && (
                                    <Alert
                                        icon="exclamation-circle"
                                        message={showAlert.message}
                                        status={showAlert.status}
                                        type={showAlert.type as any}
                                    ></Alert>
                                )}

                                <DisableForMobile>
                                    {adiantamento && apresentarTabela()}
                                </DisableForMobile>
                                <IsMobile>{getTabelaMobile()}</IsMobile>
                            </div>
                        </div>
                    ) : (
                        <div style={{ marginTop: '2rem' }}>
                            <Empty text="Nenhum viajante selecionado" />
                        </div>
                    )}

                    <Space height="3rem"></Space>
                    <Center>
                        <DisableForMobile>
                            <Button
                                disabled={!requerAdiantamento}
                                colorType="quaternary"
                                icon={
                                    solicitacao?.Viajantes != null
                                        ? 'save'
                                        : 'plus'
                                }
                                type="submit"
                            >
                                {solicitacao?.Viajantes != null
                                    ? 'Salvar'
                                    : 'Incluir'}
                            </Button>
                        </DisableForMobile>
                        <IsMobile>
                            <Button
                                disabled={!requerAdiantamento}
                                colorType="btnMobile"
                                icon={
                                    solicitacao?.Viajantes != null
                                        ? 'save'
                                        : 'plus'
                                }
                                type="submit"
                            >
                                {solicitacao?.Viajantes != null
                                    ? 'Salvar'
                                    : 'Incluir'}
                            </Button>
                        </IsMobile>
                    </Center>
                </Form>
            </>
        )
    }

    return (
        <div>
            <BlankBoard>
                <Header
                    title={
                        solicitacao?.Viajantes != null
                            ? 'Alteração de Adiantamento'
                            : 'Criação de Adiantamento'
                    }
                    actionArrowClick={() =>
                        history.push(`/solicitacoes/editar/${Id}`)
                    }
                ></Header>
                <Space height="1rem"></Space>
                <Subtitle
                    style={{
                        textAlign: 'center',
                        margin: '1rem 0'
                    }}
                >
                    Dados do Adiantamento
                </Subtitle>
                {showAdiantamentos ? (
                    formEditar()
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Consulte as politicas de viagens"
                    ></Alert>
                )}
            </BlankBoard>
        </div>
    )
}

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
`
const ViajantePrincipalDiv = styled.button`
    background-color: #5787d0;
    padding: 0.8vh;
    color: white;
    font-size: 8pt;
    border-radius: 0.7vh;
    border: none;
    width: 15vh;
`
const Text = styled.p``

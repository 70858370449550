import styled from 'styled-components'

export const InputStyle = styled.div``
export const DisableForMobile = styled.span`
    @media (max-width: 768px) {
        display: none;
    }
`
export const IsMobile = styled.span`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`

import React, { useRef, useEffect, useState } from 'react'
import { BlankBoard } from '../../../core/components/BlankBoard/index'
import { Header } from '../../../core/components/Header/Header'
import { useReactToPrint } from 'react-to-print'
import { Center } from './../../../core/components/Center/index'
import { get } from '../../../services/http'
import { ENDPOINTS } from '../../../services/config-http'
import { useParams, useHistory } from 'react-router-dom'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Button } from '../../../core/components/Button'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { ImagePrint } from '../../../core/components/ImagePrint'
import styled from 'styled-components'
import GolImg from '../../../core/assets/Gol.jpg'
import AzulImg from '../../../core/assets/Azul.png'
import LatamImg from '../../../core/assets/Latam.png'
import { IntlRealFormat } from '../../../core/utils/IntlFormat'
// Funçao de impressão do layout
export const Impressao = () => {
    const history = useHistory()
    const printRef = useRef()
    const [impressao, setImpressao] = useState([])
    const { Id } = useParams()
    const [flagShowButtons, setflagShowButtons] = useState(true)

    // Escondendo os botoes de edição rapida da impressao
    const handlePrint = async () => {
        await setflagShowButtons(false)
        print()
    }

    // Imprimindo o layout com as informações
    const print = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: () => setflagShowButtons(true)
    })

    // Conectando o EndPoint ao Model
    useEffect(() => {
        get(UrlMountQuery({ IdSolicitacao: Id }, ENDPOINTS.impressao))
            .then(({ data: { Model } }) => {
                setImpressao(Model)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    function _ShowViajanteName(value) {
        const item = impressao.ViajanteImpressao?.filter(
            item => item?.IdUsuario === value
        )[0]
        return item?.Nome
    }

    function _ShowViajanteCode(value) {
        const item = impressao.ViajanteImpressao?.filter(
            item => item?.IdUsuario === value
        )[0]
        return item?.Codigo
    }

    // Funções para cada Grid separado com Loop
    function showViajantes() {
        let lastLine
        return impressao.ViajanteImpressao?.map((viajante, index) => {
            if (index < impressao.ViajanteImpressao.length) {
                console.log('index', index)
                console.log('leng', impressao.ViajanteImpressao.length)
                lastLine = (
                    <div className="col-12">
                        <LineSeparator style={separator}></LineSeparator>
                    </div>
                )
            }
            if (index === impressao.ViajanteImpressao.length - 1) {
                lastLine = ''
            }

            return (
                <div key={viajante.Codigo}>
                    <div style={{ paddingTop: '6px' }}>
                        <Center>
                            <div style={colsix2} className="col-9">
                                <Text>
                                    <Text style={strongT}>Nome</Text>
                                </Text>
                                <Text style={textInput}>
                                    {viajante.Codigo} - {viajante.Nome}
                                </Text>
                            </div>
                            <div style={colthree} className="col-3">
                                <Text>
                                    <Text style={strongT}>Telefone</Text>
                                </Text>
                                <Text style={textInput}>
                                    {viajante.Telefone}
                                </Text>
                            </div>
                        </Center>
                        <Center>
                            <div className="col-12">
                                <Text>
                                    <Text style={textInput2}>e-Mail</Text>
                                </Text>
                                <Text style={textInput}>
                                    {viajante.Email
                                        ? viajante.Email
                                        : 'emailteste@havan.com.br'}
                                </Text>
                            </div>
                        </Center>

                        {lastLine}
                    </div>
                </div>
            )
        })
    }

    function showXpto() {
        let lastLine
        return impressao.ViajanteImpressao?.map((viajante, index) => {
            if (index < impressao.ViajanteImpressao.length) {
                console.log('index', index)
                console.log('leng', impressao.ViajanteImpressao.length)
                lastLine = (
                    <div className="col-12">
                        <LineSeparator style={separator}></LineSeparator>
                    </div>
                )
            }
            if (index === impressao.ViajanteImpressao.length - 1) {
                lastLine = ''
            }

            return (
                <div key={viajante.Codigo}>
                    <div style={{ paddingTop: '6px' }}>
                        <Center>
                            <div style={colsix} className="col-4">
                                <Text>
                                    <Text style={strongT}>Origem</Text>
                                </Text>
                                <Text style={textInput}>
                                    JPA 03/10/2021 16:45
                                </Text>
                            </div>

                            <div style={colsix2} className="col-4">
                                <Text>
                                    <Text style={strongT}>Destino</Text>
                                </Text>
                                <Text style={textInput}>
                                    NVT 03/10/2021 17:45
                                </Text>
                            </div>

                            <div style={colsix2} className="col-4">
                                <Text>
                                    <Text style={strongT}>Duração</Text>
                                </Text>
                                <Text style={textInput}>4H</Text>
                            </div>
                        </Center>
                        <Center>
                            <div style={colsix} className="col-4">
                                <Text>
                                    <Text style={strongT}>Voo</Text>
                                </Text>
                                <Text style={textInput}>G3 1183</Text>
                            </div>
                            <div style={colsix2} className="col-4">
                                <Text>
                                    <Text style={strongT}>Localizador</Text>
                                </Text>
                                <Text style={textInput}>G3 1183</Text>
                            </div>
                            <div style={colsix2} className="col-4">
                                <Text>
                                    <Text style={strongT}>Operador por</Text>
                                </Text>
                                <Text style={textInput}>G3 1183</Text>
                            </div>
                        </Center>

                        {lastLine}
                    </div>
                </div>
            )
        })
    }

    function showRoteiro() {
        let lastLine
        return impressao.RoteiroImpressao?.map((roteiro, index) => {
            if (index < impressao.RoteiroImpressao.length) {
                lastLine = (
                    <div className="col-12">
                        <LineSeparator style={separator}></LineSeparator>
                    </div>
                )
            }
            if (index === impressao.RoteiroImpressao.length - 1) {
                lastLine = ''
            }

            const strInicio = roteiro.DataInicio
            let dataFormatadaInicio = strInicio.substring(10, 0)
            dataFormatadaInicio = dataFormatadaInicio
                .split('-')
                .reverse()
                .join('/')

            const strTermino = roteiro.DataTermino
            let dataFormatadaTermino = strTermino.substring(10, 0)
            dataFormatadaTermino = dataFormatadaTermino
                .split('-')
                .reverse()
                .join('/')

            return (
                <div key={roteiro.Objetivo}>
                    <div style={{ paddingTop: '9px' }}>
                        <Center>
                            <div style={coltwelve} className="col-12">
                                <div>
                                    <Text style={strongT}>Objetivo</Text>
                                </div>
                                <Text style={textInput}>
                                    {roteiro.Objetivo}
                                </Text>
                            </div>
                        </Center>
                        <div className="col-12">
                            <LineSeparator style={separator}></LineSeparator>
                        </div>
                        <Center>
                            <div style={colsix2} className="col-6">
                                <div>
                                    <Text style={strongT}>Destino</Text>
                                </div>
                                <Text style={textInput}>{roteiro.Destino}</Text>
                            </div>
                            <div style={colthree} className="col-3">
                                <div>
                                    <Text style={strongT}>Início</Text>
                                </div>
                                <Text style={textInput}>
                                    {dataFormatadaInicio}
                                </Text>
                            </div>
                            <div style={colthree2} className="col-3">
                                <div>
                                    <Text style={strongT}>Término</Text>
                                </div>
                                <Text style={textInput}>
                                    {dataFormatadaTermino}
                                </Text>
                            </div>
                        </Center>
                        {lastLine}
                    </div>
                </div>
            )
        })
    }

    function showRateio() {
        let lastLine
        return impressao.RateioImpressao?.map((rateio, index) => {
            if (index < impressao.RateioImpressao.length) {
                lastLine = (
                    <div className="col-12">
                        <LineSeparator style={separator}></LineSeparator>
                    </div>
                )
            }
            if (index === impressao.RateioImpressao.length - 1) {
                lastLine = ''
            }

            return (
                <div key={rateio.CodigoFilial}>
                    <div style={{ paddingTop: '9px' }}>
                        <Center>
                            <div style={colfour3} className="col-4">
                                <div>
                                    <Text style={strongT}>Filial</Text>
                                </div>
                                <Text style={textInput}>
                                    {rateio.CodigoFilial} - {rateio.NomeFilial}
                                </Text>
                            </div>
                            <div style={colsix} className="col-6">
                                <div>
                                    <Text style={strongT}>Centro de custo</Text>
                                </div>
                                <Text style={textInput}>
                                    {rateio.CodigoConta} -{' '}
                                    {rateio.DescricaoConta}
                                </Text>
                            </div>
                            <div style={colthree2} className="col-2">
                                <div>
                                    <Text style={strongT}>Percentual</Text>
                                </div>
                                <Text style={textInput}>
                                    {rateio.Percentual.toFixed(2)} %
                                </Text>
                            </div>
                        </Center>
                        {lastLine}
                    </div>
                </div>
            )
        })
    }

    function showAdiantamento() {
        let lastLine
        const descricao = (
            <div>
                <Center>
                    <div style={coltwelve} className="col-12">
                        <div>
                            <Text style={strongT}>Descrição</Text>
                        </div>
                        <Text style={textInput}>
                            {impressao.AdiantamentoImpressao?.length > 0
                                ? impressao.AdiantamentoImpressao[0]?.Descricao
                                : ''}
                        </Text>
                    </div>
                </Center>
                <LineSeparator style={separator}></LineSeparator>
            </div>
        )
        return impressao.AdiantamentoImpressao?.map((adiantamento, index) => {
            if (index < impressao.AdiantamentoImpressao.length) {
                lastLine = (
                    <div className="col-12">
                        <LineSeparator style={separator}></LineSeparator>
                    </div>
                )
            }
            if (index === impressao.AdiantamentoImpressao.length - 1) {
                lastLine = ''
            }

            return (
                <div key={adiantamento.Valor}>
                    <div style={{ paddingTop: '9px' }}>
                        {index === 0 ? descricao : ''}
                        <Center>
                            <div style={coleight} className="col-10">
                                <div>
                                    <Text style={strongT}>Viajante</Text>
                                </div>
                                <Text style={textInput}>
                                    {_ShowViajanteCode(adiantamento.IdUsuario)}{' '}
                                    -{' '}
                                    {_ShowViajanteName(adiantamento.IdUsuario)}
                                </Text>
                            </div>
                            <div style={coltwo2} className="col-2">
                                <div>
                                    <Text style={strongT}>Valor</Text>
                                </div>
                                <Text style={textInput}>
                                    {IntlRealFormat(
                                        adiantamento.Valor.toFixed(2)
                                    )}
                                </Text>
                            </div>
                        </Center>
                        {lastLine}
                    </div>
                </div>
            )
        })
    }

    const showAereox = () => {
        let lastLine
        return impressao.AereoImpressao?.map((aereo, index) => {
            if (index < impressao.AereoImpressao.length) {
                lastLine = (
                    <div className="col-12">
                        <LineSeparator style={separator}></LineSeparator>
                    </div>
                )
            }
            if (index === impressao.AereoImpressao.length - 1) {
                lastLine = ''
            }

            return (
                <div key={index}>
                    <div style={{ paddingTop: '9px', marginBottom: '20px' }}>
                        <Center>
                            <div style={coleight} className="col-4">
                                <div>
                                    <Text style={strongT}>
                                        Aeroporto de Origem
                                    </Text>
                                </div>
                                <Text style={textInput}>
                                    {`${aereo.AeroportoOrigem} - ${new Date(
                                        aereo.DataIdaSaida
                                    ).toLocaleDateString('pt-BR', {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}`}
                                </Text>
                                {aereo.IdaVolta ? (
                                    <Text style={textInput}>
                                        {`${
                                            aereo.AeroportoDestino
                                        } - ${new Date(
                                            aereo.DataVoltaSaida
                                        ).toLocaleDateString('pt-BR', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}`}
                                    </Text>
                                ) : null}
                            </div>
                            <div style={coltwo2} className="col-4">
                                <div>
                                    <Text style={strongT}>
                                        Aeroporto de Destino
                                    </Text>
                                </div>
                                <Text style={textInput}>
                                    {`${aereo.AeroportoDestino} - ${new Date(
                                        aereo.DataIdaChegada
                                    ).toLocaleDateString('pt-BR', {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}`}
                                </Text>
                                {aereo.IdaVolta ? (
                                    <Text style={textInput}>
                                        {`${aereo.AeroportoOrigem} - ${new Date(
                                            aereo.DataVoltaChegada
                                        ).toLocaleDateString('pt-BR', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}`}
                                    </Text>
                                ) : null}
                            </div>
                            <div style={coltwo2} className="col-2">
                                <div>
                                    <Text style={strongT}>Valor</Text>
                                </div>
                                <Text style={textInput}>{`R$ ${parseFloat(
                                    aereo.Valor
                                ).toFixed(2)}`}</Text>
                            </div>
                            <div style={coltwo2} className="col-2">
                                <div>
                                    <Text style={strongT}>Localizador</Text>
                                </div>
                                <Text style={textInput}>
                                    {aereo.Localizador}
                                </Text>
                            </div>
                        </Center>
                        {lastLine}
                    </div>
                </div>
            )
        })
    }

    const calculaTempo = (param1, param2) => {
        const data1 = new Date(param1).getTime()
        const data2 = new Date(param2).getTime()
        const novaData = data1 / data2
        return parseInt(novaData / 6600000, 10)
    }

    function showAereo() {
        let lastLine
        return impressao.AereoImpressao?.map((aereo, index) => {
            if (index < impressao.AereoImpressao.length) {
                lastLine = (
                    <div className="col-12">
                        <LineSeparator style={separator}></LineSeparator>
                    </div>
                )
            }
            if (index === impressao.AereoImpressao.length - 1) {
                lastLine = ''
            }

            return (
                <div key={index}>
                    <div style={{ paddingTop: '6px' }}>
                        <Center>
                            <div style={colsix} className="col-4">
                                <div>
                                    <Text style={strongT}>Origem</Text>
                                </div>
                                <Text style={textInput}>
                                    {`${aereo.AeroportoOrigem} - ${new Date(
                                        aereo.DataIdaSaida
                                    ).toLocaleDateString('pt-BR', {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}`}
                                </Text>
                                {aereo.IdaVolta ? (
                                    <Text style={textInput}>
                                        {`${
                                            aereo.AeroportoDestino
                                        } - ${new Date(
                                            aereo.DataVoltaSaida
                                        ).toLocaleDateString('pt-BR', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}`}
                                    </Text>
                                ) : null}
                            </div>

                            <div style={colsix} className="col-4">
                                <Text>
                                    <Text style={strongT}>Destino</Text>
                                </Text>
                                <Text style={textInput}>
                                    {`${aereo.AeroportoDestino} - ${new Date(
                                        aereo.DataIdaChegada
                                    ).toLocaleDateString('pt-BR', {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}`}
                                </Text>
                                {aereo.IdaVolta ? (
                                    <Text style={textInput}>
                                        {`${aereo.AeroportoOrigem} - ${new Date(
                                            aereo.DataVoltaChegada
                                        ).toLocaleDateString('pt-BR', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}`}
                                    </Text>
                                ) : null}
                            </div>

                            <div style={colsix} className="col-4">
                                <Text>
                                    <Text style={strongT}>Duração</Text>
                                </Text>
                                <Text style={textInput}>
                                    {`${calculaTempo(
                                        aereo.DataIdaSaida,
                                        aereo.DataIdaSaida
                                    )} H`}
                                </Text>
                                <Text style={textInput}>
                                    {`${calculaTempo(
                                        aereo.DataVoltaSaida,
                                        aereo.DataVoltaChegada
                                    )} H`}
                                </Text>
                            </div>
                        </Center>
                        <div
                            style={{
                                width: '0 auto',
                                height: '30px'
                            }}
                            className="col-12"
                        ></div>
                        <Center>
                            <div style={colsix} className="col-4">
                                <Text>
                                    <Text style={strongT}>Voo</Text>
                                </Text>
                                <Text style={textInput}>G3 1183</Text>
                            </div>
                            <div style={colsix} className="col-4">
                                <Text>
                                    <Text style={strongT}>Localizador</Text>
                                </Text>
                                <Text style={textInput}>
                                    {aereo.Localizador}
                                </Text>
                            </div>
                            <div style={colsix} className="col-4">
                                <Text>
                                    <Text style={strongT}>Operador por</Text>
                                </Text>
                                <Text style={textInput}>
                                    {renderLogoCia(aereo.Companhia)}
                                </Text>
                            </div>
                        </Center>
                        <div
                            style={{
                                width: '0 auto',
                                height: '30px'
                            }}
                            className="col-12"
                        ></div>
                        {lastLine}
                    </div>
                </div>
            )
        })
    }

    const renderLogoCia = cia => {
        switch (cia) {
            case 'G3':
                return (
                    <ContainerLogo>
                        <img src={GolImg} alt="Gol"></img>
                    </ContainerLogo>
                )
            case 'AD':
                return (
                    <ContainerLogo>
                        <img src={AzulImg} alt="Azul"></img>
                    </ContainerLogo>
                )
            case 'JJ':
                return (
                    <ContainerLogo>
                        <img src={LatamImg} alt="Latam"></img>
                    </ContainerLogo>
                )

            default:
                break
        }
    }

    const FormatData = data => {
        const strData = data
        let dataFormatada = strData.substring(10, 0)
        dataFormatada = dataFormatada.split('-').reverse().join('/')
        return dataFormatada
    }

    function showDespesas() {
        return impressao.DespesasImpressao?.map(despesa => {
            return (
                <div key={despesa.Justificativa}>
                    <div style={{ paddingTop: '15px' }}>
                        <div style={containerNFStyle}>
                            <Center>
                                <div style={coltwelve2} className="col-12">
                                    <div>
                                        <Text style={strongT}>Nome</Text>
                                    </div>
                                    <Text style={textInput}>
                                        {despesa.Codigo} - {despesa.Nome}
                                    </Text>
                                </div>
                            </Center>
                            {despesa.Despesas.length ? (
                                <>
                                    {despesa.Despesas.map(ItemDespesa => {
                                        return (
                                            <div
                                                key={
                                                    despesa.IdUsuario -
                                                    ItemDespesa.Justificativa
                                                }
                                            >
                                                <div
                                                    style={{
                                                        paddingTop: '15px'
                                                    }}
                                                >
                                                    <div
                                                        style={containerNFStyle}
                                                    >
                                                        <Center>
                                                            <div
                                                                style={colsix3}
                                                                className="col-6"
                                                            >
                                                                <div>
                                                                    <Text
                                                                        style={
                                                                            strongT
                                                                        }
                                                                    >
                                                                        Tipo da
                                                                        Despesa
                                                                    </Text>
                                                                </div>
                                                                <Text
                                                                    style={
                                                                        textInput
                                                                    }
                                                                >
                                                                    {
                                                                        ItemDespesa.Tipo
                                                                    }
                                                                </Text>
                                                            </div>
                                                            <div
                                                                style={
                                                                    colfourNF
                                                                }
                                                                className="col-4"
                                                            >
                                                                <div>
                                                                    <Text
                                                                        style={
                                                                            strongT
                                                                        }
                                                                    >
                                                                        Data
                                                                    </Text>
                                                                </div>
                                                                <Text
                                                                    style={
                                                                        textInput
                                                                    }
                                                                >
                                                                    {FormatData(
                                                                        ItemDespesa.Data
                                                                    )}
                                                                </Text>
                                                            </div>
                                                            <div
                                                                style={
                                                                    colthree2
                                                                }
                                                                className="col-2"
                                                            >
                                                                <div>
                                                                    <Text
                                                                        style={
                                                                            strongT
                                                                        }
                                                                    >
                                                                        Valor
                                                                    </Text>
                                                                </div>
                                                                <Text
                                                                    style={
                                                                        textInput
                                                                    }
                                                                >
                                                                    {' '}
                                                                    R${' '}
                                                                    {ItemDespesa.Valor.toFixed(
                                                                        2
                                                                    )}
                                                                </Text>
                                                            </div>
                                                        </Center>
                                                        <div
                                                            style={coltwelve2}
                                                            className="col-12"
                                                        >
                                                            <div>
                                                                <Text
                                                                    style={
                                                                        strongT
                                                                    }
                                                                >
                                                                    Justificativa
                                                                </Text>
                                                            </div>
                                                            <Text
                                                                style={
                                                                    textInput
                                                                }
                                                            >
                                                                {
                                                                    ItemDespesa.Justificativa
                                                                }
                                                            </Text>
                                                        </div>
                                                        <Center>
                                                            <ImagePrint
                                                                linkImg={
                                                                    ItemDespesa.Comprovante
                                                                }
                                                                flagShowButtons={
                                                                    flagShowButtons
                                                                }
                                                            ></ImagePrint>
                                                        </Center>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            ) : (
                                <Center>
                                    <Text>**** Nada Declarado ****</Text>
                                </Center>
                            )}
                        </div>
                    </div>
                </div>
            )
        })
    }

    // Documento principal que referencia as funções, agrupando tudo na mesma <ref> para impressão
    return (
        <BlankBoard>
            <Header
                title="Impressão"
                actionArrowClick={() => history.goBack()}
            ></Header>
            <h2 style={h2Style}>Layout de Impressão </h2>
            <Button
                icon="print"
                colorType="tertiary"
                onlyIcon={true}
                style={BtnPrint}
                onClick={handlePrint}
            ></Button>
            <div ref={printRef} style={printPage}>
                <div style={containerHStyle}>
                    <div style={rowH} className="row">
                        <div className="col-3">
                            <img
                                alt="logo-havan"
                                style={formatLogo}
                                src={
                                    'https://cdn.havan.com.br/assets/images/logo-havan-b.svg'
                                }
                            />
                        </div>
                        <div className="col-7 ">
                            <Text style={c1}>Relatório de viagem</Text>
                            <Text style={c2}>
                                Havan Lojas De Departamentos Ltda
                            </Text>
                        </div>
                        <div className="col-3"></div>
                    </div>
                </div>
                <div style={{ paddingTop: '9px' }}>
                    <div style={containerStyle}>
                        <div style={row} className="row">
                            <div style={coleight} className="col-6">
                                <div>
                                    <Text style={strongT}>Descrição</Text>
                                </div>
                                <Text style={textInput}>
                                    {impressao.SolicitacaoViagem?.Descricao}
                                </Text>
                            </div>
                            <div style={colfour} className="col-6">
                                <div>
                                    <Text style={strongT}>Situação</Text>
                                </div>
                                <Text style={textInput}>
                                    {impressao.SolicitacaoViagem?.Situacao}
                                </Text>
                            </div>
                            <div className="col-12">
                                <LineSeparator
                                    style={separator}
                                ></LineSeparator>
                            </div>
                            <div className="col-12"></div>
                            <div style={coltwelve} className="col-12">
                                <div>
                                    <Text style={strongT}>Motivo</Text>
                                </div>
                                <Text style={textInput}>
                                    {impressao.SolicitacaoViagem?.Motivo}
                                </Text>
                            </div>
                            <div className="col-12">
                                <LineSeparator
                                    style={separator}
                                ></LineSeparator>
                            </div>
                            <div className="col-12"></div>
                            <div style={coleight} className="col-6">
                                <div>
                                    <Text style={strongT}>
                                        Viajante Principal
                                    </Text>
                                </div>
                                <Text style={textInput}>
                                    {impressao.SolicitacaoViagem?.Codigo} -
                                    {impressao.SolicitacaoViagem?.Viajante}
                                </Text>
                            </div>
                            <div style={colfour} className="col-5">
                                <div>
                                    <Text style={strongT}>
                                        Filial de origem
                                    </Text>
                                </div>
                                <Text style={textInput}>
                                    {impressao.SolicitacaoViagem?.Filial}
                                </Text>
                                <div style={colfour} className="col-5"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ paddingTop: '9px' }}>
                    <div style={containerStyle}>
                        <div style={row} className="row">
                            <div sytle={coltwelve} className="col-12">
                                <Text style={titleGrid}>Acompanhantes</Text>
                                {showViajantes()}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ paddingTop: '9px' }}>
                    <div style={containerStyle}>
                        <div style={row} className="row">
                            <div sytle={coltwelve} className="col-12">
                                <Text style={titleGrid}>Roteiro</Text>
                                {showRoteiro()}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ paddingTop: '9px' }}>
                    <div style={containerStyle}>
                        <div style={row} className="row">
                            <div sytle={coltwelve} className="col-12">
                                <Text style={titleGrid}>Rateio</Text>
                                {showRateio()}
                            </div>
                        </div>
                    </div>
                </div>
                {impressao?.AereoImpressao?.length ? (
                    <div style={{ paddingTop: '9px' }}>
                        <div style={containerStyle}>
                            <div style={row} className="row">
                                <div sytle={coltwelve} className="col-12">
                                    <Text style={titleGrid}>Aéreo</Text>
                                    {showAereo()}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                {impressao?.AdiantamentoImpressao?.length ||
                impressao?.DespesasImpressao?.length ? (
                    <div style={{ paddingTop: '9px' }}>
                        <div style={containerStyle}>
                            <div style={row} className="row">
                                {impressao?.AdiantamentoImpressao?.length ? (
                                    <div sytle={coltwelve} className="col-12">
                                        <Text style={titleGrid}>
                                            Adiantamento
                                        </Text>
                                        {showAdiantamento()}
                                    </div>
                                ) : (
                                    ''
                                )}

                                <div>
                                    <Text style={subtitleGrid}>
                                        {impressao?.DespesasImpressao?.length
                                            ? 'Declaração de despesas'
                                            : 'Nenhuma despesa foi declara para esta solicitação'}
                                    </Text>
                                </div>
                            </div>
                            {showDespesas()}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </BlankBoard>
    )
}
// Estilos dos componentes do layout de impressão
const containerStyle = {
    borderRadius: '9px',
    border: '1px solid black',
    paddingRight: '10px',
    marginLeft: '0.2rem',
    marginRight: '0.2rem',
    paddingLeft: '10px',
    pageBreakAfter: 'auto',
    pageBreakInside: 'avoid'
}

const containerHStyle = {
    borderRadius: '9px',
    border: '1px solid black',
    marginLeft: '0.2rem',
    marginRight: '0.2rem',
    height: '101px'
}

const containerNFStyle = {
    borderRadius: '9px',
    border: '1px solid #717171',
    marginLeft: '0.2rem',
    marginRight: '0.2rem',
    paddingTop: '20px',
    paddingLeft: '0px',
    marginBottom: '20px',
    pageBreakInside: 'avoid',
    pageBreakAfter: 'auto'
}

const h2Style = {
    marginLeft: '2rem',
    marginTop: '7rem'
}

const c1 = {
    paddingTop: '1rem',
    color: 'black',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1.2rem',
    lineHeight: '0'
}

const c2 = {
    color: 'black',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1.2rem'
}

const coltwo2 = {
    paddingLeft: '25px',
    lineHeight: '8px',
    height: '45px',
    marginBottom: '10px'
}

const colthree = {
    borderRight: '1px solid #7171714c',
    paddingLeft: '25px',
    lineHeight: '8px',
    height: '45px',
    marginBottom: '10px'
}

const colthree2 = {
    paddingLeft: '25px',
    lineHeight: '8px',
    height: '45px',
    marginBottom: '10px'
}

const colfour = {
    paddingLeft: '25px',
    lineHeight: '8px',
    height: '45px',
    marginBottom: '10px'
}

const colfour3 = {
    borderRight: '1px solid #7171714c',
    lineHeight: '8px',
    height: '45px',
    marginBottom: '10px'
}

const colfourNF = {
    borderRight: '1px solid #7171714c',
    lineHeight: '8px',
    height: '45px',
    marginBottom: '10px',
    marginLeft: '15px',
    paddingLeft: '15px'
}

const colsix = {
    borderRight: '1px solid #7171714c',
    lineHeight: '8px',
    paddingLeft: '25px',
    height: '45px',
    marginBottom: '10px'
}

const colsix2 = {
    borderRight: '1px solid #7171714c',
    lineHeight: '8px',
    height: '45px',
    marginBottom: '10px'
}

const colsix3 = {
    borderRight: '1px solid #7171714c',
    lineHeight: '8px',
    height: '45px',
    marginBottom: '10px',
    paddingLeft: '25px'
}

const coleight = {
    borderRight: '1px solid #7171714c',
    lineHeight: '8px',
    height: '45px',
    marginBottom: '10px'
}

const coltwelve = {
    lineHeight: '8px',
    height: '45px',
    marginBottom: '10px'
}

const coltwelve2 = {
    lineHeight: '8px',
    height: '45px',
    width: '100%',
    marginBottom: '10px',
    marginTop: '15px',
    marginLeft: '15px',
    paddingLeft: '-5px'
}

const strongT = {
    marginTop: '6px',
    color: 'black',
    fontWeight: '600',
    fontSize: '16px'
}

const textInput = {
    color: 'black',
    fontWeight: '400',
    fontSize: '16px'
}

const textInput2 = {
    marginBottom: '-8px',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '15px',
    borderRight: '1px solid #7171714c',
    lineHeight: '8px',
    marginTop: '4px'
}

const separator = {
    marginTop: '-5px',
    marginBottom: '8px',
    paddingTop: '0'
}

const rowH = {
    width: '100%',
    marginLeft: '0px',
    marginTop: '15px',
    marginBottom: '2px',
    paddingLeft: '12px'
}

const row = {
    width: '100%',
    marginLeft: '2px',
    marginTop: '10px',
    marginBottom: '2px'
}

const titleGrid = {
    textAlign: 'center',
    marginBottom: '20px',
    color: 'black',
    fontWeight: '600',
    marginTop: '15px',
    fontSize: '16px',
    pageBreakInside: 'avoid',
    pageBreakAfter: 'auto'
}

const subtitleGrid = {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#717171',
    fontWeight: '600',
    marginTop: '13px',
    fontSize: '16px',
    pageBreakInside: 'avoid',
    pageBreakAfter: 'auto'
}

const BtnPrint = {
    backgroundColor: 'white',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    marginLeft: '93%',
    marginTop: '-4%',
    color: '#24538B',
    minWidth: 'auto',
    fontSize: '1.5rem',
    width: '3rem',
    height: '3rem'
}

const printPage = {
    paddingLeft: '0px',
    marginLeft: '0.5rem',
    paddingBottom: '0.5rem',
    paddingTop: '0.5rem',
    marginRight: '0.5rem',
    marginTop: '70px',
    transform: 'scale(0.995)'
}

const formatLogo = {
    color: 'black',
    width: '7rem',
    height: '7rem',
    marginTop: '-1.5rem'
}

const ContainerLogo = styled.div`
    width: 100px;
    height: 50px;
    margin
    overflow: hidden;
    align-items: start;

    img {
        width: 100%;
    }
`
const Text = styled.p`
    color: #6c7480;
    font-size: 0.9375rem;
    font: 'Montserrat, sas-serif';
    margin-bottom: 20px;
`

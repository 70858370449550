import styled from 'styled-components'
import React from 'react'

type SpinnerProps = {
    show?: boolean
}

export const Spinner: React.FC<SpinnerProps> = ({ show = false }) => {
    if (!show) return null
    return (
        <Container>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </Container>
    )
}

export const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`

import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'

import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Header } from '../../../../core/components/Header/Header'
import { Form } from '@unform/web'
import { Button } from '../../../../core/components/Button'
import { Space } from '../../../../core/components/Space'
import { Input } from '../../../../core/components/Form/Input'
import { Select } from '../../../../core/components/Form/Select'
import { useHistory } from 'react-router'
import { StepByStep } from '../../../../core/components/StepByStep'
import { Center } from '../../../../core/components/Center'
import * as Yup from 'yup'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { Subtitle } from '../../../../core/components/Subtitle'
import { StepByStepItem } from '../../../../contexts/interfaces/_stepByStep'
import { useGlobalStates } from '../../../../state/InitialState'
import { SOLICITACAO_VIAGEM } from '../../../../state/SolicitacaoViagem/Actions'
import { Solicitacao } from '../../../../contexts/interfaces/solicitacao'
import { get } from '../../../../services/http'
import { useAuth } from '../../../../contexts/Auth'
import { ENDPOINTS } from '../../../../services/config-http'
import styled from 'styled-components'
import { Empty } from '../../../../core/components/Empty'
import { Alert } from '../../../../core/components/Alert'
import moment from 'moment'
import { ContainerPadding } from '../../../../core/components/ContainerPadding'
import { Viajante } from '../../../../contexts/interfaces/viajante'
import { LineSeparator } from './../../../../core/components/LineSeparator/index'
import Switch from 'react-switch'
import useWindowDimensions from '../../../../core/components/GetDimensions'
import { formataValor } from '../../../../util/legenda/util'
import { InputCurrency } from '../../../../core/components/Form/InputCurrency'
import { IntlRealFormat } from '../../../../core/utils/IntlFormat'
import { MoneyFormat } from '../../../../core/utils/MoneyFormat'
import { DisableForMobile, IsMobile } from '../stepMobile'

export const AdiantamentoForm = () => {
    const { user } = useAuth()
    const {
        setGlobalState,
        state: { solicitacao }
    } = useGlobalStates()
    const [tipoDivisao, setTipoDivisao] = useState('1')
    const [userInfo, setUserInfo] = useState<any>()
    const [valorTotal, setValorTotal] = useState(0)
    const [valorMaximo, setValorMaximo] = useState(0)
    const [valorPersonalizado, setValorPersonalizado] = useState([] as any)
    const FormRef = useRef<FormHandles>(null)
    const history = useHistory()
    const [dadosUsuario, setDadosUsuario] = useState<any>([])
    const [stepByStep, setStepByStep] = useState<StepByStepItem[]>([])
    const indexActiveStep = useRef<number>(0)
    const [habilitarBtn, setHabilitarBtn] = useState<boolean>(true)
    const [requerAdiantamento, setRequerAdiantamento] = useState<boolean>(false)
    const [regrasPoliticaViagem, setRegrasPoliticaViagem] = useState<any>(false)
    const { height, width } = useWindowDimensions()
    const [showAlert, setShowAlert] = useState({
        status: false,
        message: 'teste',
        type: 'primary'
    })

    useEffect(() => {
        const valores = [] as any
        solicitacao?.viajantes?.forEach(e => {
            valores.push({
                idUsuario: e.IdUsuario,
                valor: 0
            })
        })
        setValorPersonalizado(valores)
    }, [solicitacao])

    // useEffect(() => {
    //     let valorPersonalizadoTotal = 0
    //     if (valorPersonalizado.length !== 0 && tipoDivisao === '2') {
    //         valorPersonalizado.forEach((value: any) => {
    //             valorPersonalizadoTotal += value.valor as number
    //         })
    //         if (valorPersonalizadoTotal > valorTotal) {
    //             setHabilitarBtn(false)
    //             setShowAlert({
    //                 status: true,
    //                 message: `A soma do adiantamento de cada viajante deve resultar no valor total do adiantamento solicitado para a viagem.`,
    //                 type: 'primary'
    //             })
    //         } else {
    //             setHabilitarBtn(true)
    //             setShowAlert({
    //                 status: false,
    //                 message: '',
    //                 type: 'primary'
    //             })
    //         }
    //     }
    // }, [valorPersonalizado, valorTotal])

    useEffect(() => {
        const stepItem = localStorage.getItem('stepByStep')
        if (stepItem !== null) {
            const stepItemObj = JSON.parse(stepItem) as StepByStepItem[]
            indexActiveStep.current = stepItemObj.findIndex(
                s => s.name === 'Adiantamento'
            )
            if (indexActiveStep.current != -1) {
                stepItemObj[indexActiveStep.current].active = true
                for (let index = 0; index < indexActiveStep.current; index++) {
                    stepItemObj[index].completed = true
                }
                setStepByStep(stepItemObj)
            }
        }

        if (solicitacao === undefined) {
            const sol = localStorage.getItem('solicitacao')
            if (sol !== null) {
                const solicitacaoStorage = JSON.parse(sol) as Solicitacao
                setGlobalState(
                    SOLICITACAO_VIAGEM.REDUCER_NAME,
                    SOLICITACAO_VIAGEM.ACTION_ADD_SOLICITACAO,
                    solicitacaoStorage
                )
                configuraAdiantamento(solicitacaoStorage)
            }
        } else {
            configuraAdiantamento(solicitacao)
        }
    }, [])

    const configuraAdiantamento = (solicitacao: any) => {
        get(
            `${ENDPOINTS.usuarioByCodigo.replace(
                ':Codigo',
                solicitacao.viajantes?.filter(
                    (s: Viajante) => s.Convidado === false
                )[0]?.Codigo
            )}/`
        ).then(({ data: { Model } }) => {
            setUserInfo(Model)

            let dataInicio
            let dataTermino
            let days = 0

            solicitacao?.destinos?.forEach((item: any) => {
                const data1Split = item.dataInicio.split('/')
                const data2Split = item.dataTermino.split('/')
                dataInicio = new Date(
                    `${data1Split[1]}-${data1Split[0]}-${data1Split[2]}`
                )
                dataTermino = new Date(
                    `${data2Split[1]}-${data2Split[0]}-${data2Split[2]}`
                )

                const dataInicioDestino = moment(dataInicio)
                const dataTerminoDestino = moment(dataTermino)
                const duration = moment.duration(
                    dataInicioDestino.diff(dataTerminoDestino)
                )

                days = parseInt(duration.asDays().toString())
            })

            const numeroDias = Math.abs(days) + 1
            politicaPermissaoPorIdPolitica(
                numeroDias,
                Model.IdPoliticaViagem,
                solicitacao?.viajantes?.length
            )
        })
        if (solicitacao.adiantamentos?.length > 0) {
            setTipoDivisao(
                solicitacao.adiantamentos[0]?.tipoDivisaoAdiantamento
            )
            setRequerAdiantamento(true)

            if (solicitacao.adiantamentos[0]?.tipoDivisaoAdiantamento === '2') {
                const newValorPersonalizado: Array<any> = []
                solicitacao.adiantamentos?.forEach((adiantamento: any) => {
                    newValorPersonalizado.push({
                        idUsuario: adiantamento.idUsuario,
                        valor: adiantamento.valor
                    })
                })
                setValorPersonalizado(newValorPersonalizado)
            } else {
                const newValorPersonalizado: Array<any> = []
                solicitacao?.viajantes?.forEach((viajante: any) => {
                    newValorPersonalizado.push({
                        idUsuario: viajante.IdUsuario,
                        valor: 0
                    })
                })
                setValorPersonalizado(newValorPersonalizado)
            }
        }
    }

    const politicaPermissaoPorIdPolitica = async (
        numeroDias: number,
        idPoliticaViagem: number,
        numeroViajantes: number
    ) => {
        await get(
            `${ENDPOINTS.politicaPermissaoPorIdPolitica}/${idPoliticaViagem}`
        )
            .then(({ data: { Model } }) => {
                let valorCalculo = 0
                Model.forEach((e: any) => {
                    if (e.ParticipaCalculo === true) {
                        valorCalculo += e.ValorMaximo
                    }
                })
                const valor = valorCalculo * numeroDias * numeroViajantes
                setValorTotal(valor)
                setValorMaximo(valor)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const divisoes = [
        {
            value: '1',
            label: 'Adiantamento parcial'
        },
        {
            value: '2',
            label: 'Adiantamento personalizado'
        }
    ]

    useEffect(() => {
        const dados: Array<{ id: any; telefone: string }> = []
        if (solicitacao) {
            solicitacao?.viajantes?.map(item => {
                if (item?.Codigo) {
                    get(
                        `${ENDPOINTS.usuarioByCodigo.replace(
                            ':Codigo',
                            item?.Codigo || ''
                        )}/`
                    ).then(({ data: { Model } }) => {
                        dados.push({
                            id: Model.Codigo,
                            telefone: `(${Model.DDD}) ${Model.Telefone}`
                        })
                    })
                }
            })

            get(`${ENDPOINTS.solicitacaoViagemPorId}/${solicitacao?.id}`).then(
                ({ data: { Model } }) => {
                    const solicitacao = Model
                    get(
                        `${
                            ENDPOINTS.regrasPoliticaViagem
                        }/BuscarPorIdPolitica/${
                            solicitacao?.Viajantes?.filter(
                                (item: any) => item?.Convidado === false
                            )[0].IdPoliticaViagem
                        }`
                    )
                        .then(({ data: { Model } }) => {
                            const regras: Array<any> = Model
                            setRegrasPoliticaViagem(
                                regras.filter(
                                    item =>
                                        item?.IdTipoSolicitacao ===
                                        solicitacao?.IdTipoSolicitacao
                                )[0]
                            )
                        })
                        .catch(err => {
                            console.error(err)
                        })
                }
            )
        }
        setDadosUsuario(dados)
    }, [solicitacao])

    useEffect(() => {
        if (tipoDivisao === '1') {
            setHabilitarBtn(true)
            setShowAlert({
                status: false,
                message: '',
                type: 'primary'
            })
        }
    }, [tipoDivisao])

    const carregarDadosUsuario = (id: any) => {
        let dados: any
        dadosUsuario.filter(
            (item: { id: string | undefined; telefone: any }) => {
                if (item?.id === id) {
                    dados = item?.telefone
                }
                if (dados === '(null) ') {
                    dados = '-'
                }
            }
        )
        return dados || ''
    }

    const handleSubmit: SubmitHandler = async data => {
        try {
            const schema = Yup.object().shape({
                Valor: Yup.string().required('O Campo Valor é obrigatório'),
                Descricao: Yup.string()
                    .required('O Campo Descrição é obrigatório')
                    .max(200, 'O Campo pode ter no máximo 200 caractéres')
            })
            const adiantamentos: Array<any> = []
            let valorTotalFormatado = 0
            if (requerAdiantamento) {
                if (tipoDivisao === '2') {
                    valorPersonalizado.forEach((e: any) => {
                        valorTotalFormatado += formataValor(e.valor)
                    })
                    if (valorMaximo < valorTotalFormatado) {
                        setShowAlert({
                            status: true,
                            message: `O valor informado é maior que o máximo permitido ${IntlRealFormat(
                                valorMaximo
                            )}`,
                            type: 'exclamation'
                        })
                        return
                    }
                    await schema.validate(data, { abortEarly: false })
                    FormRef.current?.setErrors({})
                } else {
                    valorTotalFormatado = valorTotal
                    if (valorMaximo < valorTotalFormatado) {
                        setShowAlert({
                            status: true,
                            message: `O valor informado é maior que o máximo permitido ${IntlRealFormat(
                                valorMaximo
                            )}`,
                            type: 'exclamation'
                        })
                        return
                    }
                    await schema.validate(data, { abortEarly: false })
                    FormRef.current?.setErrors({})
                }

                solicitacao?.viajantes?.map(item => {
                    if (tipoDivisao === '1') {
                        const value = {
                            codigo: item.Codigo,
                            nome: item.Nome,
                            idSolicitacaoViagem: item.IdSolicitacao,
                            idUsuario: item.IdUsuario,
                            valor:
                                valorTotal /
                                (solicitacao?.viajantes?.length || 1),
                            descricao: data.Descricao,
                            status: item.Status,
                            adiantamentoRecebido: false,
                            tipoDivisaoAdiantamento: tipoDivisao
                        }
                        adiantamentos.push(value)
                    } else {
                        valorPersonalizado.filter((parametro: any) => {
                            if (parametro.idUsuario === item.IdUsuario) {
                                const value = {
                                    codigo: item.Codigo,
                                    nome: item.Nome,
                                    idSolicitacaoViagem: item.IdSolicitacao,
                                    idUsuario: item.IdUsuario,
                                    valor: formataValor(parametro.valor),
                                    descricao: data.Descricao,
                                    status: item.Status,
                                    adiantamentoRecebido: false,
                                    tipoDivisaoAdiantamento: tipoDivisao
                                }
                                adiantamentos.push(value)
                            }
                        })
                    }
                })
            }
            setGlobalState(
                SOLICITACAO_VIAGEM.REDUCER_NAME,
                SOLICITACAO_VIAGEM.ACTION_ADD_ADIANTAMENTO,
                adiantamentos
            )
            const sol = localStorage.getItem('solicitacao')
            if (sol !== null) {
                const solicitacaoObj = JSON.parse(sol) as Solicitacao
                solicitacaoObj.adiantamentos = adiantamentos
                localStorage.setItem(
                    'solicitacao',
                    JSON.stringify(solicitacaoObj)
                )
            } else {
                localStorage.setItem('solicitacao', JSON.stringify(solicitacao))
            }
            history.push(
                `/solicitar-viagem/${
                    stepByStep[indexActiveStep.current + 1].caminho
                }`
            )
        } catch (err) {
            console.log(err)
            if (err instanceof Yup.ValidationError) {
                const errors = MapFormErrors(err.inner)
                FormRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <div>
            <BlankBoard>
                <Header
                    title="Adiantamento da Viagem"
                    actionArrowClick={() => {
                        if (indexActiveStep.current === 0) {
                            history.push('/solicitar-viagem/info')
                        } else {
                            history.push(
                                `/solicitar-viagem/${
                                    stepByStep[indexActiveStep.current - 1]
                                        .caminho
                                }`
                            )
                        }
                    }}
                ></Header>
                <StepByStep items={stepByStep}></StepByStep>
                <Space height="1rem"></Space>
                <ContainerPadding>
                    {!requerAdiantamento ? (
                        <>
                            <Center>
                                <IsMobile>
                                    <Button
                                        colorType="btnMobile"
                                        icon="arrow-right"
                                        onClick={() => {
                                            history.push(
                                                `/solicitar-viagem/${
                                                    stepByStep[
                                                        indexActiveStep.current +
                                                            1
                                                    ].caminho
                                                }`
                                            )
                                        }}
                                    >
                                        Pular
                                    </Button>
                                </IsMobile>
                            </Center>
                        </>
                    ) : null}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="col-10">
                            <Subtitle
                                style={{
                                    margin: '1rem 0'
                                }}
                            >
                                <p>Adiantamento por viajante</p>
                            </Subtitle>
                            <span>
                                Clique em adicionar após o preenchimento dos
                                campos do formulário
                            </span>
                        </div>
                        <div
                            className="col-12 col-sm-2"
                            style={{ justifyContent: 'flex-end' }}
                        >
                            {!requerAdiantamento ? (
                                <DisableForMobile>
                                    <Button
                                        colorType="quintiary"
                                        icon="arrow-right"
                                        onClick={() => {
                                            history.push(
                                                `/solicitar-viagem/${
                                                    stepByStep[
                                                        indexActiveStep.current +
                                                            1
                                                    ].caminho
                                                }`
                                            )
                                        }}
                                    >
                                        Pular
                                    </Button>
                                </DisableForMobile>
                            ) : null}
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Switch
                            onColor="#24538B"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={requerAdiantamento}
                            onChange={() => {
                                setRequerAdiantamento(!requerAdiantamento)
                            }}
                        ></Switch>
                        <p className="label-switch">Solicitar adiantamento</p>
                    </div>
                    <LineSeparator style={{ margin: '2rem 0' }}></LineSeparator>

                    <Form ref={FormRef} onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <InputCurrency
                                    onChange={(e: any) => {
                                        const valorReplace = Number(
                                            e.currentTarget.value
                                                .replaceAll('.', '')
                                                .replaceAll(',', '.')
                                                .replaceAll('R$', '')
                                        )
                                        setValorTotal(valorReplace)
                                    }}
                                    value={IntlRealFormat(valorTotal || 0)}
                                    width="col-12"
                                    label="Valor"
                                    name="Valor"
                                    mask="999.999,99"
                                    required={true}
                                    defaultValue={MoneyFormat(0)}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <Select
                                    name="tipoDivisaoAdiantamento"
                                    label="Divisão de adiantamento por viajante"
                                    placeholder="Selecione o tipo de pagamento"
                                    options={divisoes}
                                    disabled={requerAdiantamento}
                                    value={divisoes.find(
                                        s => s.value === tipoDivisao
                                    )}
                                    onChange={e => {
                                        setTipoDivisao(e?.value)
                                    }}
                                ></Select>
                            </div>
                            <div className="col-12">
                                <Input
                                    style={{ height: '7rem' }}
                                    name="Descricao"
                                    label="Motivo"
                                    type="textarea"
                                    value={solicitacao?.descricao}
                                    required={true}
                                ></Input>
                            </div>
                        </div>

                        <LineSeparator
                            style={{ margin: '2rem 0' }}
                        ></LineSeparator>

                        {solicitacao?.viajantes ? (
                            <Center>
                                <Container>
                                    <DisableForMobile>
                                        {getTabela()}
                                    </DisableForMobile>
                                    <IsMobile>{getTabelaMobile()}</IsMobile>
                                </Container>
                            </Center>
                        ) : (
                            <div style={{ marginTop: '2rem' }}>
                                <Empty text="Nenhum viajante selecionado" />
                            </div>
                        )}

                        <Space height="3rem"></Space>
                        {/* <IsMobile>
                        <Center>
                            <Button
                                colorType="btnMobile"
                                icon="arrow-right"
                                onClick={() => {
                                    history.push(
                                        `/solicitar-viagem/${
                                            stepByStep[
                                                indexActiveStep.current + 1
                                            ].caminho
                                        }`
                                    )
                                }}
                            >
                                Pular
                            </Button>
                        </Center>
                    </IsMobile> */}
                        <Center>
                            {requerAdiantamento ? (
                                <>
                                    <DisableForMobile>
                                        <Button colorType="quaternary">
                                            Próximo
                                        </Button>
                                    </DisableForMobile>
                                    <IsMobile>
                                        <Button colorType="btnMobile">
                                            Próximo
                                        </Button>
                                    </IsMobile>
                                </>
                            ) : null}
                        </Center>
                    </Form>
                </ContainerPadding>
            </BlankBoard>
        </div>
    )

    function getCampo(index: number, title: string, valor: any) {
        return (
            <div
                style={{
                    flexDirection: 'column'
                }}
            >
                <ThMobile key={index}>{title}</ThMobile>
                <TdMobile
                    key={index}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {valor}
                </TdMobile>
            </div>
        )
    }

    function getTabela() {
        return (
            <div className="row" style={{ marginTop: '3rem' }}>
                <div className="col-12">
                    {showAlert.status && (
                        <Alert
                            icon="exclamation-circle"
                            message={showAlert.message}
                            status={showAlert.status}
                            type={showAlert.type as any}
                        ></Alert>
                    )}
                    <div
                        className="table-responsive"
                        style={{
                            border: 'solid 1px lightgray',
                            borderRadius: '15px'
                        }}
                    >
                        <table className="table">
                            <thead
                                style={{
                                    borderBottom: 'solid 1px lightgray'
                                }}
                            >
                                <tr
                                    style={{
                                        border: '0 !important'
                                    }}
                                    className="col-12"
                                >
                                    <th className="col-2">
                                        <p>Cod</p>
                                    </th>
                                    <th className="col-4">
                                        <p>Nome</p>
                                    </th>
                                    <th className="col-3">
                                        <p>Contato</p>
                                    </th>
                                    <th className="col-3"></th>
                                    <th className="col-2">
                                        <p>Adiantamento</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {solicitacao?.viajantes &&
                                    solicitacao?.viajantes.map(
                                        (object, key) => {
                                            return (
                                                <tr
                                                    key={key}
                                                    className="col-12"
                                                >
                                                    <td
                                                        className="col-2"
                                                        style={{
                                                            verticalAlign:
                                                                'left'
                                                        }}
                                                    >
                                                        <p>{object.Codigo}</p>
                                                    </td>
                                                    <td
                                                        className="col-4"
                                                        style={{
                                                            verticalAlign:
                                                                'left'
                                                        }}
                                                    >
                                                        <p>{object.Nome}</p>
                                                    </td>{' '}
                                                    <td
                                                        className="col-3"
                                                        style={{
                                                            verticalAlign:
                                                                'middle'
                                                        }}
                                                    >
                                                        <p>
                                                            {dadosUsuario.length &&
                                                                carregarDadosUsuario(
                                                                    object.Codigo
                                                                )}
                                                        </p>
                                                    </td>
                                                    <td
                                                        className="col-3"
                                                        style={{
                                                            verticalAlign:
                                                                'middle'
                                                        }}
                                                    >
                                                        <p>
                                                            {!object.Convidado && (
                                                                <ViajantePrincipalDiv
                                                                    disabled={
                                                                        true
                                                                    }
                                                                >
                                                                    Viajante
                                                                    Principal
                                                                </ViajantePrincipalDiv>
                                                            )}
                                                        </p>
                                                    </td>
                                                    <td
                                                        className="col-2"
                                                        style={{
                                                            verticalAlign:
                                                                'middle'
                                                        }}
                                                    >
                                                        {getAdiantamento(
                                                            solicitacao,
                                                            key
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    function getAdiantamento(solicitacao: any, key: number) {
        return (
            <div
                className="input-area-adiantamento"
                style={{
                    display: 'flex !important',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {tipoDivisao === '2' ? (
                    <>
                        <InputCurrency
                            onChange={(e: any) => {
                                const newValorPersonalizado = [
                                    ...valorPersonalizado
                                ]
                                const valorReplace = Number(
                                    e.currentTarget.value
                                        .replaceAll('.', '')
                                        .replaceAll(',', '.')
                                        .replaceAll('R$', '')
                                )

                                newValorPersonalizado[key].valor = valorReplace
                                setValorPersonalizado([
                                    ...newValorPersonalizado
                                ])
                                setValorPersonalizado([
                                    ...newValorPersonalizado
                                ])
                            }}
                            value={IntlRealFormat(
                                valorPersonalizado[key]?.valor || 0
                            )}
                            label=""
                            name={`input-adiantamento-${key}`}
                            mask="999.999,99"
                            required={false}
                            defaultValue={MoneyFormat(0)}
                        />
                    </>
                ) : (
                    <p
                        className="valor-adiantamento"
                        style={{
                            padding: '0 !important',
                            marginBottom: '0 !important'
                        }}
                    >
                        {solicitacao.viajantes ? (
                            <>
                                {IntlRealFormat(
                                    valorTotal / solicitacao?.viajantes?.length
                                )}
                            </>
                        ) : null}
                    </p>
                )}
            </div>
        )
    }

    function getTabelaMobile() {
        return (
            <Box>
                {solicitacao?.viajantes &&
                    solicitacao?.viajantes.map((tableItem, index) => {
                        if (tableItem) {
                            return (
                                <Quadro>
                                    <table style={{ width: '100%' }}>
                                        <TrMobile>
                                            {getCampo(
                                                index,
                                                'Cod',
                                                tableItem.Codigo
                                            )}
                                            {getCampo(
                                                index,
                                                'Nome',
                                                tableItem.Nome
                                            )}
                                            {getCampo(
                                                index,
                                                'Contato',
                                                dadosUsuario.length &&
                                                    carregarDadosUsuario(
                                                        tableItem.Codigo
                                                    )
                                            )}
                                            {getCampo(
                                                index,
                                                'Adiantamento',
                                                getAdiantamento(
                                                    solicitacao,
                                                    index
                                                )
                                            )}
                                        </TrMobile>
                                    </table>
                                </Quadro>
                            )
                        }
                        return null
                    })}
            </Box>
        )
    }
}

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
`
const ViajantePrincipalDiv = styled.button`
    background-color: #10478a;
    padding: 0.8vh;
    color: white;
    font-size: 8pt;
    border-radius: 0.7vh;
    border: none;
    width: 15vh;
`
const TornarPrincipalBtn = styled.button`
    padding: 0.8vh;
    color: #fff;
    background-color: #c5dbff;
    font-size: 8pt;
    border-radius: 0.7vh;
    border: none;
    width: 15vh;
`
const Box = styled.div`
    border: solid 1px lightgray;
    border-radius: 15px;
    @media (max-width: 768px) {
        border: solid 0px lightgray;
        border-radius: 0px;
        margin-left: -0.4rem;
    }
`

const TrMobile = styled.tr`
    border: 0 !important;
    z-index: 10;
`
const ThMobile = styled.th`
    position: relative;
    width: auto !important;
    height: auto;
    color: #002a5d;
`
const TdMobile = styled.td`
    padding: 0.5rem 0rem !important;
    color: ${props => props.theme.colors.text};
    padding-left: 5px !important;
    color: #002a5d;
    .form-select {
        background-color: #f1f3f5;
    }
    .button.dropdown-item {
        background: #f1f3f5;
    }
`

const Quadro = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 8px;

    width: 342px;
    height: 0 auto;
    min-height: 218px;
    background: #f1f3f5;
    border: 1px solid #dee2e6;

    box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 1rem 0;
`
const Container = styled.div`
    @media (max-width: 100px) {
        border: solid 0px lightgray;
    }
`

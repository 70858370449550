import React, { useEffect, useRef, useState } from 'react'
import { ENDPOINTS } from '../../../services/config-http'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { BlankBoard } from './../../../core/components/BlankBoard/index'
import { Header } from '../../../core/components/Header/Header'
import { get, post, put, destroy, patch } from '../../../services/http'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Button } from './../../../core/components/Button/index'
import { LineSeparator } from './../../../core/components/LineSeparator/index'
import { Center } from '../../../core/components/Center'
import { SelectRequester } from '../../../core/components/Form/SelectWithRequest'
import { Input } from '../../../core/components/Form/Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Yup from 'yup'
import { DatePicker } from '../../../core/components/DatePicker'
import { Space } from '../../../core/components/Space'
import { useAlert } from '../../../hooks/AlertToast'
import { Deletar } from '../../../core/components/Deletar'
import { Empty } from '../../../core/components/Empty'
import { SelectMapper } from '../../../core/utils/SelectMapper'
import { Select } from '../../../core/components/Form/Select'
import { STATUS_SOLICITACAO } from '../../../contexts/enums/StatusSolicitacao'
import { Alert } from '../../../core/components/Alert'
import { ModalPrestacao } from './ModalPrestacao'
import { ImagePrint } from '../../../core/components/ImagePrint'
import { Toast } from '../../../core/components/Toast'
import { MoneyFormat } from '../../../core/utils/MoneyFormat'
import moment from 'moment'
import { formataValor } from '../../../util/legenda/util'
import useWindowDimensions from '../../../core/components/GetDimensions'
import { OptionTypeBase } from 'react-select'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { IntlRealFormat } from '../../../core/utils/IntlFormat'
import { InputCurrency } from '../../../core/components/Form/InputCurrency'

export const PrestacaoDeContas = () => {
    const { width } = useWindowDimensions()
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const formUserRef = useRef<FormHandles>(null)
    const [buttonRef, setButtonOpen] = useState<HTMLButtonElement>()
    const [solicitacao, setSolicitacao] = useState<any>()
    const [roteiro, setRoteiro] = useState<any>([])
    const [currentDate, setCurrentDate] = useState<any>(null)
    const [idDespesaSelect, setIdDespesaSelect] = useState()
    const [tipoDespesa, setTipoDespesa] = useState<Array<any>>([])
    const [regraDespesa, setRegraDespesa] = useState<Array<any>>([])
    const [selectedFile, setSelectedFile] = useState<any>(undefined)
    const [hardSelectedFile, setHardSelectedFile] = useState<any>()
    const [isPickedFile, setIsPickedFile] = useState<boolean>(false)
    const [viajantes, setViajantes] = useState([] as any)
    const [prestacao, setPrestacao] = useState<any>()
    const { IdSolicitacao, IdPrestacao } = useParams<any>()
    const [codigo, setCodigo] = useState<any>(0)
    const [isEditing, setIsEditing] = useState(false)
    const [tablePrestacoes, setTablePrestacoes] = useState<Array<any>>([])
    const [prestacoesDeletadas, setPrestacoesDeletadas] = useState<Array<any>>(
        []
    )
    const [showModal, setShowModal] = useState(false)
    const [nomeAnexoBlob, setNomeAnexoBlob] = useState()
    const [showToast, setShowToast] = useState(false)
    const { setAlert, showAlert } = useAlert()
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })
    const [valorPrestacao, setValorPrestacao] = useState(0)

    interface PrestacaoDespesa {
        DataCriacao?: string
        DataDespesa: string
        Id?: number
        IdPrestacao?: number
        IdTipoDespesa: number
        Justificativa: string
        NomeAnexo: string
        NomeAnexoBlob?: string
        Valor: number
        anexo?: undefined
        adicionadoNaEdicao?: boolean
    }

    useEffect(() => {
        const montarSelectUsuario = async () => {
            await get(
                `${ENDPOINTS.statusPrestacaoIndividual}${IdSolicitacao}`
            ).then(({ data: { Model } }) => {
                const viajantesPrestacaoPendente = Model.filter(
                    (s: { Status: STATUS_SOLICITACAO }) =>
                        s.Status ===
                            STATUS_SOLICITACAO.REPROVADO_PRESTACAO_DE_CONTAS ||
                        s.Status ===
                            STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS
                )
                setViajantes(
                    SelectMapper(viajantesPrestacaoPendente, 'Nome', 'Id')
                )
            })
        }
        montarSelectUsuario()

        get(`${ENDPOINTS.solicitacaoViagemPorId}/${IdSolicitacao}`).then(
            ({ data: { Model } }) => {
                setSolicitacao(Model)
            }
        )

        get(
            UrlMountQuery(
                {
                    IdSolicitacaoViagem: IdSolicitacao
                },
                ENDPOINTS.roteiroByIdSolicitacao
            )
        ).then(({ data }) => {
            setRoteiro(data)
        })
        get(ENDPOINTS.tipoDespesaAll).then(({ data: { Items } }) => {
            setTipoDespesa(Items)
        })
    }, [])

    useEffect(() => {
        if (IdPrestacao !== '0') {
            const idPrestacaoNumber = Number(IdPrestacao)
            const data = {
                IdPrestacao: idPrestacaoNumber
            }
            formUserRef.current?.setData({ ...data })

            get(`${ENDPOINTS.prestarContas}/${IdPrestacao}`).then(
                ({ data: { Model } }) => {
                    if (
                        Model?.Status ===
                        STATUS_SOLICITACAO.REPROVADO_PRESTACAO_DE_CONTAS
                    ) {
                        get(
                            `${ENDPOINTS.prestarContasDespesas}/${Model?.Id}`
                        ).then(({ data: { Model } }) => {
                            Model?.forEach((element: any) => {
                                element.DataDespesa = new Date(
                                    element.DataDespesa
                                ).toLocaleDateString()
                            })
                            setTablePrestacoes(Model)
                        })
                    }
                    setPrestacao(Model)
                }
            )
        }
    }, [viajantes])

    const redirectSolicitacao = () => {
        history.goBack()
    }

    const carregarRegras = async () => {
        const codigo = solicitacao.Viajantes.filter(
            (item: { Convidado: boolean }) => item.Convidado === false
        )[0]?.IdUsuario
        get(`${ENDPOINTS.politicaPermissaoNovo}/${codigo}`).then(res => {
            setRegraDespesa(res?.data?.Data)
        })
    }

    function validarRegra(itemSelect: OptionTypeBase | null, valor: number) {
        const regras = regraDespesa.filter(
            item => item.IdTipoDespesa === itemSelect
        )[0]
        if (regras) {
            const { ValorMaximo, Acao } = regras
            if (formataValor(valor) === 0) {
                showAlert(true)
                getMsgAlert('Atenção!', 'warning', 'Valor não informado!')
                return true
            }
            if (regras.Frequencia !== 'Por Ocorrência') {
                let somaValorTotal = 0
                tablePrestacoes
                    .filter(item => item.IdTipoDespesa === itemSelect)
                    .map(item => {
                        if (
                            !isEditing ||
                            (isEditing && idDespesaSelect !== item.Id)
                        ) {
                            somaValorTotal =
                                somaValorTotal + formataValor(item.Valor)
                        }
                    })
                if (somaValorTotal + formataValor(valor) > ValorMaximo) {
                    if (Acao === 'Bloqueia') {
                        showAlert(true)
                        getMsgAlert('Atenção!', 'warning')
                        return true
                    }
                    if (Acao === 'Alerta') {
                        showAlert(true)
                        getMsgAlert('Sucesso!', 'success')
                        return false
                    }
                }
            } else {
                if (formataValor(valor) > ValorMaximo) {
                    if (Acao === 'Bloqueia') {
                        showAlert(true)
                        getMsgAlert('Atenção!', 'warning')
                        return true
                    }
                    if (Acao === 'Alerta') {
                        showAlert(true)
                        getMsgAlert('Sucesso!', 'warning')
                        return false
                    }
                }
            }
        }
        setAlert({
            type: 'success',
            time: 5000,
            title: 'Sucesso',
            subTitle: 'Item adicionado a lista com sucesso!'
        })
        showAlert(true)
        return false
    }

    const getMsgAlert = (title: string, type: any, msg?: string) => {
        setAlert({
            type,
            time: 5000,
            title,
            subTitle: `${
                msg ||
                'Valor informado é superior ao permitido da regra para este item!'
            }`
        })
    }

    const addDate = async () => {
        const { DataDespesa } = formRef.current?.getData() as any
        const { IdPrestacao } = formUserRef.current?.getData() as any
        try {
            const schema = Yup.object().shape({
                IdPrestacao: Yup.string().required(
                    'Voce precisa selecionar um viajante para continuar'
                ),
                DataDespesa: Yup.string().required(
                    'Voce precisa selecionar uma data para continuar'
                )
            })

            await schema.validate(
                {
                    IdPrestacao,
                    DataDespesa:
                        DataDespesa === null
                            ? ''
                            : DataDespesa.toLocaleDateString()
                },
                { abortEarly: false }
            )

            setCurrentDate(DataDespesa.toLocaleDateString())
            formRef.current?.reset()
            formRef.current?.setErrors({})
            formUserRef.current?.setErrors({})
            setCodigo(tablePrestacoes.length + 1)
            if (isEditing) {
                setSelectedFile(undefined)
            }
            setIsEditing(false)
            setNomeAnexoBlob(undefined)
            carregarRegras()
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                formRef.current?.setErrors(erros)
                formUserRef.current?.setErrors(erros)
            }
        }
    }

    const validarPoliticaPermissao = async (tipoDespesa: any, valor: any) => {
        get(`${ENDPOINTS.usuarioPesquisarId}?Id${prestacao?.IdUsuario}`).then(
            ({ data: { Model } }) => {
                get(
                    `${ENDPOINTS.politicaPermissaoPorIdPolitica}/${Model.IdPoliticaViagem}`
                ).then(({ data: { Model } }) => {
                    const permissoes: Array<any> = Model
                    permissoes.forEach(permissao => {
                        if (
                            permissao?.IdTipoDespesa === tipoDespesa &&
                            permissao?.ValorMaximo < valor
                        ) {
                            setShowModal(true)
                        }
                    })
                })
            }
        )
    }
    function getCampo(index: number, title: string, valor: any) {
        return (
            <div
                style={{
                    flexDirection: 'column'
                }}
            >
                <ThMobile key={index}>{title}</ThMobile>
                <TdMobile
                    key={index}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {valor}
                </TdMobile>
            </div>
        )
    }

    const editarDespesa = async (despesa: any, cod: any) => {
        setIsEditing(true)
        setCurrentDate(despesa.DataDespesa)
        carregarRegras()
        console.log(despesa)
        setIdDespesaSelect(despesa?.Id)
        const data = {
            DataDespesa: moment(despesa.DataDespesa, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
            ),
            IdTipoDespesa: despesa.IdTipoDespesa,
            Valor: despesa.Valor,
            Justificativa: despesa.Justificativa,
            NomeAnexo: despesa.NomeAnexo
        }
        formRef.current?.setData({ ...data })
        setCodigo(cod)
        setIsPickedFile(false)
        if (despesa.NomeAnexoBlob) {
            setNomeAnexoBlob(despesa.NomeAnexoBlob)
            setSelectedFile(undefined)
        } else {
            setSelectedFile(URL.createObjectURL(despesa.anexo))
            setNomeAnexoBlob(undefined)
        }
    }

    const addEdicao = async () => {
        // const idPrestacaoNumber = Number(IdPrestacao)
        const dataForm: any = formRef.current?.getData()
        dataForm.DataDespesa = currentDate
        dataForm.cod = tablePrestacoes.length + 1
        if (isPickedFile) {
            dataForm.anexo = hardSelectedFile
            dataForm.NomeAnexo = hardSelectedFile.name
        }
        try {
            const schema = Yup.object().shape({
                IdTipoDespesa: Yup.string()
                    .nullable()
                    .required('O Tipo de despesa é obrigatório'),
                Valor: Yup.string().required('O Valor é obrigatório'),
                Justificativa: Yup.string().required(
                    'A Justificativa é obrigatória'
                )
                // anexo: Yup.string().required('O anexo é obrigatório')
            })
            await schema.validate(dataForm, { abortEarly: false })
            const aborta = await validarRegra(
                dataForm.IdTipoDespesa,
                dataForm.Valor
            )
            if (aborta) {
                throw new Error('validarRegra')
            }
            const newTablePrestacoes = [...tablePrestacoes]
            if (isPickedFile) {
                const prestacaoEditada: PrestacaoDespesa = {
                    Id: newTablePrestacoes[codigo - 1].Id,
                    DataDespesa: dataForm.DataDespesa,
                    IdTipoDespesa: dataForm.IdTipoDespesa,
                    Justificativa: dataForm.Justificativa,
                    NomeAnexo: dataForm.NomeAnexo,
                    Valor: dataForm.Valor,
                    // Number(dataForm.Valor),
                    anexo: dataForm.anexo,
                    adicionadoNaEdicao:
                        newTablePrestacoes[codigo - 1].adicionadoNaEdicao
                }
                newTablePrestacoes[codigo - 1] = prestacaoEditada
            } else {
                const prestacaoEditada: PrestacaoDespesa = {
                    Id: newTablePrestacoes[codigo - 1].Id,
                    DataDespesa: dataForm.DataDespesa,
                    IdTipoDespesa: dataForm.IdTipoDespesa,
                    Justificativa: dataForm.Justificativa,
                    NomeAnexo: newTablePrestacoes[codigo - 1].NomeAnexo,
                    Valor: dataForm.Valor,
                    anexo: newTablePrestacoes[codigo - 1].anexo,
                    NomeAnexoBlob: nomeAnexoBlob,
                    adicionadoNaEdicao:
                        newTablePrestacoes[codigo - 1].adicionadoNaEdicao
                }
                newTablePrestacoes[codigo - 1] = prestacaoEditada
            }
            setTablePrestacoes(newTablePrestacoes)
            // setTablePrestacoes([
            //     ...tablePrestacoes,
            //     {
            //         ...dataForm
            //     }
            // ])
            formRef.current?.reset()
            formRef.current?.setErrors({})
            setIsPickedFile(false)
            setSelectedFile(undefined)
            setCodigo(tablePrestacoes.length + 1)
            setIsEditing(false)
            setCurrentDate(null)
            setNomeAnexoBlob(undefined)
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                formRef.current?.setErrors(erros)
            }
        }
    }

    const addPrestacao = async () => {
        const dataForm: any = formRef.current?.getData()
        dataForm.DataDespesa = currentDate
        dataForm.cod = tablePrestacoes.length + 1
        if (isPickedFile) {
            dataForm.anexo = hardSelectedFile
            dataForm.NomeAnexo = hardSelectedFile.name
        }
        try {
            const schema = Yup.object().shape({
                IdTipoDespesa: Yup.string()
                    .nullable()
                    .required('O Tipo de despesa é obrigatório'),
                Valor: Yup.string().required('O Valor é obrigatório'),
                Justificativa: Yup.string().required(
                    'A Justificativa é obrigatória'
                ),
                anexo: Yup.string().required('O anexo é obrigatório')
            })
            await schema.validate(dataForm, { abortEarly: false })

            setCodigo(tablePrestacoes.length + 2)

            const {
                DataDespesa,
                IdTipoDespesa,
                Justificativa,
                NomeAnexo,
                anexo,
                cod,
                Valor
            } = dataForm

            const dadosForm = {
                DataDespesa,
                IdTipoDespesa,
                Justificativa,
                NomeAnexo,
                Valor,
                anexo,
                cod
            }

            const aborta = validarRegra(IdTipoDespesa, Valor)
            if (aborta) {
                throw new Error('validarRegra')
            }
            if (
                prestacao.Status ===
                STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS
            ) {
                setTablePrestacoes([
                    ...tablePrestacoes,
                    {
                        ...dadosForm
                    }
                ])
            } else {
                setTablePrestacoes([
                    ...tablePrestacoes,
                    {
                        ...dadosForm,
                        adicionadoNaEdicao: true
                    }
                ])
            }
            formRef.current?.reset()
            formRef.current?.setErrors({})
            setIsPickedFile(false)
            setSelectedFile(undefined)
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                formRef.current?.setErrors(erros)
            }
        }
    }

    const changeHandler = async (event: any) => {
        const selectedFiles = event.target.files[0]
        setHardSelectedFile(selectedFiles)
        setSelectedFile(URL.createObjectURL(selectedFiles))
        setIsPickedFile(true)
        event.target.value = null
    }

    const changeViajante = async (id: any) => {
        get(`${ENDPOINTS.prestarContas}/${id}`).then(({ data: { Model } }) => {
            if (
                Model?.Status ===
                STATUS_SOLICITACAO.REPROVADO_PRESTACAO_DE_CONTAS
            ) {
                const dadosFormatados: React.SetStateAction<any[]> = []
                get(`${ENDPOINTS.prestarContasDespesas}/${Model?.Id}`).then(
                    ({ data: { Model } }) => {
                        Model?.forEach((element: any) => {
                            element.DataDespesa = new Date(
                                element.DataDespesa
                            ).toLocaleDateString()
                            dadosFormatados.push({
                                IdTipoDespesa: element?.IdTipoDespesa,
                                Valor: MoneyFormat(element?.Valor)
                                    .toString()
                                    .replaceAll('.', ','),
                                Justificativa: element?.Justificativa,
                                DataDespesa: element?.DataDespesa,
                                IdPrestacao: element?.IdPrestacao,
                                NomeAnexoBlob: element?.NomeAnexoBlob,
                                DataCriacao: element?.DataCriacao,
                                Id: element?.Id,
                                NomeAnexo: element?.NomeAnexo
                            })
                        })
                        setTablePrestacoes(dadosFormatados)
                        setCurrentDate(null)
                    }
                )
            } else if (
                Model?.Status ===
                    STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS &&
                prestacao?.Status ===
                    STATUS_SOLICITACAO.REPROVADO_PRESTACAO_DE_CONTAS
            ) {
                setTablePrestacoes([])
                setCurrentDate(null)
            }

            setPrestacao(Model)
            setIsEditing(false)
        })
    }

    const sendPrestacoes = () => {
        const promises: any[] = []
        const prestacoes = [...tablePrestacoes]
        const dataFormPrestacao: any = formUserRef.current?.getData()
        if (
            prestacao?.Status ===
            STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS
        ) {
            prestacoes.forEach(e => {
                const formData = new FormData()
                if (e.anexo !== undefined) {
                    formData.append('Anexo', e.anexo, e.anexo.name)
                }
                promises.push(
                    post(
                        UrlMountQuery(
                            {
                                IdTipoDespesa: e.IdTipoDespesa,
                                Valor: e.Valor.replaceAll('.', '')
                                    .replaceAll(',', '.')
                                    .replaceAll('R$', '')
                                    .trim(),
                                Justificativa: e.Justificativa,
                                DataDespesa: moment(
                                    e.DataDespesa,
                                    'DD/MM/YYYY'
                                ).format('YYYY-MM-DD'),
                                IdPrestacao: dataFormPrestacao.IdPrestacao
                            },
                            ENDPOINTS.prestarContasDespesas
                        ),
                        formData
                    )
                )
            })
        } else {
            prestacoes.forEach(e => {
                const formData = new FormData()
                if (e.anexo !== undefined) {
                    formData.append('Anexo', e.anexo, e.anexo.name)
                }
                if (e.adicionadoNaEdicao === true) {
                    promises.push(
                        post(
                            UrlMountQuery(
                                {
                                    Id: e.Id,
                                    IdTipoDespesa: e.IdTipoDespesa,
                                    Valor: e.Valor.replaceAll('.', '')
                                        .replaceAll(',', '.')
                                        .replaceAll('R$', '')
                                        .trim(),
                                    Justificativa: e.Justificativa,
                                    DataDespesa: moment(
                                        e.DataDespesa,
                                        'DD/MM/YYYY'
                                    ).format('YYYY-MM-DD'),
                                    IdPrestacao: dataFormPrestacao.IdPrestacao
                                },
                                ENDPOINTS.prestarContasDespesas
                            ),
                            formData
                        )
                    )
                } else {
                    promises.push(
                        put(
                            UrlMountQuery(
                                {
                                    Id: e.Id,
                                    IdTipoDespesa: e.IdTipoDespesa,
                                    Valor: e.Valor.replaceAll('.', '')
                                        .replaceAll(',', '.')
                                        .replaceAll('R$', '')
                                        .trim(),
                                    Justificativa: e.Justificativa,
                                    DataDespesa: moment(
                                        e.DataDespesa,
                                        'DD/MM/YYYY'
                                    ).format('YYYY-MM-DD'),
                                    IdPrestacao: dataFormPrestacao.IdPrestacao,
                                    NomeAnexoBlob: e.NomeAnexoBlob
                                },
                                ENDPOINTS.prestarContasDespesas
                            ),
                            formData
                        )
                    )
                }
            })
        }
        prestacoesDeletadas.forEach(prestacaoDeletada => {
            promises.push(
                destroy(
                    `${ENDPOINTS.prestarContasDespesas}/${prestacaoDeletada.Id}`
                )
            )
        })

        put(ENDPOINTS.prestarContas, {
            id: prestacao.Id,
            idSolicitacaoViagem: prestacao.IdSolicitacaoViagem,
            idusuario: prestacao.IdUsuario,
            idAprovador: prestacao.IdAprovador,
            idStatusPrestacao: 4
        })

        if (viajantes.length === 1) {
            patch(
                UrlMountQuery(
                    {
                        Id: solicitacao.Id,
                        IdSituacao: 4
                    },
                    ENDPOINTS.solicitarViagem
                )
            )
        }

        Promise.all(promises)
            .then(() => {
                setShowToast(true)
                setToast({
                    message: 'Prestações enviadas!',
                    type: 'success'
                })
                redirectSolicitacao()
            })
            .catch(({ data }) => {
                setShowToast(true)
                setToast({
                    message: 'Erro ao tentar enviar prestacção de contas',
                    type: 'error'
                })
            })
    }

    function getTipoDespesa(param: any) {
        return tipoDespesa.filter(({ Id }) => Id === param.IdTipoDespesa)[0]
            ?.Descricao
    }

    return (
        <BlankBoard>
            <Header
                title="Prestação de Contas"
                actionArrowClick={() => history.goBack()}
            ></Header>

            <ModalPrestacao show={showModal} setShow={setShowModal} />

            <Center>
                <h2 className="text-center">
                    Declare aqui suas <b>despesas</b> durante a viagem.
                </h2>
            </Center>

            <Space height="1.5rem"></Space>

            <Alert
                message={prestacao?.Observacao}
                boldMessage="Solicitação Reprovada."
                status={prestacao?.IdStatusPrestacao === 7}
                type="danger"
                icon="exclamation-circle"
                fixed={true}
            ></Alert>

            <Space height="1.5rem"></Space>

            {solicitacao?.IdSituacao !== 3 && solicitacao !== undefined
                ? buttonRef?.click()
                : null}
            <h2>Informações</h2>
            <div className="row">
                <div className="col-12 col-sm-8">
                    <Info>
                        <div className="f-size-08rem">
                            <FontAwesomeIcon icon="bars" />
                            &ensp;
                            {`  ${roteiro[0]?.Objetivo}` || 'Carregando...'}
                        </div>
                        {roteiro?.map((roteiroUnico: any, key: number) => (
                            <div key={key} className="f-size-08rem">
                                <FontAwesomeIcon icon="map-marker-alt" />
                                &ensp;
                                {`  ${roteiroUnico?.Destino}` ||
                                    'Carregando...'}
                            </div>
                        ))}
                        <div className="f-size-08rem">
                            <FontAwesomeIcon icon="calendar-alt" />
                            &ensp;
                            {`   ${
                                new Date(
                                    roteiro[0]?.DataInicio
                                ).toLocaleDateString() || 'Carregando...'
                            }  -  ${new Date(
                                roteiro[roteiro.length - 1]?.DataTermino
                            ).toLocaleDateString()}` || 'Carregando...'}
                        </div>
                    </Info>
                </div>
                <div className="col-12 col-sm-4">
                    <Form
                        ref={formUserRef}
                        onSubmit={() => {
                            console.log()
                        }}
                    >
                        <Select
                            name="IdPrestacao"
                            label="Viajante"
                            className="scale-in-ver-top"
                            options={viajantes}
                            required={true}
                            placeholder="Selecione o viajante"
                            onChange={e => {
                                changeViajante(e?.value)
                            }}
                        ></Select>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            {prestacao !== undefined ? (
                                <span>Status do Viajante:</span>
                            ) : null}
                            {prestacao?.Status ===
                            STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS ? (
                                <span
                                    className="badge status-pendding"
                                    style={{ margin: 'auto', color: '#5D5D5D' }}
                                >
                                    <FontAwesomeIcon icon="circle" />
                                    &ensp;
                                    {prestacao.Status}
                                </span>
                            ) : prestacao?.Status ===
                              STATUS_SOLICITACAO.REPROVADO_PRESTACAO_DE_CONTAS ? (
                                <span
                                    className="badge status-danger"
                                    style={{ margin: 'auto' }}
                                >
                                    <FontAwesomeIcon icon="circle" />
                                    &ensp;
                                    {prestacao.Status}
                                </span>
                            ) : null}
                        </div>
                    </Form>
                </div>
            </div>
            <LineSeparator style={{ margin: '2rem 0' }}></LineSeparator>

            <Form
                ref={formRef}
                onSubmit={() => {
                    console.log()
                }}
            >
                <h2>Despesas</h2>

                <div className="row mb-2">
                    <div className="col-12 col-sm-4 col-md-4">
                        <DatePicker
                            name="DataDespesa"
                            label="Qual dia quer declarar?"
                            placeholderText="Selecione uma data"
                            required={true}
                            minDate={new Date(roteiro[0]?.DataInicio)}
                            maxDate={
                                new Date(
                                    roteiro[roteiro.length - 1]?.DataTermino
                                )
                            }
                        ></DatePicker>
                    </div>
                    <div className="col-12 col-sm-3 col-md-5"></div>

                    <div className="col-12 col-sm-4 col-md-3 d-flex align-items-center">
                        <Button
                            colorType="quintiary"
                            onClick={() => addDate()}
                            style={{
                                minWidth: 'auto',
                                width: '100%',
                                marginTop: '15px'
                            }}
                        >
                            Declarar
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div
                            className={`alert alert-warning d-flex align-items-center mb-3 mt-2 ${
                                currentDate ? 'invisible-item' : ''
                            }`}
                            role="alert"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                viewBox="0 0 16 16"
                                role="img"
                                aria-label="Warning:"
                            >
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                            <div>
                                {prestacao?.Status ===
                                STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS
                                    ? 'Escolha uma data da viagem para declarar as despesas daquele dia.'
                                    : 'Escolha uma data da viagem para declarar as despesas daquele dia ou edite as despesas já cadastradas'}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`row ${currentDate ? '' : 'invisible-item'}`}>
                    <div className="col-10">
                        <h2>{isEditing ? 'Edição da Despesa' : currentDate}</h2>
                        <p
                            className={`gray-text ${
                                isEditing ? 'invisible-item' : ''
                            }`}
                        >
                            Declare todas as despesas desse dia
                        </p>
                    </div>
                    <div className="col-2">
                        <Center style={{ justifyContent: 'flex-end' }}>
                            <h1>{codigo}</h1>
                        </Center>
                    </div>

                    <div className="col-8">
                        <SelectRequester
                            name="IdTipoDespesa"
                            label="Tipo de Despesa"
                            placeholder="Selecione o tipo de Despesa"
                            required={true}
                            request={{
                                endpoint: ENDPOINTS.tipoDespesaAll,
                                labelToSelect: 'Descricao',
                                valueToSelect: 'Id'
                            }}
                        ></SelectRequester>
                    </div>
                    <div className="col-4">
                        <InputCurrency
                            onChange={(e: any) => {
                                const valorReplace = Number(
                                    e.currentTarget.value
                                        .replaceAll('.', '')
                                        .replaceAll(',', '.')
                                        .replaceAll('R$', '')
                                )
                                setValorPrestacao(valorReplace)
                            }}
                            value={IntlRealFormat(valorPrestacao || 0)}
                            width="col-12"
                            label="Valor"
                            name="Valor"
                            mask="999.999,99"
                            required={true}
                            defaultValue={MoneyFormat(0)}
                        />
                    </div>
                    <div className="col-12">
                        <Input
                            type="textarea"
                            name="Justificativa"
                            label="Justificativa"
                            required={true}
                        ></Input>
                    </div>
                    <div className="row">
                        <div className="input-group">
                            <Anexar
                                style={{
                                    marginRight: '10px'
                                }}
                                className="input-group-text"
                                id="inputGroupFile"
                                // style={{}}
                                htmlFor="file"
                            >
                                <FontAwesomeIcon
                                    color="white"
                                    icon="paperclip"
                                />
                            </Anexar>

                            <Input
                                style={{
                                    display: 'none'
                                }}
                                type="file"
                                name="anexo"
                                id="file"
                                aria-describedby="inputGroupFile"
                                onChange={e => changeHandler(e)}
                            />
                        </div>

                        <p
                            style={{
                                color: '#666666',
                                fontSize: '17px'
                            }}
                        >
                            Anexe o(s) comprovante(s) dessa declaração *
                        </p>
                    </div>
                    <div className="col-12 col-sm-10">
                        <div className={selectedFile ? '' : 'invisible-item'}>
                            <ImagePrint
                                linkImg={selectedFile}
                                flagShowButtons={false}
                            ></ImagePrint>
                        </div>
                        <div
                            className={
                                isEditing && nomeAnexoBlob
                                    ? ''
                                    : 'invisible-item'
                            }
                        >
                            <ImagePrint
                                linkImg={
                                    tablePrestacoes.length > 0
                                        ? `${nomeAnexoBlob}`
                                        : ''
                                }
                                flagShowButtons={true}
                            ></ImagePrint>
                        </div>
                    </div>
                    <div className="col-12 col-sm-2 mt-4">
                        <Button
                            icon={isEditing ? 'save' : 'plus'}
                            colorType="quaternary"
                            style={{ width: '100%', minWidth: 'auto' }}
                            onClick={() =>
                                isEditing ? addEdicao() : addPrestacao()
                            }
                        >
                            {isEditing ? 'Salvar Alterações' : 'Adicionar'}
                        </Button>
                    </div>
                </div>
                {width && width >= 769 ? (
                    tablePrestacoes.length > 0 ? (
                        // <div className="row" style={{ marginTop: '3rem' }}>
                        <div
                            className="table-responsive"
                            style={{
                                border: 'solid 1px lightgray',
                                borderRadius: '15px',
                                marginTop: '3rem'
                            }}
                        >
                            <table className="table">
                                <thead
                                    style={{
                                        borderBottom: 'solid 1px lightgray'
                                    }}
                                >
                                    <tr
                                        style={{
                                            border: '0 !important'
                                        }}
                                    >
                                        <Th
                                            style={{
                                                borderBottomWidth:
                                                    '0 !important'
                                            }}
                                            scope="col"
                                        >
                                            #
                                        </Th>
                                        <Th
                                            style={{
                                                textAlign: 'center'
                                            }}
                                            scope="col"
                                        >
                                            Tipo de Despesa
                                        </Th>
                                        <Th
                                            style={{
                                                textAlign: 'center'
                                            }}
                                            scope="col"
                                        >
                                            Valor
                                        </Th>
                                        <Th
                                            style={{
                                                textAlign: 'center'
                                                // width: '15rem'
                                            }}
                                            scope="col"
                                        >
                                            Justificativa
                                        </Th>
                                        <Th
                                            style={{
                                                textAlign: 'center'
                                            }}
                                            scope="col"
                                        >
                                            Data da Despesa
                                        </Th>
                                        <Th
                                            style={{
                                                textAlign: 'center'
                                            }}
                                            scope="col"
                                        >
                                            Anexo
                                        </Th>
                                        <Th
                                            style={{
                                                textAlign: 'center'
                                            }}
                                            scope="col"
                                        >
                                            Ações
                                        </Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tablePrestacoes.map((object, key) => {
                                        return (
                                            <tr key={key}>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {key + 1}
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {getTipoDespesa(object)}
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {object.Valor}
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center'
                                                        // width: '15rem'
                                                    }}
                                                >
                                                    {object.Justificativa}
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {object.DataDespesa}
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {object.NomeAnexo}
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <FontAwesomeIcon
                                                                className={`${
                                                                    prestacao?.Status ===
                                                                    STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS
                                                                        ? 'invisible-item'
                                                                        : ''
                                                                }`}
                                                                onClick={e => {
                                                                    editarDespesa(
                                                                        object,
                                                                        key + 1
                                                                    )
                                                                }}
                                                                color="#6C7480"
                                                                icon="pencil-alt"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-10">
                                                            <Deletar
                                                                remover={() => {
                                                                    const newPrestacoes =
                                                                        [
                                                                            ...tablePrestacoes
                                                                        ]
                                                                    const prestacaoDeletada =
                                                                        newPrestacoes.splice(
                                                                            key,
                                                                            1
                                                                        )
                                                                    if (
                                                                        !prestacaoDeletada[0]
                                                                            .adicionadoNaEdicao ||
                                                                        prestacaoDeletada[0]
                                                                            .adicionadoNaEdicao ===
                                                                            undefined
                                                                    ) {
                                                                        prestacoesDeletadas.push(
                                                                            prestacaoDeletada[0]
                                                                        )
                                                                    }

                                                                    setTablePrestacoes(
                                                                        newPrestacoes
                                                                    )
                                                                    setCodigo(
                                                                        tablePrestacoes.length
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        // </div>
                        <div style={{ marginTop: '2rem' }}>
                            <Empty text="Nenhuma despesa adicionada" />
                        </div>
                    )
                ) : tablePrestacoes.length ? (
                    tablePrestacoes.map((object, key) => (
                        <Box key={key}>
                            <Quadro>
                                <table
                                    style={{
                                        width: '100%'
                                    }}
                                >
                                    <TrMobile>
                                        {getCampo(key, '#:', key + 1)}
                                        {getCampo(
                                            key,
                                            'Tipo de Despesa:',
                                            tipoDespesa.filter(
                                                ({ Id }) =>
                                                    Id === object.IdTipoDespesa
                                            )[0]?.Descricao
                                        )}
                                        {getCampo(
                                            key,
                                            'Valor:',
                                            MoneyFormat(Number(object.Valor))
                                        )}
                                        {getCampo(
                                            key,
                                            'Justificativa:',
                                            object.Justificativa
                                        )}
                                        {getCampo(
                                            key,
                                            'Data da Despesa:',
                                            object.DataDespesa
                                        )}
                                        {getCampo(
                                            key,
                                            'Anexo:',
                                            object.NomeAnexo
                                        )}
                                        {getCampo(
                                            key,
                                            'Ações:',
                                            <Deletar
                                                remover={() => {
                                                    const newPrestacoes = [
                                                        ...tablePrestacoes
                                                    ]
                                                    const prestacaoDeletada =
                                                        newPrestacoes.splice(
                                                            key,
                                                            1
                                                        )
                                                    if (
                                                        !prestacaoDeletada[0]
                                                            .adicionadoNaEdicao ||
                                                        prestacaoDeletada[0]
                                                            .adicionadoNaEdicao ===
                                                            undefined
                                                    ) {
                                                        prestacoesDeletadas.push(
                                                            prestacaoDeletada[0]
                                                        )
                                                    }

                                                    setTablePrestacoes(
                                                        newPrestacoes
                                                    )
                                                    setCodigo(
                                                        tablePrestacoes.length
                                                    )
                                                }}
                                            />
                                        )}
                                    </TrMobile>
                                </table>
                            </Quadro>
                        </Box>
                    ))
                ) : (
                    <Box>
                        <Quadro>
                            <Center>
                                <Aviso>Nenhuma despesa adicionada</Aviso>
                            </Center>
                        </Quadro>
                    </Box>
                )}
                {/* </div> */}
                {/* <Table
                    dataTable={tablePrestacoes}
                    mapper={tableMapper}
                    config={tableConfig}
                ></Table> */}
                <Space height="3rem"></Space>
                <Center>
                    <Button
                        colorType="quaternary"
                        className={
                            tablePrestacoes.length ? '' : 'invisible-item'
                        }
                        onClick={() => sendPrestacoes()}
                    >
                        Enviar Prestações
                    </Button>
                </Center>
            </Form>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </BlankBoard>
    )
}

const Attach = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0.3rem 0 0;
    * {
        margin: 0.3rem;
        text-decoration: underline;
    }
`

const Info = styled.div`
    font-size: 0.9rem;
`
const Anexar = styled.label`
    background-color: #24538b;
    border: none;
    border-radius: 3px;
    width: 40px;
    height: 40px;
    font-size: 1.5em;
    cursor: pointer;
`
const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
`

const Box = styled.div`
    border: solid 1px lightgray;
    border-radius: 15px;
    @media (max-width: 768px) {
        border: solid 0px lightgray;
        border-radius: 0px;
        margin-left: -0.4rem;
    }
`

const Quadro = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 8px;

    width: 342px;
    height: 0 auto;
    min-height: 218px;
    background: #f1f3f5;
    border: 1px solid #dee2e6;

    box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 1rem 0;
`
const Aviso = styled.p`
    height: auto;
    margin-top: 4rem;
    font-size: 1rem;
    border-radius: 1rem;
`

const TrMobile = styled.tr`
    border: 0 !important;
    z-index: 10;
`
const ThMobile = styled.th`
    position: relative;
    width: auto !important;
    height: auto;
    color: #002a5d;
`

const TdMobile = styled.td`
    padding: 0.5rem 0rem !important;
    color: ${props => props.theme.colors.text};
    padding-left: 5px !important;
    color: #002a5d;
    .form-select {
        background-color: #f1f3f5;
    }
    .button.dropdown-item {
        background: #f1f3f5;
    }
`

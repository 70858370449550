import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { createContext, useContext, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'

type AlertProps = {
    type?: 'error' | 'success' | 'warning' | 'info'
    time?: number
    subTitle?: string
    title?: string
}

type AlertContextData = {
    setAlert(b: AlertProps): void
    showAlert(b: boolean): void
}

const AlertContext = createContext<AlertContextData>({} as AlertContextData)

export const AlertProvider: React.FC = ({ children }) => {
    const [alert, setAlert] = useState<AlertProps>({})
    const [view, showAlert] = useState<boolean>(false)

    const startTime = _.debounce(() => {
        showAlert(false)
    }, 5000)

    const renderAlert = () => {
        let color = ''
        let icon: IconName = 'check-circle'
        const AlertBox = styled.div(props => {
            switch (alert.type) {
                case 'success':
                    color = props.theme.colors.green
                    icon = 'check-circle'
                    break
                case 'warning':
                    color = props.theme.colors.warnning
                    icon = 'exclamation-circle'
                    break
                case 'error':
                    color = props.theme.colors.danger
                    icon = 'times-circle'
                    break
                case 'info':
                    color = props.theme.colors.lightBlue
                    icon = 'question-circle'
                    break
                default:
                    color = props.theme.colors.lightBlue
                    icon = 'question-circle'
                    break
            }

            return {
                background: '#FFF',
                width: '300px',
                display: 'flex',
                height: '60px',
                borderRadius: '5px',
                boxShadow: '0 5px 9px 2px #0f1e2729',
                position: 'fixed',
                right: '15px',
                bottom: '3rem',
                zIndex: 2147483647,
                borderLeft: `solid 6px ${color}`,
                color: `${color} !important`
            }
        })
        if (view) {
            startTime() // iniciar contagem para remover o alert
        }

        return view ? (
            <AlertBox
                className={
                    view ? 'slide-in-elliptic-right-fwd' : 'slide-out-right'
                }
            >
                <InnerBoxAlert>
                    <FontAwesomeIcon icon={['far', icon]} size="3x" />

                    <Info>
                        <Title>{alert.title}</Title>
                        <Description>{alert.subTitle}</Description>
                    </Info>
                </InnerBoxAlert>
            </AlertBox>
        ) : null
    }

    return (
        <AlertContext.Provider value={{ setAlert, showAlert }}>
            {renderAlert()}
            {children}
        </AlertContext.Provider>
    )
}

export function useAlert(): AlertContextData {
    const context = useContext(AlertContext)

    if (!context) {
        throw new Error('useSpinner must be used within an SpinnerProvider')
    }

    return context
}

const Description = styled.p`
    margin: 0;
    font-size: 0.65rem;
    color: ${props => props.theme.colors.text} !important;
`

const Title = styled.p`
    padding: 0;
    margin: 0;
    font-size: 1.1rem;
    font-weight: 700;
`

const Info = styled.div`
    padding-left: 10px;
`

const InnerBoxAlert = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 0.6rem;
`

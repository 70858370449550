import React from 'react'
import TimeRangeSlider from 'react-time-range-slider'
import styled from 'styled-components'

export const TimeSlider = ({ timer, setTimer }) => {
    const timeChangeHandler = time => {
        setTimer(time)
    }

    const setDefaultTimes = type => {
        switch (type) {
            case 'madrugada':
                setTimer({ start: '00:00', end: '06:00' })
                break
            case 'manhã':
                setTimer({ start: '06:00', end: '12:00' })
                break
            case 'tarde':
                setTimer({ start: '12:00', end: '18:00' })
                break
            case 'noite':
                setTimer({ start: '18:00', end: '23:59' })
                break
            default:
                setTimer({ start: '00:00', end: '00:00' })
        }
    }

    return (
        <>
            <Info>
                <span className="fw-bolder">{timer.start}</span>
                <span className="fw-bolder">{timer.end}</span>
            </Info>
            <TimeRangeSlider
                disabled={false}
                format={24}
                maxValue={'23:59'}
                minValue={'00:00'}
                name={'time_range'}
                onChange={timeChangeHandler}
                step={10}
                value={timer}
            />
            <Info>
                <span
                    onClick={() => setDefaultTimes('madrugada')}
                    className="cursor-pointer"
                >
                    Madrugada
                </span>
                |{' '}
                <span
                    onClick={() => setDefaultTimes('manhã')}
                    className="cursor-pointer"
                >
                    Manhã
                </span>{' '}
                |{' '}
                <span
                    onClick={() => setDefaultTimes('tarde')}
                    className="cursor-pointer"
                >
                    Tarde
                </span>
                |{' '}
                <span
                    onClick={() => setDefaultTimes('noite')}
                    className="cursor-pointer"
                >
                    Noite
                </span>
            </Info>
        </>
    )
}

const Info = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    color: ${props => props.theme.colors.primary};
`

export default {
    title: 'light',

    colors: {
        primary: '#002a5d',
        secondary: '#5787D0',
        white: '#FFFFFF',
        metaText: '#a9a9a9',
        darkGray: '#ECECEC',
        lightGray: '#fefefe',
        background: '#EBEBF1',
        text: '#6C7480',
        danger: '#F66D65',
        line: '#c5c5c5',
        darkBlue: '#4f81cc',
        darkCyan: '#66a8d8',
        lightCyan: '#8ED6FF',
        placeholder: '#dbdee3',
        lightBlue: '#73A4EE',
        success: '#8ADFAC',
        warnning: '#F5DB7E',
        deepBlue: '#002A5D',
        border: '#d3d3d3',

        selectBlue: '#DBEBFF',

        green: '#81c784'
    },

    font: {}
}

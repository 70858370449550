import React, { useContext, useEffect, useState } from 'react'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Button } from '../../../../core/components/Button'
import { ENDPOINTS } from '../../../../services/config-http'
import { Header } from '../../../../core/components/Header/Header'
import { UrlMountQuery } from '../../../../core/utils/UrlMountQuery'
import { get } from '../../../../services/http'
import { useHistory, useParams } from 'react-router'
import { Space } from '../../../../core/components/Space'
import { Center } from '../../../../core/components/Center'
import styled from 'styled-components'
import { LineSeparator } from '../../../../core/components/LineSeparator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import GolImg from '../../../../core/assets/Gol.jpg'
import AzulImg from '../../../../core/assets/Azul.png'
import LatamImg from '../../../../core/assets/Latam.png'
import { UserContext } from '../../../../contexts/UserContext'
import { DisableForMobile, IsMobile } from '../stepMobile'
import {
    Box,
    Info,
    Quadro,
    Separador,
    TrMobile,
    getCampo,
    getNotFound,
    Lista,
    getReservaMobile
} from '../../../../core/components/Mobile'
import { MoneyFormat } from '../../../../core/utils/MoneyFormat'

export const Editar = () => {
    const history = useHistory()
    const [solicitacao, setSolicitacao] = useState<any>()
    const [motivoViagem, setMotivoViagem] = useState<any>()
    const [regraPoliticaViagem, setRegraPoliticaViagem] = useState<Array<any>>(
        []
    )
    const [filiais, setFilial] = useState<Array<any>>([])
    const [centroDeCusto, setCentroDeCusto] = useState<Array<any>>([])
    const { Id } = useParams<any>()
    const [adiantamentoPrincipal, setAdiantamentoPrincipal] = useState<any>()
    const [valorTotal, setValorTotal] = useState<any>()
    const [viajantes, setViajantes] = useState<Array<any>>([])
    const [abaAtual, setAbaAtual] = useState('Solicitação')

    const [showRoteiros, setShowRoteiros] = useState(false)
    const [showAdiantamentos, setShowAdiantamentos] = useState(false)
    const [showAereos, setShowAereos] = useState(false)
    const [showHospedagens, setShowHospedagens] = useState(false)
    const [showServicos, setShowServicos] = useState(false)
    const dadosUsuario = useContext(UserContext)

    useEffect(() => {
        if (Id) {
            get(`${ENDPOINTS.solicitacaoViagemPorId}/${Id}`).then(
                async ({ data: { Model } }) => {
                    const solicitacao = Model
                    console.log(solicitacao)
                    setSolicitacao(solicitacao)
                    setViajantes(Model?.Viajantes)
                    get(
                        UrlMountQuery(
                            {
                                Id: Model?.IdMotivoViagem
                            },
                            ENDPOINTS.motivoViagemById
                        )
                    ).then(async ({ data: { Model } }) => {
                        const motivoViagem = Model
                        setMotivoViagem(motivoViagem)
                    })
                    Model?.Viajantes?.map((e: any, key: number) => {
                        if (Model.Viajantes[key].Convidado === false) {
                            const IdAtual = Model.Viajantes[key].Id
                            Model?.Adiantamentos?.map((e: any, key: number) => {
                                if (
                                    Model.Adiantamentos[key].IdViajante ===
                                    IdAtual
                                ) {
                                    setAdiantamentoPrincipal(
                                        Model.Adiantamentos[key]
                                    )
                                }
                            })
                            let somaAdiantamentos = 0
                            Model?.Adiantamentos?.map((e: any, key: number) => {
                                somaAdiantamentos +=
                                    Model?.Adiantamentos[key]?.Valor
                            })
                            setValorTotal(somaAdiantamentos)
                        }
                    })
                }
            )

            get(ENDPOINTS.filial).then(res => {
                setFilial(res?.data?.Model)
            })
            get(`${ENDPOINTS.regrasPoliticaViagem}/TiposSolicitacao/${1}`).then(
                ({ data: { Model } }) => {
                    setRegraPoliticaViagem(Model)
                }
            )
            get(
                UrlMountQuery(
                    { TotalRegisterPage: 500 },
                    ENDPOINTS.pesquisarContasContabeis
                )
            ).then(({ data: { Items } }) => {
                setCentroDeCusto(Items)
            })
        }
    }, [])

    useEffect(() => {
        if (solicitacao?.TipoSolicitacao) {
            buscaRegra(
                solicitacao.TipoSolicitacao === 'Viagem Completa' ? 1 : 2
            )
        }
    }, [solicitacao])

    const buscaRegra = async (id: number) => {
        if (id !== 0) {
            await get(`${ENDPOINTS.regrasPoliticaViagem}/${id}`)
                .then(({ data: { Model } }) => {
                    const {
                        UtilizarRoteiro,
                        UtilizarAdiantamentos,
                        UtilizarAereo,
                        UtilizarHospedagem,
                        UtilizarServicos
                    } = Model
                    setShowRoteiros(UtilizarRoteiro)
                    setShowAdiantamentos(UtilizarAdiantamentos)
                    setShowAereos(UtilizarAereo)
                    setShowHospedagens(UtilizarHospedagem)
                    setShowServicos(UtilizarServicos)
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    function _ShowCurrency(value: number) {
        const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        })
        return <>{formatter.format(value)}</>
    }

    function _ShowFilial(value: number) {
        const item = filiais?.filter(item => item?.Id === value)[0]
        return <>{item?.Nome}</>
    }

    function _ShowCentroDeCusto(value: number) {
        const item = centroDeCusto?.filter(item => item?.Id === value)[0]
        return <>{item?.Nome}</>
    }

    function _ShowRegrasPoliticaViagem(value: number) {
        const item = regraPoliticaViagem?.filter(item => item?.Id === value)[0]
        return <>{item?.Nome}</>
    }

    function _ShowViajanteName(value: number) {
        const item: any = viajantes?.filter(item => item?.Id === value)[0]
        return item?.Nome
    }

    function _ShowViajanteCode(value: number) {
        const item: any = viajantes?.filter(item => item?.Id === value)[0]
        return item?.Codigo
    }

    function _pipeCompanhiaAerea(value: string) {
        switch (value) {
            case 'G3':
                return (
                    <ContainerLogo>
                        <img src={GolImg} alt="Gol"></img>
                    </ContainerLogo>
                )
            case 'AD':
                return (
                    <ContainerLogo>
                        <img src={AzulImg} alt="Azul"></img>
                    </ContainerLogo>
                )
            case 'JJ':
                return (
                    <ContainerLogo>
                        <img src={LatamImg} alt="Latam"></img>
                    </ContainerLogo>
                )

            default:
                break
        }
    }

    function _getUserAdiantamento(value: number) {
        const r = viajantes?.filter((item: any) => item.IdUsuario === value)[0]

        if (r !== undefined) {
            const name = _ShowViajanteName(r.Id)
            const cod = _ShowViajanteCode(r.Id)

            return (
                <p>
                    {cod} {' - '} {name}
                </p>
            )
        }
    }
    function _getUserAdiantamentoMobile(value: number) {
        const r = viajantes?.filter((item: any) => item.IdUsuario === value)[0]

        if (r !== undefined) {
            const name = _ShowViajanteName(r.Id)
            const cod = _ShowViajanteCode(r.Id)
            return cod + '-' + name
        }
    }

    function notFound(param: string) {
        return (
            <div
                style={{
                    height: 'auto',
                    margin: '5vh 7vh',
                    borderRadius: '1vh',
                    padding: '3.5vh',
                    boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.05)'
                }}
            >
                <Text>{param}</Text>
            </div>
        )
    }

    function styleTextLine(title: string, param: any) {
        return (
            <p
                style={{
                    marginBottom: '20px'
                }}
            >
                <b
                    style={{
                        marginRight: '5px'
                    }}
                >
                    {title}:
                </b>
                {param}
            </p>
        )
    }

    const buttonAereo = (IsMobile: boolean) => {
        return (
            <Center>
                <Button
                    icon={solicitacao?.Aereos.length === 0 ? 'plus' : 'edit'}
                    colorType={IsMobile ? 'btnMobile' : 'quaternary'}
                    onClick={() =>
                        history.push(
                            `/solicitacoes/editar/solicitacao/${Id}/aereo`
                        )
                    }
                >
                    {solicitacao?.Aereos.length === 0
                        ? 'Adicionar Aéreos'
                        : 'Alterar Aéreos'}
                </Button>
            </Center>
        )
    }

    const buttonViajantes = (IsMobile: boolean) => {
        return (
            <Center>
                <Button
                    icon={solicitacao?.Viajantes.length === 0 ? 'plus' : 'edit'}
                    colorType={IsMobile ? 'btnMobile' : 'quaternary'}
                    onClick={() =>
                        history.push(
                            `/solicitacoes/editar/solicitacao/${Id}/viajantes`
                        )
                    }
                >
                    {solicitacao?.Viajantes.length === 0
                        ? 'Adicionar Viajantes'
                        : 'Alterar Viajantes'}
                </Button>
            </Center>
        )
    }

    const buttonAdiantamento = (IsMobile: boolean) => {
        return (
            <Center>
                <Button
                    style={{
                        position: 'absolute'
                    }}
                    icon={solicitacao?.Adiantamentos == null ? 'plus' : 'edit'}
                    colorType={IsMobile ? 'btnMobile' : 'quaternary'}
                    onClick={() =>
                        history.push(
                            `/solicitacoes/editar/solicitacao/${Id}/adiantamento`
                        )
                    }
                >
                    {solicitacao?.Adiantamentos == null
                        ? 'Adicionar Adiantamento'
                        : 'Alterar Adiantamento'}
                </Button>
            </Center>
        )
    }

    const buttonHospedagem = (IsMobile: boolean) => {
        return (
            <Center>
                <Button
                    icon={
                        solicitacao?.Hospedagem.length === 0 ? 'plus' : 'edit'
                    }
                    colorType={IsMobile ? 'btnMobile' : 'quaternary'}
                    onClick={() =>
                        history.push(
                            `/solicitacoes/editar/solicitacao/${Id}/hospedagem`
                        )
                    }
                >
                    {solicitacao?.Hospedagem.length === 0
                        ? 'Adicionar Hospedagem'
                        : 'Alterar Hospedagem'}
                </Button>
            </Center>
        )
    }

    const buttonServico = (IsMobile: boolean) => {
        return (
            <Center>
                <Button
                    icon={solicitacao?.Servico?.length ? 'plus' : 'edit'}
                    colorType={IsMobile ? 'btnMobile' : 'quaternary'}
                    onClick={() =>
                        history.push(
                            `/solicitacoes/editar/solicitacao/${Id}/serviços`
                        )
                    }
                >
                    {solicitacao?.Servico?.length
                        ? 'Adicionar Serviços'
                        : 'Alterar Serviços'}
                </Button>
            </Center>
        )
    }

    const buttonRateio = (IsMobile: boolean) => {
        return (
            <Center>
                <Button
                    icon={solicitacao?.Rateio.length === 0 ? 'plus' : 'edit'}
                    colorType={IsMobile ? 'btnMobile' : 'quaternary'}
                    onClick={() =>
                        history.push(
                            `/solicitacoes/editar/solicitacao/${Id}/rateio`
                        )
                    }
                >
                    {solicitacao?.Rateio.length === 0
                        ? 'Adicionar Rateio'
                        : 'Alterar Rateio'}
                </Button>
            </Center>
        )
    }

    return (
        <BlankBoard>
            <Header
                title="Editar Solicitação"
                actionArrowClick={() => {
                    history.push('/solicitacoes')
                }}
            ></Header>
            <Space height="2rem"></Space>
            <Lista className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" style={navItem} role="presentation">
                    <button
                        className="nav-link active"
                        id="info-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#info"
                        type="button"
                        role="tab"
                        aria-controls="info"
                        aria-selected="true"
                        onClick={e => setAbaAtual('Solicitação')}
                    >
                        <FontAwesomeIcon icon="bars" />
                    </button>
                </li>
                <li className="nav-item" style={navItem} role="presentation">
                    <button
                        className="nav-link"
                        id="viajantes-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#viajantes"
                        type="button"
                        role="tab"
                        aria-controls="viajantes"
                        aria-selected="true"
                        onClick={e => setAbaAtual('Viajantes')}
                    >
                        <FontAwesomeIcon icon="users" />
                    </button>
                </li>
                <li className="nav-item" style={navItem} role="presentation">
                    <button
                        className="nav-link"
                        id="roteiros-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#roteiros"
                        type="button"
                        role="tab"
                        aria-controls="roteiros"
                        aria-selected="true"
                        onClick={e => setAbaAtual('Roteiros')}
                    >
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </button>
                </li>
                <li className="nav-item" style={navItem} role="presentation">
                    <button
                        className="nav-link"
                        id="aereo-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#aereo"
                        type="button"
                        role="tab"
                        aria-controls="aereo"
                        aria-selected="true"
                        onClick={e => setAbaAtual('Aéreos')}
                    >
                        <FontAwesomeIcon icon="plane" />
                    </button>
                </li>
                <li className="nav-item" style={navItem} role="presentation">
                    <button
                        className="nav-link"
                        id="hospedagem-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#hospedagem"
                        type="button"
                        role="tab"
                        aria-controls="hospedagem"
                        aria-selected="true"
                        onClick={e => setAbaAtual('Hospedagens')}
                    >
                        <FontAwesomeIcon icon="hotel" />
                    </button>
                </li>
                <li className="nav-item" style={navItem} role="presentation">
                    <button
                        className="nav-link"
                        id="servicos-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#servicos"
                        type="button"
                        role="tab"
                        aria-controls="servicos"
                        aria-selected="true"
                        onClick={e => setAbaAtual('Serviços')}
                    >
                        <FontAwesomeIcon icon="list-ul" />
                    </button>
                </li>
                <li className="nav-item" style={navItem} role="presentation">
                    <button
                        className="nav-link"
                        id="adiantamento-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#adiantamento"
                        type="button"
                        role="tab"
                        aria-controls="adiantamento"
                        aria-selected="true"
                        onClick={e => setAbaAtual('Adiantamentos')}
                    >
                        <FontAwesomeIcon icon="dollar-sign" />
                    </button>
                </li>
                <li className="nav-item" style={navItem} role="presentation">
                    <button
                        className="nav-link"
                        id="rateio-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#rateio"
                        type="button"
                        role="tab"
                        aria-controls="rateio"
                        aria-selected="true"
                        onClick={e => setAbaAtual('Rateios')}
                    >
                        <FontAwesomeIcon icon="random" />
                    </button>
                </li>
                <div
                    style={{
                        fontSize: '1.2rem',
                        display: 'flex',
                        width: '62%',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        color: '#5F8DD3'
                    }}
                >
                    {abaAtual}
                </div>
            </Lista>
            <article className="tab-content" id="myTabContent">
                <Infos
                    id="info"
                    className="tab-pane fade show active"
                    role="tabpanel"
                    aria-labelledby="info-tab"
                >
                    <DisableForMobile>
                        <Text>
                            {styleTextLine(
                                'Tipo de Solicitação',
                                _ShowRegrasPoliticaViagem(
                                    solicitacao?.IdTipoSolicitacao
                                )
                            )}
                            {styleTextLine(
                                'Motivo da Viagem',
                                motivoViagem?.Descricao
                            )}
                            {styleTextLine(
                                'Descrição',
                                solicitacao?.Descricao || 'Não preenchido'
                            )}
                            {styleTextLine(
                                'Viajante Principal',
                                solicitacao?.Viajantes[0]?.Nome
                            )}
                        </Text>
                        <Center>
                            <Button
                                style={{
                                    marginTop: '100px',
                                    position: 'absolute'
                                }}
                                icon="edit"
                                colorType="quaternary"
                                onClick={() =>
                                    history.push(
                                        `/solicitacoes/editar/solicitacao/${Id}/solicitacao`
                                    )
                                }
                            >
                                Alterar Solicitação
                            </Button>
                        </Center>
                    </DisableForMobile>
                    <IsMobile>
                        <Center>
                            <Box>
                                <Quadro>
                                    <table
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <TrMobile>
                                            {getCampo(
                                                1,
                                                'Tipo de Solicitação:',
                                                _ShowRegrasPoliticaViagem(
                                                    solicitacao?.IdTipoSolicitacao
                                                )
                                            )}
                                            {getCampo(
                                                1,
                                                'Motivo da Viagem: ',
                                                motivoViagem?.Descricao
                                            )}
                                            {getCampo(
                                                1,
                                                'Descrição:',
                                                solicitacao?.Descricao ||
                                                    'Não preenchido'
                                            )}
                                            {getCampo(
                                                1,
                                                'Viajante Principal:',
                                                `${solicitacao?.Viajantes[0]?.Nome}`
                                            )}
                                        </TrMobile>
                                    </table>
                                </Quadro>
                            </Box>
                        </Center>
                        <Center>
                            <Button
                                icon="edit"
                                colorType="btnMobile"
                                onClick={() =>
                                    history.push(
                                        `/solicitacoes/editar/solicitacao/${Id}/solicitacao`
                                    )
                                }
                            >
                                Alterar Solicitação
                            </Button>
                        </Center>
                    </IsMobile>
                </Infos>
                <div
                    id="roteiros"
                    className="tab-pane fade show"
                    role="tabpanel"
                    aria-labelledby="roteiros-tab"
                >
                    <DisableForMobile>
                        {solicitacao?.Destinos?.length ? (
                            solicitacao?.Destinos?.map(
                                (e: any, key: number) => {
                                    return (
                                        <Infos key={key}>
                                            <Text>
                                                {styleTextLine('#', key + 1)}
                                                {solicitacao?.Destinos[key]
                                                    ?.Objetivo
                                                    ? styleTextLine(
                                                          'Objetivo',
                                                          solicitacao?.Destinos[
                                                              key
                                                          ]?.Objetivo
                                                      )
                                                    : null}
                                                {styleTextLine(
                                                    'Destino',
                                                    solicitacao?.Destinos[key]
                                                        ?.Destino
                                                )}
                                                {styleTextLine(
                                                    'Data de Ida',
                                                    new Date(
                                                        solicitacao.Destinos[
                                                            key
                                                        ]?.DataInicio
                                                    ).toLocaleDateString()
                                                )}
                                                {styleTextLine(
                                                    'Data de Volta',
                                                    new Date(
                                                        solicitacao.Destinos[
                                                            key
                                                        ]?.DataTermino
                                                    ).toLocaleDateString()
                                                )}
                                            </Text>
                                        </Infos>
                                    )
                                }
                            )
                        ) : (
                            <>{notFound('Não possui Roteiro cadastrados')}</>
                        )}

                        <Center>
                            <Button
                                icon={
                                    solicitacao?.Destinos.length === 0
                                        ? 'plus'
                                        : 'edit'
                                }
                                colorType="quaternary"
                                onClick={() =>
                                    history.push(
                                        `/solicitacoes/editar/solicitacao/${Id}/roteiros`
                                    )
                                }
                            >
                                {solicitacao?.Destinos.length === 0
                                    ? 'Adicionar Roteiro'
                                    : 'Alterar Roteiro'}
                            </Button>
                        </Center>
                    </DisableForMobile>
                    <IsMobile>
                        {solicitacao?.Destinos?.length > 0 ? (
                            solicitacao?.Destinos?.map(
                                (destino: any, key: number) => {
                                    return (
                                        <Center key={key}>
                                            <Box>
                                                <Quadro>
                                                    <table
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <TrMobile>
                                                            {getCampo(
                                                                1,
                                                                'Destino:',
                                                                destino.Destino
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Início:',
                                                                new Date(
                                                                    destino.DataInicio
                                                                ).toLocaleDateString()
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Término:',
                                                                new Date(
                                                                    destino.DataTermino
                                                                ).toLocaleDateString()
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Objetivo:',
                                                                destino.Objetivo
                                                            )}
                                                        </TrMobile>
                                                    </table>
                                                </Quadro>
                                            </Box>
                                        </Center>
                                    )
                                }
                            )
                        ) : (
                            <Center>
                                {getNotFound('Não possui Roteiro cadastrados')}
                            </Center>
                        )}

                        <Center>
                            <Button
                                icon={
                                    solicitacao?.Destinos.length === 0
                                        ? 'plus'
                                        : 'edit'
                                }
                                colorType="btnMobile"
                                onClick={() =>
                                    history.push(
                                        `/solicitacoes/editar/solicitacao/${Id}/roteiros`
                                    )
                                }
                            >
                                {solicitacao?.Destinos.length === 0
                                    ? 'Adicionar Roteiro'
                                    : 'Alterar Roteiro'}
                            </Button>
                        </Center>
                    </IsMobile>
                </div>
                <div
                    id="viajantes"
                    className="tab-pane fade show"
                    role="tabpanel"
                    aria-labelledby="viajantes-tab"
                >
                    <DisableForMobile>
                        {solicitacao?.Viajantes?.length ? (
                            solicitacao?.Viajantes?.map(
                                (e: any, key: number) => {
                                    return (
                                        <Infos key={key}>
                                            <Text>
                                                {styleTextLine('#', key + 1)}
                                                {styleTextLine('Nome', e.Nome)}
                                                {styleTextLine(
                                                    'Código',
                                                    e.Codigo
                                                )}
                                            </Text>
                                        </Infos>
                                    )
                                }
                            )
                        ) : (
                            <>{notFound('Não possui Viajantes cadastrados')}</>
                        )}
                        <Space height="2rem"></Space>

                        {buttonViajantes(false)}
                    </DisableForMobile>
                    <IsMobile>
                        {solicitacao?.Viajantes?.length ? (
                            solicitacao?.Viajantes?.map(
                                (viajante: any, key: number) => {
                                    return (
                                        <Center key={key}>
                                            <Box>
                                                <Quadro>
                                                    <table
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <TrMobile>
                                                            {getCampo(
                                                                1,
                                                                'Codigo:',
                                                                viajante.Codigo
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Nome:',
                                                                viajante.Nome
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Tipo de viajante:',
                                                                viajante.Convidado
                                                                    ? 'Convidado'
                                                                    : 'Principal'
                                                            )}
                                                        </TrMobile>
                                                    </table>
                                                </Quadro>
                                            </Box>
                                        </Center>
                                    )
                                }
                            )
                        ) : (
                            <Center>
                                {getNotFound(
                                    'Não possui Viajantes cadastrados'
                                )}
                            </Center>
                        )}
                        {buttonViajantes(true)}
                    </IsMobile>
                </div>
                <div
                    id="adiantamento"
                    className="tab-pane fade show"
                    role="tabpanel"
                    aria-labelledby="adiantamento-tab"
                >
                    <DisableForMobile>
                        <Infos>
                            {solicitacao?.Adiantamentos?.length ? (
                                <>
                                    <div className="row">
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                className="col-12 col-sm-6"
                                                style={{ textAlign: 'start' }}
                                            >
                                                <b>
                                                    <Text>Motivo</Text>
                                                </b>
                                            </div>
                                            <div
                                                className="col-12 col-sm-6"
                                                style={{ textAlign: 'end' }}
                                            >
                                                <b>
                                                    <Text>Valor Total</Text>
                                                </b>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                className="col-12 col-sm-6"
                                                style={{ textAlign: 'start' }}
                                            >
                                                <Text>
                                                    {
                                                        adiantamentoPrincipal?.Descricao
                                                    }
                                                </Text>
                                            </div>
                                            <div
                                                className="col-12 col-sm-6"
                                                style={{ textAlign: 'end' }}
                                            >
                                                <Text>
                                                    {valorTotal &&
                                                        _ShowCurrency(
                                                            valorTotal
                                                        )}
                                                </Text>
                                            </div>
                                        </div>
                                        <LineSeparator
                                            style={{ margin: '1rem 0' }}
                                        ></LineSeparator>
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                className="col-12 col-sm-6"
                                                style={{ textAlign: 'start' }}
                                            >
                                                <b>
                                                    <Text>Viajantes</Text>
                                                </b>
                                            </div>
                                            <div
                                                className="col-12 col-sm-6"
                                                style={{ textAlign: 'end' }}
                                            >
                                                <b>
                                                    <Text>
                                                        Valor Individual
                                                    </Text>
                                                </b>
                                            </div>
                                        </div>
                                        {solicitacao?.Adiantamentos?.map(
                                            (e: any, key: number) => {
                                                return (
                                                    <div key={key}>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-8">
                                                                {_getUserAdiantamento(
                                                                    e.IdUsuario
                                                                )}
                                                            </div>
                                                            <div className="col-12 col-sm-4">
                                                                <p>
                                                                    <div
                                                                        style={{
                                                                            textAlign:
                                                                                'end'
                                                                        }}
                                                                    >
                                                                        {_ShowCurrency(
                                                                            e.Valor
                                                                        )}
                                                                    </div>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    {notFound(
                                        'Não possui adiantamento cadastrado'
                                    )}
                                </>
                            )}

                            <Space height="2rem"></Space>
                        </Infos>
                        {buttonAdiantamento(false)}
                    </DisableForMobile>

                    <IsMobile>
                        <Center>
                            {solicitacao?.Adiantamentos?.length ? (
                                <Box>
                                    <Quadro>
                                        <table
                                            style={{
                                                width: '100%'
                                            }}
                                        >
                                            <TrMobile>
                                                {getCampo(
                                                    1,
                                                    'Motivo:',
                                                    solicitacao
                                                        ?.Adiantamentos[0]
                                                        ?.Descricao || ''
                                                )}
                                                {getCampo(
                                                    1,
                                                    'Valor total:',
                                                    valorTotal &&
                                                        _ShowCurrency(
                                                            valorTotal
                                                        )
                                                )}

                                                <Center>
                                                    <Info>Divisão</Info>
                                                </Center>

                                                {solicitacao?.Adiantamentos?.map(
                                                    (
                                                        adiantamento: any,
                                                        index: any
                                                    ) => {
                                                        if (
                                                            adiantamento.IdUsuario !==
                                                            undefined
                                                        ) {
                                                            return (
                                                                <TrMobile
                                                                    key={index}
                                                                >
                                                                    <Separador>
                                                                        {getCampo(
                                                                            index,
                                                                            'Viajante',
                                                                            ``
                                                                        )}
                                                                        {getCampo(
                                                                            index,
                                                                            'Valor',
                                                                            ''
                                                                        )}
                                                                    </Separador>
                                                                    <Separador>
                                                                        {getCampo(
                                                                            index,
                                                                            '',
                                                                            `${_getUserAdiantamentoMobile(
                                                                                adiantamento.IdUsuario
                                                                            )}`
                                                                        )}
                                                                        {getCampo(
                                                                            index,
                                                                            '',
                                                                            MoneyFormat(
                                                                                adiantamento.Valor
                                                                            )
                                                                        )}
                                                                    </Separador>
                                                                </TrMobile>
                                                            )
                                                        }
                                                    }
                                                )}
                                            </TrMobile>
                                        </table>
                                    </Quadro>
                                </Box>
                            ) : (
                                <Center>
                                    {getNotFound(
                                        'Não possui adiantamento cadastrado'
                                    )}
                                </Center>
                            )}
                        </Center>
                        {buttonAdiantamento(true)}
                    </IsMobile>
                </div>
                <div
                    id="aereo"
                    className="tab-pane fade show"
                    role="tabpanel"
                    aria-labelledby="aereo-tab"
                >
                    <Space height="2rem"></Space>
                    <Center>
                        {/* <div
                            className="alert alert-warning d-flex align-items-center mb-3 mt-2"
                            role="alert"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                viewBox="0 0 16 16"
                                role="img"
                                aria-label="Warning:"
                            >
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                            <div>
                                Caso seja necessário alterar um Aéreo, por favor
                                cancele esta Solicitação e crie uma nova.
                            </div>
                        </div> */}
                    </Center>
                    <DisableForMobile>
                        {solicitacao?.Aereos?.length ? (
                            solicitacao?.Aereos?.map((e: any, key: number) => (
                                <Infos key={key}>
                                    <p>
                                        <b>#{key + 1}</b>
                                    </p>
                                    <p
                                        style={{
                                            overflow: 'auto'
                                        }}
                                    >
                                        <b>Identificador da Viagem:</b>{' '}
                                        <LocalizadorText>
                                            {e.Localizador}
                                        </LocalizadorText>
                                    </p>
                                    <p>
                                        <b>Trajeto:</b> {e.AeroportoOrigem}{' '}
                                        &rarr; {e.AeroportoDestino}
                                    </p>
                                    <p>
                                        <b>Saída: </b>{' '}
                                        {new Date(
                                            e.DataIdaSaida
                                        ).toLocaleDateString('pt-br', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric'
                                        })}
                                        {' | '}
                                        {new Date(
                                            e.DataIdaSaida
                                        ).toLocaleTimeString('pt-br', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric'
                                        })}
                                    </p>
                                    <p>
                                        <b>Bagagem:</b>{' '}
                                        {e.Bagagem ? 'Sim' : 'Não'}
                                    </p>
                                    <p>
                                        <b>Valor: </b>
                                        {_ShowCurrency(e?.Valor)}
                                    </p>
                                    <p>
                                        <b>Compainha aérea: </b>
                                    </p>
                                    {_pipeCompanhiaAerea(e.Companhia)}
                                </Infos>
                            ))
                        ) : (
                            <>{notFound('Não possui Aéreo cadastrados')}</>
                        )}
                        {buttonAereo(false)}
                    </DisableForMobile>
                    <IsMobile>
                        {solicitacao?.Aereos?.length ? (
                            getReservaMobile(solicitacao?.Aereos)
                        ) : (
                            <Center>
                                {getNotFound('Não possui Aéreo cadastrados')}
                            </Center>
                        )}
                        {buttonAereo(true)}
                    </IsMobile>
                </div>
                <div
                    id="hospedagem"
                    className="tab-pane fade show"
                    role="tabpanel"
                    aria-labelledby="hospedagem-tab"
                >
                    <DisableForMobile>
                        {solicitacao?.Hospedagem?.length ? (
                            solicitacao?.Hospedagem?.map(
                                (e: any, key: number) => (
                                    <Infos key={key}>
                                        {styleTextLine('#', key + 1)}
                                        {styleTextLine(
                                            'Cidade',
                                            solicitacao?.Hospedagem[key]?.Cidade
                                        )}
                                        {styleTextLine(
                                            'Check-in',
                                            solicitacao?.Hospedagem[key]
                                                ?.DataEntrada
                                                ? new Date(
                                                      solicitacao?.Hospedagem[
                                                          key
                                                      ]?.DataEntrada
                                                  ).toLocaleDateString()
                                                : null
                                        )}
                                        {styleTextLine(
                                            'Check-out',
                                            solicitacao?.Hospedagem[key]
                                                ?.DataSaida
                                                ? new Date(
                                                      solicitacao?.Hospedagem[
                                                          key
                                                      ]?.DataSaida
                                                  ).toLocaleDateString()
                                                : null
                                        )}
                                        {styleTextLine(
                                            'Observação',
                                            solicitacao?.Hospedagem[key]
                                                ?.Observacao
                                        )}
                                        {styleTextLine(
                                            'Ponto de referência',
                                            solicitacao?.Hospedagem[key]
                                                ?.Referencia
                                        )}
                                    </Infos>
                                )
                            )
                        ) : (
                            <>
                                {notFound('Não possui Hospedagens cadastradas')}
                            </>
                        )}

                        <Space height="2rem"></Space>
                        {buttonHospedagem(false)}
                    </DisableForMobile>
                    <IsMobile>
                        {solicitacao?.Hospedagem?.length ? (
                            solicitacao?.Hospedagem?.map(
                                (hospedagem: any, key: number) => {
                                    return (
                                        <Center key={key}>
                                            <Box>
                                                <Quadro>
                                                    <table
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <TrMobile>
                                                            {getCampo(
                                                                1,
                                                                'Cidade:',
                                                                hospedagem.Cidade
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Ponto de referência:',
                                                                hospedagem.Referencia
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Check-in:',
                                                                new Date(
                                                                    hospedagem.DataEntrada
                                                                ).toLocaleDateString()
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Check-out:',
                                                                new Date(
                                                                    hospedagem.DataSaida
                                                                ).toLocaleDateString()
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Observação:',
                                                                hospedagem.Observacao
                                                            )}
                                                        </TrMobile>
                                                    </table>
                                                </Quadro>
                                            </Box>
                                        </Center>
                                    )
                                }
                            )
                        ) : (
                            <Center>
                                {getNotFound(
                                    'Não possui Hospedagens cadastradas'
                                )}
                            </Center>
                        )}
                        {buttonHospedagem(true)}
                    </IsMobile>
                </div>
                <div
                    id="servicos"
                    className="tab-pane fade show"
                    role="tabpanel"
                    aria-labelledby="servicos-tab"
                >
                    <DisableForMobile>
                        {solicitacao?.Servico?.length ? (
                            solicitacao?.Servico?.map((e: any, key: number) => (
                                <Infos key={key}>
                                    {styleTextLine('#', key + 1)}
                                    {styleTextLine(
                                        'Tipo de Despesa',
                                        e.DescricaoServico
                                    )}
                                    {styleTextLine('Observação', e.Observacao)}
                                </Infos>
                            ))
                        ) : (
                            <>{notFound('Não possui Serviços cadastrados')}</>
                        )}

                        <Space height="2rem"></Space>
                        {buttonServico(false)}
                    </DisableForMobile>
                    <IsMobile>
                        {solicitacao?.Servico?.length ? (
                            solicitacao?.Servico?.map(
                                (servico: any, key: number) => {
                                    return (
                                        <Center key={key}>
                                            <Box>
                                                <Quadro>
                                                    <table
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <TrMobile>
                                                            {getCampo(
                                                                key,
                                                                'Tipo de Despesa:',
                                                                servico.DescricaoServico
                                                            )}
                                                            {getCampo(
                                                                key,
                                                                'Observação:',
                                                                servico.Observacao
                                                            )}
                                                        </TrMobile>
                                                    </table>
                                                </Quadro>
                                            </Box>
                                        </Center>
                                    )
                                }
                            )
                        ) : (
                            <Center>
                                {getNotFound('Não possui Serviços cadastrados')}
                            </Center>
                        )}
                        {buttonServico(true)}
                    </IsMobile>
                </div>
                <div
                    id="rateio"
                    className="tab-pane fade show"
                    role="tabpanel"
                    aria-labelledby="rateio-tab"
                >
                    <DisableForMobile>
                        {solicitacao?.Rateio?.length ? (
                            solicitacao?.Rateio?.map((e: any, key: number) => (
                                <Infos key={key}>
                                    {styleTextLine('#', key + 1)}
                                    {styleTextLine(
                                        'Filial',
                                        _ShowFilial(e.IdFilial)
                                    )}
                                    {styleTextLine(
                                        'Conta Contabíl',
                                        _ShowCentroDeCusto(e.IdContasContabeis)
                                    )}
                                    {styleTextLine(
                                        'Percentual',
                                        `${e.Percentual}%`
                                    )}
                                </Infos>
                            ))
                        ) : (
                            <>{notFound('Não possui Rateios cadastrados')}</>
                        )}

                        <Space height="2rem"></Space>
                        {buttonRateio(false)}
                    </DisableForMobile>
                    <IsMobile>
                        {solicitacao?.Rateio?.length ? (
                            solicitacao?.Rateio?.map(
                                (rateio: any, key: number) => {
                                    return (
                                        <Center key={key}>
                                            <Box>
                                                <Quadro>
                                                    <table
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <TrMobile>
                                                            {getCampo(
                                                                1,
                                                                'Filial:',
                                                                _ShowFilial(
                                                                    rateio.IdFilial
                                                                )
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Centro de custo:',
                                                                _ShowCentroDeCusto(
                                                                    rateio.IdContasContabeis
                                                                )
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Percentual (%):',
                                                                `${rateio.Percentual} %`
                                                            )}
                                                        </TrMobile>
                                                    </table>
                                                </Quadro>
                                            </Box>
                                        </Center>
                                    )
                                }
                            )
                        ) : (
                            <Center>
                                {getNotFound('Não possui Rateios cadastrados')}
                            </Center>
                        )}

                        {buttonRateio(true)}
                    </IsMobile>
                </div>
            </article>
        </BlankBoard>
    )
}

const navItem = {
    width: '0vh 4vh',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2.1vh'
}

const Infos = styled.div`
    @media (min-width: 800px) {
        height: auto;
        margin: 5vh 7vh;
        font-size: 1.5vh;
        border-radius: 1vh;
        padding: 3.5vh;
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.05);
    }
`

const ContainerLogo = styled.div`
    width: 200px;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: start;

    img {
        width: 100%;
    }
`

const LocalizadorText = styled.text`
    letter-spacing: 4px;
`
const Text = styled.p``

import React, { useRef, useEffect } from 'react'
import { useField } from '@unform/core'
import styled from 'styled-components'

interface Props {
    name?: string
    description?: string
    label?: string
    value?: string
}

type SwitchProps = JSX.IntrinsicElements['input'] & Props

export const Switch = ({
    name = '',
    value = '',
    description,
    label,
    required = false,
    ...tagProps
}: SwitchProps) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const { fieldName, defaultValue, registerField, error } = useField('name')

    const defaultChecked = defaultValue === value

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.checked
            },
            clearValue: ref => {
                ref.current.checked = defaultChecked
            },
            setValue: (ref, value) => {
                ref.current.checked = value
            }
        })
    }, [defaultValue, fieldName, registerField, defaultChecked])

    return (
        <>
            <label>
                <Text>
                    {label} {required ? '*' : ''}
                </Text>
            </label>
            <div className="form-check form-switch">
                <input
                    className="form-check-input cursor-pointer"
                    defaultChecked={defaultChecked}
                    ref={inputRef}
                    type="checkbox"
                    value={value}
                    id={fieldName}
                    {...tagProps}
                />
                <label className="form-check-label" htmlFor={name}>
                    {description}
                </label>
                {error && <span className="error-text">{error}</span>}
            </div>
        </>
    )
}

const Text = styled.p`
    font-size: 0.9rem;
    font-weight: 800;
`

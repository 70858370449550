import React from 'react'

interface GridProps {
    sizeElements?: string
    spaceBetweenElements?: string
    children?: React.ReactNode
    className?: string
}

export const Grid = ({
    sizeElements = '280px',
    spaceBetweenElements = '1rem',
    children,
    className = ''
}: GridProps) => {
    const styles: React.CSSProperties = {
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${sizeElements}, 1fr))`,
        gap: spaceBetweenElements
    }
    return (
        <div style={styles} className={className}>
            {children}
        </div>
    )
}

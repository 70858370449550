import React, { useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'
import PlacesAutocomplete from 'react-places-autocomplete'
import './input-place.css'

export const InputPlaces = ({
    name,
    required = false,
    placeholder = 'Escreva o local aqui...',
    label = ''
}) => {
    const [address, setAddress] = useState('')
    const inputRef = useRef(null)
    const { fieldName, registerField, defaultValue, error } = useField(name)

    const handleSelect = async value => {
        setAddress(value)
    }

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        })
    }, [fieldName, inputRef, registerField])

    return (
        <PlacesAutocomplete
            value={address}
            defaultValue={defaultValue}
            id={name}
            name={name}
            onChange={setAddress}
            onSelect={handleSelect}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
            }) => (
                <div>
                    <label className="teste" htmlFor={label}>
                        <p>
                            {label} {required ? '*' : ''}
                        </p>
                    </label>
                    <input
                        className={`customInput form-control ${
                            error ? 'input-danger' : ''
                        }`}
                        ref={inputRef}
                        id={name}
                        defaultValue={defaultValue}
                        name={name}
                        {...getInputProps({
                            placeholder: placeholder
                        })}
                    ></input>
                    {error && <span className="error-text">{error}</span>}
                    {loading ? <div>Carregando...</div> : null}
                    <div className="body-google-place">
                        {suggestions.map(suggestion => {
                            return (
                                <div
                                    className="input-places-list"
                                    onClick={() => {
                                        setAddress(suggestion.description)
                                    }}
                                    key={suggestion.placeId}
                                    {...getSuggestionItemProps(suggestion)}
                                >
                                    {suggestion.description}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}

import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from '../../Flex'
import styled from 'styled-components'
import { LineSeparator } from '../../LineSeparator'
import arrayMove from 'array-move'
import { Link } from 'react-router-dom'
import { UrlMountQuery } from '../../../utils/UrlMountQuery'
import { get, post } from '../../../../services/http'
import { Paginator, PaginatorType } from './Paginator'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { NoHasData } from './NoHasData'
import useWindowDimensions from '../../GetDimensions'

interface Props {
    requester?: TableRequester
    mapper?: Array<MapperItem>
    paginator?: PaginatorType
    config?: ConfigTable
    dataTable?: any[]
    setTotalItems?: any
}

export interface TableRequester {
    endpoint: string
    method: 'GET' | 'POST'
    payload?: any
    mapper?: (items: any) => any
}

export interface OptionTable {
    label: string
    icon: IconName
    action: (item: any) => void
    conditional?: (option: string, item: any) => boolean
    color?: string
}

export interface ConfigTable {
    textNoHasData?: string
    collapse?: boolean
    collapseField?: string
    edit: boolean
    delete: boolean
    moreOptions?: OptionTable[]
    editAction?: (item: any) => void
    deleteAction?: (item: any) => void
}

export interface MapperItem {
    field: string
    replaceTo?: string
    delete?: boolean
    position?: number
    align?: 'start' | 'center' | 'end'
    visualCustom?: (value: any, index: number) => JSX.Element
}

export interface TableItem {
    field: string
    label: string
}

/**
 * Método responsável por mapear os ob
 * @param data
 * @param mapper
 * @returns
 */
const mapTableItems = (
    headers: Array<string>,
    mapper: Array<MapperItem>
): Array<TableItem> => {
    // Transformando cabeçalhos em Lista de TableItem
    let newHeaders = headers.map(_field => ({
        field: _field,
        label: _field
    }))

    mapper.forEach(itemMapper => {
        // Reposicionamento dos items com base no mapper
        if (itemMapper.position) {
            const positionChange = newHeaders.findIndex(
                i => i.field === itemMapper.field
            )
            newHeaders = arrayMove(
                newHeaders,
                positionChange,
                itemMapper.position
            )
        }
        // Aplicando regras de delete e replaceTo
        newHeaders = newHeaders.map(tableField => {
            if (itemMapper.field === tableField.field) {
                if (itemMapper.replaceTo) {
                    tableField.label = itemMapper.replaceTo
                }
                if (itemMapper.delete) {
                    return { field: '', label: '' }
                }
            }
            return tableField
        })
    })
    return newHeaders
}

const renderValue = (
    valueField: any,
    mapper: MapperItem[],
    header: string,
    index: number
) => {
    let render = valueField
    mapper.forEach(item => {
        if (item.field === header) {
            if (item.visualCustom) {
                render =
                    item.replaceTo === 'Ações' ? (
                        item.visualCustom(valueField, index)
                    ) : (
                        <p>{item.visualCustom(valueField, index)}</p>
                    )
            } else {
                render = (
                    <p>
                        <span style={{ textAlign: item.align || 'start' }}>
                            {render}
                        </span>
                    </p>
                )
            }
        }
    })
    return render
}
/**
 *
 * @param data Array de objetos que serão exibidos na table
 * @param mapper são as especificações para os itens da tabela, o mapper atualmente pode ser usado para costumizar os dados ou personalizar cabeçalhos
 * @returns
 */

export const Table = ({
    mapper = [],
    requester,
    config,
    setTotalItems,
    dataTable = undefined
}: Props) => {
    const [paginator, setPaginator] = useState<PaginatorType>({
        Pages: 0,
        Page: 1
    })
    const [data, setData] = useState<Array<any>>([])
    const [headers, setHeaders] = useState<Array<TableItem>>([])
    const { height, width } = useWindowDimensions()

    useEffect(() => {
        // caso os itens sejam produzidos no próprio frontend
        if (dataTable && dataTable.length) {
            mapDataLocal(dataTable)

            // caso os itens forem de fetching
        } else if (requester) {
            switch (requester.method) {
                case 'GET': {
                    if (requester.payload?.page > 0) {
                        requester.payload.page = paginator.Page
                    }
                    get(
                        UrlMountQuery(
                            { ...requester.payload, Page: paginator.Page },
                            requester.endpoint
                        )
                    ).then(({ data }) => {
                        mapDataFetched(data)
                        if (setTotalItems) {
                            const { Count } = data
                            setTotalItems(Count)
                        }
                    })
                    requester.payload.page = 1
                    break
                }
                case 'POST': {
                    post(
                        requester.endpoint,
                        { ...requester.payload, Page: paginator.Page } || {
                            Page: paginator.Page
                        }
                    ).then(({ data }) => mapDataFetched(data))
                    break
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        requester?.endpoint,
        requester?.method,
        requester?.payload,
        paginator.Page,
        dataTable
    ])

    /**
     * Método para mapear os objetos de paginação e data
     * @param response objeto resposta da requisição
     * */
    const mapDataFetched = ({
        Count,
        Direction,
        ItemsPerPage,
        OrderBy,
        Page,
        Pages,
        Projections,
        Items
    }: any) => {
        if (requester?.mapper) Items = requester?.mapper(Items)

        setData(Items)
        setPaginator({
            Count,
            Direction,
            ItemsPerPage,
            OrderBy,
            Page,
            Pages,
            Projections
        })
        if (Items?.length) {
            const headers = Object.keys(Items[0])
            setHeaders(mapTableItems(headers, mapper))
        }
    }

    const mapDataLocal = (dataTable: Array<any>) => {
        const headers = Object.keys(dataTable[0])
        setHeaders(mapTableItems(headers, mapper))
        setData(dataTable as any)
    }

    const loadOptions = (config: ConfigTable, index: number): JSX.Element => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    fontSize: '0.9rem',
                    width: '200px'
                }}
            >
                {config.delete ? (
                    <span
                        className="danger cursor-pointer"
                        onClick={() => {
                            const listItems =
                                dataTable && dataTable.length
                                    ? [...dataTable]
                                    : [...data]

                            if (config.deleteAction) {
                                config.deleteAction(listItems[index])
                            }
                            const newData = [...data]
                            newData.splice(index, 1)
                            setData(newData)
                        }}
                    >
                        <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                    </span>
                ) : null}
                {config.edit ? (
                    <span
                        className="primary-text cursor-pointer"
                        onClick={() => {
                            if (config.editAction) {
                                const listItems =
                                    dataTable && dataTable.length
                                        ? [...dataTable]
                                        : [...data]
                                config.editAction(listItems[index])
                            }
                        }}
                    >
                        <FontAwesomeIcon icon="pencil-alt"></FontAwesomeIcon>
                    </span>
                ) : null}
                {config.moreOptions ? (
                    <div className="dropdown-options">
                        <button
                            className="primary-text cursor-pointer btn-clean"
                            type="button"
                            id="dropdown-table"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <FontAwesomeIcon icon="ellipsis-v" />
                        </button>
                        <ul
                            className="dropdown-menu dropend"
                            aria-labelledby="dropdown-table"
                        >
                            <li>
                                {config.moreOptions.map(
                                    (option: OptionTable) => {
                                        const listItems =
                                            dataTable && dataTable.length
                                                ? [...dataTable]
                                                : [...data]

                                        const render = (
                                            <button
                                                onClick={() => {
                                                    if (option.action) {
                                                        option.action(
                                                            listItems[index]
                                                        )
                                                    }
                                                }}
                                                className="dropdown-item"
                                                type="button"
                                            >
                                                <FontAwesomeIcon
                                                    icon={option.icon}
                                                    style={{
                                                        width: '3vh',
                                                        color: option.color,
                                                        margin: '0 0.5rem'
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        color: option.color
                                                    }}
                                                >
                                                    {option.label}
                                                </span>
                                            </button>
                                        )

                                        if (option.conditional) {
                                            return option.conditional(
                                                option.label,
                                                listItems[index]
                                            )
                                                ? render
                                                : null
                                        }
                                        return render
                                    }
                                )}
                            </li>
                        </ul>
                    </div>
                ) : null}
            </div>
        )
    }

    if (data?.length === 0) {
        return (
            <>
                <LineSeparator></LineSeparator>
                <p>
                    <NoHasData
                        text={config?.textNoHasData || 'Não há dados'}
                    ></NoHasData>
                </p>
            </>
        )
    } else {
        return (
            <>
                <Flex justifyContent="flex-end"></Flex>
                {width && width >= 769 ? (
                    <Box className="table-responsive">
                        <table className="table">
                            <TheadStyle>
                                <Tr style={{ textAlign: 'center' }}>
                                    {headers.map((header, index) => {
                                        if (header.label) {
                                            return (
                                                <Th key={index}>
                                                    <Text>{header.label}</Text>
                                                </Th>
                                            )
                                        }
                                        return null
                                    })}
                                    {config?.moreOptions?.length ||
                                    config?.edit ||
                                    config?.delete ? (
                                        <Th>
                                            <Text>Ações</Text>
                                        </Th>
                                    ) : null}
                                </Tr>
                            </TheadStyle>
                            <tbody>
                                {data?.map((tableItem, indexData) => (
                                    <Tr
                                        key={indexData}
                                        style={{ textAlign: 'center' }}
                                    >
                                        {headers.map((value, indexHeader) => {
                                            if (value.field) {
                                                return (
                                                    <Td
                                                        key={indexHeader}
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <p>
                                                            {renderValue(
                                                                tableItem[
                                                                    value.field
                                                                ],
                                                                mapper,
                                                                value.field,
                                                                indexData
                                                            )}
                                                        </p>
                                                    </Td>
                                                )
                                            }
                                            return null
                                        })}
                                        {config?.moreOptions?.length ||
                                        config?.edit ||
                                        config?.delete ? (
                                            <Td>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        marginLeft: '30px'
                                                    }}
                                                >
                                                    {loadOptions(
                                                        config,
                                                        indexData
                                                    )}
                                                </div>
                                            </Td>
                                        ) : null}
                                    </Tr>
                                ))}
                            </tbody>
                        </table>
                    </Box>
                ) : (
                    <Box>
                        {data?.map((tableItem, indexData) => {
                            if (tableItem) {
                                return (
                                    <Quadro>
                                        <table style={{ width: '100%' }}>
                                            <TrMobile>
                                                {config?.moreOptions?.length ||
                                                config?.edit ||
                                                config?.delete ? (
                                                    <Options>
                                                        {loadOptions(
                                                            config,
                                                            indexData
                                                        )}
                                                    </Options>
                                                ) : null}
                                                {headers.map(
                                                    (header, index) => {
                                                        if (header.label) {
                                                            return (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            flexDirection:
                                                                                'column'
                                                                        }}
                                                                    >
                                                                        <ThMobile
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                header.label
                                                                            }
                                                                        </ThMobile>
                                                                        <TdMobile
                                                                            key={
                                                                                index
                                                                            }
                                                                            style={{
                                                                                textAlign:
                                                                                    'center'
                                                                            }}
                                                                        >
                                                                            {renderValue(
                                                                                tableItem[
                                                                                    header
                                                                                        .field
                                                                                ],
                                                                                mapper,
                                                                                header.field,
                                                                                indexData
                                                                            )}
                                                                        </TdMobile>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        return null
                                                    }
                                                )}
                                            </TrMobile>
                                        </table>
                                    </Quadro>
                                )
                            }
                            return null
                        })}
                    </Box>
                )}

                {paginator.Pages ? (
                    <Paginator
                        Page={paginator.Page}
                        Pages={paginator.Pages}
                        setPage={(index: number) => {
                            setPaginator({
                                ...paginator,
                                Page: index + 1
                            })
                        }}
                        setNextPage={() => {
                            const currentPage =
                                paginator.Page === paginator.Pages
                                    ? paginator.Page
                                    : paginator.Page + 1
                            setPaginator({
                                ...paginator,
                                Page: currentPage
                            })
                        }}
                        setPreviousPage={() => {
                            const currentPage =
                                paginator.Page <= 1
                                    ? paginator.Page
                                    : paginator.Page - 1
                            setPaginator({
                                ...paginator,
                                Page: currentPage
                            })
                        }}
                    ></Paginator>
                ) : null}
            </>
        )
    }
}

// border: 'solid 1px lightgray',
// borderRadius: '15px'
const Text = styled.p``
const Box = styled.div`
    border: solid 1px lightgray;
    border-radius: 15px;
    @media (max-width: 768px) {
        border: solid 0px lightgray;
        border-radius: 0px;
        margin-left: -0.4rem;
    }
`
const Tr = styled.tr`
    border: 0 !important;
    z-index: 10;
    @media (max-width: 768px) {
        display: none;
    }
`

const TrMobile = styled.tr`
    border: 0 !important;
    z-index: 10;
`
const ThMobile = styled.th`
    position: relative;
    width: auto !important;
    height: auto;
    color: #002a5d;
`
const TdMobile = styled.td`
    padding: 0.5rem 0rem !important;
    color: ${props => props.theme.colors.text};
    padding-left: 5px !important;
    color: #002a5d;
    .form-select {
        background-color: #f1f3f5;
    }
    .button.dropdown-item {
        background: #f1f3f5;
    }
`
const Th = styled.th`
    font-weight: 600;
    padding: 0 !important;
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
    @media (max-width: 768px) {
        display: none;
    }
`

const Td = styled.td`
    padding: 1.7rem 0rem !important;
    color: ${props => props.theme.colors.text};
    border-bottom: solid 1px #e6e6e6;
    padding-left: 5px !important;
    @media (max-width: 768px) {
        display: none;
    }
`

const TdArrow = styled.td`
    font-size: 0.8rem;
    border: 0;
    color: ${props => props.theme.colors.primary};
    border-bottom: solid 1px #e6e6e6;
`

const TheadStyle = styled.thead`
    @media (max-width: 768px) {
        display: none;
    }
`
const Quadro = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 8px;

    width: 342px;
    height: 0 auto;
    min-height: 218px;
    background: #f1f3f5;
    border: 1px solid #dee2e6;

    box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 1rem 0;
`

const Options = styled.div`
    // position: absolute;
    display: flex;
    flex-direction: row;
    // align-items: flex-end;
    float: right;
    padding: 0.4rem;

    width: 1.5rem;
    height: 1.5rem;

    /* Havan Primária / Oficial

002A5D
*/
    background: #002a5d;
    border-radius: 0.4rem;

    /* Inside auto layout */

    // flex: none;
    // order: 2;
    // flex-grow: 0;
    .btn-clean {
        color: #fff;
    }
`
// const Quadro = styled.div`
//     background: #000;
//     width: 10rem;
//     height: 10rem;
//     margin: 1rem;
//     border-radius: 0.8rem;
// `

import React, { useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Center } from '../../../core/components/Center'
import { Header } from '../../../core/components/Header/Header'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Input } from '../../../core/components/Form/Input'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { Button } from '../../../core/components/Button'
import { ENDPOINTS } from '../../../services/config-http'
import { put, get } from '../../../services/http'
import { Alert } from '../../../core/components/Alert'
import { Subtitle } from '../../../core/components/Subtitle'
import { Switch } from '../../../core/components/Switch'
import { Grid } from '../../../core/components/Grid'
import { Description } from '../../../core/components/Description'
import { UserContext } from '../../../contexts/UserContext'
import { Empresa } from '../../../contexts/interfaces/empresa'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { Toast } from '../../../core/components/Toast'
import { Select } from '../../../core/components/Form/Select'

export const EmpresaForm = () => {
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })
    const { Id } = useParams() as any
    const history = useHistory()
    const FormRef = useRef<FormHandles>(null)
    const [administrador, setAdministrador] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const [codigo, setCodigo] = useState(0)
    const [nome, setNome] = useState('')
    const [status, setStatus] = useState(false)
    const dadosUsuario = useContext(UserContext)

    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        getEmpresa(Id)
    }, [])

    const getEmpresa = async (Id: number) => {
        await get(`${ENDPOINTS.empresa}/Id?Id=${Id}`)
            .then(({ data: { Model } }) => {
                setCodigo(Model?.Codigo)
                setNome(Model?.Nome)
                setStatus(Model?.Status)
            })
            .catch(err => {
                console.log(err)
            })
    }
    const handleEdit: SubmitHandler = async (empresa: Empresa) => {
        try {
            const schema = Yup.object().shape({
                Codigo: Yup.string().required('O Campo codigo é obrigatório'),
                Nome: Yup.string().required('O Campo nome é obrigatório')
            })

            await schema.validate(empresa, { abortEarly: false })
            empresa.Codigo = codigo
            empresa.Nome = nome
            empresa.Status = status

            put(ENDPOINTS.empresa, {
                Id,
                codigo: empresa?.Codigo,
                nome: empresa?.Nome,
                status: empresa?.Status
            })
                .then(() => {
                    history.push(`/cadastros/empresas`)
                })
                .catch(({ data }) => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao tentar alterar despesa',
                        type: 'error'
                    })
                })
        } catch (err) {
            console.log(err)
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                FormRef.current?.setErrors(erros)
            }
        }
    }

    return (
        <div>
            <BlankBoard>
                <Header
                    title="Editar empresa"
                    actionArrowClick="/cadastros/empresas"
                ></Header>
                <Center>
                    <Subtitle>Dados da conta empresa</Subtitle>
                </Center>
                {administrador ? (
                    <>
                        <Form ref={FormRef} onSubmit={handleEdit}>
                            <div className="col12 col-sm-1">
                                <Input
                                    name="Codigo"
                                    label="Codigo"
                                    required={true}
                                    value={codigo}
                                    onChange={e =>
                                        setCodigo(parseInt(e.target.value))
                                    }
                                ></Input>
                            </div>
                            <Grid
                                sizeElements="49%"
                                spaceBetweenElements="1rem"
                            >
                                <Input
                                    name="Nome"
                                    label="Nome da empresa"
                                    required={true}
                                    placeholder="Digite um nome"
                                    value={nome}
                                    onChange={e => setNome(e.target.value)}
                                ></Input>
                            </Grid>
                            <LineSeparator></LineSeparator>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="col12 col-sm-11">
                                    <Description>Status</Description>
                                </div>
                                <div className="col12 col-sm-1">
                                    <Switch
                                        name="status"
                                        checked={status}
                                        onChange={e => {
                                            setStatus(!status)
                                        }}
                                    ></Switch>
                                </div>
                            </div>
                            <Center>
                                <Button
                                    icon="save"
                                    colorType="quaternary"
                                    style={{ marginTop: '100px' }}
                                >
                                    Salvar
                                </Button>
                            </Center>
                        </Form>
                    </>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                )}
            </BlankBoard>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </div>
    )
}

import React, { useRef, useEffect, useState, useContext } from 'react'
import { useGlobalState } from '../../../contexts/GlobalStateProvider'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Header } from '../../../core/components/Header/Header'
import { Input } from '../../../core/components/Form/Input'
import { Button } from '../../../core/components/Button'
import { Center } from '../../../core/components/Center'
import { Subtitle } from '../../../core/components/Subtitle'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { Switch } from '../../../core/components/Switch'
import { Motivo } from '../../../contexts/interfaces/motivo'
import { Space } from '../../../core/components/Space'
import { FormHandles, SubmitHandler } from '@unform/core'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { useHistory, useParams } from 'react-router'
import { ENDPOINTS } from '../../../services/config-http'
import { get, post, put } from '../../../services/http'
import { Alert } from '../../../core/components/Alert'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { Toast } from '../../../core/components/Toast'
import { UserContext } from '../../../contexts/UserContext'
import { Grid } from '../../../core/components/Grid'
import { DisableForMobile, IsMobile } from '../../Viagem/Solicitacao/stepMobile'

export const MotivoViagemForm = () => {
    const history = useHistory()
    const { setState } = useGlobalState()
    const [Dados, setDados] = useState({ status: false })
    const [ShowAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    const formRef = useRef<FormHandles>(null)

    const { Id } = useParams() as any
    const dadosUsuario = useContext(UserContext)
    const [administrador, setAdministrador] = useState(false)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        if (Id) {
            getMotivoViagem()
        }
    }, [])

    function getMotivoViagem() {
        return get(UrlMountQuery({ Id: Id }, ENDPOINTS.motivoViagemById))
            .then(({ data: { Model } }) => {
                formRef.current?.setData(Model)
                setDados({ status: Model?.Status })
            })
            .catch(err => {
                setShowAlert({
                    status: true,
                    message: `${err?.status} - Erro ao buscar os dados de Motivo de Viagem`,
                    type: 'danger'
                })
            })
    }

    const handleSubmit: SubmitHandler<Motivo> = async (data, { reset }) => {
        try {
            const schema = Yup.object().shape({
                Descricao: Yup.string().required(
                    'O Campo Descrição é obrigatório'
                )
            })
            await schema.validate(data, { abortEarly: false })

            formRef.current?.setErrors({})
            if (Id) {
                put(ENDPOINTS.motivoViagem, {
                    id: Id,
                    descricao: data.Descricao,
                    status: Dados.status
                })
                    .then(() => {
                        setShowToast(true)
                        setToast({
                            message: 'Motivo alterado!',
                            type: 'success'
                        })
                        history.push(`/cadastros/motivo-viagem`)
                    })
                    .catch(({ data }) => {
                        setShowToast(true)
                        setToast({
                            message: 'Erro ao tentar alterar motivo',
                            type: 'error'
                        })
                    })
            } else {
                post(ENDPOINTS.motivoViagem, {
                    descricao: data.Descricao,
                    status: Dados.status
                })
                    .then(() => {
                        setShowToast(true)
                        setToast({
                            message: 'Motivo de viagem foi cadastrado!',
                            type: 'success'
                        })
                        history.push(`/cadastros/motivo-viagem`)
                    })
                    .catch(({ data }) => {
                        setShowToast(true)
                        setToast({
                            message: 'Erro ao tentar cadastrar novo motivo',
                            type: 'error'
                        })
                    })
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                formRef.current?.setErrors(erros)
            }
        }
    }

    return (
        <div>
            <BlankBoard>
                <Header
                    title={
                        Id ? 'Alterar Motivo Viagem' : 'Cadastar Motivo Viagem'
                    }
                    actionArrowClick="/cadastros/motivo-viagem/"
                ></Header>
                <Center>
                    <Subtitle>Dados do motivo de viagem</Subtitle>
                </Center>
                {administrador ? (
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Alert
                            message={ShowAlert.message}
                            status={ShowAlert.status}
                            type={ShowAlert.type as any}
                        ></Alert>
                        <Space height="5rem"></Space>
                        <Grid sizeElements="49%" spaceBetweenElements="1rem">
                            <Input
                                name="Descricao"
                                label="Descrição"
                                required={true}
                                placeholder="Digite uma descrição"
                            ></Input>
                        </Grid>
                        <Switch
                            checked={Dados.status}
                            onChange={() => {
                                if (Dados.status) {
                                    setDados({ status: false })
                                } else {
                                    setDados({ status: true })
                                }
                            }}
                            name="Status"
                            label="Status"
                        ></Switch>
                        <Space height="5rem"></Space>
                        <Center>
                            <DisableForMobile>
                                <Button
                                    icon={Id ? 'save' : 'plus'}
                                    type={
                                        ShowAlert.status ? 'button' : 'submit'
                                    }
                                    colorType="quaternary"
                                >
                                    {Id ? 'Salvar' : 'Cadastrar'}
                                </Button>
                            </DisableForMobile>
                            <IsMobile>
                                <Button
                                    icon={Id ? 'save' : 'plus'}
                                    type={
                                        ShowAlert.status ? 'button' : 'submit'
                                    }
                                    colorType="btnMobile"
                                >
                                    {Id ? 'Salvar' : 'Cadastrar'}
                                </Button>
                            </IsMobile>
                        </Center>
                    </Form>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                )}
                {showToast ? (
                    <Toast
                        setShow={setShowToast}
                        message={toast.message}
                        type={toast.type}
                    />
                ) : null}
            </BlankBoard>
        </div>
    )
}

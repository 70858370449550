import React from 'react'
import styled from 'styled-components'

const BlankSpace = styled.div`
    width: 100%;
    background: transparent;
`

export const Space = ({ height = '0.8rem' }) => (
    <BlankSpace style={{ height }}></BlankSpace>
)

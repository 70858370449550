import React, { useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Center } from '../../../../core/components/Center'
import { Header } from '../../../../core/components/Header/Header'
import { LineSeparator } from '../../../../core/components/LineSeparator'
import { Input } from '../../../../core/components/Form/Input'
import { Select } from '../../../../core/components/Form/Select'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { Button } from '../../../../core/components/Button'
import { ENDPOINTS } from '../../../../services/config-http'
import { put, get } from '../../../../services/http'
import { Alert } from '../../../../core/components/Alert'
import { Subtitle } from '../../../../core/components/Subtitle'
import { Switch } from '../../../../core/components/Switch'
import { Grid } from '../../../../core/components/Grid'
import { Description } from '../../../../core/components/Description'
import { UserContext } from '../../../../contexts/UserContext'
import { TipoDespesa } from '../../../../contexts/interfaces/tipoDespesa'
import { formataValor } from '../../../../util/legenda/util'
import { InputMoney } from '../../../../core/components/Form/v3/InputMoney'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { Toast } from '../../../../core/components/Toast'
import { InputCurrency } from '../../../../core/components/Form/InputCurrency'
import { IntlRealFormat } from '../../../../core/utils/IntlFormat'
import { MoneyFormat } from '../../../../core/utils/MoneyFormat'

export const DespesasForm = () => {
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })
    const { Id } = useParams() as any
    const history = useHistory()
    const FormRef = useRef<FormHandles>(null)
    const [numeroNota, setNumeroNota] = useState(false)
    const [administrador, setAdministrador] = useState(false)

    const [descricao, setDescricao] = useState('')
    const [necessitaAnexo, setNecessitaAnexo] = useState(false)
    const [necessitaJustificativa, setNecessitaJustificativa] = useState(false)
    const [quantificacao, setQuantificacao] = useState(false)
    const [valorPadrao, setValorPadrao] = useState(0)
    const [showToast, setShowToast] = useState(false)

    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    const QuantificacaoOptions = [
        {
            value: '2',
            label: 'Valor'
        },
        {
            value: '1',
            label: 'Quantidade'
        }
    ]

    useEffect(() => {
        getTipoDespesa(Id)
    }, [])

    const getTipoDespesa = async (Id: number) => {
        await get(`${ENDPOINTS.tipoDespesaPorId}/${Id}`)
            .then(({ data: { Model } }) => {
                console.log(Model.Quantificacao)
                console.log(Model.Quantificacao === 'Valor')
                setDescricao(Model.Descricao)
                setNecessitaAnexo(Model.NecessitaAnexo)
                setNecessitaJustificativa(Model.NecessitaJustificativa)
                setNumeroNota(Model.NecessitaNumeroNota)
                setQuantificacao(Model.Quantificacao === 'Valor')
                setValorPadrao(Model.ValorPadrao)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleEdit: SubmitHandler = async (tipoDespesa: TipoDespesa) => {
        try {
            const schema = Yup.object().shape({
                Descricao: Yup.string().required('O Campo valor é obrigatório'),
                Quantificacao: Yup.string().required(
                    'O Campo quantificação é obrigatório'
                ),
                ValorPadrao: Yup.string().required(
                    'O Campo descrição é obrigatório'
                )
            })

            await schema.validate(tipoDespesa, { abortEarly: false })
            tipoDespesa.Status = true
            tipoDespesa.NecessitaNumeroNota = numeroNota
            tipoDespesa.NecessitaAnexo = necessitaAnexo
            tipoDespesa.NecessitaJustificativa = necessitaJustificativa
            tipoDespesa.Descricao = descricao
            if (tipoDespesa.Quantificacao == '1')
                tipoDespesa.Quantificacao = 'Quantidade'
            else tipoDespesa.Quantificacao = 'Valor'

            put(ENDPOINTS.editartipoDespesa, {
                id: Id,
                descricao: tipoDespesa?.Descricao,
                quantificacao: tipoDespesa?.Quantificacao,
                valorPadrao: valorPadrao,
                NecessitaNumeroNota: tipoDespesa?.NecessitaNumeroNota,
                NecessitaAnexo: tipoDespesa?.NecessitaAnexo,
                NecessitaJustificativa: tipoDespesa?.NecessitaJustificativa,
                status: tipoDespesa?.Status
            })
                .then(() => {
                    history.push(`/cadastros/tipos-despesa`)
                })
                .catch(({ data }) => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao tentar alterar despesa',
                        type: 'error'
                    })
                })
        } catch (err) {
            console.log(err)
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                FormRef.current?.setErrors(erros)
            }
        }
    }

    return (
        <div>
            <BlankBoard>
                <Header
                    title="Editar tipo de despesa"
                    actionArrowClick="/cadastros/tipos-despesa"
                ></Header>
                <Center>
                    <Subtitle>Dados da Despesa</Subtitle>
                </Center>
                {administrador ? (
                    <>
                        <Form ref={FormRef} onSubmit={handleEdit}>
                            <Input
                                name="Descricao"
                                label="Descrição da despesa"
                                required={true}
                                placeholder="Digite uma descrição"
                                value={descricao}
                                onChange={e => setDescricao(e.target.value)}
                            ></Input>
                            <Grid
                                sizeElements="49%"
                                spaceBetweenElements="1rem"
                            >
                                <Select
                                    name="Quantificacao"
                                    label="Quantificação"
                                    options={QuantificacaoOptions}
                                    required={true}
                                    defaultOption={quantificacao ? 2 : 1}
                                ></Select>
                                <InputCurrency
                                    onChange={(e: any) => {
                                        const valorReplace = Number(
                                            e.currentTarget.value
                                                .replaceAll('.', '')
                                                .replaceAll(',', '.')
                                                .replaceAll('R$', '')
                                        )
                                        setValorPadrao(valorReplace)
                                    }}
                                    value={IntlRealFormat(valorPadrao || 0)}
                                    width="col-12"
                                    label="Valor Padrão R$"
                                    name="ValorPadrao"
                                    mask="999.999,99"
                                    required={true}
                                    defaultValue={MoneyFormat(0)}
                                />
                            </Grid>
                            <LineSeparator></LineSeparator>
                            <Center>
                                <Subtitle>
                                    Informações/Documentos Requeridos
                                </Subtitle>
                            </Center>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="col12 col-sm-11">
                                    <Description>Número da nota</Description>
                                </div>
                                <div className="col12 col-sm-1">
                                    <Switch
                                        name="NecessitaNumeroNota"
                                        checked={numeroNota}
                                        onChange={e => {
                                            setNumeroNota(!numeroNota)
                                        }}
                                    ></Switch>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="col12 col-sm-11">
                                    <Description>Anexo</Description>
                                </div>
                                <div className="col12 col-sm-1">
                                    <Switch
                                        name="anexo"
                                        checked={necessitaAnexo}
                                        onChange={e => {
                                            setNecessitaAnexo(!necessitaAnexo)
                                        }}
                                    ></Switch>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="col12 col-sm-11">
                                    <Description>Justificativa</Description>
                                </div>
                                <div className="col12 col-sm-1">
                                    <Switch
                                        name="justificativa"
                                        checked={necessitaJustificativa}
                                        onChange={e => {
                                            setNecessitaJustificativa(
                                                !necessitaJustificativa
                                            )
                                        }}
                                    ></Switch>
                                </div>
                            </div>

                            <Center>
                                <Button
                                    icon="save"
                                    colorType="quaternary"
                                    style={{ marginTop: '100px' }}
                                >
                                    Salvar
                                </Button>
                            </Center>
                        </Form>
                    </>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                )}
            </BlankBoard>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </div>
    )
}

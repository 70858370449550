import React, { createContext, useEffect, useState, ReactNode } from 'react'
import { ENDPOINTS } from '../services/config-http'
import { get } from '../services/http'
import { useAuth } from './Auth'

import { Usuario } from './interfaces/usuario'

export const UserContext = createContext<Usuario>({})
interface UserProviderProps {
    children: ReactNode
}

export function UserProvider({ children }: UserProviderProps) {
    const { user } = useAuth()
    const [dadosUsuario, setDadosUsuario] = useState<Usuario>({})
    useEffect(() => {
        if (user?.codigo) {
            get(
                `${ENDPOINTS.usuarioByCodigo.replace(':Codigo', user.codigo)}`
            ).then(({ data: { Model } }) => {
                setDadosUsuario(Model)
            })
        }
    }, [user])
    return (
        <UserContext.Provider value={dadosUsuario}>
            {children}
        </UserContext.Provider>
    )
}

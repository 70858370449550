import React, { createContext, useContext, useEffect } from 'react'
import { useSpinner } from './Spinner'
import { apiClient } from '../services/http'
import { useAuth } from '../contexts/Auth'
import { useHistory } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/ban-types
type InterceptorContextData = {}

const InterceptorContext = createContext({})

const InterceptorProvider: React.FC = ({ children }) => {
    const history = useHistory()
    const { setLoading } = useSpinner()
    const { handleRefresh, signOut } = useAuth()

    useEffect(() => {
        const isRefreshing = false
        apiClient.interceptors.request.use(request => {
            // Ativação do Spinner
            setLoading(true)

            // Verificação da validade do token
            // if (!isRefreshing) {
            //     isRefreshing = true
            //     return handleRefresh()
            //         .then(() => {
            //             isRefreshing = false
            //             return request
            //         })
            //         .catch(e => {
            //             return Promise.reject(e)
            //         })
            // }
            return request
        })
    }, [setLoading, handleRefresh, signOut])

    useEffect(() => {
        apiClient.interceptors.response.use(
            response => {
                setLoading(false)
                return response
            },
            error => {
                return Promise.reject(error)
            }
        )
    }, [setLoading])

    return (
        <InterceptorContext.Provider value={{}}>
            {children}
        </InterceptorContext.Provider>
    )
}

function useInterceptor(): InterceptorContextData {
    const context = useContext(InterceptorContext)

    if (!context) {
        throw new Error(
            'useInterceptor must be used within an InterceptorProvider'
        )
    }

    return context
}

export { InterceptorProvider, useInterceptor }

import React, { createContext, useContext, useState } from 'react'
import { GlobalStateInterface } from '../contexts/interfaces/global'
import { SOLICITACAO_VIAGEM } from './SolicitacaoViagem/Actions'
import { SolicitacaoViagemReducer } from './SolicitacaoViagem/Reducer'
import { STEP_BY_STEP } from './StepByStep/Actions'
import { StepByStepReducer } from './StepByStep/Reducer'

type StateContextData = {
    setGlobalState(context: string, action: string, data: any): void
    state: Partial<GlobalStateInterface>
}
const StateContext = createContext<StateContextData>({} as StateContextData)

export const GlobalStateProviderV2: React.FC = ({ children }) => {
    const [state, setState] = useState<Partial<GlobalStateInterface>>({})

    /**
     * Método para chavemanto de o que será alterado no estado global.
     * @param reducer Nome do reducer definido no arquivo action da informação que será alterada.
     * @param action Ação Definida no reducer.
     * @param data Dados que serão salvos no estado através do reducer.
     */
    const setGlobalState = (reducer: string, action: string, data: any) => {
        switch (reducer) {
            case SOLICITACAO_VIAGEM.REDUCER_NAME: {
                const newState = SolicitacaoViagemReducer(state, data, action)
                setState(newState)
                break
            }
            case STEP_BY_STEP.REDUCER_NAME: {
                const newState = StepByStepReducer(state, data, action)
                setState(newState)
                break
            }
            default:
                break
        }
    }
    return (
        <StateContext.Provider value={{ setGlobalState, state }}>
            {children}
        </StateContext.Provider>
    )
}

export function useGlobalStates(): StateContextData {
    const context = useContext(StateContext)

    if (!context) {
        throw new Error('useSpinner must be used within an SpinnerProvider')
    }

    return context
}

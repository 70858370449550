import React, { useContext, useEffect, useState } from 'react'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button'
import { ENDPOINTS } from '../../../services/config-http'
import { Header } from '../../../core/components/Header/Header'
import { get, patch, post } from '../../../services/http'
import { useHistory, useParams } from 'react-router'
import { Space } from '../../../core/components/Space'
import { Center } from '../../../core/components/Center'
import styled from 'styled-components'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '../../../contexts/Auth'
import GolImg from '../../../core/assets/Gol.jpg'
import AzulImg from '../../../core/assets/Azul.png'
import LatamImg from '../../../core/assets/Latam.png'
import { Aereo } from '../../../contexts/interfaces/aereo'
import { Toast } from '../../../core/components/Toast'
import { useSpinner } from '../../../hooks/Spinner'
import { Alert } from '../../../core/components/Alert'
import { ModalValidacao } from '../../../core/components/Modal/ModalValidacao'
import { MoneyFormat } from '../../../core/utils/MoneyFormat'
import { UserContext } from '../../../contexts/UserContext'

export const Aprovacao = () => {
    const { user } = useAuth()
    const { Id } = useParams<any>()
    const history = useHistory()

    const [solicitacao, setSolicitacao] = useState<any>()
    const [reservas, setReservas] = useState<Aereo[]>([])
    const [idaVolta, setIdaVolta] = useState(true)
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })
    const [showModal, setShowModal] = useState(false)
    const [tipo, setTipo] = useState(0)
    const { setLoading } = useSpinner()
    const [aprovador, setAprovador] = useState(false)
    const [administrador, setAdministrador] = useState(false)

    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAprovador(dadosUsuario.AprovaViagem === true)
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        const buscarDados = async () => {
            get(`${ENDPOINTS.solicitacaoViagemPorId}/${Id}`)
                .then(async ({ data: { Model } }) => {
                    setSolicitacao(Model)
                    Model?.Aereos?.forEach((element: any) => {
                        const {
                            DataIdaChegada,
                            DataIdaSaida,
                            DataVoltaChegada,
                            DataVoltaSaida,
                            Companhia,
                            QtdEscalas,
                            AeroportoDestino,
                            AeroportoOrigem,
                            IdaVolta,
                            Valor
                        } = element
                        const newAereo: Aereo = {
                            DataIdaChegada: new Date(DataIdaChegada),
                            DataIdaSaida: new Date(DataIdaSaida),
                            DataVoltaChegada: new Date(DataVoltaChegada),
                            DataVoltaSaida: new Date(DataVoltaSaida),
                            Companhia: Companhia,
                            QtdEscalas: QtdEscalas,
                            AeroportoDestino: AeroportoDestino,
                            AeroportoOrigem: AeroportoOrigem,
                            Valor: Valor,
                            IdaVolta: IdaVolta
                        }
                        setIdaVolta(IdaVolta)
                        setReservas(reservas => [...reservas, newAereo])
                    })
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao buscar solicitação',
                        type: 'error'
                    })
                    return err
                })
        }
        if (administrador || aprovador) {
            buscarDados()
        }
    }, [Id, administrador, aprovador])

    function _ShowViajanteName(value: any) {
        const item = solicitacao.Viajantes?.filter(
            (item: any) => item?.IdUsuario === value
        )[0]
        return item?.Nome
    }

    function _ShowViajanteCode(value: any) {
        const item = solicitacao.Viajantes?.filter(
            (item: any) => item?.IdUsuario === value
        )[0]
        return item?.Codigo
    }

    function calcularValorTotal() {
        let valorTotal = 0
        solicitacao?.Adiantamentos?.forEach((adiantamento: any) => {
            valorTotal += adiantamento.Valor
        })
        return valorTotal
    }

    const handleAprovacao = async () => {
        if (!tipo) {
            let status = 0
            if (
                solicitacao?.Hospedagem?.length ||
                reservas.length ||
                solicitacao?.Servico?.length
            ) {
                status = 6
            } else {
                status = 3
            }
            await patch(
                `${ENDPOINTS.solicitarViagem}?Id=${Id}&IdSituacao=${status}`
            )
                .then(({ data: { Model } }) => {
                    history.push(`/solicitacoes`)
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: err.data,
                        type: 'error'
                    })
                })
        } else {
            patch(`${ENDPOINTS.solicitarViagem}?Id=${Id}&IdSituacao=${8}`)
                .then(({ data: { Model } }) => {
                    history.push(`/solicitacoes`)
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: err.data,
                        type: 'error'
                    })
                })
        }
    }
    const renderLogoCia = (cia: string) => {
        switch (cia) {
            case 'G3':
                return (
                    <ContainerLogo>
                        <img src={GolImg} alt="Gol"></img>
                    </ContainerLogo>
                )
            case 'AD':
                return (
                    <ContainerLogo>
                        <img src={AzulImg} alt="Azul"></img>
                    </ContainerLogo>
                )
            case 'JJ':
                return (
                    <ContainerLogo>
                        <img src={LatamImg} alt="Latam"></img>
                    </ContainerLogo>
                )

            default:
                break
        }
    }

    function styleTextLine(title: string, param: any, paramIcon?: string) {
        return (
            <p
                style={{
                    marginBottom: '20px'
                }}
            >
                <b
                    style={{
                        marginRight: '5px'
                    }}
                >
                    {paramIcon && (
                        <FontAwesomeIcon
                            icon={paramIcon}
                            style={{
                                marginRight: '7px'
                            }}
                        />
                    )}
                    {title}:
                </b>
                {param}
            </p>
        )
    }

    return (
        <BlankBoard>
            <Header
                title="Aprovação da Solicitação"
                actionArrowClick={() => history.goBack()}
            ></Header>
            <Space height="2rem"></Space>
            <div className="row">
                {administrador || aprovador ? (
                    <>
                        <h4 style={{ marginLeft: '2.5vh' }}>Informação</h4>
                        <div className="col-12">
                            <Space height="1rem"></Space>
                            <div
                                className="col-12"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: '1vh',
                                        boxShadow:
                                            '0px 4px 30px rgba(0, 0, 0, 0.1)',
                                        padding: '2vh 3vh',
                                        width: '100%'
                                    }}
                                >
                                    {styleTextLine(
                                        'Tipo de Solicitação',
                                        solicitacao?.TipoSolicitacao ||
                                            'Não preenchido'
                                    )}

                                    {styleTextLine(
                                        'Motivo da Viagem',
                                        solicitacao?.MotivoViagem ||
                                            'Não preenchido'
                                    )}
                                    {styleTextLine(
                                        'Descrição',
                                        solicitacao?.Descricao ||
                                            'Não preenchido'
                                    )}
                                    {styleTextLine(
                                        'Viajante Principal',
                                        solicitacao?.Viajantes?.find(
                                            (s: { Convidado: boolean }) =>
                                                s.Convidado === false
                                        )?.Nome || 'Não preenchido'
                                    )}
                                </div>
                            </div>
                        </div>
                        <LineSeparator
                            style={{ margin: '1rem 0' }}
                        ></LineSeparator>
                        <h4 style={{ marginLeft: '2.5vh' }}>Roteiro</h4>
                        <Space height="1rem"></Space>

                        <div
                            className="col-12"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap'
                            }}
                        >
                            {solicitacao?.Destinos?.length
                                ? solicitacao?.Destinos?.map(
                                      (e: any, i: number) => (
                                          <>
                                              <div
                                                  className="col-12 col-sm-12"
                                                  style={{
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      marginBottom: '2vh'
                                                  }}
                                              >
                                                  <div
                                                      style={{
                                                          borderRadius: '1vh',
                                                          boxShadow:
                                                              '0px 4px 30px rgba(0, 0, 0, 0.1)',
                                                          padding: '2vh 3vh',
                                                          width: '100%'
                                                      }}
                                                  >
                                                      <p
                                                          style={{
                                                              fontSize: '15px'
                                                          }}
                                                      >
                                                          {styleTextLine(
                                                              'Roteiro',
                                                              `${i + 1}`
                                                          )}
                                                      </p>

                                                      {styleTextLine(
                                                          'Observação',
                                                          solicitacao?.Destinos[
                                                              i
                                                          ]?.Objetivo ||
                                                              'Não preenchido',
                                                          'bars'
                                                      )}

                                                      {styleTextLine(
                                                          'Destino',
                                                          solicitacao?.Destinos[
                                                              i
                                                          ]?.Destino ||
                                                              'Não preenchido',
                                                          'map-marker-alt'
                                                      )}
                                                      <div>
                                                          <p>
                                                              <FontAwesomeIcon icon="calendar-alt" />
                                                              &ensp;
                                                              <b>
                                                                  Data da
                                                                  Viagem:{' '}
                                                              </b>
                                                              {new Date(
                                                                  solicitacao.Destinos[
                                                                      i
                                                                  ]?.DataInicio
                                                              ).toLocaleDateString() ||
                                                                  'Carregando...'}{' '}
                                                              -{' '}
                                                              {new Date(
                                                                  solicitacao.Destinos[
                                                                      i
                                                                  ]?.DataTermino
                                                              ).toLocaleDateString() ||
                                                                  'Carregando...'}
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </>
                                      )
                                  )
                                : 'Não possui roteiro cadastrado'}
                        </div>
                        <LineSeparator
                            style={{ margin: '1.5rem 0' }}
                        ></LineSeparator>
                        <h4 style={{ marginLeft: '2.5vh' }}>Viajantes</h4>
                        <Space height="1rem"></Space>
                        <div className="col-12">
                            {solicitacao?.Viajantes?.length
                                ? solicitacao?.Viajantes?.map(
                                      (e: any, i: number) => (
                                          <>
                                              <div
                                                  style={{
                                                      borderRadius: '1vh',
                                                      boxShadow:
                                                          '0px 4px 30px rgba(0, 0, 0, 0.1)',
                                                      padding: '2vh 3vh',
                                                      marginBottom: '2vh'
                                                  }}
                                              >
                                                  <p
                                                      style={{
                                                          fontSize: '15px'
                                                      }}
                                                  >
                                                      {styleTextLine(
                                                          'Viajante',
                                                          `${i + 1}`
                                                      )}
                                                  </p>
                                                  <p>
                                                      {e.Codigo} - {e.Nome}
                                                  </p>
                                              </div>
                                          </>
                                      )
                                  )
                                : 'Não possui viajantes cadastrados'}
                        </div>
                        <LineSeparator
                            style={{ margin: '1.5rem 0' }}
                        ></LineSeparator>
                        <h4 style={{ marginLeft: '2.5vh' }}>Adiantamento</h4>
                        <Space height="1rem"></Space>
                        {solicitacao?.Adiantamentos?.length ? (
                            <div
                                className="col-12 col-sm-12"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: '2vh'
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: '1vh',
                                        boxShadow:
                                            '0px 4px 30px rgba(0, 0, 0, 0.1)',
                                        padding: '2vh 3vh',
                                        width: '100%'
                                    }}
                                >
                                    <div className="row">
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                className="col-12 col-sm-3"
                                                style={{ textAlign: 'start' }}
                                            >
                                                <p>
                                                    <b>Motivo</b>
                                                </p>
                                            </div>
                                            <div
                                                className="col-12 col-sm-9"
                                                style={{ textAlign: 'start' }}
                                            >
                                                <p>
                                                    <b>Valor Total</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                className="col-12 col-sm-3"
                                                style={{ textAlign: 'start' }}
                                            >
                                                <p>
                                                    {' '}
                                                    {
                                                        solicitacao
                                                            ?.Adiantamentos[0]
                                                            ?.Descricao
                                                    }
                                                </p>
                                            </div>
                                            <div
                                                className="col-12 col-sm-9"
                                                style={{ textAlign: 'start' }}
                                            >
                                                <p>
                                                    {MoneyFormat(
                                                        calcularValorTotal()
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <Space height="0.6rem"></Space>
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                className="col-12 col-sm-3"
                                                style={{ textAlign: 'start' }}
                                            >
                                                <p>
                                                    <b>Viajantes</b>
                                                </p>
                                            </div>
                                            <div
                                                className="col-12 col-sm-9"
                                                style={{ textAlign: 'start' }}
                                            >
                                                <p>
                                                    <b>Valor Individual</b>
                                                </p>
                                            </div>
                                        </div>

                                        {solicitacao?.Adiantamentos?.map(
                                            (e: any, key: number) => {
                                                return (
                                                    <div key={key}>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-3">
                                                                <p>
                                                                    {_ShowViajanteCode(
                                                                        e.IdUsuario
                                                                    )}
                                                                    {' - '}
                                                                    {_ShowViajanteName(
                                                                        e.IdUsuario
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="col-12 col-sm-9">
                                                                <p>
                                                                    <div
                                                                        style={{
                                                                            textAlign:
                                                                                'start'
                                                                        }}
                                                                    >
                                                                        {MoneyFormat(
                                                                            e.Valor
                                                                        )}
                                                                    </div>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p>Não possui Adiantamento cadastrado</p>
                        )}
                        <LineSeparator
                            style={{ margin: '1.5rem 0' }}
                        ></LineSeparator>
                        <h4 style={{ marginLeft: '2.5vh' }}>Aéreo</h4>
                        <Space height="1rem"></Space>
                        <div className="col-12">
                            {reservas.length > 0 ? (
                                <>
                                    {reservas.map((reserva, index) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    padding: '1rem 0',
                                                    borderRadius: '1vh',
                                                    boxShadow:
                                                        '0px 4px 30px rgba(0, 0, 0, 0.1)'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        border: '1px solid #BDBDBD',
                                                        margin: '1rem 1rem',
                                                        padding: '1rem'
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-3 title-passagem">
                                                            <p>Origem</p>
                                                        </div>
                                                        <div className="col-3 title-passagem">
                                                            <p>Destino</p>
                                                        </div>
                                                        <div className="col-2 title-passagem">
                                                            <p>Escalas</p>
                                                        </div>
                                                        <div className="col-2 title-passagem">
                                                            <p>Companhia</p>
                                                        </div>
                                                        <div className="col-2 title-passagem">
                                                            <p>Valor</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row"
                                                        style={{
                                                            color: '#5F8DD3',
                                                            fontSize: 'medium'
                                                        }}
                                                    >
                                                        <div className="col-3">
                                                            {idaVolta ? (
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            <p>
                                                                                {
                                                                                    reserva.AeroportoOrigem
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <div
                                                                            className="col"
                                                                            style={{
                                                                                marginLeft:
                                                                                    '.5rem'
                                                                            }}
                                                                        >
                                                                            <p>
                                                                                {reserva.DataIdaSaida?.toLocaleDateString(
                                                                                    'pt-BR',
                                                                                    {
                                                                                        hour: '2-digit',
                                                                                        minute: '2-digit'
                                                                                    }
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            <p>
                                                                                {
                                                                                    reserva.AeroportoDestino
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <div
                                                                            className="col"
                                                                            style={{
                                                                                marginLeft:
                                                                                    '.5rem'
                                                                            }}
                                                                        >
                                                                            <p>
                                                                                {reserva.DataVoltaSaida?.toLocaleDateString(
                                                                                    'pt-BR',
                                                                                    {
                                                                                        hour: '2-digit',
                                                                                        minute: '2-digit'
                                                                                    }
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="row">
                                                                    <div className="col-2">
                                                                        <p>
                                                                            {
                                                                                reserva.AeroportoOrigem
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className="col"
                                                                        style={{
                                                                            marginLeft:
                                                                                '.5rem'
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {reserva.DataIdaSaida?.toLocaleDateString(
                                                                                'pt-BR',
                                                                                {
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit'
                                                                                }
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-3">
                                                            {idaVolta ? (
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            <p>
                                                                                {
                                                                                    reserva.AeroportoDestino
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <div
                                                                            className="col"
                                                                            style={{
                                                                                marginLeft:
                                                                                    '.5rem'
                                                                            }}
                                                                        >
                                                                            <p>
                                                                                {reserva.DataIdaChegada?.toLocaleDateString(
                                                                                    'pt-BR',
                                                                                    {
                                                                                        hour: '2-digit',
                                                                                        minute: '2-digit'
                                                                                    }
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            <p>
                                                                                {
                                                                                    reserva.AeroportoOrigem
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <div
                                                                            className="col"
                                                                            style={{
                                                                                marginLeft:
                                                                                    '.5rem'
                                                                            }}
                                                                        >
                                                                            <p>
                                                                                {reserva.DataVoltaChegada?.toLocaleDateString(
                                                                                    'pt-BR',
                                                                                    {
                                                                                        hour: '2-digit',
                                                                                        minute: '2-digit'
                                                                                    }
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="row">
                                                                    <div className="col-2">
                                                                        <p>
                                                                            {
                                                                                reserva.AeroportoDestino
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className="col"
                                                                        style={{
                                                                            marginLeft:
                                                                                '.5rem'
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {reserva.DataIdaChegada?.toLocaleDateString(
                                                                                'pt-BR',
                                                                                {
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit'
                                                                                }
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div
                                                            className="col-2"
                                                            style={{
                                                                alignContent:
                                                                    'center',
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center'
                                                            }}
                                                        >
                                                            {reserva.QtdEscalas ===
                                                            1 ? (
                                                                <div>
                                                                    {
                                                                        reserva.QtdEscalas
                                                                    }{' '}
                                                                    <p>
                                                                        PARADAS
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    {
                                                                        reserva.QtdEscalas
                                                                    }{' '}
                                                                    <p>
                                                                        PARADA
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div
                                                            className="col-2"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center'
                                                            }}
                                                        >
                                                            <p>
                                                                {renderLogoCia(
                                                                    reserva.Companhia ||
                                                                        ''
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-2"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                fontWeight:
                                                                    'bold',
                                                                color: '#002A5D'
                                                            }}
                                                        >
                                                            <p>
                                                                {' '}
                                                                {MoneyFormat(
                                                                    Number(
                                                                        reserva.Valor
                                                                    )
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            ) : (
                                <p>Não possui aéreo cadastrado</p>
                            )}
                        </div>
                        <LineSeparator
                            style={{ margin: '1.5rem 0' }}
                        ></LineSeparator>
                        <h4 style={{ marginLeft: '2.5vh' }}>Hospedagem</h4>
                        <Space height="1rem"></Space>
                        <div className="col-12">
                            {solicitacao?.Hospedagem?.length
                                ? solicitacao?.Hospedagem?.map(
                                      (e: any, i: number) => (
                                          <>
                                              <div
                                                  className="col-12 col-sm-12"
                                                  style={{
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      marginBottom: '2vh'
                                                  }}
                                              >
                                                  <div
                                                      style={{
                                                          borderRadius: '1vh',
                                                          boxShadow:
                                                              '0px 4px 30px rgba(0, 0, 0, 0.1)',
                                                          padding: '2vh 3vh',
                                                          width: '100%'
                                                      }}
                                                  >
                                                      {styleTextLine(
                                                          'Hospedagem',
                                                          `${i + 1}`
                                                      )}

                                                      {styleTextLine(
                                                          'Cidade',
                                                          solicitacao
                                                              ?.Hospedagem[i]
                                                              ?.Cidade
                                                      )}
                                                      {styleTextLine(
                                                          'Check-in',
                                                          solicitacao
                                                              ?.Hospedagem[i]
                                                              ?.DataEntrada
                                                              ? new Date(
                                                                    solicitacao?.Hospedagem[
                                                                        i
                                                                    ]?.DataEntrada
                                                                ).toLocaleDateString()
                                                              : null
                                                      )}
                                                      {styleTextLine(
                                                          'Check-out',
                                                          solicitacao
                                                              ?.Hospedagem[i]
                                                              ?.DataSaida
                                                              ? new Date(
                                                                    solicitacao?.Hospedagem[
                                                                        i
                                                                    ]?.DataSaida
                                                                ).toLocaleDateString()
                                                              : null
                                                      )}
                                                      {styleTextLine(
                                                          'Observação',
                                                          solicitacao
                                                              ?.Hospedagem[i]
                                                              ?.Observacao
                                                      )}
                                                  </div>
                                              </div>
                                          </>
                                      )
                                  )
                                : 'Não possui hospedagem cadastrado'}
                        </div>
                        <LineSeparator
                            style={{ margin: '1.5rem 0' }}
                        ></LineSeparator>
                        <h4 style={{ marginLeft: '2.5vh' }}>Serviços</h4>
                        <div className="col-12">
                            <Space height="1rem"></Space>
                            {solicitacao?.Servico?.length
                                ? solicitacao?.Servico?.map(
                                      (e: any, i: number) => (
                                          <>
                                              <div
                                                  className="col-12 col-sm-12"
                                                  style={{
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      marginBottom: '2vh'
                                                  }}
                                              >
                                                  <div
                                                      style={{
                                                          borderRadius: '1vh',
                                                          boxShadow:
                                                              '0px 4px 30px rgba(0, 0, 0, 0.1)',
                                                          padding: '2vh 3vh',
                                                          width: '100%'
                                                      }}
                                                  >
                                                      {styleTextLine(
                                                          'Serviço',
                                                          `${i + 1}`
                                                      )}

                                                      {styleTextLine(
                                                          'Descrição',
                                                          e.DescricaoServico
                                                      )}
                                                      {styleTextLine(
                                                          'Observação',
                                                          e.Observacao
                                                      )}
                                                  </div>
                                              </div>
                                          </>
                                      )
                                  )
                                : 'Não possui serviços cadastrados'}
                        </div>
                        <LineSeparator
                            style={{ margin: '1.5rem 0' }}
                        ></LineSeparator>
                        <h4 style={{ marginLeft: '2.5vh' }}>Rateio</h4>
                        <Space height="1rem"></Space>
                        <div className="col-12">
                            {solicitacao?.Rateio?.length
                                ? solicitacao?.Rateio?.map(
                                      (e: any, i: number) => (
                                          <>
                                              <div
                                                  className="col-12 col-sm-12"
                                                  style={{
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      marginBottom: '2vh'
                                                  }}
                                              >
                                                  <div
                                                      style={{
                                                          borderRadius: '1vh',
                                                          boxShadow:
                                                              '0px 4px 30px rgba(0, 0, 0, 0.1)',
                                                          padding: '2vh 3vh',
                                                          width: '100%'
                                                      }}
                                                  >
                                                      {styleTextLine(
                                                          'Rateio',
                                                          `${i + 1}`
                                                      )}
                                                      {styleTextLine(
                                                          'Filial',
                                                          e.Filial
                                                      )}
                                                      {styleTextLine(
                                                          'Conta Contabíl',
                                                          e.ContaContabil
                                                      )}
                                                      {styleTextLine(
                                                          'Percentual',
                                                          `${e.Percentual}%`
                                                      )}
                                                  </div>
                                              </div>
                                          </>
                                      )
                                  )
                                : 'Não possui rateio cadastrado'}
                        </div>
                    </>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador ou aprovador"
                    ></Alert>
                )}

                <Space height="2rem"></Space>

                <div className="row">
                    {administrador || aprovador ? (
                        <>
                            <div className="col-12 col-sm-2"></div>
                            <div className="col-12 col-sm-4">
                                <Center>
                                    <Button
                                        icon="ban"
                                        colorType="quintiary"
                                        type="submit"
                                        onClick={() => {
                                            setTipo(1)
                                            setShowModal(true)
                                        }}
                                    >
                                        Reprovar
                                    </Button>
                                </Center>
                            </div>
                            <div className="col-12 col-sm-4">
                                <Center>
                                    <Button
                                        icon="check"
                                        colorType="quaternary"
                                        type="submit"
                                        onClick={() => {
                                            setTipo(0)
                                            setShowModal(true)
                                        }}
                                    >
                                        Aprovar
                                    </Button>
                                </Center>
                            </div>
                            <div className="col-12 col-sm-2"></div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
            {showModal && (
                <ModalValidacao
                    setShow={setShowModal}
                    handleCancel={() => {
                        setShowModal(false)
                    }}
                    height={'17rem'}
                    buttonConfirm={
                        !tipo
                            ? { text: 'Aprovar', color: '#157347' }
                            : { text: 'Reprovar', color: '#bb2d3b' }
                    }
                    handleConfirm={handleAprovacao}
                    title={
                        !tipo
                            ? {
                                  text: 'Aprovar',
                                  color: '#157347'
                              }
                            : {
                                  text: 'Reprovar',
                                  color: '#bb2d3b'
                              }
                    }
                    text={
                        !tipo
                            ? 'Deseja realmente aprovar esta solicitação ?'
                            : 'Deseja realmente reprovar esta solicitação ?'
                    }
                />
            )}
        </BlankBoard>
    )
}

const ContainerLogo = styled.div`
    width: 80px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: end;
    align-items: left;
    margin: 0 auto;

    img {
        width: 100%;
    }
`
const Text = styled.p``

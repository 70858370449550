import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router-dom'
import { useAuth } from '../../../../contexts/Auth'
import { Button } from '../../../../core/components/Button'
import { Center } from '../../../../core/components/Center'
import { Input } from '../../../../core/components/Form/Input'
import { AutoCompleteSelect } from '../../../../core/components/Form/v3/AutoCompleteSelect'
import { LineSeparator } from '../../../../core/components/LineSeparator'
import { Subtitle } from '../../../../core/components/Subtitle'
import { ENDPOINTS } from '../../../../services/config-http'
import { BlankBoard } from '.././../../../core/components/BlankBoard'
import { Header } from '.././../../../core/components/Header/Header'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import styled from 'styled-components'
import { Space } from '../../../../core/components/Space'
import { UrlMountQuery } from '../../../../core/utils/UrlMountQuery'
import { get, post, put } from '../../../../services/http'
import { Viajante } from '../../../../contexts/interfaces/viajante'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DisableForMobile, IsMobile } from '../stepMobile'
import { Box } from '@material-ui/core'
import { Quadro, TrMobile, getCampo } from '../../../../core/components/Mobile'
import { Deletar } from '../../../../core/components/Deletar'

export const EditarViajantesForm = () => {
    const history = useHistory()
    const { user } = useAuth()
    const formRef = useRef<FormHandles>(null)
    const { Id } = useParams() as any
    const [viajantes, setViajantes] = useState<Array<Viajante>>([])
    const [viajantePrincipal, setViajantePrincipal] = useState<any>()
    const [flagErrorForm, setFlagErrorForm] = useState<boolean>(false)
    const [flagErrorSolicitante, setFlagErrorSolicitante] =
        useState<boolean>(false)
    const [messageError, setMessageError] = useState<string>('')
    const [messageErrorSolicitante, setMessageErrorSolicitante] =
        useState<string>('')
    const [viajantesNovos, setViajantesNovos] = useState<Array<Viajante>>([])
    const [viajantesRemovidos, setViajantesRemovidos] = useState<
        Array<Viajante>
    >([])
    const [viajantesAlterados, setViajantesAlterados] = useState<
        Array<Viajante>
    >([])
    let submit = false

    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })

    useEffect(() => {
        get(`${ENDPOINTS.viajanteByIdSolicitacao}/${Id}`)
            .then(({ data: { Model } }) => {
                if (Model && Model?.length) {
                    setViajantes(Model)
                }
            })
            .catch(err => {
                setShowAlert({
                    status: true,
                    message: err.data,
                    type: 'danger'
                })
            })
        formRef.current?.setFieldValue(
            'principal',
            `${user.codigo} - ${user.nome}`
        )
        setPrincipal(formRef.current?.getFieldValue('principal'))
    }, [])

    const setPrincipal = (principal: any) => {
        let codigoUsuarioSolicitante
        if (typeof principal === 'string') {
            codigoUsuarioSolicitante = +principal.split('-', 1)[0].trim()
        } else if (typeof principal === 'object') {
            codigoUsuarioSolicitante = +principal.Codigo
        }
        if (codigoUsuarioSolicitante) {
            get(
                UrlMountQuery(
                    { Codigo: codigoUsuarioSolicitante },
                    ENDPOINTS.usuarioByCodigo
                )
            ).then(({ data: { Model } }) => {
                setViajantePrincipal(Model)
            })
        }
    }

    function ChecarSeExiste(
        listaViajantes: Viajante[],
        viajanteParaChecar: Viajante
    ) {
        let existe = -1
        listaViajantes?.map((e: any, key: number) => {
            if (listaViajantes[key].Codigo === viajanteParaChecar.Codigo) {
                existe = key
            }
        })
        return existe // retorna -1 caso não exista
    }

    function AdicionarViajante(viajanteRecebido: Viajante) {
        const existeRemovidos = ChecarSeExiste(
            viajantesRemovidos,
            viajanteRecebido
        )

        if (existeRemovidos === -1) {
            // caso nao seja um viajante removido
            const existeAlterados = ChecarSeExiste(
                viajantesAlterados,
                viajanteRecebido
            )
            if (existeAlterados === -1) {
                // caso nao seja um viajante alterado
                viajantesNovos.push(viajanteRecebido)
                setViajantesNovos(viajantesNovos)
            } else {
                viajantesAlterados?.map((e: any, key: number) => {
                    if (
                        viajantesAlterados[key].Convidado ===
                        viajanteRecebido.Convidado
                    ) {
                        viajantesAlterados.splice(existeAlterados, 1)
                        setViajantesAlterados(viajantesAlterados)
                    }
                })
            }
        } else {
            const existeAlterados = ChecarSeExiste(
                viajantesAlterados,
                viajanteRecebido
            )
            if (existeAlterados === -1) {
                // caso nao seja um viajante alterado
                viajantesRemovidos.splice(existeAlterados, 1)
                setViajantesRemovidos(viajantesRemovidos)
            } else {
                viajantesRemovidos?.map((e: any, key: number) => {
                    if (
                        viajantesRemovidos[key].Convidado ===
                        viajanteRecebido.Convidado
                    ) {
                        viajantesAlterados.splice(existeRemovidos, 1)
                        setViajantesAlterados(viajantesAlterados)
                        viajantesRemovidos.splice(existeRemovidos, 1)
                        setViajantesRemovidos(viajantesRemovidos)
                    }
                })
            }
        }
    }

    function TornarPrincipal(viajanteRecebido: Viajante) {
        const existeNovos = viajantesNovos.indexOf(viajanteRecebido)

        if (existeNovos !== -1) {
            // se for um viajante novo
            viajanteRecebido.Convidado = false
        } else {
            const existeAlterados = viajantesAlterados.indexOf(viajanteRecebido)

            if (existeAlterados === -1) {
                // se nao for um viajante alterado
                viajantesAlterados.push(viajanteRecebido)
                setViajantesAlterados(viajantesAlterados)
            } else {
                viajantesAlterados.splice(existeAlterados, 1)
                setViajantesAlterados(viajantesAlterados)
            }

            viajanteRecebido.Convidado = false
        }
    }

    function DeletarViajante(viajanteRecebido: Viajante) {
        const existeNovos = viajantesNovos.indexOf(viajanteRecebido)

        if (existeNovos === -1) {
            // se nao for um viajante novo
            const hasViajante = viajantesRemovidos.some(
                i => i.Codigo === viajanteRecebido.Codigo
            )
            if (hasViajante === false) {
                viajantesRemovidos.push(viajanteRecebido)
                setViajantesRemovidos(viajantesRemovidos)
            }
        } else {
            viajantesNovos.splice(existeNovos, 1)
            setViajantesNovos(viajantesNovos)
        }

        const existeAlterados = viajantesAlterados.indexOf(viajanteRecebido)
        if (existeAlterados !== -1) {
            // caso seja um viajante alterado
            viajantesAlterados.splice(existeAlterados, 1)
            setViajantesAlterados(viajantesAlterados)
        }
    }

    function BotaoTrocarPrincipal(
        quemVaiSerPrincipal: number,
        quemEraPrincipal: number
    ) {
        const existeNovosVaiSerPrincipal = viajantesNovos.indexOf(
            viajantes[quemVaiSerPrincipal]
        )

        if (existeNovosVaiSerPrincipal !== -1) {
            // se for um viajante novo
            viajantes[quemVaiSerPrincipal].Convidado = false
        } else {
            const existeAlterados = viajantesAlterados.indexOf(
                viajantes[quemVaiSerPrincipal]
            )

            if (existeAlterados === -1) {
                // se nao for um viajante alterado
                viajantesAlterados.push(viajantes[quemVaiSerPrincipal])
                setViajantesAlterados(viajantesAlterados)
            } else {
                viajantesAlterados.splice(existeAlterados, 1)
                setViajantesAlterados(viajantesAlterados)
            }
            const existeRemovidos = viajantesRemovidos.indexOf(
                viajantes[quemVaiSerPrincipal]
            )

            if (existeRemovidos !== -1) {
                // se ele for um viajante removido
                viajantesRemovidos.splice(existeRemovidos, 1)
                setViajantesRemovidos(viajantesRemovidos)
            }
            viajantes[quemVaiSerPrincipal].Convidado = false
        }

        const existeNovosEraPrincipal = viajantesNovos.indexOf(
            viajantes[quemEraPrincipal]
        )

        if (existeNovosEraPrincipal !== -1) {
            // se for um viajante novo
            viajantes[quemEraPrincipal].Convidado = true
        } else {
            const existeAlterados = viajantesAlterados.indexOf(
                viajantes[quemEraPrincipal]
            )

            if (existeAlterados === -1) {
                // se nao for um viajante alterado
                viajantesAlterados.push(viajantes[quemEraPrincipal])
                setViajantesAlterados(viajantesAlterados)
            } else {
                viajantesAlterados.splice(existeAlterados, 1)
                setViajantesAlterados(viajantesAlterados)
            }
            const existeRemovidos = viajantesRemovidos.indexOf(
                viajantes[quemEraPrincipal]
            )

            if (existeRemovidos !== -1) {
                // se ele for um viajante removido
                viajantesRemovidos.splice(existeRemovidos, 1)
                setViajantesRemovidos(viajantesRemovidos)
            }
            viajantes[quemEraPrincipal].Convidado = true
        }
        setViajantes(viajantes)
    }

    function getTabelaMobile() {
        return (
            <Box>
                {viajantes.map((tableItem, index) => {
                    if (tableItem) {
                        return (
                            <Quadro>
                                <table style={{ width: '100%' }}>
                                    <TrMobile>
                                        {getCampo(index, '#', tableItem.Codigo)}
                                        {getCampo(
                                            index,
                                            'Nome',
                                            tableItem.Nome
                                        )}
                                        {getCampo(
                                            index,
                                            'Viajante Principal',
                                            getViajante(index, tableItem)
                                        )}
                                        {getCampo(
                                            index,
                                            'Remover',
                                            btnRemover(index)
                                        )}
                                    </TrMobile>
                                </table>
                            </Quadro>
                        )
                    }
                    return null
                })}
            </Box>
        )
    }

    function getViajante(key: number, object: any) {
        return !object.Convidado ? (
            <ViajantePrincipalDiv disabled={true}>
                Viajante Principal
            </ViajantePrincipalDiv>
        ) : object.Codigo !== '' ? (
            <TornarPrincipalBtn
                type="button"
                onClick={() => {
                    const newViajantes = [...viajantes]
                    newViajantes.forEach((object, index) => {
                        if (index === key) {
                            if (object.Codigo === '') {
                                setMessageError(
                                    'Apenas colaboradores podem ser o viajante principal'
                                )
                                return
                            }
                            object.Convidado = false
                        } else {
                            object.Convidado = true
                        }
                    })
                    setViajantes(newViajantes)
                }}
            >
                Tornar Principal
            </TornarPrincipalBtn>
        ) : null
    }

    function btnRemover(index: number) {
        return (
            <Deletar
                remover={() => {
                    const newViajantes = [...viajantes]
                    const convidado = newViajantes[index].Convidado

                    const viajanteRemovido = newViajantes.splice(index, 1)
                    if (!convidado && newViajantes.length > 0) {
                        newViajantes[0].Convidado = false
                        TornarPrincipal(newViajantes[0])
                    }

                    DeletarViajante(viajanteRemovido[0])
                    setViajantes(newViajantes)
                }}
            />
        )
    }

    const handleFormSubmit: SubmitHandler = async (
        { user, principal },
        { reset }
    ) => {
        if (submit === false) {
            setPrincipal(principal)
            setFlagErrorForm(false)
            const newViajantes = [...viajantes]
            const hasViajante = newViajantes.some(i => i.Codigo === user.Codigo)
            if (hasViajante) {
                setFlagErrorForm(true)
                setMessageError('Ops! Viajante já adicionado a lista')
            } else {
                try {
                    const schema = Yup.object().shape({
                        user: Yup.object()
                            .nullable()
                            .required('Escolha um usuário para adicionar')
                    })

                    await schema.validate({ user: user }, { abortEarly: false })

                    formRef.current?.setErrors({})

                    get(
                        UrlMountQuery(
                            { Codigo: user.Codigo },
                            ENDPOINTS.pesquisarUsuarios
                        )
                    ).then(({ data: { Items } }) => {
                        const user = Items[0]
                        const newViajantes = [...viajantes]
                        const hasViajante = newViajantes.some(
                            i => i.Codigo === user.Codigo
                        )
                        if (hasViajante) {
                            setFlagErrorSolicitante(true)
                            setMessageErrorSolicitante(
                                'Ops! Viajante já adicionado a lista'
                            )
                        } else {
                            const viajanteLocal: Viajante = {
                                IdSolicitacao: Number(Id) as number,
                                Nome: user.Nome,
                                Codigo: user.Codigo,
                                IdPoliticaViagem: user.TipoUsuario,
                                Convidado: viajantes.length > 0,
                                Status: true,
                                IdUsuario: user.Id,
                                Id: 0
                            }
                            AdicionarViajante(viajanteLocal)

                            newViajantes.push(viajanteLocal)
                            setViajantes([...newViajantes])
                        }
                    })
                } catch (err) {
                    if (err instanceof Yup.ValidationError) {
                        const erros = MapFormErrors(err.inner)
                        formRef.current?.setErrors(erros)
                    }
                }
            }
        } else {
            if (viajantes.length === 0) {
                try {
                    const schema = Yup.object().shape({
                        user: Yup.object()
                            .nullable()
                            .required(
                                'Pelo menos um viajante precisa ser adicionado'
                            )
                    })
                    await schema.validate({ user: user }, { abortEarly: false })

                    formRef.current?.setErrors({})
                } catch (err) {
                    if (err instanceof Yup.ValidationError) {
                        const erros = MapFormErrors(err.inner)
                        formRef.current?.setErrors(erros)
                    }
                }
            } else {
                viajantesNovos.forEach(element => {
                    if (element.IdSolicitacao !== undefined) {
                        post(ENDPOINTS.viajante, {
                            IdSolicitacaoViagem: element.IdSolicitacao,
                            IdUsuario: element.IdUsuario,
                            IdPoliticaViagem: element.IdPoliticaViagem,
                            Convidado: element.Convidado,
                            Status: true,
                            Codigo: element.Codigo
                        })
                            .then(res => {
                                setShowAlert({
                                    status: true,
                                    message: res.data,
                                    type: 'success'
                                })
                            })
                            .catch(err => {
                                setShowAlert({
                                    status: true,
                                    message: err.data.Text,
                                    type: 'danger'
                                })
                            })
                    }
                })
                viajantesAlterados.forEach(element => {
                    put(ENDPOINTS.viajante, {
                        id: element.Id,
                        idSolicitacaoViagem: element.IdSolicitacao,
                        idUsuario: element.IdUsuario,
                        idPoliticaViagem: element.IdPoliticaViagem,
                        convidado: element.Convidado,
                        status: true
                    })
                        .then(res => {
                            setShowAlert({
                                status: true,
                                message: res.data,
                                type: 'success'
                            })
                        })
                        .catch(err => {
                            setShowAlert({
                                status: true,
                                message: err.data.Text,
                                type: 'danger'
                            })
                        })
                })
                viajantesRemovidos.forEach(element => {
                    put(ENDPOINTS.viajante, {
                        id: element.Id,
                        idSolicitacaoViagem: element.IdSolicitacao,
                        idUsuario: element.IdUsuario,
                        idPoliticaViagem: element.IdPoliticaViagem,
                        convidado: element.Convidado,
                        status: false
                    })
                        .then(res => {
                            setShowAlert({
                                status: true,
                                message: res.data,
                                type: 'success'
                            })
                        })
                        .catch(err => {
                            setShowAlert({
                                status: true,
                                message: err.data.Text,
                                type: 'danger'
                            })
                        })
                })
                history.push(`/solicitacoes/editar/${Id}`)
            }
        }
    }

    const incluirComoViajante = () => {
        get(
            UrlMountQuery(
                { Codigo: viajantePrincipal.Codigo },
                ENDPOINTS.pesquisarUsuarios
            )
        ).then(({ data: { Items } }) => {
            const user = Items[0]
            const newViajantes = [...viajantes]
            const hasViajante = newViajantes.some(i => i.Codigo === user.Codigo)
            if (hasViajante) {
                setFlagErrorSolicitante(true)
                setMessageErrorSolicitante(
                    'Ops! Viajante já adicionado a lista'
                )
            } else {
                const viajanteLocal: Viajante = {
                    IdSolicitacao: Id as number,
                    Nome: user.Nome,
                    Codigo: user.Codigo,
                    IdPoliticaViagem: user.TipoUsuario,
                    Convidado: viajantes.length > 0,
                    Status: true,
                    IdUsuario: user.Id,
                    Id: 0
                }
                AdicionarViajante(viajanteLocal)

                newViajantes.push(viajanteLocal)
                setViajantes([...newViajantes])
            }
        })
    }

    return (
        <BlankBoard>
            <Header
                title="Viajantes"
                actionArrowClick={() =>
                    history.push(`/solicitacoes/editar/${Id}`)
                }
            ></Header>
            <Space height="1rem"></Space>
            <Center>
                <Subtitle className="text-center">Dados dos Viajantes</Subtitle>
            </Center>

            <Form
                ref={formRef}
                onSubmit={handleFormSubmit}
                style={{ margin: '2rem 0', padding: '0' }}
            >
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <Input
                            name="principal"
                            label="Usuário Solicitante"
                            required={true}
                            disabled={true}
                        ></Input>
                    </div>
                    <div
                        className="col-2"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '0'
                        }}
                    >
                        <Center>
                            <DisableForMobile>
                                <Button
                                    style={{
                                        marginTop: '20px',
                                        marginLeft: '10px'
                                    }}
                                    icon="user-plus"
                                    title="Salvar"
                                    type="button"
                                    onClick={() => incluirComoViajante()}
                                    colorType="quintiary"
                                >
                                    <b>Incluir como viajante</b>
                                </Button>
                            </DisableForMobile>
                        </Center>
                    </div>
                    <Center>
                        <IsMobile>
                            <Button
                                icon="user-plus"
                                title="Salvar"
                                type="button"
                                onClick={() => incluirComoViajante()}
                                colorType="btnMobile"
                            >
                                <b>Incluir como viajante</b>
                            </Button>
                        </IsMobile>
                    </Center>
                    <div className="col-7">
                        {flagErrorSolicitante ? (
                            <p className="danger" style={{ marginTop: '15px' }}>
                                {messageErrorSolicitante}
                            </p>
                        ) : null}
                    </div>
                    <LineSeparator style={{ margin: '1.5rem' }}></LineSeparator>
                    <div className="col-12 col-sm-6">
                        <AutoCompleteSelect
                            name="user"
                            label="Viajante"
                            placeholder="Digite nome ou matrícula"
                            required={true}
                            request={{
                                endpoint: ENDPOINTS.usuarioPesquisar,
                                firstLabelToSelect: 'Codigo',
                                secoundLabelToSelect: 'Nome',
                                valuesToSelect: [
                                    'Codigo',
                                    'Nome',
                                    'TipoUsuario'
                                ],
                                queryTextFilter: 'Nome',
                                queryNumberFilter: 'Codigo'
                            }}
                        ></AutoCompleteSelect>
                    </div>
                    <div
                        className="col-12 col-sm-2 d-flex align-items-center"
                        style={{ position: 'relative' }}
                    >
                        <DisableForMobile>
                            <ContentButton>
                                <Button
                                    colorType="quintiary"
                                    icon="user-plus"
                                    onClick={() => {
                                        submit = false
                                    }}
                                >
                                    <b>Adicionar</b>
                                </Button>
                            </ContentButton>
                        </DisableForMobile>
                        <Center>
                            <IsMobile>
                                <ContentButton>
                                    <Button
                                        colorType="btnMobile"
                                        icon="user-plus"
                                        onClick={() => {
                                            submit = false
                                        }}
                                    >
                                        <b>Adicionar</b>
                                    </Button>
                                </ContentButton>
                            </IsMobile>
                        </Center>
                    </div>
                </div>
                <div className="col-6">
                    {flagErrorForm ? (
                        <p className="danger" style={{ marginTop: '15px' }}>
                            {messageError}
                        </p>
                    ) : null}
                </div>
                <DisableForMobile>
                    <div className="row" style={{ marginTop: '1.5rem' }}>
                        <div className="col-12">
                            <label htmlFor="">Lista de Viajantes</label>
                            <div
                                className="table-responsive"
                                style={{
                                    border: 'solid 1px lightgray',
                                    borderRadius: '15px'
                                }}
                            >
                                <table className="table">
                                    <thead
                                        style={{
                                            borderBottom: 'solid 1px lightgray'
                                        }}
                                    >
                                        <tr
                                            style={{
                                                border: '0 !important'
                                            }}
                                        >
                                            <Th
                                                style={{
                                                    borderBottomWidth:
                                                        '0 !important'
                                                }}
                                                scope="col"
                                                colSpan={2}
                                            >
                                                <Text>Código</Text>
                                            </Th>
                                            <Th scope="col" colSpan={2}>
                                                <Text>Nome</Text>
                                            </Th>
                                            <Th scope="col">
                                                <Text>Viajante Principal</Text>
                                            </Th>
                                            <Th scope="col">
                                                <Text>Deletar</Text>
                                            </Th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {viajantes?.map((object: any, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        <Text>
                                                            {object.Codigo}
                                                        </Text>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <Text>
                                                            {object.Nome}
                                                        </Text>
                                                    </td>
                                                    <td>
                                                        {!object.Convidado ? (
                                                            <ViajantePrincipalDiv
                                                                disabled={true}
                                                            >
                                                                Viajante
                                                                Principal
                                                            </ViajantePrincipalDiv>
                                                        ) : (
                                                            <TornarPrincipalBtn
                                                                type="button"
                                                                onClick={() => {
                                                                    let x = -1
                                                                    let y = -1
                                                                    const newViajantes =
                                                                        [
                                                                            ...viajantes
                                                                        ]
                                                                    newViajantes?.map(
                                                                        (
                                                                            e,
                                                                            key
                                                                        ) => {
                                                                            if (
                                                                                newViajantes[
                                                                                    key
                                                                                ]
                                                                                    .Convidado ===
                                                                                false
                                                                            ) {
                                                                                y =
                                                                                    key
                                                                            }
                                                                        }
                                                                    )
                                                                    newViajantes.forEach(
                                                                        (
                                                                            object,
                                                                            index
                                                                        ) => {
                                                                            if (
                                                                                index ===
                                                                                key
                                                                            ) {
                                                                                x =
                                                                                    key
                                                                            }
                                                                        }
                                                                    )
                                                                    BotaoTrocarPrincipal(
                                                                        x,
                                                                        y
                                                                    )
                                                                    setViajantes(
                                                                        newViajantes
                                                                    )
                                                                }}
                                                            >
                                                                Tornar Principal
                                                            </TornarPrincipalBtn>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <span
                                                            className="danger cursor-pointer"
                                                            onClick={() => {
                                                                const newViajantes =
                                                                    [
                                                                        ...viajantes
                                                                    ]
                                                                const convidado =
                                                                    newViajantes[
                                                                        key
                                                                    ].Convidado

                                                                const viajanteRemovido =
                                                                    newViajantes.splice(
                                                                        key,
                                                                        1
                                                                    )
                                                                if (
                                                                    !convidado &&
                                                                    newViajantes.length >
                                                                        0
                                                                ) {
                                                                    newViajantes[0].Convidado =
                                                                        false
                                                                    TornarPrincipal(
                                                                        newViajantes[0]
                                                                    )
                                                                }

                                                                DeletarViajante(
                                                                    viajanteRemovido[0]
                                                                )
                                                                setViajantes(
                                                                    newViajantes
                                                                )
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </DisableForMobile>
                <Center>
                    <IsMobile>{getTabelaMobile()}</IsMobile>
                </Center>
                <Space height="0.5rem"></Space>
                <Center>
                    <DisableForMobile>
                        <Button
                            colorType="quaternary"
                            icon="save"
                            onClick={() => {
                                submit = true
                            }}
                        >
                            Salvar
                        </Button>
                    </DisableForMobile>
                    <IsMobile>
                        <Button
                            colorType="btnMobile"
                            icon="save"
                            onClick={() => {
                                submit = true
                            }}
                        >
                            Salvar
                        </Button>
                    </IsMobile>
                </Center>
            </Form>
        </BlankBoard>
    )
}

const ContentButton = styled.div`
    button {
        margin-top: 15px;
        height: 40px;
        width: 160px;
        min-height: auto;
        min-width: auto;
    }

    @media (max-width: 768px) {
        width: 100%;
        button {
            width: 100%;
        }
    }
`
const ViajantePrincipalDiv = styled.button`
    background-color: #5787d0;
    padding: 0.8vh;
    color: white;
    font-size: 8pt;
    border-radius: 0.7vh;
    border: none;
    width: 15vh;
`
const TornarPrincipalBtn = styled.button`
    padding: 0.8vh;
    color: gray;
    font-size: 8pt;
    border-radius: 0.7vh;
    border: none;
    width: 15vh;
`
const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
`
const Text = styled.p``

import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import React, { useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router-dom'

import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Button } from '../../../../core/components/Button'
import { Input } from '../../../../core/components/Form/Input'
import { SelectRequester } from '../../../../core/components/Form/SelectWithRequest'
import { Header } from '../../../../core/components/Header/Header'
import { ENDPOINTS } from '../../../../services/config-http'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { Space } from '../../../../core/components/Space'
import { Center } from '../../../../core/components/Center'
import { Subtitle } from '../../../../core/components/Subtitle'
import { destroy, get, post, put } from '../../../../services/http'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Empty } from '../../../../core/components/Empty'
import { Servico } from '../../../../contexts/interfaces/servico'
import { Flex } from '../../../../core/components/Flex'
import { UserContext } from '../../../../contexts/UserContext'
import { Alert } from '../../../../core/components/Alert'
import { DisableForMobile, IsMobile } from '../stepMobile'
import {
    Box,
    Quadro,
    TdMobile,
    ThMobile,
    TrMobile,
    getNotFound
} from '../../../../core/components/Mobile'
import { Deletar } from '../../../../core/components/Deletar'

export const EditarServicoForm = () => {
    const { Id } = useParams() as any
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const [numPessoas, setTipoDespesa] = useState(1)
    const [servicosAdicionados, setservicosAdicionados] = useState<Array<any>>(
        []
    )
    const [tipoServicos, setTipoServicos] = useState<Array<any>>([])
    const [servicos, setServicos] = useState<Array<Servico>>([])
    const [servicosNovos, setServicosNovos] = useState<Array<Servico>>([])
    const [servicosAlterados, setServicosAlterados] = useState<Array<Servico>>(
        []
    )
    const [servicosRemovidos, setServicosRemovidos] = useState<Array<Servico>>(
        []
    )
    const [solicitacao, setSolicitacao] = useState<any>()
    const [editing, setEditing] = useState(false)
    const [editingKey, setEditingKey] = useState(-1)
    const [showServicos, setShowServicos] = useState(false)
    const [customErrorForm, setCustomErrorForm] = useState({
        show: false,
        message: ''
    })
    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })

    useEffect(() => {
        get(`${ENDPOINTS.solicitacaoViagemPorId}/${Id}`).then(
            async ({ data: { Model } }) => {
                setSolicitacao(Model)
            }
        )
    }, [])

    useEffect(() => {
        if (showServicos) {
            get(ENDPOINTS.servicoSolicitacaoById + Id)
                .then(({ data: { Model } }) => {
                    setServicos(Model)
                })
                .catch(err => {
                    setShowAlert({
                        status: true,
                        message: err.data,
                        type: 'danger'
                    })
                })
            const getTipoDespesa = async () => {
                await get(ENDPOINTS.servico)
                    .then(({ data: { Model } }) => {
                        setTipoServicos(Model)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            getTipoDespesa()
        }
    }, [showServicos])

    useEffect(() => {
        if (solicitacao?.TipoSolicitacao) {
            buscaRegra(
                solicitacao.TipoSolicitacao === 'Viagem Completa' ? 1 : 2
            )
        }
    }, [solicitacao])

    const buscaRegra = async (id: number) => {
        if (id !== 0) {
            await get(`${ENDPOINTS.regrasPoliticaViagem}/${id}`)
                .then(({ data: { Model } }) => {
                    const { UtilizarServicos } = Model
                    setShowServicos(UtilizarServicos)
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    function _pipeShowService(value: any) {
        const tipo = tipoServicos.filter(item => item.Id === value)[0]
        return <>{tipo?.Descricao || ''}</>
    }

    const handleFormSubmit: SubmitHandler = async (servico, { reset }) => {
        try {
            const schema = Yup.object().shape({
                IdServico: Yup.string().required(
                    'Escolha um tipo de serviço para adicionar!'
                ),
                Observacao: Yup.string().required(
                    'Digite uma observação para adicionar!'
                )
            })

            await schema.validate(servico, { abortEarly: false })

            let newServicos: Array<Servico> = []

            if (servicos !== undefined) newServicos = [...servicos]

            const servicoData: any = {
                IdServico: formRef.current?.getFieldValue('IdServico'),
                Observacao: formRef.current?.getFieldValue('Observacao'),
                IdSolicitacao: Id
            }
            if (editing && editingKey !== -1) {
                servicoData.Id = servicos[editingKey].Id
                servicoData.IdAprovador = servicos[editingKey].IdAprovador
                _alterarServico(servicoData)
            } else {
                newServicos.push(servicoData)
                _adicionarServico(servicoData)
            }
            setServicos([...newServicos])

            formRef.current?.setErrors({})
            reset()
            setEditing(false)
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                formRef.current?.setErrors(erros)
            }
            console.log(err)
        }
    }

    function _UpdateFormRef(value: Servico) {
        formRef.current?.setData(value)
        setTipoDespesa(value.IdServico ?? 1)
    }

    function ChecarSeExiste(
        listaHospedagem: Servico[],
        hospedagemParaChecar: Servico
    ) {
        let existe = -1
        listaHospedagem?.map((e: any, key: number) => {
            if (
                listaHospedagem[key].Observacao ===
                    hospedagemParaChecar.Observacao &&
                listaHospedagem[key].IdServico ===
                    hospedagemParaChecar.IdServico
            ) {
                existe = key
            }
        })
        return existe // retorna -1 caso não exista
    }

    function _adicionarServico(servicoRecebido: Servico) {
        const existeRemovidos = ChecarSeExiste(
            servicosRemovidos,
            servicoRecebido
        )

        if (existeRemovidos === -1) {
            // caso nao seja uma hospedagem removida
            const existeAlterados = ChecarSeExiste(
                servicosAlterados,
                servicoRecebido
            )
            if (existeAlterados === -1) {
                // caso nao seja uma hospedagem alterada
                servicosNovos.push(servicoRecebido)
                setServicosNovos(servicosNovos)
            } else {
                servicosAlterados.splice(existeAlterados, 1)
                setServicosAlterados(servicosAlterados)
            }
        } else {
            const existeAlterados = ChecarSeExiste(
                servicosAlterados,
                servicoRecebido
            )
            if (existeAlterados === -1) {
                // caso nao seja uma hospedagem alterada
                servicosRemovidos.splice(existeAlterados, 1)
                setServicosRemovidos(servicosRemovidos)
            } else {
                servicosRemovidos?.map((e: any, key: number) => {
                    if (
                        servicosRemovidos[key].Observacao ===
                            servicoRecebido.Observacao &&
                        servicosRemovidos[key].IdServico ===
                            servicoRecebido.IdServico
                    ) {
                        servicosAlterados.splice(existeRemovidos, 1)
                        setServicosAlterados(servicosAlterados)
                        servicosRemovidos.splice(existeRemovidos, 1)
                        setServicosRemovidos(servicosRemovidos)
                    }
                })
            }
        }
        setServicos(servicos)
    }

    function _alterarServico(servicoRecebido: Servico) {
        const existeNovos = ChecarSeExiste(servicosNovos, servicoRecebido)

        if (existeNovos === -1) {
            // se não for uma hospedagem nova
            const existeAlterados = ChecarSeExiste(
                servicosAlterados,
                servicoRecebido
            )

            if (existeAlterados === -1) {
                // se nao for uma hospedagem alterada
                if (
                    servicos[editingKey].Observacao !==
                        servicoRecebido.Observacao ||
                    servicos[editingKey].IdServico !== servicoRecebido.IdServico
                ) {
                    servicos[editingKey].IdServico = servicoRecebido.IdServico
                    servicos[editingKey].Observacao = servicoRecebido.Observacao

                    servicosAlterados.push(servicoRecebido)
                    setServicosAlterados(servicosAlterados)
                }
            } else {
                servicosAlterados.splice(existeAlterados, 1)
                setServicosAlterados(servicosAlterados)
            }
        }
    }

    function _removerServico(servicoRecebido: Servico, index: number) {
        const existeNovos = servicosNovos.indexOf(servicoRecebido)

        setEditing(false)
        setEditingKey(-1)
        formRef.current?.reset()

        if (existeNovos === -1) {
            // se nao for uma hospedagem nova

            const hasHospedagem = servicosRemovidos.some(
                i => i.IdServico === servicoRecebido.IdServico
            )
            if (hasHospedagem === false) {
                servicosRemovidos.push(servicoRecebido)
                setServicosRemovidos(servicosRemovidos)
            }
        } else {
            servicosNovos.splice(existeNovos, 1)
            setServicosNovos(servicosNovos)
        }

        const existeAlterados = servicosAlterados.indexOf(servicoRecebido)
        if (existeAlterados !== -1) {
            // caso seja uma hospedagem alterada
            servicosAlterados.splice(existeAlterados, 1)
            setServicosAlterados(servicosAlterados)
        }
    }

    const SubmitInformation = async () => {
        try {
            servicosNovos.forEach(element => {
                post(ENDPOINTS.servicoSolicitacao, {
                    idSolicitacaoViagem: element.IdSolicitacao,
                    idServico: element.IdServico,
                    observacao: element.Observacao
                })
                    .then(res => {
                        console.log(res)
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
            })
            servicosAlterados.forEach(element => {
                console.log(element)
                put(ENDPOINTS.servicoSolicitacao, {
                    id: element.Id,
                    idSolicitacaoViagem: Number(element.IdSolicitacao),
                    idServico: element.IdServico,
                    observacao: element.Observacao
                })
                    .then(res => {
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
            })
            servicosRemovidos.forEach(element => {
                console.log(element)
                destroy(`${ENDPOINTS.servicoSolicitacao}/${element.Id}`)
                    .then(res => {
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
            })
            history.push(`/solicitacoes/editar/${Id}`)
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                formRef.current?.setErrors(erros)
            }
        }
    }
    function getCampo(index: number, title: string, valor: any) {
        return (
            <div
                style={{
                    flexDirection: 'column'
                }}
            >
                <ThMobile key={index}>{title}</ThMobile>
                <TdMobile
                    key={index}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {valor}
                </TdMobile>
            </div>
        )
    }

    function getTabelaMobile() {
        return (
            <Center>
                <Box>
                    {showServicos && servicos.length ? (
                        servicos.map((tableItem, index) => {
                            if (tableItem) {
                                return (
                                    <Quadro>
                                        <table style={{ width: '100%' }}>
                                            <TrMobile>
                                                {getCampo(
                                                    index,
                                                    'Serviço',
                                                    _pipeShowService(
                                                        tableItem.IdServico
                                                    )
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Observação',
                                                    tableItem.Observacao
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Remover',
                                                    <Deletar
                                                        remover={() => {
                                                            const newServicos =
                                                                [...servicos]

                                                            const servicoRemovido =
                                                                newServicos.splice(
                                                                    index,
                                                                    1
                                                                )

                                                            _removerServico(
                                                                servicoRemovido[0],
                                                                index
                                                            )

                                                            setServicos(
                                                                newServicos
                                                            )
                                                        }}
                                                    />
                                                )}
                                            </TrMobile>
                                        </table>
                                    </Quadro>
                                )
                            }
                            return null
                        })
                    ) : showServicos ? (
                        <Center>
                            {getNotFound('Nenhumm serviço adicionado')}
                        </Center>
                    ) : (
                        <Center>
                            {getNotFound('Consulte as politicas de viagens')}
                        </Center>
                    )}
                </Box>
            </Center>
        )
    }

    return (
        <BlankBoard>
            <Header
                title="Alteração de Serviços"
                actionArrowClick={() => {
                    history.push(`/solicitacoes/editar/${Id}`)
                }}
            ></Header>
            <Space height="2rem"></Space>
            <Subtitle
                style={{
                    textAlign: 'center',
                    margin: '1rem 0'
                }}
            >
                Dados de Serviços
            </Subtitle>
            <Space height="2rem"></Space>

            {showServicos ? (
                <>
                    <Form onSubmit={handleFormSubmit} ref={formRef}>
                        <div className="row">
                            <div className="col-12 col-md-5">
                                <SelectRequester
                                    name="IdServico"
                                    label="Qual Serviço?"
                                    placeholder="Escolha um serviço"
                                    required={true}
                                    request={{
                                        endpoint: ENDPOINTS.servico,
                                        labelToSelect: 'Descricao',
                                        valueToSelect: 'Id'
                                    }}
                                ></SelectRequester>
                            </div>
                            <div className="col-12 col-md-7">
                                <Input
                                    name="Observacao"
                                    label="Observação"
                                    placeholder="Por que você precisa deste serviço?"
                                    required={true}
                                ></Input>
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                                {editing ? (
                                    <div className="col-12 col-sm-3">
                                        <Flex justifyContent="flex-end">
                                            <Button
                                                style={{
                                                    width: '80%',
                                                    minWidth: 'auto'
                                                }}
                                                colorType="secondary"
                                                icon="ban"
                                                onClick={() => {
                                                    setEditing(false)
                                                    setEditingKey(-1)
                                                    formRef.current?.reset()
                                                }}
                                            >
                                                <b>Cancelar</b>
                                            </Button>
                                        </Flex>
                                    </div>
                                ) : null}
                                <div className="col-12 col-sm-3">
                                    <Flex justifyContent="flex-end">
                                        <DisableForMobile>
                                            <Button
                                                style={{
                                                    width: '80%',
                                                    minWidth: 'auto'
                                                }}
                                                colorType="quintiary"
                                                icon={editing ? 'save' : 'plus'}
                                            >
                                                {editing ? (
                                                    <b>Salvar</b>
                                                ) : (
                                                    <b>Adicionar</b>
                                                )}
                                            </Button>
                                        </DisableForMobile>
                                        <Center>
                                            <IsMobile>
                                                <Button
                                                    colorType="btnMobile"
                                                    icon={
                                                        editing
                                                            ? 'save'
                                                            : 'plus'
                                                    }
                                                >
                                                    {editing ? (
                                                        <b>Salvar</b>
                                                    ) : (
                                                        <b>Adicionar</b>
                                                    )}
                                                </Button>
                                            </IsMobile>
                                        </Center>
                                    </Flex>
                                </div>
                            </div>
                        </div>
                    </Form>
                </>
            ) : (
                <></>
            )}
            <div className="col-6">
                {customErrorForm.show ? (
                    <p className="danger" style={{ marginTop: '15px' }}>
                        {customErrorForm.message}
                    </p>
                ) : null}
            </div>
            <DisableForMobile>
                {showServicos && servicos?.length ? (
                    <div className="row" style={{ marginTop: '3rem' }}>
                        <div className="col-12">
                            <label htmlFor=""></label>
                            <div
                                className="table-responsive"
                                style={{
                                    border: 'solid 1px lightgray',
                                    borderRadius: '15px'
                                }}
                            >
                                <table className="table">
                                    <thead
                                        style={{
                                            borderBottom: 'solid 1px lightgray'
                                        }}
                                    >
                                        <tr
                                            style={{
                                                border: '0 !important'
                                            }}
                                        >
                                            <Th className="col-1" scope="col">
                                                <Text>#</Text>
                                            </Th>
                                            <Th scope="col" className="col-3">
                                                <Text>Tipo</Text>
                                            </Th>
                                            <Th scope="col" className="col-3">
                                                <Text>Observação</Text>
                                            </Th>
                                            <Th
                                                scope="col"
                                                className="col-1"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <Text>Editar</Text>
                                            </Th>
                                            <Th scope="col" className="col-1">
                                                <Text>Deletar</Text>
                                            </Th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {servicos.map((item, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td className="col-1">
                                                        <Text>{key + 1}</Text>
                                                    </td>
                                                    <td className="col-3">
                                                        <Text>
                                                            {_pipeShowService(
                                                                item.IdServico
                                                            )}
                                                        </Text>
                                                    </td>
                                                    <td className="col-3">
                                                        <Text>
                                                            {item.Observacao}
                                                        </Text>
                                                    </td>

                                                    <td
                                                        className="col-1 cursor-pointer"
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <span
                                                            onClick={() => {
                                                                setEditing(true)
                                                                setEditingKey(
                                                                    key
                                                                )

                                                                _UpdateFormRef(
                                                                    servicos[
                                                                        key
                                                                    ]
                                                                )
                                                                formRef.current?.setErrors(
                                                                    {}
                                                                )
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon="edit"></FontAwesomeIcon>
                                                        </span>
                                                    </td>
                                                    <td className="col-1 danger cursor-pointer">
                                                        <span
                                                            onClick={() => {
                                                                const newServicos =
                                                                    [
                                                                        ...servicos
                                                                    ]

                                                                const servicoRemovido =
                                                                    newServicos.splice(
                                                                        key,
                                                                        1
                                                                    )

                                                                _removerServico(
                                                                    servicoRemovido[0],
                                                                    key
                                                                )

                                                                setServicos(
                                                                    newServicos
                                                                )
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ) : showServicos ? (
                    <>
                        <Empty text="Nenhumm serviço adicionado" />
                    </>
                ) : (
                    <>
                        <Alert
                            fixed={true}
                            icon="exclamation-circle"
                            type="primary"
                            message="Consulte as politicas de viagens"
                        ></Alert>
                    </>
                )}
            </DisableForMobile>

            <IsMobile>{getTabelaMobile()}</IsMobile>
            <Space height="2rem"></Space>
            <Center>
                <DisableForMobile>
                    <Button
                        icon="save"
                        colorType="quaternary"
                        onClick={() => SubmitInformation()}
                    >
                        Salvar
                    </Button>
                </DisableForMobile>
                <IsMobile>
                    <Button
                        icon="save"
                        colorType="btnMobile"
                        onClick={() => SubmitInformation()}
                    >
                        Salvar
                    </Button>
                </IsMobile>
            </Center>
        </BlankBoard>
    )
}

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
    text-align: 'center';
`
const Text = styled.p``

import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}

body {
    background: ${props => props.theme.colors.background};
    font-size: 75.5%;
    color: ${props => props.theme.colors.text};
    font-family: 'Montserrat', sans-serif !important;
}

p{
    color: #6c7480;
    font-size: 0.9375rem;
    font: 'Montserrat, sas-serif';
    padding: 0;
    margin: 0;
}

h1, h2, h3, a{
    color: ${props => props.theme.colors.primary};
}
h1{
    font-weight: 700;
    font-size: 2rem;
}
h2, h4, h5, h6{
    color : ${props => props.theme.colors.secondary}
}
h2{
    font-weight: 400;
    font-size: 1.4rem;
}
a:hover{
    color : ${props => props.theme.colors.white}
}
label{
    font-size: 14px;
    font-weight: 600;
}

form{
    /* padding: 0 10% */
}

/* Margins */

.margin-auto{
    margin: auto;
}


/* Text-size */

.f-size-08rem{
    font-size: 0.8rem;
}

.f-size-09rem{
    font-size: 0.9rem;
}

.f-size-1rem{
    font-size: 1rem;
}

.f-size-12rem{
    font-size: 1.2rem;
}

/* Displays */

.hidden-md{
    @media (max-width: 768px) {
        display: none;
    }
}

.hidden-sm{
    @media (max-width: 576px) {
        display: none;
    }
}

.hidden-lg{
    @media (max-width: 992px) {
        display: none;
    }
}

.show-sm{
    @media (max-width: 576px) {
        display: unset !important;
    }

}


/* class colors */

.bg-select-blue{
    background-color: ${props => props.theme.colors.selectBlue}
}
.bg-white{
    background-color: ${props => props.theme.colors.white}
}

.status-success{
    background-color: #85ED7C !important
}
.status-pendding{
    background-color: #FFDD63 !important
}
.status-danger{
    background-color: #F05A5A !important
}
.status-cancel{
    background-color: #525252 !important
}
.page-link{
    color : ${props => props.theme.colors.primary};
    background-color: #f6f6f6;
    font-size: 0.9rem
}

.hvn-nav{
    width: 100%;
    background-color: #FFF;
    position: fixed;
    box-shadow: 1px 0 15px #80808061;
    z-index: 999;
    
    .btn{
        min-width: unset;
        min-height: unset;
    }
    .hvn-logo{
        background-color: ${props => props.theme.colors.primary}
    }
}
.offcanvas-body{
    background-color: #002a5d
}

.modal-header{
    color: ${props => props.theme.colors.primary}
}


.invisible-item{
    display: none !important;
}
.body-google-place{
    width: 100%;
    font-size: 0.8rem;
    box-shadow: 0 2px 12px 0px #8080802b;
}
.place-item{
    padding: 1rem,
}

.cursor-pointer{
    cursor: pointer;
}

.dataPickCustom{
    border-radius: .25rem;
}

.customInput::placeholder{
    color:  ${props => props.theme.colors.placeholder};
    font-size: 0.9rem
}

.customInput{
    background-color: ${props => props.theme.colors.lightGray};
    border: 1px solid ${props => props.theme.colors.border};
}

.error-text{
    color : ${props => props.theme.colors.danger}
}

.primary-text{
    color : ${props => props.theme.colors.primary}
}

.rightBallPrimary{
    background-color: ${props => props.theme.colors.secondary};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 4px;
}
.rightBallSecundary{
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 4px;
}
.chevronRight{
    color: ${props => props.theme.colors.primary};
}
.buttonBack{
    position: relative;
    background-color: #83CBFF;
    border: 0;
    border-radius: 33.5px;
    color: white;
    width: 210px;
    height: 54px;
}

.input-range__track--active, .input-range__slider{
    background-color: ${props => props.theme.colors.secondary};
    border-color: ${props => props.theme.colors.secondary}
}

.form-check-input, .form-switch {
    height: 1.8em;
    width: 4em !important;
}
.form-check-input:checked{
    background-color: ${props => props.theme.colors.primary};
    border: 0
}
.form-control:focus {
    border-color: ${props => props.theme.colors.lightBlue} !important;
    outline: 0;
    box-shadow: 0px 3px 3px 0 rgb(143 143 143 / 25%);
}

.react-datepicker-wrapper{
    width: 100%
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    font-size: 0.9375rem;
    font: 'Montserrat, sas-serif';
}

.dataPickCustom{
    color: ${props => props.theme.colors.text};
}

table, .table {
    margin-bottom: 0 !important;
}
thead{
    height: 47px;
    background: #f6f6f6;
}
table tbody {
  display: block;
  max-height: 415px;
  overflow-y: auto;
}

table thead, table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.table>:not(:first-child){
    border-top: 2px solid lightgrey;
}

th:first-child,td:first-child {
    width: 10%;
    text-align: center;
    @media (max-width: 768px) {
        width: unset;
        text-align: start;
        padding-left: 1rem !important;
    }
}
th:last-child,td:last-child {
    text-align: center;
}

.btn{
    border: 0;
    border-radius: 30px;
    min-width: 14rem;
    min-height: 3rem;
    font-size: 0.8rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center
}
.btn-clean{
    border: 0;
    background: unset;
    color: ${props => props.theme.colors.primary};
    font-size: 1.09rem;
}

.page-link:focus{
    box-shadow: unset !important
}

.gray-text{
    color : ${props => props.theme.colors.metaText}
}

.danger{
    color : ${props => props.theme.colors.danger}
}

.input-danger{
    border: 1px solid ${props => props.theme.colors.danger} !important;
}
input::-webkit-calendar-picker-indicator {
  display: none;
  opacity: 0;
}


.btn-primary{
    background-color: ${props => props.theme.colors.primary};
}

.btn-primary:active, .btn-primary:hover{
    background-color: ${props => props.theme.colors.primary};
    border: 0;
}
.btn-check:focus+.btn-primary, .btn-primary:focus{
    box-shadow: none
}
.btn-check:focus+.btn-primary, .btn-primary:focus{
    background-color: ${props => props.theme.colors.primary};
    border: 0
}

.btn-secondary{
    justify-content: center;
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0px 2px 7px 0px #3f3f3f40;
    color: ${props => props.theme.colors.primary};
}

.btn-secondary:active, .btn-secondary:hover, .btn-check:focus+.btn-secondary, .btn-secondary:focus{
    background-color: #f9fafa;
    color: ${props => props.theme.colors.primary};
    border:0;
    box-shadow: 0px 2px 3px 0px #59595940;
}
.btn-secondary:hover{
    margin-bottom: 0rem;
    transition: .1s ease-in-out;
    box-shadow: 0px 2px 3px 0px #59595940;
}
.btn-check:active+.btn-secondary:focus, .btn-check:checked+.btn-secondary:focus, .btn-secondary.active:focus, .btn-secondary:active:focus, .show>.btn-secondary.dropdown-toggle:focus{
    box-shadow: 0px 2px 3px 0px #59595940;
}

.btn-no-focus:focus {
  outline: none !important;
  box-shadow: none !important;
}

.alert-primary{
    background-color: ${props => props.theme.colors.selectBlue};
    border: 0;
    color: #FF7373;
    background: #FFDEDE;
}

.alert-secondary{
    background-color: ${props => props.theme.colors.selectBlue};
    border: 0;
    color: #6B8CB6;
    background: #DBEBFF;
    font-size: 0.9375rem;
    font: 'Montserrat, sas-serif';
}

.alert-exclamation{
    background-color: ${props => props.theme.colors.selectBlue};
    border: 0;
    color: #896701;
    background: #ffd75e;
    font-size: 0.9375rem;
    font: 'Montserrat, sas-serif';
}

.select-arrow{
    position: absolute;
    right: 0.8rem;
    top: 1.8rem;
    font-size: 1.1rem;
    color: ${props => props.theme.colors.primary}
}

.react-select__control{
    background-color: ${props => props.theme.colors.lightGray};
    border: 1px solid ${props => props.theme.colors.border} !important;
    font-size: 0.9375rem;
    font: 'Montserrat, sas-serif';
}

.react-select__control--is-focused, .react-select__control--menu-is-open{
    box-shadow: 0 !important
    font-size: 0.9375rem;
    font: 'Montserrat, sas-serif';
}
.InputBs{
    box-shadow: 0 !important
    font-size: 0.9375rem;
    font: 'Montserrat, sas-serif';  
}
.Container{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
}
.card-item{
    box-shadow: 0px 4px 9px 0px #80808042;
    border-radius: 5px;
    padding: 2rem 0rem;
    transition: 0.2s ease-in-out;
}
.card-item:hover{
    transform: translateY(-14px);
}


.badge{
    font-size: .80em;
    font-weight: 600;
    /* width: 200px; */
    height: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}
.text-danger{
    color: #F19C97;
}
.no-link{
    cursor: pointer;
    text-decoration: none
}
.no-list-style > li{
    list-style-type: none;
}
.rightLine{
    border-right: 1px solid #E8E8E8;
}
.no-show{
    display: none;
}
.text-rote{
    transform: rotate(90deg)
}
.theflex{
    display: flex;
}
.opsall{
    display: none;
    background-color: #e3e3e3;
    border: 0;
    padding: 10px 20px;
    clip-path: polygon(84% 89%, 84% 17%, 8% 17%, 3% 0, 0 16%, 0 89%);
}
.opsmin{
    position: relative;
    background-color: #CCC;
    padding: 4px;
    width: 100px;
    height: 25px;
    left: 9px;
    top: -22px;
    // z-index: 2;
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
}
.text-rotez{
    transform: rotate(0deg)
}
.bg-success{
    background-color: ${props => props.theme.colors.success} !important
}
.bg-warning{
    background-color: ${props => props.theme.colors.warnning} !important
}
.bg-danger{
    background-color: ${props => props.theme.colors.danger} !important
}
.lightCyan{
    color: ${props => props.theme.colors.lightCyan}
}

.removed-focus:focus{
    box-shadow: unset
}

.meta-text{
    color: ${props => props.theme.colors.placeholder};
    font-weight: 600;
    font-size: 1.2rem;
}

.react-select__menu{
    // z-index: 1545456465;
    font-size: 0.9375rem;
    font: 'Montserrat, sas-serif';
}

.form-control:disabled{
    background-color: #f2f2f2;
    color: #838383;
}

.scale-in-ver-top {
	-webkit-animation: scale-in-ver-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-ver-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}



@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
// SCROLL BAR
::-webkit-scrollbar{
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-thumb{
    background: #dedede;
    width:5px
}
::-webkit-scrollbar-thumb:hover{
    background: #c7c7c7;
}
::-webkit-scrollbar-track{
    background: white;
    border-radius: 0px;
    width: 5px;
    border: solid 1px #ECECEC;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
}

.d-tree-container{
    padding-left: 1.2rem !important;
}


.progress {
    width: 150px;
    height: 150px !important;
    float: left;
    line-height: 150px;
    background: none;
    margin: 20px;
    box-shadow: none;
    position: relative
}

.progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid #fff;
    position: absolute;
    top: 0;
    left: 0
}

.progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1
}

.progress .progress-left {
    left: 0
}

.progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0
}

.progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left
}

.progress .progress-right {
    right: 0
}

.progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards
}

.d-tree-node{
    list-style-type: none
}

.progress .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: #000;
    font-size: 24px;
    color: #fff;
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%
}

.progress.blue .progress-bar {
    border-color: #049dff
}

.progress.blue .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.8s
}

.progress.yellow .progress-bar {
    border-color: #fdba04
}

.progress.yellow .progress-right .progress-bar {
    animation: loading-3 1.8s linear forwards
}

.progress.yellow .progress-left .progress-bar {
    animation: none
}

.hvn-sub-menu {
        background-color: #002a5d;
}

.hvn-menu, .hvn-menu a, .hvn-user-links, .hvn-user-links a, .hvn-user-links button {
        color: #fff;
        background-color: #002a5d;
}

.hvn-sub-menu a:hover, .hvn-user-links a:hover, .hvn-user-links button:hover {
        color: #FFF;
        background-color: #004aa3;
}

.hvn-menu a:hover {
        color: #FFF;
        background-color: #004aa3;
}

@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg)
    }
}

@keyframes loading-3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg)
    }
}

/**
 * ----------------------------------------
 * animation slide-in-elliptic-right-fwd
 * ----------------------------------------
 */

 .slide-in-elliptic-right-fwd {
	-webkit-animation: slide-in-elliptic-right-fwd 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-right-fwd 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-in-elliptic-right-fwd {
  0% {
    -webkit-transform: translateX(800px) rotateY(-30deg) scale(0);
            transform: translateX(800px) rotateY(-30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -1800px 50%;
            transform-origin: -1800px 50%;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-right-fwd {
  0% {
    -webkit-transform: translateX(800px) rotateY(-30deg) scale(0);
            transform: translateX(800px) rotateY(-30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -1800px 50%;
            transform-origin: -1800px 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-right
 * ----------------------------------------
 */

 .slide-out-right {
	-webkit-animation: slide-out-right 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-right 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

 @-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
/**
 * ----------------------------------------
 * animation Check
 * ----------------------------------------
 */

@keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px ${props =>
            props.theme.colors.primary}
    }
}
`

import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Center } from '../../../core/components/Center'
import { Header } from '../../../core/components/Header/Header'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Input } from '../../../core/components/Form/Input'
import { Select } from '../../../core/components/Form/Select'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { Usuario } from '../../../contexts/interfaces/usuario'
import { DatePicker } from '../../../core/components/DatePicker'
import { Button } from '../../../core/components/Button'
import { ENDPOINTS } from '../../../services/config-http'
import { put, get, post } from '../../../services/http'
import { Alert } from '../../../core/components/Alert'
import { Subtitle } from '../../../core/components/Subtitle'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { SelectMapper } from '../../../core/utils/SelectMapper'
import { Input as InputMask } from '../../../core/components/Form/v2/InputMask'
import { Space } from '../../../core/components/Space'
import { Switch } from '../../../core/components/Switch'
import { useSpinner } from '../../../hooks/Spinner'

export const Usuarios = () => {
    const history = useHistory()
    const FormRef = useRef<FormHandles>(null)
    const cpfRef = useRef(null) as any
    const telefoneRef = useRef(null) as any
    const { Id } = useParams() as any
    const [politicaViagem, setPoliticaViagem] = useState([])
    const [options, setOptions] = useState([])
    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })

    const [administrador, setAdministrador] = useState(false)
    const [agenteViagem, setAgenteViagem] = useState(false)
    const [aprovaPrestacao, setAprovaPrestacao] = useState(false)
    const [aprovaViagem, setAprovaViagem] = useState(false)
    const [usuario, setUsuario] = useState<Usuario>()
    const { setLoading } = useSpinner()

    useEffect(() => {
        setOptions(
            SelectMapper(
                [
                    { value: 0, label: 'Masculino' },
                    { value: 1, label: 'Feminino' }
                ],
                'label',
                'value'
            )
        )
        get(ENDPOINTS.politicaViagem)
            .then(({ data: { Model } }) => {
                setPoliticaViagem(SelectMapper(Model, 'Nome', 'Id'))
            })
            .catch(err => {
                console.error(err)
            })
        get(`${ENDPOINTS.usuario}/${Id}`)
            .then(({ data: { Model } }) => {
                if (Model) {
                    setUsuario(Model)
                    FormRef.current?.setData(Model)
                    const {
                        IdFilial,
                        IdSetor,
                        Administrador,
                        AprovaViagem,
                        AprovaPrestacao,
                        AgenteViagem
                    } = Model

                    setAdministrador(Administrador)
                    setAprovaViagem(AprovaViagem)
                    setAprovaPrestacao(AprovaPrestacao)
                    setAgenteViagem(AgenteViagem)

                    get(`${ENDPOINTS.getFilialById}${IdFilial}`)
                        .then(({ data: { Model } }) => {
                            FormRef.current?.setFieldValue(
                                'IdFilial',
                                Model.Nome
                            )
                        })
                        .catch(err => {
                            setShowAlert({
                                status: true,
                                message: `${err.status} - Erro ao buscar os dados da Filial`,
                                type: 'danger'
                            })
                        })
                    get(`${ENDPOINTS.getSetorById}${IdSetor}`)
                        .then(({ data: { Model } }) => {
                            FormRef.current?.setFieldValue(
                                'IdSetor',
                                Model.Nome
                            )
                        })
                        .catch(err => {
                            setShowAlert({
                                status: true,
                                message: `${err.status} - Erro ao buscar os dados da Filial`,
                                type: 'danger'
                            })
                        })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    const handleSubmit: SubmitHandler<Usuario> = async (
        dadosForm,
        { reset }
    ) => {
        try {
            setLoading(true)
            const schema = Yup.object().shape({
                Nome: Yup.string().required('O Campo nome é obrigatório'),
                Cpf: Yup.string().required('O Campo CPF é obrigatório'),
                Genero: Yup.string().required('O Campo Gênero é obrigatório'),
                DataNascimento: Yup.string().required(
                    'O Campo Data de Nascimento é obrigatório'
                ),
                IdPoliticaViagem: Yup.string().required(
                    'A politica é obrigatória'
                )
            })

            await schema.validate(dadosForm, { abortEarly: false })
            const newUsuario = { ...usuario }
            const { IdPoliticaViagem } = dadosForm
            newUsuario.IdPoliticaViagem = IdPoliticaViagem
            newUsuario.Administrador = administrador
            newUsuario.AgenteViagem = agenteViagem
            newUsuario.AprovaPrestacao = aprovaPrestacao
            newUsuario.AprovaViagem = aprovaViagem

            put(ENDPOINTS.usuario, newUsuario)
                .then(res => {
                    setShowAlert({
                        status: true,
                        message: res.data,
                        type: 'success'
                    })
                    setTimeout(() => {
                        setLoading(false)
                        history.push(`/cadastros/usuario`)
                    }, 1000)
                })
                .catch(err => {
                    setShowAlert({
                        status: true,
                        message: err.data.Text,
                        type: 'danger'
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                FormRef.current?.setErrors(erros)
            }
        }
    }
    return (
        <>
            <BlankBoard>
                <Alert
                    message={showAlert.message}
                    status={showAlert.status}
                    type={showAlert.type as any}
                ></Alert>
                <Header
                    title="Edição de Usuário"
                    actionArrowClick="/cadastros/usuario"
                ></Header>
                <Center>
                    <Subtitle>Dados do colaborador</Subtitle>
                </Center>
                <Form ref={FormRef} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12 col-sm-2">
                            <Input
                                disabled={true}
                                name="Codigo"
                                label="Codigo"
                                required={true}
                            ></Input>
                        </div>
                        <div className="col-12 col-sm-5">
                            <Input
                                disabled={true}
                                name="IdFilial"
                                label="Filial"
                                required={true}
                            ></Input>
                        </div>
                        <div className="col-12 col-sm-5">
                            <Input
                                disabled={true}
                                name="IdSetor"
                                label="Setor"
                                required={true}
                            ></Input>
                        </div>
                        <div className="col-12 col-sm-7">
                            <Input
                                disabled={true}
                                name="Nome"
                                label="Nome"
                                required={true}
                                placeholder="Digite o nome"
                            ></Input>
                        </div>
                        <div className="col-12 col-sm-5">
                            <Input
                                disabled={true}
                                name="Email"
                                label="E-mail"
                                required={false}
                                placeholder="Digite o email"
                            ></Input>
                        </div>
                        <div className="col-12 col-sm-2">
                            <DatePicker
                                disabled={true}
                                required={true}
                                name="DataNascimento"
                                label="Data de Nascimento"
                            ></DatePicker>
                        </div>
                        <div className="col12 col-sm-1">
                            <Input
                                disabled={true}
                                name="DDD"
                                label="DDD"
                                required={false}
                            ></Input>
                        </div>
                        <div className="col12 col-sm-4">
                            <Input
                                disabled={true}
                                // mask="99999-9999"
                                name="Telefone"
                                label="Telefone"
                                required={true}
                                placeholder="Digite o número de telefone"
                                // inputRef={telefoneRef}
                            ></Input>
                        </div>
                        <div className="col12 col-sm-2">
                            <Select
                                disabled={true}
                                name="Genero"
                                className="scale-in-ver-top"
                                options={options}
                                required={true}
                                placeholder="Selecione o genero"
                            ></Select>
                        </div>
                        <div className="col12 col-sm-3">
                            <Select
                                required={true}
                                name="IdPoliticaViagem"
                                label="Política de Viagem"
                                className="scale-in-ver-top"
                                defaultOption={politicaViagem.filter(
                                    (i: any) => {
                                        if (
                                            i.value ===
                                            usuario?.IdPoliticaViagem
                                        )
                                            return i
                                    }
                                )}
                                options={politicaViagem}
                                placeholder="Selecione a política"
                            ></Select>
                        </div>
                        <div className="col-12 col-sm-2">
                            <Switch
                                required={true}
                                defaultChecked={false}
                                checked={administrador}
                                onChange={e => {
                                    setAdministrador(!administrador)
                                }}
                                name="Administrador"
                                label="Administrador"
                            ></Switch>
                        </div>
                        <div className="col-12 col-sm-2">
                            <Switch
                                required={true}
                                defaultChecked={false}
                                checked={aprovaViagem}
                                onChange={() => {
                                    setAprovaViagem(!aprovaViagem)
                                }}
                                name="AprovaViagem"
                                label="Aprova Viagem"
                            ></Switch>
                        </div>
                        <div className="col-12 col-sm-2">
                            <Switch
                                required={true}
                                defaultChecked={false}
                                checked={aprovaPrestacao}
                                onChange={() => {
                                    setAprovaPrestacao(!aprovaPrestacao)
                                }}
                                name="AprovaPrestacao"
                                label="Aprova Prestacao"
                            ></Switch>
                        </div>
                        <div className="col-12 col-sm-2">
                            <Switch
                                required={true}
                                defaultChecked={false}
                                checked={agenteViagem}
                                onChange={() => {
                                    setAgenteViagem(!agenteViagem)
                                }}
                                name="AgenteViagem"
                                label="Agente de Viagem"
                            ></Switch>
                        </div>
                        <LineSeparator></LineSeparator>
                        <Center>
                            <Subtitle>Documentos</Subtitle>
                        </Center>
                        <div className="col12 col-sm-4">
                            <InputMask
                                disabled={true}
                                mask="999-999-999-99"
                                name="Cpf"
                                label="CPF"
                                required={true}
                                placeholder="Digite o número do CPF"
                                inputRef={cpfRef}
                            ></InputMask>
                        </div>
                        <div className="col12 col-sm-4">
                            <Input
                                disabled={true}
                                name="Rg"
                                label="RG"
                                required={false}
                                placeholder="Digite o número do RG"
                            ></Input>
                        </div>
                        <div className="col12 col-sm-4">
                            <Input
                                disabled={true}
                                name="Cnh"
                                label="CNH"
                                required={false}
                                placeholder="Digite o número da CNH"
                            ></Input>
                        </div>
                        <div className="col12 col-sm-8">
                            <Input
                                disabled={true}
                                name="Passaporte"
                                label="Passaporte"
                                required={false}
                                placeholder="Digite o número do Passaporte"
                            ></Input>
                        </div>
                        <div className="col12 col-sm-4">
                            <DatePicker
                                disabled={true}
                                name="ValidadePassaporte"
                                label="Validade do Passaporte"
                            ></DatePicker>
                        </div>

                        <Space height="4rem"></Space>
                        <Center>
                            <Button
                                colorType="quaternary"
                                type="submit"
                                icon="check"
                            >
                                Salvar
                            </Button>
                        </Center>
                    </div>
                </Form>
            </BlankBoard>
        </>
    )
}

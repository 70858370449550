import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { get } from '../../../services/http'
import { ENDPOINTS } from '../../../services/config-http'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Header } from '../../../core/components/Header/Header'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Subtitle } from '../../../core/components/Subtitle'
import { Center } from '../../../core/components/Center'

const useStyles = makeStyles(theme => ({
    paper: {
        boxShadow: 'none',
        padding: '6px 16px'
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif'
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main
    }
}))

export const Historico = () => {
    const classes = useStyles()
    const history = useHistory()
    const { Id } = useParams<{ Id: string }>()
    const [historico, setHistorico] = useState<Array<any>>([])
    useEffect(() => {
        get(ENDPOINTS.historico + '?id=' + Id).then(({ data: { Model } }) => {
            setHistorico(Model)
            console.log(Model)
        })
    }, [])
    const formatarData = (data: string) => {
        const dataFormatada = new Date(data)
        return `${dataFormatada.toLocaleDateString()} - ${dataFormatada.toLocaleTimeString()}`
    }
    const conditionalIcons = (situation: string) => {
        switch (situation) {
            case 'Autorização pendente':
                return (
                    <div
                        style={{
                            ...customCircle,
                            background: '#ffd84c',
                            color: 'white'
                        }}
                    >
                        <FontAwesomeIcon icon="user-clock"></FontAwesomeIcon>
                    </div>
                )
            case 'Em cotação':
                return (
                    <div
                        style={{
                            ...customCircle,
                            background: '#ffd84c',
                            color: 'white'
                        }}
                    >
                        <FontAwesomeIcon icon="hand-holding-usd"></FontAwesomeIcon>
                    </div>
                )
            case 'Aguardando prestação de contas':
                return (
                    <div
                        style={{
                            ...customCircle,
                            background: '#85ED7C',
                            color: 'white'
                        }}
                    >
                        <FontAwesomeIcon icon="business-time"></FontAwesomeIcon>
                    </div>
                )
            case 'Aguardando conferencia':
                return (
                    <div
                        style={{
                            ...customCircle,
                            background: '#ffd84c',
                            color: 'white'
                        }}
                    >
                        <FontAwesomeIcon icon="scroll"></FontAwesomeIcon>
                    </div>
                )
            case 'Aguardando finalizar na tesouraria':
                return (
                    <div
                        style={{
                            ...customCircle,
                            background: '#ffd84c',
                            color: 'white'
                        }}
                    >
                        <FontAwesomeIcon icon="cut"></FontAwesomeIcon>
                    </div>
                )
            case 'Finalizada':
                return (
                    <div
                        style={{
                            ...customCircle,
                            background: '#85ED7C',
                            color: 'white'
                        }}
                    >
                        <FontAwesomeIcon icon="check-circle"></FontAwesomeIcon>
                    </div>
                )
            case 'Cancelada':
                return (
                    <div
                        style={{
                            ...customCircle,
                            background: '#F05A5A',
                            color: 'white'
                        }}
                    >
                        <FontAwesomeIcon icon="times-circle"></FontAwesomeIcon>
                    </div>
                )
            default:
                break
        }
    }
    return (
        <BlankBoard>
            <Header
                title="Histórico"
                actionArrowClick={() => history.goBack()}
            ></Header>
            <Center>
                <Subtitle>
                    <b> Histórico</b> da solicitação
                </Subtitle>
            </Center>
            <LineSeparator></LineSeparator>
            <Timeline
                align="alternate"
                style={{
                    marginTop: 50
                }}
            >
                {historico.map((item, index) => {
                    return (
                        <TimelineItem key={index}>
                            <TimelineOppositeContent>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    <p
                                        style={{
                                            marginTop: '5px',
                                            marginBottom: '0'
                                        }}
                                    >
                                        {formatarData(item.DataEHoraAlteracao)}
                                        <br />
                                        {item.NomeUsuario}
                                    </p>
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator style={{ fontSize: '1.1rem' }}>
                                {conditionalIcons(item.Situacao)}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography variant="h6" component="h1">
                                        <p
                                            style={{
                                                marginBottom: '0rem',
                                                fontWeight: 600
                                            }}
                                        >
                                            {item.Situacao}
                                        </p>
                                    </Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })}
            </Timeline>
        </BlankBoard>
    )
}

const customCircle = {
    width: '40px',
    height: '40px',
    background: 'aqua',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '25px'
}

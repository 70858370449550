import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export interface PaginatorType {
    Count?: number
    Direction?: number
    ItemsPerPage?: number
    OrderBy?: boolean
    Page: number
    Pages: number
    Projections?: null
}

export interface PaginatorProps {
    Page: number
    Pages: number
    setPage: (numPage: number) => void
    setNextPage: () => void
    setPreviousPage: () => void
}

export const Paginator = ({
    Page,
    Pages,
    setPage,
    setNextPage,
    setPreviousPage
}: PaginatorProps) => {
    const [list, setList] = useState<number[]>([])
    useEffect(() => {
        const lista = []
        for (let index = 0; index < Pages + 1; index++) {
            lista.push(index)
        }
        setList(lista)
    }, [])
    return (
        <div style={{ marginTop: '2rem' }}>
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    <li className="page-item">
                        <span
                            className="page-link"
                            aria-label="Previous"
                            style={
                                Page === 1
                                    ? {
                                          backgroundColor: 'gainsboro',
                                          cursor: 'no-drop',
                                          width: '2rem',
                                          color: '#002A5D',
                                          fontWeight: 600,
                                          fontSize: '0.8rem',
                                          margin: '0 0.8rem',
                                          borderRadius: '0.5rem'
                                      }
                                    : {
                                          backgroundColor: 'gainsboro',
                                          cursor: 'pointer',
                                          width: '2rem',
                                          color: '#002A5D',
                                          fontWeight: 600,
                                          fontSize: '0.8rem',
                                          margin: '0 0.8rem',
                                          borderRadius: '0.5rem'
                                      }
                            }
                            onClick={() => setPreviousPage()}
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </span>
                    </li>
                    {getLista()}
                    <li className="page-item">
                        <span
                            className="page-link "
                            aria-label="Next"
                            style={
                                Page === Pages
                                    ? {
                                          backgroundColor: 'gainsboro',
                                          cursor: 'no-drop',
                                          width: '2rem',
                                          color: '#002A5D',
                                          fontWeight: 600,
                                          fontSize: '0.8rem',
                                          margin: '0 0.8rem',
                                          borderRadius: '0.5rem'
                                      }
                                    : {
                                          cursor: 'pointer',
                                          backgroundColor: 'gainsboro',
                                          width: '2rem',
                                          color: '#002A5D',
                                          fontWeight: 600,
                                          fontSize: '0.8rem',
                                          margin: '0 0.8rem',
                                          borderRadius: '0.5rem'
                                      }
                            }
                            onClick={() => setNextPage()}
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </span>
                    </li>
                </ul>
            </nav>
        </div>
    )
    function getLista() {
        const objetivo = Page + 7
        const param1 = Math?.max(1, objetivo > Pages ? Pages - 6 : Page)
        const param2 = objetivo > Pages ? Pages + 1 : objetivo
        const paginas = list?.slice(param1, param2).map(item => item)
        return paginas.map((item, index) => (
            <li className="page-item" key={index}>
                <span
                    className="page-link"
                    style={
                        Page === item
                            ? {
                                  width: '2rem',
                                  backgroundColor: '#002A5D',
                                  color: '#fff',
                                  fontWeight: 600,
                                  fontSize: '0.8rem',
                                  cursor: 'no-drop',
                                  margin: '0 0.1rem',
                                  borderRadius: '0.5rem'
                              }
                            : {
                                  cursor: 'pointer',
                                  width: '2rem',
                                  backgroundColor: '#fff',
                                  color: '#002A5D',
                                  fontWeight: 600,
                                  fontSize: '0.8rem',
                                  margin: '0 0.1rem',
                                  borderRadius: '0.5rem'
                              }
                    }
                    onClick={() => setPage(item - 1)}
                >
                    {item}
                </span>
            </li>
        ))
    }
}

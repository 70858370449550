import React, { useEffect, useRef } from 'react'

export type Props = {
    title?: string
    text?: string
    status?: boolean
    textOk?: string
    classBtnOk?: string
    okClick?: () => void | string
    textBack?: string
    btnClose?: string
    classBtnBack?: string
    backClick?: () => void | string
    setButtonOpen?: any
}

export const Modal = ({
    title,
    text,
    okClick,
    status = false,
    setButtonOpen,
    textOk = 'Ok',
    textBack = 'Voltar',
    btnClose = 'default',
    classBtnOk = 'btn-primary',
    classBtnBack = 'btn-outline-danger'
}: Props) => {
    const buttonRef = useRef<HTMLButtonElement>(null)
    useEffect(() => {
        if (status) {
            buttonRef.current?.click()
        }
        // setButtonOpen(buttonRef.current)
    }, [status])
    return (
        <>
            <button
                type="button"
                className="btn btn-primary invisible-item"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                ref={buttonRef}
            >
                Abrir modal - Botão escondido
            </button>
            <div
                className="modal fade"
                id="exampleModal"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5
                                className="modal-title"
                                id="exampleModalLabel"
                                style={{
                                    color: '#FFFFFF'
                                }}
                            >
                                {title}
                            </h5>
                            {btnClose === 'hidden' ? null : (
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            )}
                        </div>
                        <div
                            className="modal-body"
                            style={{ fontSize: '12pt' }}
                        >
                            {text}
                        </div>
                        <div className="modal-footer d-grid gap-2 d-md-flex justify-content-md-end">
                            {textBack === 'hidden' ? null : (
                                <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    className={`btn ${classBtnBack}`}
                                >
                                    {textBack}
                                </button>
                            )}

                            <button
                                type="button"
                                onClick={okClick}
                                data-bs-dismiss="modal"
                                className={`btn ${classBtnOk}`}
                            >
                                {textOk}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

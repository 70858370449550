import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const Legenda = (param: number, value: string) => {
    let paramBackgroundColor
    let paramcolor
    let paramIcon: any
    let corIcon
    if (param === 1) {
        // 1 = Rascunho
        paramBackgroundColor = '#DEE2E6'
        paramcolor = '#495057'
        paramIcon = 'pencil-alt'
        corIcon = '#495057'
    } else if (param === 2) {
        // Finalização tesouraria - Aguardando prest. De contas
        // Autorização pendente - Aguardando conferência
        paramBackgroundColor = '#FFF1C8'
        paramcolor = '#896701'
        paramIcon = 'exclamation-circle'
        corIcon = '#E0A800'
    } else if (param === 3) {
        // Reprovado
        paramBackgroundColor = '#FFDEDE'
        paramcolor = '#DC3545'
        paramIcon = 'times'
        corIcon = '#C82333'
    } else if (param === 4) {
        // Aprovado
        paramBackgroundColor = '#74C686'
        paramcolor = '#218838'
        paramIcon = 'check-double'
        corIcon = '#218838'
    } else if (param === 5) {
        // Cancelado
        paramBackgroundColor = '#DEE2E6'
        paramcolor = '#495057'
        paramIcon = 'power-off'
        corIcon = '#495057'
    }
    return (
        <div
            style={{
                position: 'relative',
                width: '0 auto',
                backgroundColor: paramBackgroundColor,
                color: paramcolor,
                borderRadius: '8px',
                padding: '4px',
                textAlign: 'center',
                fontSize: '0.8rem',
                font: 'Montserrat, sas-serif'
            }}
        >
            <span
                style={{
                    paddingRight: '4px'
                }}
            >
                <FontAwesomeIcon
                    icon={paramIcon}
                    color={corIcon}
                    widths="20px"
                    height="16px"
                    className="ms-2"
                ></FontAwesomeIcon>{' '}
            </span>

            {value}
        </div>
    )
}

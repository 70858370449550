/**
 * Método Util para montar uma url com filtros para chamada de métodos GET
 * @param payload objeto com os filtros para montagem da url com query params >> Ex. {id : 0, nome: 'andrew'}
 * @param endpoint endpoint que será aplicado os filtros >> Ex. ENDPOINTS.usuarios
 * @returns string com o endpoint montado de queries >> Ex. usuarios/pesquisar?id=0&nome=andrew
 */
export function UrlMountQuery(payload: any, endpoint: string) {
    endpoint = `${endpoint}?`
    const numberOfPropierties = Object.keys(payload).length
    Object.keys(payload).forEach((f: string, index: number) => {
        if (payload[f] !== undefined) {
            endpoint = `${endpoint}${f}=${
                typeof payload[f] == 'object'
                    ? `${payload[f].getFullYear()}-${
                          payload[f].getMonth() + 1
                      }-${payload[f].getDate()}`
                    : payload[f]
            }${
                numberOfPropierties > 1 && index < numberOfPropierties - 1
                    ? '&'
                    : ''
            }`
        }
    })
    return endpoint
}

import { Form } from '@unform/web'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button'
import { Center } from '../../../core/components/Center'
import { Input } from '../../../core/components/Form/Input'
import { Header } from '../../../core/components/Header/Header'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Subtitle } from '../../../core/components/Subtitle'
import { Alert } from '../../../core/components/Alert'
import { UserContext } from '../../../contexts/UserContext'
import { ENDPOINTS } from '../../../services/config-http'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Flex } from '../../../core/components/Flex'
import {
    TableRequester,
    MapperItem,
    ConfigTable,
    Table
} from './../../../core/components/Table/v2/Table'

import { useHistory } from 'react-router'
import { Select } from '../../../core/components/Form/Select'
import { put } from '../../../services/http'
import { useAlert } from '../../../hooks/AlertToast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DisableForMobile, IsMobile } from '../../Viagem/Solicitacao/stepMobile'

const Empresas = () => {
    const FormRef = useRef<FormHandles>(null)
    const history = useHistory()
    const [payloadSearch, setPayloadSearch] = useState<TableRequester>({
        endpoint: ENDPOINTS.pesquisarEmpresa,
        method: 'GET',
        payload: {}
    })

    const mapper: MapperItem[] = [
        { field: 'Nome', position: 1 },
        { field: 'Status', visualCustom: _pipeStatus, position: 3 }
    ]
    const tableConfig: ConfigTable = {
        edit: false,
        delete: false,
        moreOptions: [
            {
                label: ' Editar',
                icon: 'file-signature',
                color: '#002A5D',
                conditional: (option: string, item: any) => {
                    return item.Status === true
                },
                action: (item: any) => {
                    history.push(`/cadastros/empresa/${item.Id}/editar`)
                }
            },
            {
                label: ' Habilitar',
                icon: 'file-signature',
                color: '#002A5D',
                conditional: (option: string, item: any) => {
                    return item.Status === false
                },
                action: (item: any) => {
                    alterarStatus(item, true)
                }
            },
            {
                label: ' Desabilitar',
                icon: 'file-signature',
                color: '#002A5D',
                action: (item: any) => {
                    alterarStatus(item, false)
                }
            }
        ]
    }

    function _pipeStatus(value: boolean): JSX.Element {
        if (value) {
            return (
                <span
                    className="badge status-success"
                    style={{ width: '75px', margin: 'auto' }}
                >
                    <FontAwesomeIcon icon="circle" />
                    &ensp; {'ATIVO'}
                </span>
            )
        } else {
            return (
                <span
                    className="badge status-danger"
                    style={{ width: '75px', margin: 'auto' }}
                >
                    <FontAwesomeIcon icon="circle" />
                    &ensp; {'INATIVO'}
                </span>
            )
        }
    }

    const StatusOptions = [
        {
            value: '2',
            label: 'ATIVO'
        },
        {
            value: '1',
            label: 'INATIVO'
        }
    ]

    const [administrador, setAdministrador] = useState(false)
    const { setAlert, showAlert } = useAlert()

    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    const handleFilterSubmit: SubmitHandler<FormData> = (data: any) => {
        Object.keys(data).forEach(
            k => !data[k] && data[k] !== undefined && delete data[k] // eliminando valores não preenchidos para envio da query de busca
        )
        data.page = 0
        data.lastCount = 0
        data.totalRegisterPage = 10
        data.IdSituacao = 6
        const newPayloadSearch = { ...payloadSearch, payload: data }
        setPayloadSearch(newPayloadSearch)
    }

    const alterarStatus = (item: any, param: boolean) => {
        put(ENDPOINTS.empresa, {
            id: item.Id,
            status: param,
            codigo: item.Codigo,
            nome: item.Nome
        })
            .then(() => {
                setAlert({
                    type: 'success',
                    time: 5000,
                    title: 'Sucesso!',
                    subTitle: `Empresa foi ${
                        param ? 'hbilitada' : 'desabilitada'
                    }`
                })
                showAlert(true)
                history.push(`/cadastros/empresas`)
            })
            .catch(({ data }) => {
                setAlert({
                    type: 'error',
                    time: 5000,
                    title: 'Erro!',
                    subTitle: `Não foi possivel ${
                        param ? 'habilitar' : 'desabilitar'
                    } empresa`
                })
                showAlert(true)
            })
    }
    return (
        <div>
            <BlankBoard>
                <Header
                    title="Empresas"
                    actionArrowClick="/solicitacoes"
                ></Header>
                <Center>
                    <Subtitle>Dados empresas</Subtitle>
                </Center>
                <Flex justifyContent="flex-end">
                    <DisableForMobile>
                        <Button
                            style={{
                                marginRight: '-3.2rem'
                            }}
                            icon={'plus'}
                            colorType="quaternary"
                            onClick={() =>
                                history.push('/cadastros/empresa/incluir')
                            }
                        >
                            Cadastrar
                        </Button>
                    </DisableForMobile>
                </Flex>
                <Center>
                    <IsMobile>
                        <Button
                            icon={'plus'}
                            colorType="btnMobile"
                            onClick={() =>
                                history.push('/cadastros/empresa/incluir')
                            }
                        >
                            Cadastrar
                        </Button>
                    </IsMobile>
                </Center>
                {administrador ? (
                    <>
                        <LineSeparator></LineSeparator>
                        <Form
                            onSubmit={handleFilterSubmit}
                            ref={FormRef}
                            style={{ padding: 0 }}
                        >
                            <div className="row">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: '1rem'
                                    }}
                                >
                                    <Button
                                        style={{
                                            margin: '0 .2rem',
                                            padding: '0 15px'
                                        }}
                                        onlyIcon={true}
                                        icon="search"
                                        title="Pesquisar"
                                        colorType="primary"
                                    ></Button>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <Input
                                        placeholder="Codigo"
                                        label="Codigo"
                                        name="Codigo"
                                        maxLength={50}
                                    ></Input>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <Input
                                        placeholder="Nome"
                                        label="Nome"
                                        name="Nome"
                                        maxLength={50}
                                    ></Input>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <Select
                                        name="Status"
                                        options={StatusOptions}
                                        placeholder="Selecione o status"
                                    ></Select>
                                </div>
                            </div>
                        </Form>
                        <Table
                            requester={payloadSearch}
                            mapper={mapper}
                            config={tableConfig}
                        ></Table>
                    </>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                )}
            </BlankBoard>
        </div>
    )
}

export default Empresas

import React, { useRef, useEffect, useState, useContext } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Button } from '../../../../core/components/Button'
import { Input } from '../../../../core/components/Form/Input'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Center } from '../../../../core/components/Center'
import { Select } from '../../../../core/components/Form/Select'
import { Header } from '../../../../core/components/Header/Header'
import { Space } from '../../../../core/components/Space'
import { Subtitle } from '../../../../core/components/Subtitle'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { SelectMapper } from '../../../../core/utils/SelectMapper'
import { ENDPOINTS } from '../../../../services/config-http'
import { get, patch } from '../../../../services/http'
import { Solicitacao } from '../../../../contexts/interfaces/solicitacao'
import styled from 'styled-components'
import { UserContext } from '../../../../contexts/UserContext'
import { Alert } from '../../../../core/components/Alert'
import { DisableForMobile, IsMobile } from '../stepMobile'

export const EditarSolicitacaoForm = () => {
    const FormRef = useRef<FormHandles>(null)
    const history = useHistory()
    const { Id } = useParams() as any
    const [motivos, setMotivos] = useState([])
    const [tiposSolicitacao, setTiposSolicitacao] = useState([])
    const [administrador, setAdministrador] = useState(false)

    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })

    useEffect(() => {
        Promise.all([getMotivos(), getTiposSolicitacao()])
        get(`${ENDPOINTS.solicitacaoViagemPorId}/${Id}`)
            .then(({ data: { Model } }) => {
                FormRef.current?.setData({
                    idTipoSolicitacao: Model.IdTipoSolicitacao,
                    idMotivoViagem: Model.IdMotivoViagem,
                    descricao: Model.Descricao
                })
            })
            .catch(err => {
                setShowAlert({
                    status: true,
                    message: err.data,
                    type: 'danger'
                })
            })
    }, [])

    function getMotivos() {
        return get(ENDPOINTS.motivoViagemPesquisar)
            .then(({ data: { Items } }) => {
                setMotivos(SelectMapper(Items, 'Descricao', 'Id'))
            })
            .catch(err => {
                console.error(err)
            })
    }

    function getTiposSolicitacao() {
        return get(ENDPOINTS.tipoSolicitacao)
            .then(({ data: { Model } }) => {
                console.log(Model)
                setTiposSolicitacao(SelectMapper(Model, 'Nome', 'Id'))
            })
            .catch(err => {
                console.error(err)
            })
    }

    const handleSubmit: SubmitHandler<Solicitacao> = async (
        data,
        { reset }
    ) => {
        try {
            const schema = Yup.object().shape({
                idTipoSolicitacao: Yup.string().required(
                    'O campo Tipo de Viagem é obrigatório!'
                ),
                idMotivoViagem: Yup.string().required(
                    'O campo Motivo é obrigatório!'
                ),
                descricao: Yup.string().required(
                    'O campo Descrição é obrigatória!'
                )
            })

            await schema.validate(data, { abortEarly: false })

            FormRef.current?.setErrors({})

            patch(
                `${ENDPOINTS.solicitarViagem}?Id=${Id}&IdTipoSolicitacao=${data.idTipoSolicitacao}&IdMotivoViagem=${data.idMotivoViagem}&Descricao=${data.descricao}`
            )
                .then(({ data: { Model } }) => {
                    history.push(`/solicitacoes/editar/${Id}`)
                    window.location.reload()
                })
                .catch(err => {
                    setShowAlert({
                        status: true,
                        message: err.data,
                        type: 'danger'
                    })
                })
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                // Caso exista erros, o MapFormErrors vai mapear o objeto para enviarmos ao unform
                const errors = MapFormErrors(err.inner)
                // Mostrar erros na tela
                FormRef.current?.setErrors(errors)
            }
        }
    }
    return (
        <BlankBoard>
            <Header
                title="Solicitação de Viagem"
                actionArrowClick={() =>
                    history.push(`/solicitacoes/editar/${Id}`)
                }
            ></Header>
            {administrador ? (
                <Form ref={FormRef} onSubmit={handleSubmit}>
                    <Center>
                        <ResponsiveSubtitle>
                            <Subtitle
                                style={{
                                    textAlign: 'center',
                                    padding: '0 5rem'
                                }}
                            >
                                Dados da Solicitação
                            </Subtitle>
                        </ResponsiveSubtitle>
                    </Center>
                    <Space height="3rem"></Space>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <Select
                                name="idTipoSolicitacao"
                                label="Que Tipo de Viagem?"
                                placeholder="Selecione o tipo"
                                required={true}
                                options={tiposSolicitacao}
                            ></Select>
                        </div>
                        <div className="col-12 col-sm-6">
                            <Select
                                name="idMotivoViagem"
                                label="Motivo"
                                placeholder="Selecione o motivo"
                                required={true}
                                options={motivos}
                            ></Select>
                        </div>
                        <Space height="2rem"></Space>
                        <Input
                            name="descricao"
                            label="Descrição"
                            placeholder="Digite a descrição"
                            required={true}
                        ></Input>
                    </div>
                    <Space height="3rem"></Space>
                    <Center>
                        <DisableForMobile>
                            <Button
                                icon="save"
                                colorType="quaternary"
                                type="submit"
                            >
                                Salvar
                            </Button>
                        </DisableForMobile>
                        <IsMobile>
                            <Button
                                icon="save"
                                colorType="btnMobile"
                                type="submit"
                            >
                                Salvar
                            </Button>
                        </IsMobile>
                    </Center>
                </Form>
            ) : (
                <Alert
                    fixed={true}
                    icon="exclamation-circle"
                    type="primary"
                    message="Você não tem permissão para cadastrar conferencia "
                ></Alert>
            )}
        </BlankBoard>
    )
}

const ResponsiveSubtitle = styled.div`
    @media (max-width: 768px) {
        h2 {
            padding: 0 1rem !important;
        }
    }
`

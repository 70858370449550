import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import React, { useEffect, useRef, useState } from 'react'
import { Input } from '../../../core/components/Form/Input'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { ENDPOINTS } from '../../../services/config-http'
import { Toast } from '../../../core/components/Toast'
import styled from 'styled-components'
import { STATUS_SOLICITACAO } from '../../../contexts/enums/StatusSolicitacao'
import { useHistory } from 'react-router-dom'

interface PrestacaoProp {
    show?: boolean
    setShow: any
}

export const ModalPrestacao = ({ show, setShow }: PrestacaoProp) => {
    const history = useHistory()
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })
    const [viajantes, setViajantes] = useState<Array<any>>([])

    useEffect(() => {
        if (show) {
            document
                .getElementsByClassName('modal-aereo')[0]
                ?.classList.remove('hidden')
        } else
            document
                .getElementsByClassName('modal-aereo')[0]
                ?.classList.add('hidden')
    }, [show])

    function _PipeCustomStatus(value: string | undefined): JSX.Element {
        switch (value) {
            case STATUS_SOLICITACAO.FINALIZADA:
                return (
                    <span
                        className="badge status-success"
                        style={{ margin: 'auto' }}
                    >
                        <FontAwesomeIcon icon="circle" />
                        &ensp; {value}
                    </span>
                )
            case STATUS_SOLICITACAO.AUTORIZACAO_PENDENTE:
            case STATUS_SOLICITACAO.AGUARDANDO_CONFERENCIA:
            case STATUS_SOLICITACAO.AGUARDANDO_FINALIZAR_NA_TESOURARIA:
            case STATUS_SOLICITACAO.EM_COTACAO:
            case STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS:
                return (
                    <span
                        className="badge status-pendding"
                        style={{ margin: 'auto', color: '#5D5D5D' }}
                    >
                        <FontAwesomeIcon icon="circle" />
                        &ensp;
                        {value === 'Aguardando prestação de contas'
                            ? 'Aguardando prest. contas'
                            : value === 'Aguardando finalizar na tesouraria'
                            ? 'Finalização tesouraria'
                            : value}
                    </span>
                )
            case STATUS_SOLICITACAO.REPROVADO:
            case STATUS_SOLICITACAO.REPROVADO_PRESTACAO_DE_CONTAS:
                return (
                    <span
                        className="badge status-danger"
                        style={{ margin: 'auto' }}
                    >
                        <FontAwesomeIcon icon="circle" />
                        &ensp;
                        {value === 'Reprovado: prestação de contas'
                            ? 'Reprovado: prest. contas'
                            : value}
                    </span>
                )
            case STATUS_SOLICITACAO.CANCELADO:
                return (
                    <span
                        className="badge status"
                        style={{ margin: 'auto', backgroundColor: '#525252' }}
                    >
                        <FontAwesomeIcon icon="circle" />
                        &ensp; {value}
                    </span>
                )
            default:
                return (
                    <>
                        <i className="text-danger" style={{ margin: 'auto' }}>
                            {value}
                        </i>
                    </>
                )
        }
    }

    return (
        <>
            <div className="modal-aereo">
                <div
                    style={{
                        borderRadius: '0.5rem',
                        padding: '1rem',
                        marginTop: '3.5rem',
                        backgroundColor: 'white',
                        width: '50rem',
                        maxHeight: '24rem'
                    }}
                >
                    <div className="modalcotacaoheader">
                        <div style={{ width: '5%' }}></div>
                        <h1
                            style={{
                                fontWeight: 'bold',
                                color: '#24538B',
                                width: '90%',
                                textAlign: 'center'
                            }}
                        >
                            Aviso
                        </h1>
                        <button
                            onClick={e => setShow(false)}
                            className="closemodalcotacao"
                            style={{
                                width: '5%',
                                textAlign: 'center'
                            }}
                        >
                            <FontAwesomeIcon icon="times" />
                        </button>
                    </div>
                    <div className="modalcotacaobody">
                        <LineSeparator
                            style={{ margin: '.1rem 2rem' }}
                        ></LineSeparator>
                        <span
                            style={{
                                fontSize: '1rem',
                                textAlign: 'center',
                                color: '#002A5D'
                            }}
                        >
                            Ao alterar o viajante todas as despesas lançadas ou
                            editadas serão perdidas. Você pode enviar a
                            prestação para análise e salvar todas as despesas
                            lançadas ou deseja descarta-las?
                        </span>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <ButtonDiscard>
                                    Descartar Despesas
                                </ButtonDiscard>
                            </div>
                            <div className="col-12 col-md-6">
                                <ButtonKeep> Descartar Despesas</ButtonKeep>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </>
    )
}

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
`
const ButtonDiscard = styled.button`
    background-color: white;
    color: #002a5d;
    border: #002a5d 0.08rem solid !important;
    padding: 0.3rem 0.7rem;
    border-radius: 4px;
    font-size: 1.2rem;
    width: 20rem;
    height: 3rem;
`

const ButtonKeep = styled.button`
    background-color: #002a5d;
    color: #fff;
    border: #002a5d 0.08rem solid !important;
    padding: 0.3rem 0.7rem;
    border-radius: 4px;
    font-size: 1.2rem;
    width: 20rem;
    height: 3rem;
`

import React from 'react'
import {
    RouteProps as ReactDOMRouteProps,
    Route as ReactDOMRoute,
    Redirect
} from 'react-router-dom'
import { useAuth } from '../contexts/Auth'

interface RouteProps extends ReactDOMRouteProps {
    isPrivate?: boolean
    component: React.ComponentType
}

const Route: React.FC<RouteProps> = ({
    isPrivate = false,
    component: Component,
    ...rest
}) => {
    const { user } = useAuth()

    return (
        <ReactDOMRoute
            {...rest}
            render={props => {
                return isPrivate === !!user ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: isPrivate ? '/' : '/solicitacoes',
                            state: { from: props.location }
                        }}
                    />
                )
            }}
        />
    )
}

export default Route

import React, { useRef, useEffect, useState } from 'react'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { DatePicker } from '../../../../core/components/DatePicker'
import { Input } from '../../../../core/components/Form/Input'
import { Header } from '../../../../core/components/Header/Header'
import { StepByStep } from '../../../../core/components/StepByStep'
import { useHistory } from 'react-router'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { Center } from '../../../../core/components/Center'
import { Button } from '../../../../core/components/Button'
import { Space } from '../../../../core/components/Space'
import { StepByStepItem } from '../../../../contexts/interfaces/_stepByStep'
import { Subtitle } from '../../../../core/components/Subtitle'
import { SOLICITACAO_VIAGEM } from '../../../../state/SolicitacaoViagem/Actions'
import { useGlobalStates } from '../../../../state/InitialState'
import { Solicitacao } from '../../../../contexts/interfaces/solicitacao'
import { Empty } from '../../../../core/components/Empty'
import styled from 'styled-components'
import { Hospedagem as HospedagemProp } from '../../../../contexts/interfaces/hospedagem'
import { InputPlaces } from '../../../../core/components/InputPlace'
import { ContainerPadding } from '../../../../core/components/ContainerPadding'
import { Deletar } from '../../../../core/components/Deletar'
import { Roteiro } from '../../../../contexts/interfaces/roteiro'
import moment from 'moment'
import useWindowDimensions from '../../../../core/components/GetDimensions'

export const Hospedagem = () => {
    const history = useHistory()
    const {
        setGlobalState,
        state: { solicitacao }
    } = useGlobalStates()

    const FormRef = useRef<FormHandles>(null)

    const [hospedagens, setHospedagens] = useState<Array<HospedagemProp>>([])
    const [stepByStep, setStepByStep] = useState<StepByStepItem[]>([])
    const [roteiro, setRoteiro] = useState([
        {
            dataInicio: new Date().toLocaleDateString(),
            dataTermino: new Date().toLocaleDateString()
        }
    ])
    const indexActiveStep = useRef<number>(0)
    const { height, width } = useWindowDimensions()

    useEffect(() => {
        const stepItem = localStorage.getItem('stepByStep')
        if (stepItem !== null) {
            const stepItemObj = JSON.parse(stepItem) as StepByStepItem[]
            indexActiveStep.current = stepItemObj.findIndex(
                s => s.name === 'Hospedagem'
            )
            stepItemObj[indexActiveStep.current].active = true
            for (let index = 0; index < indexActiveStep.current; index++) {
                stepItemObj[index].completed = true
            }
            setStepByStep(stepItemObj)
        }

        if (solicitacao === undefined) {
            const sol = localStorage.getItem('solicitacao')
            if (sol !== null) {
                const solicitacaoStorage = JSON.parse(sol) as Solicitacao
                setGlobalState(
                    SOLICITACAO_VIAGEM.REDUCER_NAME,
                    SOLICITACAO_VIAGEM.ACTION_ADD_SOLICITACAO,
                    solicitacaoStorage
                )
                setHospedagens(solicitacaoStorage?.hospedagem || [])
                setRoteiro(solicitacaoStorage?.destinos || [])
            }
        } else {
            setHospedagens(solicitacao?.hospedagem || [])
            setRoteiro(solicitacao?.destinos)
        }
    }, [])

    const getMinDate = () => {
        return new Date(
            moment(roteiro[0]?.dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD') +
                ' 00:00:00'
        )
    }

    const getMaxDate = () => {
        return new Date(
            moment(
                roteiro[roteiro?.length - 1]?.dataTermino,
                'DD/MM/YYYY'
            ).format('YYYY-MM-DD') + ' 00:00:00'
        )
    }

    const handleSubmit: SubmitHandler = async (data, { reset }) => {
        try {
            const schema = Yup.object().shape({
                cidade: Yup.string().required('O Campo Cidade é Obrigatório!'),
                referencia: Yup.string().required(
                    'O Campo Ponto de referencia é Obrigatório!'
                ),
                dataEntrada: Yup.string()
                    .nullable()
                    .required('O Campo Check-in é Obrigatório!'),
                dataSaida: Yup.string()
                    .nullable()
                    .required('O Campo Check-out é Obrigatório!')
            })
            if (
                new Date(data.dataEntrada).toLocaleString() >
                new Date(data.dataSaida).toLocaleString()
            ) {
                data.dataEntrada = ''
                schema.fields.dataEntrada = Yup.string().required(
                    'A Data de Check-in não pode ser menor que a de Check-out'
                )
            }

            await schema.validate(data, { abortEarly: false })
            const hospedagem: HospedagemProp = {
                Cidade: data.cidade,
                Referencia: data.referencia,
                DataEntrada: data.dataEntrada.toLocaleDateString(),
                DataSaida: data.dataSaida.toLocaleDateString(),
                Observacao: data.observacao
            }
            console.log(hospedagem)
            const newHospedagens = [...hospedagens]
            newHospedagens.push(hospedagem)
            setHospedagens(newHospedagens)
            FormRef.current?.setErrors({})
            reset()
        } catch (err) {
            console.log(err)
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                FormRef.current?.setErrors(erros)
            }
        }
    }

    const nextStep = async () => {
        setGlobalState(
            SOLICITACAO_VIAGEM.REDUCER_NAME,
            SOLICITACAO_VIAGEM.ACTION_ADD_HOSPEDAGEM,
            hospedagens
        )
        const sol = localStorage.getItem('solicitacao')
        if (sol !== null) {
            const solicitacaoObj = JSON.parse(sol) as Solicitacao
            solicitacaoObj.hospedagem = hospedagens
            localStorage.setItem('solicitacao', JSON.stringify(solicitacaoObj))
        } else {
            localStorage.setItem('solicitacao', JSON.stringify(solicitacao))
        }
        history.push(
            `/solicitar-viagem/${
                stepByStep[indexActiveStep.current + 1].caminho
            }`
        )
    }

    function _PipeCustomDate(value: string) {
        const date = new Date(value)
        return <>{date.toLocaleDateString()}</>
    }

    return (
        <BlankBoard>
            <Header
                title="Solicitação de viagem"
                actionArrowClick={() => {
                    if (indexActiveStep.current === 0) {
                        history.push('/solicitar-viagem/info')
                    } else {
                        history.push(
                            `/solicitar-viagem/${
                                stepByStep[indexActiveStep.current - 1].caminho
                            }`
                        )
                    }
                }}
            ></Header>
            <StepByStep items={stepByStep}></StepByStep>
            <ContainerPadding>
                <Space height="1rem"></Space>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="col-12 col-sm-10">
                        <Subtitle
                            style={{
                                margin: '1rem 0'
                            }}
                        >
                            Solicite reserva de hospedagem
                        </Subtitle>
                    </div>
                    <div
                        className="col-12 col-sm-2"
                        style={{ justifyContent: 'flex-end' }}
                    >
                        <DisableForMobile>
                            <Button
                                style={{
                                    width: '75%',
                                    minWidth: 'auto'
                                }}
                                colorType="quintiary"
                                icon="arrow-right"
                                onClick={() => {
                                    history.push(
                                        `/solicitar-viagem/${
                                            stepByStep[
                                                indexActiveStep.current + 1
                                            ].caminho
                                        }`
                                    )
                                }}
                            >
                                Pular
                            </Button>
                        </DisableForMobile>
                    </div>
                </div>

                <Center>
                    <IsMobile>
                        <Button
                            colorType="btnMobile"
                            icon="arrow-right"
                            onClick={() => {
                                history.push(
                                    `/solicitar-viagem/${
                                        stepByStep[indexActiveStep.current + 1]
                                            .caminho
                                    }`
                                )
                            }}
                        >
                            Pular
                        </Button>
                    </IsMobile>
                </Center>

                <Space height="2rem"></Space>

                <Form ref={FormRef} onSubmit={handleSubmit}>
                    <Space height="1.3em"></Space>
                    <div className="row">
                        <div
                            className="col-12 col-sm-6"
                            style={{ paddingTop: '1rem' }}
                        >
                            <InputPlaces
                                name="cidade"
                                label="Cidade"
                                required={true}
                                placeholder="Em que cidade?"
                            ></InputPlaces>
                        </div>
                        <div className="col-12 col-sm-6">
                            <Input
                                name="referencia"
                                label="Ponto de referencia"
                                required={true}
                                placeholder="Ex. Próximo a havan vila velha"
                            ></Input>
                        </div>
                        <div className="col-12 col-sm-6">
                            <DatePicker
                                name="dataEntrada"
                                label="Check-in"
                                minDate={getMinDate()}
                                maxDate={getMaxDate()}
                                placeholderText="Data da entrada"
                            ></DatePicker>
                        </div>

                        <div className="col-12 col-sm-6">
                            <DatePicker
                                name="dataSaida"
                                label="Check-out"
                                minDate={getMinDate()}
                                maxDate={getMaxDate()}
                                placeholderText="Data da saída"
                            ></DatePicker>
                        </div>
                        <div className="col-12">
                            <Input
                                style={{ height: '7.5rem' }}
                                name="observacao"
                                label="Observação"
                                type="textarea"
                                placeholder="Digite a observação..."
                                required={false}
                            ></Input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="col-12 col-sm-3">
                                <Center>
                                    <DisableForMobile>
                                        <Button
                                            style={{
                                                marginRight: '-3rem'
                                            }}
                                            colorType="quaternary"
                                            icon="plus"
                                        >
                                            Adicionar
                                        </Button>
                                    </DisableForMobile>
                                </Center>
                            </div>
                        </div>
                    </div>
                    <Center>
                        <IsMobile>
                            <Button colorType="btnMobile" icon="plus">
                                Adicionar
                            </Button>
                        </IsMobile>
                    </Center>
                    <Space height="4em"></Space>
                    {hospedagens?.length ? (
                        <Container>
                            {width && width > 768
                                ? getTabela()
                                : getTabelaMobile()}
                        </Container>
                    ) : (
                        <Empty text="Nenhuma hospedagem adicionada" />
                    )}
                </Form>
                <Space height="2rem"></Space>
                <Center>
                    {hospedagens.length > 0 ? (
                        <>
                            <Center>
                                <DisableForMobile>
                                    <Button
                                        colorType="quaternary"
                                        type="submit"
                                        onClick={() => {
                                            nextStep()
                                        }}
                                    >
                                        Próximo
                                    </Button>
                                </DisableForMobile>

                                <IsMobile>
                                    <Button
                                        colorType="btnMobile"
                                        type="submit"
                                        onClick={() => {
                                            nextStep()
                                        }}
                                    >
                                        Próximo
                                    </Button>
                                </IsMobile>
                            </Center>
                        </>
                    ) : null}
                </Center>
            </ContainerPadding>
        </BlankBoard>
    )

    function getTabela() {
        return (
            <div className="row" style={{ marginTop: '3rem' }}>
                <div className="col-12">
                    <label htmlFor=""></label>
                    <div
                        className="table-responsive"
                        style={{
                            border: 'solid 1px lightgray',
                            borderRadius: '15px'
                        }}
                    >
                        <table className="table">
                            <thead
                                style={{
                                    borderBottom: 'solid 1px lightgray'
                                }}
                            >
                                <tr
                                    style={{
                                        border: '0 !important'
                                    }}
                                >
                                    <Th className="col-1" scope="col">
                                        <p>#</p>
                                    </Th>
                                    <Th scope="col" className="col-2">
                                        <p>Cidade</p>
                                    </Th>
                                    <Th scope="col" className="col-1">
                                        <p>Check-in</p>
                                    </Th>
                                    <Th scope="col" className="col-1">
                                        <p>Check-out</p>
                                    </Th>
                                    <Th scope="col" className="col-2">
                                        <p>Ponto de Referência</p>
                                    </Th>
                                    <Th scope="col" className="col-2">
                                        <p>Observação</p>
                                    </Th>
                                    <Th scope="col" className="col-3">
                                        <p>Remover</p>
                                    </Th>
                                </tr>
                            </thead>
                            <tbody>
                                {hospedagens.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td className="col-1">
                                                <p>{key + 1}</p>
                                            </td>
                                            <td className="col-2">
                                                <p>{item.Cidade}</p>
                                            </td>
                                            <td className="col-1">
                                                <p>
                                                    {item.DataEntrada !==
                                                    undefined
                                                        ? item.DataEntrada.toString()
                                                        : 'Não possui data de entrada'}
                                                </p>
                                            </td>
                                            <td className="col-1">
                                                <p>
                                                    {item.DataSaida !==
                                                    undefined
                                                        ? item.DataSaida.toString()
                                                        : 'Não possui data de saída'}
                                                </p>
                                            </td>
                                            <td className="col-2">
                                                <p>{item.Referencia}</p>
                                            </td>
                                            <td className="col-2">
                                                <p>{item.Observacao}</p>
                                            </td>
                                            <td className="col-3 danger cursor-pointer">
                                                <Deletar
                                                    remover={() => {
                                                        const newHospedagens =
                                                            hospedagens
                                                        newHospedagens.splice(
                                                            key,
                                                            1
                                                        )
                                                        setHospedagens([
                                                            ...newHospedagens
                                                        ])
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    function getCampo(index: number, title: string, valor: any) {
        return (
            <div
                style={{
                    flexDirection: 'column'
                }}
            >
                <ThMobile key={index}>{title}</ThMobile>
                <TdMobile
                    key={index}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {valor}
                </TdMobile>
            </div>
        )
    }

    function getTabelaMobile() {
        return (
            <Center>
                <Box>
                    {hospedagens.map((tableItem, index) => {
                        if (tableItem) {
                            return (
                                <Quadro>
                                    <table style={{ width: '100%' }}>
                                        <TrMobile>
                                            {getCampo(index, '#', index + 1)}
                                            {getCampo(
                                                index,
                                                'Cidade',
                                                tableItem.Cidade
                                            )}
                                            {getCampo(
                                                index,
                                                'Check-in',
                                                tableItem.DataEntrada !==
                                                    undefined
                                                    ? tableItem.DataEntrada.toString()
                                                    : 'Não possui data de entrada'
                                            )}
                                            {getCampo(
                                                index,
                                                'Check-out',
                                                tableItem.DataSaida !==
                                                    undefined
                                                    ? tableItem.DataSaida.toString()
                                                    : 'Não possui data de saída'
                                            )}
                                            {getCampo(
                                                index,
                                                'Ponto de Referência',
                                                tableItem.Referencia
                                            )}
                                            {getCampo(
                                                index,
                                                'Observação',
                                                tableItem.Observacao
                                            )}
                                            {getCampo(
                                                index,
                                                'Remover',
                                                <Deletar
                                                    remover={() => {
                                                        const newHospedagens =
                                                            hospedagens
                                                        newHospedagens.splice(
                                                            index,
                                                            1
                                                        )
                                                        setHospedagens([
                                                            ...newHospedagens
                                                        ])
                                                    }}
                                                />
                                            )}
                                        </TrMobile>
                                    </table>
                                </Quadro>
                            )
                        }
                        return null
                    })}
                </Box>
            </Center>
        )
    }
}

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
    text-align: 'center';
`
const DisableForMobile = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`
const IsMobile = styled.span`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }  }
`
const Box = styled.div`
    border: solid 1px lightgray;
    border-radius: 15px;
    @media (max-width: 768px) {
        border: solid 0px lightgray;
        border-radius: 0px;
        margin-left: -0.4rem;
    }
`

const TrMobile = styled.tr`
    border: 0 !important;
    z-index: 10;
`
const ThMobile = styled.th`
    position: relative;
    width: auto !important;
    height: auto;
    color: #002a5d;
`
const TdMobile = styled.td`
    padding: 0.5rem 0rem !important;
    color: ${props => props.theme.colors.text};
    padding-left: 5px !important;
    color: #002a5d;
    .form-select {
        background-color: #f1f3f5;
    }
    .button.dropdown-item {
        background: #f1f3f5;
    }
`

const Quadro = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 8px;

    width: 342px;
    height: 0 auto;
    min-height: 218px;
    background: #f1f3f5;
    border: 1px solid #dee2e6;

    box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 1rem 0;
`
const Container = styled.div`
    @media (max-width: 768px) {
        border: solid 0px lightgray;
    }
`

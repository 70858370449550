import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button'
import { Header } from '../../../core/components/Header/Header'
import { ENDPOINTS } from '../../../services/config-http'
import { get } from '../../../services/http'
import { CadastroAereo } from '../../Aereo/CadastroAereo'

export const NovoAereo = () => {
    const history = useHistory()
    const { Id } = useParams() as any
    const [qtdViajantes, setQtdViajantes] = useState(1)

    useEffect(() => {
        const buscarViajantes = async () => {
            await get(`${ENDPOINTS.viajanteByIdSolicitacao}/${Id}`).then(
                ({ data: { Model } }) => {
                    setQtdViajantes(Model.length)
                }
            )
        }
        buscarViajantes()
    }, [])

    return (
        <BlankBoard>
            <Header
                title="Aéreo"
                actionArrowClick={() => history.push(`/cotar-viagem/${Id}`)}
            ></Header>
            <div className="row">
                <div className="col-9"></div>
                <div className="col-3" style={{ justifyContent: 'flex-end' }}>
                    <Button
                        type="button"
                        style={{
                            width: '75%',
                            minWidth: 'auto'
                        }}
                        colorType="quintiary"
                        onClick={() => {
                            history.push(`/cotar-viagem/${Id}`)
                        }}
                    >
                        Retornar para cotações
                    </Button>
                </div>
            </div>
            <CadastroAereo idSolicitacao={Id} qtdViajantes={qtdViajantes} />
        </BlankBoard>
    )
}

import { useField } from '@unform/core'
import React, {
    InputHTMLAttributes,
    useCallback,
    useEffect,
    useMemo,
    useRef
} from 'react'
import styled from 'styled-components'
import { Container, Error } from './styles'

type InputCurrencyProps = InputHTMLAttributes<HTMLInputElement> & {
    mask: string
    label: string
    name: string
    width?: string
    setValorOriginal?: (valor: number) => void
}

export const InputCurrency: React.FC<InputCurrencyProps> = ({
    label,
    name,
    mask,
    setValorOriginal,
    width = 'col-12',
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const { fieldName, defaultValue, registerField, error, clearError } =
        useField(name)

    const splitedMask = useMemo(() => mask.split(''), [mask])
    const maxLength = useMemo(
        () => (mask ? mask.length + 3 : undefined),
        [mask]
    )

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.value
            },
            setValue: (ref, value) => {
                ref.current.value = value
            },
            clearValue: ref => {
                ref.current.value = ''
            }
        })
    }, [fieldName, registerField])

    const handleChange = useCallback(
        (digit: string) => {
            const onlyNumbers = Number(digit.replace(/([^\d])+/gim, ''))
            if (setValorOriginal) {
                setValorOriginal(onlyNumbers / 100)
            }
            const splitedValue = String(onlyNumbers).split('')
            const totalMask = splitedMask.length - 1
            let ctrl = 0

            const formatedValue = splitedValue
                .reverse()
                .map(item => {
                    const maskItem = splitedMask[totalMask - ctrl]

                    if (maskItem !== '9') {
                        ctrl += 2
                        return item + maskItem
                    }
                    ctrl += 1
                    return item
                })
                .reverse()
                .join('')

            if (inputRef?.current?.value) {
                if (formatedValue.length <= 2) {
                    if (formatedValue.length === 1) {
                        inputRef.current.value = `R$ 0,0${formatedValue.slice(
                            -4
                        )}`
                    } else {
                        inputRef.current.value = `R$ 0,${formatedValue.slice(
                            -4
                        )}`
                    }
                } else {
                    inputRef.current.value = `R$ ${formatedValue}`
                }
            }
        },
        [splitedMask, setValorOriginal]
    )

    return (
        <div style={{ margin: '1rem 0' }}>
            <label htmlFor={label}>
                {' '}
                <Text>{label} </Text>
            </label>
            <Container>
                <input
                    className={`form-control ${
                        !inputRef.current?.value && error ? 'is-invalid' : ''
                    }`}
                    maxLength={maxLength}
                    id={name}
                    type="text"
                    name={name}
                    ref={inputRef}
                    autoComplete="off"
                    defaultValue={defaultValue}
                    onFocusCapture={clearError}
                    onChangeCapture={e => handleChange(e.currentTarget.value)}
                    // placeholder={label}
                    {...rest}
                />
                <Error>
                    {!inputRef.current?.value && error && (
                        <span>{error ?? 'Erro desconhecido'}</span>
                    )}
                </Error>
            </Container>
        </div>
    )
}

const Text = styled.p`
    font-size: 0.9rem;
`

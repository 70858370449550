import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'

import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Button } from '../../../../core/components/Button'
import { Input } from '../../../../core/components/Form/Input'
import { SelectRequester } from '../../../../core/components/Form/SelectWithRequest'
import { Header } from '../../../../core/components/Header/Header'
import { StepByStep } from '../../../../core/components/StepByStep'
import {
    ConfigTable,
    MapperItem,
    Table
} from '../../../../core/components/Table/v2/Table'
import { ENDPOINTS } from '../../../../services/config-http'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { StepByStepItem } from '../../../../contexts/interfaces/_stepByStep'
import { Space } from '../../../../core/components/Space'
import { Center } from '../../../../core/components/Center'
import { SOLICITACAO_VIAGEM } from '../../../../state/SolicitacaoViagem/Actions'
import { useGlobalStates } from '../../../../state/InitialState'
import { Subtitle } from '../../../../core/components/Subtitle'
import { get } from '../../../../services/http'
import { Solicitacao } from '../../../../contexts/interfaces/solicitacao'
import { ContainerPadding } from '../../../../core/components/ContainerPadding'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Toast } from '../../../../core/components/Toast'
import { Deletar } from '../../../../core/components/Deletar'
import { Empty } from '../../../../core/components/Empty'
import styled from 'styled-components'
import useWindowDimensions from '../../../../core/components/GetDimensions'

export const ServicosForm = () => {
    const {
        setGlobalState,
        state: { solicitacao }
    } = useGlobalStates()
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const [servicosAdicionados, setServicosAdicionados] = useState<Array<any>>(
        []
    )
    const [servicos, setServicos] = useState<Array<any>>([])
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })
    const [stepByStep, setStepByStep] = useState<StepByStepItem[]>([])
    const indexActiveStep = useRef<number>(0)
    const { height, width } = useWindowDimensions()

    useEffect(() => {
        const stepItem = localStorage.getItem('stepByStep')
        if (stepItem !== null) {
            const stepItemObj = JSON.parse(stepItem) as StepByStepItem[]
            indexActiveStep.current = stepItemObj.findIndex(
                s => s.name === 'Serviços'
            )
            stepItemObj[indexActiveStep.current].active = true
            for (let index = 0; index < indexActiveStep.current; index++) {
                stepItemObj[index].completed = true
            }
            setStepByStep(stepItemObj)
        }

        const getTiposServicos = async () => {
            await get(ENDPOINTS.servico)
                .then(({ data: { Model } }) => {
                    setServicos(Model)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        getTiposServicos()

        if (solicitacao === undefined) {
            const sol = localStorage.getItem('solicitacao')
            if (sol !== null) {
                const solicitacaoStorage = JSON.parse(sol) as Solicitacao
                setGlobalState(
                    SOLICITACAO_VIAGEM.REDUCER_NAME,
                    SOLICITACAO_VIAGEM.ACTION_ADD_SOLICITACAO,
                    solicitacaoStorage
                )
                const servico = solicitacaoStorage?.servico
                if (servico) setServicosAdicionados(servico)
            }
        }
        const servico = solicitacao?.servico
        if (servico) setServicosAdicionados(servico)
    }, [solicitacao])

    function _pipeShowService(value: any) {
        const tipo = servicos.filter(item => item.Id === value)[0]
        return <>{tipo?.Descricao || ''}</>
    }

    const handleFormSubmit: SubmitHandler = async (servico, { reset }) => {
        const newservicosAdicionados = [...servicosAdicionados]
        const hasServico = newservicosAdicionados.some(
            i => i.idServico === servico.idServico
        )
        if (hasServico) {
            setShowToast(true)
            setToast({
                message: 'Ops! Serviço já adicionado a lista',
                type: 'error'
            })
        } else {
            try {
                const schema = Yup.object().shape({
                    idServico: Yup.string().required(
                        'Escolha um tipo de serviço para adicionar!'
                    ),
                    observacao: Yup.string().required(
                        'Digite uma observação para adicionar!'
                    )
                })

                await schema.validate(servico, { abortEarly: false })
                servico.Id = servicosAdicionados.length + 1
                newservicosAdicionados.push(servico)
                setServicosAdicionados(newservicosAdicionados)
                reset()
                formRef.current?.setErrors({})
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const erros = MapFormErrors(err.inner)
                    formRef.current?.setErrors(erros)
                }
            }
        }
    }

    const nextStep = () => {
        setGlobalState(
            SOLICITACAO_VIAGEM.REDUCER_NAME,
            SOLICITACAO_VIAGEM.ACTION_ADD_SERVICO,
            servicosAdicionados
        )

        const sol = localStorage.getItem('solicitacao')
        if (sol !== null) {
            const solicitacaoObj = JSON.parse(sol) as Solicitacao
            solicitacaoObj.servico = servicosAdicionados
            localStorage.setItem('solicitacao', JSON.stringify(solicitacaoObj))
        } else {
            localStorage.setItem('solicitacao', JSON.stringify(solicitacao))
        }

        history.push(
            `/solicitar-viagem/${
                stepByStep[indexActiveStep.current + 1].caminho
            }`
        )
    }

    return (
        <>
            <BlankBoard>
                <Header
                    title="Solicitação de viagem"
                    actionArrowClick={() => {
                        if (indexActiveStep.current === 0) {
                            history.push('/solicitar-viagem/info')
                        } else {
                            history.push(
                                `/solicitar-viagem/${
                                    stepByStep[indexActiveStep.current - 1]
                                        .caminho
                                }`
                            )
                        }
                    }}
                ></Header>
                <StepByStep items={stepByStep}></StepByStep>
                <ContainerPadding>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="col-10">
                            <Subtitle>Cadastro de serviços</Subtitle>
                            <p style={{ padding: 'none' }}>
                                Clique em adicionar após o preenchimento dos
                                campos do formulário
                            </p>
                        </div>
                        <div
                            className="col-12 col-sm-2"
                            style={{
                                justifyContent: 'flex-end'
                            }}
                        >
                            <DisableForMobile>
                                <Button
                                    style={{
                                        width: '75%',
                                        minWidth: 'auto'
                                    }}
                                    colorType="quintiary"
                                    icon="arrow-right"
                                    onClick={() => {
                                        history.push(
                                            `/solicitar-viagem/${
                                                stepByStep[
                                                    indexActiveStep.current + 1
                                                ].caminho
                                            }`
                                        )
                                    }}
                                >
                                    Pular
                                </Button>
                            </DisableForMobile>
                        </div>
                    </div>

                    <Center>
                        <IsMobile>
                            <Button
                                colorType="btnMobile"
                                icon="arrow-right"
                                onClick={() => {
                                    history.push(
                                        `/solicitar-viagem/${
                                            stepByStep[
                                                indexActiveStep.current + 1
                                            ].caminho
                                        }`
                                    )
                                }}
                            >
                                Pular
                            </Button>
                        </IsMobile>
                    </Center>
                    <Space height="2rem"></Space>

                    <Form onSubmit={handleFormSubmit} ref={formRef}>
                        <div className="row">
                            <div className="col-12 col-md-5">
                                <SelectRequester
                                    name="idServico"
                                    label="Tipo do serviço"
                                    placeholder="Escolha um serviço"
                                    required={true}
                                    request={{
                                        endpoint: ENDPOINTS.servico,
                                        labelToSelect: 'Descricao',
                                        valueToSelect: 'Id'
                                    }}
                                ></SelectRequester>
                            </div>
                            <div className="col-12 col-md-7">
                                <Input
                                    name="observacao"
                                    label="Observação"
                                    placeholder="Por que você precisa deste serviço?"
                                    required={true}
                                ></Input>
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                                <div className="col-12 col-sm-3">
                                    <Center>
                                        <DisableForMobile>
                                            <Button
                                                style={{
                                                    marginRight: '-3rem'
                                                }}
                                                colorType="quaternary"
                                                icon="plus"
                                            >
                                                Adicionar
                                            </Button>
                                        </DisableForMobile>

                                        <IsMobile>
                                            <Button
                                                colorType="btnMobile"
                                                icon="plus"
                                            >
                                                Adicionar Serviço
                                            </Button>
                                        </IsMobile>
                                    </Center>
                                </div>
                            </div>
                        </div>
                    </Form>
                    {servicosAdicionados.length > 0 ? (
                        <Container>
                            {width && width > 768
                                ? getTabela()
                                : getTabelaMobile()}
                        </Container>
                    ) : (
                        <Empty text="Nenhum serviço adicionado" />
                    )}
                    <Space height="2rem"></Space>
                    <Center>
                        {servicosAdicionados.length ? (
                            <>
                                <DisableForMobile>
                                    <Button
                                        colorType="quaternary"
                                        onClick={() => nextStep()}
                                    >
                                        Próximo
                                    </Button>
                                </DisableForMobile>
                                <IsMobile>
                                    <Button
                                        colorType="btnMobile"
                                        onClick={() => nextStep()}
                                    >
                                        Próximo
                                    </Button>
                                </IsMobile>
                            </>
                        ) : null}
                    </Center>
                </ContainerPadding>
            </BlankBoard>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </>
    )

    function getTabela() {
        return (
            <div className="row" style={{ marginTop: '3rem' }}>
                <div className="col-12">
                    <label htmlFor=""></label>
                    <div
                        className="table-responsive"
                        style={{
                            border: 'solid 1px lightgray',
                            borderRadius: '15px'
                        }}
                    >
                        <table className="table">
                            <thead>
                                <tr
                                    style={{
                                        verticalAlign: 'middle'
                                    }}
                                >
                                    <th
                                        style={{
                                            width: '1rem',
                                            textAlign: 'left',
                                            borderBottom: 'solid 1px lightgray',
                                            color: '#6C7480'
                                        }}
                                    ></th>
                                    <th
                                        style={{
                                            width: '6rem',
                                            textAlign: 'left',
                                            borderBottom: 'solid 1px lightgray',
                                            color: '#6C7480'
                                        }}
                                    >
                                        <p>Serviço</p>
                                    </th>
                                    <th
                                        style={{
                                            width: '8rem',
                                            textAlign: 'left',
                                            borderBottom: 'solid 1px lightgray',
                                            color: '#6C7480'
                                        }}
                                    >
                                        <p>Observação</p>
                                    </th>
                                    <th
                                        style={{
                                            width: '3rem',
                                            textAlign: 'center',
                                            borderBottom: 'solid 1px lightgray',
                                            color: '#6C7480'
                                        }}
                                    >
                                        <p>Remover</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {servicosAdicionados.map((servico, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td
                                                    style={{
                                                        width: '1rem',
                                                        marginLeft: '1rem',
                                                        textAlign: 'left'
                                                    }}
                                                ></td>
                                                <td
                                                    style={{
                                                        width: '6rem',
                                                        marginLeft: '1rem',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <p>
                                                        {' '}
                                                        {_pipeShowService(
                                                            servico.idServico
                                                        )}
                                                    </p>
                                                </td>
                                                <td
                                                    style={{
                                                        width: '8rem',
                                                        marginLeft: '1rem',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <p>{servico.observacao}</p>
                                                </td>
                                                <td
                                                    style={{
                                                        width: '3rem',
                                                        marginLeft: '1rem',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <Deletar
                                                        remover={() => {
                                                            const newServicos =
                                                                servicosAdicionados
                                                            newServicos.splice(
                                                                index,
                                                                1
                                                            )
                                                            console.log(
                                                                newServicos
                                                            )
                                                            setServicosAdicionados(
                                                                [...newServicos]
                                                            )
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    function getCampo(index: number, title: string, valor: any) {
        return (
            <div
                style={{
                    flexDirection: 'column'
                }}
            >
                <ThMobile key={index}>{title}</ThMobile>
                <TdMobile
                    key={index}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {valor}
                </TdMobile>
            </div>
        )
    }
    function getTabelaMobile() {
        return (
            <Center>
                <Box>
                    {servicosAdicionados.map((tableItem, index) => {
                        if (tableItem) {
                            return (
                                <Quadro>
                                    <table style={{ width: '100%' }}>
                                        <TrMobile>
                                            {getCampo(
                                                index,
                                                'Serviço',
                                                _pipeShowService(
                                                    tableItem.idServico
                                                )
                                            )}
                                            {getCampo(
                                                index,
                                                'Observação',
                                                tableItem.observacao
                                            )}
                                            {getCampo(
                                                index,
                                                'Remover',
                                                <Deletar
                                                    remover={() => {
                                                        const newServicos =
                                                            servicosAdicionados
                                                        newServicos.splice(
                                                            index,
                                                            1
                                                        )
                                                        console.log(newServicos)
                                                        setServicosAdicionados([
                                                            ...newServicos
                                                        ])
                                                    }}
                                                />
                                            )}
                                        </TrMobile>
                                    </table>
                                </Quadro>
                            )
                        }
                        return null
                    })}
                </Box>
            </Center>
        )
    }
}

const DisableForMobile = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`
const IsMobile = styled.span`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`
const Box = styled.div`
    border: solid 1px lightgray;
    border-radius: 15px;
    @media (max-width: 768px) {
        border: solid 0px lightgray;
        border-radius: 0px;
        margin-left: -0.4rem;
    }
`

const TrMobile = styled.tr`
    border: 0 !important;
    z-index: 10;
`
const ThMobile = styled.th`
    position: relative;
    width: auto !important;
    height: auto;
    color: #002a5d;
`
const TdMobile = styled.td`
    padding: 0.5rem 0rem !important;
    color: ${props => props.theme.colors.text};
    padding-left: 5px !important;
    color: #002a5d;
    .form-select {
        background-color: #f1f3f5;
    }
    .button.dropdown-item {
        background: #f1f3f5;
    }
`

const Quadro = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 8px;

    width: 342px;
    height: 0 auto;
    min-height: 218px;
    background: #f1f3f5;
    border: 1px solid #dee2e6;

    box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 1rem 0;
`
const Container = styled.div`
    @media (max-width: 768px) {
        border: solid 0px lightgray;
    }
`

const mountStepItems = (): StepByStepItem[] => [
    {
        name: 'Roteiro',
        active: false,
        completed: true,
        icon: 'map-marker-alt'
    },
    {
        name: 'Aéreo',
        active: false,
        completed: true,
        icon: 'plane'
    },
    {
        name: 'Hospedagem',
        active: false,
        completed: true,
        icon: 'building'
    },
    {
        name: 'Serviços',
        active: true,
        completed: false,
        icon: 'list-ul'
    },
    {
        name: 'Adiantamento',
        active: false,
        completed: false,
        icon: 'dollar-sign'
    },
    {
        name: 'Rateio',
        active: false,
        completed: false,
        icon: 'random'
    }
]

import React, { useState } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { Button } from '../../../core/components/Button'

const imageGrid = {
    border: '1px solid #e7e7e7',
    height: 'auto',
    width: '98%',
    marginTop: '2rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    marginBottom: '20px',
    display: 'flex',
    align: 'center'
}

const gridDeFora = {
    marginLeft: '0%',
    marginRight: '0%',
    width: '100%',
    height: 'auto',
    display: 'inline',
    align: 'center'
}

const testandoBotoes = {
    display: 'flex',
    maxWidth: '30px',
    maxHeight: '30opx',
    marginTop: '0.5rem'
}

const botoesProps = {
    width: '2rem',
    marginLeft: '5px',
    marginBottom: '5px',
    height: '1rem'
}

const divDeDentro = {
    paddingLeft: '15%',
    paddingTop: '8%',
    width: '50rem',
    height: '25rem'
}

interface Props {
    linkImg: string
    flagShowButtons: boolean
}

export const ImagePrint = ({ linkImg = '', flagShowButtons }: Props) => {
    const [positionRotation, setPositionRotation] = useState(90)
    const [gridTestZoom, setGridTestZoom] = useState({
        marginTop: '1px',
        marginLeft: '1px',
        width: 'auto',
        height: 'auto',
        maxWidth: 'auto',
        maxHeight: '300px',
        display: 'flex',
        transform: '',
        transition: 'all 0.5s ease-in-out'
    })

    const rotateImg = async () => {
        await setPositionRotation(positionRotation + 90)
        setGridTestZoom({
            ...gridTestZoom,
            transform: `rotate(${positionRotation}deg) translate(0%, 0%)`
        })
    }

    return (
        <div style={imageGrid}>
            <div style={gridDeFora}>
                <TransformWrapper initialScale={1}>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                            <TransformComponent>
                                <div style={divDeDentro}>
                                    <img style={gridTestZoom} src={linkImg} />
                                </div>
                            </TransformComponent>
                            <div style={testandoBotoes}>
                                <Button
                                    icon="plus"
                                    style={botoesProps}
                                    className={
                                        flagShowButtons ? '' : 'invisible-item'
                                    }
                                    colorType="primary"
                                    onlyIcon={true}
                                    onClick={() => zoomIn()}
                                ></Button>

                                <Button
                                    icon="minus"
                                    style={botoesProps}
                                    className={
                                        flagShowButtons ? '' : 'invisible-item'
                                    }
                                    colorType="primary"
                                    onlyIcon={true}
                                    onClick={() => zoomOut()}
                                ></Button>

                                <Button
                                    icon="redo"
                                    style={botoesProps}
                                    className={
                                        flagShowButtons ? '' : 'invisible-item'
                                    }
                                    colorType="primary"
                                    onlyIcon={true}
                                    onClick={() => rotateImg()}
                                ></Button>
                            </div>
                        </React.Fragment>
                    )}
                </TransformWrapper>
            </div>
        </div>
    )
}

import { icon } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

interface Props {
    message: string
    boldMessage?: string // Ben add
    secounds?: number
    fixed?: boolean
    status?: boolean
    icon?: string
    type:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'danger'
        | 'warning'
        | 'info'
        | 'light'
        | 'dark'
        | 'exclamation'
}
/**
 * @author Andrews - 22-06-2021
 * @param message mensagem que será exibida no alert
 * @param secounds tempo em milésimos que serão mostrado o alert
 * @param fixed flag para definir se o alert é fixo
 * @param type tipo do alerta, pode ser acessado nesta url https://getbootstrap.com/docs/5.0/components/alerts/
 * @returns
 */
export const Alert = ({
    message,
    boldMessage = '',
    secounds,
    fixed = false,
    type,
    status = true,
    icon = ''
}: Props) => {
    useEffect(() => {
        if (!fixed) {
            setTimeout(() => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                status = false
            }, secounds || 5000)
        }
    }, [])

    return (
        <div className={!status ? 'invisible-item' : ''}>
            <div
                className={`alert alert-${type} alert-dismissible fade show d-flex align-items-center`}
                role="alert"
                style={{
                    borderRadius: '8px',
                    border: '0px',
                    alignSelf: 'center',
                    fontSize: '.9rem',
                    font: 'Montserrat, sas-serif'
                }}
            >
                {icon !== '' ? (
                    <FontAwesomeIcon
                        icon={icon}
                        style={{
                            marginRight: '0.75rem',
                            fontSize: '1.5rem'
                        }}
                    />
                ) : null}
                {boldMessage !== '' ? (
                    <span
                        style={{
                            fontWeight: '600',
                            marginRight: '0.5rem'
                        }}
                    >
                        {boldMessage}
                    </span>
                ) : null}
                {message}
                {!fixed ? (
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                    ></button>
                ) : null}
            </div>
        </div>
    )
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import styled from 'styled-components'
import * as Yup from 'yup'
import _ from 'lodash'
import { BlankBoard } from '../../core/components/BlankBoard'
import { useHistory } from 'react-router-dom'
import { Header } from '../../core/components/Header/Header'
import { Disclaimer } from '../../core/components/Disclaimer'
import { Space } from '../../core/components/Space'
import { FormHandles, SubmitHandler } from '@unform/core'
import { ENDPOINTS, PESQUISA } from '../../services/config-http'
import { apiClient, get, patch, post, put } from '../../services/http'
import { Node, TreeNode } from '../../core/components/Tree'
import { UrlMountQuery } from '../../core/utils/UrlMountQuery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import {
    ConfigTable,
    MapperItem,
    Table
} from '../../core/components/Table/v2/Table'
import { Button } from '../../core/components/Button'
import { AutoCompleteSelect } from '../../core/components/Form/v3/AutoCompleteSelect'
import { Usuario } from '../../contexts/interfaces/usuario'
import { useAlert } from '../../hooks/AlertToast'
import { SelectRequester } from '../../core/components/Form/SelectWithRequest'
import { Input } from '../../core/components/Form/Input'
import { LineSeparator } from '../../core/components/LineSeparator'
import { MapFormErrors } from '../../core/utils/MapFormErrors'
import { DatePicker } from '../../core/components/DatePicker'
import { ContainerPadding } from '../../core/components/ContainerPadding'
import { UserContext } from '../../contexts/UserContext'
import { Alert } from '../../core/components/Alert'

export const Hierarquia = () => {
    const history = useHistory()
    const userFormRef = useRef<FormHandles>(null)
    const hierarquiaFormRef = useRef<FormHandles>(null)
    const { setAlert, showAlert } = useAlert()
    const [flagEdit, setFlagEdit] = useState<boolean>(false)
    const [flagViewForm, setFlagViewForm] = useState<boolean>(false)
    const [flagRefresh, setFlagRefresh] = useState<boolean>(false)
    const [flagPeriodo, setFlagPeriodo] = useState<boolean>(false)

    const [selectedNode, setSelectedNode] = useState<Node>()
    const [hierarquia, setHierarquia] = useState<[]>([])
    const [hierarquiaByIdUser, setHierarquiaByIdUser] = useState<[]>([])
    const [users, setUsers] = useState<Array<Usuario>>([])
    const [userSelected, setUserSelected] = useState<Array<Usuario>>([])
    const [userCodeSelected, setUserCodeSelected] = useState(0)
    const [selectedUser, setSelectedUser] = useState<Usuario>()
    const [abaAtual, setAbaAtual] = useState('Adicionar Hierarquias')

    const [dotColor, setDotColor] = useState('')
    const [errors, setErrors] = useState('')
    const [administrador, setAdministrador] = useState(false)
    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(!!dadosUsuario.Administrador)
    }, [dadosUsuario])

    const openNode = (node: Node, color: string) => {
        setSelectedNode(node)
        setDotColor(color)
        getUsersByHierarquia(node.Id)
        return getHierarquiaByIdHierarquiaPai(node.Id).then(
            (res: Node[]) => res
        )
    }

    const tableConfig: ConfigTable = {
        textNoHasData: 'Nenhuma hierarquia selecionada!',
        delete: false,
        edit: false
    }

    const mapper: MapperItem[] = [
        {
            field: 'Codigo',
            replaceTo: 'Cod'
        },
        {
            field: 'Status',
            delete: true
        },
        {
            field: 'TipoUsuario',
            delete: true
        },
        {
            field: 'IdHierarquia',
            delete: true
        },
        {
            field: 'IdFilial',
            delete: true
        },
        {
            field: 'IdPoliticaViagem',
            delete: true
        },
        {
            field: 'Email',
            delete: true
        },
        {
            field: 'Id',
            delete: true
        },
        {
            field: 'AprovaViagem',
            delete: true
        },
        {
            field: 'AprovaPrestacao',
            delete: true
        },
        {
            field: 'Administrador',
            delete: true
        }
    ]

    useEffect(() => {
        getHierarquiaInit()
    }, [])

    const getHierarquiaInit = () => {
        get(`${ENDPOINTS.hierarquia}${PESQUISA}`)
            .then(
                ({
                    data: {
                        Model: { Items }
                    }
                }) => {
                    setHierarquia(Items)
                    const btnFirst = document.getElementById('btn-first') // captura do botão
                    btnFirst?.click() // click simulado para abertura dos itens
                }
            )
            .catch(err => {
                console.error(err)
            })
    }

    const getUsersByHierarquia = (id = 0) => {
        setSelectedUser(undefined)
        setFlagRefresh(true)
        setErrors('')
        return get(
            UrlMountQuery(
                { IdHierarquia: id },
                `${ENDPOINTS.conexaoUsuarioHierarquia}`
            )
        ).then(({ data: { Items } }) => {
            setFlagRefresh(false)
            if (Items?.length === 0) {
                setUsers([
                    { Codigo: '#', Nome: 'Essa hierarquia não possui usuários' }
                ])
            } else {
                setUsers(Items)
            }
        })
    }

    const getHierarquiaByUser = (id = 0) => {
        setSelectedUser(undefined)
        setFlagRefresh(true)
        setErrors('')
        return get(
            UrlMountQuery(
                { IdUsuario: id },
                `${ENDPOINTS.conexaoUsuarioHierarquiaCodigo}${'/USUARIO'}`
            )
        ).then(({ data: { Items } }) => {
            setFlagRefresh(false)
            if (Items?.length === 0) {
                setUsers([
                    { Codigo: '#', Nome: 'Esse usuário não possui hierarquia.' }
                ])
            } else {
                setUsers(Items)
            }
        })
    }

    const getHierarquiaByIdUsuario = (id: any) => {
        get(
            `${ENDPOINTS.conexaoUsuarioHierarquiaIdUsuario.replace(
                ':idUsuario',
                id
            )}`
        )
            .then(dados => {
                setHierarquiaByIdUser(dados?.data?.Result.Items)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getHierarquiaByIdHierarquiaPai = (id = 0) => {
        setFlagViewForm(false)
        const payload = id === 0 ? {} : { IdHierarquiaPai: id }
        return get(UrlMountQuery(payload, `${ENDPOINTS.hierarquia}${PESQUISA}`))
            .then(
                ({
                    data: {
                        Model: { Items }
                    }
                }) => Items
            )
            .catch(err => {
                console.error(err)
            })
    }

    const handleSubmit: SubmitHandler = async ({ user }) => {
        setErrors('')
        if (user.IdHierarquia === selectedNode?.Id) {
            setErrors('O Colaborador já pertence a essa hierarquia')
        } else if (user === undefined) {
            setErrors('Nenhum colaborador localizado')
        } else {
            get(
                UrlMountQuery(
                    { Id: user.IdHierarquia },
                    `${ENDPOINTS.hierarquia}${PESQUISA}`
                )
            ).then(
                ({
                    data: {
                        Model: { Items }
                    }
                }) => {
                    user.HierarquiaNome = Items[0].Nome
                    setSelectedUser(user)
                }
            )
        }
    }

    const changeUserHierarquia = () => {
        showAlert(false)
        post(ENDPOINTS.conexaoUsuarioHierarquiaIncluir, {
            IdUsuario: selectedUser?.Id,
            Status: selectedUser?.Status,
            IdHierarquia: selectedNode?.Id
        })
            .then(_ => {
                setAlert({
                    type: 'success',
                    time: 5000,
                    title: 'Sucesso!',
                    subTitle: 'Hierarquia de usuário alterada!'
                })
                showAlert(true)
                getUsersByHierarquia(selectedNode?.Id)
                setSelectedUser(undefined)
                userFormRef.current?.reset()
            })
            .catch(err => console.error(err))
    }

    const setEditHierarquia = () => {
        setFlagViewForm(true)
        setFlagEdit(true)
        setTimeout(() => {
            hierarquiaFormRef.current?.setData(selectedNode as any)
        }, 1000)
    }

    const setDeleteHierarquia = () => {
        patch(ENDPOINTS.inativarHierarquia, { Id: selectedNode?.Id })
            .then(_ => {
                setAlert({
                    type: 'success',
                    time: 5000,
                    title: 'Sucesso!',
                    subTitle: 'Hierarquia desativada!'
                })
                showAlert(true)
                setSelectedUser(undefined)
                setSelectedNode(undefined)
                setFlagEdit(false)
                setFlagViewForm(false)
                setUsers([])
                setFlagRefresh(false)
                getHierarquiaInit()
                userFormRef.current?.reset()
                hierarquiaFormRef.current?.reset()
            })
            .catch(err => console.error(err))
    }

    const changeAndCreateHierarquia: SubmitHandler = async data => {
        data = {
            ...selectedNode,
            IdFilial: data.IdFilial,
            Nome: data.Nome,
            Setor: data.IdSetor,
            Regiao: data.IdRegiao
        }
        try {
            const schema = Yup.object().shape({
                Nome: Yup.string().required('O Campo nome é obrigatório')
            })

            await schema.validate(data, { abortEarly: false })
            setFlagRefresh(true)
            if (!flagEdit) {
                data.IdHierarquiaPai = selectedNode?.Id

                post(ENDPOINTS.hierarquia, data)
                    .then(_ => {
                        setAlert({
                            type: 'success',
                            time: 5000,
                            title: 'Sucesso!',
                            subTitle: 'Hierarquia adicionada!'
                        })
                        showAlert(true)

                        setSelectedUser(undefined)
                        setSelectedNode(undefined)
                        setFlagEdit(false)
                        setFlagViewForm(false)
                        setUsers([])
                        setFlagRefresh(false)
                        getHierarquiaInit()
                        hierarquiaFormRef.current?.reset()
                    })
                    .catch(err => console.error(err))
            } else {
                put(ENDPOINTS.hierarquia, data)
                    .then(_ => {
                        setAlert({
                            type: 'success',
                            time: 5000,
                            title: 'Sucesso!',
                            subTitle: 'Hierarquia Alterada!'
                        })
                        showAlert(true)
                        setSelectedUser(undefined)
                        setSelectedNode(undefined)
                        setFlagEdit(false)
                        setFlagViewForm(false)
                        setUsers([])
                        setFlagRefresh(false)
                        getHierarquiaInit()
                        hierarquiaFormRef.current?.reset()
                    })
                    .catch(err => console.error(err))
            }

            hierarquiaFormRef.current?.setErrors({})
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                _.debounce(() => {
                    showAlert(false)
                }, 5000)
                hierarquiaFormRef.current?.setErrors(erros)
            }
        }
    }

    const hierarquiaForm = () => (
        <Form ref={hierarquiaFormRef} onSubmit={changeAndCreateHierarquia}>
            <div className="row">
                <div className="col-12">
                    <Space height="1rem"></Space>
                    <h2>{flagEdit ? 'Editar' : 'Adicionar sub-nível'}</h2>
                    <LineSeparator style={{ margin: '0' }}></LineSeparator>
                </div>
                <div className="col-4">
                    <SelectRequester
                        className="scale-in-ver-top"
                        request={{
                            endpoint: ENDPOINTS.regiao,
                            labelToSelect: 'Nome',
                            valueToSelect: 'Id'
                        }}
                        name="IdRegiao"
                        label="Região"
                    ></SelectRequester>
                </div>
                <div className="col-4">
                    <SelectRequester
                        className="scale-in-ver-top"
                        items={true}
                        request={{
                            endpoint: ENDPOINTS.filial,
                            labelToSelect: 'Nome',
                            valueToSelect: 'Id'
                        }}
                        name="IdFilial"
                        label="Filial"
                    ></SelectRequester>
                </div>
                <div className="col-4">
                    <SelectRequester
                        className="scale-in-ver-top"
                        request={{
                            endpoint: ENDPOINTS.setor,
                            labelToSelect: 'Nome',
                            valueToSelect: 'Id'
                        }}
                        name="IdSetor"
                        label="Setor"
                    ></SelectRequester>
                </div>
                <div className="col-12 col-sm-8">
                    <Input
                        name="Nome"
                        label="Nome"
                        required={true}
                        placeholder="Digite um nome para a hierarquia"
                    ></Input>
                </div>
                <div className="col-12 col-sm-4 d-flex align-items-center">
                    <ContentButton>
                        <Button
                            icon={flagEdit ? 'save' : 'plus'}
                            colorType="quintiary"
                            type="submit"
                        >
                            {flagEdit ? 'Salvar' : 'Adicionar'}
                        </Button>
                    </ContentButton>
                </div>
            </div>
        </Form>
    )

    const usuarioForm = () => (
        <>
            <Form ref={userFormRef} onSubmit={handleSubmit}>
                <div className="row d-flex">
                    <div className="col-12 col-sm-8">
                        <AutoCompleteSelect
                            name="user"
                            label="Novo aprovador"
                            placeholder="Digite nome ou matrícula do colaborador"
                            required={true}
                            request={{
                                endpoint: ENDPOINTS.usuarioPesquisar,
                                firstLabelToSelect: 'Codigo',
                                secoundLabelToSelect: 'Nome',
                                valuesToSelect: [
                                    'Codigo',
                                    'Nome',
                                    'Status',
                                    'Id',
                                    'IdHierarquia'
                                ],
                                queryTextFilter: 'Nome',
                                queryNumberFilter: 'Codigo'
                            }}
                        ></AutoCompleteSelect>
                    </div>

                    <div
                        className="col-12 col-sm-4"
                        style={{
                            paddingTop: '1.6rem'
                        }}
                    >
                        <Button
                            style={{
                                minWidth: 'auto',
                                width: '100%',
                                padding: '0',
                                justifyContent: 'right',
                                marginBottom: '0.4rem'
                            }}
                            onClick={() => {
                                setFlagPeriodo(!flagPeriodo)
                                setFlagViewForm(false)
                            }}
                            type="button"
                            colorType="quaternary"
                            icon={flagPeriodo ? 'angle-up' : 'angle-down'}
                        >
                            Adicionar período
                        </Button>
                        <Button
                            style={{
                                minWidth: 'auto',
                                width: '100%',
                                padding: '0',
                                justifyContent: 'right'
                            }}
                            colorType="quaternary"
                            type="submit"
                            icon="plus"
                        >
                            Adicionar
                        </Button>
                    </div>

                    {flagPeriodo && (
                        <>
                            <div className="col-12 col-sm-4">
                                <DatePicker
                                    name="inicioDtVigencia"
                                    label="Início da vigência"
                                    placeholder="Data da início"
                                    required={true}
                                ></DatePicker>
                            </div>
                            <div className="col-12 col-sm-4">
                                <DatePicker
                                    name="fimDtVitencia"
                                    label="Fim da vigência"
                                    placeholder="Data de fim"
                                    required={true}
                                ></DatePicker>
                            </div>
                        </>
                    )}
                </div>
                {showTable()}
            </Form>
        </>
    )
    const showTable = () => (
        <div className="col-12">
            <span className="danger">{errors}</span>
            {selectedUser && selectedNode && errors === '' ? (
                <span>
                    <h5>Informações</h5>
                    <div>
                        <b>Nome:</b> {selectedUser.Nome}
                    </div>
                    <div>
                        <b>Matrícula:</b> {selectedUser.Codigo}
                    </div>
                    <div>
                        <b>Hierarquia Atual:</b> {selectedUser.HierarquiaNome}
                    </div>
                    <div>
                        <b>Nova Hierarquia:</b> {selectedNode.Nome}
                    </div>
                    <div className="col-4">
                        <ContentButton>
                            <Button
                                colorType="secondary"
                                type="button"
                                onClick={() => changeUserHierarquia()}
                            >
                                Confirmar
                            </Button>
                        </ContentButton>
                    </div>
                </span>
            ) : null}
        </div>
    )

    const handlerDelet = (id: any) => {
        showAlert(false)
        patch(ENDPOINTS.conexaoUsuarioHierarquiaInativar, {
            idUsuario: userSelected?.Id,
            IdHierarquia: id
        })
            .then(_ => {
                setAlert({
                    type: 'success',
                    time: 5000,
                    title: 'Sucesso!',
                    subTitle: 'Usuário Inativado!'
                })
                showAlert(true)
                getUsersByHierarquia(selectedNode?.Id)
                setSelectedUser(undefined)
                userFormRef.current?.reset()
            })
            .catch(err => console.error(err))
    }

    const getTh = (nomeCampo: string, tamanho: string) => (
        <th
            style={{
                width: tamanho,
                textAlign: 'left',
                borderBottom: 'solid 1px lightgray',
                color: '#6C7480'
            }}
        >
            <p>{nomeCampo}</p>
        </th>
    )

    const getTd = (resCampo: any, tamanho: string) => (
        <td
            style={{
                width: tamanho,
                marginLeft: '1rem',
                textAlign: 'left'
            }}
        >
            <p>{resCampo}</p>
        </td>
    )

    const showTable2 = () => (
        <table
            className="table"
            style={{
                borderRadius: '20px'
            }}
        >
            <thead>
                <tr
                    style={{
                        verticalAlign: 'middle'
                    }}
                >
                    {getTh('', '1rem')}
                    {getTh('Cod', '6rem')}
                    {getTh('Nome', '8rem')}
                </tr>
            </thead>
            <tbody>
                {users?.map((usuario, index) => {
                    if (
                        !userCodeSelected ||
                        userCodeSelected?.toString() == usuario.Codigo
                    ) {
                        return (
                            <>
                                <Tr
                                    key={index}
                                    onClick={() => {
                                        setUserSelected(usuario)
                                        getHierarquiaByIdUsuario(usuario?.Id)
                                    }}
                                >
                                    {getTd('', '1rem')}
                                    {getTd(usuario?.Codigo, '6rem')}
                                    {getTd(usuario?.Nome, '8rem')}
                                </Tr>
                            </>
                        )
                    }
                })}
            </tbody>
        </table>
    )
    const showTable3 = () =>
        userSelected?.Codigo ? (
            <table
                className="table"
                style={{
                    borderRadius: '20px'
                }}
            >
                <thead>
                    <Tr
                        style={{
                            verticalAlign: 'middle',
                            background: '#fff',
                            border: '0'
                        }}
                    >
                        {getTh('Cod', '6rem')}
                        {getTh('Nome', '10rem')}
                    </Tr>
                </thead>
                <tbody>
                    <>
                        <Tr>
                            {getTd('', '1rem')}
                            {getTd(userSelected?.Codigo, '6rem')}
                            {getTd(userSelected?.Nome, '10rem')}
                            {getTd(userSelected?.Nome?.length, '10rem')}
                        </Tr>
                    </>
                    <p>Hirarquias:</p>
                    <ul>
                        {hierarquiaByIdUser?.map((item, index) => {
                            return (
                                <li style={{ color: '#e74c3c' }} key={index}>
                                    <span style={{ color: '#000' }}>
                                        {item?.Nome}
                                        <button
                                            style={{
                                                border: '0',
                                                background: '#fff'
                                            }}
                                            onClick={e =>
                                                handlerDelet(item?.Id)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon="trash-alt"
                                                color="#FF6262"
                                                className="ms-2"
                                            ></FontAwesomeIcon>
                                        </button>
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                </tbody>
            </table>
        ) : (
            <div className="col-12">
                {flagRefresh ? null : (
                    <CustomSpacingTableList>
                        <Table
                            dataTable={''}
                            mapper={mapper}
                            config={tableConfig}
                        ></Table>
                    </CustomSpacingTableList>
                )}
            </div>
        )

    const apenasNumero = (teste: string) => {
        const numsStr = teste.replace(/[^0-9]/g, '')
        return parseInt(numsStr)
    }

    const showForm = () => (
        <Form
            ref={userFormRef}
            onSubmit={res => {
                console.log(res)
            }}
        >
            <div className="row d-flex align-items-center">
                <div className="col-12 col-sm-8">
                    <AutoCompleteSelect
                        type="button"
                        name="user"
                        label="Novo aprovador"
                        placeholder="Digite nome ou matrícula do colaborador"
                        required={true}
                        request={{
                            endpoint: ENDPOINTS.usuarioPesquisar,
                            firstLabelToSelect: 'Codigo',
                            secoundLabelToSelect: 'Nome',
                            valuesToSelect: [
                                'Codigo',
                                'Nome',
                                'Status',
                                'Id',
                                'IdHierarquia'
                            ],
                            queryTextFilter: 'Nome',
                            queryNumberFilter: 'Codigo'
                        }}
                        onChange={res => {
                            res?.label &&
                                setUserCodeSelected(apenasNumero(res?.label))
                        }}
                    ></AutoCompleteSelect>
                </div>

                {flagPeriodo && (
                    <>
                        <div className="col-12 col-sm-4">
                            <DatePicker
                                name="inicioDtVigencia"
                                label="Início da vigência"
                                placeholder="Data da início"
                                required={true}
                            ></DatePicker>
                        </div>
                        <div className="col-12 col-sm-4">
                            <DatePicker
                                name="fimDtVitencia"
                                label="Fim da vigência"
                                placeholder="Data de fim"
                                required={true}
                            ></DatePicker>
                        </div>
                    </>
                )}
            </div>
        </Form>
    )

    return (
        <div>
            <BlankBoard>
                <Header
                    title="Hierarquias"
                    actionArrowClick={() => history.goBack()}
                ></Header>
                {administrador ? (
                    <ContainerPadding>
                        <ul
                            className="nav nav-tabs"
                            id="myTab"
                            role="tablist"
                            style={{ margin: '0vh 5vh' }}
                        >
                            <li
                                className="nav-item"
                                style={navItem}
                                role="presentation"
                            >
                                <button
                                    className="nav-link active"
                                    id="addhierarquia-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#addhierarquia"
                                    type="button"
                                    role="tab"
                                    aria-controls="addhierarquia"
                                    aria-selected="true"
                                    onClick={e =>
                                        setAbaAtual('Adicionar hierarquias')
                                    }
                                >
                                    <FontAwesomeIcon icon="plus" />
                                </button>
                            </li>
                            <li
                                className="nav-item"
                                style={navItem}
                                role="presentation"
                            >
                                <button
                                    className="nav-link"
                                    id="hierarquia-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#hierarquia"
                                    type="button"
                                    role="tab"
                                    aria-controls="hierarquia"
                                    aria-selected="true"
                                    onClick={e =>
                                        setAbaAtual('Visualizar hierarquias')
                                    }
                                >
                                    <FontAwesomeIcon icon="list-ul" />
                                </button>
                            </li>
                            <Title>{abaAtual}</Title>
                        </ul>
                        <div
                            style={{
                                fontSize: '1.2rem',
                                display: 'flex',
                                width: '62%',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                color: '#5F8DD3'
                            }}
                        ></div>
                        <article className="tab-content" id="myTabContent">
                            <div
                                id="addhierarquia"
                                className="tab-pane fade show active"
                                role="tabpanel"
                                aria-labelledby="addhierarquia-tab"
                            >
                                <Infos
                                    className="tab-pane fade show active"
                                    role="tabpanel"
                                    aria-labelledby="info-tab"
                                >
                                    <div>
                                        <Alert
                                            fixed={true}
                                            icon="info-circle"
                                            type="secondary"
                                            message="Explore as hierarquias
                                            clicando em seu nome e
                                            visualizando na sessão à
                                            direita os usuários
                                            pertencentes"
                                        ></Alert>
                                        <Space height="2em"></Space>
                                        <div className="row">
                                            <div
                                                className="col-12 col-sm-4 rightLine"
                                                style={{
                                                    minHeight: '250px',
                                                    overflow: 'auto'
                                                }}
                                            >
                                                <div className="d-tree">
                                                    <ul className="d-flex d-tree-container flex-column">
                                                        {hierarquia.map(
                                                            (
                                                                tree: Node,
                                                                index: number
                                                            ) => {
                                                                return (
                                                                    <TreeNode
                                                                        key={
                                                                            index
                                                                        }
                                                                        node={
                                                                            tree
                                                                        }
                                                                        color={
                                                                            '#84B5FF'
                                                                        }
                                                                        openNodeFunction={
                                                                            openNode
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-1"></div>
                                            <div className="col-12 col-sm-7">
                                                <div className="row">
                                                    {selectedNode ===
                                                    undefined ? null : (
                                                        <>
                                                            <div className="d-flex cursor-pointer align-items-center ms-3">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faCircle
                                                                    }
                                                                    color={
                                                                        dotColor
                                                                    }
                                                                />
                                                                <div
                                                                    className="col-4 d-tree-head"
                                                                    style={{
                                                                        marginLeft:
                                                                            '10px',
                                                                        textAlign:
                                                                            'left',
                                                                        fontSize:
                                                                            '1rem',
                                                                        fontWeight: 600
                                                                    }}
                                                                >
                                                                    {
                                                                        selectedNode?.Nome
                                                                    }
                                                                </div>
                                                                {selectedNode.IdHierarquiaPai ==
                                                                undefined ? null : (
                                                                    <div>
                                                                        <FontAwesomeIcon
                                                                            icon="pencil-alt"
                                                                            onClick={() =>
                                                                                setEditHierarquia()
                                                                            }
                                                                        ></FontAwesomeIcon>
                                                                        <FontAwesomeIcon
                                                                            icon="trash-alt"
                                                                            color="#FF6262"
                                                                            className="ms-2"
                                                                            onClick={() =>
                                                                                setDeleteHierarquia()
                                                                            }
                                                                        ></FontAwesomeIcon>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                                <Button
                                                                    colorType="quaternary"
                                                                    onClick={() => {
                                                                        setFlagEdit(
                                                                            false
                                                                        )
                                                                        setFlagViewForm(
                                                                            false
                                                                        )
                                                                    }}
                                                                    style={{
                                                                        minWidth:
                                                                            'auto',
                                                                        padding:
                                                                            '0'
                                                                    }}
                                                                >
                                                                    + Adicionar
                                                                    aprovador
                                                                </Button>
                                                                <Button
                                                                    colorType="quaternary"
                                                                    onClick={() => {
                                                                        setFlagEdit(
                                                                            false
                                                                        )
                                                                        setFlagViewForm(
                                                                            !flagViewForm
                                                                        )
                                                                        hierarquiaFormRef.current?.reset()
                                                                    }}
                                                                    style={{
                                                                        minWidth:
                                                                            'auto',
                                                                        padding:
                                                                            '0',
                                                                        marginLeft:
                                                                            '0.3rem'
                                                                    }}
                                                                >
                                                                    + Adicionar
                                                                    sub-nível
                                                                </Button>
                                                            </div>
                                                            <div className="col-12">
                                                                {flagViewForm
                                                                    ? hierarquiaForm()
                                                                    : usuarioForm()}
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className="col-12">
                                                        {flagRefresh ? null : (
                                                            <CustomSpacingTableList>
                                                                <Table
                                                                    dataTable={
                                                                        users
                                                                    }
                                                                    mapper={
                                                                        mapper
                                                                    }
                                                                    config={
                                                                        tableConfig
                                                                    }
                                                                ></Table>
                                                            </CustomSpacingTableList>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Infos>
                            </div>
                            <div
                                id="hierarquia"
                                className="tab-pane fade show"
                                role="tabpanel"
                                aria-labelledby="hierarquia-tab"
                            >
                                <Infos
                                    className="tab-pane fade show active"
                                    role="tabpanel"
                                    aria-labelledby="info-tab"
                                >
                                    <Alert
                                        fixed={true}
                                        icon="exclamation-circle"
                                        type="secondary"
                                        message="Pesquise por código ou
                                                nome do colaborador"
                                    ></Alert>
                                    <div className="container">
                                        <Space height="4em"></Space>
                                        <Space height="2em"></Space>
                                        <div className="row">
                                            <div className="col-12 col-sm-7">
                                                <div className="row">
                                                    <div className="col-12">
                                                        {showForm()}
                                                        {showTable2()}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-1 rightLine"
                                                style={{ width: '10px' }}
                                            ></div>
                                            <div className="col-4">
                                                {showTable3()}
                                            </div>
                                        </div>
                                    </div>
                                </Infos>
                            </div>
                        </article>
                    </ContainerPadding>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                )}
            </BlankBoard>
        </div>
    )
}

const CustomSpacingTableList = styled.div`
    th:first-child,
    td:first-child {
        width: 20% !important;
    }
`

const ContentButton = styled.div`
    width: 100%;
    button {
        margin-top: 15px;
        height: 40px;
        width: 100%;
        min-height: auto;
        min-width: auto;
    }

    @media (max-width: 768px) {
        width: 100%;
        button {
            width: 100%;
        }
    }
`
const navItem = {
    // width: '20vh',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2.2vh'
}

const Infos = styled.div`
    height: auto;
    margin: 5vh 7vh;
    font-size: 1.5vh;
    padding: 3.5vh;
    border-radius: 9px;
    //box-shadow: 0px 0px 6px 0px #00000026;
`

const Tr = styled.tr`
    :hover {
        background: #dbebff;
    }
`

const Title = styled.div`
    position: relative;
    //width: 1157px;
    //height: 37px;
    margin: 0 auto;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 37px;
    left: -50px;
    /* identical to box height */
    text-align: center;
    color: #5f8dd3;
`
const Text = styled.p`
    color: '#fff';
    font: 'Montserrat, sas-serif';
`

import React, { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Form } from '@unform/web'
import { useHistory } from 'react-router-dom'
import { FormHandles, SubmitHandler } from '@unform/core'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button'
import { DatePicker } from '../../../core/components/DatePicker'
import { ENDPOINTS } from '../../../services/config-http'
import {
    TableRequester,
    Table,
    MapperItem,
    ConfigTable
} from './../../../core/components/Table/v2/Table'
import { SelectRequester } from '../../../core/components/Form/SelectWithRequest'
import { Input } from '../../../core/components/Form/Input'
import { STATUS_SOLICITACAO } from '../../../contexts/enums/StatusSolicitacao'
import CleanIcon from '../../../core/assets/filter_white.svg'
import { date } from 'yup/lib/locale'
import { Header } from '../../../core/components/Header/Header'
import { Flex } from '../../../core/components/Flex'
import { get, patch } from '../../../services/http'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { Modal } from '../../../core/components/Modal'
import { Hospedagem as HospedagemProp } from '../../../contexts/interfaces/hospedagem'
import { Servico } from '../../../contexts/interfaces/servico'
import { useAuth } from '../../../contexts/Auth'
import { UserContext } from '../../../contexts/UserContext'
import { Empty } from '../../../core/components/Empty'
import { Alert } from '../../../core/components/Alert'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { DisableForMobile, IsMobile } from '../Solicitacao/stepMobile'
import { Center } from '../../../core/components/Center'
import {
    Box,
    Quadro,
    TdMobile,
    ThMobile,
    TrMobile
} from '../../../core/components/Mobile'
import { Subtitle } from '../../../core/components/Subtitle'

export const ListagemConferencia = () => {
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const [flagFilter, setFlagFilter] = useState(false)
    const [buttonRef, setButtonOpen] = useState<HTMLButtonElement>()
    const [idSolicitacao, setIdSolicitacao] = useState(0)
    const [approving, setApproving] = useState(true)
    const [hospedagens, setHospedagens] = useState<Array<HospedagemProp>>([])
    const [servicos, setServicos] = useState<Array<Servico>>([])
    const [idUsuario, setIdUsuario] = useState()
    const [aprovaPrestacao, setAprovaPrestacao] = useState(false)
    const [administrador, setAdministrador] = useState(false)
    const { user } = useAuth()
    const [showModal, setShowModal] = useState(false)
    const [id, setId] = useState(0)
    const [tablePrestacoes, setTablePrestacoes] = useState([])
    const dadosUsuario = useContext(UserContext)
    const [payloadSearch, setPayloadSearch] = useState<TableRequester>({
        endpoint: ENDPOINTS.prestacaoContasPesquisar,
        method: 'GET',
        payload: {}
    })
    type Filter = {
        IdSolicitacaoViagem: number
    }

    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })
    useEffect(() => {
        setAprovaPrestacao(dadosUsuario.AprovaPrestacao === true)
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        get(`${ENDPOINTS.prestacaoContasPesquisar}`).then(
            ({ data: { Model } }) => {
                const prestacoes: [] = Model
                setTablePrestacoes(
                    limpaValoresRepetidos(
                        prestacoes
                            .filter(
                                ({ Status }) =>
                                    Status ===
                                    STATUS_SOLICITACAO.AGUARDANDO_CONFERENCIA
                            )
                            .sort((a: Filter, b: Filter) => {
                                if (
                                    a.IdSolicitacaoViagem >
                                    b.IdSolicitacaoViagem
                                ) {
                                    return -1
                                } else {
                                    return 1
                                }
                            })
                    )
                )
            }
        )
    }, [])

    function limpaValoresRepetidos(array) {
        for (const i in array) {
            const valorComparado = array[i]
            let cont = 0
            for (const i in array) {
                if (valorComparado.Id === array[i].Id) {
                    cont += 1
                    if (cont > 1) {
                        cont--
                        delete array[i]
                    }
                }
            }
        }
        return array
    }

    const mapper: MapperItem[] = [
        { field: 'IdSolicitacaoViagem', replaceTo: 'ID', position: 1 },

        {
            field: 'IdUsuario',
            replaceTo: 'Viajante',
            visualCustom: _PipelistPeople,
            position: 2
        },
        // {
        //     field: 'DataCadastro',
        //     replaceTo: 'Data do Cadastro',
        //     visualCustom: _PipeCustomDate,
        //     position: 3
        // },
        // { field: 'Tipo', position: 3 },
        {
            field: 'Status',
            replaceTo: 'Situação',
            visualCustom: _PipeCustomStatus,
            position: 3
        },
        { field: 'Etapas', delete: true },
        { field: 'IdSolicitacaoViagem', delete: true },
        { field: 'Viajante', delete: true },
        { field: 'ViajantePrincipal', delete: true },
        { field: 'ValorViagem', delete: true },
        { field: 'FilialViajantePrincipal', delete: true },
        { field: 'CotacaoSolicitacaoViagem', delete: true }
    ]

    const tableConfig: ConfigTable = {
        edit: false,
        delete: false,
        moreOptions: [
            {
                label: 'Realizar Conferência',
                icon: 'receipt',
                action: (item: any) => {
                    history.push(
                        `/solicitacoes/listar-conferencia/conferencia/32` // to do
                    )
                }
            }
        ]
    }

    const alterarSolicitacao = () => {
        let data = 0
        if (approving) {
            get(`${ENDPOINTS.solicitacaoViagemPorId}/${idSolicitacao}`).then(
                async ({ data: { Model } }) => {
                    const aereos = Model.Aereos
                    const hospedagem = Model.Hospedagem
                    const servico = Model.Servico
                    if (aereos.length || hospedagem.length || servico.length) {
                        data = 6 // Em Cotação
                    } else {
                        data = 3 // Aguardando Prestação de Contas
                    }
                    patch(
                        `${ENDPOINTS.solicitarViagem}?Id=${idSolicitacao}&idSituacao=${data}`
                    )
                        .then(({ data: { Model } }) => {
                            window.location.reload()
                        })
                        .catch(err => {
                            setShowAlert({
                                status: true,
                                message: err.data,
                                type: 'danger'
                            })
                        })
                }
            )
        } else {
            patch(
                `${
                    ENDPOINTS.solicitarViagem
                }?Id=${idSolicitacao}&idSituacao=${8}`
            )
                .then(({ data: { Model } }) => {
                    window.location.reload()
                })
                .catch(err => {
                    setShowAlert({
                        status: true,
                        message: err.data,
                        type: 'danger'
                    })
                })
        }
    }

    const handleSubmit: SubmitHandler<FormData> = (data: any) => {
        Object.keys(data).forEach(
            k => !data[k] && data[k] !== undefined && delete data[k] // eliminando valores não preenchidos para envio da query de busca
        )
        data.page = 0
        data.lastCount = 0
        data.totalRegisterPage = 10
        const newPayloadSearch = { ...payloadSearch, payload: data }
        setPayloadSearch(newPayloadSearch)
    }

    function _PipelistPeople(value: number): JSX.Element {
        let nomin: string
        get(`${ENDPOINTS.usuarioPesquisarId}?Id=${value}`).then(
            ({ data: { Model } }) => {
                nomin = Model
            }
        )
        return (
            <>
                <i className="text-danger" style={{ margin: 'auto' }}>
                    {value}
                </i>
            </>
        )
    }

    function _PipeCustomStatus(value: string | undefined): JSX.Element {
        console.log(value)
        switch (value) {
            case STATUS_SOLICITACAO.FINALIZADA:
                return (
                    <span
                        className="badge status-success"
                        style={{ margin: 'auto' }}
                    >
                        <FontAwesomeIcon icon="circle" />
                        &ensp; {value}
                    </span>
                )
            case STATUS_SOLICITACAO.AUTORIZACAO_PENDENTE:
            case STATUS_SOLICITACAO.AGUARDANDO_CONFERENCIA:
            case STATUS_SOLICITACAO.AGUARDANDO_FINALIZAR_NA_TESOURARIA:
            case STATUS_SOLICITACAO.EM_COTACAO:
            case STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS:
                return (
                    <span
                        className="badge status-pendding"
                        style={{ margin: 'auto', color: '#5D5D5D' }}
                    >
                        <FontAwesomeIcon icon="circle" />
                        &ensp;
                        {value === 'Aguardando prestação de contas'
                            ? 'Aguardando prest. contas'
                            : value === 'Aguardando finalizar na tesouraria'
                            ? 'Finalização tesouraria'
                            : value}
                    </span>
                )
            case STATUS_SOLICITACAO.REPROVADO:
            case STATUS_SOLICITACAO.REPROVADO_PRESTACAO_DE_CONTAS:
                return (
                    <span
                        className="badge status-danger"
                        style={{ margin: 'auto' }}
                    >
                        <FontAwesomeIcon icon="circle" />
                        &ensp;
                        {value === 'Reprovado: prestação de contas'
                            ? 'Reprovado: prest. contas'
                            : value}
                    </span>
                )
            case STATUS_SOLICITACAO.CANCELADO:
                return (
                    <span
                        className="badge status"
                        style={{ margin: 'auto', backgroundColor: '#525252' }}
                    >
                        <FontAwesomeIcon icon="circle" />
                        &ensp; {value}
                    </span>
                )
            default:
                return (
                    <>
                        <i className="text-danger" style={{ margin: 'auto' }}>
                            {value}
                        </i>
                    </>
                )
        }
    }

    function _PipeCustomDate(value: string) {
        const date = new Date(value)
        return <>{date.toLocaleDateString()}</>
    }

    function getCampo(index: number, title: string, valor: any) {
        return (
            <div
                style={{
                    flexDirection: 'column'
                }}
            >
                <ThMobile key={index}>{title}</ThMobile>
                <TdMobile
                    key={index}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {valor}
                </TdMobile>
            </div>
        )
    }

    function getTableMobile() {
        return (
            <IsMobile>
                <Center>
                    <Box>
                        {tablePrestacoes.length > 0 &&
                            tablePrestacoes.map((tableItem, index) => {
                                if (tableItem) {
                                    return (
                                        <Quadro>
                                            <table style={{ width: '100%' }}>
                                                <TrMobile>
                                                    {getCampo(
                                                        index,
                                                        'Id',
                                                        tableItem?.IdSolicitacaoViagem
                                                    )}
                                                    {getCampo(
                                                        index,
                                                        'Viajante',
                                                        tableItem?.NomeUsuario
                                                    )}
                                                    {getCampo(
                                                        index,
                                                        'Situação',
                                                        _PipeCustomStatus(
                                                            tableItem?.Status
                                                        )
                                                    )}
                                                    {getCampo(
                                                        index,
                                                        'Conferir',
                                                        <ButtonConferencia
                                                            onClick={() =>
                                                                history.push(
                                                                    `/solicitacoes/listar-conferencia/conferencia/${tableItem?.Id}` // to do
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon="glasses" />
                                                        </ButtonConferencia>
                                                    )}
                                                </TrMobile>
                                            </table>
                                        </Quadro>
                                    )
                                }
                                return null
                            })}
                    </Box>
                </Center>
            </IsMobile>
        )
    }
    function getTableDesktop() {
        return (
            <DisableForMobile>
                <div
                    className="table-responsive"
                    style={{
                        border: 'solid 1px lightgray',
                        borderRadius: '15px',
                        marginTop: '3rem'
                    }}
                >
                    <table className="table">
                        <thead
                            style={{
                                borderBottom: 'solid 1px lightgray'
                            }}
                        >
                            <tr
                                style={{
                                    border: '0 !important'
                                }}
                            >
                                <Th
                                    style={{
                                        borderBottomWidth: '0 !important'
                                    }}
                                    scope="col"
                                >
                                    <p>Id</p>
                                </Th>
                                <Th
                                    style={{
                                        textAlign: 'center'
                                    }}
                                    scope="col"
                                >
                                    <p>Viajante</p>
                                </Th>
                                <Th
                                    style={{
                                        textAlign: 'center'
                                    }}
                                    scope="col"
                                >
                                    <p>Situação</p>
                                </Th>
                                <Th
                                    style={{
                                        textAlign: 'center'
                                    }}
                                    scope="col"
                                >
                                    <p>Conferir</p>
                                </Th>
                            </tr>
                        </thead>
                        <tbody>
                            {tablePrestacoes?.map(
                                (object: any, key: number) => {
                                    return (
                                        <>
                                            <tr key={key}>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <p>
                                                        {
                                                            object.IdSolicitacaoViagem
                                                        }
                                                    </p>
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <p>{object.NomeUsuario}</p>
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {_PipeCustomStatus(
                                                        object.Status
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <ButtonConferencia
                                                        onClick={() =>
                                                            history.push(
                                                                `/solicitacoes/listar-conferencia/conferencia/${object.Id}` // to do
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon="glasses" />
                                                    </ButtonConferencia>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                            )}
                        </tbody>
                    </table>
                </div>
            </DisableForMobile>
        )
    }

    return (
        <BlankBoard>
            <Header
                title="Conferência"
                actionArrowClick="/solicitacoes"
            ></Header>
            <Center>
                <Subtitle>
                    Prestações aguardando
                    <b> Conferência</b>
                </Subtitle>
            </Center>
            <LineSeparator></LineSeparator>
            <Form onSubmit={handleSubmit} ref={formRef} style={{ padding: 0 }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '1rem 0'
                    }}
                >
                    <Button
                        style={{ margin: '0 .2rem', padding: '0 15px' }}
                        onlyIcon={true}
                        icon="search"
                        title="Pesquisar"
                        colorType="primary"
                    ></Button>
                    <Button
                        onlyIcon={true}
                        customIcon={CleanIcon}
                        colorType="danger"
                        title="Limpar Filtros"
                        type="button"
                        onClick={() => {
                            formRef.current?.reset()
                        }}
                    ></Button>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-3">
                        <Input
                            placeholder="Digite a ID da solicitacao"
                            label="Id Solicitacão"
                            name="id"
                            maxLength={10}
                        ></Input>
                    </div>
                    <div className="col-12 col-sm-3">
                        <Input
                            placeholder="Digite o código do viajante"
                            label="Código"
                            name="CodigoUsuario"
                            maxLength={10}
                        ></Input>
                    </div>
                    <div className="col-6 col-sm-3">
                        <DatePicker
                            name="dataInicial"
                            label="Data Início"
                        ></DatePicker>
                    </div>
                    <div className="col-6 col-sm-3">
                        <DatePicker
                            name="dataFinal"
                            label="Data Final"
                        ></DatePicker>
                    </div>
                </div>
            </Form>
            {(administrador || aprovaPrestacao) &&
            tablePrestacoes.length > 0 ? (
                <>
                    {' '}
                    {getTableDesktop()}
                    {getTableMobile()}
                </>
            ) : (
                // </div>
                <div style={{ marginTop: '2rem' }}>
                    {!aprovaPrestacao ? (
                        <div className="col-12">
                            <Alert
                                fixed={true}
                                icon="exclamation-circle"
                                type="primary"
                                message="Você não tem permissão para aprovar prestações "
                            ></Alert>
                        </div>
                    ) : (
                        <Empty text="Nenhuma despesa adicionada" />
                    )}
                </div>
            )}
        </BlankBoard>
    )
}

const LabelFilterText = styled.p`
    margin: 0 1rem;
    color: ${props => props.theme.colors.primary};
    font-size: 0.9rem;
    font-weight: 600;
`

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
`
const ButtonConferencia = styled.button`
    background-color: #002a5d;
    color: #fff;
    border: 0;
    padding: 0.3rem 0.7rem;
    border-radius: 4px;
`

import React from 'react'
import { Switch } from 'react-router'
import { CadastroAgencia } from '../views/Cadastros/Agencia'
import { CadastroEmpresa } from '../views/Cadastros/Empresa/incluir'
import { FilialForm } from '../views/Cadastros/Filial/FilialForm'
import { ListaFilial } from '../views/Cadastros/Filial/ListaFilial'
import { MotivoViagemForm } from '../views/Cadastros/MotivoViagem/MotivoViagemForm'
import { ListaRegiao } from '../views/Cadastros/Regiao/ListaRegiao'
import { RegiaoForm } from '../views/Cadastros/Regiao/RegiaoForm'
import CadastroContaContabil from '../views/Cadastros/ContaContabil/Cadastro'
import ContasContabeis from '../views/Cadastros/ContaContabil'
import { ContaContabilForm } from '../views/Cadastros/ContaContabil/ContaContabilForm'
import Despesa from '../views/Cadastros/Tipos/Despesa'
import CadastroDespesa from '../views/Cadastros/Tipos/Despesa/Cadastro'
import { Usuarios } from '../views/Cadastros/Usuario/UsuarioForm'
import { DespesasForm } from '../views/Cadastros/Tipos/Despesa/DespesasForm'
import { UsuariosConvidados } from '../views/Cadastros/Usuario/ConvidadoForm'
import { ListarUsuario } from '../views/Cadastros/Usuario/ListarUsuario'
import { ListagemSolicitacoes } from '../views/Viagem/Listagem'
import { AdiantamentoForm } from '../views/Viagem/Solicitacao/StepByStep/AdiantamentoForm'
import { IdaVoltaForm } from '../views/Viagem/Solicitacao/StepByStep/IdaVoltaForm'
import { MultiTrechosForm } from '../views/Viagem/Solicitacao/StepByStep/MultiTrechosForm'
import Route from './Route'
import { Login } from '../views/Login'
import { MinhasViagens } from '../views/Viagem/MinhasViagens'
import { Detalhes } from '../views/Viagem/Detalhes'
import { Impressao } from '../views/Viagem/Impressao/Index.jsx'
import { PrestacaoDeContas } from './../views/Viagem/PrestacaoDeContas'
import { AereoForm } from '../views/Viagem/Solicitacao/StepByStep/AereoForm'
import { ListarMotivoViagem } from '../views/Cadastros/MotivoViagem/ListarMotivoViagem'
import { Viajantes } from '../views/Viagem/Solicitacao/ViajantesForm'
import { Hospedagem } from '../views/Viagem/Solicitacao/StepByStep/HospedagemForm'
import { Historico } from '../views/Viagem/Historico/Index'
import { RatioForm } from '../views/Viagem/Solicitacao/StepByStep/RateioForm'
import { Hierarquia } from '../views/Hierarquia'
import { ServicosForm } from '../views/Viagem/Solicitacao/StepByStep/ServicosForm'
import { ReviewForm } from '../views/Viagem/Solicitacao/StepByStep/ReviewForm'
import { ListagemCotacoes } from '../views/Cotacoes/ListagemCotacoes'
import { CotacaoViagem } from '../views/Cotacoes/CotacaoViagem'
import { NovaEtapa } from '../views/Viagem/Nova Etapa'
import { Editar } from '../views/Viagem/Solicitacao/Edicao/Editar'
import { EditarAdiantamentoForm } from '../views/Viagem/Solicitacao/Edicao/EditarAdiantamentoForm'
import { EditarHospedagemForm } from '../views/Viagem/Solicitacao/Edicao/EditarHospedagemForm'
import { EditarSolicitacaoForm } from '../views/Viagem/Solicitacao/Edicao/EditarSolicitacaoForm'
import { EditarViajantesForm } from '../views/Viagem/Solicitacao/Edicao/EditarViajantesForm'
import { EditarRateioForm } from '../views/Viagem/Solicitacao/Edicao/EditarRateioForm'
import { EditarServicoForm } from '../views/Viagem/Solicitacao/Edicao/EditarServicoForm'
import { EditarRoteirosForm } from '../views/Viagem/Solicitacao/Edicao/EditarRoteirosForm'
import { EditarAereoForm } from '../views/Viagem/Solicitacao/Edicao/EditarAereoForm'
import { Aprovacao } from '../views/Viagem/Aprovacao'
import { Politicas } from '../views/Configuracao/Politicas/Politicas'
import { PoliticaPermissao } from '../views/Configuracao/Politicas/PoliticaPermissao/PoliticaPermissao'
import { TipoSolicitacao } from '../views/Cadastros/TipoSolicitacao'
import { InfoForm } from '../views/Viagem/Solicitacao/InfoForm'
import { ListagemConferencia } from '../views/Viagem/Conferencia/index'
import { Conferencia } from '../views/Viagem/Conferencia/conferencia'
import { NovoAereo } from '../views/Cotacoes/Aereo/NovoAereo'
import { CadastroAereo } from '../views/Aereo/CadastroAereo'
import Empresas from '../views/Cadastros/Empresa'
import { EmpresaForm } from '../views/Cadastros/Empresa/EmpresaForm'
import Servico from '../views/Cadastros/Servico'
import { CadastroServico } from '../views/Cadastros/Servico/incluir'
import { ServicoForm as EditServicoForm } from '../views/Cadastros/Servico/ServicoForm'

export const routes = [
    {
        path: '/minhas-viagens',
        name: 'Minhas Viagens',
        Component: MinhasViagens,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes',
        name: 'Listagem de Solicitações',
        Component: ListagemSolicitacoes,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/listar-conferencia',
        name: 'Listagem de Conferência',
        Component: ListagemConferencia,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/listar-conferencia/conferencia/:Id',
        name: 'Conferência',
        Component: Conferencia,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/detalhes/:Id',
        name: 'Detalhes da Solicitação',
        Component: Detalhes,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/editar/:Id',
        name: 'Editar Solicitação',
        Component: Editar,
        isPrivate: true,
        rules: {}
    },
    // VIAGEM
    {
        path: '/solicitar-viagem/tipo-roteiro/ida-volta',
        name: 'Roteiro Ida e Volta',
        Component: IdaVoltaForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitar-viagem/tipo-roteiro/trechos',
        name: 'Roteiro multiplos trechos',
        Component: MultiTrechosForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitar-viagem/adiantamento',
        name: 'Roteiro multiplos trechos',
        Component: AdiantamentoForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitar-viagem/rateio',
        name: 'Rateio',
        Component: RatioForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitar-viagem/aereo',
        name: 'Cadastrar Aereo',
        Component: AereoForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitar-viagem/hospedagem',
        name: 'Hospedagem',
        Component: Hospedagem,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitar-viagem/incluir-viajantes',
        name: 'Viajantes',
        Component: Viajantes,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitar-viagem/review',
        name: 'Viajantes',
        Component: ReviewForm,
        isPrivate: true,
        rules: {}
    },
    // CADASTROS
    {
        path: '/cadastros/agencia/incluir',
        name: 'Cadastro de Agência',
        Component: CadastroAgencia,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/motivo-viagem/incluir',
        name: 'Cadastro de motivo viagem',
        Component: MotivoViagemForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/motivo-viagem/editar/:Id',
        name: 'Alterar motivo viagem',
        Component: MotivoViagemForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/tipos-despesa',
        name: 'Lista de tipo de despesa',
        Component: Despesa,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/tipos-despesa/:Id/editar',
        name: 'Editar tipo despesa',
        Component: DespesasForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/tipos-despesa/Cadastro',
        name: 'Cadastro tipo de despesa',
        Component: CadastroDespesa,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/contas-contabeis',
        name: 'Contas Contabeis',
        Component: ContasContabeis,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/contas-contabeis/:Id/editar',
        name: 'Editar tipo despesa',
        Component: ContaContabilForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/empresa/:Id/editar',
        name: 'Editar tipo despesa',
        Component: EmpresaForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/contas-contabeis/Cadastro',
        name: 'Cadastro tipo de despesa',
        Component: CadastroContaContabil,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/empresa/incluir',
        name: 'Cadastro de empresa',
        Component: CadastroEmpresa,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/empresas',
        name: 'Lista de empresas',
        Component: Empresas,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/Servico',
        name: 'Lista de servicos',
        Component: Servico,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/Servico/incluir',
        name: 'Cadastro de servicos',
        Component: CadastroServico,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/Servico/:Id/editar',
        name: 'Editar servico',
        Component: EditServicoForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/regiao',
        name: 'Cadastro de região',
        Component: ListaRegiao,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/regiao/incluir',
        name: 'Cadastro de região',
        Component: RegiaoForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/regiao/:Id/editar',
        name: 'Editar região',
        Component: RegiaoForm,
        isPrivate: true,
        rules: {}
    },

    {
        path: '/cadastros/usuario',
        name: 'Listar usuários',
        Component: ListarUsuario,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/usuario/:Id/editar',
        name: 'Editar usuário',
        Component: Usuarios,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/convidado/incluir',
        name: 'Cadastrar usuário convidado',
        Component: UsuariosConvidados,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/convidado/:Id/editar',
        name: 'Editar usuário convidado',
        Component: UsuariosConvidados,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/filial',
        name: 'Listar filiais',
        Component: ListaFilial,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/filial/incluir',
        name: 'Cadastrar filial',
        Component: FilialForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/filial/:Id',
        name: 'Editar filial',
        Component: FilialForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/tipo-solicitacao',
        name: 'Tipo de Solicitação',
        Component: TipoSolicitacao,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/impressao/:Id?',
        name: 'Impressao Solicitação',
        Component: Impressao,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cadastros/motivo-viagem/',
        name: 'Listar Motivo Viagem',
        Component: ListarMotivoViagem,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/prestar-contas/:IdSolicitacao/:IdPrestacao',
        name: 'Prestação de Contas',
        Component: PrestacaoDeContas,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/historico/:Id',
        name: 'Histórico',
        Component: Historico,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitar-viagem/servicos',
        name: 'Adicionar Serviços',
        Component: ServicosForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/listagem-cotacao',
        name: 'Listagem de Contações',
        Component: ListagemCotacoes,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/cotar-viagem/:Id',
        name: 'Listagem de Contações',
        Component: CotacaoViagem,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/novo-aereo/:Id',
        name: 'Novo Aereo',
        Component: NovoAereo,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/hierarquia',
        name: 'Hierarquia',
        Component: Hierarquia,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/nova-etapa/:Id',
        name: 'Nova Etapa',
        Component: NovaEtapa,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/editar/solicitacao/:Id/adiantamento',
        name: 'Editar Adiantamento',
        Component: EditarAdiantamentoForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/editar/solicitacao/:Id/hospedagem',
        name: 'Editar Hospedagem',
        Component: EditarHospedagemForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/editar/solicitacao/:Id/solicitacao',
        name: 'Editar Solicitacao',
        Component: EditarSolicitacaoForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/editar/solicitacao/:Id/viajantes',
        name: 'Editar Viajantes',
        Component: EditarViajantesForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/editar/solicitacao/:Id/rateio',
        name: 'Editar Rateio',
        Component: EditarRateioForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/editar/solicitacao/:Id/serviços',
        name: 'Editar Serviços',
        Component: EditarServicoForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/editar/solicitacao/:Id/roteiros',
        name: 'Editar Roteiros',
        Component: EditarRoteirosForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/editar/solicitacao/:Id/aereo',
        name: 'Editar Aéreo',
        Component: EditarAereoForm,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/aprovar/:Id',
        name: 'Aprovar Solicitação',
        Component: Aprovacao,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/configuracao/politicas',
        name: 'Políticas de Viagem',
        Component: Politicas,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitacoes/configuracao/permissao/:Id',
        name: 'Políticas de Viagem',
        Component: PoliticaPermissao,
        isPrivate: true,
        rules: {}
    },
    {
        path: '/solicitar-viagem/info',
        name: 'Informações de Viagem',
        Component: InfoForm,
        isPrivate: true,
        rules: {}
    }
]
export const Routes = () => (
    <Switch>
        <Route path="/" exact component={Login} />
        {routes.map(({ path, Component, isPrivate }, key) => (
            <Route
                key={key}
                exact
                path={path}
                component={Component}
                isPrivate={isPrivate}
            />
        ))}
        <Route path="*" component={Login} isPrivate />
    </Switch>
)

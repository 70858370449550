import React, { useRef, useState, useEffect } from 'react'
import ReactDatePicker, {
    ReactDatePickerProps,
    registerLocale
} from 'react-datepicker'
import { useField } from '@unform/core'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ptBr from 'date-fns/locale/pt-BR' // the locale you want
registerLocale('pt-br', ptBr) // register it with the name you want
interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
    name: string
    label: string
    time?: boolean
}
export const DatePicker = ({
    name,
    required,
    time,
    label = '',
    ...rest
}: Props) => {
    const datepickerRef = useRef(null)
    const { fieldName, registerField, defaultValue, error } = useField(name)
    const [date, setDate] = useState(defaultValue || null)
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: datepickerRef.current,
            path: 'props.selected',
            clearValue: (ref: any) => {
                ref.clear()
            },
            setValue: (e: any, v: any) => {
                setDate(new Date(v))
            }
        })
    }, [fieldName, registerField, defaultValue])

    const Time = () => {
        return (
            <ReactDatePicker
                locale="pt-br"
                timeFormat="p"
                timeIntervals={15}
                dateFormat="Pp"
                showTimeSelect
                name={name}
                id={name}
                className={`customInput form-control dataPickCustom ${
                    error ? 'input-danger' : ''
                }`}
                ref={datepickerRef}
                selected={date}
                autoComplete="off"
                onChange={setDate}
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                showYearDropdown
                {...rest}
            />
        )
    }

    const Data = () => {
        return (
            <ReactDatePicker
                locale="pt-br"
                dateFormat="dd/MM/yyyy"
                name={name}
                id={name}
                className={`customInput form-control dataPickCustom ${
                    error ? 'input-danger' : ''
                }`}
                ref={datepickerRef}
                selected={date}
                onChange={setDate}
                autoComplete="off"
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                showYearDropdown
                {...rest}
            />
        )
    }

    const verf = () => {
        if (time) {
            return Time()
        } else {
            return Data()
        }
    }

    return (
        <DataPickContainer style={{ margin: '1rem 0' }}>
            <label htmlFor={label}>
                <Text>
                    {label} {required ? '*' : ''}
                </Text>
            </label>
            <div style={{ display: 'flex', position: 'relative' }}>
                {verf()}
                <DataPickIcon>
                    <FontAwesomeIcon icon="calendar-alt" />
                </DataPickIcon>
            </div>
            {error && <span className="error-text">{error}</span>}
        </DataPickContainer>
    )
}

const DataPickContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const DataPickIcon = styled.div`
    position: absolute;
    right: 5px;
    top: 1px;
    margin: 0.45rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.colors.placeholder};
    font-size: 1.3rem;
    border-left: solid 1px;
    padding-left: 10px;
`
const Text = styled.p`    
font-size: 0.9rem;
font-weight: 800;
color: '#6C7480',
font: 'Montserrat, sas-serif'
`

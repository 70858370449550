import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { useHistory } from 'react-router-dom'
import { Form } from '@unform/web'
import { BlankBoard } from '../../core/components/BlankBoard'
import { Center } from '../../core/components/Center'
import { Header } from '../../core/components/Header/Header'
import { Subtitle } from '../../core/components/Subtitle'
import {
    ConfigTable,
    MapperItem,
    Table,
    TableRequester
} from '../../core/components/Table/v2/Table'
import { ENDPOINTS } from '../../services/config-http'
import { Input } from '../../core/components/Form/Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '../../core/components/Button'
import { Viajante } from '../../contexts/interfaces/viajante'
import { MoneyFormat } from '../../core/utils/MoneyFormat'
import { Alert } from '../../core/components/Alert'
import { UserContext } from '../../contexts/UserContext'
import { LineSeparator } from '../../core/components/LineSeparator'

interface CotacoesProps {
    Servico: boolean
    Aereo: boolean
    Hospedagem: boolean
}

export const ListagemCotacoes = () => {
    const [payloadSearch, setPayloadSearch] = useState<TableRequester>({
        endpoint: ENDPOINTS.pesquisaSolicitacaoViagem,
        method: 'GET',
        payload: { IdSituacao: 6 },
        mapper: (items: Array<any>) => {
            return items.map((item, index) => {
                const viajantePrincipal = item.Viajantes.filter(
                    (viajante: Viajante) => {
                        if (viajante.Convidado === false) {
                            return viajante
                        }
                    }
                )[0]
                return {
                    ...item,
                    cotacao: item.IdSolicitacao,
                    CodigoViajante: viajantePrincipal.Codigo,
                    ViajantePrincipal: viajantePrincipal
                }
            })
        }
    })
    const [totalItems, setTotalItems] = useState(0)
    const [administrador, setAdministrador] = useState(false)
    const [agenteViagens, setAgenteViagens] = useState(false)
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)

    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
        setAgenteViagens(dadosUsuario.AgenteViagem === true)
    }, [dadosUsuario])

    const tableConfig: ConfigTable = {
        edit: false,
        delete: false
    }

    const mapper: MapperItem[] = [
        { field: 'IdSolicitacao', replaceTo: 'ID', position: 1 },
        {
            field: 'CodigoViajante',
            replaceTo: 'Cod. Viajante',
            position: 2,
            visualCustom: _pipeViajantePrincipalCod
        },
        {
            field: 'ViajantePrincipal',
            replaceTo: 'Viajante Principal',
            position: 3,
            visualCustom: _pipeViajantePrincipal
        },
        {
            field: 'DataCadastro',
            replaceTo: 'Data do Cadastro',
            position: 5,
            visualCustom: _pipeData
        },
        {
            field: 'CotacaoSolicitacaoViagem',
            replaceTo: 'Serviços',
            position: 6,
            visualCustom: _pipeServicos
        },
        {
            field: 'ValorViagem',
            replaceTo: 'Valor',
            position: 7,
            visualCustom: _pipeValor
        },
        {
            field: 'cotacao',
            replaceTo: 'Ações',
            position: 8,
            visualCustom: _pipeBotaoCotacao
        },

        { field: 'Viajantes', delete: true },
        { field: 'Tipo', delete: true },
        { field: 'Situacao', delete: true },
        { field: 'Etapas', delete: true }
    ]

    function _pipeServicos(value: CotacoesProps, index: number): JSX.Element {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {value.Aereo ? (
                    <span>
                        <FontAwesomeIcon icon="plane" />
                    </span>
                ) : null}
                {value.Servico ? (
                    <span>
                        <FontAwesomeIcon icon="wrench" />
                    </span>
                ) : null}
                {value.Hospedagem ? (
                    <span>
                        <FontAwesomeIcon icon="hotel" />
                    </span>
                ) : null}
            </div>
        )
    }

    function _pipeBotaoCotacao(
        value: Array<any> | string,
        index: number
    ): JSX.Element {
        return (
            <button
                onClick={() => history.push(`cotar-viagem/${value}`)}
                style={{
                    backgroundColor: '#002a5d',
                    color: '#fff',
                    border: 0,
                    padding: '0.3rem 0.8rem',
                    borderRadius: '15px'
                }}
            >
                Cotar <FontAwesomeIcon icon="plus" />
            </button>
        )
    }

    function _pipeValor(value: number, index: number): JSX.Element {
        return <>{MoneyFormat(value || 0)}</>
    }

    function _pipeData(value: string, index: number): JSX.Element {
        const date = new Date(value)
        return <>{date.toLocaleDateString()}</>
    }

    function _pipeViajantePrincipal(
        value: Viajante,
        index: number
    ): JSX.Element {
        return <>{value.Nome}</>
    }

    function _pipeViajantePrincipalCod(
        value: number,
        index: number
    ): JSX.Element {
        return <>{value}</>
    }

    const handleSubmit: SubmitHandler = (data: any) => {
        Object.keys(data).forEach(
            k => !data[k] && data[k] !== undefined && delete data[k] // eliminando valores não preenchidos para envio da query de busca
        )
        data.page = 0
        data.lastCount = 0
        data.totalRegisterPage = 10
        data.IdSituacao = 6
        const newPayloadSearch = { ...payloadSearch, payload: data }
        setPayloadSearch(newPayloadSearch)
    }

    return (
        <BlankBoard>
            <Header
                title="Cotação"
                actionArrowClick={() => history.goBack()}
            ></Header>
            {administrador || agenteViagens ? (
                <>
                    <Center>
                        <Subtitle>
                            Solicitações para
                            <b> Cotação</b>
                        </Subtitle>
                    </Center>
                    <div style={{ marginTop: '3rem' }}>
                        <LineSeparator></LineSeparator>
                        <Form
                            onSubmit={handleSubmit}
                            ref={formRef}
                            style={{ padding: 0 }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        margin: '1rem 0'
                                    }}
                                ></div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        margin: '1rem 0'
                                    }}
                                >
                                    <Button
                                        style={{
                                            margin: '0 .2rem',
                                            padding: '0 15px'
                                        }}
                                        onlyIcon={true}
                                        icon="search"
                                        title="Pesquisar"
                                        colorType="primary"
                                    ></Button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <Input
                                        placeholder="Número da solicitação"
                                        label="Solicitação"
                                        name="Id"
                                        maxLength={10}
                                    ></Input>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <Input
                                        placeholder="Pesquise pela código do viajante"
                                        label="Código Viajante"
                                        name="CodigoUsuario"
                                        maxLength={15}
                                    ></Input>
                                </div>
                                <div className="col-6 col-sm-4">
                                    <Input
                                        placeholder="Pesquise pelo nome do viajante"
                                        label="Nome Viajante"
                                        name="NomeSolicitante"
                                        maxLength={50}
                                    ></Input>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <Table
                        requester={payloadSearch}
                        mapper={mapper}
                        config={tableConfig}
                        setTotalItems={setTotalItems}
                    ></Table>
                    <p style={{ textAlign: 'right', fontSize: '12pt' }}>
                        Restam{' '}
                        <span style={{ color: '#5F8DD3' }}>{totalItems}</span>{' '}
                        solicitações a cotar
                    </p>
                </>
            ) : (
                <Alert
                    fixed={true}
                    icon="exclamation-circle"
                    type="primary"
                    message="Você não tem permissão de administrador"
                ></Alert>
            )}
        </BlankBoard>
    )
}

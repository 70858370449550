import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import React, { useEffect, useRef, useState } from 'react'
import { ENDPOINTS } from '../../../../services/config-http'
import { get, post, put } from '../../../../services/http'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { LineSeparator } from '../../../../core/components/LineSeparator'
import { Input } from '../../../../core/components/Form/Input'
import { Select } from '../../../../core/components/Form/Select'
import { SelectMapper } from '../../../../core/utils/SelectMapper'
import '../politica.css'
import { Modal } from '../../../../core/components/Modal/Modal'
import { Button } from '../../../../core/components/Button'
import { InputCurrency } from '../../../../core/components/Form/InputCurrency'
import { MoneyFormat } from '../../../../core/utils/MoneyFormat'
import { IntlRealFormat } from '../../../../core/utils/IntlFormat'

interface ResumoProp {
    id: number
    idPolitica: number
    show?: boolean
    setShow: any
}

export const ModalResumo = ({ show, setShow, id, idPolitica }: ResumoProp) => {
    const formRef = useRef<FormHandles>(null)
    const [tiposDespesas, setTiposDespesas] = useState([] as any)
    const [valor, setValor] = useState(0)

    const aplicacao = [
        {
            value: '0',
            label: 'Por Dia'
        },
        {
            value: '1',
            label: 'Por Ocorrência'
        },
        {
            value: '2',
            label: 'Por Pernoite'
        }
    ]

    const acao = [
        {
            value: '0',
            label: 'Alerta'
        },
        {
            value: '1',
            label: 'Bloqueia'
        }
    ]

    const moeda = [
        {
            value: '0',
            label: 'Real'
        },
        {
            value: '1',
            label: 'Outro'
        }
    ]

    useEffect(() => {
        if (show) {
            document
                .getElementsByClassName('modal-permissao')[0]
                ?.classList.remove('hidden')

            const montaModal = async () => {
                await get(`${ENDPOINTS.tipoDespesa}?TotalRegisterPage=100`)
                    .then(({ data: { Items } }) => {
                        setTiposDespesas(SelectMapper(Items, 'Descricao', 'Id'))
                    })
                    .catch(err => {
                        console.error(err)
                    })
                if (id !== 0) {
                    await get(`${ENDPOINTS.politicaPermissao}/${id}`)
                        .then(({ data: { Model } }) => {
                            const {
                                ValorMaximo,
                                Moeda,
                                Acao,
                                Frequencia,
                                IdTipoDespesa
                            } = Model

                            const data = {
                                valorMaximo: IntlRealFormat(ValorMaximo),
                                moeda: moeda
                                    .findIndex(s => s.label === Moeda)
                                    .toString(),
                                acao: acao
                                    .findIndex(s => s.label === Acao)
                                    .toString(),
                                aplicacao: aplicacao
                                    .findIndex(s => s.label === Frequencia)
                                    .toString(),
                                tipoDespesa: IdTipoDespesa
                            }
                            formRef.current?.setData({ ...data })
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
            }
            montaModal()

            if (id !== 0) {
                const buscarPolitica = async () => {
                    await get(`${ENDPOINTS.politicaPermissao}/${id}`)
                        .then(({ data: { Model } }) => {
                            const {
                                ValorMaximo,
                                Moeda,
                                Acao,
                                Frequencia,
                                IdTipoDespesa
                            } = Model

                            const data = {
                                valorMaximo: ValorMaximo,
                                moeda: moeda
                                    .findIndex(s => s.label === Moeda)
                                    .toString(),
                                acao: acao
                                    .findIndex(s => s.label === Acao)
                                    .toString(),
                                aplicacao: aplicacao
                                    .findIndex(s => s.label === Frequencia)
                                    .toString(),
                                tipoDespesa: IdTipoDespesa
                            }
                            formRef.current?.setData({ ...data })
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
                buscarPolitica()
            }
        } else
            document
                .getElementsByClassName('modal-permissao')[0]
                ?.classList.add('hidden')
    }, [show])

    const handleSubmit: SubmitHandler = async (data: any) => {
        try {
            const schema = Yup.object().shape({
                tipoDespesa: Yup.string().required(
                    'O Campo Tipo de Despesa é obrigatório'
                ),
                aplicacao: Yup.string().required(
                    'O Campo Aplicação é obrigatório'
                ),
                moeda: Yup.string().required('O Campo Moeda é obrigatório'),
                valorMaximo: Yup.string().required(
                    'O Campo Valor Máximo é obrigatório'
                ),
                acao: Yup.string().required('O Campo Ação é obrigatório')
            })

            // Verificação - Caso exista algum erro, o fluxo cai no catch que fará a amostragem dos erros na tela
            await schema.validate(data, { abortEarly: false })

            if (id === 0) {
                const p = {
                    idPoliticaViagem: idPolitica,
                    idTipoDespesa: data.tipoDespesa,
                    frequencia: aplicacao[data.aplicacao].label,
                    valorMaximo: valor,
                    moeda: moeda[data.moeda].label,
                    acao: acao[data.acao].label,
                    status: true
                }
                await post(ENDPOINTS.politicaPermissao, p).then(() => {
                    setShow(false)
                    window.location.reload()
                })
            } else {
                await put(ENDPOINTS.politicaPermissao, {
                    id: id,
                    idPoliticaViagem: idPolitica,
                    idTipoDespesa: data.tipoDespesa,
                    frequencia: aplicacao[data.aplicacao].label,
                    valorMaximo: valor,
                    moeda: moeda[data.moeda].label,
                    acao: acao[data.acao].label,
                    status: true
                }).then(() => {
                    setShow(false)
                    window.location.reload()
                })
            }
            formRef.current?.reset()
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                formRef.current?.setErrors(erros)
            }
        }
    }
    return (
        <>
            <div className="modal-permissao">
                <Modal
                    setShow={setShow}
                    title={
                        id === 0 ? 'Cadastrar permissão' : 'Alterar permissão'
                    }
                    height={'22rem'}
                >
                    <Form
                        onSubmit={handleSubmit}
                        ref={formRef}
                        style={{ padding: 0 }}
                    >
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Select
                                    name="tipoDespesa"
                                    label="Tipo Despesa"
                                    placeholder="Selecione o tipo de despesa"
                                    required={true}
                                    options={tiposDespesas}
                                ></Select>
                            </div>
                            <div className="col-12 col-md-6">
                                <Select
                                    name="aplicacao"
                                    label="Aplicação"
                                    placeholder="Selecione a aplicacao"
                                    required={true}
                                    options={aplicacao}
                                ></Select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <Select
                                    name="moeda"
                                    label="Moeda"
                                    placeholder="Selecione a aplicacao"
                                    required={true}
                                    options={moeda}
                                ></Select>
                            </div>
                            <div className="col-12 col-md-4">
                                <InputCurrency
                                    onChange={(e: any) => {
                                        const valorReplace = Number(
                                            e.currentTarget.value
                                                .replaceAll('.', '')
                                                .replaceAll(',', '.')
                                                .replaceAll('R$', '')
                                        )
                                        setValor(valorReplace)
                                    }}
                                    width="col-12"
                                    label="Valor Padrão R$"
                                    name="valorMaximo"
                                    mask="999.999,99"
                                    required={true}
                                    defaultValue={MoneyFormat(0)}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <Select
                                    name="acao"
                                    label="Ação"
                                    placeholder="Selecione a aplicacao"
                                    required={true}
                                    options={acao}
                                ></Select>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '0.2rem'
                            }}
                        >
                            <Button
                                type="submit"
                                icon={id === 0 ? 'plus' : 'save'}
                                colorType="quaternary"
                            >
                                {id === 0 ? 'Cadastrar' : 'Salvar'}
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </div>
        </>
    )
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

export const Deletar = ({ remover }: { remover: () => void }) => {
    const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)

    return (
        <>
            {!showConfirmationDelete ? (
                <span
                    className="danger cursor-pointer"
                    onClick={() => {
                        setShowConfirmationDelete(!showConfirmationDelete)
                        console.log(showConfirmationDelete)
                    }}
                >
                    <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                </span>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                    }}
                >
                    <button
                        type="button"
                        className="btn-tertiary"
                        onClick={() =>
                            setShowConfirmationDelete(!showConfirmationDelete)
                        }
                        style={{
                            border: 'none',
                            padding: '0.5rem 1rem',
                            borderRadius: '20px',
                            background: '#DBDEE3',
                            color: 'white',
                            width: '36%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        Cancelar{' '}
                    </button>
                    <button
                        type="button"
                        style={{
                            border: 'none',
                            padding: '0.5rem 1rem',
                            borderRadius: '20px',
                            background: '#FF6262',
                            color: 'white',
                            width: '60%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        onClick={() => {
                            remover()
                            setShowConfirmationDelete(!showConfirmationDelete)
                        }}
                    >
                        Confirmar remoção{' '}
                    </button>
                </div>
            )}
        </>
    )
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { PoliticaPermissao } from '../../../../contexts/interfaces/politicaPermissao'
import { TipoDespesa } from '../../../../contexts/interfaces/tipoDespesa'
import { Button } from '../../../../core/components/Button'
import { Empty } from '../../../../core/components/Empty'
import { ENDPOINTS } from '../../../../services/config-http'
import { destroy, get, put } from '../../../../services/http'
import { ModalResumo } from './ModalResumo'
import Switch from 'react-switch'
import styled from 'styled-components'
import { IntlRealFormat } from '../../../../core/utils/IntlFormat'
import { Deletar } from '../../../../core/components/Deletar'
export const Resumo = ({ nomePolitica }: { nomePolitica: string }) => {
    const { Id } = useParams() as any
    const [showModal, setShowModal] = useState(false)
    const [id, setId] = useState(0)
    const [politicasPermissao, setPoliticasPermissao] = useState<
        PoliticaPermissao[]
    >([])
    const [tipoDespesa, setTipoDespesa] = useState<TipoDespesa[]>([])
    useEffect(() => {
        const tipoDespesa = async () => {
            await get(`${ENDPOINTS.tipoDespesa}?TotalRegisterPage=100`)
                .then(({ data: { Items } }) => {
                    setTipoDespesa(Items)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        tipoDespesa()
        pesquisaPermissao()
    }, [])
    const pesquisaPermissao = async () => {
        await get(`${ENDPOINTS.politicaPermissaoPorIdPolitica}/${Id}`).then(
            ({ data: { Model } }) => {
                setPoliticasPermissao(Model)
            }
        )
    }
    const atualizaParticipaCalculo = (
        param: boolean,
        politica: PoliticaPermissao
    ) => {
        put(ENDPOINTS.politicaPermissao, {
            id: politica.Id,
            idPoliticaViagem: politica?.IdPoliticaViagem,
            idTipoDespesa: politica?.IdTipoDespesa,
            frequencia: politica?.Frequencia,
            valorMaximo: politica?.ValorMaximo,
            moeda: politica?.Moeda,
            acao: politica?.Acao,
            status: politica?.Status,
            participaCalculo: param
        })
            .then(data => {
                pesquisaPermissao()
            })
            .catch(err => {
                console.log(err)
            })
    }
    const _pipeTipoDespesa = (id: number) => {
        return tipoDespesa.find(s => s.Id === id)?.Descricao
    }

    const handleDeletarPolitica = (id: number) => {
        destroy(`${ENDPOINTS.politicaPermissao}/${id}`).then(() => {
            pesquisaPermissao()
        })
    }
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '2rem'
                }}
            >
                <div style={{ fontSize: '1.5rem' }}>{nomePolitica}</div>
                <div>
                    <Button
                        colorType="quaternary"
                        icon={'plus'}
                        onClick={() => {
                            setId(0)
                            setShowModal(true)
                        }}
                    >
                        Cadastrar
                    </Button>
                </div>
            </div>
            {politicasPermissao.length > 0 ? (
                <div className="row" style={{ marginTop: '3rem' }}>
                    <div className="col-12">
                        <label htmlFor=""></label>
                        <div
                            className="table-responsive"
                            style={{
                                border: 'solid 1px lightgray',
                                borderRadius: '15px'
                            }}
                        >
                            <table className="table">
                                <thead>
                                    <tr>
                                        <Th
                                            style={{
                                                width: '10rem',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <Text>Tipo de despesa</Text>
                                        </Th>
                                        <Th
                                            style={{
                                                width: '5rem',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <Text>Aplicação</Text>
                                        </Th>
                                        <Th
                                            style={{
                                                width: '5rem',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <Text>Moeda</Text>
                                        </Th>
                                        <Th
                                            style={{
                                                width: '5rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <Text>Valor Máximo</Text>
                                        </Th>
                                        <Th
                                            style={{
                                                width: '5rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <Text>Ação</Text>
                                        </Th>
                                        <Th
                                            style={{
                                                width: '5rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <Text>Cálculo padrão</Text>
                                        </Th>
                                        <Th
                                            style={{
                                                width: '15rem',
                                                textAlign: 'center'
                                            }}
                                        ></Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {politicasPermissao.map(
                                        (politica, index) => {
                                            return (
                                                <tr key={index}>
                                                    <Td
                                                        style={{
                                                            width: '10rem',
                                                            marginLeft: '1rem',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <Text>
                                                            {' '}
                                                            {_pipeTipoDespesa(
                                                                politica.IdTipoDespesa
                                                            )}
                                                        </Text>
                                                    </Td>
                                                    <Td
                                                        style={{
                                                            width: '5rem',
                                                            marginLeft: '1rem',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <Text>
                                                            {
                                                                politica.Frequencia
                                                            }
                                                        </Text>
                                                    </Td>
                                                    <Td
                                                        style={{
                                                            width: '5rem',
                                                            marginLeft: '1rem',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <Text>
                                                            {politica.Moeda}
                                                        </Text>
                                                    </Td>
                                                    <Td
                                                        style={{
                                                            width: '5rem',
                                                            marginLeft: '1rem',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <Text>
                                                            {' '}
                                                            {IntlRealFormat(
                                                                politica.ValorMaximo
                                                            )}
                                                        </Text>
                                                    </Td>
                                                    <Td
                                                        style={{
                                                            width: '5rem',
                                                            marginLeft: '1rem',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <Text>
                                                            {politica.Acao}
                                                        </Text>
                                                    </Td>
                                                    <Td
                                                        style={{
                                                            width: '5rem',
                                                            marginLeft: '1rem',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <Switch
                                                            onColor="#24538B"
                                                            offColor="#ADB5BD"
                                                            onHandleColor="#fff"
                                                            offHandleColor="#fff"
                                                            handleDiameter={15}
                                                            checkedIcon={false}
                                                            uncheckedIcon={
                                                                false
                                                            }
                                                            width={48}
                                                            height={21}
                                                            checked={
                                                                !!politica.ParticipaCalculo
                                                            }
                                                            onChange={res => {
                                                                atualizaParticipaCalculo(
                                                                    res,
                                                                    politica
                                                                )
                                                            }}
                                                        ></Switch>
                                                    </Td>
                                                    <Td
                                                        style={{
                                                            width: '15rem',
                                                            marginLeft: '1rem',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'space-evenly'
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                onClick={e => {
                                                                    setId(
                                                                        politica.Id
                                                                    )
                                                                    setShowModal(
                                                                        true
                                                                    )
                                                                }}
                                                                color="#6C7480"
                                                                icon="pencil-alt"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                            <Deletar
                                                                remover={() => {
                                                                    handleDeletarPolitica(
                                                                        politica.Id
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    </Td>
                                                </tr>
                                            )
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : (
                <Empty text="Nenhuma política adicionada" />
            )}
            {showModal ? (
                <ModalResumo
                    show={showModal}
                    id={id}
                    idPolitica={Id}
                    setShow={setShowModal}
                />
            ) : null}
        </>
    )
}

const Th = styled.th`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: center;
    color: #6c7480;
`

const Td = styled.td`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #6c7480;
`
const Text = styled.p``

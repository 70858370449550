import React from 'react'
import { Helmet } from 'react-helmet'

export const MetaDecorator = () => (
    <Helmet>
        <meta charSet="utf-8" />
        <title>Viagens Corp</title>
        <link
            href="https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700&display=swap"
            rel="stylesheet"
        ></link>
        <script src="https://cdn.havan.com.br/assets/scripts/bootstrap.bundle.min.js"></script>
    </Helmet>
)

import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import InputMask from 'react-input-mask'
import { Label, InputProps } from 'reactstrap'
import { isOptionDisabled } from 'react-select/src/builtins'
import styled from 'styled-components'

interface Props extends InputProps {
    name?: string
    required?: boolean
    hide?: boolean
    placeholder?: string
    label?: string
    money?: boolean
    mask: string
    inputRef?: any
}

export const Input = ({
    name = '',
    required = false,
    hide = false,
    placeholder = '',
    label = '',
    money = false,
    mask,
    inputRef,
    ...tagProps
}: Props) => {
    // const { error } = useField(name)
    const { fieldName, registerField, defaultValue, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        })
    }, [fieldName, registerField])

    return (
        <div style={{ margin: '1rem 0' }}>
            <Label for={label}>
                <Text>
                    {' '}
                    {label} {required ? '*' : ''}
                </Text>
            </Label>
            <InputMask
                className={`customInput form-control ${
                    error ? 'input-danger' : ''
                }`}
                ref={inputRef}
                style={maskedStyle}
                placeholder={placeholder}
                mask={mask}
                {...(tagProps as any)}
            ></InputMask>
            {error && <span className="error-text">{error}</span>}
        </div>
    )
}

const maskedStyle = {
    width: '100%',
    height: 'calc(1.5em + 0.75rem + 2px)',
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    fontQeight: '400',
    lineHeight: '1.5',
    // backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color 0.15s ease-in-out'
}
const Text = styled.p`
    font-size: 0.9rem;
    font-wight: 800;
`

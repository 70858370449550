export enum STATUS_SOLICITACAO {
    EM_COTACAO = 'Em Cotação',
    AGUARDANDO_CONFERENCIA = 'Aguardando conferência',
    AUTORIZACAO_PENDENTE = 'Autorização pendente',
    AGUARDANDO_FINALIZAR_NA_TESOURARIA = 'Aguardando finalização na tesouraria',
    AGUARDANDO_PRESTACAO_DE_CONTAS = 'Aguardando prestação de contas',
    REPROVADO = 'Reprovado',
    REPROVADO_PRESTACAO_DE_CONTAS = 'Reprovado: prestação de contas',
    CANCELADO = 'Cancelada',
    FINALIZADA = 'Finalizada'
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { LineSeparator } from '../LineSeparator'

interface ModalProps {
    height?: string
    children: any
    setShow: any
    title: string
    btnConfirm?: {
        text: string
        action: () => void
    }
}

export const Modal = ({
    children,
    setShow,
    btnConfirm,
    title,
    height
}: ModalProps) => {
    return (
        <ModalBack className="modal-back">
            <ModalContent style={{ height: height }}>
                <ModalHeader>
                    <div style={{ width: '5%' }}></div>
                    <h1
                        style={{
                            fontWeight: 'bold',
                            color: '#24538B',
                            width: '90%',
                            textAlign: 'center'
                        }}
                    >
                        {title}
                    </h1>
                    <CloseModal
                        onClick={e => setShow(false)}
                        style={{
                            width: '5%',
                            textAlign: 'center'
                        }}
                    >
                        <FontAwesomeIcon icon="times" />
                    </CloseModal>
                </ModalHeader>
                <ModalBody>
                    <LineSeparator style={{ margin: '0' }}></LineSeparator>
                    {children}
                </ModalBody>
                {btnConfirm ? (
                    <ModalFooter>
                        <LineSeparator style={{ margin: '0' }}></LineSeparator>
                        <div></div>
                    </ModalFooter>
                ) : null}
            </ModalContent>
        </ModalBack>
    )
}

const ModalBack = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(82, 82, 82, 0.59);
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10000000000;
`

const ModalContent = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 5rem auto;
    width: 65rem;
    background-color: white;
    border-radius: 0.5rem;
`

const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    height: 4rem;
    padding: 1rem 0;
`

const ModalBody = styled.div`
    padding: 0 2rem;
    height: auto;
    overflow-y: auto;
`

const ModalFooter = styled.div`
    height: 15%;
`
const CloseModal = styled.button`
    border: none;
    position: relative;
    font-size: 1.5rem;
    background-color: white;
    font-weight: bold;
    color: #ff8686;
`

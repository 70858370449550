import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
    font-size: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const Label = styled.div`
    color: '#6C7480';
    text-align: center;
    font-weight: 500;
    margin-top: 3vh;
`
interface EmptyProp {
    text?: string;
}

export const Empty = ({ text = 'Não há dados' }: EmptyProp) => {
    return (
        <div>
            <Container>
                <FontAwesomeIcon
                    style={{
                        alignSelf: 'center',
                        fontSize: '4rem',
                        height: '55px',
                        color: '#E7E7E7'
                    }}
                    icon="times-circle"
                />
                <Label>{text}</Label>
            </Container>
        </div>
    )
}

export enum STEP_BY_STEP {
    REDUCER_NAME = 'STEP_BY_STEP',
    INIT_STEP_BY_STEP = 'INIT_STEP_BY_STEP',

    ACTIVE_ROTEIRO = 'ACTIVE_ROTEIRO',
    COMPLETE_ROTEIRO = 'COMPLETE_ROTEIRO',
    ACTIVE_ADIANTAMENTO = 'ACTIVE_ADIANTAMENTO',
    COMPLETE_ADIANTAMENTO = 'COMPLETE_ADIANTAMENTO',
    ACTIVE_AEREO = 'ACTIVE_AEREO',
    COMPLETE_AEREO = 'COMPLETE_AEREO',
    ACTIVE_HOSPEDAGEM = 'ACTIVE_HOSPEDAGEM',
    COMPLETE_HOSPEDAGEM = 'COMPLETE_HOSPEDAGEM',
    ACTIVE_SERVICOS = 'ACTIVE_SERVICOS',
    COMPLETE_SERVICOS = 'COMPLETE_SERVICOS',
    ACTIVE_RATEIO = 'ACTIVE_RATEIO',
    COMPLETE_RATEIO = 'COMPLETE_RATEIO'
}

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import {
    Voo,
    Aereo,
    Passagem,
    AereoRequest,
    Trecho
} from '../../contexts/interfaces/aereo'
import PlaneGo from '../../core/assets/plane-1.svg'
import PlaneBack from '../../core/assets/plane-2.svg'
import GolImg from '../../core/assets/Gol.jpg'
import AzulImg from '../../core/assets/Azul.png'
import LatamImg from '../../core/assets/Latam.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Center } from '../../core/components/Center'
import { useSpinner } from '../../hooks/Spinner'
import { BASE_URL_AEREO, ENDPOINTS } from '../../services/config-http'
import { get } from '../../services/http'
import { Button } from '../../core/components/Button'
import { Form } from '@unform/web'
import { Space } from '../../core/components/Space'
import { Switch } from '../../core/components/Switch'
import { TimeSlider } from '../../core/components/TimeSlider/Index'
import { DatePicker } from '../../core/components/DatePicker'
import { SelectRequester } from '../../core/components/Form/SelectWithRequest'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import { MapFormErrors } from '../../core/utils/MapFormErrors'
import { Toast } from '../../core/components/Toast'
import { MoneyFormat } from '../../core/utils/MoneyFormat'
import { Alert } from '../../core/components/Alert'
import { Solicitacao } from '../../contexts/interfaces/solicitacao'
import moment from 'moment'
import useWindowDimensions from '../../core/components/GetDimensions'
import { TrMobile, getReservaMobile } from '../../core/components/Mobile'
import { DisableForMobile, IsMobile } from '../Viagem/Solicitacao/stepMobile'

interface CadastroAereoProp {
    idSolicitacao: number
    qtdViajantes: number
    editar?: boolean
    changeLabel?: any
    isEtapa?: any
}

export const CadastroAereo = ({
    idSolicitacao,
    qtdViajantes = 1,
    editar = false,
    changeLabel,
    isEtapa
}: CadastroAereoProp) => {
    const [reservas, setReservas] = useState<Aereo[]>([])
    const [idaVolta, setIdaVolta] = useState(true)
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })
    const [regrasPoliticaViagem, setRegrasPoliticaViagem] = useState<any>()
    const [possuiBagagem, setPossuiBagagem] = useState(false)
    const [showAlertMenorPreço, setShowAlertMenorPreco] = useState(false)
    const [dataIda, setDataIda] = useState<Date>()
    const [dataVolta, setDataVolta] = useState<Date>()
    const [passagens, setPassagens] = useState<Passagem>()
    const [timeStart, setTimeStart] = useState({ start: '00:00', end: '23:59' })
    const [timeReturn, setTimeReturn] = useState({
        start: '00:00',
        end: '23:59'
    })
    const FormRef = useRef<FormHandles>(null)
    const { setLoading } = useSpinner()
    const [roteiro, setRoteiro] = useState([
        {
            dataInicio: new Date().toLocaleDateString(),
            dataTermino: new Date().toLocaleDateString()
        }
    ])
    const [fromLocalStorage, setFromLocalStorage] = useState(false)
    const { height, width } = useWindowDimensions()

    useEffect(() => {
        if (reservas && isEtapa) {
            changeLabel(reservas?.length)
        }
    }, [reservas])

    useEffect(() => {
        if (idSolicitacao !== undefined) {
            get(`${ENDPOINTS.solicitacaoViagemPorId}/${idSolicitacao}`).then(
                ({ data: { Model } }) => {
                    const solicitacao = Model
                    if (solicitacao?.IdSituacao === 13) {
                        const sol = localStorage.getItem('solicitacao')
                        if (sol !== null) {
                            const solicitacaoStorage = JSON.parse(
                                sol
                            ) as Solicitacao
                            setRoteiro(solicitacaoStorage?.destinos)
                            setFromLocalStorage(true)
                        }
                    } else {
                        setRoteiro(solicitacao?.Destinos || [])
                        setFromLocalStorage(false)
                    }
                    get(
                        `${
                            ENDPOINTS.regrasPoliticaViagem
                        }/BuscarPorIdPolitica/${
                            solicitacao?.Viajantes?.filter(
                                (item: any) => item?.Convidado === false
                            )[0].IdPoliticaViagem
                        }`
                    )
                        .then(({ data: { Model } }) => {
                            const regras: Array<any> = Model
                            setRegrasPoliticaViagem(
                                regras.filter(
                                    item =>
                                        item?.IdTipoSolicitacao ===
                                        solicitacao?.IdTipoSolicitacao
                                )[0]
                            )
                        })
                        .catch(err => {
                            console.error(err)
                        })
                }
            )
        }

        const buscaPassagensReservadas = async () => {
            setLoading(true)
            setReservas([])
            await get(
                `${ENDPOINTS.aereoResumido.replace(
                    ':idSolicitacao',
                    idSolicitacao.toString()
                )}`
            )
                .then(({ data: { Model } }) => {
                    setLoading(false)
                    Model?.forEach((element: any) => {
                        const {
                            DataIdaChegada,
                            DataIdaSaida,
                            DataVoltaChegada,
                            DataVoltaSaida,
                            Companhia,
                            QtdEscalas,
                            AeroportoDestino,
                            AeroportoOrigem,
                            IdaVolta,
                            Valor
                        } = element
                        const newAereo: Aereo = {
                            DataIdaChegada: new Date(DataIdaChegada),
                            DataIdaSaida: new Date(DataIdaSaida),
                            DataVoltaChegada: new Date(DataVoltaChegada),
                            DataVoltaSaida: new Date(DataVoltaSaida),
                            Companhia: Companhia,
                            QtdEscalas: QtdEscalas,
                            AeroportoDestino: AeroportoDestino,
                            AeroportoOrigem: AeroportoOrigem,
                            Valor: Valor,
                            IdaVolta: IdaVolta
                        }
                        setIdaVolta(IdaVolta)
                        setReservas(reservas => [...reservas, newAereo])
                    })
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                    setShowToast(true)
                    setToast({
                        message: `Erro ao buscar passagens reservadas`,
                        type: 'error'
                    })
                })
        }
        if (idSolicitacao !== 0) buscaPassagensReservadas()
    }, [idSolicitacao])

    const renderLogoCia = (cia: string) => {
        switch (cia) {
            case 'G3':
                return (
                    <ContainerLogo>
                        <img src={GolImg} alt="Gol"></img>
                    </ContainerLogo>
                )
            case 'AD':
                return (
                    <ContainerLogo>
                        <img src={AzulImg} alt="Azul"></img>
                    </ContainerLogo>
                )
            case 'JJ':
                return (
                    <ContainerLogo>
                        <img src={LatamImg} alt="Latam"></img>
                    </ContainerLogo>
                )

            default:
                break
        }
    }

    const handleSubmit: SubmitHandler = async form => {
        try {
            let schema

            if (idaVolta) {
                schema = Yup.object().shape({
                    origem: Yup.string().required(
                        'O Campo de Aeroporto de Origem é Obrigatório'
                    ),
                    destino: Yup.string().required(
                        'O Campo de Aeroporto de Origem é Obrigatório'
                    ),
                    dataIda: Yup.date()
                        .nullable()
                        .required('O Campo de Data de Saida é Obrigatório'),
                    dataVolta: Yup.date()
                        .nullable()
                        .required('O Campo de Data de Retorno é Obrigatório')
                })
            } else {
                schema = Yup.object().shape({
                    origem: Yup.string().required(
                        'O Campo de Aeroporto de Origem é Obrigatório'
                    ),
                    destino: Yup.string().required(
                        'O Campo de Aeroporto de Origem é Obrigatório'
                    ),
                    dataIda: Yup.date()
                        .nullable()
                        .required('O Campo de Data de Saida é Obrigatório')
                })
            }
            await schema.validate(form, { abortEarly: false })
            const data: AereoRequest = {
                temBagagem: possuiBagagem,
                idaVolta: idaVolta,
                dataIda: setDate(form.dataIda, 'START'),
                destino: form.destino,
                origem: form.origem,
                quantidadeAdultos: qtdViajantes
            }
            setDataIda(form.dataIda)
            if (idaVolta) {
                data.dataVolta = setDate(form.dataVolta, 'RETURN')
                setDataVolta(form.dataVolta)
            }

            setLoading(true)
            const token = localStorage.getItem('@ViagensCorp:token')
            axios
                .post(ENDPOINTS.buscarVoos, data, {
                    baseURL: BASE_URL_AEREO,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(({ data }) => {
                    const { Exception } = data
                    if (Exception !== null) throw Exception()

                    const { ViagensTrecho1 } = data
                    if (ViagensTrecho1.length === 0) {
                        setShowToast(true)
                        setToast({
                            message: `Nenhuma passagem encontrada para essa busca`,
                            type: 'error'
                        })
                        setLoading(false)
                        return
                    }

                    if (regrasPoliticaViagem.ValidarMenorPrecoAereo) {
                        const min = ViagensTrecho1?.reduce(function (
                            a: any,
                            b: any
                        ) {
                            return Math.min(a, b.Preco?.Total)
                        },
                        ViagensTrecho1[0].Preco?.Total)
                        data.ViagensTrecho1 = ViagensTrecho1.filter(
                            (item: any) => item.Preco.Total === min
                        )
                        setShowAlertMenorPreco(true)
                    }

                    setPassagens(data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    setShowToast(true)
                    setToast({
                        message: `Erro ao buscar passagens`,
                        type: 'error'
                    })
                    return err
                })
            FormRef.current?.setErrors({})
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                FormRef.current?.setErrors(erros)
            }
        }
    }

    const getMinDate = () => {
        let minDate: Date
        if (fromLocalStorage) {
            minDate = new Date(
                moment(roteiro[0]?.dataInicio, 'DD/MM/YYYY').format(
                    'YYYY-MM-DD'
                ) + ' 00:00:00'
            )
        } else {
            minDate = new Date(roteiro[0]?.dataInicio)
        }
        // Valida 10 dias de antecedencia, caso for antes, bloqueia o campo..
        if (
            regrasPoliticaViagem?.ValidarPrazoAntecedenciaParaRequisacaoDeAereo
        ) {
            const minDateComRegra = new Date()
            minDateComRegra.setDate(minDateComRegra.getDate() + 10)
            if (minDateComRegra.getTime() > minDate.getTime()) {
                return minDateComRegra
            }
        }
        return minDate
    }

    const getMaxDate = () => {
        if (fromLocalStorage) {
            return new Date(
                moment(
                    roteiro[roteiro?.length - 1]?.dataTermino,
                    'DD/MM/YYYY'
                ).format('YYYY-MM-DD') + ' 00:00:00'
            )
        }
        return new Date(roteiro[roteiro?.length - 1]?.dataTermino)
    }

    const setDate = (date: Date, time: 'START' | 'RETURN') => {
        const timer = time === 'START' ? timeStart : timeReturn
        return convertDateEpoch(
            new Date(
                `${date.toISOString().slice(0, 10)}T${timer.start}-${timer.end}`
            )
        )
    }

    const convertDateEpoch = (date: Date) =>
        `/Date(${date.getTime() - date.getMilliseconds() / 1000})/`

    const reservar = async (aereo: Aereo) => {
        setLoading(true)
        const token = localStorage.getItem('@ViagensCorp:token')
        await axios
            .post(ENDPOINTS.reservarVoos, aereo, {
                baseURL: BASE_URL_AEREO,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(({ data }) => {
                setReservas(reservas => [...reservas, aereo])
                setLoading(false)
                window.scrollTo(0, 0)
                setShowToast(true)
                setToast({
                    message: `Passagem reservada com sucesso!`,
                    type: 'success'
                })
                if (editar) {
                    setTimeout(() => {
                        document.location.reload()
                    }, 2000)
                }
            })
            .catch(error => {
                setLoading(false)
                setShowToast(true)
                setToast({
                    message:
                        error?.response?.data || `Erro ao reservar passagens`,
                    type: 'error'
                })
                return error
            })
    }

    const getReserva = () => {
        return reservas.map((reserva, index) => {
            return (
                <div
                    key={index}
                    style={{
                        border: '1px solid #BDBDBD',
                        margin: '1rem 10rem',
                        padding: '1rem'
                    }}
                >
                    <div className="row">
                        <div className="col-3 title-passagem">Origem</div>
                        <div className="col-3 title-passagem">Destino</div>
                        <div className="col-2 title-passagem">Escalas</div>
                        <div className="col-2 title-passagem">Companhia</div>
                        <div className="col-2 title-passagem">Valor</div>
                    </div>
                    <div
                        className="row"
                        style={{
                            color: '#5F8DD3',
                            fontSize: 'medium'
                        }}
                    >
                        <div className="col-3">
                            {idaVolta ? (
                                <div>
                                    <div className="row">
                                        <div className="col-2">
                                            {reserva.AeroportoOrigem}
                                        </div>
                                        <div
                                            className="col"
                                            style={{
                                                marginLeft: '.5rem'
                                            }}
                                        >
                                            {reserva.DataIdaSaida?.toLocaleDateString(
                                                'pt-BR',
                                                {
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2">
                                            {reserva.AeroportoDestino}
                                        </div>
                                        <div
                                            className="col"
                                            style={{
                                                marginLeft: '.5rem'
                                            }}
                                        >
                                            {reserva.DataVoltaSaida?.toLocaleDateString(
                                                'pt-BR',
                                                {
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-2">
                                        {reserva.AeroportoOrigem}
                                    </div>
                                    <div
                                        className="col"
                                        style={{
                                            marginLeft: '.5rem'
                                        }}
                                    >
                                        {reserva.DataIdaSaida?.toLocaleDateString(
                                            'pt-BR',
                                            {
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            }
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-3">
                            {idaVolta ? (
                                <div>
                                    <div className="row">
                                        <div className="col-2">
                                            {reserva.AeroportoDestino}
                                        </div>
                                        <div
                                            className="col"
                                            style={{
                                                marginLeft: '.5rem'
                                            }}
                                        >
                                            {reserva.DataIdaChegada?.toLocaleDateString(
                                                'pt-BR',
                                                {
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2">
                                            {reserva.AeroportoOrigem}
                                        </div>
                                        <div
                                            className="col"
                                            style={{
                                                marginLeft: '.5rem'
                                            }}
                                        >
                                            {reserva.DataVoltaChegada?.toLocaleDateString(
                                                'pt-BR',
                                                {
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-2">
                                        {reserva.AeroportoDestino}
                                    </div>
                                    <div
                                        className="col"
                                        style={{
                                            marginLeft: '.5rem'
                                        }}
                                    >
                                        {reserva.DataIdaChegada?.toLocaleDateString(
                                            'pt-BR',
                                            {
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            }
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className="col-2"
                            style={{
                                alignContent: 'center',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {reserva.QtdEscalas === 1 ? (
                                <div>{reserva.QtdEscalas} PARADAS</div>
                            ) : (
                                <div>{reserva.QtdEscalas} PARADA</div>
                            )}
                        </div>
                        <div
                            className="col-2"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {renderLogoCia(reserva.Companhia || '')}
                        </div>
                        <div
                            className="col-2"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: 'bold',
                                color: '#002A5D'
                            }}
                        >
                            {MoneyFormat(reserva.Valor || 0)}
                        </div>
                    </div>
                </div>
            )
        })
    }
    const getMobile = (index: number, passagens: Passagem, trecho: Trecho) => {
        return (
            <Center key={index}>
                <Quadro>
                    {idaVolta &&
                        getTrecho(
                            trecho,
                            trecho.CiaMandatoria.CodigoIata,
                            passagens.Origem.CodigoIata,
                            passagens.Destino.CodigoIata,
                            passagens.Origem.Descricao,
                            passagens.Destino.Descricao,
                            true,
                            dataIda
                        )}
                    {idaVolta &&
                        getTrecho(
                            trecho,
                            trecho.CiaMandatoria.CodigoIata,
                            passagens.Destino.CodigoIata,
                            passagens.Origem.CodigoIata,
                            passagens.Destino.Descricao,
                            passagens.Origem.Descricao,
                            false,
                            dataVolta
                        )}
                    {!idaVolta &&
                        getTrecho(
                            trecho,
                            trecho.CiaMandatoria.CodigoIata,
                            passagens.Origem.CodigoIata,
                            passagens.Destino.CodigoIata,
                            passagens.Origem.Descricao,
                            passagens.Destino.Descricao,
                            true,
                            dataIda
                        )}
                    <Container>
                        <Center>
                            <Esquerda>
                                <Valor1>
                                    <TextoValores>Valor total</TextoValores>
                                </Valor1>
                                <Valor2>
                                    <TextoValores>
                                        {MoneyFormat(trecho.Preco.Total || 0)}
                                    </TextoValores>
                                </Valor2>
                            </Esquerda>
                        </Center>
                        <Meio>ou</Meio>
                        <Center>
                            <Direita>
                                <Valor3>
                                    <TextoValores>Por Viajante</TextoValores>
                                </Valor3>
                                <Valor4>
                                    <TextoValores>
                                        {MoneyFormat(
                                            trecho.Preco.Total / qtdViajantes
                                        )}
                                    </TextoValores>
                                </Valor4>
                            </Direita>
                        </Center>
                    </Container>
                    <Title>{getBtnReservar(trecho)}</Title>
                </Quadro>
            </Center>
        )
    }

    const getTrecho = (
        trecho: Trecho,
        logoIata: string,
        CodigoIata1: string,
        CodigoIata2: string,
        Descricao1: string,
        Descricao2: string,
        IdaVolta: boolean,
        dataViagem?: Date
    ) => {
        return (
            <>
                <Title>{renderLogoCia(logoIata || '')}</Title>
                <Data>{dataViagem?.toLocaleDateString()}</Data>
                <Evento>
                    <Detail>{IdaVolta ? 'IDA' : 'VOLTA'}</Detail>
                </Evento>
                <Container>
                    <Esquerda>
                        <Iata>{CodigoIata1}</Iata>
                        <Center>
                            <Text>{Descricao1}</Text>
                        </Center>
                    </Esquerda>
                    <Meio>
                        <img src={IdaVolta ? PlaneGo : PlaneBack} alt="aviao" />
                    </Meio>

                    <Direita>
                        <Iata>{CodigoIata2}</Iata>
                        <Center>
                            <Text>{Descricao2}</Text>
                        </Center>
                    </Direita>
                </Container>
                <Title>Escalas</Title>
                {IdaVolta
                    ? ListaEscalas(
                          trecho.Voos?.filter(s => s.Segmento === 'I'),
                          2
                      )
                    : ListaEscalas(
                          trecho.Voos?.filter(s => s.Segmento !== 'I'),
                          2
                      )}
            </>
        )
    }

    const getReservasMobile = (
        logoIata: string,
        CodigoIata1: string,
        CodigoIata2: string,
        Descricao1: string,
        Descricao2: string,
        IdaVolta: boolean,
        dataViagem?: Date
    ) => {
        return (
            <>
                <Title>{renderLogoCia(logoIata || '')}</Title>
                <Data>{dataViagem?.toLocaleDateString()}</Data>
                <Evento>
                    <Detail>{IdaVolta ? 'IDA' : 'VOLTA'}</Detail>
                </Evento>
                <Container>
                    <Esquerda>
                        <Iata>{CodigoIata1}</Iata>
                        <Center>
                            <Text>{Descricao1}</Text>
                        </Center>
                    </Esquerda>
                    <Meio>
                        <img src={IdaVolta ? PlaneGo : PlaneBack} alt="aviao" />
                    </Meio>

                    <Direita>
                        <Iata>{CodigoIata2}</Iata>
                        <Center>
                            <Text>{Descricao2}</Text>
                        </Center>
                    </Direita>
                </Container>
            </>
        )
    }

    const getBtnReservar = (trecho: Trecho) => {
        return (
            <button
                onClick={e => {
                    const voosIda = trecho.Voos.filter(s => s.Segmento === 'I')
                    const voosVolta = trecho.Voos.filter(
                        s => s.Segmento !== 'I'
                    )

                    const newAereo: Aereo = {
                        Valor: trecho.Preco.Total,
                        Companhia: trecho.CiaMandatoria.CodigoIata.trim(),
                        QtdEscalas: trecho.NumeroParadas,
                        AeroportoOrigem: passagens?.Origem.CodigoIata,
                        AeroportoDestino: passagens?.Destino.CodigoIata,
                        IdaVolta: idaVolta,
                        TemBagagem: possuiBagagem,
                        DataIdaChegada: new Date(
                            parseInt(
                                voosIda[
                                    voosIda.length - 1
                                ].DataChegada.replaceAll(
                                    '/Date(',
                                    ''
                                ).replaceAll(')/', '')
                            )
                        ),
                        DataIdaSaida: new Date(
                            parseInt(
                                voosIda[0].DataSaida.replaceAll(
                                    '/Date(',
                                    ''
                                ).replaceAll(')/', '')
                            )
                        ),
                        DataVoltaChegada: new Date(
                            parseInt(
                                voosVolta[
                                    voosVolta.length - 1
                                ].DataChegada.replaceAll(
                                    '/Date(',
                                    ''
                                ).replaceAll(')/', '')
                            )
                        ),
                        DataVoltaSaida: new Date(
                            parseInt(
                                voosVolta[0].DataSaida.replaceAll(
                                    '/Date(',
                                    ''
                                ).replaceAll(')/', '')
                            )
                        ),
                        IdAereo: trecho.Id,
                        IdSolicitacaoViagem: idSolicitacao,
                        IdentificadorAereo: trecho.IdentificacaoDaViagem
                    }
                    reservar(newAereo)
                }}
                style={{
                    backgroundColor: '#0B4BB1',
                    color: '#FFF',
                    border: 'none',
                    borderRadius: '2rem',
                    width: '10rem',
                    height: '2rem',
                    marginTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '1rem'
                }}
            >
                Selecionar
                <FontAwesomeIcon
                    style={{
                        marginLeft: '.5rem',
                        fontSize: '1rem'
                    }}
                    icon="angle-right"
                />
            </button>
        )
    }

    const handleReservar = (trecho: any, passagens: any) => {
        const voosIda = trecho.Voos.filter(s => s.Segmento === 'I')
        const voosVolta = trecho.Voos.filter(s => s.Segmento !== 'I')

        const newAereo: Aereo = {
            Valor: trecho.Preco.Total,
            Companhia: trecho.CiaMandatoria.CodigoIata.trim(),
            QtdEscalas: trecho.NumeroParadas,
            AeroportoOrigem: passagens.Origem.CodigoIata,
            AeroportoDestino: passagens.Destino.CodigoIata,
            IdaVolta: idaVolta,
            TemBagagem: possuiBagagem,
            DataIdaChegada: new Date(
                parseInt(
                    voosIda[voosIda.length - 1].DataChegada.replaceAll(
                        '/Date(',
                        ''
                    ).replaceAll(')/', '')
                )
            ),
            DataIdaSaida: new Date(
                parseInt(
                    voosIda[0].DataSaida.replaceAll('/Date(', '').replaceAll(
                        ')/',
                        ''
                    )
                )
            ),
            DataVoltaChegada: new Date(
                parseInt(
                    voosVolta[voosVolta.length - 1].DataChegada.replaceAll(
                        '/Date(',
                        ''
                    ).replaceAll(')/', '')
                )
            ),
            DataVoltaSaida: new Date(
                parseInt(
                    voosVolta[0].DataSaida.replaceAll('/Date(', '').replaceAll(
                        ')/',
                        ''
                    )
                )
            ),
            IdAereo: trecho.Id,
            IdSolicitacaoViagem: idSolicitacao,
            IdentificadorAereo: trecho.IdentificacaoDaViagem
        }
        reservar(newAereo)
    }

    const getDesktop = () => {
        return passagens?.ViagensTrecho1?.map(
            (trecho: Trecho, index: number) => {
                return (
                    <div className="passagem-aereo" key={index}>
                        {renderLogoCia(trecho.CiaMandatoria.CodigoIata)}
                        {idaVolta ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}
                            >
                                <div className="trecho-aereo">
                                    <div>
                                        {InfoViagem(
                                            passagens.Origem.CodigoIata,
                                            passagens.Origem.Descricao,
                                            passagens.Destino.CodigoIata,
                                            passagens.Destino.Descricao,
                                            true,
                                            dataIda
                                        )}
                                        <div style={{ width: '90%' }}>
                                            {ListaEscalas(
                                                trecho.Voos?.filter(
                                                    s => s.Segmento === 'I'
                                                ),
                                                1
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: '20%',
                                        marginTop: '2rem'
                                    }}
                                >
                                    <div>
                                        <div
                                            style={{
                                                backgroundColor: '#24538B',
                                                color: '#FFF',
                                                padding:
                                                    '.5rem 2rem .5rem 2rem',
                                                borderRadius: '.6rem .6rem 0 0',
                                                width: '9rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            Valor Total
                                        </div>
                                        <div
                                            style={{
                                                color: '#0B4BB1',
                                                padding:
                                                    '.5rem 2rem .5rem 2rem',
                                                border: '1px solid #0B4BB1',
                                                borderRadius: '0 0 .5rem .5rem',
                                                width: '9rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {MoneyFormat(
                                                trecho.Preco.Total || 0
                                            )}
                                        </div>
                                    </div>
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '1rem'
                                        }}
                                    >
                                        ou
                                    </p>
                                    <div>
                                        <div
                                            style={{
                                                backgroundColor: '#7C838D',
                                                color: '#FFF',
                                                padding:
                                                    '.5rem 2rem .5rem 2rem',
                                                borderRadius: '.6rem .6rem 0 0',
                                                width: '9rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            por Viajante
                                        </div>
                                        <div
                                            style={{
                                                color: '#7C838D',
                                                padding:
                                                    '.5rem 2rem .5rem 2rem',
                                                border: '1px solid #7C838D',
                                                borderRadius: '0 0 .5rem .5rem',
                                                width: '9rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {MoneyFormat(
                                                trecho.Preco.Total /
                                                    qtdViajantes
                                            )}
                                        </div>
                                    </div>

                                    <button
                                        onClick={e =>
                                            handleReservar(trecho, passagens)
                                        }
                                        style={{
                                            backgroundColor: '#0B4BB1',
                                            color: '#FFF',
                                            border: 'none',
                                            borderRadius: '2rem',
                                            width: '8rem',
                                            height: '2rem',
                                            marginTop: '3rem',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        Reservar
                                        <FontAwesomeIcon
                                            style={{
                                                marginLeft: '.5rem',
                                                fontSize: '1rem'
                                            }}
                                            icon="angle-right"
                                        />
                                    </button>
                                </div>
                                <div>
                                    {InfoViagem(
                                        passagens.Destino.CodigoIata,
                                        passagens.Destino.Descricao,
                                        passagens.Origem.CodigoIata,
                                        passagens.Origem.Descricao,
                                        false,
                                        dataVolta
                                    )}
                                    <div style={{ width: '90%' }}>
                                        {ListaEscalas(
                                            trecho.Voos?.filter(
                                                s => s.Segmento !== 'I'
                                            ),
                                            1
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    paddingTop: '2rem'
                                }}
                            >
                                <div style={{ width: '40%' }}>
                                    {InfoViagem(
                                        passagens.Origem.CodigoIata,
                                        passagens.Origem.Descricao,
                                        passagens.Destino.CodigoIata,
                                        passagens.Destino.Descricao,
                                        true,
                                        dataIda
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: '20%'
                                    }}
                                >
                                    <div>
                                        <div
                                            style={{
                                                backgroundColor: '#24538B',
                                                color: '#FFF',
                                                padding:
                                                    '.5rem 2rem .5rem 2rem',
                                                borderRadius: '.6rem .6rem 0 0',
                                                width: '9rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            Valor Total
                                        </div>
                                        <div
                                            style={{
                                                color: '#0B4BB1',
                                                padding:
                                                    '.5rem 2rem .5rem 2rem',
                                                border: '1px solid #0B4BB1',
                                                borderRadius: '0 0 .5rem .5rem',
                                                width: '9rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {MoneyFormat(trecho.Preco.Total)}
                                        </div>
                                    </div>
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '1rem'
                                        }}
                                    >
                                        ou
                                    </p>
                                    <div>
                                        <div
                                            style={{
                                                backgroundColor: '#7C838D',
                                                color: '#FFF',
                                                padding:
                                                    '.5rem 2rem .5rem 2rem',
                                                borderRadius: '.6rem .6rem 0 0',
                                                width: '9rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            por Viajante
                                        </div>
                                        <div
                                            style={{
                                                color: '#7C838D',
                                                padding:
                                                    '.5rem 2rem .5rem 2rem',
                                                border: '1px solid #7C838D',
                                                borderRadius: '0 0 .5rem .5rem',
                                                width: '9rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {MoneyFormat(
                                                trecho.Preco.Total /
                                                    qtdViajantes
                                            )}
                                        </div>
                                    </div>

                                    <button
                                        style={{
                                            backgroundColor: '#0B4BB1',
                                            color: '#FFF',
                                            border: 'none',
                                            borderRadius: '2rem',
                                            width: '8rem',
                                            height: '2rem',
                                            marginTop: '3rem',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        onClick={e =>
                                            handleReservar(trecho, passagens)
                                        }
                                    >
                                        Reservar
                                        <FontAwesomeIcon
                                            style={{
                                                marginLeft: '.5rem',
                                                fontSize: '1rem'
                                            }}
                                            icon="angle-right"
                                        />
                                    </button>
                                </div>
                                <div style={{ width: '40%' }}>
                                    {ListaEscalas(trecho.Voos, 1)}
                                </div>
                            </div>
                        )}
                    </div>
                )
            }
        )
    }

    return (
        <>
            {reservas.length ? (
                <>
                    <Center
                        style={{
                            color: '#5F8DD3',
                            fontSize: 'medium',
                            marginBottom: '5px'
                        }}
                    >
                        Informações de seus voos
                    </Center>
                    {!editar && (
                        <>
                            <DisableForMobile>{getReserva()}</DisableForMobile>
                            <IsMobile>{getReservaMobile(reservas)}</IsMobile>
                        </>
                    )}
                </>
            ) : null}

            {showAlertMenorPreço ? (
                <Alert
                    fixed={true}
                    icon="exclamation-circle"
                    type="warning"
                    message="Você precisa reservar seu voo com no mínimo 10 dias de antecedência. "
                ></Alert>
            ) : (
                ''
            )}

            {reservas.length === 0 ? (
                <div style={{ display: 'flex', width: '16rem' }}>
                    <input
                        type="button"
                        style={idaVolta ? selecionado : nselecionado}
                        value="Ida e volta"
                        onClick={e => {
                            setIdaVolta(true)
                            setPassagens(undefined)
                        }}
                    />
                    <input
                        type="button"
                        style={!idaVolta ? selecionado : nselecionado}
                        value="Somente ida"
                        onClick={e => {
                            setIdaVolta(false)
                            setPassagens(undefined)
                        }}
                    />
                </div>
            ) : null}
            <Form ref={FormRef} onSubmit={handleSubmit} style={{ padding: 0 }}>
                <div className="row">
                    <div className="col-12 col-md-5">
                        <SelectRequester
                            name="origem"
                            label="Origem"
                            placeholder="Escolha o aeroporto de embarque"
                            required={true}
                            request={{
                                endpoint: ENDPOINTS.aeroportoListar,
                                labelToSelect: 'Nome',
                                valueToSelect: 'Iata'
                            }}
                        ></SelectRequester>
                    </div>
                    <div className="col-12 col-md-2">
                        <ContentCircle>
                            <Circle>
                                <FontAwesomeIcon icon="exchange-alt" />
                            </Circle>
                        </ContentCircle>
                    </div>
                    <div className="col-sm-12 col-md-5">
                        <SelectRequester
                            name="destino"
                            required={true}
                            placeholder="Escolha o aeroporto de desembarque"
                            label="Destino"
                            request={{
                                endpoint: ENDPOINTS.aeroportoListar,
                                labelToSelect: 'Nome',
                                valueToSelect: 'Iata'
                            }}
                        ></SelectRequester>
                    </div>
                    <div className="col-md-12 col-lg-5">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <DatePicker
                                    name="dataIda"
                                    label="Data de Ida"
                                    required={true}
                                    minDate={getMinDate()}
                                    maxDate={getMaxDate()}
                                ></DatePicker>
                            </div>
                            <div className="col-12 col-md-6 margin-auto">
                                <TimeSlider
                                    timer={timeStart}
                                    setTimer={setTimeStart}
                                ></TimeSlider>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <Line />
                    </div>
                    {idaVolta ? (
                        <div className="col-md-12 col-lg-5">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <DatePicker
                                        name="dataVolta"
                                        label="Data de Volta"
                                        minDate={getMinDate()}
                                        maxDate={getMaxDate()}
                                        required={true}
                                    ></DatePicker>
                                </div>
                                <div className="col-12 col-md-6 margin-auto">
                                    <TimeSlider
                                        timer={timeReturn}
                                        setTimer={setTimeReturn}
                                    ></TimeSlider>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <Space height="2rem"></Space>
                    <div className="col-sm-12 col-md-7">
                        <Switch
                            name="BuscarVoosComBagagem"
                            label="Possui Bagagem?"
                            onChange={e => {
                                setPossuiBagagem(!possuiBagagem)
                            }}
                        ></Switch>
                    </div>

                    <div className="col-sm-12 col-md-5 col-lg-4">
                        <>
                            <Space height="2rem"></Space>
                            <Center>
                                <IsMobile>
                                    <Button colorType="btnMobile" type="submit">
                                        Buscar
                                    </Button>
                                </IsMobile>
                            </Center>
                            <DisableForMobile>
                                <Button
                                    style={{
                                        width: '100%',
                                        minWidth: 'auto',
                                        marginTop: '15px',
                                        justifyContent: 'flex-start'
                                    }}
                                    colorType="quintiary"
                                >
                                    Buscar
                                </Button>
                            </DisableForMobile>
                        </>
                    </div>
                    <div className="col-md-12 col-lg-1"></div>
                </div>
            </Form>
            <Space height="3rem"></Space>
            {showAlertMenorPreço ? (
                <Alert
                    fixed={true}
                    icon="exclamation-circle"
                    type="warning"
                    message="A política do viajante principal permite que seja selecionado apenas as passagens de menor preço "
                ></Alert>
            ) : (
                ''
            )}
            <Space height="2rem"></Space>
            <DisableForMobile>{getDesktop()}</DisableForMobile>
            <IsMobile>
                {passagens?.ViagensTrecho1?.map(
                    (trecho: Trecho, index: number) => {
                        return getMobile(index, passagens, trecho)
                    }
                )}
            </IsMobile>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                    standard={false}
                />
            ) : null}
        </>
    )
}

//  Components
const InfoViagem = (
    iataOrigem: string,
    descricaoOrigem: string,
    iataDestino: string,
    descricaoDestino: string,
    idaVolta: boolean,
    dataViagem?: Date
) => {
    return (
        <div className="trecho-aereo">
            <p
                style={{
                    color: '#24538B',
                    margin: '0 0 .2rem 0'
                }}
            >
                {dataViagem?.toLocaleDateString()}
            </p>
            <p className="label">{idaVolta ? 'IDA' : 'VOLTA'}</p>
            <div className="container-trecho">
                <div
                    style={{
                        height: '100%',
                        width: '40%'
                    }}
                >
                    <p className="codigo-label" style={{ textAlign: 'right' }}>
                        {iataOrigem}
                    </p>
                    <p>{descricaoOrigem}</p>
                </div>
                <div
                    style={{
                        width: '20%',
                        textAlign: 'center'
                    }}
                >
                    <img src={idaVolta ? PlaneGo : PlaneBack} alt="aviao" />
                </div>
                <div
                    style={{
                        height: '100%',
                        width: '40%'
                    }}
                >
                    <p className="codigo-label" style={{ textAlign: 'left' }}>
                        {iataDestino}
                    </p>
                    <p>{descricaoDestino}</p>
                </div>
            </div>
        </div>
    )
}

const ListaEscalas = (voos: Voo[], tipo: number) => {
    return tipo === 1 ? (
        <div>
            <p
                style={{
                    color: '#24538B',
                    textAlign: 'center',
                    fontSize: '1rem'
                }}
            >
                Escalas
            </p>
            {voos.map((voo: Voo, index: number) => {
                return (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div style={{ width: '40%' }}>
                            <p
                                style={{
                                    margin: '0',
                                    textAlign: 'right'
                                }}
                            >{`${voo.Origem.Descricao}`}</p>
                            <p
                                style={{
                                    textAlign: 'right',
                                    color: '#24538B',
                                    fontWeight: 'bold'
                                }}
                            >{`${voo.HoraSaida.substring(
                                0,
                                2
                            )}:${voo.HoraSaida.substring(2, 4)}`}</p>
                        </div>
                        <div
                            style={{
                                width: '20%',
                                textAlign: 'center'
                            }}
                        >
                            <FontAwesomeIcon
                                icon="arrow-right"
                                style={{
                                    color: '#8ED6FF'
                                }}
                            />
                        </div>
                        <div style={{ width: '40%' }}>
                            <p
                                style={{
                                    margin: '0'
                                }}
                            >{`${voo.Destino.Descricao}`}</p>
                            <p
                                style={{
                                    color: '#24538B',
                                    fontWeight: 'bold'
                                }}
                            >
                                {`${voo.HoraChegada.substring(
                                    0,
                                    2
                                )}:${voo.HoraChegada.substring(2, 4)}`}
                            </p>
                        </div>
                    </div>
                )
            })}
        </div>
    ) : (
        getListaScalaMobile(voos)
    )
}

const getListaScalaMobile = (voos: Voo[]) => {
    return voos.map((voo: Voo, index: number) => (
        <div key={index}>
            <Container>
                <Center>
                    <Esquerda>
                        <Text>{`${voo.Origem.Descricao}`}</Text>
                        <TimeEsquerda>{`${voo.HoraSaida.substring(
                            0,
                            2
                        )}:${voo.HoraSaida.substring(2, 4)}`}</TimeEsquerda>
                    </Esquerda>
                </Center>
                <Meio>
                    <FontAwesomeIcon
                        icon="arrow-right"
                        style={{
                            color: '#8ED6FF'
                        }}
                    />
                </Meio>
                <Center>
                    <Direita>
                        <Text>{`${voo.Destino.Descricao}`}</Text>
                        <TimeDireita>{`${voo.HoraChegada.substring(
                            0,
                            2
                        )}:${voo.HoraChegada.substring(2, 4)}`}</TimeDireita>
                    </Direita>
                </Center>
            </Container>
        </div>
    ))
}

//  Styled Components

const Quadro = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    width: 342px;
    height: 0 auto;
    min-height: 218px;
    background: #f1f3f5;
    border: 1px solid #dee2e6;

    box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;
    border-bottom: 2px dashed #6c7480;
    // margin: 1rem 0;
`

const Title = styled.span`
    display: flex;
    width: 100%;
    height: 0 auto;
    // background: #fff;
    justify-content: center;
    margin: 0;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;

    color: #24538b;
`
const Data = styled.span`
    display: flex;
    width: 100%;
    height: 0 auto;
    justify-content: center;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;

    color: #24538b;
`

const Evento = styled.span`
    display: flex;
    width: 100%;
    height: 0 auto;
    justify-content: center;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;

    color: #24538b;
`

const Detail = styled.span`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 16px;
    gap: 8px;
    width: 0 auto;
    height: 18px;
    color: #fff;
    background: #10478a;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
`

const TimeEsquerda = styled.span`
    width: 8rem;
    height: 18px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    /* identical to box height, or 180% */

    display: flex;
    align-items: flex-end;
    letter-spacing: 0.04em;
    justify-content: flex-end;
    // flex-directon: row-reverse;
    margin: 0 10px 0 -10px;

    color: #24538b;
    order: 1;
`

const TimeDireita = styled.span`
    width: 8rem;
    height: 18px;

    /* Text Semibold/Caption */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    /* identical to box height, or 180% */

    display: flex;
    align-items: flex-end;
    letter-spacing: 0.04em;
    margin: 0 10px 0 0.2rem;

    color: #24538b;
    order: 1;
`

const Text = styled.text`
    width: 110px;
    height: 18px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;

    letter-spacing: 0.04em;

    color: #6c7480;

    flex: none;
    order: 0;
    flex-grow: 0;
`

const Iata = styled.text`
    width: 100%;
    height: 33.44px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    color: #6c7480;
    justify-content: center;

    flex: none;
    order: 0;
    flex-grow: 0;
`
const Valor1 = styled.text`
    box-sizing: border-box;

    position: relative;
    width: 124px;
    height: 28px;
    display: flex;
    justify-content: center;

    color: #fff;
    background: #24538b;
    border: 1px solid #c1c1c1;
    border-radius: 12px 12px 0px 0px;
`

const TextoValores = styled.text`
    font-family: 'Montserrat';
    font-style: normal;
    display: flex;
    align-items: center;
    text-align: center;
`
const Valor3 = styled.text`
    box-sizing: border-box;
    position: relative;
    width: 124px;
    height: 28px;
    display: flex;
    justify-content: center;

    color: #fff;
    background: #7c838d;
    border: 1px solid #c1c1c1;
    border-radius: 12px 12px 0px 0px;
`

const Valor4 = styled.text`
    box-sizing: border-box;
    position: relative;
    width: 124px;
    height: 63px;
    display: flex;

    justify-content: center;

    color: #0b4bb1;
    border: 1px solid #c1c1c1;
    border-radius: 0px 0px 12px 12px;

    font-size: 18px;
    line-height: 22px;

    color: #7c838d;

    .span {
        margin: 0;
        padding: 0;
    }
`

const Valor2 = styled.text`
    box-sizing: border-box;
    position: relative;
    width: 124px;
    height: 63px;
    display: flex;
    justify-content: center;

    border: 1px solid #c1c1c1;
    border-radius: 0px 0px 12px 12px;

    font-size: 18px;
    line-height: 22px;

    color: #0b4bb1;
    border: 1px solid #c1c1c1;
    border-radius: 0px 0px 12px 12px;
    color: #0b4bb1;

    .span {
        margin: 0;
        padding: 0;
    }
`

const Container = styled.span`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`

const Esquerda = styled.span`
    width: 8rem;
    height: 0 auto;
    // background: #e1e1e2;
    margin: 1px;
`
const Meio = styled.span`
    width: 5rem;
    height: 0 auto;
    margin: 1px;
    display: flex;
    justify-content: center;
`
const Direita = styled.span`
    width: 8rem;
    height: 0 auto;
    // background: #e1e1e4;
    margin: 1px;
`

const Box = styled.div`
    @media (min-width: 375px) and (max-width: 400px) {
        // margin-left: -0.5rem;
    }
`
const selecionado = {
    border: '1px solid #10478A',
    width: '8rem',
    background: '#10478A',
    color: '#FFF'
}

const nselecionado = {
    border: '1px solid #10478A',
    width: '8rem',
    background: '#FFF',
    color: '#10478A'
}
const Circle = styled.div`
    width: 60px;
    height: 60px;
    background-color: #dbdee3;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    margin-top: 15px;
    @media (max-width: 720px) {
        transform: rotate(270deg);
        transition: 0.2s ease-in-out;
    }
`
const ContentCircle = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
const Line = styled.div`
    height: 50%;
    width: 2px;
    background-color: #dbdee3;
    margin: auto;
    margin-top: 20px;
`
const ContainerLogo = styled.div`
    width: 100px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: start;
    margin: 0 auto;

    img {
        width: 100%;
    }
`

import { Form } from '@unform/web'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ContaContabil } from '../../../../contexts/interfaces/contaContabil'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Button } from '../../../../core/components/Button'
import { Center } from '../../../../core/components/Center'
import { Description } from '../../../../core/components/Description'
import { Input } from '../../../../core/components/Form/Input'
import { Grid } from '../../../../core/components/Grid'
import { Header } from '../../../../core/components/Header/Header'
import { LineSeparator } from '../../../../core/components/LineSeparator'
import { Subtitle } from '../../../../core/components/Subtitle'
import { Switch } from '../../../../core/components/Switch'
import { Alert } from '../../../../core/components/Alert'
import { UserContext } from '../../../../contexts/UserContext'
import { post } from '../../../../services/http'
import { ENDPOINTS } from '../../../../services/config-http'
import { useAlert } from '../../../../hooks/AlertToast'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { useHistory } from 'react-router'
import {
    DisableForMobile,
    IsMobile
} from '../../../Viagem/Solicitacao/stepMobile'

const CadastroContaContabil = () => {
    const FormRef = useRef<FormHandles>(null)
    const { setAlert, showAlert } = useAlert()

    const [codigo, setCodigo] = useState('')
    const [nome, setNome] = useState('')
    const [contaPadrao, setContaPadrao] = useState(true)
    const [status, setStatus] = useState(true)
    const history = useHistory()

    const handleSubmit: SubmitHandler = async (
        contaContabil: ContaContabil
    ) => {
        try {
            const schema = Yup.object().shape({
                Codigo: Yup.string().required('O Campo codigo é obrigatório'),
                Nome: Yup.string().required('O Campo nome é obrigatório')
            })

            await schema.validate(contaContabil, { abortEarly: false })
            contaContabil.Codigo = codigo
            contaContabil.Nome = nome
            contaContabil.ContaPadrao = contaPadrao
            contaContabil.Status = status
            showAlert(false)
            post(ENDPOINTS.contasContabeis, {
                codigo: contaContabil?.Codigo,
                nome: contaContabil?.Nome,
                contaPadrao: contaContabil?.ContaPadrao,
                status: contaContabil?.Status
            })
                .then(_ => {
                    setAlert({
                        type: 'success',
                        time: 5000,
                        title: 'Sucesso!',
                        subTitle: 'Conta contábil incluída!'
                    })
                    showAlert(true)
                    history.push(`/cadastros/contas-contabeis`)
                })
                .catch(err => console.error(err))
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                FormRef.current?.setErrors(erros)
            }
        }
    }
    const [administrador, setAdministrador] = useState(false)

    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    return (
        <div>
            <BlankBoard>
                <Header
                    title="Cadastrar conta contábil"
                    actionArrowClick="/cadastros/contas-contabeis"
                ></Header>
                <Center>
                    <Subtitle>Dados da conta contábil</Subtitle>
                </Center>
                {administrador ? (
                    <>
                        <Form ref={FormRef} onSubmit={handleSubmit}>
                            <div className="col12 col-sm-1">
                                <Input
                                    name="Codigo"
                                    label="Código"
                                    required={true}
                                    value={codigo}
                                    onChange={e => setCodigo(e.target.value)}
                                ></Input>
                            </div>
                            <Grid
                                sizeElements="49%"
                                spaceBetweenElements="1rem"
                            >
                                <Input
                                    name="Nome"
                                    label="Nome da conta contábil"
                                    required={true}
                                    placeholder="Digite um nome"
                                    value={nome}
                                    onChange={e => setNome(e.target.value)}
                                ></Input>
                            </Grid>
                            <LineSeparator></LineSeparator>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="col12 col-sm-11">
                                    <Description>Conta padrão</Description>
                                </div>
                                <div className="col12 col-sm-1">
                                    <Switch
                                        name="contapadrao"
                                        checked={contaPadrao}
                                        onChange={e => {
                                            setContaPadrao(!contaPadrao)
                                        }}
                                    ></Switch>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="col12 col-sm-11">
                                    <Description>Status</Description>
                                </div>
                                <div className="col12 col-sm-1">
                                    <Switch
                                        name="status"
                                        checked={status}
                                        onChange={e => {
                                            setStatus(!status)
                                        }}
                                    ></Switch>
                                </div>
                            </div>
                            <Center>
                                <DisableForMobile>
                                    <Button
                                        icon="plus"
                                        colorType="quaternary"
                                        style={{ marginTop: '100px' }}
                                    >
                                        Cadastrar
                                    </Button>
                                </DisableForMobile>
                                <IsMobile>
                                    <Button
                                        icon="plus"
                                        colorType="btnMobile"
                                        style={{ marginTop: '100px' }}
                                    >
                                        Cadastrar
                                    </Button>
                                </IsMobile>
                            </Center>
                        </Form>
                        <LineSeparator></LineSeparator>
                    </>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                )}
            </BlankBoard>
        </div>
    )
}

export default CadastroContaContabil

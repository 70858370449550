import styled from 'styled-components'

export const Container = styled.div`
    input {
        text-align: left;
        font-size: 0.9375rem;
    }

    label {
        font-size: 0.9375rem;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
`

export const Error = styled.div`
    height: 20px;

    display: flex;
    justify-content: flex-end;

    font-size: 0.875rem;
    span {
        color: #c53030;
    }
`

import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useHistory } from 'react-router'
import { useAuth } from '../../../contexts/Auth'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button'
import { Center } from '../../../core/components/Center'
import { Input } from '../../../core/components/Form/Input'
import { Select } from '../../../core/components/Form/Select'
import { Header } from '../../../core/components/Header/Header'
import { Space } from '../../../core/components/Space'
import { Subtitle } from '../../../core/components/Subtitle'
import { SelectMapper } from '../../../core/utils/SelectMapper'
import { ENDPOINTS } from '../../../services/config-http'
import { get, post } from '../../../services/http'
import { useGlobalStates } from '../../../state/InitialState'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { SOLICITACAO_VIAGEM } from '../../../state/SolicitacaoViagem/Actions'
import { Solicitacao } from '../../../contexts/interfaces/solicitacao'
import { RegraPoliticaViagem } from '../../../contexts/interfaces/regrasPoliticaViagem'
import { StepByStepItem } from '../../../contexts/interfaces/_stepByStep'
import styled from 'styled-components'
import { DisableForMobile } from './stepMobile'

export const InfoForm = () => {
    const {
        setGlobalState,
        state: { solicitacao }
    } = useGlobalStates()
    const [tiposSolicitacao, setTiposSolicitacao] = useState([])
    const [motivos, setMotivos] = useState([])
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()
    const { user } = useAuth()
    const [solicitacaoStorage, setSolicitacaoStorage] = useState<Solicitacao>()
    const [idPoliticaViagem, setIdPoliticaViagem] = useState()

    useEffect(() => {
        const pesquisaOpcoes = async () => {
            if (user?.codigo) {
                await get(
                    `${ENDPOINTS.usuarioByCodigo.replace(
                        ':Codigo',
                        user.codigo
                    )}/`
                ).then(async ({ data: { Model } }) => {
                    const { IdPoliticaViagem } = Model
                    setIdPoliticaViagem(IdPoliticaViagem)
                    await get(
                        `${ENDPOINTS.regrasPoliticaViagem}/BuscarPorIdPolitica/${IdPoliticaViagem}`
                    )
                        .then(({ data: { Model } }) => {
                            setTiposSolicitacao(
                                SelectMapper(Model, 'TipoSolicitacao', 'Id')
                            )
                        })
                        .catch(err => {
                            console.error(err)
                        })
                })
            }
            await get(ENDPOINTS.motivoViagemPesquisar)
                .then(({ data: { Items } }) => {
                    setMotivos(SelectMapper(Items, 'Descricao', 'Id'))
                })
                .catch(err => {
                    console.error(err)
                })

            const sol = localStorage.getItem('solicitacao')
            if (sol !== null) {
                const solicitacaoStorage = JSON.parse(sol) as Solicitacao
                setSolicitacaoStorage(solicitacaoStorage)
                setGlobalState(
                    SOLICITACAO_VIAGEM.REDUCER_NAME,
                    SOLICITACAO_VIAGEM.ACTION_ADD_SOLICITACAO,
                    solicitacaoStorage
                )
                formRef.current?.setData({ ...solicitacaoStorage })
                return null
            }

            // formRef.current?.setData({ ...solicitacao })
        }
        pesquisaOpcoes()
    }, [])

    const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
        try {
            const schema = Yup.object().shape({
                idTipoSolicitacao: Yup.string().required(
                    'O Tipo de Viagem é Obrigatório!'
                ),
                idMotivoViagem: Yup.string().required(
                    'O Motivo da Viagem é Obrigatório!'
                ),
                descricao: Yup.string()
                    .nullable()
                    .required('O Campo Descrição é Obrigatório!')
            })
            await schema.validate(data, { abortEarly: false })

            setGlobalState(
                SOLICITACAO_VIAGEM.REDUCER_NAME,
                SOLICITACAO_VIAGEM.ACTION_ADD_INFO_VIAGEM,
                data
            )

            let idSolicitacao = localStorage.getItem('idSolicitacao')
            const sol = localStorage.getItem('solicitacao')
            const { idTipoSolicitacao, idMotivoViagem, descricao } = data as any
            if (sol !== null) {
                const solicitacaoObj = JSON.parse(sol) as Solicitacao
                solicitacaoObj.idTipoSolicitacao = idTipoSolicitacao
                solicitacaoObj.idMotivoViagem = idMotivoViagem
                solicitacaoObj.descricao = descricao
                solicitacaoObj.idSituacao = 13
                solicitacaoObj.dtCreate = new Date()
                if (idSolicitacao === null || idSolicitacao === undefined) {
                    post(ENDPOINTS.solicitarViagem, solicitacaoObj)
                        .then(({ data: { Model } }) => {
                            idSolicitacao = Model
                            localStorage.setItem(
                                'idSolicitacao',
                                JSON.stringify(idSolicitacao)
                            )
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
                localStorage.setItem(
                    'solicitacao',
                    JSON.stringify(solicitacaoObj)
                )
            } else {
                localStorage.setItem('solicitacao', JSON.stringify(solicitacao))
            }

            let regraPoliticaViagem: any
            await get(
                `${ENDPOINTS.regrasPoliticaViagem}/BuscarPorIdPolitica/${idPoliticaViagem}`
            )
                .then(({ data: { Model } }) => {
                    const regrasPoliticaViagem: RegraPoliticaViagem[] = Model
                    regraPoliticaViagem = regrasPoliticaViagem?.find(
                        s => s.Id === idTipoSolicitacao
                    )
                })
                .catch(err => {
                    console.error(err)
                })
            // get()
            const newStepByStep: StepByStepItem[] = []

            if (regraPoliticaViagem?.UtilizarRoteiro) {
                const stepItem: StepByStepItem = {
                    name: 'Roteiro',
                    active: false,
                    completed: false,
                    icon: 'map-marker-alt',
                    caminho: 'tipo-roteiro/trechos'
                }
                newStepByStep.push(stepItem)
            }
            if (regraPoliticaViagem?.UtilizarAereo) {
                const stepItem: StepByStepItem = {
                    name: 'Aéreo',
                    active: false,
                    completed: false,
                    icon: 'plane',
                    caminho: 'aereo'
                }
                newStepByStep.push(stepItem)
            }
            if (regraPoliticaViagem?.UtilizarHospedagem) {
                const stepItem: StepByStepItem = {
                    name: 'Hospedagem',
                    active: false,
                    completed: false,
                    icon: 'building',
                    caminho: 'hospedagem'
                }
                newStepByStep.push(stepItem)
            }
            if (regraPoliticaViagem?.UtilizarServicos) {
                const stepItem: StepByStepItem = {
                    name: 'Serviços',
                    active: false,
                    completed: false,
                    icon: 'list-ul',
                    caminho: 'servicos'
                }
                newStepByStep.push(stepItem)
            }
            if (regraPoliticaViagem?.UtilizarAdiantamentos) {
                const stepItem: StepByStepItem = {
                    name: 'Adiantamento',
                    active: false,
                    completed: false,
                    icon: 'dollar-sign',
                    caminho: 'adiantamento'
                }
                newStepByStep.push(stepItem)
            }
            const stepItem: StepByStepItem = {
                name: 'Rateio',
                active: false,
                completed: false,
                icon: 'random',
                caminho: 'rateio'
            }
            newStepByStep.push(stepItem)

            localStorage.setItem('stepByStep', JSON.stringify(newStepByStep))

            // post(ENDPOINTS.solicitarViagem)

            history.push(`/solicitar-viagem/${newStepByStep[0].caminho}`)
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                formRef.current?.setErrors(erros)
            }
        }
    }

    return (
        <BlankBoard>
            <Header
                title="Solicitação de viagem"
                actionArrowClick={() =>
                    history.push('/solicitar-viagem/incluir-viajantes')
                }
            ></Header>
            <Space height="3rem"></Space>
            <Center>
                <Subtitle className="text-center">
                    Para dar continuidade à solicitação de viagem, informe o{' '}
                    <b>tipo</b>, o <b>motivo</b>
                    <br /> e uma breve <b>descrição</b> da viagem.
                </Subtitle>
            </Center>
            <Form
                ref={formRef}
                onSubmit={handleSubmit}
                style={{ margin: '3rem', padding: '0' }}
            >
                <CenterMobile>
                    <Box>
                        <InputStyle>
                            <Select
                                name="idTipoSolicitacao"
                                label="Que tipo de viagem?"
                                placeholder="Selecione o tipo"
                                required={true}
                                options={tiposSolicitacao}
                            ></Select>
                        </InputStyle>
                        <InputStyle>
                            <Select
                                name="idMotivoViagem"
                                label="Por qual motivo você vai?"
                                placeholder="Selecione o motivo"
                                required={true}
                                options={motivos}
                            ></Select>
                        </InputStyle>
                        <InputStyle>
                            <Input
                                label="Descrição da viagem"
                                name="descricao"
                                placeholder="Digite a descrição"
                                required={true}
                            ></Input>
                        </InputStyle>
                    </Box>
                </CenterMobile>

                <Center>
                    <DisableForMobile>
                        <Button colorType="quaternary" type="submit">
                            Próximo
                        </Button>
                    </DisableForMobile>
                </Center>
                <Center>
                    <ButtonMobile2>
                        <Button colorType="btnMobile" type="submit">
                            Próximo
                        </Button>
                    </ButtonMobile2>
                </Center>
            </Form>
        </BlankBoard>
    )
}

const Box = styled.div`
    @media (min-width: 375px) and (max-width: 400px) {
        margin: 0 0 0 0;
    }
`

const CenterMobile = styled.div`
    @media (min-width: 360px) and (max-width: 1368px) {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`
const ButtonMobile2 = styled.span`
    display: none;
    @media (max-width: 768px) {
        // margin-left: -1.1rem;
        display: block;
        lign-items: 'center';
        justify-content: center;
        align-items: center;
    }
`
export const InputStyle = styled.div`
    width: 21rem;
    justify-content: center;
    align-items: center;
    .form-control {
        width: 21rem;
    }
    @media (min-width: 1380px) and (max-width: 4500px) {
        margin-left: -2.3rem;
        width: 100%;
        .form-control {
            width: 100%;
        }
    }
    // @media (min-width: 396px) and (max-width: 415px) {
    //     margin-left: -2.3rem;
    //     width: 21rem;
    // }
    // @media (min-width: 380px) and (max-width: 395px) {
    //     .form-control {
    //         margin-left: 0.6rem;
    //     }
    //     .react-select__control.css-1txb49o-control {
    //         width: 21rem;
    //         margin-left: 0.6rem;
    //     }
    // }
`

import React, { useContext, useEffect, useRef, useState } from 'react'
import { ENDPOINTS } from '../../../services/config-http'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { BlankBoard } from './../../../core/components/BlankBoard/index'
import { Header } from '../../../core/components/Header/Header'
import { get, patch, post, put } from '../../../services/http'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Button } from './../../../core/components/Button/index'
import { LineSeparator } from './../../../core/components/LineSeparator/index'
import { Center } from '../../../core/components/Center'
import { Input } from '../../../core/components/Form/Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import * as Yup from 'yup'
import { useAuth } from '../../../contexts/Auth'
import { Space } from '../../../core/components/Space'
import { Empty } from '../../../core/components/Empty'
import { STATUS_SOLICITACAO } from '../../../contexts/enums/StatusSolicitacao'
import { Alert } from '../../../core/components/Alert'
import { ImagePrint } from '../../../core/components/ImagePrint'
import './conferencia.css'
import { Toast } from '../../../core/components/Toast'
import { MoneyFormat } from '../../../core/utils/MoneyFormat'
import { UserContext } from '../../../contexts/UserContext'
import { DisableForMobile, IsMobile } from '../Solicitacao/stepMobile'
import {
    Box,
    Quadro,
    TdMobile,
    ThMobile,
    TrMobile
} from '../../../core/components/Mobile'

export const Conferencia = () => {
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const formUserRef = useRef<FormHandles>(null)
    const [solicitacao, setSolicitacao] = useState<any>()
    const [roteiro, setRoteiro] = useState<any>([])
    const [tipoDespesa, setTipoDespesa] = useState<Array<any>>([])
    const [prestacao, setPrestacao] = useState<any>()
    const { Id } = useParams<any>()
    const { user } = useAuth()
    const [tablePrestacoes, setTablePrestacoes] = useState<Array<any>>([])
    const [selectedOption, setSelectedOption] = useState()
    const [showToast, setShowToast] = useState(false)
    const [habilitarAviso, setHabilitarAviso] = useState(false)
    const [aprovaPrestacao, setAprovaPrestacao] = useState(false)
    const [observacao, setObservacao] = useState('')
    const [administrador, setAdministrador] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAprovaPrestacao(dadosUsuario.AprovaPrestacao === true)
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        get(`${ENDPOINTS.prestarContas}/${Id}`).then(({ data: { Model } }) => {
            if (Model) {
                setPrestacao(Model)

                get(`${ENDPOINTS.usuario}/${Model?.IdUsuario}`).then(
                    ({ data: { Model } }) => {
                        const { Nome } = Model
                        formRef.current?.setFieldValue('IdUsuario', Nome)
                    }
                )

                get(`${ENDPOINTS.prestarContasDespesas}/${Model?.Id}`).then(
                    ({ data: { Model } }) => {
                        Model?.forEach((element: any) => {
                            element.DataDespesa = new Date(
                                element.DataDespesa
                            ).toLocaleDateString()
                        })
                        setTablePrestacoes(Model)
                    }
                )
                const { IdSolicitacaoViagem } = Model
                get(
                    `${ENDPOINTS.solicitacaoViagemPorId}/${IdSolicitacaoViagem}`
                ).then(({ data: { Model } }) => {
                    setSolicitacao(Model)
                    get(
                        UrlMountQuery(
                            {
                                IdSolicitacaoViagem: Model?.Id
                            },
                            ENDPOINTS.roteiroByIdSolicitacao
                        )
                    ).then(({ data }) => {
                        setRoteiro(data)
                    })
                })
            }
        })

        get(ENDPOINTS.tipoDespesaAll).then(({ data: { Items } }) => {
            setTipoDespesa(Items)
        })
    }, [])

    const redirectSolicitacao = () => {
        history.goBack()
    }

    const onChangeAprovacao = async (event: any) => {
        setHabilitarAviso(false)
        setSelectedOption(event.target.value)
    }

    const aprovarPrestacao = async () => {
        const dataForm: any = formRef.current?.getData()
        let idStatus = 0
        selectedOption === 'aprovar' ? (idStatus = 5) : (idStatus = 7)
        if (selectedOption === 'reprovar') {
            try {
                const schema = Yup.object().shape({
                    Observacao: Yup.string().required(
                        'É necessário reportar uma observação com a justificativa da reprovação'
                    )
                })
                await schema.validate(dataForm, {
                    abortEarly: false
                })
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const erros = MapFormErrors(err.inner)
                    formRef.current?.setErrors(erros)
                    return err
                }
            }
        }

        try {
            if (selectedOption !== undefined) {
                await put(ENDPOINTS.prestarContas, {
                    id: prestacao?.Id,
                    idSolicitacaoViagem: prestacao?.IdSolicitacaoViagem,
                    idusuario: prestacao?.IdUsuario,
                    idAprovador: prestacao?.IdAprovador,
                    idStatusPrestacao: idStatus,
                    Observacao: observacao
                })
                if (idStatus === 7) {
                    patch(
                        UrlMountQuery(
                            {
                                Id: solicitacao.Id,
                                IdSituacao: 3
                            },
                            ENDPOINTS.solicitarViagem
                        )
                    )
                } else {
                    get(
                        `${ENDPOINTS.statusPrestacaoIndividual}${solicitacao.Id}`
                    ).then(({ data: { Model } }) => {
                        console.log(Model)
                        const mudarSituacao =
                            Model.filter(
                                (item: any) =>
                                    item.Status === 'Aguardando conferência' ||
                                    item.Status ===
                                        'Aguardando prestação de contas'
                            ).length <= 0
                        if (mudarSituacao) {
                            patch(
                                UrlMountQuery(
                                    {
                                        Id: solicitacao.Id,
                                        IdSituacao: 5
                                    },
                                    ENDPOINTS.solicitarViagem
                                )
                            )
                        }
                    })
                }
                setShowToast(true)
                setToast({
                    message: 'Prestação Aprovada!',
                    type: 'success'
                })
                redirectSolicitacao()
            } else {
                setHabilitarAviso(true)
            }
        } catch (err) {
            setShowToast(true)
            setToast({
                message: 'Erro ao tentar aprovar prestação de contas',
                type: 'error'
            })
        }
    }

    function getCampo(index: number, title: string, valor: any) {
        return (
            <div
                style={{
                    flexDirection: 'column'
                }}
            >
                <ThMobile key={index}>{title}</ThMobile>
                <TdMobile
                    key={index}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {valor}
                </TdMobile>
            </div>
        )
    }

    function getTableDeskTop() {
        return tablePrestacoes.length > 0 ? (
            <div
                className="table-responsive"
                style={{
                    border: 'solid 1px lightgray',
                    borderRadius: '15px',
                    marginTop: '3rem'
                }}
            >
                <table className="table">
                    <thead
                        style={{
                            borderBottom: 'solid 1px lightgray'
                        }}
                    >
                        <tr
                            style={{
                                border: '0 !important'
                            }}
                        >
                            <Th
                                style={{
                                    borderBottomWidth: '0 !important'
                                }}
                                scope="col"
                            >
                                #
                            </Th>
                            <Th
                                style={{
                                    textAlign: 'center'
                                }}
                                scope="col"
                            >
                                Tipo de Despesa
                            </Th>
                            <Th
                                style={{
                                    textAlign: 'center'
                                }}
                                scope="col"
                            >
                                Valor
                            </Th>
                            <Th
                                style={{
                                    textAlign: 'center',
                                    width: '18rem'
                                }}
                                scope="col"
                            >
                                Justificativa
                            </Th>
                            <Th
                                style={{
                                    textAlign: 'center'
                                }}
                                scope="col"
                            >
                                Data da Despesa
                            </Th>
                            <Th
                                style={{
                                    textAlign: 'center'
                                }}
                                scope="col"
                            >
                                Anexo
                            </Th>
                        </tr>
                    </thead>
                    <tbody>
                        {tablePrestacoes.map((object, key) => {
                            return (
                                <>
                                    <tr key={key}>
                                        <td
                                            style={{
                                                verticalAlign: 'middle',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {key + 1}
                                        </td>
                                        <td
                                            style={{
                                                verticalAlign: 'middle',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {
                                                tipoDespesa.filter(
                                                    ({ Id }) =>
                                                        Id ===
                                                        object.IdTipoDespesa
                                                )[0]?.Descricao
                                            }
                                        </td>
                                        <td
                                            style={{
                                                verticalAlign: 'middle',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {MoneyFormat(Number(object.Valor))}
                                        </td>
                                        <td
                                            style={{
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                                width: '18rem'
                                            }}
                                        >
                                            {object.Justificativa}
                                        </td>
                                        <td
                                            style={{
                                                verticalAlign: 'middle',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {object.DataDespesa}
                                        </td>
                                        <td
                                            style={{
                                                verticalAlign: 'middle',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <ButtonEye type="button">
                                                <FontAwesomeIcon
                                                    style={{
                                                        backgroundColor:
                                                            '#002a5d',
                                                        color: '#fff',
                                                        padding: '0.3rem',
                                                        borderRadius: '4px'
                                                    }}
                                                    icon="eye"
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#flush-collapse${key}`}
                                                    aria-expanded="false"
                                                    aria-controls={`flush-collapse${key}`}
                                                />
                                            </ButtonEye>
                                        </td>
                                    </tr>
                                    <div
                                        id={`flush-collapse${key}`}
                                        className="accordion-collapse collapse"
                                        aria-labelledby="flush-headingOne"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <tr
                                            style={{
                                                display: 'flex',
                                                borderBottom:
                                                    'solid 1px lightgray'
                                            }}
                                        >
                                            <Center>
                                                <ImagePrint
                                                    linkImg={`${tablePrestacoes[key].NomeAnexoBlob}`}
                                                    flagShowButtons={true}
                                                ></ImagePrint>
                                            </Center>
                                        </tr>
                                    </div>
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        ) : (
            // </div>
            <div style={{ marginTop: '2rem' }}>
                <Empty text="Nenhuma despesa adicionada" />
            </div>
        )
    }

    function getTipoDespesa(param: any) {
        return tipoDespesa.filter(({ Id }) => Id === param.IdTipoDespesa)[0]
            ?.Descricao
    }

    function getTableMobile() {
        return (
            <IsMobile>
                <Center>
                    <Box>
                        {tablePrestacoes.length > 0 &&
                            tablePrestacoes.map((tableItem, index) => {
                                if (tableItem) {
                                    return (
                                        <Quadro>
                                            <table style={{ width: '100%' }}>
                                                <TrMobile>
                                                    {getCampo(
                                                        index,
                                                        '#',
                                                        index + 1
                                                    )}
                                                    {getCampo(
                                                        index,
                                                        'Tipo de Despesa Valor',
                                                        getTipoDespesa(
                                                            tableItem
                                                        )
                                                    )}
                                                    {getCampo(
                                                        index,
                                                        'Valor',
                                                        MoneyFormat(
                                                            Number(
                                                                tableItem.Valor
                                                            )
                                                        )
                                                    )}
                                                    {getCampo(
                                                        index,
                                                        'Justificativa',
                                                        tableItem.Justificativa
                                                    )}
                                                    {getCampo(
                                                        index,
                                                        'Data da Despesa',
                                                        tableItem.DataDespesa
                                                    )}
                                                    {getCampo(
                                                        index,
                                                        'Anexo',
                                                        <ButtonEye type="button">
                                                            <FontAwesomeIcon
                                                                style={{
                                                                    backgroundColor:
                                                                        '#002a5d',
                                                                    color: '#fff',
                                                                    padding:
                                                                        '0.3rem',
                                                                    borderRadius:
                                                                        '4px'
                                                                }}
                                                                icon="eye"
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#flush-collapse${index}`}
                                                                aria-expanded="false"
                                                                aria-controls={`flush-collapse${index}`}
                                                            />
                                                        </ButtonEye>
                                                    )}
                                                    <Center>
                                                        <div
                                                            id={`flush-collapse${index}`}
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby="flush-headingOne"
                                                            data-bs-parent="#accordionFlushExample"
                                                        >
                                                            <tr
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    borderBottom:
                                                                        'solid 1px lightgray'
                                                                }}
                                                            >
                                                                <Center>
                                                                    <ImagePrint
                                                                        linkImg={`${tablePrestacoes[index].NomeAnexoBlob}`}
                                                                        flagShowButtons={
                                                                            true
                                                                        }
                                                                    ></ImagePrint>
                                                                </Center>
                                                            </tr>
                                                        </div>
                                                    </Center>
                                                </TrMobile>
                                            </table>
                                        </Quadro>
                                    )
                                }
                                return null
                            })}
                    </Box>
                </Center>
            </IsMobile>
        )
    }

    return (
        <BlankBoard>
            <Header
                title="Conferência"
                actionArrowClick={() => history.goBack()}
            ></Header>

            <Center>
                <h2 className="text-center">
                    Conferir e aprovar as despesas da prestação de contas.
                </h2>
            </Center>

            <Space height="1.5rem"></Space>
            {administrador || aprovaPrestacao ? (
                <>
                    <Form
                        ref={formRef}
                        onSubmit={() => {
                            // aprovarPrestacao()
                        }}
                    >
                        <h2>Informações</h2>
                        <div className="row">
                            <div className="col-12 col-sm-8">
                                <Info>
                                    <div className="f-size-08rem">
                                        <FontAwesomeIcon icon="bars" />
                                        &ensp;
                                        {`  ${roteiro[0]?.Objetivo}` ||
                                            'Carregando...'}
                                    </div>
                                    {roteiro?.map(
                                        (roteiroUnico: any, key: number) => (
                                            <div
                                                key={key}
                                                className="f-size-08rem"
                                            >
                                                <FontAwesomeIcon icon="map-marker-alt" />
                                                &ensp;
                                                {`  ${roteiroUnico?.Destino}` ||
                                                    'Carregando...'}
                                            </div>
                                        )
                                    )}
                                    <div className="f-size-08rem">
                                        <FontAwesomeIcon icon="calendar-alt" />
                                        &ensp;
                                        {`   ${
                                            new Date(
                                                roteiro[0]?.DataInicio
                                            ).toLocaleDateString() ||
                                            'Carregando...'
                                        }  -  ${new Date(
                                            roteiro[
                                                roteiro.length - 1
                                            ]?.DataTermino
                                        ).toLocaleDateString()}` ||
                                            'Carregando...'}
                                    </div>
                                </Info>
                            </div>
                            <div className="col-12 col-sm-4">
                                <Input
                                    name="IdUsuario"
                                    label="Viajante"
                                    disabled={true}
                                ></Input>
                                <div
                                    style={{
                                        display: 'flex'
                                    }}
                                >
                                    {prestacao !== undefined ? (
                                        <span>Status do Viajante:</span>
                                    ) : null}
                                    {prestacao?.Status ===
                                    STATUS_SOLICITACAO.AGUARDANDO_CONFERENCIA ? (
                                        <span
                                            className="badge status-pendding"
                                            style={{
                                                margin: 'auto',
                                                color: '#5D5D5D'
                                            }}
                                        >
                                            <FontAwesomeIcon icon="circle" />
                                            &ensp;
                                            {prestacao.Status}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <LineSeparator
                            style={{ margin: '2rem 0' }}
                        ></LineSeparator>

                        <h2>Despesas</h2>
                        <DisableForMobile>{getTableDeskTop()}</DisableForMobile>
                        <IsMobile>{getTableMobile()}</IsMobile>
                        <LineSeparator
                            style={{ margin: '2rem 0' }}
                        ></LineSeparator>

                        <h2>Aprovação da prestação de contas</h2>
                        <span
                            style={{
                                color:
                                    habilitarAviso &&
                                    selectedOption === undefined
                                        ? '#ff0000'
                                        : ''
                            }}
                        >
                            Selecione uma opção para aprovar ou reprovar a
                            prestação de contas do viajante
                        </span>
                        <Space height="3rem"></Space>
                        <label style={{ marginRight: '2.5rem' }}>
                            <input
                                type="radio"
                                value="aprovar"
                                name="Aprovacao"
                                onChange={onChangeAprovacao}
                                style={{ marginRight: '.5rem' }}
                            />
                            <span>Aprovar</span>
                        </label>
                        <label>
                            <input
                                style={{ marginRight: '.5rem' }}
                                type="radio"
                                value="reprovar"
                                name="Aprovacao"
                                onChange={onChangeAprovacao}
                            />
                            <span>Reprovar</span>
                        </label>
                        <div
                            className={
                                selectedOption === 'reprovar'
                                    ? ''
                                    : 'invisible-item'
                            }
                        >
                            <Input
                                type="textarea"
                                name="Observacao"
                                label="Observação"
                                onChange={e => setObservacao(e.target.value)}
                                required={true}
                            ></Input>
                        </div>

                        <Space height="3rem"></Space>
                    </Form>
                    <>
                        <Center>
                            <DisableForMobile>
                                <Button
                                    onClick={() => aprovarPrestacao()}
                                    colorType="primary"
                                    className={
                                        tablePrestacoes.length
                                            ? ''
                                            : 'invisible-item'
                                    }
                                >
                                    Finalizar Conferência
                                </Button>
                            </DisableForMobile>
                        </Center>
                        <Center>
                            <IsMobile>
                                <Button
                                    className={
                                        tablePrestacoes.length
                                            ? ''
                                            : 'invisible-item'
                                    }
                                    onClick={() => aprovarPrestacao()}
                                    colorType="btnMobile"
                                    type="submit"
                                >
                                    Finalizar Conferência
                                </Button>
                            </IsMobile>
                        </Center>
                    </>
                </>
            ) : (
                <Alert
                    fixed={true}
                    icon="exclamation-circle"
                    type="primary"
                    message="Você não tem permissão para cadastrar conferencia "
                ></Alert>
            )}
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </BlankBoard>
    )
}

const Info = styled.div`
    font-size: 0.9rem;
`

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
`

// const Radio = styled.input`
//     background-color: ${props => };
// `

const ButtonEye = styled.button`
    background-color: #002a5d;
    color: #fff;
    border: 0;
    padding: 0.2rem 0.4rem !important;
    border-radius: 4px;
`

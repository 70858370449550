import styled from 'styled-components'
import React from 'react'
import { Center } from '../Center'
import GolImg from '../../../core/assets/Gol.jpg'
import AzulImg from '../../../core/assets/Azul.png'
import LatamImg from '../../../core/assets/Latam.png'
import PlaneGo from '../../../core/assets/plane-1.svg'
import PlaneBack from '../../../core/assets/plane-2.svg'
import { MoneyFormat } from '../../utils/MoneyFormat'

export function getCampo(index: number, title: string, valor: any) {
    return (
        <div
            style={{
                flexDirection: 'column'
            }}
        >
            <ThMobile key={index}>{title}</ThMobile>
            <TdMobile
                key={index}
                style={{
                    textAlign: 'center'
                }}
            >
                {valor}
            </TdMobile>
        </div>
    )
}
export function getNotFound(param: string) {
    return (
        <Quadro>
            <Center>
                <Aviso>{param}</Aviso>
            </Center>
        </Quadro>
    )
}

export const getReservaMobile = (reservas: any) => {
    return reservas?.map((reserva: any, index: number) => {
        return (
            <Center key={index}>
                <Quadro>
                    {getReservasMobile(
                        reserva.Companhia,
                        reserva.AeroportoOrigem,
                        reserva.AeroportoDestino,
                        '',
                        '',
                        true,
                        reserva.DataIdaSaida
                    )}
                    {reserva.IdaVolta &&
                        getReservasMobile(
                            reserva.Companhia,
                            reserva.AeroportoOrigem,
                            reserva.AeroportoDestino,
                            '',
                            '',
                            false,
                            reserva.DataVoltaSaida
                        )}
                    {!reserva.IdaVolta &&
                        getReservasMobile(
                            reserva.Companhia,
                            reserva.AeroportoOrigem,
                            reserva.AeroportoDestino,
                            '',
                            '',
                            true,
                            reserva.DataVoltaSaida
                        )}
                    <Container>
                        <Esquerda>
                            <Title>Valor</Title>
                            <Title>
                                {MoneyFormat(parseInt(reserva.Valor))}
                            </Title>
                        </Esquerda>
                        <Meio></Meio>

                        <Direita>
                            <Title>Escalas</Title>
                            <Title>{reserva.QtdEscalas}</Title>
                        </Direita>
                    </Container>
                </Quadro>
            </Center>
        )
    })
}
const getReservasMobile = (
    logoIata: string,
    CodigoIata1: string,
    CodigoIata2: string,
    Descricao1: string,
    Descricao2: string,
    IdaVolta: boolean,
    dataViagem?: Date
) => {
    return (
        <>
            <Title>{renderLogoCia(logoIata || '')}</Title>
            <Data>
                {dataViagem &&
                    new Date(dataViagem)?.toLocaleDateString('pt-BR', {
                        hour: '2-digit',
                        minute: '2-digit'
                    })}
            </Data>
            <Evento>
                <Detail>{IdaVolta ? 'IDA' : 'VOLTA'}</Detail>
            </Evento>
            <Container>
                <Esquerda>
                    <Iata>{CodigoIata1}</Iata>
                    <Center>
                        <Text>{Descricao1}</Text>
                    </Center>
                </Esquerda>
                <Meio>
                    <img src={IdaVolta ? PlaneGo : PlaneBack} alt="aviao" />
                </Meio>

                <Direita>
                    <Iata>{CodigoIata2}</Iata>
                    <Center>
                        <Text>{Descricao2}</Text>
                    </Center>
                </Direita>
            </Container>
        </>
    )
}

const renderLogoCia = (cia: string) => {
    switch (cia) {
        case 'G3':
            return (
                <ContainerLogo>
                    <img src={GolImg} alt="Gol"></img>
                </ContainerLogo>
            )
        case 'AD':
            return (
                <ContainerLogo>
                    <img src={AzulImg} alt="Azul"></img>
                </ContainerLogo>
            )
        case 'JJ':
            return (
                <ContainerLogo>
                    <img src={LatamImg} alt="Latam"></img>
                </ContainerLogo>
            )

        default:
            break
    }
}

export const Box = styled.div`
    border: solid 1px lightgray;
    border-radius: 15px;
    @media (max-width: 768px) {
        border: solid 0px lightgray;
        border-radius: 0px;
    }
`

export const BoxNotFound = styled.div`
    margin: 4.8rem 7vh;
    border: solid 1px lightgray;
    border-radius: 15px;
    @media (max-width: 768px) {
        border: solid 0px lightgray;
        border-radius: 0px;
    }
`

export const TrMobile = styled.tr`
    border: 0 !important;
    z-index: 10;
`
export const ThMobile = styled.th`
    position: relative;
    width: auto !important;
    height: auto;
    color: #002a5d;
`
export const TdMobile = styled.td`
    padding: 0.5rem 0rem !important;
    color: ${props => props.theme.colors.text};
    padding-left: 5px !important;
    color: #002a5d;
    .form-select {
        background-color: #f1f3f5;
    }
    .button.dropdown-item {
        background: #f1f3f5;
    }
`

export const Quadro = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0;
    gap: 8px;

    width: 342px;
    height: 0 auto;
    min-height: 218px;
    background: #f1f3f5;
    border: 1px solid #dee2e6;

    box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 1rem 0;
`
export const Aviso = styled.p`
    height: auto;
    margin-top: 4rem;
    font-size: 1rem;
    border-radius: 1rem;
`

export const Info = styled.p`
    height: auto;
    margin-top: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
`
export const Separador = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const Lista = styled.ul`
    @media (max-width: 3000px) and (min-width: 769px) {
        margin: 0vh 5vh;
    }
    @media (max-width: 768px) {
        .nav-link {
            display: block;
            padding: 0.5rem 0.7rem;
            color: #0d6efd;
            text-decoration: none;
            margin: 0 0;
        }
    }
    .info {
        background: #000;
    }
`

export const Container = styled.span`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`

export const Esquerda = styled.span`
    width: 8rem;
    height: 0 auto;
    // background: #e1e1e2;
    margin: 1px;
`
export const Meio = styled.span`
    width: 5rem;
    height: 0 auto;
    margin: 1px;
    display: flex;
    justify-content: center;
`
export const Direita = styled.span`
    width: 8rem;
    height: 0 auto;
    // background: #e1e1e4;
    margin: 1px;
`
export const Title = styled.span`
    display: flex;
    width: 100%;
    height: 0 auto;
    // background: #fff;
    justify-content: center;
    margin: 0;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;

    color: #24538b;
`
export const Data = styled.span`
    display: flex;
    width: 100%;
    height: 0 auto;
    justify-content: center;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;

    color: #24538b;
`

export const Evento = styled.span`
    display: flex;
    width: 100%;
    height: 0 auto;
    justify-content: center;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;

    color: #24538b;
`

export const Detail = styled.span`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 16px;
    gap: 8px;
    width: 0 auto;
    height: 18px;
    color: #fff;
    background: #10478a;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
`

export const TimeEsquerda = styled.span`
    width: 8rem;
    height: 18px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    /* identical to box height, or 180% */

    display: flex;
    align-items: flex-end;
    letter-spacing: 0.04em;
    justify-content: flex-end;
    // flex-directon: row-reverse;
    margin: 0 10px 0 -10px;

    color: #24538b;
    order: 1;
`

export const TimeDireita = styled.span`
    width: 8rem;
    height: 18px;

    /* Text Semibold/Caption */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    /* identical to box height, or 180% */

    display: flex;
    align-items: flex-end;
    letter-spacing: 0.04em;
    margin: 0 10px 0 0.2rem;

    color: #24538b;
    order: 1;
`
export const Iata = styled.text`
    width: 100%;
    height: 33.44px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    color: #6c7480;
    justify-content: center;

    flex: none;
    order: 0;
    flex-grow: 0;
`
export const Text = styled.text`
    width: 110px;
    height: 18px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;

    letter-spacing: 0.04em;

    color: #6c7480;

    flex: none;
    order: 0;
    flex-grow: 0;
`
const ContainerLogo = styled.div`
    width: 80px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: end;
    align-items: left;
    margin: 0 auto;

    img {
        width: 100%;
    }
`

import React, { useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import { Select } from '../../../../core/components/Form/Select'
import { SelectMapper } from '../../../../core/utils/SelectMapper'
import { ENDPOINTS } from '../../../../services/config-http'
import { get, post, patch } from '../../../../services/http'
import { FormHandles, SubmitHandler } from '@unform/core'
import Switch from 'react-switch'
import { RegraPoliticaViagem } from '../../../../contexts/interfaces/regrasPoliticaViagem'
import { Button } from '../../../../core/components/Button'
import { LineSeparator } from '../../../../core/components/LineSeparator'
import styled from 'styled-components'

interface RegraPoliticaTipoSolicitacao {
    Id: number
    Nome: string
    Incluido: boolean
    IdRegraPolitica: number
}

export const TipoSolicitacao = ({
    nomePolitica,
    idPolitica
}: {
    nomePolitica: string
    idPolitica: number
}) => {
    const [tiposSolicitacao, setTiposSolicitacao] = useState<
        RegraPoliticaTipoSolicitacao[]
    >([])
    const [newTiposSolicitacao, setNewTiposSolicitacao] = useState<
        RegraPoliticaTipoSolicitacao[]
    >([])

    useEffect(() => {
        const politicasTipoSolicitacao = async () => {
            await get(
                `${ENDPOINTS.regrasPoliticaViagem}/TiposSolicitacao/${idPolitica}`
            )
                .then(({ data: { Model } }) => {
                    setTiposSolicitacao(Model)
                })
                .catch(err => {
                    console.error(err)
                })
        }
        politicasTipoSolicitacao()
    }, [])

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: '1.5rem', marginTop: '2rem' }}>
                    {nomePolitica}
                </div>
            </div>
            <div className="container-permissoes">
                <div className="row linha-permissao">
                    {tiposSolicitacao.map(
                        (
                            tipoSolicitacao: RegraPoliticaTipoSolicitacao,
                            key: number
                        ) => {
                            // console.log(tipoSolicitacao.Incluido)
                            return (
                                <div
                                    key={key}
                                    className="col-7"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        margin: '1rem 0 1rem'
                                    }}
                                >
                                    <Switch
                                        onColor="#10478A"
                                        offColor="#E9ECEF"
                                        onHandleColor="#E9ECEF"
                                        offHandleColor="#ADB5BD"
                                        handleDiameter={15}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        width={48}
                                        height={21}
                                        checked={tipoSolicitacao.Incluido}
                                        onChange={() => {
                                            const newTipo = [
                                                ...tiposSolicitacao
                                            ]
                                            newTipo[key].Incluido =
                                                !newTipo[key].Incluido
                                            setTiposSolicitacao([...newTipo])

                                            const validacao =
                                                newTiposSolicitacao?.findIndex(
                                                    s =>
                                                        s.Id ===
                                                        tipoSolicitacao.Id
                                                )
                                            if (validacao !== -1) {
                                                newTiposSolicitacao?.splice(
                                                    validacao,
                                                    1
                                                )
                                            } else {
                                                setNewTiposSolicitacao([
                                                    ...newTiposSolicitacao,
                                                    newTipo[key]
                                                ])
                                            }
                                        }}
                                    ></Switch>
                                    <Text>{tipoSolicitacao.Nome}</Text>
                                </div>
                            )
                        }
                    )}
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-5"></div>
                <div
                    className="col-4"
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    <Button
                        icon="save"
                        colorType="quaternary"
                        onClick={() => {
                            // console.log(newTiposSolicitacao)
                            newTiposSolicitacao.map(
                                (
                                    tipoSolicitacao: RegraPoliticaTipoSolicitacao,
                                    key: number
                                ) => {
                                    if (tipoSolicitacao.Incluido === true) {
                                        console.log('entrou')
                                        post(ENDPOINTS.regrasPoliticaViagem, {
                                            idPoliticaViagem: idPolitica,
                                            idTipoSolicitacao:
                                                tipoSolicitacao.Id,
                                            solicitacaoSemDespesasReembolso:
                                                false,
                                            bloquearAdiantamentoComDoisAdiantamentosPendentes:
                                                false,
                                            bloquearAutorizacaoRetroativa:
                                                false,
                                            naoConsiderarColaboradorNasAprovacoes:
                                                false,
                                            utilizarAdiantamentos: false,
                                            utilizarAereo: false,
                                            utilizarHospedagem: false,
                                            utilizarRoteiro: false,
                                            utilizarServicos: false,
                                            validarMenorPrecoAereo: false,
                                            validarPrazoAntecedenciaParaRequisacaoDeAereo:
                                                false,
                                            validarPrazoAntecedenciaParaRequisacaoDeSolicitacao:
                                                false,
                                            validarSobreposicaoPeriodoNoRoteiro:
                                                false
                                        })
                                            .then(data => {
                                                console.log(data)
                                            })
                                            .catch(err => {
                                                console.log(err)
                                            })
                                    } else {
                                        patch(
                                            `${ENDPOINTS.inativarRegra}/${tipoSolicitacao.IdRegraPolitica}`
                                        )
                                    }
                                }
                            )
                            newTiposSolicitacao.splice(
                                0,
                                newTiposSolicitacao.length
                            )
                            console.log(newTiposSolicitacao)
                        }}
                    >
                        Salvar
                    </Button>
                </div>
                <div className="col-3"></div>
            </div>
        </>
    )
}

const Text = styled.p`
    margin-left: 0.5rem;
    color: #06347a;
    font-size: 0.9rem;
    font-weight: 500;
`

import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'

import { useAuth } from '../../../contexts/Auth'
import { Button } from '../../../core/components/Button'
import { Center } from '../../../core/components/Center'
import { Input } from '../../../core/components/Form/Input'
import { AutoCompleteSelect } from '../../../core/components/Form/v3/AutoCompleteSelect'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Subtitle } from '../../../core/components/Subtitle'
import { ENDPOINTS } from '../../../services/config-http'
import { useGlobalStates } from '../../../state/InitialState'
import { SOLICITACAO_VIAGEM } from '../../../state/SolicitacaoViagem/Actions'
import { BlankBoard } from './../../../core/components/BlankBoard'
import { Header } from './../../../core/components/Header/Header'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import styled from 'styled-components'
import { Space } from '../../../core/components/Space'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { get } from '../../../services/http'
import { Viajante } from '../../../contexts/interfaces/viajante'
import { Solicitacao } from '../../../contexts/interfaces/solicitacao'
import { Empty } from '../../../core/components/Empty'
import { ContainerPadding } from '../../../core/components/ContainerPadding'
import { Alert } from '../../../core/components/Alert'
import './solicitacao.css'
import { Deletar } from '../../../core/components/Deletar'
import { Toast } from '../../../core/components/Toast'
import useWindowDimensions from '../../../core/components/GetDimensions'
import { DisableForMobile, InputStyle, IsMobile } from './stepMobile'

export const Viajantes = () => {
    const {
        state: { solicitacao },
        setGlobalState
    } = useGlobalStates()
    const history = useHistory()
    const { user } = useAuth()
    const formRef = useRef<FormHandles>(null)
    const [viajantes, setViajantes] = useState<Array<Viajante>>([])
    const [viajantePrincipal, setViajantePrincipal] = useState<any>()
    const [flagErrorForm, setFlagErrorForm] = useState<boolean>(false)
    const [flagErrorSolicitante, setFlagErrorSolicitante] =
        useState<boolean>(false)
    const [messageError, setMessageError] = useState<string>('')
    const [messageErrorSolicitante, setMessageErrorSolicitante] =
        useState<string>('')
    const [toastShow, setToastShow] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const { height, width } = useWindowDimensions()
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    useEffect(() => {
        if (solicitacao?.viajantes && solicitacao.viajantes.length) {
            setViajantes(solicitacao.viajantes)
        } else if (solicitacao === undefined) {
            const sol = localStorage.getItem('solicitacao')
            if (sol !== null) {
                const solicitacaoStorage = JSON.parse(sol) as Solicitacao
                setGlobalState(
                    SOLICITACAO_VIAGEM.REDUCER_NAME,
                    SOLICITACAO_VIAGEM.ACTION_ADD_SOLICITACAO,
                    solicitacaoStorage
                )
                setViajantes(solicitacaoStorage.viajantes ?? [])
            }
        }

        formRef.current?.setFieldValue(
            'principal',
            `${user.codigo} - ${user.nome}`
        )
        setPrincipal(formRef.current?.getFieldValue('principal'))
    }, [])

    const setPrincipal = (principal: any) => {
        let codigoUsuarioSolicitante

        if (typeof principal === 'string') {
            codigoUsuarioSolicitante = +principal.split('-', 1)[0].trim()
        } else if (typeof principal === 'object') {
            codigoUsuarioSolicitante = +principal.Codigo
        }

        get(
            `${ENDPOINTS.usuarioByCodigo.replace(':Codigo', user.codigo)}`
        ).then(({ data: { Model } }) => {
            setViajantePrincipal(Model)

            setGlobalState(
                SOLICITACAO_VIAGEM.REDUCER_NAME,
                SOLICITACAO_VIAGEM.ACTION_ADD_VIAJANTE_PRINCIPAL,
                Model.Id
            )
        })
    }

    const handleFormSubmit: SubmitHandler = async (
        { user, principal },
        { reset }
    ) => {
        setPrincipal(principal)
        setFlagErrorForm(false)
        const newViajantes = [...viajantes]
        const hasViajante = newViajantes.some(i => i.Codigo === user.Codigo)
        if (hasViajante) {
            setFlagErrorForm(true)
            setShowToast(true)
            setToast({
                message: 'Ops, viajante já adicionado...',
                type: 'error'
            })
        } else {
            try {
                const schema = Yup.object().shape({
                    user: Yup.object()
                        .nullable()
                        .required('Escolha um usuário para adicionar')
                })
                await schema.validate({ user: user }, { abortEarly: false })
                formRef.current?.setErrors({})

                newViajantes.push({
                    Codigo: user.Codigo,
                    Nome: user.Nome,
                    Status: true,
                    IdUsuario: user.Id,
                    IdPoliticaViagem: user.TipoUsuario,
                    IdSolicitacao: 0,
                    Convidado:
                        user.Codigo === ''
                            ? true
                            : viajantes.length > 0 &&
                              viajantes.filter((viajante: Viajante) => {
                                  return viajante.Convidado === false
                              }).length > 0
                })
                setViajantes([...newViajantes])
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const erros = MapFormErrors(err.inner)
                    formRef.current?.setErrors(erros)
                }
            }
        }
    }

    const nextStep = async () => {
        const principal = formRef.current?.getFieldValue('principal')
        setPrincipal(principal)
        setGlobalState(
            SOLICITACAO_VIAGEM.REDUCER_NAME,
            SOLICITACAO_VIAGEM.ACTION_ADD_VIAJANTES,
            viajantes
        )
        const sol = localStorage.getItem('solicitacao')
        if (sol !== null) {
            const solicitacaoObj = JSON.parse(sol) as Solicitacao
            solicitacaoObj.viajantes = viajantes
            localStorage.setItem('solicitacao', JSON.stringify(solicitacaoObj))
        } else {
            localStorage.setItem('solicitacao', JSON.stringify(solicitacao))
        }
        history.push('/solicitar-viagem/info')
    }

    const handleIncluirViajante = () => {
        get(
            UrlMountQuery(
                {
                    Codigo: viajantePrincipal.Codigo
                },
                ENDPOINTS.pesquisarUsuarios
            )
        ).then(({ data: { Items } }) => {
            const user = Items[0]
            const newViajantes = [...viajantes]
            const hasViajante = newViajantes.some(i => i.Codigo === user.Codigo)
            if (hasViajante) {
                setFlagErrorSolicitante(true)
                setMessageErrorSolicitante(
                    'Ops! Viajante já adicionado a lista'
                )
            } else {
                newViajantes.push({
                    Codigo: user.Codigo,
                    Nome: user.Nome,
                    Status: true,
                    IdUsuario: user.Id,
                    IdPoliticaViagem: user.TipoUsuario,
                    IdSolicitacao: 0,
                    Convidado:
                        viajantes.length > 0 &&
                        viajantes.filter((viajante: Viajante) => {
                            return viajante.Convidado === false
                        }).length > 0
                })
            }
            setViajantes(newViajantes)
        })
    }

    return (
        <BlankBoard>
            <Header
                title="Solicitação de viagem"
                actionArrowClick={() => history.push('/solicitacoes')}
            ></Header>
            <ContainerPadding>
                <Subtitle>Adicione os viajantes</Subtitle>
                <p>
                    Adicione o viajante principal (responsável pela viagem) e os
                    demais acompanhantes.{' '}
                </p>
                <Space></Space>
                <Alert
                    fixed={true}
                    icon="exclamation-circle"
                    type="primary"
                    message="As políticas e regras da viagem podem mudar de acordo com o viajante principal (responsável pela viagem)"
                ></Alert>
                <Form
                    ref={formRef}
                    onSubmit={handleFormSubmit}
                    style={{
                        margin: '1rem 0',
                        padding: '0'
                    }}
                >
                    <div className="row">
                        <InputStyle>
                            <div className="col-12 col-sm-6">
                                <Input
                                    name="principal"
                                    label="Usuário Solicitante"
                                    required={true}
                                    disabled={true}
                                ></Input>
                            </div>
                        </InputStyle>

                        <div
                            className="col-2"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '0'
                            }}
                        >
                            <TitleDesktop>
                                <Button
                                    icon="user-plus"
                                    title="Salvar"
                                    type="button"
                                    onClick={handleIncluirViajante}
                                    colorType="quintiary"
                                >
                                    Incluir como viajante
                                </Button>
                            </TitleDesktop>
                        </div>
                        <Center>
                            <ButtonMobile2>
                                <Button
                                    icon="user-plus"
                                    title="Salvar"
                                    type="button"
                                    onClick={handleIncluirViajante}
                                    colorType="btnMobile"
                                >
                                    Incluir como viajante
                                </Button>
                            </ButtonMobile2>
                        </Center>
                        <div className="col-6">
                            {flagErrorSolicitante ? (
                                <p
                                    className="danger"
                                    style={{ marginTop: '15px' }}
                                >
                                    {messageErrorSolicitante}
                                </p>
                            ) : null}
                        </div>
                        <LineSeparator></LineSeparator>
                        <InputStyle>
                            <div className="col-12 col-sm-6">
                                <AutoCompleteSelect
                                    name="user"
                                    label="Viajante"
                                    placeholder="Digite nome ou matrícula"
                                    required={true}
                                    request={{
                                        endpoint: ENDPOINTS.usuarioPesquisar,
                                        firstLabelToSelect: 'Codigo',
                                        secoundLabelToSelect: 'Nome',
                                        valuesToSelect: [
                                            'Codigo',
                                            'Id',
                                            'Nome',
                                            'TipoUsuario'
                                        ],
                                        queryTextFilter: 'Nome',
                                        queryNumberFilter: 'Codigo'
                                    }}
                                ></AutoCompleteSelect>
                            </div>
                        </InputStyle>
                        <div
                            className="col-12 col-sm-2 d-flex align-items-center"
                            style={{ position: 'relative' }}
                        >
                            <DisableForMobile>
                                <ContentButton>
                                    <Button
                                        colorType="quintiary"
                                        icon="user-plus"
                                    >
                                        Adicionar
                                    </Button>
                                </ContentButton>
                            </DisableForMobile>
                            <Center>
                                <IsMobile>
                                    <Button
                                        colorType="btnMobile"
                                        type="submit"
                                        icon="user-plus"
                                    >
                                        Adicionar
                                    </Button>
                                </IsMobile>
                            </Center>
                        </div>
                    </div>
                    <div className="col-6">
                        {flagErrorForm ? (
                            <p className="danger" style={{ marginTop: '15px' }}>
                                {messageError}
                            </p>
                        ) : null}
                    </div>
                    {viajantes.length > 0 ? (
                        <div className="row" style={{ marginTop: '3rem' }}>
                            <div className="col-12">
                                <label htmlFor="">
                                    <p>Lista de Viajantes</p>
                                </label>
                                <Container>
                                    {
                                        <DisableForMobile>
                                            {getTabela()}
                                        </DisableForMobile>
                                    }
                                    <IsMobile>{getTabelaMobile()}</IsMobile>
                                </Container>
                            </div>
                        </div>
                    ) : (
                        <div style={{ marginTop: '2rem' }}>
                            <Empty text="Nenhum viajante selecionado" />
                        </div>
                    )}
                </Form>
                <Center>
                    {viajantes.length > 0 &&
                    viajantes.filter((viajante: Viajante) => {
                        return viajante.Convidado === false
                    }).length > 0 ? (
                        <>
                            <Center>
                                <DisableForMobile>
                                    <Button
                                        colorType="quaternary"
                                        type="submit"
                                        onClick={() => nextStep()}
                                    >
                                        Próximo
                                    </Button>
                                </DisableForMobile>
                            </Center>
                            <Center>
                                <ButtonMobile2>
                                    <Button
                                        colorType="btnMobile"
                                        type="submit"
                                        onClick={() => nextStep()}
                                    >
                                        Próximo
                                    </Button>
                                </ButtonMobile2>
                            </Center>
                        </>
                    ) : null}
                </Center>
                {showToast ? (
                    <Toast
                        setShow={setShowToast}
                        message={toast.message}
                        type={toast.type}
                    />
                ) : null}
            </ContainerPadding>
        </BlankBoard>
    )
    function getTabela() {
        return (
            <table className="table">
                <thead
                    style={{
                        borderBottom: 'solid 1px lightgray'
                    }}
                >
                    <tr
                        style={{
                            border: '0 !important'
                        }}
                    >
                        <Th
                            style={{
                                borderBottomWidth: '0 !important'
                            }}
                            scope="col"
                            colSpan={2}
                        >
                            <p> #</p>
                        </Th>
                        <Th scope="col" colSpan={2}>
                            <p>Nome</p>
                        </Th>
                        <Th scope="col">
                            <p>Viajante Principal</p>
                        </Th>
                        <Th scope="col">
                            <p>Remover</p>
                        </Th>
                    </tr>
                </thead>
                <tbody>
                    {viajantes.map((object, key) => {
                        return (
                            <tr key={key}>
                                <td
                                    style={{
                                        verticalAlign: 'middle'
                                    }}
                                >
                                    <p>{object.Codigo}</p>
                                </td>
                                <td
                                    colSpan={2}
                                    style={{
                                        verticalAlign: 'middle'
                                    }}
                                >
                                    <p>{object.Nome}</p>
                                </td>
                                <td
                                    style={{
                                        verticalAlign: 'middle'
                                    }}
                                >
                                    <p>{getViajante(key, object)}</p>
                                </td>
                                <td
                                    style={{
                                        verticalAlign: 'middle'
                                    }}
                                >
                                    <p>{btnRemover(key)}</p>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }
    function getTabelaMobile() {
        return (
            <Box>
                {viajantes.map((tableItem, index) => {
                    if (tableItem) {
                        return (
                            <Center>
                                <Quadro>
                                    <table style={{ width: '100%' }}>
                                        <TrMobile>
                                            {getCampo(
                                                index,
                                                '#',
                                                tableItem.Codigo
                                            )}
                                            {getCampo(
                                                index,
                                                'Nome',
                                                tableItem.Nome
                                            )}
                                            {getCampo(
                                                index,
                                                'Viajante Principal',
                                                getViajante(index, tableItem)
                                            )}
                                            {getCampo(
                                                index,
                                                'Remover',
                                                btnRemover(index)
                                            )}
                                        </TrMobile>
                                    </table>
                                </Quadro>
                            </Center>
                        )
                    }
                    return null
                })}
            </Box>
        )
    }
    function getViajante(key: number, object: any) {
        return !object.Convidado ? (
            <ViajantePrincipalDiv disabled={true}>
                Viajante Principal
            </ViajantePrincipalDiv>
        ) : object.Codigo !== '' ? (
            <TornarPrincipalBtn
                type="button"
                onClick={() => {
                    const newViajantes = [...viajantes]
                    newViajantes.forEach((object, index) => {
                        if (index === key) {
                            if (object.Codigo === '') {
                                setMessageError(
                                    'Apenas colaboradores podem ser o viajante principal'
                                )
                                setToastShow(true)
                                return
                            }
                            object.Convidado = false
                        } else {
                            object.Convidado = true
                        }
                    })
                    setViajantes(newViajantes)
                }}
            >
                Tornar Principal
            </TornarPrincipalBtn>
        ) : null
    }
    function getCampo(index: number, title: string, valor: any) {
        return (
            <div
                style={{
                    flexDirection: 'column'
                }}
            >
                <ThMobile key={index}>{title}</ThMobile>
                <TdMobile
                    key={index}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {valor}
                </TdMobile>
            </div>
        )
    }
    function btnRemover(index: number) {
        return (
            <Deletar
                remover={() => {
                    const newViajantes = [...viajantes]
                    const convidado = newViajantes[index].Convidado

                    newViajantes.splice(index, 1)
                    if (!convidado && newViajantes.length > 0) {
                        newViajantes.forEach(viajante => {
                            if (viajante.Codigo !== '') {
                                viajante.Convidado = false
                                return ''
                            }
                        })
                    }
                    setViajantes(newViajantes)
                }}
            />
        )
    }
}

const ContentButton = styled.div`
    button {
        margin-top: 15px;
        height: 40px;
        width: 160px;
        min-height: auto;
        min-width: auto;
    }

    @media (max-width: 768px) {
        width: 100%;
        button {
            width: 100%;
        }
    }
`
const ViajantePrincipalDiv = styled.button`
    background-color: #10478a;
    padding: 0.8vh;
    color: white;
    font-size: 8pt;
    border-radius: 0.7vh;
    border: none;
    width: 15vh;
    font: 'Montserrat, sas-serif';
`
const TornarPrincipalBtn = styled.button`
    padding: 0.8vh;
    color: #fff;
    background-color: #c5dbff;
    font-size: 8pt;
    border-radius: 0.7vh;
    border: none;
    width: 15vh;
`
const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
`
const TitleDesktop = styled.span`
    margin-top: 20px;
    margin-left: 10px;
    @media (max-width: 768px) {
        display: none;
    }
`

const ButtonMobile2 = styled.span`
    display: none;
    @media (max-width: 768px) {
        display: block;
        lign-items: 'center';
        justify-content: center;
        align-items: center;
    }
`
const Box = styled.div`
    border: solid 0px lightgray;
    border-radius: 0px;

    // @media (max-width: 415px) {
    //     margin-left: 0.9rem;
    // }

    // @media (max-width: 390px) {
    //     margin-left: -0.4rem;
    // }

    // @media (max-width: 376) {
    //     margin-left: 1rem;
    // }
`

const TrMobile = styled.tr`
    border: 0 !important;
    z-index: 10;
`
const ThMobile = styled.th`
    position: relative;
    width: auto !important;
    height: auto;
    color: #002a5d;
`
const TdMobile = styled.td`
    padding: 0.5rem 0rem !important;
    color: ${props => props.theme.colors.text};
    padding-left: 5px !important;
    color: #002a5d;
    .form-select {
        background-color: #f1f3f5;
    }
    .button.dropdown-item {
        background: #f1f3f5;
    }
`

const Quadro = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 8px;

    width: 342px;
    height: 0 auto;
    min-height: 218px;
    background: #f1f3f5;
    border: 1px solid #dee2e6;

    box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 1rem 0;
`
const Container = styled.div`
    border: solid 1px lightgray;
    border-radius: 15px;
    @media (max-width: 768px) {
        border: solid 0px lightgray;
    }
`

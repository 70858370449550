import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button'
import { ENDPOINTS } from '../../../services/config-http'
import { STATUS_SOLICITACAO } from '../../../contexts/enums/StatusSolicitacao'
import { Header } from '../../../core/components/Header/Header'
import { get } from '../../../services/http'
import { useHistory, useParams } from 'react-router'
import { Solicitacao } from '../../../contexts/interfaces/solicitacao'
import { Alert } from '../../../core/components/Alert'

export const NovaEtapa = () => {
    const history = useHistory()
    const [solicitacao, setSolicitacao] = useState<any>()
    const { Id } = useParams<any>()
    const [possuiRascunho, setPossuiRascunho] = useState(false)

    useEffect(() => {
        get(`${ENDPOINTS.solicitarViagem}/${Id}`).then(
            ({ data: { Model } }) => {
                const solicitacao: Solicitacao = Model
                get(ENDPOINTS.solicitarViagemEtapas.replace(':Id', Id)).then(
                    ({ data: { Model } }) => {
                        solicitacao.etapas = Model
                        setSolicitacao(solicitacao)
                    }
                )
            }
        )
    }, [Id])

    useEffect(() => {
        setPossuiRascunho(localStorage.getItem('NovaEtapa') !== null)
    }, [])

    function PeopleList(value: Array<any> = []): JSX.Element {
        if (value.length) {
            return (
                <div className="btn-group">
                    <button
                        type="button"
                        className="form-select removed-focus"
                        style={{
                            fontSize: '0.77777rem',
                            border: 'none',
                            paddingLeft: '0',
                            marginLeft: '0',
                            color: '#5f8dd3'
                        }}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        {value[0].Nome}
                    </button>
                    <ul
                        className="dropdown-menu"
                        style={{ fontSize: '0.7777rem' }}
                    >
                        {value.map((i: any, index) => (
                            <li key={index}>
                                <a
                                    className="dropdown-item"
                                    href="/"
                                    style={{
                                        pointerEvents: 'none',
                                        cursor: 'default'
                                    }}
                                >
                                    {i.Nome}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )
        } else {
            return <>{value}</>
        }
    }

    function StatusInfo(value: string): JSX.Element {
        switch (value) {
            case STATUS_SOLICITACAO.FINALIZADA:
                return (
                    <span
                        className="badge status-success"
                        style={{ margin: 'auto' }}
                    >
                        <FontAwesomeIcon icon="circle" />
                        &ensp; {value}
                    </span>
                )
            case STATUS_SOLICITACAO.AUTORIZACAO_PENDENTE:
            case STATUS_SOLICITACAO.AGUARDANDO_CONFERENCIA:
            case STATUS_SOLICITACAO.AGUARDANDO_FINALIZAR_NA_TESOURARIA:
            case STATUS_SOLICITACAO.EM_COTACAO:
            case STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS:
                return (
                    <span
                        className="badge status-pendding"
                        style={{ marginLeft: '1rem', color: '#5D5D5D' }}
                    >
                        <FontAwesomeIcon icon="circle" />
                        &ensp;
                        {value === 'Aguardando prestação de contas'
                            ? 'Aguardando prest. contas'
                            : value === 'Aguardando finalizar na tesouraria'
                            ? 'Finalização tesouraria'
                            : value}
                    </span>
                )
            case STATUS_SOLICITACAO.REPROVADO:
            case STATUS_SOLICITACAO.REPROVADO_PRESTACAO_DE_CONTAS:
                return (
                    <span
                        className="badge status-danger"
                        style={{ margin: 'auto' }}
                    >
                        <FontAwesomeIcon icon="circle" />
                        &ensp;
                        {value === 'Reprovado: prestação de contas'
                            ? 'Reprovado: prest. contas'
                            : value}
                    </span>
                )
            case STATUS_SOLICITACAO.CANCELADO:
                return (
                    <span
                        className="badge status"
                        style={{ backgroundColor: '#525252' }}
                    >
                        <FontAwesomeIcon icon="circle" />
                        &ensp; {value}
                    </span>
                )
            default:
                return (
                    <>
                        <i className="text-danger" style={{ margin: 'auto' }}>
                            {value}
                        </i>
                    </>
                )
        }
    }
    return (
        <BlankBoard>
            <Header
                title="Nova Etapa"
                actionArrowClick={() => history.goBack()}
            ></Header>

            <Alert
                fixed={true}
                icon="exclamation-circle"
                type="exclamation"
                message={
                    possuiRascunho
                        ? 'Você possui uma solicitação de NOVA ETAPA em rascunho, clique no botão ao lado para continuar'
                        : 'Caso precise estender sua viagem, você pode adicionar uma NOVA ETAPA à sua solicitação'
                }
            ></Alert>

            <div className="row">
                <div className="col-12 col-sm-7"></div>

                <div
                    style={{
                        display: 'flex',
                        margin: '1rem 0',
                        justifyContent: 'flex-end'
                    }}
                    className="col-12 col-sm-5"
                >
                    <Button
                        icon={possuiRascunho ? 'play' : 'plus'}
                        colorType="quaternary"
                        onClick={() => {
                            localStorage.setItem('NovaEtapa', Id)
                            history.push('/solicitar-viagem/incluir-viajantes')
                        }}
                    >
                        {possuiRascunho
                            ? 'Continuar Nova Etapa'
                            : 'Criar Nova Etapa'}
                    </Button>
                    {possuiRascunho && (
                        <Button
                            icon="trash-alt"
                            colorType="oitotiary"
                            onClick={() => {
                                localStorage.removeItem('NovaEtapa')
                                setPossuiRascunho(!possuiRascunho)
                            }}
                        >
                            Deletar
                        </Button>
                    )}
                </div>

                <div
                    style={{
                        alignItems: 'center',
                        margin: '1rem 0'
                    }}
                >
                    <LabelFilterText>
                        {solicitacao?.etapas?.length} Etapa(s)
                    </LabelFilterText>
                </div>

                <p>
                    {solicitacao?.etapas?.map((item: any, index: number) => {
                        return (
                            <div key={index}>
                                <Info>
                                    <p>
                                        <Title>Etapa {index + 1}</Title>
                                    </p>
                                    <p>
                                        <div style={{ display: 'flex' }}>
                                            <b>Situação:</b>
                                            {StatusInfo(item?.Situacao)}
                                        </div>
                                    </p>
                                    <p>
                                        <div style={{ display: 'flex' }}>
                                            <b>Viajantes:</b>
                                            <span
                                                style={{
                                                    marginTop: '-6px',
                                                    marginLeft: '10px',
                                                    color: '#5f8dd3'
                                                }}
                                            >
                                                {PeopleList(
                                                    solicitacao?.Viajantes
                                                )}
                                            </span>
                                        </div>
                                    </p>
                                </Info>
                                <RoteiroInfo>
                                    {item?.Destinos?.map(
                                        (roteiro: any, index: number) => (
                                            <div key={index}>
                                                <p>
                                                    <Title>
                                                        Roteiro {index + 1}
                                                    </Title>
                                                </p>
                                                <p>
                                                    <FontAwesomeIcon icon="bars" />
                                                    {`  ${roteiro?.Objetivo}` ||
                                                        'Carregando...'}
                                                </p>

                                                <div>
                                                    <p>
                                                        <FontAwesomeIcon icon="map-marker-alt" />
                                                        {`  ${roteiro?.Destino}` ||
                                                            'Carregando...'}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        <FontAwesomeIcon icon="calendar-alt" />
                                                        &ensp;
                                                        {`   ${
                                                            new Date(
                                                                roteiro?.DataInicio
                                                            ).toLocaleDateString() ||
                                                            'Carregando...'
                                                        }  -  ${new Date(
                                                            roteiro?.DataTermino
                                                        ).toLocaleDateString()}` ||
                                                            'Carregando...'}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </RoteiroInfo>
                            </div>
                        )
                    })}
                </p>
            </div>
        </BlankBoard>
    )
}

const LabelFilterText = styled.p`
    margin: 0 1rem;
    color: ${props => props.theme.colors.primary};
    font-size: 1.75rem;
    font-weight: 600;
`
const Info = styled.div`
    font-size: 0.75rem;
    padding: 2.5em;
    margin-left: 3.5em;
    color: #6c7480;
`
const Title = styled.div`
    font-size: 1.3em;
    color: #5f8dd3;
`
const RoteiroInfo = styled.div`
    font-size: 0.75rem;
    padding: 0.1em;
    margin-left: 5.9em;
    color: #6c7480;
`
const TextLegend = styled.div`
    position: relative;
    width: 50rem;
    padding: 1rem 1rem;
    color: #896701;
    border-radius: 0.5rem;z
    text-align: start;
    font-weight: 500;
    background: #ffd75e;
    justify-content: center;
    font-size: 0.9375rem;    
    font: 'Montserrat, sas-serif';
    
    
    @media (max-width: 800px) {
        width: 20rem;
        padding: 10px;
    }
`

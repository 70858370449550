import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Header } from '../../../../core/components/Header/Header'
import { useHistory, useParams } from 'react-router-dom'
import { ENDPOINTS } from '../../../../services/config-http'
import { Button } from '../../../../core/components/Button'
import { Space } from '../../../../core/components/Space'
import { get, post, destroy } from '../../../../services/http'
import { SistemasAncoradouro } from '../../../../contexts/interfaces/sistemasAncoradouro'
import { UrlMountQuery } from '../../../../core/utils/UrlMountQuery'
import GolImg from '../../../../core/assets/Gol.jpg'
import AzulImg from '../../../../core/assets/Azul.png'
import LatamImg from '../../../../core/assets/Latam.png'
import { Center } from '../../../../core/components/Center'
import { useGlobalStates } from '../../../../state/InitialState'
import { Subtitle } from '../../../../core/components/Subtitle'
import { AereoDto } from '../../../../contexts/interfaces/aereoDto'
import { CadastroAereo } from '../../../Aereo/CadastroAereo'
import { UserContext } from '../../../../contexts/UserContext'
import { Alert } from '../../../../core/components/Alert'
import PlaneGo from '../../../../core/assets/plane-1.svg'
import PlaneBack from '../../../../core/assets/plane-2.svg'
import { DisableForMobile, IsMobile } from '../stepMobile'
import {
    Container,
    Data,
    Detail,
    Direita,
    Esquerda,
    Evento,
    Iata,
    Meio,
    Quadro,
    Text,
    Title
} from '../../../../core/components/Mobile'
import { MoneyFormat } from '../../../../core/utils/MoneyFormat'

export const EditarAereoForm = () => {
    const { Id } = useParams<any>()
    const dadosUsuario = useContext(UserContext)
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const {
        setGlobalState,
        state: { solicitacao }
    } = useGlobalStates()
    const [aereoSystems, setAereoSystems] = useState<SistemasAncoradouro[]>([])
    const [aereos, setAereos] = useState<Array<AereoDto>>([])
    const [prevSelectedTravel, setPrevSelectedTravel] = useState<Array<any>>([])
    const [infoTravel, setInfoTravel] = useState<any>({})
    const [travels, setTravels] = useState([])
    const [blankTravels, setBlankTravels] = useState([])
    const [selectedTravel, setSelectedTravel] = useState<Array<any>>([])
    const [blankSelectedTravel, setBlankSelectedTravel] = useState<Array<any>>(
        []
    )
    const [timeStart, setTimeStart] = useState({ start: '00:00', end: '23:59' })
    const [timeReturn, setTimeReturn] = useState({
        start: '00:00',
        end: '23:59'
    })
    const [listaAeroportos, setListaAeroportos] = useState<Array<any>>([])
    const [aereoSelecionado, setAereoSelecionado] = useState<any>()
    const [aereoNovos, setAereoNovos] = useState<Array<AereoDto>>([])
    const [aereoAlterados, setAereoAlterados] = useState<Array<AereoDto>>([])
    const [aereoRemovidos, setAereoRemovidos] = useState<Array<AereoDto>>([])
    const [searching, setSearching] = useState(false)
    const [showAereos, setShowAereos] = useState(false)
    const [dadosSolicitacao, setDadosSolicitacao] = useState<any>()

    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })

    useEffect(() => {
        get(`${ENDPOINTS.solicitacaoViagemPorId}/${Id}`).then(
            async ({ data: { Model } }) => {
                const listaAereos = Model?.Aereos
                setDadosSolicitacao(Model)
                setAereos(listaAereos)
            }
        )
        const aereoSelectedPrev = solicitacao?.aereo || []
        setPrevSelectedTravel(aereoSelectedPrev)
        const getAeroportos = async () => {
            await get(ENDPOINTS.aeroporto)
                .then(({ data: { Model } }) => {
                    setListaAeroportos(Model)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        getAeroportos()
    }, [])

    useEffect(() => {
        if (dadosSolicitacao?.TipoSolicitacao) {
            buscaRegra(
                dadosSolicitacao.TipoSolicitacao === 'Viagem Completa' ? 1 : 2
            )
        }
    }, [dadosSolicitacao])

    const buscaRegra = async (id: number) => {
        if (id !== 0) {
            await get(`${ENDPOINTS.regrasPoliticaViagem}/${id}`)
                .then(({ data: { Model } }) => {
                    const { UtilizarAereo } = Model
                    setShowAereos(UtilizarAereo)
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    function ChecarSeExiste(listaAereo: AereoDto[], aereoParaChecar: AereoDto) {
        let existe = -1
        listaAereo?.map((e: any, key: number) => {
            if (
                listaAereo[key].IdAeroportoOrigem ===
                    aereoParaChecar.IdAeroportoOrigem &&
                listaAereo[key].IdAeroportoDestino ===
                    aereoParaChecar.IdAeroportoDestino &&
                listaAereo[key].IdValorAereo === aereoParaChecar.IdValorAereo &&
                listaAereo[key].DataChegada?.toLocaleString() ===
                    aereoParaChecar.DataChegada?.toLocaleString() &&
                listaAereo[key].DataSaida?.toLocaleString() ===
                    aereoParaChecar.DataSaida?.toLocaleString() &&
                listaAereo[key].CompanhiaAerea ===
                    aereoParaChecar.CompanhiaAerea
            ) {
                existe = key
            }
        })
        return existe // retorna -1 caso não exista
    }

    function _adicionarAereo(aereoRecebido: AereoDto) {
        const existeRemovidas = ChecarSeExiste(aereoRemovidos, aereoRecebido)

        if (existeRemovidas === -1) {
            // caso nao seja uma hospedagem removida
            const existeAlteradas = ChecarSeExiste(
                aereoAlterados,
                aereoRecebido
            )
            if (existeAlteradas === -1) {
                // caso nao seja uma hospedagem alterada
                aereoNovos.push(aereoRecebido)
                setAereoNovos(aereoNovos)
            } else {
                aereoAlterados.splice(existeAlteradas, 1)
                setAereoAlterados(aereoAlterados)
            }
        } else {
            aereoRemovidos?.map((e: any, key: number) => {
                if (
                    aereoRemovidos[key].IdAeroportoOrigem ===
                        aereoRecebido.IdAeroportoOrigem &&
                    aereoRemovidos[key].IdAeroportoDestino ===
                        aereoRecebido.IdAeroportoDestino &&
                    aereoRemovidos[key].IdValorAereo ===
                        aereoRecebido.IdValorAereo &&
                    aereoRemovidos[key].DataChegada?.toLocaleString() ===
                        aereoRecebido.DataChegada?.toLocaleString() &&
                    aereoRemovidos[key].DataSaida?.toLocaleString() ===
                        aereoRecebido.DataSaida?.toLocaleString() &&
                    aereoRemovidos[key].CompanhiaAerea ===
                        aereoRecebido.CompanhiaAerea
                ) {
                    aereoAlterados.splice(existeRemovidas, 1)
                    setAereoAlterados(aereoAlterados)
                    aereoRemovidos.splice(existeRemovidas, 1)
                    setAereoRemovidos(aereoRemovidos)
                }
            })
        }
        setAereos(aereos)
    }

    function _removerAereo(aereoRecebido: AereoDto) {
        const existeNovas = aereoNovos.indexOf(aereoRecebido)

        formRef.current?.reset()

        if (existeNovas === -1) {
            // se nao for uma hospedagem nova
            const hasHospedagem = aereoRemovidos.some(
                i => i.Id === aereoRecebido.Id
            )
            if (hasHospedagem === false) {
                aereoRemovidos.push(aereoRecebido)
                setAereoRemovidos(aereoRemovidos)
            }
        } else {
            aereoNovos.splice(existeNovas, 1)
            setAereoNovos(aereoNovos)
        }

        const existeAlteradas = aereoAlterados.indexOf(aereoRecebido)
        if (existeAlteradas !== -1) {
            // caso seja uma hospedagem alterada
            aereoAlterados.splice(existeAlteradas, 1)
            setAereoAlterados(aereoAlterados)
        }
    }

    function _PipeCustomDate(value: string) {
        return (
            <>
                {new Date(value).toLocaleDateString('pt-br', {
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    year: 'numeric',
                    month: 'numeric'
                })}
            </>
        )
    }

    function _getAeroporto(value: any): number {
        const id = listaAeroportos.filter(item => item.Iata === value)[0].Id
        return id
    }

    function _pipeShowAeroporto(value: any) {
        const tipo = listaAeroportos.filter(item => item.Iata === value)[0]
        return <>{`${tipo?.Cidade} - ${tipo?.Iata}` || ''}</>
    }

    function _pipeShowBagagem(value: any): JSX.Element {
        if (value) {
            return (
                <span
                    className="badge status-success"
                    style={{ width: '75px', margin: 'auto' }}
                >
                    <FontAwesomeIcon icon="circle" />
                    &ensp; {'SIM'}
                </span>
            )
        } else {
            return (
                <span
                    className="badge status-danger"
                    style={{ width: '75px', margin: 'auto' }}
                >
                    <FontAwesomeIcon icon="circle" />
                    &ensp; {'NÃO'}
                </span>
            )
        }
    }

    function _pipeCompanhiaAerea(value: string) {
        switch (value) {
            case 'G3':
                return (
                    <ContainerLogo>
                        <img src={GolImg} alt="Gol"></img>
                    </ContainerLogo>
                )
            case 'AD':
                return (
                    <ContainerLogo>
                        <img src={AzulImg} alt="Azul"></img>
                    </ContainerLogo>
                )
            case 'JJ':
                return (
                    <ContainerLogo>
                        <img src={LatamImg} alt="Latam"></img>
                    </ContainerLogo>
                )

            default:
                break
        }
    }

    const handleSubmit: SubmitHandler = async form => {
        try {
            const schema = Yup.object().shape({
                origem: Yup.string().required(
                    'O Campo de Aeroporto de Origem é Obrigatório'
                ),
                destino: Yup.string().required(
                    'O Campo de Aeroporto de Origem é Obrigatório'
                ),
                dataIda: Yup.date()
                    .nullable()
                    .required('O Campo de Data de Saida é Obrigatório'),
                dataVolta: Yup.date()
                    .nullable()
                    .required('O Campo de Data de Retorno é Obrigatório')
            })

            await schema.validate(form, { abortEarly: false })
            getAereoSystems(form.origem, form.destino).then((sistema: number) =>
                getTravels(form, sistema)
            )

            formRef.current?.setErrors({})
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                formRef.current?.setErrors(erros)
            }
        }
    }

    const SubmitInformation = async () => {
        try {
            // aereoNovos.forEach(element => {
            //     console.log(element)
            //     post(ENDPOINTS.aereo, {
            //         idSolicitacaoViagem: element.IdSolicitacaoViagem,
            //         idAeroportoOrigem: element.IdAeroportoOrigem,
            //         idAeroportoDestino: element.IdAeroportoDestino,
            //         idValorAereo: element.IdValorAereo,
            //         companhiaAerea: element.CompanhiaAerea,
            //         dataSaida: element.DataSaida,
            //         dataChegada: element.DataChegada,
            //         identificacaoViagem: element.IdentificacaoViagem,
            //         bagagem: element.Bagagem,
            //         sistema: 0,
            //         numeroParadas: element.NumeroParadas
            //     })
            //         .then(res => {
            //             setShowAlert({
            //                 status: true,
            //                 message: res.data,
            //                 type: 'success'
            //             })
            //         })
            //         .catch(err => {
            //             setShowAlert({
            //                 status: true,
            //                 message: err.data.Text,
            //                 type: 'danger'
            //             })
            //         })
            // })

            aereoRemovidos.forEach(element => {
                destroy(`${ENDPOINTS.aereo}/${element.Id}`)
                    .then(res => {
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
            })
            history.push(`/solicitacoes/editar/${Id}`)
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                formRef.current?.setErrors(erros)
            }
        }
    }
    /**
     * Método para buscar os serviços disponíveis do ancoradouro para requisições de vôos
     */
    const getAereoSystems = (origem: string, destino: string) => {
        return get(
            UrlMountQuery(
                {
                    origem,
                    destino
                },
                ENDPOINTS.AncoradouroSistemasAereo
            )
        )
            .then(({ data: { Model } }) => {
                setAereoSystems(Model)
                return Model[0].Sistema
            })
            .catch(e => {
                console.error(e)
            })
    }
    /**
     *
     * @param form Dados do formulário preenchido
     * @param sistema primeiro sistema que veio na chamada do getAereoSystems()
     * @param counter contador responsável por definir qual próximo sistema será consultado, Ex. Caso o primeiro sistemna não esteja disponível
     * ele fará uma nova contulta de forma recursiva iterando o counter até a quantidade final de sistemas
     * @returns promise com o resultado da requisição
     */
    const getTravels = (form: any, sistema: number, counter = 0) => {
        setSelectedTravel([])
        const payload = {
            ...form,
            dataIda: setDate(form.dataIda, 'START'),
            dataVolta: setDate(form.dataVolta, 'RETURN'),
            flex: true,
            quantidadeAdultos: 1,
            quantidadeBebes: 0,
            quantidadeCriancas: 0,
            quantidadeDeVoos: 10,
            recomendacao: true,
            Sistema: sistema
        }

        return post(ENDPOINTS.AncoradouroVoos, payload)
            .then(({ data: { Model } }) => {
                setInfoTravel(Model)
                setTravels(Model.ViagensTrecho1)
            })
            .catch(e => {
                if (counter <= aereoSystems.length)
                    getTravels(form, aereoSystems[counter++].Sistema, counter++) // chamada recursiva
                console.error(e)
            })
    }

    const saveSelected = () => {
        if (selectedTravel.length) {
            const selected = selectedTravel[0]
            const info = formRef.current?.getData()
            const idOrigem: number = _getAeroporto(
                formRef.current?.getFieldValue('origem')
            )
            const idDestino: number = _getAeroporto(
                formRef.current?.getFieldValue('destino')
            )
            const selecionado = {
                IdSolicitacaoViagem: Id,
                IdAeroportoOrigem: idOrigem,
                IdAeroportoDestino: idDestino,
                IdValorAereo: selected?.Preco?.Total,
                CompanhiaAerea: selected?.Fornecedor?.Nome,
                DataSaida: info?.dataIda,
                DataChegada: info?.dataVolta,
                IdentificacaoViagem: selected?.IdentificacaoDaViagem,
                Bagagem: info?.BuscarVoosComBagagem,
                NumeroParadas: selected?.NumeroParadas,
                Sistema: 95
            }
            setAereoSelecionado(selecionado)
            const newAereos = [...aereos]

            newAereos.push(selecionado)
            _adicionarAereo(selecionado)

            setAereos([...newAereos])
            formRef.current?.setErrors({})

            setTravels(blankTravels)
            setSelectedTravel(blankSelectedTravel)
            setSearching(false)
            // history.push('/solicitar-viagem/hotel')
        }
    }

    /**
     * Método para conversar de um objeto Date em um formato específico da API Ancoradouro que usa o padrão epoch unix
     * @param date
     * @returns
     */
    const convertDateEpoch = (date: Date) =>
        `/Date(${date.getTime() - date.getMilliseconds() / 1000})/`

    /**
     * Método que realiza verificação da companhia para exibição do logo
     * @param cia
     * @returns
     */
    const renderLogoCia = (cia: string) => {
        switch (cia) {
            case 'G3':
                return (
                    <ContainerLogo>
                        <img src={GolImg} alt="Gol"></img>
                    </ContainerLogo>
                )
            case 'AD':
                return (
                    <ContainerLogo>
                        <img src={AzulImg} alt="Azul"></img>
                    </ContainerLogo>
                )
            case 'JJ':
                return (
                    <ContainerLogo>
                        <img src={LatamImg} alt="Latam"></img>
                    </ContainerLogo>
                )

            default:
                break
        }
    }

    /**
     * Método para montagem da data, no tempo e formato definido pela API Ancoradouro
     * @param date data a ser formatada
     * @param time referência para o estado que armazena o tempo do slider-time na tela, podendo ser START ou RETURN
     * @returns
     */
    const setDate = (date: Date, time: 'START' | 'RETURN') => {
        const timer = time === 'START' ? timeStart : timeReturn
        return convertDateEpoch(
            new Date(
                `${date.toISOString().slice(0, 10)}T${timer.start}-${timer.end}`
            )
        )
    }

    /**
     * Método responsável por separar os voos de ida e volta
     * @param flights lista de voos
     */
    const origanizeFlights = (flights: Array<any>) => {
        const goingFlights = new Array<unknown>()
        const returnFlights = new Array<unknown>()
        let flagToSeparateList = false // flag para definir a separação das listas de voo

        flights.forEach(flight => {
            if (flagToSeparateList) {
                returnFlights.push(flight)
            } else {
                goingFlights.push(flight)
                flagToSeparateList =
                    flight.Destino.CodigoIata === infoTravel.Destino.CodigoIata
            }
        })
        return { goingFlights, returnFlights }
    }

    const getReservaMobile = (reservas: any) => {
        return reservas?.map((reserva: any, index: number) => {
            return (
                <Center key={index}>
                    <Quadro>
                        <Container>
                            <Esquerda></Esquerda>
                            <Meio></Meio>

                            <Direita>
                                <Center>
                                    <Title></Title>
                                    <Title>
                                        <td className="col-2 danger cursor-pointer">
                                            <span
                                                onClick={() => {
                                                    const newAereos = [
                                                        ...aereos
                                                    ]

                                                    const aereoRemovido =
                                                        newAereos.splice(
                                                            index,
                                                            1
                                                        )
                                                    _removerAereo(
                                                        aereoRemovido[0]
                                                    )
                                                    setAereos(newAereos)
                                                }}
                                            >
                                                <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                                            </span>
                                        </td>
                                    </Title>
                                </Center>
                            </Direita>
                        </Container>
                        {getReservasMobile(
                            reserva.Companhia,
                            reserva.AeroportoOrigem,
                            reserva.AeroportoDestino,
                            reserva.AeroportoOrigem,
                            reserva.AeroportoDestino,
                            true,
                            reserva.DataIdaSaida
                        )}
                        {reserva.IdaVolta &&
                            getReservasMobile(
                                reserva.Companhia,
                                reserva.AeroportoOrigem,
                                reserva.AeroportoDestino,
                                reserva.AeroportoOrigem,
                                reserva.AeroportoDestino,
                                false,
                                reserva.DataVoltaSaida
                            )}
                        {!reserva.IdaVolta &&
                            getReservasMobile(
                                reserva.Companhia,
                                reserva.AeroportoOrigem,
                                reserva.AeroportoDestino,
                                reserva.AeroportoOrigem,
                                reserva.AeroportoDestino,
                                true,
                                reserva.DataVoltaSaida
                            )}
                        <Container>
                            <Esquerda>
                                <Title>Valor</Title>
                                <Title>
                                    {MoneyFormat(parseInt(reserva.Valor))}
                                </Title>
                            </Esquerda>
                            <Meio></Meio>

                            <Direita>
                                <Title>Escalas</Title>
                                <Title>{reserva.QtdEscalas}</Title>
                            </Direita>
                        </Container>
                    </Quadro>
                </Center>
            )
        })
    }
    const getReservasMobile = (
        logoIata: string,
        CodigoIata1: string,
        CodigoIata2: string,
        Descricao1: string,
        Descricao2: string,
        IdaVolta: boolean,
        dataViagem?: Date
    ) => {
        return (
            <>
                <Title>{renderLogoCia(logoIata || '')}</Title>
                <Data>
                    {dataViagem &&
                        new Date(dataViagem)?.toLocaleDateString('pt-BR', {
                            hour: '2-digit',
                            minute: '2-digit'
                        })}
                </Data>
                <Evento>
                    <Detail>{IdaVolta ? 'IDA' : 'VOLTA'}</Detail>
                </Evento>
                <Container>
                    <Esquerda>
                        <Iata>{CodigoIata1}</Iata>
                        <Center>
                            <Text>{_pipeShowAeroporto(Descricao1)}</Text>
                        </Center>
                    </Esquerda>
                    <Meio>
                        <img src={IdaVolta ? PlaneGo : PlaneBack} alt="aviao" />
                    </Meio>

                    <Direita>
                        <Iata>{CodigoIata2}</Iata>
                        <Center>
                            <Text>{_pipeShowAeroporto(Descricao2)}</Text>
                        </Center>
                    </Direita>
                </Container>
            </>
        )
    }

    const listTravels = (travels: Array<any>) => {
        return travels.map((item: any) => {
            const { goingFlights, returnFlights } = origanizeFlights(item.Voos)

            return (
                <>
                    <CardTravel
                        key={item.Id}
                        className="border-top"
                        style={
                            selectedTravel.length
                                ? { border: 'solid 3px #8ADFAC' }
                                : {}
                        }
                    >
                        {selectedTravel.length ? (
                            <TravelChecked>
                                <FontAwesomeIcon icon="check-circle" />{' '}
                                <b>Selecionado</b>
                            </TravelChecked>
                        ) : null}
                        <div className="row">
                            <div className="col-12">
                                {renderLogoCia(item.CiaMandatoria.CodigoIata)}
                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className="row">
                                    <MetaInfoDate className="col-12">
                                        <p>
                                            {new Date(
                                                infoTravel.DataIda
                                            ).toLocaleDateString()}
                                        </p>
                                        <span>IDA</span>
                                    </MetaInfoDate>
                                    <div className="col-12 d-flex justify-content-center mb-3">
                                        {TravelLocalInfo(item, 'START')}
                                    </div>
                                    {listFlights(goingFlights)}
                                </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className="row">
                                    <MetaInfoDate className="col-12">
                                        <p>
                                            {new Date(
                                                infoTravel.DataVolta
                                            ).toLocaleDateString()}
                                        </p>
                                        <span>VOLTA</span>
                                    </MetaInfoDate>
                                    <div className="col-12 d-flex justify-content-center mb-3">
                                        {TravelLocalInfo(item, 'RETURN')}
                                    </div>
                                    {listFlights(returnFlights)}
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center align-items-center mt-3">
                                <Price>
                                    R$
                                    {item.Preco.TotalGeral}
                                </Price>
                            </div>
                            <div className="col-12 d-flex justify-content-center align-items-center mb-3">
                                <Button
                                    style={{
                                        width: '160px',
                                        minWidth: 'auto',
                                        minHeight: '35px'
                                    }}
                                    colorType="secondary"
                                    onClick={() => {
                                        if (selectedTravel.length) {
                                            setSelectedTravel([])
                                            setSearching(false)
                                        } else {
                                            const selected = []
                                            selected.push(item)
                                            setSelectedTravel(selected as any)
                                            setSearching(true)
                                        }
                                    }}
                                    icon={
                                        selectedTravel.length
                                            ? 'ban'
                                            : 'comment-alt-check'
                                    }
                                >
                                    <b>
                                        {selectedTravel.length
                                            ? 'Cancelar'
                                            : 'Selecionar'}
                                    </b>
                                </Button>
                            </div>
                            <div className="col-12 pb-4">
                                <BoxInfo>
                                    <div className="info-item">
                                        <FontAwesomeIcon icon="suitcase-rolling" />{' '}
                                        Bagagem:{' '}
                                        <b>
                                            {item?.Voos[0]?.BagagemInclusa
                                                ? 'Sim'
                                                : 'Não'}
                                        </b>
                                    </div>
                                    <span> | </span>
                                    <div className="info-item">
                                        <FontAwesomeIcon icon="clock" />{' '}
                                        Duração: <b>{item.TempoDeDuracao}</b>
                                    </div>
                                    <span> | </span>
                                    <div className="info-item">
                                        <FontAwesomeIcon icon="stop-circle" />{' '}
                                        Paradas: <b>{item.NumeroParadas}</b>
                                    </div>
                                </BoxInfo>
                            </div>
                        </div>
                    </CardTravel>
                </>
            )
        })
    }

    /**
     * Render
     */
    return (
        <BlankBoard>
            <Header
                title="Solicitação de viagem"
                actionArrowClick={() => {
                    history.push(`/solicitacoes/editar/${Id}`)
                }}
            ></Header>
            <>
                <Space height="2rem"></Space>
                <Subtitle
                    style={{
                        textAlign: 'center',
                        margin: '1rem 0'
                    }}
                >
                    Dados de Aéreo
                </Subtitle>

                {showAereos ? (
                    <>
                        <CadastroAereo
                            idSolicitacao={Id}
                            qtdViajantes={solicitacao?.viajantes?.length || 1}
                            editar={true}
                        />

                        <div className="row" style={{ marginTop: '3rem' }}>
                            <div className="col-12">
                                <label htmlFor=""></label>
                                <DisableForMobile>
                                    {' '}
                                    <div
                                        className="table-responsive"
                                        style={{
                                            border: 'solid 1px lightgray',
                                            borderRadius: '15px'
                                        }}
                                    >
                                        <table className="table">
                                            <thead
                                                style={{
                                                    borderBottom:
                                                        'solid 1px lightgray'
                                                }}
                                            >
                                                <tr
                                                    style={{
                                                        border: '0 !important'
                                                    }}
                                                >
                                                    <Th
                                                        className="col-1"
                                                        scope="col"
                                                    >
                                                        #
                                                    </Th>
                                                    <Th
                                                        scope="col"
                                                        className="col-2"
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        Compania Aérea
                                                    </Th>
                                                    <Th
                                                        scope="col"
                                                        className="col-2"
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        Origem
                                                    </Th>
                                                    <Th
                                                        scope="col"
                                                        className="col-2"
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        Destino
                                                    </Th>
                                                    <Th
                                                        scope="col"
                                                        className="col-2"
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        Data Saida
                                                    </Th>
                                                    <Th
                                                        scope="col"
                                                        className="col-2"
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        Data Chegada
                                                    </Th>
                                                    <Th
                                                        scope="col"
                                                        className="col-2"
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        Bagagem
                                                    </Th>

                                                    <Th
                                                        scope="col"
                                                        className="col-2"
                                                    >
                                                        Deletar
                                                    </Th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {aereos.map((item, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td className="col-1">
                                                                {key + 1}
                                                            </td>
                                                            <td
                                                                className="col-2"
                                                                style={{
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                {_pipeCompanhiaAerea(
                                                                    item.Companhia as string
                                                                )}
                                                            </td>
                                                            <td
                                                                className="col-2"
                                                                style={{
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                {_pipeShowAeroporto(
                                                                    item.AeroportoOrigem
                                                                )}
                                                            </td>
                                                            <td
                                                                className="col-2"
                                                                style={{
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                {_pipeShowAeroporto(
                                                                    item.AeroportoDestino
                                                                )}
                                                            </td>
                                                            <td
                                                                className="col-2"
                                                                style={{
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                {item.DataIdaSaida !==
                                                                undefined
                                                                    ? _PipeCustomDate(
                                                                          item.DataIdaSaida.toString()
                                                                      )
                                                                    : 'Não possui data de entrada'}
                                                            </td>
                                                            <td
                                                                className="col-2"
                                                                style={{
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                {item.DataIdaChegada !==
                                                                undefined
                                                                    ? _PipeCustomDate(
                                                                          item.DataIdaChegada.toString()
                                                                      )
                                                                    : 'Não possui data de entrada'}
                                                            </td>
                                                            <td className="col-2">
                                                                {_pipeShowBagagem(
                                                                    item.Bagagem
                                                                )}
                                                            </td>

                                                            <td className="col-2 danger cursor-pointer">
                                                                <span
                                                                    onClick={() => {
                                                                        const newAereos =
                                                                            [
                                                                                ...aereos
                                                                            ]

                                                                        const aereoRemovido =
                                                                            newAereos.splice(
                                                                                key,
                                                                                1
                                                                            )
                                                                        _removerAereo(
                                                                            aereoRemovido[0]
                                                                        )
                                                                        setAereos(
                                                                            newAereos
                                                                        )
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </DisableForMobile>
                                <IsMobile>{getReservaMobile(aereos)}</IsMobile>
                            </div>
                        </div>
                        <Space height="3rem"></Space>

                        <ContainerList>
                            <div className="row">
                                <div
                                    className="col-12"
                                    style={{
                                        overflowY: 'scroll',
                                        overflowX: 'hidden',
                                        maxHeight: '70vh'
                                    }}
                                >
                                    {!selectedTravel.length
                                        ? listTravels(travels)
                                        : listTravels(selectedTravel)}
                                </div>
                            </div>
                        </ContainerList>
                        <div id="listaDeVoos"></div>
                        <Space height="2rem"></Space>
                        <Center>
                            {selectedTravel.length ? (
                                <Button
                                    colorType="primary"
                                    type="submit"
                                    icon="plus"
                                    onClick={() => saveSelected()}
                                >
                                    Adicionar
                                </Button>
                            ) : null}
                        </Center>
                        <Space height="2rem"></Space>
                        <Center>
                            {!searching ? (
                                <>
                                    <DisableForMobile>
                                        <Button
                                            colorType="quaternary"
                                            type="submit"
                                            icon="save"
                                            onClick={() => SubmitInformation()}
                                        >
                                            Salvar
                                        </Button>
                                    </DisableForMobile>
                                    <IsMobile>
                                        <Button
                                            colorType="btnMobile"
                                            type="submit"
                                            icon="save"
                                            onClick={() => SubmitInformation()}
                                        >
                                            Salvar
                                        </Button>
                                    </IsMobile>
                                </>
                            ) : null}
                        </Center>
                    </>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Consulte as politicas de viagens"
                    ></Alert>
                )}
            </>
        </BlankBoard>
    )
}

//  Components
const TravelLocalInfo = (item: any, type: 'START' | 'RETURN') => (
    <>
        <StartInfo>
            <CodeCia>
                {type === 'START'
                    ? item.Origem.CodigoIata
                    : item.Destino.CodigoIata}
            </CodeCia>
            <Description>
                {type === 'START'
                    ? item.Origem.Descricao
                    : item.Destino.Descricao}
            </Description>
        </StartInfo>

        <Icon>
            <FontAwesomeIcon
                icon="plane-departure"
                style={type === 'RETURN' ? { transform: 'scaleX(-1)' } : {}}
            />
        </Icon>
        <EndInfo>
            <CodeCia>
                {type === 'START'
                    ? item.Destino.CodigoIata
                    : item.Origem.CodigoIata}
            </CodeCia>
            <Description>
                {type === 'START'
                    ? item.Destino.Descricao
                    : item.Origem.Descricao}
            </Description>
        </EndInfo>
    </>
)

/**
 * Alone Function para formatação com zero nos horários dos voos
 * @param timer
 * @returns
 */
const addZeroInTime = (timer: number) => {
    return timer < 10 ? '0' : ''
}

/**
 * Componente para listar voos
 */
const listFlights = (flights: Array<any>) => {
    return flights.map((flight: any, index) => {
        const dataChegada = new Date(flight.DataChegada)
        const dataSaida = new Date(flight.DataSaida)

        const startHour = `${addZeroInTime(
            dataSaida.getHours()
        )}${dataSaida.getHours()}:${addZeroInTime(
            dataSaida.getMinutes()
        )}${dataSaida.getMinutes()}`

        const endHour = `${addZeroInTime(
            dataChegada.getHours()
        )}${dataChegada.getHours()}:${addZeroInTime(
            dataChegada.getMinutes()
        )}${dataChegada.getMinutes()}`

        return (
            <div className="col-12" key={index}>
                <div className="row">
                    <DescriptionFlights className="col-4 col-sm-5 text-end">
                        <span className="hidden-sm">
                            {flight.Destino.Descricao}
                        </span>
                        <span className="d-none show-sm">
                            {flight.Origem.CodigoIata}
                        </span>
                    </DescriptionFlights>
                    <HoursFly className="col-4 col-sm-2">
                        <span>
                            <b>{startHour}</b>
                        </span>
                        <span>
                            <FontAwesomeIcon
                                icon="arrow-right"
                                color="#83cbff"
                            />
                        </span>
                        <span>
                            <b>{endHour}</b>
                        </span>
                    </HoursFly>
                    <DescriptionFlights className="col-4 col-sm-5">
                        <span className="hidden-sm">
                            {flight.Destino.Descricao}
                        </span>
                        <span className="d-none show-sm">
                            {flight.Origem.CodigoIata}
                        </span>
                    </DescriptionFlights>
                </div>
            </div>
        )
    })
}

//  Styled Components

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
    text-align: 'center';
`

const BoxInfo = styled.div`
    width: 320px;
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
`

const Price = styled.h4`
    color: #83cbff;
    font-weight: 600;
`

const HoursFly = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const DescriptionFlights = styled.div`
    @media (max-width: 520px) {
        font-size: 0.7777rem;
    }
`

const TravelChecked = styled.span`
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 600;
    color: ${props => props.theme.colors.success};
`

const Circle = styled.div`
    width: 60px;
    height: 60px;
    background-color: #dbdee3;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    margin-top: 15px;
    @media (max-width: 720px) {
        transform: rotate(270deg);
        transition: 0.2s ease-in-out;
    }
`
const ContentCircle = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
const Line = styled.div`
    height: 50%;
    width: 2px;
    background-color: #dbdee3;
    margin: auto;
    margin-top: 20px;
`
const ContainerList = styled.div`
    border-radius: 10px;
    background-color: #dbdee3;
`
const CardTravel = styled.div`
    background-color: white;
    margin: 1.5rem 1rem;
    position: relative;

    @media (max-width: 720px) {
        margin: 0;
    }
`
const ContainerLogo = styled.div`
    width: 100px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: start;
    margin: 0 auto;

    img {
        width: 100%;
    }
`

const MetaInfoDate = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-bottom: 5px;
    p {
        margin-bottom: 5px;
        color: ${props => props.theme.colors.primary};
        font-weight: 600;
    }
    span {
        width: 100px;
        text-align: center;
        color: #fff;
        background-color: ${props => props.theme.colors.primary};
    }
`
const StartInfo = styled.div`
    display: flex;
    flex-direction: column;
    text-align: end;
    padding-right: 5px;
    width: 45%;
`
const Icon = styled.div`
    font-size: 1.5rem;
    width: 10%;
    text-align: center;
    color: #83cbff;
`
const EndInfo = styled.div`
    display: flex;
    flex-direction: column;
    text-align: start;
    /* padding-right: 20px; */
    padding-left: 5px;
    width: 45%;
`

const CodeCia = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
`

const Description = styled.div`
    font-size: 0.6rem;
`

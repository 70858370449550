import React from 'react'

interface FlexProps {
    justifyContent?: string
    alignItems?: string
    children?: React.ReactNode
    style?: React.CSSProperties
}

export const Flex = ({
    justifyContent = 'unset',
    alignItems = 'unset',
    children,
    style
}: FlexProps) => (
    <div style={{ ...FLexStyle, justifyContent, alignItems, ...style }}>
        {children}
    </div>
)

const FLexStyle: React.CSSProperties = {
    display: 'flex',
    width: '100%',
    margin: '1rem 0'
}

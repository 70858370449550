import React, { useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { StepByStep } from '../../../../core/components/StepByStep'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Header } from '../../../../core/components/Header/Header'
import { Input } from '../../../../core/components/Form/Input'
import { Space } from '../../../../core/components/Space'
import { Center } from '../../../../core/components/Center'
import { Button } from '../../../../core/components/Button'
import { useHistory } from 'react-router-dom'
import { Subtitle } from '../../../../core/components/Subtitle'
import { InputPlaces } from '../../../../core/components/InputPlace'
import { DatePicker } from '../../../../core/components/DatePicker'
import { useGlobalStates } from '../../../../state/InitialState'
import { SOLICITACAO_VIAGEM } from '../../../../state/SolicitacaoViagem/Actions'
import { STEP_BY_STEP } from '../../../../state/StepByStep/Actions'
import { Solicitacao } from '../../../../contexts/interfaces/solicitacao'
import { StepByStepItem } from '../../../../contexts/interfaces/_stepByStep'
import { ContainerPadding } from '../../../../core/components/ContainerPadding'
import { Empty } from '../../../../core/components/Empty'
import { Deletar } from '../../../../core/components/Deletar'
import { ENDPOINTS } from '../../../../services/config-http'
import { get } from '../../../../services/http'
import styled from 'styled-components'
import { DisableForMobile, IsMobile } from '../stepMobile'

interface RoteiroProp {
    destino?: string
    origem?: string
    objetivo?: string
    dataInicio?: string
    dataTermino?: string
}

export const MultiTrechosForm = () => {
    const FormRef = useRef<FormHandles>(null)
    const history = useHistory()
    const {
        state: { solicitacao },
        setGlobalState
    } = useGlobalStates()
    const [roteiros, setRoteiros] = useState<Array<RoteiroProp>>([])
    const [stepByStep, setStepByStep] = useState<StepByStepItem[]>([])
    const indexActiveStep = useRef<number>(0)
    const [regrasPoliticaViagem, setRegrasPoliticaViagem] = useState<any>()
    const [msgErro, setMsgErro] = useState('')

    useEffect(() => {
        const stepItem = localStorage.getItem('stepByStep')

        get(
            `${ENDPOINTS.regrasPoliticaViagem}/BuscarPorIdPolitica/${
                solicitacao?.viajantes?.filter(
                    item => item?.Convidado === false
                )[0].IdPoliticaViagem
            }`
        )
            .then(({ data: { Model } }) => {
                const regras: Array<any> = Model
                setRegrasPoliticaViagem(
                    regras.filter(
                        item =>
                            item?.IdTipoSolicitacao ===
                            solicitacao?.idTipoSolicitacao
                    )[0]
                )
            })
            .catch(err => {
                console.error(err)
            })

        if (stepItem !== null) {
            const stepItemObj = JSON.parse(stepItem) as StepByStepItem[]
            indexActiveStep.current = stepItemObj.findIndex(
                s => s.name === 'Roteiro'
            )
            stepItemObj[indexActiveStep.current].active = true
            for (let index = 0; index < indexActiveStep.current; index++) {
                stepItemObj[index].completed = true
            }
            setStepByStep(stepItemObj)
        }

        if (solicitacao === undefined) {
            const sol = localStorage.getItem('solicitacao')
            if (sol !== null) {
                const solicitacaoStorage = JSON.parse(sol) as Solicitacao
                setGlobalState(
                    SOLICITACAO_VIAGEM.REDUCER_NAME,
                    SOLICITACAO_VIAGEM.ACTION_ADD_SOLICITACAO,
                    solicitacaoStorage
                )
                setRoteiros(solicitacaoStorage?.destinos || [])
            }
        } else {
            setRoteiros(solicitacao.destinos || [])
        }
    }, [solicitacao])

    const handleSubmit: SubmitHandler = async (data, { reset }) => {
        try {
            const schema = Yup.object().shape({
                destino: Yup.string().required('O Campo Destino é obrigatório'),
                dataInicio: Yup.string().required(
                    'O Campo Data Início é obrigatório'
                ),
                dataTermino: Yup.string().required(
                    'O Campo Data Termino é obrigatório'
                ),
                objetivo: Yup.string().required(
                    'O Campo Objetivo é obrigatório'
                )
            })
            let { destino, dataInicio, dataTermino, objetivo } = data

            // quando o item vier null é necessário inputar o valor "" = vazio para que seja pego na validação
            dataInicio =
                dataInicio === null ? '' : data.dataInicio.toLocaleDateString()

            dataTermino =
                dataTermino === null
                    ? ''
                    : data.dataTermino.toLocaleDateString()

            if (
                regrasPoliticaViagem?.ValidarPrazoAntecedenciaParaRequisacaoDeSolicitacao
            ) {
                if (dataInicio === new Date().toLocaleDateString()) {
                    dataInicio = ''
                    schema.fields.dataInicio = Yup.string().required(
                        'A política não permite fazer a solicitação para viagem sem ao menos 1 dia de antecedência'
                    )
                }
            }

            const trataData = (param: string | undefined) => {
                const strData = param
                const partesData = strData?.split('/')
                if (partesData) {
                    data = new Date(
                        `${Number(partesData[1]) - 1}/${partesData[0]}/${
                            partesData[2]
                        }`
                    )
                }
                return data
            }
            if (trataData(dataInicio) > trataData(dataTermino)) {
                dataInicio = ''
                schema.fields.dataInicio = Yup.string().required(
                    'A Data de início não pode ser menor que a de término'
                )
            }

            roteiros.forEach(element => {
                if (
                    trataData(dataInicio) > trataData(dataTermino) ||
                    trataData(element?.dataTermino) > trataData(dataInicio)
                ) {
                    dataInicio = ''
                    schema.fields.dataInicio = Yup.string().required(
                        'A Data de início não pode ser menor que a de término de outro destino'
                    )
                }
            })
            await schema.validate(
                { destino, dataInicio, dataTermino, objetivo },
                { abortEarly: false }
            )

            FormRef.current?.setErrors({})
            setRoteiros([
                ...roteiros,
                {
                    destino,
                    objetivo,
                    dataInicio,
                    dataTermino
                }
            ])
            reset()
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                FormRef.current?.setErrors(erros)
            }
        }
    }
    function next() {
        let valido = false
        setGlobalState(
            SOLICITACAO_VIAGEM.REDUCER_NAME,
            SOLICITACAO_VIAGEM.ACTION_ADD_ROTEIRO,
            roteiros
        )
        setGlobalState(
            STEP_BY_STEP.REDUCER_NAME,
            STEP_BY_STEP.COMPLETE_ROTEIRO,
            true
        )
        const sol = localStorage.getItem('solicitacao')
        if (sol !== null) {
            const solicitacaoObj = JSON.parse(sol) as Solicitacao
            solicitacaoObj.destinos = roteiros
            valido = msgNext(solicitacaoObj.destinos)
            localStorage.setItem('solicitacao', JSON.stringify(solicitacaoObj))
        } else {
            valido = true
            localStorage.setItem('solicitacao', JSON.stringify(solicitacao))
        }

        if (valido) {
            history.push(
                `/solicitar-viagem/${
                    stepByStep[indexActiveStep.current + 1].caminho
                }`
            )
        }
    }

    const msgNext = (dados: any[]) => {
        let valido = true
        dados.forEach(destino => {
            if (
                regrasPoliticaViagem?.ValidarPrazoAntecedenciaParaRequisacaoDeSolicitacao
            ) {
                console.log(destino.dataInicio)
                if (
                    // destino.dataInicio < new Date().toLocaleDateString() ||
                    destino.dataInicio === new Date().toLocaleDateString()
                ) {
                    valido = false
                    setMsgErro(
                        'A política não permite fazer a solicitação para viagem sem ao menos 1 dia de antecedência'
                    )
                }
            }
        })
        return valido
    }

    return (
        <>
            <BlankBoard>
                <Header
                    title="Solicitação de Viagem"
                    actionArrowClick={() => {
                        if (indexActiveStep.current === 0) {
                            history.push('/solicitar-viagem/info')
                        } else {
                            history.push(
                                `/solicitar-viagem/${
                                    stepByStep[indexActiveStep.current - 1]
                                        .caminho
                                }`
                            )
                        }
                    }}
                ></Header>
                <StepByStep items={stepByStep}></StepByStep>
                <ContainerPadding>
                    <Subtitle>Roteiro da viagem</Subtitle>
                    <p>
                        Descreva o roteiro da sua viagem com seus destidos,
                        datas e objetivos.
                    </p>
                    <Space height="2rem"></Space>
                    <Form onSubmit={handleSubmit} ref={FormRef}>
                        <div className="row">
                            <InputPlaces
                                name="destino"
                                label="Destino?"
                                required={true}
                                placeholder="Localize o destino"
                            ></InputPlaces>
                            <div className="col-12 col-sm-6">
                                <DatePicker
                                    name="dataInicio"
                                    label="Quando começa?"
                                    placeholderText="Escolha a data que você iniciará no destino.."
                                    minDate={
                                        regrasPoliticaViagem?.BloquearAutorizacaoRetroativa
                                            ? new Date()
                                            : null
                                    }
                                    required={true}
                                ></DatePicker>
                            </div>
                            <div className="col-12 col-sm-6">
                                <DatePicker
                                    name="dataTermino"
                                    label="Quando termina?"
                                    placeholderText="Escolha a data que você terminará no destino.."
                                    minDate={
                                        regrasPoliticaViagem?.BloquearAutorizacaoRetroativa
                                            ? new Date()
                                            : null
                                    }
                                    required={true}
                                ></DatePicker>
                            </div>

                            <Input
                                name="objetivo"
                                label="Objetivo"
                                placeholder="Ex. Apoio a inauguração da loja 200"
                                type="text"
                                required={true}
                            ></Input>
                            <Space height="3rem"></Space>
                            <Center>
                                <IsMobile>
                                    <Button colorType="btnMobile" type="submit">
                                        Adicionar
                                    </Button>
                                </IsMobile>
                            </Center>
                            <div className="row">
                                <div className="d-flex justify-content-end align-items-center">
                                    <div className="col-12 col-sm-3">
                                        <Center>
                                            <DisableForMobile>
                                                <Button
                                                    colorType="quaternary"
                                                    icon="plus"
                                                >
                                                    Adicionar
                                                </Button>
                                            </DisableForMobile>
                                        </Center>
                                    </div>
                                </div>
                            </div>
                            <Space height="2rem"></Space>
                            {roteiros.length > 0 ? (
                                <div>
                                    <div className="col-12">
                                        <label htmlFor=""></label>
                                        <Container>
                                            {getTabela()}
                                            {getTabelaMobile()}
                                        </Container>
                                    </div>
                                    <p
                                        style={{
                                            color: 'red'
                                        }}
                                    >
                                        {msgErro}
                                    </p>
                                </div>
                            ) : (
                                <Empty text="Nenhum roteiro incluído"></Empty>
                            )}
                            <Space height="2rem"></Space>
                            <Center>
                                <>
                                    <DisableForMobile>
                                        <Button
                                            colorType="quaternary"
                                            onClick={() => next()}
                                            className={
                                                roteiros.length
                                                    ? ''
                                                    : 'invisible-item'
                                            }
                                        >
                                            Próximo
                                        </Button>
                                    </DisableForMobile>
                                    <IsMobile>
                                        <Button
                                            colorType="btnMobile"
                                            onClick={() => next()}
                                            className={
                                                roteiros.length
                                                    ? ''
                                                    : 'invisible-item'
                                            }
                                        >
                                            Próximo
                                        </Button>
                                    </IsMobile>
                                </>
                            </Center>
                        </div>
                    </Form>
                </ContainerPadding>
            </BlankBoard>
        </>
    )

    function getTabela() {
        return (
            <DisableForMobile>
                <table className="table">
                    <thead>
                        <tr
                            style={{
                                verticalAlign: 'middle'
                            }}
                        >
                            <th
                                style={{
                                    width: '4rem',
                                    textAlign: 'left',
                                    borderBottom: 'solid 1px lightgray',
                                    color: '#6C7480'
                                }}
                            >
                                <p>Destino</p>
                            </th>
                            <th
                                style={{
                                    width: '4rem',
                                    textAlign: 'left',
                                    borderBottom: 'solid 1px lightgray',
                                    color: '#6C7480'
                                }}
                            >
                                <p>Início</p>
                            </th>
                            <th
                                style={{
                                    width: '4rem',
                                    textAlign: 'left',
                                    borderBottom: 'solid 1px lightgray',
                                    color: '#6C7480'
                                }}
                            >
                                <p>Fim</p>
                            </th>
                            <th
                                style={{
                                    width: '4rem',
                                    borderBottom: 'solid 1px lightgray',
                                    color: '#6C7480'
                                }}
                            >
                                <p>Objetivo</p>
                            </th>
                            <th
                                style={{
                                    width: '4rem',
                                    borderBottom: 'solid 1px lightgray',
                                    color: '#6C7480',
                                    textAlign: 'center'
                                }}
                            >
                                <p>Remover</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {roteiros.map((roteiro, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <td
                                            style={{
                                                width: '4rem',
                                                marginLeft: '1rem',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <p>{roteiro?.destino}</p>
                                        </td>
                                        <td
                                            style={{
                                                width: '4rem',
                                                marginLeft: '1rem',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <p>{roteiro?.dataInicio}</p>
                                        </td>
                                        <td
                                            style={{
                                                width: '4rem',
                                                marginLeft: '1rem',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <p>{roteiro?.dataTermino}</p>
                                        </td>
                                        <td
                                            style={{
                                                width: '4rem',
                                                marginLeft: '1rem',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <p>{roteiro?.objetivo}</p>
                                        </td>
                                        <td
                                            style={{
                                                width: '4rem',
                                                marginLeft: '1rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <Deletar
                                                remover={() => {
                                                    const newRoteiros = [
                                                        ...roteiros
                                                    ]

                                                    newRoteiros.splice(index, 1)
                                                    setRoteiros(newRoteiros)
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </DisableForMobile>
        )
    }

    function getCampo(index: number, title: string, valor: any) {
        return (
            <div
                style={{
                    flexDirection: 'column'
                }}
            >
                <ThMobile key={index}>{title}</ThMobile>
                <TdMobile
                    key={index}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {valor}
                </TdMobile>
            </div>
        )
    }

    function getTabelaMobile() {
        return (
            <IsMobile>
                <Center>
                    <Box>
                        {roteiros.map((tableItem, index) => {
                            if (tableItem) {
                                return (
                                    <Quadro>
                                        <table style={{ width: '100%' }}>
                                            <TrMobile>
                                                {getCampo(
                                                    index,
                                                    'Destino',
                                                    tableItem.destino
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Início',
                                                    tableItem.dataInicio
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Fim',
                                                    tableItem.dataTermino
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Objetivo',
                                                    tableItem.objetivo
                                                )}
                                                {getCampo(
                                                    index,
                                                    'Remover',
                                                    <Deletar
                                                        remover={() => {
                                                            const newRoteiros =
                                                                [...roteiros]

                                                            newRoteiros.splice(
                                                                index,
                                                                1
                                                            )
                                                            setRoteiros(
                                                                newRoteiros
                                                            )
                                                        }}
                                                    />
                                                )}
                                            </TrMobile>
                                        </table>
                                    </Quadro>
                                )
                            }
                            return null
                        })}
                    </Box>
                </Center>
            </IsMobile>
        )
    }
}
const Box = styled.div`
    border: solid 1px lightgray;
    border-radius: 15px;
    @media (max-width: 768px) {
        border: solid 0px lightgray;
        border-radius: 0px;
        margin-left: -0.4rem;
    }
`

const TrMobile = styled.tr`
    border: 0 !important;
    z-index: 10;
`
const ThMobile = styled.th`
    position: relative;
    width: auto !important;
    height: auto;
    color: #002a5d;
`
const TdMobile = styled.td`
    padding: 0.5rem 0rem !important;
    color: ${props => props.theme.colors.text};
    padding-left: 5px !important;
    color: #002a5d;
    .form-select {
        background-color: #f1f3f5;
    }
    .button.dropdown-item {
        background: #f1f3f5;
    }
`

const Quadro = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 8px;

    width: 342px;
    height: 0 auto;
    min-height: 218px;
    background: #f1f3f5;
    border: 1px solid #dee2e6;

    box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 1rem 0;
`
const Container = styled.div`
    border: solid 1px lightgray;
    border-radius: 15px;
    @media (max-width: 768px) {
        border: solid 0px lightgray;
    }
`

const mountStepItems = (): StepByStepItem[] => [
    {
        name: 'Roteiro',
        active: true,
        completed: false,
        icon: 'map-marker-alt'
    },
    {
        name: 'Aéreo',
        active: false,
        completed: false,
        icon: 'plane'
    },
    {
        name: 'Hospedagem',
        active: false,
        completed: false,
        icon: 'building'
    },
    {
        name: 'Serviços',
        active: false,
        completed: false,
        icon: 'list-ul'
    },
    {
        name: 'Adiantamento',
        active: false,
        completed: false,
        icon: 'dollar-sign'
    },
    {
        name: 'Rateio',
        active: false,
        completed: false,
        icon: 'random'
    }
]

import React, { useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Center } from '../../../core/components/Center'
import { Header } from '../../../core/components/Header/Header'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Input } from '../../../core/components/Form/Input'
import { Select } from '../../../core/components/Form/Select'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { Usuario } from '../../../contexts/interfaces/usuario'
import { DatePicker } from '../../../core/components/DatePicker'
import { Button } from '../../../core/components/Button'
import { ENDPOINTS } from '../../../services/config-http'
import { put, get, post } from '../../../services/http'
import { Alert } from '../../../core/components/Alert'
import { Subtitle } from '../../../core/components/Subtitle'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { SelectMapper } from '../../../core/utils/SelectMapper'
import { Input as InputMask } from '../../../core/components/Form/v2/InputMask'
import { Space } from '../../../core/components/Space'
import { Switch } from '../../../core/components/Switch'
import { Hidden } from '@material-ui/core'
import { UserContext } from '../../../contexts/UserContext'
import { useSpinner } from '../../../hooks/Spinner'
import { DisableForMobile, IsMobile } from '../../Viagem/Solicitacao/stepMobile'

export const UsuariosConvidados = () => {
    const history = useHistory()
    const FormRef = useRef<FormHandles>(null)
    const cpfRef = useRef(null) as any
    const dddRef = useRef(null) as any
    const telefoneRef = useRef(null) as any
    const { Id } = useParams() as any
    const [switchAdministrador, setSwitchAdministrador] = useState(true)
    const [aprovadorDeViagens, setAprovadorDeViagens] = useState(true)
    const [aprovadorDePrestacao, setAprovadorDePrestacao] = useState(true)
    const [agenteDeViagens, setAgenteDeViagens] = useState(true)
    const [status, setStatus] = useState(true)
    const [msg, setMsg] = useState([])
    const { setLoading } = useSpinner()

    const [options, setOptions] = useState([])
    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })

    const [administrador, setAdministrador] = useState(false)
    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])
    useEffect(() => {
        setOptions(
            SelectMapper(
                [
                    { value: 0, label: 'Masculino' },
                    { value: 1, label: 'Feminino' }
                ],
                'label',
                'value'
            )
        )

        if (Id !== undefined) {
            get(`${ENDPOINTS.usuario}/${Id}`)
                .then(({ data: { Model } }) => {
                    FormRef.current?.setData(Model)
                    setStatus(Model?.Status)
                    setSwitchAdministrador(Model?.Administrador)
                    setAprovadorDeViagens(Model?.AprovaViagem)
                    setAprovadorDePrestacao(Model?.AprovaPrestacao)
                    setAgenteDeViagens(Model?.AgenteViagem)
                })
                .catch(err => {
                    if (err === undefined) {
                        setShowAlert({
                            status: true,
                            message: 'Impossível acessar a API',
                            type: 'danger'
                        })
                    } else if (err?.data) {
                        setShowAlert({
                            status: true,
                            message: err?.data,
                            type: 'danger'
                        })
                    }
                })
        }
    }, [])
    const handleSubmit: SubmitHandler<Usuario> = async (
        dadosForm,
        { reset }
    ) => {
        dadosForm.Cpf = cpfRef?.current?.value
            ?.replaceAll('.', '')
            .replaceAll('-', '')
            .replaceAll('_', '')
        dadosForm.DDD = dddRef?.current?.value
        dadosForm.Telefone = telefoneRef?.current?.value?.replace('-', '')
        setMsg([])
        try {
            const schema = Yup.object().shape({
                Nome: Yup.string().required('O Campo nome é obrigatório'),
                Cpf: Yup.string().required('O Campo CPF é obrigatório'),
                Genero: Yup.string().required('O Campo Gênero é obrigatório'),
                DataNascimento: Yup.date()
                    .nullable()
                    .required('O Campo Data de Nascimento é obrigatório'),
                DDD: Yup.string().required('O Campo DDD é obrigatório'),
                Telefone: Yup.string().required(
                    'O Campo Telefone é obrigatório'
                ),
                Email: Yup.string().required('O Campo E-mail é obrigatório')
            })

            if (dadosForm.Cpf?.length !== 11) dadosForm.Cpf = undefined

            await schema.validate(dadosForm, { abortEarly: false })
            if (Id !== undefined) {
                setLoading(true)
                dadosForm.Id = Id
                // dadosForm.Status = status
                put(ENDPOINTS.usuario, {
                    id: dadosForm.Id,
                    nome: dadosForm.Nome,
                    genero: dadosForm.Genero,
                    cpf: dadosForm.Cpf,
                    rg: dadosForm.Rg,
                    email: dadosForm.Email,
                    dataNascimento: dadosForm.DataNascimento,
                    cnh: dadosForm.Cnh,
                    ddd: dadosForm.DDD,
                    telefone: dadosForm.Telefone,
                    passaporte: dadosForm.Passaporte,
                    validadePassaporte: dadosForm.ValidadePassaporte,
                    status: status,
                    administrador: switchAdministrador,
                    AprovaViagem: aprovadorDeViagens,
                    AprovaPrestacao: aprovadorDePrestacao,
                    AgenteViagem: agenteDeViagens
                })
                    .then(res => {
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                        setTimeout(() => {
                            setLoading(false)
                            history.push(`/cadastros/usuario`)
                        }, 1000)
                    })
                    .catch(err => {
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            } else {
                // dadosForm.Status = true
                setLoading(true)
                post(ENDPOINTS.usuarioII, {
                    nome: dadosForm.Nome,
                    genero: dadosForm.Genero,
                    cpf: dadosForm.Cpf,
                    rg: dadosForm.Rg,
                    email: dadosForm.Email,
                    dataNascimento: dadosForm.DataNascimento,
                    cnh: dadosForm.Cnh,
                    ddd: dadosForm.DDD,
                    telefone: dadosForm.Telefone,
                    passaporte: dadosForm.Passaporte,
                    validadePassaporte: dadosForm.ValidadePassaporte,
                    status: dadosForm.Status,
                    administrador: dadosForm.Administrador,
                    aprovadorDeViagens: dadosForm.AprovaViagem,
                    aprovadorDePrestacao: dadosForm.AprovaPrestacao,
                    agenteDeViagens: dadosForm.AgenteViagem
                })
                    .then(res => {
                        if (
                            res?.data?.Messages &&
                            res.data.Messages[0] ===
                                'Já existe um usuário cadastrado para o CPF informado.'
                        ) {
                            setShowAlert({
                                status: true,
                                message: res.data.Messages[0],
                                type: 'danger'
                            })
                        } else {
                            setShowAlert({
                                status: true,
                                message: 'Cadastrado com sucesso',
                                type: 'success'
                            })
                            reset()
                            setLoading(true)
                            setTimeout(() => {
                                setLoading(false)
                                history.push(`/cadastros/usuario`)
                            }, 1000)
                        }
                    })
                    .catch(err => {
                        setMsg(err?.data?.Messages)
                        setShowAlert({
                            status: true,
                            message: 'Erro ao realizar cadastro',
                            type: 'danger'
                        })
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                FormRef.current?.setErrors(erros)
            }
        }
    }
    return (
        <>
            <BlankBoard>
                <Alert
                    message={showAlert.message}
                    status={showAlert.status}
                    type={showAlert.type as any}
                ></Alert>
                <Header
                    title={
                        Id !== undefined
                            ? 'Alteração de Usuário'
                            : 'Cadastro de Usuário'
                    }
                    actionArrowClick="/cadastros/usuario"
                ></Header>
                <Center>
                    <Subtitle>Dados do convidado</Subtitle>
                </Center>
                {administrador ? (
                    <Form ref={FormRef} onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <Input
                                    name="Nome"
                                    label="Nome"
                                    required={true}
                                    placeholder="Digite o nome"
                                ></Input>
                            </div>
                            <div className="col-12 col-sm-6">
                                <Input
                                    name="Email"
                                    label="E-mail"
                                    required={true}
                                    placeholder="Digite o email"
                                ></Input>
                            </div>
                            <div className="col-12 col-sm-5">
                                <DatePicker
                                    required={true}
                                    name="DataNascimento"
                                    label="Data de Nascimento"
                                ></DatePicker>
                            </div>
                            <div className="col12 col-sm-1">
                                <InputMask
                                    type="text"
                                    mask="99"
                                    name="DDD"
                                    label="DDD"
                                    required={true}
                                    placeholder="DDD"
                                    inputRef={dddRef}
                                ></InputMask>
                            </div>
                            <div className="col12 col-sm-6">
                                <InputMask
                                    mask="99999-9999"
                                    name="Telefone"
                                    label="Telefone"
                                    required={true}
                                    placeholder="Digite o número de telefone"
                                    inputRef={telefoneRef}
                                ></InputMask>
                            </div>
                            <div className="col12 col-sm-5">
                                <Select
                                    name="Genero"
                                    className="scale-in-ver-top"
                                    options={options}
                                    required={true}
                                    placeholder="Selecione o genero"
                                ></Select>
                            </div>
                            <div className="col-12 col-sm-2">
                                <Switch
                                    required={true}
                                    checked={status}
                                    onChange={() => {
                                        setStatus(!status)
                                    }}
                                    name="Status"
                                    label="Status"
                                ></Switch>

                                <Switch
                                    defaultChecked={switchAdministrador}
                                    checked={switchAdministrador}
                                    onChange={() => {
                                        setSwitchAdministrador(
                                            !switchAdministrador
                                        )
                                    }}
                                    name="Administrador"
                                    label="Administrador"
                                ></Switch>
                            </div>
                            <div className="col-12 col-sm-2">
                                <Switch
                                    defaultChecked={aprovadorDeViagens}
                                    checked={aprovadorDeViagens}
                                    onChange={() => {
                                        setAprovadorDeViagens(
                                            !aprovadorDeViagens
                                        )
                                    }}
                                    name="AprovaViagem"
                                    label="Aprovador de viagens"
                                ></Switch>

                                <Switch
                                    defaultChecked={aprovadorDePrestacao}
                                    checked={aprovadorDePrestacao}
                                    onChange={() => {
                                        setAprovadorDePrestacao(
                                            !aprovadorDePrestacao
                                        )
                                    }}
                                    name="AprovaPrestacao"
                                    label="Aprovador de prestação"
                                ></Switch>
                            </div>
                            <div className="col-12 col-sm-2">
                                <Switch
                                    defaultChecked={agenteDeViagens}
                                    checked={agenteDeViagens}
                                    onChange={() => {
                                        setAgenteDeViagens(!agenteDeViagens)
                                    }}
                                    name="AgenteViagem"
                                    label="Agente de viagens"
                                ></Switch>
                            </div>
                            <LineSeparator></LineSeparator>
                            <Center>
                                <Subtitle>Documentos</Subtitle>
                            </Center>
                            <div className="col12 col-sm-4">
                                <InputMask
                                    mask="999-999-999-99"
                                    name="Cpf"
                                    label="CPF"
                                    required={true}
                                    placeholder="Digite o número do CPF"
                                    inputRef={cpfRef}
                                ></InputMask>
                            </div>
                            <div className="col12 col-sm-4">
                                <Input
                                    name="Rg"
                                    label="RG"
                                    required={false}
                                    placeholder="Digite o número do RG"
                                ></Input>
                            </div>
                            <div className="col12 col-sm-4">
                                <Input
                                    name="Cnh"
                                    label="CNH"
                                    placeholder="Digite o número da CNH"
                                ></Input>
                            </div>
                            <div className="col12 col-sm-8">
                                <Input
                                    name="Passaporte"
                                    label="Passaporte"
                                    required={false}
                                    placeholder="Digite o número do Passaporte"
                                ></Input>
                            </div>
                            <div className="col12 col-sm-4">
                                <DatePicker
                                    required={false}
                                    name="ValidadePassaporte"
                                    label="Validade do Passaporte"
                                ></DatePicker>
                            </div>

                            {msg.length > 0 && (
                                <div
                                    style={{
                                        background: '#f8d7da',
                                        color: '#000 !important',
                                        borderRadius: '8px',
                                        padding: '10px'
                                    }}
                                >
                                    {msg.map((res, index) => {
                                        return (
                                            <p
                                                style={{
                                                    color: '#842029'
                                                }}
                                                key={index}
                                            >
                                                {res}
                                            </p>
                                        )
                                    })}
                                </div>
                            )}

                            <Space height="4rem"></Space>
                            <Center>
                                <DisableForMobile>
                                    <Button
                                        colorType="quaternary"
                                        icon={
                                            Id !== undefined ? 'check' : 'plus'
                                        }
                                    >
                                        {Id !== undefined
                                            ? 'Salvar'
                                            : 'Cadastrar'}
                                    </Button>
                                </DisableForMobile>
                            </Center>
                            <Center>
                                <IsMobile>
                                    <Button
                                        colorType="btnMobile"
                                        icon={
                                            Id !== undefined ? 'check' : 'plus'
                                        }
                                    >
                                        {Id !== undefined
                                            ? 'Salvar'
                                            : 'Cadastrar'}
                                    </Button>
                                </IsMobile>
                            </Center>
                        </div>
                    </Form>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                )}
            </BlankBoard>
        </>
    )
}

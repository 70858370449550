import React, { useContext, useEffect, useState } from 'react'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Header } from '../../../../core/components/Header/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Center } from '../../../../core/components/Center'
import { Resumo } from './Resumo'
import { Configuracao } from './Configuracao'
import { TipoSolicitacao } from './TipoSolicitacao'
import styled from 'styled-components'
import { get } from '../../../../services/http'
import { ENDPOINTS } from '../../../../services/config-http'
import { useParams } from 'react-router'
import checklistImg from '../../../../core/assets/checklist-checkmark.svg'
import { UserContext } from '../../../../contexts/UserContext'
import { Alert } from '../../../../core/components/Alert'

export const PoliticaPermissao = () => {
    const [tab, setTab] = useState(0)
    const [nomePolitica, setNomePolitica] = useState('')
    const { Id } = useParams() as any

    const [administrador, setAdministrador] = useState(false)
    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        const buscarPolitica = async () => {
            await get(`${ENDPOINTS.politicaViagem}/${Id}`)
                .then(({ data: { Model } }) => {
                    const { Nome } = Model
                    setNomePolitica(Nome)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        buscarPolitica()
    }, [])
    return (
        <BlankBoard>
            <Header
                title="Regras para validação de despesas"
                actionArrowClick="/solicitacoes/configuracao/politicas"
            ></Header>
            <Center>
                {tab === 0 ? (
                    <h2>Resumo</h2>
                ) : tab === 1 ? (
                    <h2>Configuração de regra de políticas</h2>
                ) : (
                    <h2>Tipo de Solicitação da Política</h2>
                )}
            </Center>
            <ul
                className="nav nav-tabs"
                id="myTab"
                role="tablist"
                style={{ margin: '0vh 5vh' }}
            >
                <li className="nav-item">
                    <button
                        style={{
                            fontSize: '1.2rem'
                        }}
                        className="nav-link active"
                        id="resumo-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#resumo"
                        type="button"
                        role="tab"
                        aria-controls="resumo"
                        aria-selected="true"
                        onClick={e => setTab(0)}
                    >
                        <FontAwesomeIcon icon="bars" />
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        style={{
                            fontSize: '1.2rem'
                        }}
                        className="nav-link"
                        id="regra-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#regra"
                        type="button"
                        role="tab"
                        aria-controls="regra"
                        aria-selected="true"
                        onClick={e => setTab(1)}
                    >
                        <FontAwesomeIcon icon="cogs" />
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        style={{
                            fontSize: '1.2rem'
                        }}
                        className="nav-link"
                        id="tipo-solicitacao-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#tipo-solicitacao"
                        type="button"
                        role="tab"
                        aria-controls="tipo-solicitacao"
                        aria-selected="true"
                        onClick={e => setTab(2)}
                    >
                        <FontAwesomeIcon icon="check-double" />
                    </button>
                </li>
            </ul>
            {administrador ? (
                <article className="tab-content" id="myTabContent">
                    <div
                        id="resumo"
                        className="tab-pane fade show active"
                        role="tabpanel"
                        aria-labelledby="resumo-tab"
                    >
                        <Infos>
                            <Resumo nomePolitica={nomePolitica} />
                        </Infos>
                    </div>
                    <div
                        id="regra"
                        className="tab-pane fade show"
                        role="tabpanel"
                        aria-labelledby="regra-tab"
                    >
                        <Infos>
                            <Configuracao
                                nomePolitica={nomePolitica}
                                idPolitica={Id}
                            />
                        </Infos>
                    </div>
                    <div
                        id="tipo-solicitacao"
                        className="tab-pane fade show"
                        role="tabpanel"
                        aria-labelledby="tipo-solicitacao-tab"
                    >
                        <Infos>
                            <TipoSolicitacao
                                nomePolitica={nomePolitica}
                                idPolitica={Id}
                            />
                        </Infos>
                    </div>
                </article>
            ) : (
                <>
                    <br />
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                </>
            )}
        </BlankBoard>
    )
}
const Infos = styled.div`
    height: auto;
    margin: 0 7vh;
    padding: 3.5vh;
`

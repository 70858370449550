import React, { useRef, useEffect, useState } from 'react'
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select'
import { useField } from '@unform/core'
import { Label } from 'reactstrap'
import { get } from '../../../../services/http'
import { SelectMapper } from '../../../utils/SelectMapper'
import { UrlMountQuery } from '../../../utils/UrlMountQuery'
import { debounce } from 'lodash'

interface Props extends SelectProps<OptionTypeBase> {
    name: string
    required?: boolean
    request: RequestOptions
}

export type RequestOptions = {
    endpoint: string
    firstLabelToSelect: string
    secoundLabelToSelect: string
    valuesToSelect: Array<string>
    queryTextFilter: string
    queryNumberFilter: string
}

export const AutoCompleteSelect = ({
    name,
    required = false,
    label,
    request,
    ...rest
}: Props) => {
    const { fieldName, defaultValue, registerField, error } = useField(name)
    const [mappedOptions, setMappedOptions] = useState([])
    const [options, setOptions] = useState<Array<any>>([])
    const selectRef = useRef(null)
    const [textPlaceholder, setTextPlaceholder] = useState('Digite aqui')

    useEffect(() => {
        initSelectRegister()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldName, registerField, rest.isMulti, options])

    const requester = (text = '') => {
        text.trim()
        if (text.length >= 3) {
            setTextPlaceholder(`Buscando: ${text}`)
            const filter = isNaN(+text)
                ? request.queryTextFilter
                : request.queryNumberFilter

            get(UrlMountQuery({ [filter]: text }, request.endpoint)).then(
                ({ data }) => {
                    let response: Array<any> = data.Model
                        ? data.Model
                        : data.Items // verificando qual tipo de nomeclatura do retorno, Model ou Items.
                    response = response.map((item, index) => ({
                        ...item,
                        reactID: index
                    }))
                    setMappedOptions(
                        SelectMapper(
                            JSON.parse(JSON.stringify(response)),
                            request.firstLabelToSelect,
                            'reactID',
                            request.secoundLabelToSelect
                        )
                    )
                    setTextPlaceholder(`Selecione`)
                    setOptions([...response])
                }
            )
        }
    }

    const handleTextInput = debounce(text => {
        requester(text)
    }, 500)

    const initSelectRegister = () => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref: any) => {
                if (rest.isMulti) {
                    if (!ref?.state?.value) {
                        return []
                    }
                    return ref.state.value.map(
                        (option: OptionTypeBase) => option.value
                    )
                } else {
                    if (!ref?.state?.value) {
                        return ''
                    }
                    if (Array.isArray(ref?.state?.value)) {
                        return ref?.state?.value[0]?.value
                    }
                    const value = ref?.state?.value?.value
                    if (ref?.state?.value && options.length) {
                        const selected = options.find(
                            (i: any) => i.reactID === value
                        )
                        return getProperties(selected)
                    }
                    return value
                }
            },
            setValue: (ref: any, value: any) => {
                if (rest.isMulti && Array.isArray(value)) {
                    const items = ref?.props?.options?.filter((option: any) =>
                        value.includes(option.value)
                    )
                    ref?.select.setValue(items)
                } else {
                    const item = ref?.props?.options?.filter(
                        (option: any) => option.value === value
                    )
                    if (item && item.length > 0) {
                        ref?.select?.setValue(item)
                    }
                }
            },
            clearValue: ref => {
                ref?.select?.setValue('')
            }
        })
    }

    const getProperties = (data: any) => {
        const selected: any = {}
        request.valuesToSelect.forEach((name: string) => {
            selected[name] = data[name]
        })
        return selected
    }

    return (
        <div style={{ margin: '1rem 0' }}>
            <Label for={name}>
                <p>
                    {label} {required ? '*' : ''}
                </p>
            </Label>
            <ReactSelect
                menuPortalTarget={document.body}
                placeholder={textPlaceholder}
                name={name}
                id={name}
                options={mappedOptions}
                defaultValue={
                    defaultValue &&
                    mappedOptions.find(
                        (option: OptionTypeBase) =>
                            option.value === defaultValue
                    )
                }
                onInputChange={text => handleTextInput(text)}
                ref={selectRef}
                classNamePrefix="react-select"
                styles={{
                    control: props => ({
                        ...props,
                        border: error ? 'solid 1px #F66D65 !important' : ''
                    })
                }}
                theme={theme => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary: '#73A4EE'
                    }
                })}
                {...rest}
            />
            <span className="danger">{error}</span>
        </div>
    )
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Servico } from '../../../../contexts/interfaces/servico'
import { Center } from '../../../../core/components/Center'
import { Button } from '../../../../core/components/Button'
import { Form } from '@unform/web'
import { Header } from '../../../../core/components/Header/Header'
import { Input } from '../../../../core/components/Form/Input'
import { Subtitle } from '../../../../core/components/Subtitle'
import { Switch } from '../../../../core/components/Switch'
import { Space } from '../../../../core/components/Space'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { ENDPOINTS } from '../../../../services/config-http'
import { post } from '../../../../services/http'
import { Toast } from '../../../../core/components/Toast'
import { Alert } from '../../../../core/components/Alert'
import { UserContext } from '../../../../contexts/UserContext'
import { useHistory } from 'react-router'
import {
    DisableForMobile,
    IsMobile
} from '../../../Viagem/Solicitacao/stepMobile'

export const CadastroServico = () => {
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const [status, setStatus] = useState(true)
    const [exclusivoViagemGrupo, setExclusivoViagemGrupo] = useState(false)
    const [cotavel, setCotavel] = useState(false)

    const [showToast, setShowToast] = useState(false)
    const [administrador, setAdministrador] = useState(false)
    const dadosUsuario = useContext(UserContext)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    const novoServido: SubmitHandler<Servico> = async (data, { reset }) => {
        try {
            // Esquema de Validação
            const schema = Yup.object().shape({
                DescricaoServico: Yup.string().required(
                    'O Campo Nome é descricao'
                )
            })
            // Verificação - Caso exista algum erro, o fluxo cai no catch que fará a amostragem dos erros na tela
            await schema.validate(data, { abortEarly: false })

            // Caso exista erros, mas passou na validação do schema, ele limpa o objeto
            formRef.current?.setErrors({})

            // reinicia o form com campos vazios
            // reset()
            post(ENDPOINTS.servico, {
                Descricao: data.DescricaoServico,
                Cotavel: cotavel ? 1 : 0,
                ExclusivoViagemGrupo: exclusivoViagemGrupo ? 1 : 0,
                status: status ? 1 : 0
            })
                .then(() => {
                    setShowToast(true)
                    setToast({
                        message: 'Servico cadastrada!',
                        type: 'success'
                    })
                    reset()
                    history.push(`/cadastros/servico`)
                })
                .catch(({ data }) => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao tentar cadastrar nova servico',
                        type: 'error'
                    })
                })
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                // Caso exista erros, o MapFormErrors vai mapear o objeto para enviarmos ao unform
                const errors = MapFormErrors(err.inner)
                // Mostrar erros na tela
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <div>
            <BlankBoard>
                <Header
                    title="Cadastro de serviço"
                    actionArrowClick="/cadastros/servico"
                ></Header>
                <Center>
                    <Subtitle>Dados do serviço</Subtitle>
                </Center>
                {administrador ? (
                    <Form ref={formRef} onSubmit={novoServido}>
                        <div className="row">
                            <div className="col12 col-sm-4">
                                <Input
                                    name="DescricaoServico"
                                    label="Descricao"
                                    required={true}
                                    placeholder="Digite a descricao"
                                ></Input>
                            </div>
                            <div className="col12">
                                <Switch
                                    defaultChecked={status}
                                    onChange={() => {
                                        setStatus(!status)
                                    }}
                                    name="Status"
                                    label="Status"
                                ></Switch>
                            </div>
                            <div className="col12">
                                <Switch
                                    defaultChecked={cotavel}
                                    onChange={() => {
                                        setCotavel(!cotavel)
                                    }}
                                    name="Cotavel"
                                    label="Cotavel"
                                ></Switch>
                            </div>
                            <div className="col12">
                                <Switch
                                    defaultChecked={exclusivoViagemGrupo}
                                    onChange={() => {
                                        setExclusivoViagemGrupo(
                                            !exclusivoViagemGrupo
                                        )
                                    }}
                                    name="ExclusivoViagemGrupo"
                                    label="Exclusivo viagem grupo"
                                ></Switch>
                            </div>
                            <Space height="3rem"></Space>
                            <Center>
                                <DisableForMobile>
                                    <Button icon="plus" colorType="quaternary">
                                        Cadastrar
                                    </Button>
                                </DisableForMobile>
                                <IsMobile>
                                    <Button icon="plus" colorType="btnMobile">
                                        Cadastrar
                                    </Button>
                                </IsMobile>
                            </Center>
                        </div>
                    </Form>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                )}
                {showToast ? (
                    <Toast
                        setShow={setShowToast}
                        message={toast.message}
                        type={toast.type}
                    />
                ) : null}
            </BlankBoard>
        </div>
    )
}

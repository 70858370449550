import styled from 'styled-components'

export const BlankBoard = styled.div`
    margin: 5rem 2rem 3rem 2rem;
    background-color: ${props => props.theme.colors.white};
    border-radius: 14px;
    width: auto;
    min-height: 90vh;
    padding: 3rem;

    @media screen and (max-width: 720px) {
        min-height: 100vh;
        margin: 0;
        padding: 3.5rem 1.5rem;
    }
`

import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from '@unform/web'
import { LineSeparator } from '../../../../core/components/LineSeparator'
import { FormHandles, SubmitHandler } from '@unform/core'
import './modaltiposolicitacao.css'
import { Button } from '../../../../core/components/Button'
import { post, put } from '../../../../services/http'
import { ENDPOINTS } from '../../../../services/config-http'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { Toast } from '../../../../core/components/Toast'
import * as Yup from 'yup'
import { Input } from '../../../../core/components/Form/Input'

// tipo = 1 => Criar Novo tipoSolicitacao
// tipo = 2 => Alterar tipoSolicitacao

interface TipoSolicitacaoProp {
    tipo: number
    show?: boolean
    setShow: any
    idTipoSolicitacao: number
    nomeTipoSolicitacao: string
    statusTipoSolicitacao?: boolean
}

export const ModalTipoSolicitacao = ({
    tipo,
    show,
    setShow,
    idTipoSolicitacao,
    nomeTipoSolicitacao,
    statusTipoSolicitacao
}: TipoSolicitacaoProp) => {
    const formRef = useRef<FormHandles>(null)
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    const handleSubmit: SubmitHandler = async (data: any) => {
        const { nome } = data

        try {
            const schema = Yup.object().shape({
                nome: Yup.string().required('O Campo Nome é obrigatório')
            })

            // Verificação - Caso exista algum erro, o fluxo cai no catch que fará a amostragem dos erros na tela
            await schema.validate(data, { abortEarly: false })

            if (tipo === 1) {
                await post(ENDPOINTS.tipoSolicitacao, {
                    Nome: nome
                })
                    .then(() => {
                        setShow(false)
                        setShowToast(true)
                        setToast({
                            message: 'Tipo Solicitação cadastrado com sucesso',
                            type: 'success'
                        })
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000)
                    })
                    .catch(() => {
                        setShowToast(true)
                        setToast({
                            message: `Erro interno do sistema`,
                            type: 'error'
                        })
                    })
            } else {
                await put(ENDPOINTS.tipoSolicitacao, {
                    Id: idTipoSolicitacao,
                    Nome: nome,
                    Status: statusTipoSolicitacao
                })
                    .then(() => {
                        setShow(false)
                        setShowToast(true)
                        setToast({
                            message: 'Tipo Solicitação alterado com sucesso',
                            type: 'success'
                        })
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000)
                    })
                    .catch(() => {
                        setShowToast(true)
                        setToast({
                            message: `Erro interno do sistema`,
                            type: 'error'
                        })
                    })
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                formRef.current?.setErrors(erros)
            }
        }
    }

    useEffect(() => {
        if (show) {
            formRef.current?.reset()
            document
                .getElementsByClassName('modal-tipo-solicitacao')[0]
                ?.classList.remove('hidden')
        } else {
            document
                .getElementsByClassName('modal-tipo-solicitacao')[0]
                ?.classList.add('hidden')
        }

        console.log(idTipoSolicitacao)
        console.log(nomeTipoSolicitacao)

        formRef.current?.setFieldValue('nome', nomeTipoSolicitacao)
    }, [show, idTipoSolicitacao, nomeTipoSolicitacao])

    return (
        <>
            <div className="modal-tipo-solicitacao">
                <div
                    style={{
                        borderRadius: '0.5rem',
                        padding: '1rem',
                        marginTop: '3.5rem',
                        backgroundColor: 'white',
                        width: '60rem',
                        height: '22rem'
                    }}
                >
                    <div className="modal-tipo-solicitacao-header">
                        <div style={{ width: '5%' }}></div>
                        <h1
                            style={{
                                fontWeight: 'bold',
                                color: '#24538B',
                                width: '90%',
                                textAlign: 'center'
                            }}
                        >
                            {tipo === 1 ? 'Novo ' : 'Alterar '}
                            Tipo Solicitação
                        </h1>
                        <button
                            onClick={e => setShow(false)}
                            className="close-modal-tipo-solicitacao"
                            style={{
                                width: '5%',
                                textAlign: 'center'
                            }}
                        >
                            <FontAwesomeIcon icon="times" />
                        </button>
                    </div>
                    <div className="modal-tipo-solicitacao-body">
                        <LineSeparator
                            style={{ margin: '.3rem 2rem' }}
                        ></LineSeparator>
                        <Form
                            onSubmit={handleSubmit}
                            ref={formRef}
                            style={{ padding: 0 }}
                        >
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <Input
                                        name="nome"
                                        label="Nome"
                                        placeholder="Digite o nome do tipo solicitação..."
                                        required={true}
                                    ></Input>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '2rem'
                                }}
                            >
                                <Button
                                    icon="save"
                                    type="submit"
                                    colorType="quaternary"
                                >
                                    Salvar
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </>
    )
}

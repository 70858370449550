import React, { useRef, useEffect } from 'react'
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select'
import { useField } from '@unform/core'
import { Label } from 'reactstrap'
import styled from 'styled-components'

interface Props extends SelectProps<OptionTypeBase> {
    name: string
    required?: boolean
    options?: Options[]
    defaultOption?: any
}

type Options = {
    value: string
    label: string
}

export const Select = ({
    name,
    options = [],
    required = false,
    label = name,
    defaultOption,
    ...rest
}: Props) => {
    const selectRef = useRef(null)
    const { fieldName, defaultValue, registerField, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref: any) => {
                if (rest.isMulti) {
                    if (!ref?.state?.value) {
                        return []
                    }
                    return ref.state.value.map(
                        (option: OptionTypeBase) => option.value
                    )
                } else {
                    if (!ref?.state?.value) {
                        return ''
                    }
                    if (Array.isArray(ref?.state?.value)) {
                        return ref?.state?.value[0]?.value
                    }
                    return ref?.state?.value?.value
                }
            },
            setValue: (ref: any, value: any) => {
                if (rest.isMulti && Array.isArray(value)) {
                    const items = ref?.props?.options?.filter((option: any) =>
                        value.includes(option.value)
                    )
                    ref?.select.setValue(items)
                } else {
                    const item = ref?.props?.options?.filter(
                        (option: any) => option.value === value
                    )
                    if (item && item.length > 0) {
                        ref?.select?.setValue(item)
                    }
                }
            }
        })
    }, [fieldName, registerField, rest.isMulti])

    return (
        <div style={{ margin: '1rem 0' }}>
            <Label for={name}>
                <Text>
                    {label} {required ? '*' : ''}
                </Text>
            </Label>
            <ReactSelect
                menuPortalTarget={document.body}
                name={name}
                id={name}
                options={options}
                defaultValue={
                    defaultOption &&
                    options.find(option => option.value == defaultOption)
                }
                ref={selectRef}
                classNamePrefix="react-select"
                styles={{
                    control: props => ({
                        ...props,
                        border: error ? 'solid 1px #F66D65 !important' : ''
                    })
                }}
                theme={theme => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary: '#73A4EE'
                    }
                })}
                {...(rest as any)}
            />
            <span className="danger">{error}</span>
        </div>
    )
}

const Text = styled.p`    
font-size: 0.9rem;
font-weight: 800;
color: '#6C7480',
font: 'Montserrat, sas-serif'
`

import { Form } from '@unform/web'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { TipoDespesa } from '../../../../contexts/interfaces/tipoDespesa'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Button } from '../../../../core/components/Button'
import { Center } from '../../../../core/components/Center'
import { Description } from '../../../../core/components/Description'
import { Input } from '../../../../core/components/Form/Input'
import { Select } from '../../../../core/components/Form/Select'
import { Grid } from '../../../../core/components/Grid'
import { Header } from '../../../../core/components/Header/Header'
import { LineSeparator } from '../../../../core/components/LineSeparator'
import { Subtitle } from '../../../../core/components/Subtitle'
import { Switch } from '../../../../core/components/Switch'
import { Alert } from '../../../../core/components/Alert'
import { UserContext } from '../../../../contexts/UserContext'
import { post, put } from '../../../../services/http'
import { ENDPOINTS } from '../../../../services/config-http'
import { useAlert } from '../../../../hooks/AlertToast'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import {
    TableRequester,
    MapperItem,
    ConfigTable,
    Table
} from './../../../../core/components/Table/v2/Table'

import { useHistory } from 'react-router'
import { MoneyFormat } from '../../../../core/utils/MoneyFormat'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { InputMoney } from '../../../../core/components/Form/v3/InputMoney'
import { formataValor } from '../../../../util/legenda/util'
import { IntlRealFormat } from '../../../../core/utils/IntlFormat'
import { Flex } from '../../../../core/components/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    DisableForMobile,
    IsMobile
} from '../../../Viagem/Solicitacao/stepMobile'

const StatusOptions = [
    {
        value: '',
        label: 'TODOS'
    },
    {
        value: '2',
        label: 'ATIVO'
    },
    {
        value: '1',
        label: 'INATIVO'
    }
]

const QuantificacaoOptionsFilter = [
    {
        value: '',
        label: 'TODOS'
    },
    {
        value: 'Valor',
        label: 'VALOR'
    },
    {
        value: 'Quantidade',
        label: 'QUANTIDADE'
    }
]

const Despesa = () => {
    const [selectedTipoDespesa, setSelectedTipoDespesa] =
        useState<TipoDespesa>()
    const FormRef = useRef<FormHandles>(null)
    const [numeroNota, setNumeroNota] = useState(false)
    const [anexo, setAnexo] = useState(false)
    const [justificativa, setJustificativa] = useState(false)
    const { setAlert, showAlert } = useAlert()
    const history = useHistory()
    const [payloadSearch, setPayloadSearch] = useState<TableRequester>({
        endpoint: ENDPOINTS.tipoDespesa,
        method: 'GET',
        payload: {}
    })

    const mapper: MapperItem[] = [
        { field: 'Id', position: 1 },
        { field: 'Descricao', replaceTo: 'Descrição', position: 2 },
        { field: 'Quantificacao', replaceTo: 'Quantificação', position: 3 },
        {
            field: 'ValorPadrao',
            replaceTo: 'Valor padrão',
            visualCustom: _pipeValor,
            position: 4
        },
        { field: 'NecessitaNumeroNota', delete: true },
        { field: 'NecessitaAnexo', delete: true },
        { field: 'NecessitaJustificativa', delete: true },
        {
            field: 'Status',
            visualCustom: _pipeStatus,
            position: 5
        }
    ]
    const tableConfig: ConfigTable = {
        edit: false,
        delete: false,
        moreOptions: [
            {
                label: ' Editar',
                icon: 'file-signature',
                color: '#002A5D',
                action: (item: any) => {
                    history.push(`/cadastros/tipos-despesa/${item.Id}/editar`)
                }
            },
            {
                label: ' Desabilitar',
                icon: 'file-signature',
                color: '#002A5D',
                conditional: (option: string, item: any) => {
                    return item.Status === true
                },
                action: (item: any) => {
                    alterarStatus(item, false)
                }
            },
            {
                label: ' Habilitar',
                icon: 'file-signature',
                color: '#002A5D',
                conditional: (option: string, item: any) => {
                    return item.Status === false
                },
                action: (item: any) => {
                    alterarStatus(item, true)
                }
            }
        ]
    }

    const alterarStatus = (item: any, param: boolean) => {
        put(ENDPOINTS.editartipoDespesa, {
            id: item.Id,
            status: param,
            descricao: item.Descricao,
            quantificacao: item.Quantificacao,
            valorPadrao: item.ValorPadrao
        })
            .then(() => {
                setAlert({
                    type: 'success',
                    time: 5000,
                    title: 'Sucesso!',
                    subTitle: `Despesa foi ${
                        param ? 'hbilitada' : 'desabilitada'
                    }`
                })
                showAlert(true)
                history.push(`/cadastros/tipos-despesa`)
            })
            .catch(({ data }) => {
                setAlert({
                    type: 'error',
                    time: 5000,
                    title: 'Erro!',
                    subTitle: `Não foi possivel ${
                        param ? 'Habilitar' : 'Desabilitar'
                    } despesa`
                })
                showAlert(true)
            })
    }

    const handleSubmit: SubmitHandler = async (tipoDespesa: TipoDespesa) => {
        try {
            const schema = Yup.object().shape({
                Descricao: Yup.string().required('O Campo valor é obrigatório'),
                Quantificacao: Yup.string().required(
                    'O Campo descrição é obrigatório'
                ),
                ValorPadrao: Yup.string().required(
                    'O Campo descrição é obrigatório'
                )
            })

            await schema.validate(tipoDespesa, { abortEarly: false })
            tipoDespesa.Status = true
            tipoDespesa.NecessitaNumeroNota = numeroNota
            tipoDespesa.NecessitaAnexo = anexo
            tipoDespesa.NecessitaJustificativa = justificativa
            if (tipoDespesa.Quantificacao == '1')
                tipoDespesa.Quantificacao = 'Quantidade'
            else tipoDespesa.Quantificacao = 'Valor'
            showAlert(false)
            post(ENDPOINTS.tipoDespesaIncluir, {
                Status: tipoDespesa?.Status,
                Descricao: tipoDespesa?.Descricao,
                Quantificacao: tipoDespesa?.Quantificacao,
                ValorPadrao: formataValor(tipoDespesa?.ValorPadrao),
                NecessitaNumeroNota: tipoDespesa?.NecessitaNumeroNota,
                NecessitaAnexo: tipoDespesa?.NecessitaAnexo,
                NecessitaJustificativa: tipoDespesa?.NecessitaJustificativa
            })
                .then(_ => {
                    setAlert({
                        type: 'success',
                        time: 5000,
                        title: 'Sucesso!',
                        subTitle: 'Tipo de despesa incluída!'
                    })
                    showAlert(true)
                    setSelectedTipoDespesa(undefined)
                })
                .catch(err => console.error(err))
        } catch (err) {
            console.log(err)
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                FormRef.current?.setErrors(erros)
            }
        }
    }
    const [administrador, setAdministrador] = useState(false)

    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    function _pipeValor(value: number, index: number): JSX.Element {
        return <>{IntlRealFormat(value || 0)}</>
    }

    function _pipeStatus(value: boolean): JSX.Element {
        if (value) {
            return (
                <span
                    className="badge status-success"
                    style={{ width: '75px', margin: 'auto' }}
                >
                    <FontAwesomeIcon icon="circle" />
                    &ensp; {'ATIVO'}
                </span>
            )
        } else {
            return (
                <span
                    className="badge status-danger"
                    style={{ width: '75px', margin: 'auto' }}
                >
                    <FontAwesomeIcon icon="circle" />
                    &ensp; {'INATIVO'}
                </span>
            )
        }
    }

    const handleFilterSubmit: SubmitHandler<FormData> = (data: any) => {
        Object.keys(data).forEach(
            k => !data[k] && data[k] !== undefined && delete data[k] // eliminando valores não preenchidos para envio da query de busca
        )
        data.page = 0
        data.lastCount = 0
        data.totalRegisterPage = 10
        data.IdSituacao = 6
        const newPayloadSearch = { ...payloadSearch, payload: data }
        setPayloadSearch(newPayloadSearch)
    }
    return (
        <div>
            <BlankBoard>
                <Header
                    title="Lista tipo de despesa"
                    actionArrowClick="/solicitacoes"
                ></Header>
                <Center>
                    <Subtitle>Dados da Despesa</Subtitle>
                </Center>
                <Flex justifyContent="flex-end">
                    <DisableForMobile>
                        <Button
                            style={{
                                marginRight: '-3.2rem'
                            }}
                            icon={'plus'}
                            colorType="quaternary"
                            onClick={() =>
                                history.push(
                                    '/cadastros/tipos-despesa/Cadastro'
                                )
                            }
                        >
                            Cadastrar
                        </Button>
                    </DisableForMobile>
                </Flex>
                <Center>
                    <IsMobile>
                        <Button
                            icon={'plus'}
                            colorType="btnMobile"
                            onClick={() =>
                                history.push(
                                    '/cadastros/tipos-despesa/Cadastro'
                                )
                            }
                        >
                            Cadastrar
                        </Button>
                    </IsMobile>
                </Center>
                {administrador ? (
                    <>
                        <LineSeparator></LineSeparator>
                        <Form
                            onSubmit={handleFilterSubmit}
                            ref={FormRef}
                            style={{ padding: 0 }}
                        >
                            <div className="row">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: '1rem'
                                    }}
                                >
                                    <Button
                                        style={{
                                            margin: '0 .2rem',
                                            padding: '0 15px'
                                        }}
                                        onlyIcon={true}
                                        icon="search"
                                        title="Pesquisar"
                                        colorType="primary"
                                    ></Button>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <Input
                                        placeholder="Descrição"
                                        label="Descrição"
                                        name="Descricao"
                                        maxLength={50}
                                    ></Input>
                                </div>

                                <div className="col-12 col-sm-4">
                                    <Select
                                        name="Status"
                                        options={StatusOptions}
                                        placeholder="Selecione o status"
                                    ></Select>
                                </div>

                                <div className="col-12 col-sm-4">
                                    <Select
                                        name="Quantificacao"
                                        label="Quantificação"
                                        options={QuantificacaoOptionsFilter}
                                        placeholder="Selecione"
                                    ></Select>
                                </div>

                                <div
                                    className="col-12 col-sm-1"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                ></div>
                            </div>
                        </Form>
                        <Table
                            requester={payloadSearch}
                            mapper={mapper}
                            config={tableConfig}
                        ></Table>
                    </>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                )}
            </BlankBoard>
        </div>
    )
}

export default Despesa

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Button } from '../../../../core/components/Button'
import { Disclaimer } from '../../../../core/components/Disclaimer'
import { Input } from '../../../../core/components/Form/Input'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { ENDPOINTS } from '../../../../services/config-http'
import { Center } from '../../../../core/components/Center'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { Rateio } from '../../../../contexts/interfaces/rateio'
import { useGlobalStates } from '../../../../state/InitialState'
import { Header } from '../../../../core/components/Header/Header'
import { StepByStep } from '../../../../core/components/StepByStep'
import { Subtitle } from '../../../../core/components/Subtitle'
import { useHistory } from 'react-router-dom'
import { StepByStepItem } from '../../../../contexts/interfaces/_stepByStep'
import { Space } from '../../../../core/components/Space'
import { SOLICITACAO_VIAGEM } from '../../../../state/SolicitacaoViagem/Actions'
import { get } from '../../../../services/http'
import { Solicitacao } from '../../../../contexts/interfaces/solicitacao'
import { Empty } from '../../../../core/components/Empty'
import styled from 'styled-components'
import { ContainerPadding } from '../../../../core/components/ContainerPadding'
import { Deletar } from '../../../../core/components/Deletar'
import { SelectMapper } from '../../../../core/utils/SelectMapper'
import { Select } from '../../../../core/components/Form/Select'
import useWindowDimensions from '../../../../core/components/GetDimensions'
import { Alert } from '../../../../core/components/Alert'
import { DisableForMobile, IsMobile } from '../stepMobile'

export const RatioForm = () => {
    const {
        state: { solicitacao },
        setGlobalState
    } = useGlobalStates()
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const [totalPercent, setTotalPercent] = useState<number>(0)
    const [rateios, setRateios] = useState<Array<any>>([])
    const [filiais, setFilial] = useState<Array<any>>([])
    const [centroDeCusto, setCentroDeCusto] = useState<Array<any>>([])
    const [centroDeCustoInput, setCentroDeCustoInput] = useState<Array<any>>([])
    const [messageError, setMessageError] = useState<string>('')
    const [flagErrorForm, setFlagErrorForm] = useState<boolean>(false)
    const [stepByStep, setStepByStep] = useState<StepByStepItem[]>([])
    const indexActiveStep = useRef<number>(0)
    const { width } = useWindowDimensions()

    useEffect(() => {
        if (centroDeCusto) {
            setCentroDeCustoInput(centroDeCusto[0])
        }
    }, [centroDeCusto])

    useEffect(() => {
        const stepItem = localStorage.getItem('stepByStep')
        if (stepItem !== null) {
            const stepItemObj = JSON.parse(stepItem) as StepByStepItem[]
            indexActiveStep.current = stepItemObj.findIndex(
                s => s.name === 'Rateio'
            )
            stepItemObj[indexActiveStep.current].active = true
            for (let index = 0; index < indexActiveStep.current; index++) {
                stepItemObj[index].completed = true
            }
            setStepByStep(stepItemObj)
        }

        get(ENDPOINTS.filial).then(({ data: { Model } }) => {
            setFilial(SelectMapper(Model, 'Codigo', 'Id', 'Nome'))
        })

        get(ENDPOINTS.contasContabeis).then(({ data: { Model } }) => {
            setCentroDeCusto(SelectMapper(Model, 'Codigo', 'Id', 'Nome'))
        })

        let solicitacaoStorage = solicitacao
        if (solicitacao === undefined) {
            const sol = localStorage.getItem('solicitacao')
            if (sol !== null) {
                solicitacaoStorage = JSON.parse(sol) as Solicitacao
                setGlobalState(
                    SOLICITACAO_VIAGEM.REDUCER_NAME,
                    SOLICITACAO_VIAGEM.ACTION_ADD_SOLICITACAO,
                    solicitacaoStorage
                )
            }
        }

        const rateio = solicitacaoStorage?.rateio
        if (rateio && rateio.length) {
            setRateios(rateio)
            let percentual = 0
            rateio.forEach(e => {
                const per = e.Percentual || 0
                percentual += +per
            })
            setTotalPercent(percentual)
        }
    }, [])

    function changeTotalPercent(rateio: any) {
        const newRateios = [...rateios]
        const position = newRateios.findIndex(s => s.Id === rateio.Id)
        newRateios.splice(position, 1)
        setRateios(newRateios)
        setTotalPercent(totalPercent - rateio.Percentual)
    }

    function _pipeShowFilial(value: any) {
        const item = filiais?.filter(item => item?.value === value)[0]
        return <>{item?.label}</>
    }

    function _pipeShowCentroDeCusto(value: number) {
        const item = centroDeCusto?.filter(item => item?.value === value)[0]
        return <>{item?.label}</>
    }

    function _pipeChangePercent(value: number, index: number): JSX.Element {
        const subtractPercent = () => {
            if (value <= 1) return
            const changedRateios: Array<Rateio> = [...rateios]
            changedRateios[index].Percentual = value - 1
            // verificação do novo percentual e o total, deve ser maior ou igual a zero
            setRateios(changedRateios)
            setTotalPercent(totalPercent - 1)
        }
        const addPercent = () => {
            if (value >= 100 || totalPercent >= 100) return
            const changedRateios: Array<Rateio> = [...rateios]
            changedRateios[index].Percentual = +value + 1
            // verificação do novo percentual e o total, deve ser menor ou igual a 100
            setRateios(changedRateios)
            setTotalPercent(totalPercent + 1)
        }

        return (
            <div
                style={{
                    width: '100px',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    fontSize: '0.9rem'
                }}
            >
                <FontAwesomeIcon
                    icon="chevron-up"
                    className="cursor-pointer"
                    color="#8ED6FF"
                    onClick={e => addPercent()}
                />
                <p style={{ margin: 0 }}>
                    <b>{value}</b>%
                </p>
                <FontAwesomeIcon
                    icon="chevron-down"
                    className="cursor-pointer"
                    color="#8ED6FF"
                    onClick={e => subtractPercent()}
                />
            </div>
        )
    }

    const handleFormSubmit: SubmitHandler<Rateio> = async (
        rateio,
        { reset }
    ) => {
        setFlagErrorForm(false)
        const newRateios = [...rateios]
        const hasRateio = newRateios.some(i => i.Id === rateio.Id)
        if (hasRateio) {
            setFlagErrorForm(true)
            setMessageError('Ops! Viajante já adicionado a lista')
        } else {
            try {
                const schema = Yup.object().shape({
                    IdFilial: Yup.string().required(
                        'O Campo Filial é Obrigatório!'
                    ),
                    Percentual: Yup.string()
                        .nullable()
                        .required('O Campo Percentual é Obrigatório!')
                })

                await schema.validate(rateio, { abortEarly: false })

                if (rateio.Percentual == 0) {
                    setMessageError('Percentual de rateio não pode ser zero!')
                    return
                }
                if (
                    rateio.Percentual &&
                    +rateio.Percentual + totalPercent <= 100
                ) {
                    rateio.Id = rateios.length + 1 // adicionado referencia
                    const newRateios = [...rateios]
                    newRateios.push({
                        Id: rateio.Id,
                        IdContasContabeis: centroDeCustoInput?.value,
                        IdFilial: rateio?.IdFilial,
                        Percentual: rateio?.Percentual
                    })
                    setRateios(newRateios)
                    setTotalPercent(+rateio.Percentual + totalPercent)
                } else {
                    setMessageError(
                        'Percentual de rateionão pode ultrapassar 100%!'
                    )
                }

                formRef.current?.setErrors({})

                // reinicia o form com campos vazios
                reset()
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const erros = MapFormErrors(err.inner)

                    formRef.current?.setErrors(erros)
                }
            }
        }
    }

    const finish = () => {
        setGlobalState(
            SOLICITACAO_VIAGEM.REDUCER_NAME,
            SOLICITACAO_VIAGEM.ACTION_ADD_RATEIO,
            rateios
        )

        const sol = localStorage.getItem('solicitacao')
        if (sol !== null) {
            const solicitacaoObj = JSON.parse(sol) as Solicitacao
            solicitacaoObj.rateio = rateios
            localStorage.setItem('solicitacao', JSON.stringify(solicitacaoObj))
        } else {
            localStorage.setItem('solicitacao', JSON.stringify(solicitacao))
        }

        history.push('/solicitar-viagem/review')
    }

    return (
        <BlankBoard>
            <Header
                title="Solicitação de viagem"
                actionArrowClick={() => {
                    if (indexActiveStep.current === 0) {
                        history.push('/solicitar-viagem/info')
                    } else {
                        history.push(
                            `/solicitar-viagem/${
                                stepByStep[indexActiveStep.current - 1].caminho
                            }`
                        )
                    }
                }}
            ></Header>
            <StepByStep items={stepByStep}></StepByStep>
            <ContainerPadding>
                <Subtitle>Cadastro de Rateio</Subtitle>
                <div className="row">
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message=" soma do rateio deve totalizar em 100% para seguir a
                                        diante"
                    ></Alert>
                </div>
                <div className="row">
                    <Form
                        ref={formRef}
                        style={{ margin: '2rem 0' }}
                        onSubmit={handleFormSubmit}
                    >
                        <div className="row">
                            <div className="col-12 col-sm-4">
                                <Select
                                    // request={{
                                    //     endpoint: ENDPOINTS.pesquisarFilial,
                                    //     labelToSelect: 'Nome',
                                    //     valueToSelect: 'Id'
                                    // }}
                                    options={filiais}
                                    name="IdFilial"
                                    placeholder="Selecione a filial"
                                    label="Filial"
                                    required={true}
                                ></Select>
                            </div>
                            <div className="col-12 col-sm-5">
                                <Select
                                    // request={{
                                    //     endpoint: ENDPOINTS.contasContabeis,
                                    //     labelToSelect: 'Nome',
                                    //     valueToSelect: 'Id'
                                    // }}
                                    options={centroDeCusto}
                                    name="IdContasContabeis"
                                    label="Conta Contábil"
                                    placeholder="Selecione a conta contábil"
                                    value={centroDeCustoInput}
                                    onChange={e => setCentroDeCustoInput(e)}
                                ></Select>
                            </div>
                            <div className="col-12 col-sm-3">
                                <Input
                                    name="Percentual"
                                    placeholder="Ex. 50"
                                    label="Porcentagem"
                                    type="number"
                                    required={true}
                                ></Input>
                            </div>
                        </div>
                        {/* Botão invisivel. Os botões fora da tag <Form> não podem manipular o onSubmit,
                    portanto esse botão é apenas uma referencia para outro botão que está fora */}
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 col-sm-4 col-md-2">
                                <DisableForMobile>
                                    <Button colorType="quaternary" icon="plus">
                                        Adicionar
                                    </Button>
                                </DisableForMobile>

                                <Center>
                                    <IsMobile>
                                        <Button
                                            colorType="btnMobile"
                                            icon="plus"
                                        >
                                            Adicionar
                                        </Button>
                                    </IsMobile>
                                </Center>
                            </div>
                        </div>
                        <div className="col-6">
                            {messageError ? (
                                <p
                                    className="danger"
                                    style={{ marginTop: '15px' }}
                                >
                                    {messageError}
                                </p>
                            ) : null}
                        </div>
                    </Form>
                </div>
                {rateios.length ? (
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <Center>
                                    <Container>
                                        {/* {width && width > 768
                                            ? getTabela()
                                            : getTabelaMobile()} */}
                                        <DisableForMobile>
                                            {getTabela()}
                                        </DisableForMobile>
                                        <IsMobile>{getTabelaMobile()}</IsMobile>
                                    </Container>
                                </Center>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Empty text="Nenhum rateio adicionado" />
                )}
                {rateios.length ? (
                    <div className="row">
                        <div className="col-12">
                            <Center
                                style={{
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: '3rem'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-evenly',
                                        width: '160px'
                                    }}
                                >
                                    <b>Total</b>
                                    <div style={{ width: '100px' }}>
                                        <CircularProgressbar
                                            value={totalPercent}
                                            text={`${totalPercent}%`}
                                            styles={buildStyles({
                                                textColor: '#6C7480',
                                                pathColor:
                                                    totalPercent === 100
                                                        ? '#92ffbe'
                                                        : '#8ED6FF',
                                                trailColor: '#E4E4E4'
                                            })}
                                        />
                                    </div>
                                </div>
                            </Center>
                            <Space height="2rem"></Space>
                            <Center>
                                {totalPercent === 100 ? (
                                    <>
                                        <DisableForMobile>
                                            <Button
                                                colorType="quaternary"
                                                onClick={() => finish()}
                                            >
                                                Finalizar
                                            </Button>
                                        </DisableForMobile>
                                        <IsMobile>
                                            <Button
                                                colorType="btnMobile"
                                                onClick={() => finish()}
                                            >
                                                Finalizar
                                            </Button>
                                        </IsMobile>
                                    </>
                                ) : null}
                            </Center>
                        </div>
                    </div>
                ) : null}
            </ContainerPadding>
        </BlankBoard>
    )
    function getCampo(index: number, title: string, valor: any) {
        return (
            <div
                style={{
                    flexDirection: 'column'
                }}
            >
                <ThMobile key={index}>{title}</ThMobile>
                <TdMobile
                    key={index}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {valor}
                </TdMobile>
            </div>
        )
    }

    function getTabela() {
        return (
            <table className="table">
                <thead
                    style={{
                        borderBottom: 'solid 1px lightgray'
                    }}
                >
                    <tr
                        style={{
                            border: '0 !important'
                        }}
                    >
                        <Th
                            style={{
                                borderBottomWidth: '0 !important'
                            }}
                            scope="col"
                            colSpan={2}
                        >
                            <p>Id</p>
                        </Th>
                        <Th scope="col">
                            <p>Filial</p>
                        </Th>
                        <Th scope="col">
                            <p>Conta Contábil</p>
                        </Th>
                        <Th scope="col">
                            <p>Percentual</p>
                        </Th>
                        <Th scope="col">
                            <p>Remover</p>
                        </Th>
                    </tr>
                </thead>
                <tbody>
                    {rateios.map((object: any, key: number) => {
                        return (
                            <tr key={key}>
                                <td>
                                    <p>{object.Id}</p>
                                </td>
                                <td>
                                    <p>{_pipeShowFilial(object.IdFilial)}</p>
                                </td>
                                <td>
                                    <p>
                                        {_pipeShowCentroDeCusto(
                                            object.IdContasContabeis
                                        )}
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        {_pipeChangePercent(
                                            object.Percentual,
                                            key
                                        )}
                                    </p>
                                </td>
                                <td>
                                    <Deletar
                                        remover={() => {
                                            const newRateios = [...rateios]

                                            newRateios.splice(key, 1)
                                            setRateios(newRateios)
                                            const newTotalPercent =
                                                totalPercent - object.Percentual
                                            setTotalPercent(newTotalPercent)
                                        }}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    function getTabelaMobile() {
        return (
            <Box>
                {rateios?.map((tableItem, index) => {
                    if (tableItem) {
                        return (
                            <Quadro>
                                <table style={{ width: '100%' }}>
                                    <TrMobile>
                                        {getCampo(index, 'Id', tableItem.Id)}
                                        {getCampo(
                                            index,
                                            'Filial',
                                            _pipeShowFilial(tableItem.IdFilial)
                                        )}
                                        {getCampo(
                                            index,
                                            'Conta Contábil',
                                            _pipeShowCentroDeCusto(
                                                tableItem.IdContasContabeis
                                            )
                                        )}
                                        {getCampo(
                                            index,
                                            'Percentual',
                                            _pipeChangePercent(
                                                tableItem.Percentual,
                                                index
                                            )
                                        )}
                                        {getCampo(
                                            index,
                                            'Remover',
                                            <Deletar
                                                remover={() => {
                                                    const newRateios = [
                                                        ...rateios
                                                    ]

                                                    newRateios.splice(index, 1)
                                                    setRateios(newRateios)
                                                    const newTotalPercent =
                                                        totalPercent -
                                                        tableItem.Percentual
                                                    setTotalPercent(
                                                        newTotalPercent
                                                    )
                                                }}
                                            />
                                        )}
                                    </TrMobile>
                                </table>
                            </Quadro>
                        )
                    }
                    return null
                })}
            </Box>
        )
    }
}

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
`
const Box = styled.div`
    border: solid 1px lightgray;
    border-radius: 15px;
    @media (max-width: 768px) {
        border: solid 0px lightgray;
        border-radius: 0px;
        margin-left: -1rem;
    }
`

const TrMobile = styled.tr`
    border: 0 !important;
    z-index: 10;
`
const ThMobile = styled.th`
    position: relative;
    width: auto !important;
    height: auto;
    color: #002a5d;
`
const TdMobile = styled.td`
    padding: 0.5rem 0rem !important;
    color: ${props => props.theme.colors.text};
    padding-left: 5px !important;
    color: #002a5d;
    .form-select {
        background-color: #f1f3f5;
    }
    .button.dropdown-item {
        background: #f1f3f5;
    }
`

const Quadro = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 8px;

    width: 342px;
    height: 0 auto;
    min-height: 218px;
    background: #f1f3f5;
    border: 1px solid #dee2e6;

    box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 1rem 0;
`
const Container = styled.div`
    @media (max-width: 768px) {
        border: solid 0px lightgray;
    }
`

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Button } from '../../../../core/components/Button'
import { Disclaimer } from '../../../../core/components/Disclaimer'
import { Input } from '../../../../core/components/Form/Input'
import { SelectRequester } from '../../../../core/components/Form/SelectWithRequest'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { ENDPOINTS } from '../../../../services/config-http'
import { Center } from '../../../../core/components/Center'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { Rateio } from '../../../../contexts/interfaces/rateio'
import { Header } from '../../../../core/components/Header/Header'
import { Subtitle } from '../../../../core/components/Subtitle'
import { useHistory, useParams } from 'react-router-dom'
import { Space } from '../../../../core/components/Space'
import { destroy, get, post, put } from '../../../../services/http'
import { UrlMountQuery } from '../../../../core/utils/UrlMountQuery'
import styled from 'styled-components'
import { Alert } from '../../../../core/components/Alert'
import {
    Box,
    Quadro,
    TdMobile,
    ThMobile,
    TrMobile
} from '../../../../core/components/Mobile'
import { Deletar } from '../../../../core/components/Deletar'
import { DisableForMobile, IsMobile } from '../stepMobile'

export const EditarRateioForm = () => {
    const { Id } = useParams() as any
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const [totalPercent, setTotalPercent] = useState<number>(100)
    const [rateios, setRateios] = useState<Rateio[]>([])
    const [filiais, setFilial] = useState<Array<any>>([])
    const [centroDeCusto, setCentroDeCusto] = useState<Array<any>>([])
    const [messageError, setMessageError] = useState<string>('')
    const [rateiosNovos, setRateiosNovos] = useState<Array<Rateio>>([])
    const [rateiosRemovidos, setRateiosRemovidos] = useState<Array<Rateio>>([])
    const [rateiosAlterados, setRateiosAlterados] = useState<Array<Rateio>>([])

    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })

    useEffect(() => {
        get(ENDPOINTS.filial).then(res => {
            setFilial(res?.data?.Model)
        })
        get(ENDPOINTS.contasContabeis).then(({ data: { Model } }) => {
            setCentroDeCusto(Model)
        })
        get(UrlMountQuery({ Id: Id }, ENDPOINTS.rateio)).then(
            ({ data: { Model } }) => {
                setRateios(Model)
            }
        )
    }, [])

    function _changeTotalPercent(index: Rateio) {
        let removedPercentual = 0
        rateios?.map((e: any, key: number) => {
            if (rateios[key].Id === index.Id) {
                removedPercentual = rateios[key].Percentual!
                setTotalPercent(totalPercent - removedPercentual)
            }
        })
        setRateios(rateios)
    }

    function _pipeShowFilial(value: any) {
        const item = filiais?.filter(item => item?.Id === value)[0]
        return <>{item?.Nome}</>
    }

    function _pipeShowCentroDeCusto(value: any) {
        const item = centroDeCusto?.filter(item => item?.Id === value)[0]
        return <>{item?.Nome}</>
    }

    function ChecarSeExiste(listaRateios: Rateio[], rateioParaChecar: Rateio) {
        let existe = -1
        listaRateios?.map((e: any, key: number) => {
            if (
                listaRateios[key].IdFilial === rateioParaChecar.IdFilial &&
                listaRateios[key].IdContasContabeis ===
                    rateioParaChecar.IdContasContabeis
            ) {
                existe = key
            }
        })
        return existe // retorna -1 caso não exista
    }

    function _adicionarRateio(rateioRecebido: Rateio) {
        const existeRemovidos = ChecarSeExiste(rateiosRemovidos, rateioRecebido)

        if (existeRemovidos === -1) {
            // caso nao seja um rateio removido

            const existeAlterados = ChecarSeExiste(
                rateiosAlterados,
                rateioRecebido
            )
            if (existeAlterados === -1) {
                // caso nao seja um rateio alterado

                rateiosNovos.push(rateioRecebido)
                setRateiosNovos(rateiosNovos)
            } else {
                rateiosAlterados?.map((e: any, key: number) => {
                    if (
                        rateiosAlterados[key].IdFilial ===
                            rateioRecebido.IdFilial &&
                        rateiosAlterados[key].IdContasContabeis ===
                            rateioRecebido.IdContasContabeis &&
                        rateiosAlterados[key].Percentual ===
                            rateioRecebido.Percentual
                    ) {
                        rateiosAlterados.splice(existeAlterados, 1)
                        setRateiosAlterados(rateiosAlterados)
                    }
                })
            }
        } else {
            const existeAlterados = ChecarSeExiste(
                rateiosAlterados,
                rateioRecebido
            )
            if (existeAlterados === -1) {
                // caso nao seja um rateio alterado
                rateiosRemovidos?.map((e: any, key: number) => {
                    if (
                        rateiosRemovidos[key].IdFilial ===
                            rateioRecebido.IdFilial &&
                        rateiosRemovidos[key].IdContasContabeis ===
                            rateioRecebido.IdContasContabeis &&
                        rateiosRemovidos[key].Percentual ==
                            rateioRecebido.Percentual
                    ) {
                        rateiosRemovidos.splice(existeAlterados, 1)
                        setRateiosRemovidos(rateiosRemovidos)
                    } else {
                        rateiosRemovidos.splice(existeAlterados, 1)
                        setRateiosRemovidos(rateiosRemovidos)
                        rateiosAlterados.push(rateioRecebido)
                        setRateiosAlterados(rateiosAlterados)
                    }
                })
            } else {
                rateiosRemovidos?.map((e: any, key: number) => {
                    if (
                        rateiosRemovidos[key].IdFilial ===
                            rateioRecebido.IdFilial &&
                        rateiosRemovidos[key].IdContasContabeis ===
                            rateioRecebido.IdContasContabeis &&
                        rateiosRemovidos[key].Percentual ===
                            rateioRecebido.Percentual
                    ) {
                        rateiosAlterados.splice(existeRemovidos, 1)
                        setRateiosAlterados(rateiosAlterados)
                        rateiosRemovidos.splice(existeRemovidos, 1)
                        setRateiosRemovidos(rateiosRemovidos)
                    } else {
                        rateiosAlterados.push(rateioRecebido)
                        setRateiosAlterados(rateiosAlterados)
                        rateiosRemovidos.splice(existeRemovidos, 1)
                        setRateiosRemovidos(rateiosRemovidos)
                    }
                })
            }
        }
    }

    function _alterarRateio(rateioRecebido: Rateio) {
        const existeNovos = rateiosNovos.indexOf(rateioRecebido)

        if (existeNovos === -1) {
            // se não for um rateio novo
            const existeAlterados = rateiosAlterados.indexOf(rateioRecebido)

            if (existeAlterados === -1) {
                // se nao for um rateio alterado
                rateiosAlterados.push(rateioRecebido)
                setRateiosAlterados(rateiosAlterados)
            } else {
                rateiosAlterados.splice(existeAlterados, 1)
                setRateiosAlterados(rateiosAlterados)
            }
        }
    }

    function _removerRateio(rateioRecebido: Rateio) {
        const existeNovos = rateiosNovos.indexOf(rateioRecebido)

        if (existeNovos === -1) {
            // se nao for um rateio novo
            const hasRateio = rateiosRemovidos.some(
                i => i.Id === rateioRecebido.Id
            )
            if (hasRateio === false) {
                rateiosRemovidos.push(rateioRecebido)
                setRateiosRemovidos(rateiosRemovidos)
            }
        } else {
            rateiosNovos.splice(existeNovos, 1)
            setRateiosNovos(rateiosNovos)
        }

        const existeAlterados = rateiosAlterados.indexOf(rateioRecebido)
        if (existeAlterados !== -1) {
            // caso seja um rateio alterado
            rateiosAlterados.splice(existeAlterados, 1)
            setRateiosAlterados(rateiosAlterados)
        }
        _changeTotalPercent(rateioRecebido)
    }

    function _pipeChangePercent(
        value: any | string,
        index: number
    ): JSX.Element {
        const subtractPercent = () => {
            const changedRateios: Array<Rateio> = [...rateios]
            changedRateios[index].Percentual = +value - 1
            // verificação do novo percentual e o total, deve ser maior ou igual a zero
            if (+value - 1 >= 0 && totalPercent - 1 >= 0) {
                setRateios(changedRateios)
                setTotalPercent(totalPercent - 1)
            }
            _alterarRateio(changedRateios[index])
        }

        const addPercent = () => {
            const changedRateios: Array<Rateio> = [...rateios]
            changedRateios[index].Percentual = +value + 1
            // verificação do novo percentual e o total, deve ser menor ou igual a 100
            if (+value + 1 <= 100 && totalPercent + 1 <= 100) {
                setRateios(changedRateios)
                setTotalPercent(totalPercent + 1)
            }
            _alterarRateio(changedRateios[index])
        }

        return (
            <div
                style={{
                    width: '100px',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    fontSize: '0.9rem',
                    margin: 'auto'
                }}
            >
                <FontAwesomeIcon
                    icon="plus"
                    className="cursor-pointer"
                    color={totalPercent === 100 ? 'DarkGrey' : '#8ED6FF'}
                    onClick={() => addPercent()}
                />

                <p style={{ margin: 0 }}>
                    <b>{value}</b>%
                </p>
                <FontAwesomeIcon
                    icon="minus"
                    className="cursor-pointer"
                    color={totalPercent === 0 ? 'DarkGrey' : '#8ED6FF'}
                    onClick={() => subtractPercent()}
                />
            </div>
        )
    }

    const handleFormSubmit: SubmitHandler<Rateio> = async (
        rateio,
        { reset }
    ) => {
        try {
            const schema = Yup.object().shape({
                idFilial: Yup.string().required(
                    'O Campo Filial é Obrigatório!'
                ),
                idContasContabeis: Yup.string().required(
                    'O Campo Centro de custo é Obrigatório!'
                ),
                percentual: Yup.string()
                    .nullable()
                    .required('O Campo Percentual é Obrigatório!')
            })

            await schema.validate(rateio, { abortEarly: false })

            const rateioLocal: Rateio = {
                IdSolicitacaoViagem: Id,
                IdFilial: formRef.current?.getFieldValue('idFilial'),
                IdContasContabeis:
                    formRef.current?.getFieldValue('idContasContabeis'),
                Percentual: formRef.current?.getFieldValue(
                    'percentual'
                ) as number,
                Id: 0
            }
            const newRateios = [...rateios]
            const hasRateio = newRateios.some(
                i =>
                    i.IdFilial === rateioLocal.IdFilial &&
                    i.IdContasContabeis === rateioLocal.IdContasContabeis
            )
            if (hasRateio) {
                setMessageError('Ops! Rateio já adicionado a lista')
                return
            }
            if (rateioLocal.Percentual === 0) {
                setMessageError('Percentual de rateio não pode ser zero!')
                return
            }
            if (
                rateioLocal.Percentual &&
                +rateioLocal.Percentual + totalPercent <= 100
            ) {
                _adicionarRateio(rateioLocal)
                newRateios.push(rateioLocal)
                setRateios([...newRateios])
                setTotalPercent(+rateioLocal.Percentual + totalPercent)
            } else {
                setMessageError(
                    'Percentual de rateionão pode ultrapassar 100%!'
                )
            }

            formRef.current?.setErrors({})

            // reinicia o form com campos vazios
            reset()
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                formRef.current?.setErrors(erros)
            }
        }
    }

    const SubmitInformation = async () => {
        try {
            if (rateios.length === 0) {
                setMessageError('Não existem rateios')
            } else if (totalPercent < 100) {
                setMessageError('A porcentagem precisa ser 100!')
            } else {
                rateiosNovos.forEach(element => {
                    post(ENDPOINTS.rateio, {
                        id: element.Id,
                        idSolicitacaoViagem: element.IdSolicitacaoViagem,
                        idFilial: element.IdFilial,
                        idContasContabeis: element.IdContasContabeis,
                        percentual: element.Percentual as number
                    })
                        .then(res => {
                            setShowAlert({
                                status: true,
                                message: res.data,
                                type: 'success'
                            })
                        })
                        .catch(err => {
                            setShowAlert({
                                status: true,
                                message: err.data.Text,
                                type: 'danger'
                            })
                        })
                })
                rateiosAlterados.forEach(element => {
                    put(ENDPOINTS.rateio, {
                        id: element.Id,
                        idSolicitacaoViagem: element.IdSolicitacaoViagem,
                        idFilial: element.IdFilial,
                        idContasContabeis: element.IdContasContabeis,
                        percentual: element.Percentual as number
                    })
                        .then(res => {
                            setShowAlert({
                                status: true,
                                message: res.data,
                                type: 'success'
                            })
                        })
                        .catch(err => {
                            setShowAlert({
                                status: true,
                                message: err.data.Text,
                                type: 'danger'
                            })
                        })
                })
                rateiosRemovidos.forEach(element => {
                    destroy(`${ENDPOINTS.rateio}/${element.Id}`)
                        .then(res => {
                            setShowAlert({
                                status: true,
                                message: res.data,
                                type: 'success'
                            })
                        })
                        .catch(err => {
                            setShowAlert({
                                status: true,
                                message: err.data.Text,
                                type: 'danger'
                            })
                        })
                })
                history.push(`/solicitacoes/editar/${Id}`)
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                formRef.current?.setErrors(erros)
            }
        }
    }

    function getCampo(index: number, title: string, valor: any) {
        return (
            <div
                style={{
                    flexDirection: 'column'
                }}
            >
                <ThMobile key={index}>{title}</ThMobile>
                <TdMobile
                    key={index}
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {valor}
                </TdMobile>
            </div>
        )
    }

    function getTabelaMobile() {
        return (
            <Center>
                <Box>
                    {rateios?.map((tableItem, index) => {
                        if (tableItem) {
                            return (
                                <Quadro>
                                    <table style={{ width: '100%' }}>
                                        <TrMobile>
                                            {getCampo(
                                                index,
                                                'Id',
                                                tableItem.Id
                                            )}
                                            {getCampo(
                                                index,
                                                'Filial',
                                                _pipeShowFilial(
                                                    tableItem.IdFilial
                                                )
                                            )}
                                            {getCampo(
                                                index,
                                                'Conta Contábil',
                                                _pipeShowCentroDeCusto(
                                                    tableItem.IdContasContabeis
                                                )
                                            )}
                                            {getCampo(
                                                index,
                                                'Percentual',
                                                _pipeChangePercent(
                                                    tableItem.Percentual,
                                                    index
                                                )
                                            )}
                                            {getCampo(
                                                index,
                                                'Remover',
                                                <Deletar
                                                    remover={() => {
                                                        const newRateios = [
                                                            ...rateios
                                                        ]

                                                        newRateios.splice(
                                                            index,
                                                            1
                                                        )
                                                        setRateios(newRateios)
                                                        const newTotalPercent =
                                                            totalPercent -
                                                            tableItem.Percentual
                                                        setTotalPercent(
                                                            newTotalPercent
                                                        )
                                                    }}
                                                />
                                            )}
                                        </TrMobile>
                                    </table>
                                </Quadro>
                            )
                        }
                        return null
                    })}
                </Box>
            </Center>
        )
    }

    return (
        <BlankBoard>
            <Header
                title="Rateio"
                actionArrowClick={() => {
                    history.push(`/solicitacoes/editar/${Id}`)
                }}
            ></Header>
            <Subtitle
                style={{
                    textAlign: 'center',
                    margin: '1rem 0'
                }}
            >
                Dados de Rateio
            </Subtitle>
            <div className="row">
                <Alert
                    fixed={true}
                    icon="info-circle"
                    type="primary"
                    message=" soma do rateio deve totalizar em 100% para seguir a
                                        diante"
                ></Alert>
            </div>
            <div className="row">
                <Form
                    ref={formRef}
                    style={{ margin: '1rem 0' }}
                    onSubmit={handleFormSubmit}
                >
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <SelectRequester
                                request={{
                                    endpoint: ENDPOINTS.pesquisarFilial,
                                    labelToSelect: 'Nome',
                                    valueToSelect: 'Id'
                                }}
                                name="idFilial"
                                placeholder="Selecione a filial"
                                label="Filial"
                                required={true}
                            ></SelectRequester>
                        </div>
                        <div className="col-12 col-sm-5">
                            <SelectRequester
                                request={{
                                    endpoint: ENDPOINTS.contasContabeis,
                                    labelToSelect: 'Nome',
                                    valueToSelect: 'Id'
                                }}
                                name="idContasContabeis"
                                label="Centro de custo"
                                placeholder="Selecione o centro de custo"
                                required={true}
                            ></SelectRequester>
                        </div>
                        <div className="col-12 col-sm-3">
                            <Input
                                name="percentual"
                                placeholder="Ex. 50"
                                label="Porcentagem"
                                type="number"
                                required={true}
                            ></Input>
                        </div>
                    </div>
                    {/* Botão invisivel. Os botões fora da tag <Form> não podem manipular o onSubmit,
                    portanto esse botão é apenas uma referencia para outro botão que está fora */}
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="col-12 col-sm-4 col-md-2">
                            <DisableForMobile>
                                <Button
                                    colorType="quintiary"
                                    icon="plus"
                                    style={{ width: '100%', minWidth: '150px' }}
                                >
                                    <b>Adicionar</b>
                                </Button>
                            </DisableForMobile>
                            <IsMobile>
                                <Center>
                                    <IsMobile>
                                        <Button
                                            icon="plus"
                                            colorType="btnMobile"
                                        >
                                            Adicionar
                                        </Button>
                                    </IsMobile>
                                </Center>
                            </IsMobile>
                        </div>
                    </div>
                    <div className="col-6">
                        {messageError ? (
                            <p className="danger" style={{ marginTop: '15px' }}>
                                {messageError}
                            </p>
                        ) : null}
                    </div>
                </Form>
            </div>

            <DisableForMobile>
                <div className="row" style={{ marginTop: '1.5rem' }}>
                    <div className="col-12">
                        <label htmlFor="">Rateios</label>
                        <div
                            className="table-responsive"
                            style={{
                                border: 'solid 1px lightgray',
                                borderRadius: '15px'
                            }}
                        >
                            <table className="table">
                                <thead
                                    style={{
                                        borderBottom: 'solid 1px lightgray',
                                        textAlign: 'center'
                                    }}
                                >
                                    <tr
                                        style={{
                                            border: '0 !important'
                                        }}
                                    >
                                        <Th scope="col">
                                            <Text>#</Text>
                                        </Th>
                                        <Th scope="col" colSpan={2}>
                                            <Text>Filial</Text>
                                        </Th>
                                        <Th scope="col" colSpan={2}>
                                            <Text>Centro de Custo</Text>
                                        </Th>
                                        <Th scope="col">
                                            <Text>Percentual</Text>
                                        </Th>
                                        <Th scope="col">
                                            <Text>Deletar</Text>
                                        </Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rateios?.map((object: Rateio, key) => {
                                        return (
                                            <tr
                                                key={key}
                                                style={{ textAlign: 'center' }}
                                            >
                                                <Text>
                                                    <td>{key + 1}</td>
                                                </Text>
                                                <td colSpan={2}>
                                                    <Text>
                                                        {' '}
                                                        {_pipeShowFilial(
                                                            object.IdFilial
                                                        )}
                                                    </Text>
                                                </td>
                                                <td colSpan={2}>
                                                    <Text>
                                                        {_pipeShowCentroDeCusto(
                                                            object.IdContasContabeis
                                                        )}
                                                    </Text>
                                                </td>
                                                <td>
                                                    <Text>
                                                        {_pipeChangePercent(
                                                            object.Percentual,
                                                            key
                                                        )}
                                                    </Text>
                                                </td>
                                                <td>
                                                    <span
                                                        className="danger cursor-pointer"
                                                        onClick={() => {
                                                            const newRateios = [
                                                                ...rateios
                                                            ]

                                                            const rateioRemovido =
                                                                newRateios.splice(
                                                                    key,
                                                                    1
                                                                )

                                                            _removerRateio(
                                                                rateioRemovido[0]
                                                            )
                                                            setRateios(
                                                                newRateios
                                                            )
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {rateios.length ? (
                    <div className="row">
                        <div className="col-12">
                            <Center
                                style={{
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: '3rem'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-evenly',
                                        width: '160px'
                                    }}
                                >
                                    <b>Total</b>
                                    <div style={{ width: '100px' }}>
                                        <CircularProgressbar
                                            value={totalPercent}
                                            text={`${totalPercent}%`}
                                            styles={buildStyles({
                                                textColor: '#6C7480',
                                                pathColor:
                                                    totalPercent === 100
                                                        ? '#92ffbe'
                                                        : '#8ED6FF',
                                                trailColor: '#E4E4E4'
                                            })}
                                        />
                                    </div>
                                </div>
                                {/* <p style={{ marginTop: '15px' }}>
                                Complete <b className="lightCyan">100%</b> na
                                soma dos rateios para enviar
                            </p> */}
                            </Center>
                        </div>
                    </div>
                ) : null}
            </DisableForMobile>
            <IsMobile>{getTabelaMobile()}</IsMobile>
            <Space height="2rem"></Space>
            <Center>
                <DisableForMobile>
                    <Button
                        icon="save"
                        colorType="quaternary"
                        onClick={() => {
                            console.log('Alterados: ', rateiosAlterados)
                            SubmitInformation()
                        }}
                    >
                        Salvar
                    </Button>
                </DisableForMobile>
                <IsMobile>
                    <Button
                        icon="save"
                        colorType="btnMobile"
                        onClick={() => {
                            console.log('Alterados: ', rateiosAlterados)
                            SubmitInformation()
                        }}
                    >
                        Salvar
                    </Button>
                </IsMobile>
            </Center>
        </BlankBoard>
    )
}

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
`
const Text = styled.p``

import { BASE_URL_DEV } from './config-http'

import axios from 'axios'

const apiClient = axios.create({
    baseURL: BASE_URL_DEV
})
apiClient.interceptors.request.use(
    config => {
        return {
            ...config
        }
    },
    error => Promise.reject(error)
)

apiClient.interceptors.response.use(
    response => {
        return response
    },
    async error => {
        if (!error.response) {
            localStorage.removeItem('@ViagensCorp:refreshToken')
            localStorage.removeItem('@ViagensCorp:user')
            localStorage.removeItem('@ViagensCorp:token')
            // eslint-disable-next-line no-restricted-globals
            location.reload()
            return null
        }
        return Promise.reject(error.response)
    }
)

const { get, post, put, patch, delete: destroy } = apiClient
export { get, post, put, patch, destroy, apiClient }

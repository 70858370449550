import React, { useEffect, useState } from 'react'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button'
import { ENDPOINTS } from '../../../services/config-http'
import { Header } from '../../../core/components/Header/Header'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { get } from '../../../services/http'
import { useHistory, useParams } from 'react-router'
import { Space } from '../../../core/components/Space'
import { Center } from '../../../core/components/Center'
import styled from 'styled-components'
import { ContainerPadding } from '../../../core/components/ContainerPadding'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import GolImg from '../../../core/assets/Gol.jpg'
import AzulImg from '../../../core/assets/Azul.png'
import LatamImg from '../../../core/assets/Latam.png'
import { SelectMapper } from '../../../core/utils/SelectMapper'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Aereo } from '../../../contexts/interfaces/aereo'
import { useSpinner } from '../../../hooks/Spinner'
import { Toast } from '../../../core/components/Toast'
import { MoneyFormat } from '../../../core/utils/MoneyFormat'
import PlaneGo from '../../../core/assets/plane-1.svg'
import PlaneBack from '../../../core/assets/plane-2.svg'
import useWindowDimensions from '../../../core/components/GetDimensions'
import {
    getNotFound,
    getCampo,
    Lista,
    Box,
    Quadro,
    TrMobile,
    Title,
    Data,
    Evento,
    Detail,
    Container,
    Esquerda,
    Iata,
    Text,
    Meio,
    Direita,
    getReservaMobile
} from '../../../core/components/Mobile'
import { IntlRealFormat } from '../../../core/utils/IntlFormat'
import { DisableForMobile, IsMobile } from '../Solicitacao/stepMobile'

export const Detalhes = () => {
    const history = useHistory()
    const { setLoading } = useSpinner()
    const [solicitacao, setSolicitacao] = useState<any>()
    const [motivoViagem, setMotivoViagem] = useState<any>()
    const [adiantamentoPrincipal, setAdiantamentoPrincipal] = useState<any>()
    const [valorTotal, setValorTotal] = useState<any>()
    const [tiposSolicitacao, setTiposSolicitacao] = useState<Array<any>>([])
    const [filiais, setFilial] = useState<Array<any>>([])
    const [viajantes, setViajantes] = useState<Array<any>>([])
    const [centroDeCusto, setCentroDeCusto] = useState<Array<any>>([])
    const { Id } = useParams<{ Id: string }>()
    const [abaAtual, setAbaAtual] = useState('Solicitação')
    const [servicos, setServicos] = useState<Array<any>>([])
    const [idaVolta, setIdaVolta] = useState(true)
    const [showToast, setShowToast] = useState(false)
    const { width } = useWindowDimensions()
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })
    const [reservas, setReservas] = useState<Aereo[]>([])

    useEffect(() => {
        get(`${ENDPOINTS.solicitacaoViagemPorId}/${Id}`).then(
            async ({ data: { Model } }) => {
                setSolicitacao(Model)
                Model?.Viajantes?.map((e: any, key: number) => {
                    if (Model.Viajantes[key].Convidado === false) {
                        const IdAtual = Model.Viajantes[key].IdUsuario
                        Model?.Adiantamentos?.map((e: any, key: number) => {
                            if (
                                Model.Adiantamentos[key].IdUsuario === IdAtual
                            ) {
                                setAdiantamentoPrincipal(
                                    Model.Adiantamentos[key]
                                )
                            }
                        })
                        let somaAdiantamentos = 0
                        Model?.Adiantamentos?.map((e: any, key: number) => {
                            somaAdiantamentos +=
                                Model?.Adiantamentos[key]?.Valor
                        })
                        setValorTotal(somaAdiantamentos)
                    }
                })
                Model?.Aereos?.forEach((element: any) => {
                    const {
                        DataIdaChegada,
                        DataIdaSaida,
                        DataVoltaChegada,
                        DataVoltaSaida,
                        Companhia,
                        QtdEscalas,
                        AeroportoDestino,
                        AeroportoOrigem,
                        IdaVolta,
                        Valor
                    } = element
                    const newAereo: Aereo = {
                        DataIdaChegada: new Date(DataIdaChegada),
                        DataIdaSaida: new Date(DataIdaSaida),
                        DataVoltaChegada: new Date(DataVoltaChegada),
                        DataVoltaSaida: new Date(DataVoltaSaida),
                        Companhia: Companhia,
                        QtdEscalas: QtdEscalas,
                        AeroportoDestino: AeroportoDestino,
                        AeroportoOrigem: AeroportoOrigem,
                        Valor: Valor,
                        IdaVolta: IdaVolta
                    }
                    setIdaVolta(IdaVolta)
                    setReservas(reservas => [...reservas, newAereo])
                })
                setViajantes(Model.Viajantes)
                get(
                    UrlMountQuery(
                        {
                            Id: Model.IdMotivoViagem
                        },
                        ENDPOINTS.motivoViagemById
                    )
                )
                    .then(async ({ data: { Model } }) => {
                        setMotivoViagem(Model)
                    })
                    .catch(err => {
                        console.error(err)
                    })
            }
        )

        get(ENDPOINTS.servico)
            .then(({ data: { Model } }) => {
                setServicos(Model)
            })
            .catch(err => {
                console.error(err)
            })
        get(ENDPOINTS.filial).then(res => {
            setFilial(res?.data?.Model)
        })
        const pesquisa = async () => {
            await get(`${ENDPOINTS.tipoSolicitacao}`)
                .then(({ data: { Model } }) => {
                    setTiposSolicitacao(SelectMapper(Model, 'Nome', 'Id'))
                })
                .catch(err => {
                    console.error(err)
                })
        }
        pesquisa()
        get(
            UrlMountQuery(
                { TotalRegisterPage: 500 },
                ENDPOINTS.pesquisarContasContabeis
            )
        ).then(({ data: { Items } }) => {
            setCentroDeCusto(Items)
        })
    }, [Id])

    const renderLogoCia = (cia: string) => {
        switch (cia) {
            case 'G3':
                return (
                    <ContainerLogo>
                        <img src={GolImg} alt="Gol"></img>
                    </ContainerLogo>
                )
            case 'AD':
                return (
                    <ContainerLogo>
                        <img src={AzulImg} alt="Azul"></img>
                    </ContainerLogo>
                )
            case 'JJ':
                return (
                    <ContainerLogo>
                        <img src={LatamImg} alt="Latam"></img>
                    </ContainerLogo>
                )

            default:
                break
        }
    }

    function _ShowFilial(value: number) {
        const item = filiais?.filter(item => item?.Id === value)[0]
        return <>{item?.Nome}</>
    }

    function _ShowCentroDeCusto(value: number) {
        const item = centroDeCusto?.filter(item => item?.Id === value)[0]
        return <>{item?.Nome}</>
    }

    function _ShowTipoSolicitacao(value: any) {
        const item = tiposSolicitacao?.filter(item => item?.value === value)[0]
        return item?.label
    }

    function _ShowServicos(value: any) {
        const item = servicos?.filter(item => item?.Id === value?.IdServico)[0]
        return item?.Descricao
    }

    function _ShowViajanteName(value: number) {
        const item: any = viajantes?.filter(
            item => item?.IdUsuario === value
        )[0]
        return item?.Nome
    }

    function _ShowViajanteCode(value: number) {
        const item: any = viajantes?.filter(
            item => item?.IdUsuario === value
        )[0]
        return item?.Codigo
    }

    function styleTextLine(title: string, param: any) {
        return (
            <p
                style={{
                    marginBottom: '20px'
                }}
            >
                <b
                    style={{
                        marginRight: '5px'
                    }}
                >
                    {title}:
                </b>
                {param}
            </p>
        )
    }

    function notFoundDesktop(param: string) {
        return (
            <div
                style={{
                    height: 'auto',
                    margin: '5vh 7vh',
                    borderRadius: '1vh',
                    padding: '1.5vh',
                    boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.05)'
                }}
            >
                <p>{param}</p>
            </div>
        )
    }

    return (
        <BlankBoard>
            <Header
                title="Detalhes"
                actionArrowClick={() => {
                    history.goBack()
                }}
            ></Header>
            <ContainerPadding>
                <Lista className="nav nav-tabs" id="myTab" role="tablist">
                    <li
                        className="nav-item"
                        style={navItem}
                        role="presentation"
                    >
                        <button
                            className="nav-link active"
                            id="info-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#info"
                            type="button"
                            role="tab"
                            aria-controls="info"
                            aria-selected="true"
                            onClick={e => setAbaAtual('Solicitação')}
                        >
                            <FontAwesomeIcon icon="bars" />
                        </button>
                    </li>
                    <li
                        className="nav-item"
                        style={navItem}
                        role="presentation"
                    >
                        <button
                            className="nav-link"
                            id="viajantes-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#viajantes"
                            type="button"
                            role="tab"
                            aria-controls="viajantes"
                            aria-selected="true"
                            onClick={e => setAbaAtual('Viajantes')}
                        >
                            <FontAwesomeIcon icon="users" />
                        </button>
                    </li>
                    <li
                        className="nav-item"
                        style={navItem}
                        role="presentation"
                    >
                        <button
                            className="nav-link"
                            id="roteiros-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#roteiros"
                            type="button"
                            role="tab"
                            aria-controls="roteiros"
                            aria-selected="true"
                            onClick={e => setAbaAtual('Roteiros')}
                        >
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </button>
                    </li>
                    <li
                        className="nav-item"
                        style={navItem}
                        role="presentation"
                    >
                        <button
                            className="nav-link"
                            id="aereo-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#aereo"
                            type="button"
                            role="tab"
                            aria-controls="aereo"
                            aria-selected="true"
                            onClick={e => setAbaAtual('Aéreos')}
                        >
                            <FontAwesomeIcon icon="plane" />
                        </button>
                    </li>
                    <li
                        className="nav-item"
                        style={navItem}
                        role="presentation"
                    >
                        <button
                            className="nav-link"
                            id="hospedagem-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#hospedagem"
                            type="button"
                            role="tab"
                            aria-controls="hospedagem"
                            aria-selected="true"
                            onClick={e => setAbaAtual('Hospedagens')}
                        >
                            <FontAwesomeIcon icon="hotel" />
                        </button>
                    </li>
                    <li
                        className="nav-item"
                        style={navItem}
                        role="presentation"
                    >
                        <button
                            className="nav-link"
                            id="servicos-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#servicos"
                            type="button"
                            role="tab"
                            aria-controls="servicos"
                            aria-selected="true"
                            onClick={e => setAbaAtual('Serviços')}
                        >
                            <FontAwesomeIcon icon="list-ul" />
                        </button>
                    </li>
                    <li
                        className="nav-item"
                        style={navItem}
                        role="presentation"
                    >
                        <button
                            className="nav-link"
                            id="adiantamento-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#adiantamento"
                            type="button"
                            role="tab"
                            aria-controls="adiantamento"
                            aria-selected="true"
                            onClick={e => setAbaAtual('Adiantamentos')}
                        >
                            <FontAwesomeIcon icon="dollar-sign" />
                        </button>
                    </li>
                    <li
                        className="nav-item"
                        style={navItem}
                        role="presentation"
                    >
                        <button
                            className="nav-link"
                            id="rateio-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#rateio"
                            type="button"
                            role="tab"
                            aria-controls="rateio"
                            aria-selected="true"
                            onClick={e => setAbaAtual('Rateios')}
                        >
                            <FontAwesomeIcon icon="random" />
                        </button>
                    </li>
                    <div
                        style={{
                            fontSize: '1.2rem',
                            display: 'flex',
                            width: '62%',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            color: '#5F8DD3'
                        }}
                    >
                        {abaAtual}
                    </div>
                </Lista>
                <article className="tab-content" id="myTabContent">
                    <Infos
                        id="info"
                        className="tab-pane fade show active"
                        role="tabpanel"
                        aria-labelledby="info-tab"
                    >
                        {width && width > 768 ? (
                            <>
                                {styleTextLine(
                                    'Tipo de Solicitação',
                                    _ShowTipoSolicitacao(
                                        solicitacao?.IdTipoSolicitacao
                                    )
                                )}
                                {styleTextLine(
                                    'Motivo da Viagem',
                                    motivoViagem?.Descricao
                                )}
                                {styleTextLine(
                                    'Descrição',
                                    solicitacao?.Descricao || 'Não preenchido'
                                )}
                                {styleTextLine(
                                    'Viajante Principal',
                                    solicitacao?.Viajantes[0]?.Nome
                                )}
                            </>
                        ) : (
                            <Center>
                                <Box>
                                    <Quadro>
                                        <table
                                            style={{
                                                width: '100%'
                                            }}
                                        >
                                            <TrMobile>
                                                {getCampo(
                                                    1,
                                                    'Tipo de Solicitação:',
                                                    _ShowTipoSolicitacao(
                                                        solicitacao?.IdTipoSolicitacao
                                                    )
                                                )}
                                                {getCampo(
                                                    1,
                                                    'Motivo da Viagem: ',
                                                    motivoViagem?.Descricao
                                                )}
                                                {getCampo(
                                                    1,
                                                    'Descrição:',
                                                    solicitacao?.Descricao ||
                                                        'Não preenchido'
                                                )}
                                                {getCampo(
                                                    1,
                                                    'Viajante Principal:',
                                                    `${solicitacao?.Viajantes[0]?.Nome}`
                                                )}
                                            </TrMobile>
                                        </table>
                                    </Quadro>
                                </Box>
                            </Center>
                        )}
                    </Infos>
                    <div
                        id="roteiros"
                        className="tab-pane fade show"
                        role="tabpanel"
                        aria-labelledby="roteiros-tab"
                    >
                        {width && width > 768 ? (
                            solicitacao?.Destinos?.length ? (
                                <Infos
                                    id="info"
                                    className="tab-pane fade show active"
                                    role="tabpanel"
                                    aria-labelledby="info-tab"
                                >
                                    <table
                                        className="table"
                                        style={{ color: '#6C7480' }}
                                    >
                                        <thead
                                            style={{
                                                background: '#FFFFFF',
                                                border: 'none !important'
                                            }}
                                        >
                                            <tr>
                                                <th
                                                    style={{
                                                        width: '3rem'
                                                    }}
                                                >
                                                    <p>Destino</p>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '2rem'
                                                    }}
                                                >
                                                    <p>Início</p>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '2rem'
                                                    }}
                                                >
                                                    <p>Término</p>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '2rem'
                                                    }}
                                                >
                                                    <p>Objetivo</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody
                                            style={{
                                                background: '#FFFFFF',
                                                borderTop: '#DFDFDF 1px'
                                            }}
                                        >
                                            {solicitacao?.Destinos?.map(
                                                (destino: any, key: number) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td
                                                                style={{
                                                                    width: '3rem'
                                                                }}
                                                            >
                                                                <p>
                                                                    {
                                                                        destino.Destino
                                                                    }
                                                                </p>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '2rem'
                                                                }}
                                                            >
                                                                <p>
                                                                    {new Date(
                                                                        destino.DataInicio
                                                                    ).toLocaleDateString()}
                                                                </p>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '2rem'
                                                                }}
                                                            >
                                                                <p>
                                                                    {' '}
                                                                    {new Date(
                                                                        destino.DataTermino
                                                                    ).toLocaleDateString()}
                                                                </p>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '2rem'
                                                                }}
                                                            >
                                                                <p>
                                                                    {
                                                                        destino.Objetivo
                                                                    }
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </Infos>
                            ) : (
                                <p>
                                    <div
                                        style={{
                                            height: 'auto',
                                            margin: '5vh 7vh',
                                            fontSize: '2.2vh',
                                            borderRadius: '1vh',
                                            padding: '3.5vh',
                                            boxShadow:
                                                '0px 2px 30px rgba(0, 0, 0, 0.05)'
                                        }}
                                    >
                                        Não possui Roteiros cadastrados
                                    </div>
                                </p>
                            )
                        ) : solicitacao?.Destinos?.length ? (
                            solicitacao?.Destinos?.map(
                                (destino: any, key: number) => {
                                    return (
                                        <Center key={key}>
                                            <Box>
                                                <Quadro>
                                                    <table
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <TrMobile>
                                                            {getCampo(
                                                                key,
                                                                'Destino:',
                                                                destino.Destino
                                                            )}
                                                            {getCampo(
                                                                key,
                                                                'Início:',
                                                                new Date(
                                                                    destino.DataInicio
                                                                ).toLocaleDateString()
                                                            )}
                                                            {getCampo(
                                                                key,
                                                                'Término:',
                                                                new Date(
                                                                    destino.DataTermino
                                                                ).toLocaleDateString()
                                                            )}
                                                            {getCampo(
                                                                key,
                                                                'Objetivo:',
                                                                destino.Objetivo
                                                            )}
                                                        </TrMobile>
                                                    </table>
                                                </Quadro>
                                            </Box>
                                        </Center>
                                    )
                                }
                            )
                        ) : (
                            <Center>
                                {getNotFound('Não possui Roteiros cadastrados')}
                            </Center>
                        )}
                    </div>
                    <div
                        id="viajantes"
                        className="tab-pane fade show"
                        role="tabpanel"
                        aria-labelledby="viajantes-tab"
                    >
                        {width && width > 768 ? (
                            solicitacao?.Viajantes?.length ? (
                                <Infos
                                    id="info"
                                    className="tab-pane fade show active"
                                    role="tabpanel"
                                    aria-labelledby="info-tab"
                                >
                                    <table
                                        className="table"
                                        style={{ color: '#6C7480' }}
                                    >
                                        <thead
                                            style={{
                                                background: '#FFFFFF',
                                                border: 'none !important'
                                            }}
                                        >
                                            <tr
                                                style={{
                                                    border: 'none !important'
                                                }}
                                            >
                                                <th
                                                    style={{
                                                        width: '2rem',
                                                        border: 'none !important'
                                                    }}
                                                >
                                                    <p>Viajante</p>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '5rem'
                                                    }}
                                                >
                                                    <p>Tipo de viajante</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody
                                            style={{
                                                background: '#FFFFFF',
                                                borderTop: '#DFDFDF 1px'
                                            }}
                                        >
                                            {solicitacao?.Viajantes?.map(
                                                (
                                                    viajante: any,
                                                    key: number
                                                ) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td
                                                                style={{
                                                                    width: '2rem'
                                                                }}
                                                            >
                                                                <p>{`${viajante.Codigo} - ${viajante.Nome}`}</p>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '5rem'
                                                                }}
                                                            >
                                                                <p>
                                                                    {viajante.Convidado
                                                                        ? 'Convidado'
                                                                        : 'Principal'}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </Infos>
                            ) : (
                                <p>
                                    <div
                                        style={{
                                            height: 'auto',
                                            margin: '5vh 7vh',
                                            fontSize: '2.2vh',
                                            borderRadius: '1vh',
                                            padding: '3.5vh',
                                            boxShadow:
                                                '0px 2px 30px rgba(0, 0, 0, 0.05)'
                                        }}
                                    >
                                        Não possui Viajantes cadastrados
                                    </div>
                                </p>
                            )
                        ) : solicitacao?.Viajantes?.length ? (
                            solicitacao?.Viajantes?.map(
                                (viajante: any, key: number) => {
                                    return (
                                        <Center key={key}>
                                            <Box>
                                                <Quadro>
                                                    <table
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <TrMobile>
                                                            {getCampo(
                                                                key,
                                                                'Codigo:',
                                                                viajante.Codigo
                                                            )}
                                                            {getCampo(
                                                                key,
                                                                'Nome:',
                                                                viajante.Nome
                                                            )}
                                                            {getCampo(
                                                                key,
                                                                'Tipo de viajante:',
                                                                viajante.Convidado
                                                                    ? 'Convidado'
                                                                    : 'Principal'
                                                            )}
                                                        </TrMobile>
                                                    </table>
                                                </Quadro>
                                            </Box>
                                        </Center>
                                    )
                                }
                            )
                        ) : (
                            <Center>
                                {getNotFound(
                                    'Não possui Viajantes cadastrados'
                                )}
                            </Center>
                        )}
                    </div>
                    <div
                        id="viajantes"
                        className="tab-pane fade show"
                        role="tabpanel"
                        aria-labelledby="viajantes-tab"
                    >
                        {solicitacao?.Viajantes?.length ? (
                            solicitacao?.Viajantes?.map(
                                (e: any, key: number) => {
                                    return (
                                        <Infos key={key}>
                                            <p>
                                                <b>#{key + 1}</b>
                                            </p>
                                            <p>
                                                {e.Nome} - {e.Codigo}
                                            </p>
                                        </Infos>
                                    )
                                }
                            )
                        ) : (
                            <p>
                                <div
                                    style={{
                                        height: 'auto',
                                        margin: '5vh 7vh',
                                        fontSize: '2.2vh',
                                        borderRadius: '1vh',
                                        padding: '3.5vh',
                                        boxShadow:
                                            '0px 2px 30px rgba(0, 0, 0, 0.05)'
                                    }}
                                >
                                    Não possui Viajantes cadastrados
                                </div>
                            </p>
                        )}
                    </div>

                    <div
                        id="adiantamento"
                        className="tab-pane fade show"
                        role="tabpanel"
                        aria-labelledby="adiantamento-tab"
                    >
                        {width && width > 768 ? (
                            solicitacao?.Adiantamentos?.length ? (
                                <Infos
                                    id="info"
                                    className="tab-pane fade show active"
                                    role="tabpanel"
                                    aria-labelledby="info-tab"
                                >
                                    <table
                                        className="table"
                                        style={{ color: '#6C7480' }}
                                    >
                                        <thead
                                            style={{
                                                background: '#FFFFFF',
                                                border: 'none !important'
                                            }}
                                        >
                                            <th
                                                style={{
                                                    width: '1rem',
                                                    border: '0 none !important',
                                                    textAlign: 'left',
                                                    borderColor: '#fff'
                                                }}
                                            >
                                                <p>Motivo</p>
                                            </th>

                                            <th
                                                style={{
                                                    width: '1rem',
                                                    border: 'none !important',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <p>Valor Total</p>
                                            </th>
                                        </thead>
                                        <tbody
                                            style={{
                                                borderTop: '#DFDFDF 0px'
                                            }}
                                        >
                                            <tr
                                                style={{
                                                    borderColor: '#fff'
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        width: '1rem',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <p>
                                                        {
                                                            adiantamentoPrincipal?.Descricao
                                                        }
                                                    </p>
                                                </td>

                                                <td
                                                    style={{
                                                        width: '1rem',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <p>
                                                        {IntlRealFormat(
                                                            valorTotal || 0
                                                        )}
                                                    </p>
                                                </td>
                                            </tr>

                                            <LineSeparator
                                                style={{
                                                    margin: '.3rem 0'
                                                }}
                                            ></LineSeparator>
                                            <Center>
                                                <h4
                                                    style={{
                                                        color: '#6C7480'
                                                    }}
                                                >
                                                    Divisão
                                                </h4>
                                            </Center>
                                            {solicitacao?.Adiantamentos?.map(
                                                (
                                                    adiantamento: any,
                                                    key: any
                                                ) => {
                                                    return (
                                                        <tr
                                                            key={key}
                                                            style={{
                                                                borderColor:
                                                                    '#fff'
                                                            }}
                                                        >
                                                            <td
                                                                style={{
                                                                    width: '1rem',
                                                                    textAlign:
                                                                        'left'
                                                                }}
                                                            >
                                                                <p>
                                                                    {_ShowViajanteCode(
                                                                        adiantamento.IdUsuario
                                                                    )}
                                                                    {' - '}
                                                                    {_ShowViajanteName(
                                                                        adiantamento.IdUsuario
                                                                    )}{' '}
                                                                </p>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '1rem',
                                                                    textAlign:
                                                                        'left'
                                                                }}
                                                            ></td>
                                                            <td
                                                                style={{
                                                                    width: '1rem',
                                                                    textAlign:
                                                                        'left'
                                                                }}
                                                            >
                                                                <p>
                                                                    {IntlRealFormat(
                                                                        adiantamento.Valor
                                                                    )}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </Infos>
                            ) : (
                                <Infos>
                                    Não possui adiantamento cadastrado
                                </Infos>
                            )
                        ) : solicitacao?.Adiantamentos?.length ? (
                            <Box>
                                <Quadro>
                                    <table
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <TrMobile>
                                            {getCampo(1, 'Motivo:', 'Motivo')}
                                            {getCampo(
                                                1,
                                                'Valor total:',
                                                '1111'
                                            )}

                                            <Center>
                                                <Info>Divisão</Info>
                                            </Center>

                                            {solicitacao?.Adiantamentos?.map(
                                                (
                                                    adiantamento: any,
                                                    index: any
                                                ) => {
                                                    const code =
                                                        _ShowViajanteCode(
                                                            adiantamento.IdUsuario
                                                        )
                                                    const name =
                                                        _ShowViajanteName(
                                                            adiantamento.IdUsuario
                                                        )
                                                    return (
                                                        <TrMobile key={index}>
                                                            <Separador>
                                                                {getCampo(
                                                                    index,
                                                                    'Viajante',
                                                                    ``
                                                                )}
                                                                {getCampo(
                                                                    index,
                                                                    'Valor',
                                                                    ''
                                                                )}
                                                            </Separador>
                                                            <Separador>
                                                                {getCampo(
                                                                    index,
                                                                    '',
                                                                    `${code} - ${name}`
                                                                )}
                                                                {getCampo(
                                                                    index,
                                                                    '',
                                                                    MoneyFormat(
                                                                        adiantamento.Valor
                                                                    )
                                                                )}
                                                            </Separador>
                                                        </TrMobile>
                                                    )
                                                }
                                            )}
                                        </TrMobile>
                                    </table>
                                </Quadro>
                            </Box>
                        ) : (
                            <Center>
                                {getNotFound(
                                    'Não possui adiantamento cadastrado'
                                )}
                            </Center>
                        )}
                    </div>
                    <div
                        id="aereo"
                        className="tab-pane fade show"
                        role="tabpanel"
                        aria-labelledby="aereo-tab"
                    >
                        <DisableForMobile>
                            <>
                                {reservas.length > 0 ? (
                                    <Infos>
                                        {reservas.map((reserva, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    style={{
                                                        border: '1px solid #BDBDBD',
                                                        margin: '1rem 1rem',
                                                        padding: '1rem'
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-3 title-passagem">
                                                            Origem
                                                        </div>
                                                        <div className="col-3 title-passagem">
                                                            Destino
                                                        </div>
                                                        <div className="col-2 title-passagem">
                                                            Escalas
                                                        </div>
                                                        <div className="col-2 title-passagem">
                                                            Companhia
                                                        </div>
                                                        <div className="col-2 title-passagem">
                                                            Valor
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row"
                                                        style={{
                                                            color: '#5F8DD3',
                                                            fontSize: 'medium'
                                                        }}
                                                    >
                                                        <div className="col-3">
                                                            {idaVolta ? (
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            {
                                                                                reserva.AeroportoOrigem
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className="col"
                                                                            style={{
                                                                                marginLeft:
                                                                                    '.5rem'
                                                                            }}
                                                                        >
                                                                            {reserva.DataIdaSaida?.toLocaleDateString(
                                                                                'pt-BR',
                                                                                {
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit'
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            {
                                                                                reserva.AeroportoDestino
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className="col"
                                                                            style={{
                                                                                marginLeft:
                                                                                    '.5rem'
                                                                            }}
                                                                        >
                                                                            {reserva.DataVoltaSaida?.toLocaleDateString(
                                                                                'pt-BR',
                                                                                {
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit'
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="row">
                                                                    <div className="col-2">
                                                                        {
                                                                            reserva.AeroportoOrigem
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className="col"
                                                                        style={{
                                                                            marginLeft:
                                                                                '.5rem'
                                                                        }}
                                                                    >
                                                                        {reserva.DataIdaSaida?.toLocaleDateString(
                                                                            'pt-BR',
                                                                            {
                                                                                hour: '2-digit',
                                                                                minute: '2-digit'
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-3">
                                                            {idaVolta ? (
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            {
                                                                                reserva.AeroportoDestino
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className="col"
                                                                            style={{
                                                                                marginLeft:
                                                                                    '.5rem'
                                                                            }}
                                                                        >
                                                                            {reserva.DataIdaChegada?.toLocaleDateString(
                                                                                'pt-BR',
                                                                                {
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit'
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            {
                                                                                reserva.AeroportoOrigem
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className="col"
                                                                            style={{
                                                                                marginLeft:
                                                                                    '.5rem'
                                                                            }}
                                                                        >
                                                                            {reserva.DataVoltaChegada?.toLocaleDateString(
                                                                                'pt-BR',
                                                                                {
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit'
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="row">
                                                                    <div className="col-2">
                                                                        {
                                                                            reserva.AeroportoDestino
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className="col"
                                                                        style={{
                                                                            marginLeft:
                                                                                '.5rem'
                                                                        }}
                                                                    >
                                                                        {reserva.DataIdaChegada?.toLocaleDateString(
                                                                            'pt-BR',
                                                                            {
                                                                                hour: '2-digit',
                                                                                minute: '2-digit'
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div
                                                            className="col-2"
                                                            style={{
                                                                alignContent:
                                                                    'center',
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center'
                                                            }}
                                                        >
                                                            {reserva.QtdEscalas ===
                                                            1 ? (
                                                                <div>
                                                                    {
                                                                        reserva.QtdEscalas
                                                                    }{' '}
                                                                    PARADAS
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    {
                                                                        reserva.QtdEscalas
                                                                    }{' '}
                                                                    PARADA
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div
                                                            className="col-2"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center'
                                                            }}
                                                        >
                                                            {renderLogoCia(
                                                                reserva.Companhia ||
                                                                    ''
                                                            )}
                                                        </div>
                                                        <div
                                                            className="col-2"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                fontWeight:
                                                                    'bold',
                                                                color: '#002A5D'
                                                            }}
                                                        >
                                                            {MoneyFormat(
                                                                reserva.Valor ||
                                                                    0
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Infos>
                                ) : (
                                    notFoundDesktop(
                                        'Não possui Aéreo cadastradas'
                                    )
                                )}
                            </>
                        </DisableForMobile>
                        <IsMobile>
                            {reservas.length > 0 ? (
                                getReservaMobile(reservas)
                            ) : (
                                <Center>
                                    {getNotFound(
                                        'Não possui Aéreo cadastradas'
                                    )}
                                </Center>
                            )}
                        </IsMobile>
                    </div>
                    <div
                        id="hospedagem"
                        className="tab-pane fade show"
                        role="tabpanel"
                        aria-labelledby="hospedagem-tab"
                    >
                        {width && width > 768 ? (
                            solicitacao?.Hospedagem?.length ? (
                                <Infos
                                    id="info"
                                    className="tab-pane fade show active"
                                    role="tabpanel"
                                    aria-labelledby="info-tab"
                                >
                                    <table
                                        className="table"
                                        style={{ color: '#6C7480' }}
                                    >
                                        <thead
                                            style={{
                                                background: '#FFFFFF',
                                                border: 'none !important'
                                            }}
                                        >
                                            <tr
                                                style={{
                                                    border: 'none !important'
                                                }}
                                            >
                                                <th
                                                    style={{
                                                        width: '3rem',
                                                        border: 'none !important'
                                                    }}
                                                >
                                                    <p>Cidade</p>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '5rem'
                                                    }}
                                                >
                                                    <p>Ponto de referência</p>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '2rem'
                                                    }}
                                                >
                                                    <p>Check-in</p>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '2rem'
                                                    }}
                                                >
                                                    <p>Check-out</p>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '5rem'
                                                    }}
                                                >
                                                    <p>Observação</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody
                                            style={{
                                                background: '#FFFFFF',
                                                borderTop: '#DFDFDF 1px'
                                            }}
                                        >
                                            {solicitacao?.Hospedagem?.map(
                                                (
                                                    hospedagem: any,
                                                    key: number
                                                ) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td
                                                                style={{
                                                                    width: '3rem'
                                                                }}
                                                            >
                                                                <p>
                                                                    {
                                                                        hospedagem.Cidade
                                                                    }
                                                                </p>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '5rem'
                                                                }}
                                                            >
                                                                <p>
                                                                    {
                                                                        hospedagem.Referencia
                                                                    }
                                                                </p>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '2rem'
                                                                }}
                                                            >
                                                                <p>
                                                                    {new Date(
                                                                        hospedagem.DataEntrada
                                                                    ).toLocaleDateString()}
                                                                </p>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '2rem'
                                                                }}
                                                            >
                                                                <p>
                                                                    {new Date(
                                                                        hospedagem.DataSaida
                                                                    ).toLocaleDateString()}
                                                                </p>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '5rem'
                                                                }}
                                                            >
                                                                <p>
                                                                    {
                                                                        hospedagem.Observacao
                                                                    }
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </Infos>
                            ) : (
                                <Infos>
                                    Não possui Hospedagens cadastradas
                                </Infos>
                            )
                        ) : solicitacao?.Hospedagem?.length ? (
                            solicitacao?.Hospedagem?.map(
                                (hospedagem: any, key: number) => {
                                    return (
                                        <Box key={key}>
                                            <Quadro>
                                                <table
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    <TrMobile>
                                                        {getCampo(
                                                            1,
                                                            'Cidade:',
                                                            hospedagem.Cidade
                                                        )}
                                                        {getCampo(
                                                            1,
                                                            'Ponto de referência:',
                                                            hospedagem.Referencia
                                                        )}
                                                        {getCampo(
                                                            1,
                                                            'Check-in:',
                                                            new Date(
                                                                hospedagem.DataEntrada
                                                            ).toLocaleDateString()
                                                        )}
                                                        {getCampo(
                                                            1,
                                                            'Check-out:',
                                                            new Date(
                                                                hospedagem.DataSaida
                                                            ).toLocaleDateString()
                                                        )}
                                                        {getCampo(
                                                            1,
                                                            'Observação:',
                                                            hospedagem.Observacao
                                                        )}
                                                    </TrMobile>
                                                </table>
                                            </Quadro>
                                        </Box>
                                    )
                                }
                            )
                        ) : (
                            <Center>
                                {getNotFound(
                                    'Não possui Hospedagens cadastradas'
                                )}
                            </Center>
                        )}
                    </div>
                    <div
                        id="servicos"
                        className="tab-pane fade show"
                        role="tabpanel"
                        aria-labelledby="servicos-tab"
                    >
                        {width && width > 768 ? (
                            solicitacao?.Servico?.length ? (
                                <Infos
                                    className="tab-pane fade show active"
                                    role="tabpanel"
                                    aria-labelledby="info-tab"
                                >
                                    <table
                                        className="table"
                                        style={{ color: '#6C7480' }}
                                    >
                                        <thead
                                            style={{
                                                background: '#FFFFFF',
                                                border: 'none !important'
                                            }}
                                        >
                                            <tr
                                                style={{
                                                    border: 'none !important'
                                                }}
                                            >
                                                <th
                                                    style={{
                                                        width: '1rem',
                                                        border: 'none !important',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <p>Serviço</p>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '1rem',
                                                        border: 'none !important',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <p>Observação</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody
                                            style={{
                                                background: '#FFFFFF',
                                                borderTop: '#DFDFDF 1px'
                                            }}
                                        >
                                            {solicitacao?.Servico?.map(
                                                (servico: any, key: number) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td
                                                                style={{
                                                                    width: '1rem',
                                                                    textAlign:
                                                                        'left'
                                                                }}
                                                            >
                                                                <p>
                                                                    {_ShowServicos(
                                                                        servico
                                                                    )}
                                                                </p>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '1rem',
                                                                    textAlign:
                                                                        'left'
                                                                }}
                                                            >
                                                                <p>
                                                                    {
                                                                        servico.Observacao
                                                                    }
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </Infos>
                            ) : (
                                <Infos>Não possui Serviços cadastrados</Infos>
                            )
                        ) : solicitacao?.Servico?.length ? (
                            solicitacao?.Servico?.map(
                                (servico: any, key: number) => {
                                    return (
                                        <Center key={key}>
                                            <Box>
                                                <Quadro>
                                                    <table
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <TrMobile>
                                                            {getCampo(
                                                                key,
                                                                'Serviço:',
                                                                _ShowServicos(
                                                                    servico
                                                                )
                                                            )}
                                                            {getCampo(
                                                                key,
                                                                'Codigo:',
                                                                servico.Observacao
                                                            )}
                                                        </TrMobile>
                                                    </table>
                                                </Quadro>
                                            </Box>
                                        </Center>
                                    )
                                }
                            )
                        ) : (
                            <Center>
                                {getNotFound('Não possui Serviços cadastrados')}
                            </Center>
                        )}
                    </div>
                    <div
                        id="rateio"
                        className="tab-pane fade show"
                        role="tabpanel"
                        aria-labelledby="rateio-tab"
                    >
                        {width && width > 768 ? (
                            solicitacao?.Rateio?.length ? (
                                <Infos
                                    className="tab-pane fade show active"
                                    role="tabpanel"
                                    aria-labelledby="info-tab"
                                >
                                    <table
                                        className="table"
                                        style={{ color: '#6C7480' }}
                                    >
                                        <thead
                                            style={{
                                                background: '#FFFFFF',
                                                border: 'none !important'
                                            }}
                                        >
                                            <tr
                                                style={{
                                                    border: 'none !important'
                                                }}
                                            >
                                                <th
                                                    style={{
                                                        width: '1rem',
                                                        border: 'none !important',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <p>Filial</p>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '1rem',
                                                        border: 'none !important',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <p>Centro de custo</p>
                                                </th>
                                                <th
                                                    style={{
                                                        width: '1rem',
                                                        border: 'none !important'
                                                    }}
                                                >
                                                    <p>Percentual (%)</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody
                                            style={{
                                                background: '#FFFFFF',
                                                borderTop: '#DFDFDF 1px'
                                            }}
                                        >
                                            {solicitacao?.Rateio?.map(
                                                (rateio: any, key: number) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td
                                                                style={{
                                                                    width: '1rem',
                                                                    textAlign:
                                                                        'left'
                                                                }}
                                                            >
                                                                <p>
                                                                    {_ShowFilial(
                                                                        rateio.IdFilial
                                                                    )}
                                                                </p>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '1rem',
                                                                    textAlign:
                                                                        'left'
                                                                }}
                                                            >
                                                                <p>
                                                                    {_ShowCentroDeCusto(
                                                                        rateio.IdContasContabeis
                                                                    )}
                                                                </p>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '1rem'
                                                                }}
                                                            >
                                                                <p>
                                                                    {
                                                                        rateio.Percentual
                                                                    }
                                                                    %
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </Infos>
                            ) : (
                                <p>
                                    <div
                                        style={{
                                            height: 'auto',
                                            margin: '5vh 7vh',
                                            fontSize: '2.2vh',
                                            borderRadius: '1vh',
                                            padding: '3.5vh',
                                            boxShadow:
                                                '0px 2px 30px rgba(0, 0, 0, 0.05)'
                                        }}
                                    >
                                        Não possui Rateios cadastrados
                                    </div>
                                </p>
                            )
                        ) : solicitacao?.Rateio?.length ? (
                            solicitacao?.Rateio?.map(
                                (rateio: any, key: number) => {
                                    return (
                                        <Center key={key}>
                                            <Box>
                                                <Quadro>
                                                    <table
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <TrMobile>
                                                            {getCampo(
                                                                1,
                                                                'Filial:',
                                                                _ShowFilial(
                                                                    rateio.IdFilial
                                                                )
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Centro de custo:',
                                                                _ShowCentroDeCusto(
                                                                    rateio.IdContasContabeis
                                                                )
                                                            )}
                                                            {getCampo(
                                                                1,
                                                                'Percentual (%):',
                                                                `${rateio.Percentual} %`
                                                            )}
                                                        </TrMobile>
                                                    </table>
                                                </Quadro>
                                            </Box>
                                        </Center>
                                    )
                                }
                            )
                        ) : (
                            <Center>
                                {getNotFound('Não possui Rateios cadastrados')}
                            </Center>
                        )}
                    </div>
                </article>
            </ContainerPadding>
            <Space height="3rem"></Space>
            <Center>
                <DisableForMobile>
                    <Button
                        icon="print"
                        colorType="quaternary"
                        type="submit"
                        onClick={() => history.push(`/impressao/${Id}`)}
                    >
                        Ir para Impressão
                    </Button>
                </DisableForMobile>
                <IsMobile>
                    <Button
                        icon="print"
                        colorType="btnMobile"
                        type="submit"
                        onClick={() => history.push(`/impressao/${Id}`)}
                    >
                        Ir para Impressão
                    </Button>
                </IsMobile>
            </Center>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </BlankBoard>
    )
}

const navItem = {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2.1vh'
}

const Infos = styled.div`
    @media (min-width: 800px) {
        height: auto;
        margin: 5vh 7vh;
        font-size: 1.5vh;
        border-radius: 1vh;
        padding: 3.5vh;
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.05);
    }
`
const ContainerLogo = styled.div`
    width: 80px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: end;
    align-items: left;
    margin: 0 auto;

    img {
        width: 100%;
    }
`
// const Box = styled.div`
//     border: solid 1px lightgray;
//     border-radius: 15px;
//     @media (max-width: 768px) {
//         border: solid 0px lightgray;
//         border-radius: 0px;
//     }
// `

// const TrMobile = styled.tr`
//     border: 0 !important;
//     z-index: 10;
// `
// const Quadro = styled.div`
//     box-sizing: border-box;

//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     padding: 1rem;
//     gap: 8px;

//     width: 342px;
//     height: 0 auto;
//     min-height: 218px;
//     background: #f1f3f5;
//     border: 1px solid #dee2e6;

//     box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
//     border-radius: 8px;

//     flex: none;
//     order: 0;
//     flex-grow: 0;
//     margin: 1rem 0;
// `
const Info = styled.p`
    height: auto;
    margin-top: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
`
const Separador = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

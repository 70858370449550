export const BASE_URL_DEV = process.env.REACT_APP_API
export const BASE_URL_AEREO = process.env.REACT_APP_AEREO_API

export const PESQUISA = '/Pesquisar'

export const ENDPOINTS = {
    pesquisaSolicitacaoViagem: 'SolicitacaoViagem/Pesquisar',
    solicitarViagem: 'SolicitacaoViagem',
    deletarSolicitacaoViagemTemp: 'SolicitacaoViagem/DeletarSolicitacao',
    solicitarViagemEtapas: 'SolicitacaoViagem/:Id/etapas',
    cotarSolicitarViagem: 'SolicitacaoViagem/Cotar/:Id',
    situacao: 'Situacao',
    setor: 'Setor',
    filial: 'Filial',
    usuarioII: 'NovoUsuario',
    usuario: 'Usuario',
    contasContabeis: 'ContasContabeis',
    pesquisarContasContabeis: 'ContasContabeis/Pesquisar',
    motivoViagem: 'MotivoViagem',
    motivoViagemById: 'MotivoViagem/Id',
    aeroporto: 'Aeroporto',
    prestarContas: 'PrestacaoContas',
    solicitacaoViagemPorId: 'SolicitacaoViagem',
    prestacaoContasPesquisar: 'PrestacaoContas/Pesquisar',
    prestarContasDespesas: 'PrestacaoContasDespesas',
    statusPrestacaoIndividual:
        'PrestacaoContas/BuscarStatusPorIdSolicitacao?idsolicitacao=',
    adiantamento: 'Adiantamento',
    adiantamentoByIdSolicitacao: 'Adiantamento/',
    motivoViagemPesquisar: 'MotivoViagem/Pesquisar',
    getFilialById: 'Filial/Id?Id=',
    buscarFilialPorCodigo: 'Filial/Codigo',
    buscarFilialPorNome: 'Filial/Nome',
    pesquisarFilial: 'Filial/Pesquisar',
    empresa: 'Empresa',
    pesquisarEmpresa: 'Empresa/Pesquisar',
    regiaoListar: 'Regiao/',
    regiao: 'Regiao/Pesquisar',
    viajanteByIdSolicitacao: 'Viajante/BuscarPorIdSolicitacao',
    viajante: 'Viajante',
    regiaoPesquisar: 'Regiao/Pesquisar',
    regiaoPesquisarId: 'Regiao/Id',
    rateio: 'Rateio',
    usuarioPesquisar: 'Usuario/Pesquisar',
    usuarioPesquisarId: 'Usuario/Id',
    usuarioByCodigo: 'Usuario/:Codigo/BuscarPorCodigo',
    politicaViagem: 'PoliticaViagem',
    politicaPermissaoPorIdPolitica: 'PoliticaPermissao/BuscarPorIdPolitica',
    politicaPermissao: 'PoliticaPermissao',
    politicaPermissaoNovo:
        'NovoPoliticaPermissao/BuscarPorIdUsuarioSolicitante',
    regrasPoliticaViagemById: 'RegrasPoliticaViagem/Id',
    regrasPoliticaViagem: 'RegrasPoliticaViagem',
    regrasPoliticaViagemTipoSolicitacao:
        'RegrasPoliticaViagem/TiposSolicitacao/Id',
    inativarRegra: 'RegrasPoliticaViagem/InativarRegra',
    impressao: 'Impressao/Id',
    hospedagem: 'Hospedagem',
    hospedagemById: 'Hospedagem/Id',
    hospedagemByIdSolicitacao: 'Hospedagem/:idSolicitacao/solicitacao',
    aeroportoListar: 'Aeroporto',
    roteiroByIdSolicitacao: 'Roteiro/IdSolicitacaoViagem',
    roteiro: 'Roteiro',
    tipoDespesa: 'TipoDespesa/Pesquisar',
    tipoDespesaAll: 'TipoDespesa/Pesquisar?TotalRegisterPage=10000',
    tipoDespesaPorId: 'TipoDespesa',
    tipoSolicitacao: 'TipoSolicitacao',
    servico: 'Servico',
    servicoPesquisar: 'NovoServico/Pesquisar',
    servicoSolicitacaoById: 'ServicoSolicitacao/',
    servicoSolicitacao: 'ServicoSolicitacao',
    historico: 'HistoricoSolicitacaoViagem/Id',
    pesquisarUsuarios: 'Usuario/Pesquisar',
    hierarquia: 'Hierarquia',
    AncoradouroSistemasAereo: 'Ancoradouro/sistemas',
    AncoradouroVoos: 'Ancoradouro/voos',
    autenticarUsuario_old: 'Usuario/Autenticar',
    autenticarUsuario: 'NovoUsuario/Autenticar',
    novaEtapaViagem: 'ViagemComEtapas',
    aereo: 'Aereo',
    aereoResumido: 'Aereo/:idSolicitacao/BuscaResumida',
    aereoByIdSolicitacao: 'Aereo/:idSolicitacao',
    cotacaoAereo: 'CotacaoAereo',
    cotacaoAereoByIdSolicitacao: 'CotacaoAereo/BuscarPorIdSolicitacao',
    cotacaoHospedagem: 'CotacaoHospedagem',
    cotacaoHospedagemPorIdHospedagem:
        'CotacaoHospedagem/:idHospedagem/hospedagem',
    cotacaoServico: 'CotacaoServico',
    getSetorById: 'Setor/Id?id=',
    cotacaoHospedagemBySolicitacao: 'CotacaoHospedagem/BuscarPorIdSolicitacao',
    cotacaoServicoBuscarPorIdSolicitacao:
        'CotacaoServico/BuscarPorIdSolicitacao',
    buscarVoos: 'Ancoradouro/Buscar',
    reservarVoos: 'Ancoradouro/Reservar',
    cancelarReserva: 'Ancoradouro/Cancelar',
    conexaoUsuarioHierarquia: 'ConexaoUsuarioHierarquia/BuscarPorHierarquia',
    conexaoUsuarioHierarquiaIncluir: 'ConexaoUsuarioHierarquia',
    conexaoUsuarioHierarquiaInativar:
        'ConexaoUsuarioHierarquia/InativarUsuarioHierarquia',
    conexaoUsuarioHierarquiaCodigo: 'ConexaoUsuarioHierarquia',
    conexaoUsuarioHierarquiaIdUsuario:
        'ConexaoUsuarioHierarquia/:idUsuario/usuario',
    tipoDespesaIncluir: 'TipoDespesa',
    editartipoDespesa: 'TipoDespesa',
    inativarHierarquia: 'Hierarquia/Desativar'
}

import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useHistory } from 'react-router'
import { UserContext } from '../../../contexts/UserContext'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button'
import { Flex } from '../../../core/components/Flex'
import { Header } from '../../../core/components/Header/Header'
import { Space } from '../../../core/components/Space'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { Form } from '@unform/web'
import { Input } from '../../../core/components/Form/Input'
import { get, post } from '../../../services/http'
import { ENDPOINTS, PESQUISA } from '../../../services/config-http'
import { Alert } from '../../../core/components/Alert'
import { Select } from '../../../core/components/Form/Select'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { useAuth } from '../../../contexts/Auth'

import {
    TableRequester,
    Table,
    ConfigTable
} from './../../../core/components/Table/v2/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Center } from '../../../core/components/Center'
import { Subtitle } from '../../../core/components/Subtitle'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { DisableForMobile, IsMobile } from '../../Viagem/Solicitacao/stepMobile'

export const ListarMotivoViagem = () => {
    const history = useHistory()
    const [administrador, setAdministrador] = useState(false)
    const dadosUsuario = useContext(UserContext)
    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })

    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    const [payloadSearch, setPayloadSearch] = useState<TableRequester>({
        endpoint: ENDPOINTS.motivoViagemPesquisar,
        method: 'GET',
        payload: {}
    })

    const MapperItem = [
        { field: 'Id', replaceTo: 'Código', position: 1 },
        { field: 'Descricao', replaceTo: 'Descrição', position: 2 },
        { field: 'Status', visualCustom: _pipeStatus, position: 3 }
    ]

    function _pipeStatus(value: boolean): JSX.Element {
        if (value) {
            return (
                <span
                    className="badge status-success"
                    style={{ width: '75px', margin: 'auto' }}
                >
                    <FontAwesomeIcon icon="circle" />
                    &ensp; {'ATIVO'}
                </span>
            )
        } else {
            return (
                <span
                    className="badge status-danger"
                    style={{ width: '75px', margin: 'auto' }}
                >
                    <FontAwesomeIcon icon="circle" />
                    &ensp; {'INATIVO'}
                </span>
            )
        }
    }

    const tableConfig: ConfigTable = {
        edit: true,
        delete: false,
        editAction: item => {
            history.push(`/cadastros/motivo-viagem/editar/${item.Id}`)
        }
    }

    const StatusOptions = [
        {
            value: '1',
            label: 'ATIVO'
        },
        {
            value: '0',
            label: 'INATIVO'
        }
    ]

    const FormRef = useRef<FormHandles>(null)

    const handleSubmit: SubmitHandler<FormData> = (data: any) => {
        Object.keys(data).forEach(
            k => !data[k] && data[k] !== undefined && delete data[k] // eliminando valores não preenchidos para envio da query de busca
        )
        data.page = 0
        data.lastCount = 0
        data.totalRegisterPage = 10
        const newPayloadSearch = { ...payloadSearch, payload: data }
        setPayloadSearch(newPayloadSearch)
    }
    return (
        <BlankBoard>
            <Header
                title="Motivo Viagem"
                actionArrowClick={() => history.push('/solicitacoes')}
            ></Header>
            <Center>
                <Subtitle>Dados motivo viagem</Subtitle>
            </Center>
            {administrador ? (
                <>
                    <Flex justifyContent="flex-end">
                        <DisableForMobile>
                            <Button
                                style={{
                                    marginRight: '-3.2rem'
                                }}
                                icon="plus"
                                colorType="quaternary"
                                onClick={() =>
                                    history.push(
                                        '/cadastros/motivo-viagem/incluir'
                                    )
                                }
                            >
                                Cadastrar
                            </Button>
                        </DisableForMobile>
                    </Flex>
                    <Center>
                        <IsMobile>
                            <Button
                                icon="plus"
                                colorType="btnMobile"
                                onClick={() =>
                                    history.push(
                                        '/cadastros/motivo-viagem/incluir'
                                    )
                                }
                            >
                                Cadastrar
                            </Button>
                        </IsMobile>
                    </Center>
                    <LineSeparator></LineSeparator>
                    <Form
                        ref={FormRef}
                        onSubmit={handleSubmit}
                        style={{ padding: '0px' }}
                    >
                        <div className="row">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '1rem'
                                }}
                            >
                                <Button
                                    style={{
                                        margin: '0 .2rem',
                                        padding: '0 15px'
                                    }}
                                    onlyIcon={true}
                                    icon="search"
                                    title="Pesquisar"
                                    colorType="primary"
                                ></Button>
                            </div>

                            <div className="col-12 col-sm-4">
                                <Input
                                    name="Id"
                                    label="Código"
                                    placeholder="Digite o código"
                                ></Input>
                            </div>
                            <div className="col-12 col-sm-4">
                                <Input
                                    name="Descricao"
                                    label="Nome"
                                    placeholder="Digite o nome"
                                ></Input>
                            </div>
                            <div className="col-12 col-sm-4">
                                <Select
                                    name="Status"
                                    options={StatusOptions}
                                    placeholder="Selecione o status"
                                ></Select>
                            </div>
                        </div>
                    </Form>
                    <Alert
                        message={showAlert.message}
                        status={showAlert.status}
                        type={showAlert.type as any}
                    ></Alert>
                    <Table
                        requester={payloadSearch}
                        mapper={MapperItem}
                        config={tableConfig}
                    ></Table>
                </>
            ) : (
                <Alert
                    fixed={true}
                    icon="exclamation-circle"
                    type="primary"
                    message="Você não tem permissão de administrador"
                ></Alert>
            )}
        </BlankBoard>
    )
}

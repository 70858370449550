import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

interface Props {
    text: string
}

export const NoHasData = ({ text }: Props) => (
    <div className="d-flex justify-content-center align-items-center flex-column gray-text">
        <FontAwesomeIcon icon="times-circle" size="4x" />
        <Text>{text}</Text>
    </div>
)

const Text = styled.p`
    margin: 1rem 2rem;
`

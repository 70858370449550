import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from '@unform/web'
import { LineSeparator } from '../../../../core/components/LineSeparator'
import { FormHandles, SubmitHandler } from '@unform/core'
import './modaltiposolicitacao.css'
import { Button } from '../../../../core/components/Button'
import { put } from '../../../../services/http'
import { ENDPOINTS } from '../../../../services/config-http'
import { Toast } from '../../../../core/components/Toast'
import { Center } from '../../../../core/components/Center'

interface ModalProp {
    id: number
    nome: string
    statusTipoSolicitacao: boolean
    show?: boolean
    setShow: any
}

export const ModalConfirmacao = ({
    id,
    nome,
    statusTipoSolicitacao,
    show,
    setShow
}: ModalProp) => {
    const formRef = useRef<FormHandles>(null)
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    const handleSubmit: SubmitHandler = async () => {
        console.log(id, nome, !statusTipoSolicitacao)

        await put(ENDPOINTS.tipoSolicitacao, {
            id: id,
            nome: nome,
            status: !statusTipoSolicitacao
        })
            .then(() => {
                setShow(false)
                setShowToast(true)
                setToast({
                    message: statusTipoSolicitacao
                        ? `${nome} inativado com sucesso`
                        : `${nome} ativado com sucesso`,
                    type: 'success'
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1500)
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    message: err,
                    type: 'error'
                })
            })
    }

    useEffect(() => {
        console.log(show)
        if (show) {
            document
                .getElementById('modal-confirmacao')
                ?.classList.remove('hidden')
        } else {
            document
                .getElementById('modal-confirmacao')
                ?.classList.add('hidden')
        }
    }, [show])

    return (
        <>
            <div className="modal-tipo-solicitacao" id="modal-confirmacao">
                <div
                    style={{
                        borderRadius: '0.5rem',
                        padding: '1rem',
                        marginTop: '3.5rem',
                        backgroundColor: 'white',
                        width: '60rem',
                        height: '22rem'
                    }}
                >
                    <div className="modal-tipo-solicitacao-header">
                        <div style={{ width: '5%' }}></div>
                        <h1
                            style={{
                                fontWeight: 'bold',
                                color: `${
                                    statusTipoSolicitacao
                                        ? '#FF6C63'
                                        : '#002A5D'
                                }`,
                                width: '90%',
                                textAlign: 'center'
                            }}
                        >
                            {statusTipoSolicitacao
                                ? 'Inativar Tipo Solicitação'
                                : 'Ativar Tipo Solicitação'}
                        </h1>
                        <button
                            onClick={e => setShow(false)}
                            className="close-modal-tipo-solicitacao"
                            style={{
                                width: '5%',
                                textAlign: 'center'
                            }}
                        >
                            <FontAwesomeIcon icon="times" />
                        </button>
                    </div>
                    <div className="modal-tipo-solicitacao-body">
                        <LineSeparator
                            style={{ margin: '.3rem 2rem' }}
                        ></LineSeparator>
                        <Center>
                            <h5>Tipo Solicitação: {nome}</h5>
                        </Center>
                        <Form
                            onSubmit={handleSubmit}
                            ref={formRef}
                            style={{ padding: 0 }}
                        >
                            <Center>
                                <p
                                    style={{
                                        fontSize: '1rem',
                                        textAlign: 'center',
                                        padding: '1rem'
                                    }}
                                >
                                    Deseja realmente confirmar a{' '}
                                    {statusTipoSolicitacao
                                        ? 'inativação '
                                        : 'ativação '}
                                    deste Tipo Solicitação para todas as
                                    Politicas em que a mesma está cadastrada?
                                </p>
                            </Center>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '1rem'
                                }}
                            >
                                <Button
                                    type="button"
                                    colorType=""
                                    style={{
                                        backgroundColor: 'gray',
                                        color: 'white'
                                    }}
                                    icon="save"
                                    onClick={() => setShow(false)}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    colorType={
                                        statusTipoSolicitacao
                                            ? 'danger'
                                            : 'success'
                                    }
                                    icon="save"
                                    style={{ marginLeft: '1rem' }}
                                >
                                    {statusTipoSolicitacao
                                        ? 'Inativar'
                                        : 'Ativar'}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </>
    )
}

import React, { useState, useEffect, useContext } from 'react'
// import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { Center } from '../../../core/components/Center'
import { Empty } from '../../../core/components/Empty'
import { Space } from '../../../core/components/Space'
import { get, patch, destroy } from '../../../services/http'
import { ENDPOINTS } from '../../../services/config-http'
import { Aereo } from '../../../contexts/interfaces/aereo'
import { useHistory, useParams } from 'react-router'
import { CotacaoAereo as ICotacaoAereo } from '../../../contexts/interfaces/cotacaoAereo'
import { Toast } from '../../../core/components/Toast'
import { Deletar } from '../../../core/components/Deletar'
import { useSpinner } from '../../../hooks/Spinner'
import { MoneyFormat } from '../../../core/utils/MoneyFormat'
import { Button } from '../../../core/components/Button'
import { UserContext } from '../../../contexts/UserContext'
import { Alert } from '../../../core/components/Alert'
import styled from 'styled-components'

export const CotacaoAereo = () => {
    const { setLoading } = useSpinner()
    const history = useHistory()
    const [listaAeroportos, setListaAeroportos] = useState<Array<any>>([])
    const { Id } = useParams() as any
    const [reservas, setReservas] = useState<Aereo[]>([])
    const [showToast, setShowToast] = useState(false)
    const [qtdViajantes, setQtdViajantes] = useState(0)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    const [administrador, setAdministrador] = useState(false)
    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        const buscaAereo = async () => {
            setReservas([])
            await get(`${ENDPOINTS.viajanteByIdSolicitacao}/${Id}`)
                .then(({ data: { Model } }) => {
                    setQtdViajantes(Model.length)
                })
                .catch(e => {
                    setShowToast(true)
                    setToast({
                        message: 'Não foi possível buscar os viajantes',
                        type: 'error'
                    })
                    return e
                })
            await get(
                `${ENDPOINTS.aereoByIdSolicitacao.replace(
                    ':idSolicitacao',
                    Id
                )}/`
            )
                .then(({ data: { Model } }) => {
                    Model?.forEach(async (element: any) => {
                        const {
                            Id,
                            DataIdaChegada,
                            DataIdaSaida,
                            DataVoltaChegada,
                            DataVoltaSaida,
                            IdAeroportoDestino,
                            IdAeroportoOrigem,
                            IdaVolta,
                            Bagagem
                        } = element
                        const newAereo: Aereo = {
                            DataIdaChegada: new Date(DataIdaChegada),
                            DataIdaSaida: new Date(DataIdaSaida),
                            DataVoltaChegada: new Date(DataVoltaChegada),
                            DataVoltaSaida: new Date(DataVoltaSaida),
                            Id: Id,
                            IdAeroportoDestino: IdAeroportoDestino,
                            IdAeroportoOrigem: IdAeroportoOrigem,
                            IdaVolta: IdaVolta,
                            Bagagem: Bagagem
                        }
                        await get(`${ENDPOINTS.cotacaoAereo}/${Id}`).then(
                            ({ data: { Model } }) => {
                                if (Model) {
                                    const newCotacao: ICotacaoAereo = {
                                        Id: Model?.Id,
                                        IdAereo: Model?.IdAereo,
                                        Total: Model?.Total,
                                        Localizador: Model?.Localizador,
                                        Companhia: nomeCompanhia(
                                            Model?.Companhia
                                        ),
                                        VencimentoReserva: new Date(
                                            Model?.VencimentoReserva
                                        ),
                                        Status: Model?.Status
                                    }
                                    newAereo.Cotacao = newCotacao
                                } else {
                                    newAereo.Cotacao = undefined
                                }
                            }
                        )

                        setReservas(reservas => [...reservas, newAereo])
                    })
                })
                .catch(err => {
                    setShowToast(true)
                    setToast({
                        message: 'Não foi possível buscar as cotações do aereo',
                        type: 'error'
                    })
                    return err
                })
        }
        buscaAereo()

        const getAeroportos = async () => {
            await get(ENDPOINTS.aeroporto)
                .then(({ data: { Model } }) => {
                    setListaAeroportos(Model)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        getAeroportos()
    }, [])

    function _pipeShowAeroporto(value: any) {
        if (typeof value === 'number') {
            const tipo = listaAeroportos.filter(item => item.Id === value)[0]
            return <>{tipo?.Cidade || ''}</>
        } else if (typeof value === 'string') {
            return <>{value}</>
        }
    }

    const handleSubmitEmitir = async (position: number) => {
        const cotacao: ICotacaoAereo = {
            Id: reservas[position].Cotacao?.Id,
            IdAereo: reservas[position].Cotacao?.IdAereo,
            Total: reservas[position].Cotacao?.Total,
            Localizador: reservas[position].Cotacao?.Localizador,
            Companhia: reservas[position].Cotacao?.Companhia,
            VencimentoReserva: new Date(
                reservas[position].Cotacao?.VencimentoReserva || ''
            ),
            Status: 1,
            Observacao: reservas[position].Cotacao?.Observacao
        }
        setLoading(true)
        await patch(`${ENDPOINTS.cotacaoAereo}/`, {
            id: reservas[position].Cotacao?.Id,
            cotado: true
        })
            .then(res => {
                reservas[position].Cotacao = cotacao
                setReservas([...reservas])
                setLoading(false)
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    type: 'Error',
                    message: 'Não Foi possível emitir cotação'
                })
                return err
            })
    }

    const handleDeleteAereo = async (posicaoAereo: number) => {
        const aereo = reservas.splice(posicaoAereo, 1)[0]
        await destroy(`${ENDPOINTS.cotacaoAereo}/${aereo.Cotacao?.Id}`)
            .then(res => {
                destroy(`${ENDPOINTS.aereo}/${aereo.Id}`)
                    .then(res => {
                        setShowToast(true)
                        setToast({
                            type: 'success',
                            message: 'Reserva deletada com sucesso'
                        })
                    })
                    .catch(err => {
                        setShowToast(true)
                        setToast({
                            type: 'Error',
                            message: 'Não Foi possível deletar a cotação'
                        })
                        return err
                    })
            })
            .catch(err => {
                setShowToast(true)
                setToast({
                    type: 'Error',
                    message: 'Não Foi possível deletar a cotação'
                })
                return err
            })
    }

    const nomeCompanhia = (cia: string) => {
        switch (cia) {
            case 'G3':
                return 'Gol'
            case 'AD':
                return 'Azul'
            case 'JJ':
                return 'Latam'
            default:
                break
        }
    }

    return (
        <>
            {administrador ? (
                <>
                    <div
                        className="col-12 d-flex justify-content-end"
                        style={{ padding: '15px 0' }}
                    >
                        <Button
                            colorType="quaternary"
                            onClick={() => history.push(`/novo-aereo/${Id}`)}
                            icon="plus"
                        >
                            Adicionar
                        </Button>
                    </div>
                    <Space height="2rem"></Space>
                    <div className="row">
                        {reservas.length ? (
                            reservas.map((item, index) => (
                                <div key={index} className="content">
                                    <div
                                        className="col-12"
                                        style={{
                                            padding: '1rem',
                                            border: 'solid 1px lightgray',
                                            borderRadius: '7px',
                                            marginBottom: '2rem'
                                        }}
                                    >
                                        <div
                                            className="row"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div className="col-2">
                                                <label>
                                                    <Text>Origem</Text>
                                                </label>
                                                <p>
                                                    {_pipeShowAeroporto(
                                                        item?.IdAeroportoOrigem
                                                    )}
                                                </p>
                                                {item.IdaVolta ? (
                                                    <p>
                                                        {_pipeShowAeroporto(
                                                            item?.IdAeroportoDestino
                                                        )}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className="col-2">
                                                <label>
                                                    <Text>Destino</Text>
                                                </label>
                                                <p>
                                                    {_pipeShowAeroporto(
                                                        item?.IdAeroportoDestino
                                                    )}
                                                </p>
                                                {item.IdaVolta ? (
                                                    <p>
                                                        {_pipeShowAeroporto(
                                                            item?.IdAeroportoOrigem
                                                        )}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className="col-2">
                                                <label>
                                                    <Text>Qtd. Viajantes</Text>
                                                </label>
                                                <p>{qtdViajantes}</p>
                                            </div>
                                            <div className="col-4">
                                                <label>
                                                    <Text>Bagagem</Text>
                                                </label>
                                                <p>
                                                    {item.Bagagem
                                                        ? 'Sim'
                                                        : 'Não'}
                                                </p>
                                            </div>
                                            <div className="col-2">
                                                {item.Cotacao?.Status === 0 ? (
                                                    <Deletar
                                                        remover={() => {
                                                            handleDeleteAereo(
                                                                index
                                                            )
                                                        }}
                                                    />
                                                ) : null}
                                            </div>
                                            <div className="col-1">
                                                <button
                                                    style={{
                                                        backgroundColor: '#FFF',
                                                        color: '#6C7480',
                                                        border: 0,
                                                        padding:
                                                            '0.3rem 0.8rem',
                                                        borderRadius: '15px'
                                                    }}
                                                ></button>
                                            </div>
                                        </div>
                                        <LineSeparator
                                            style={{ margin: '.3rem 0' }}
                                        ></LineSeparator>
                                        <Center>
                                            <h4 style={{ color: '#6C7480' }}>
                                                Cotações
                                            </h4>
                                        </Center>
                                        {item.Cotacao !== null &&
                                        item.Cotacao !== undefined ? (
                                            <div className="row">
                                                <div className="col col-sm-2">
                                                    <label>
                                                        <Text>
                                                            Status da cotação
                                                        </Text>
                                                    </label>
                                                    {item.Cotacao.Status ? (
                                                        <span
                                                            className="badge status-success"
                                                            style={{
                                                                width: '80px'
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon="circle" />
                                                            &ensp; {'Emitido'}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            className="badge status-pendding"
                                                            style={{
                                                                width: '150px'
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon="circle" />
                                                            &ensp;{' '}
                                                            {
                                                                'Aguardando emissão'
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col col-sm-2">
                                                    <label>
                                                        <Text>Localizador</Text>
                                                    </label>
                                                    <p>
                                                        {
                                                            item.Cotacao
                                                                .Localizador
                                                        }
                                                    </p>
                                                </div>
                                                <div className="col col-sm-2">
                                                    <label>
                                                        <Text>Companhia</Text>
                                                    </label>
                                                    <p>
                                                        {item.Cotacao.Companhia}
                                                    </p>
                                                </div>
                                                <div className="col col-sm-4">
                                                    <label>
                                                        <Text>Valor Total</Text>
                                                    </label>
                                                    <p>
                                                        {MoneyFormat(
                                                            Number(
                                                                item.Cotacao
                                                                    .Total
                                                            )
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="col-1">
                                                    {item.Cotacao.Status ===
                                                    0 ? (
                                                        <button
                                                            onClick={e =>
                                                                handleSubmitEmitir(
                                                                    index
                                                                )
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    '#002a5d',
                                                                color: '#fff',
                                                                border: 0,
                                                                padding:
                                                                    '0.3rem 1.1rem',
                                                                borderRadius:
                                                                    '15px'
                                                            }}
                                                        >
                                                            Emitir
                                                        </button>
                                                    ) : (
                                                        <button
                                                            style={{
                                                                backgroundColor:
                                                                    '#c2c2c2',
                                                                color: '#FFF',
                                                                border: 0,
                                                                padding:
                                                                    '0.3rem 1.1rem',
                                                                borderRadius:
                                                                    '15px'
                                                            }}
                                                        >
                                                            Emitir
                                                        </button>
                                                    )}
                                                </div>
                                                <div className="col col-sm-1"></div>
                                                <div className="col col-sm-6">
                                                    <label>
                                                        <Text>
                                                            Observação da
                                                            cotação
                                                        </Text>
                                                    </label>
                                                    <p>
                                                        {item.Cotacao
                                                            .Observacao ??
                                                            'Sem Observação'}
                                                    </p>
                                                </div>
                                                <div className="col col-sm-2">
                                                    <label>
                                                        <Text>
                                                            Vencimento Reserva
                                                        </Text>
                                                    </label>
                                                    <p>
                                                        {item.Cotacao.VencimentoReserva?.toLocaleDateString()}
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            <Center>
                                                <Empty text="Não há cotações cadastradas" />
                                            </Center>
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <Center>
                                <div
                                    className="alert alert-info d-flex align-items-center mb-3 mt-2"
                                    role="alert"
                                >
                                    Não foi encontrado registro !<div></div>
                                </div>
                            </Center>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <br />
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                </>
            )}
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </>
    )
}

const Text = styled.p``

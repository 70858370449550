import React, { useRef, useEffect, useState, useContext } from 'react'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { DatePicker } from '../../../../core/components/DatePicker'
import { Input } from '../../../../core/components/Form/Input'
import { Header } from '../../../../core/components/Header/Header'
import { useHistory, useParams } from 'react-router'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { Center } from '../../../../core/components/Center'
import { Button } from '../../../../core/components/Button'
import { Space } from '../../../../core/components/Space'
import { Subtitle } from '../../../../core/components/Subtitle'
import { Empty } from '../../../../core/components/Empty'
import styled from 'styled-components'
import { Hospedagem as HospedagemProp } from '../../../../contexts/interfaces/hospedagem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, post, put, destroy } from '../../../../services/http'
import { ENDPOINTS } from '../../../../services/config-http'
import { Flex } from '../../../../core/components/Flex'
import { UserContext } from '../../../../contexts/UserContext'
import { Alert } from '../../../../core/components/Alert'
import {
    Quadro,
    TrMobile,
    getCampo,
    getNotFound
} from '../../../../core/components/Mobile'
import { Box } from '@material-ui/core'
import { Deletar } from '../../../../core/components/Deletar'
import { DisableForMobile, IsMobile } from '../stepMobile'

export const EditarHospedagemForm = () => {
    const history = useHistory()
    const { Id } = useParams() as any
    const formRef = useRef<FormHandles>(null)
    const [messageError, setMessageError] = useState<string>('')
    const [hospedagens, setHospedagens] = useState<Array<HospedagemProp>>([])
    const [hospedagensNovas, setHospedagensNovas] = useState<
        Array<HospedagemProp>
    >([])
    const [hospedagensAlteradas, setHospedagensAlteradas] = useState<
        Array<HospedagemProp>
    >([])
    const [hospedagensRemovidas, setHospedagensRemovidas] = useState<
        Array<HospedagemProp>
    >([])
    const [editing, setEditing] = useState(false)
    const [editingKey, setEditingKey] = useState(-1)

    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })

    const [showHospedagens, setShowHospedagens] = useState(false)
    const [solicitacao, setSolicitacao] = useState<any>()
    const dadosUsuario = useContext(UserContext)

    useEffect(() => {
        if (showHospedagens) {
            get(`${ENDPOINTS.hospedagem}/${Id}/solicitacao`)
                .then(({ data: { Model } }) => {
                    setHospedagens(Model)
                })
                .catch(err => {
                    setShowAlert({
                        status: true,
                        message: err.data,
                        type: 'danger'
                    })
                })
        }
    }, [Id, showHospedagens])

    useEffect(() => {
        carregarSolicitacaoViagemPorId()
    }, [])

    const carregarSolicitacaoViagemPorId = () => {
        get(`${ENDPOINTS.solicitacaoViagemPorId}/${Id}`).then(
            async ({ data: { Model } }) => {
                const solicitacao = Model
                setSolicitacao(solicitacao)
            }
        )
    }

    useEffect(() => {
        if (solicitacao?.TipoSolicitacao) {
            buscaRegra(
                solicitacao.TipoSolicitacao === 'Viagem Completa' ? 1 : 2
            )
        }
    }, [solicitacao])

    const buscaRegra = async (id: number) => {
        if (id !== 0) {
            await get(`${ENDPOINTS.regrasPoliticaViagem}/${id}`)
                .then(({ data: { Model } }) => {
                    const { UtilizarHospedagem } = Model
                    setShowHospedagens(UtilizarHospedagem)
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    function _PipeCustomDate(value: string) {
        const date = new Date(value)
        return <>{date.toLocaleDateString()}</>
    }

    function _UpdateFormRef(value: HospedagemProp) {
        formRef.current?.setData(value)
    }

    function ChecarSeExiste(
        listaHospedagem: HospedagemProp[],
        hospedagemParaChecar: HospedagemProp
    ) {
        let existe = -1
        listaHospedagem?.map((e: any, key: number) => {
            if (
                listaHospedagem[key].Cidade === hospedagemParaChecar.Cidade &&
                listaHospedagem[key].Referencia ===
                    hospedagemParaChecar.Referencia &&
                listaHospedagem[key].DataEntrada?.toLocaleString() ===
                    hospedagemParaChecar.DataEntrada?.toLocaleString() &&
                listaHospedagem[key].DataSaida?.toLocaleString() ===
                    hospedagemParaChecar.DataSaida?.toLocaleString() &&
                listaHospedagem[key].Observacao ===
                    hospedagemParaChecar.Observacao
            ) {
                existe = key
            }
        })
        return existe // retorna -1 caso não exista
    }

    function _adicionarHospedagem(hospedagemRecebida: HospedagemProp) {
        const existeRemovidas = ChecarSeExiste(
            hospedagensRemovidas,
            hospedagemRecebida
        )

        if (existeRemovidas === -1) {
            // caso nao seja uma hospedagem removida
            const existeAlteradas = ChecarSeExiste(
                hospedagensAlteradas,
                hospedagemRecebida
            )
            if (existeAlteradas === -1) {
                // caso nao seja uma hospedagem alterada
                hospedagensNovas.push(hospedagemRecebida)
                setHospedagensNovas(hospedagensNovas)
            } else {
                hospedagensAlteradas.splice(existeAlteradas, 1)
                setHospedagensAlteradas(hospedagensAlteradas)
            }
        } else {
            const existeAlteradas = ChecarSeExiste(
                hospedagensAlteradas,
                hospedagemRecebida
            )
            if (existeAlteradas === -1) {
                // caso nao seja uma hospedagem alterada
                hospedagensRemovidas.splice(existeAlteradas, 1)
                setHospedagensRemovidas(hospedagensRemovidas)
            } else {
                hospedagensRemovidas?.map((e: any, key: number) => {
                    if (
                        hospedagensRemovidas[key].Cidade ===
                            hospedagemRecebida.Cidade &&
                        hospedagensRemovidas[key].Referencia ===
                            hospedagemRecebida.Referencia &&
                        hospedagensRemovidas[
                            key
                        ].DataEntrada?.toLocaleString() ===
                            hospedagemRecebida.DataEntrada?.toLocaleString() &&
                        hospedagensRemovidas[
                            key
                        ].DataSaida?.toLocaleString() ===
                            hospedagemRecebida.DataSaida?.toLocaleString() &&
                        hospedagensRemovidas[key].Observacao ===
                            hospedagemRecebida.Observacao
                    ) {
                        hospedagensAlteradas.splice(existeRemovidas, 1)
                        setHospedagensAlteradas(hospedagensAlteradas)
                        hospedagensRemovidas.splice(existeRemovidas, 1)
                        setHospedagensRemovidas(hospedagensRemovidas)
                    }
                })
            }
        }
        setHospedagens(hospedagens)
    }

    function _alterarHospedagem(hospedagemRecebida: HospedagemProp) {
        const existeNovas = ChecarSeExiste(hospedagensNovas, hospedagemRecebida)

        if (existeNovas === -1) {
            // se não for uma hospedagem nova
            const existeAlteradas = ChecarSeExiste(
                hospedagensAlteradas,
                hospedagemRecebida
            )
            if (existeAlteradas === -1) {
                // se nao for uma hospedagem alterada
                hospedagens[editingKey].Cidade = hospedagemRecebida.Cidade
                hospedagens[editingKey].Referencia =
                    hospedagemRecebida.Referencia
                hospedagens[editingKey].DataEntrada =
                    hospedagemRecebida.DataEntrada
                hospedagens[editingKey].DataSaida = hospedagemRecebida.DataSaida
                hospedagens[editingKey].Observacao =
                    hospedagemRecebida.Observacao
                hospedagensAlteradas.push(hospedagemRecebida)
                setHospedagensAlteradas(hospedagensAlteradas)
            } else {
                // caso ja exista, só atualiza os dados na tabela e nos alterados
                hospedagens[editingKey].Cidade = hospedagemRecebida.Cidade
                hospedagens[editingKey].Referencia =
                    hospedagemRecebida.Referencia
                hospedagens[editingKey].DataEntrada =
                    hospedagemRecebida.DataEntrada
                hospedagens[editingKey].DataSaida = hospedagemRecebida.DataSaida
                hospedagens[editingKey].Observacao =
                    hospedagemRecebida.Observacao
                hospedagensAlteradas[existeAlteradas].Cidade =
                    hospedagemRecebida.Cidade
                hospedagensAlteradas[existeAlteradas].Referencia =
                    hospedagemRecebida.Referencia
                hospedagensAlteradas[existeAlteradas].DataEntrada =
                    hospedagemRecebida.DataEntrada
                hospedagensAlteradas[existeAlteradas].DataSaida =
                    hospedagemRecebida.DataSaida
                hospedagensAlteradas[existeAlteradas].Observacao =
                    hospedagemRecebida.Observacao
            }
        }
    }

    function _removerHospedagem(hospedagemRecebida: HospedagemProp) {
        const existeNovas = hospedagensNovas.indexOf(hospedagemRecebida)

        setEditing(false)
        setEditingKey(-1)
        formRef.current?.reset()

        if (existeNovas === -1) {
            // se nao for uma hospedagem nova
            const hasHospedagem = hospedagensRemovidas.some(
                i => i.Id === hospedagemRecebida.Id
            )
            if (hasHospedagem === false) {
                hospedagensRemovidas.push(hospedagemRecebida)
                setHospedagensRemovidas(hospedagensRemovidas)
            }
        } else {
            hospedagensNovas.splice(existeNovas, 1)
            setHospedagensNovas(hospedagensNovas)
        }

        const existeAlteradas = hospedagensAlteradas.indexOf(hospedagemRecebida)
        if (existeAlteradas !== -1) {
            // caso seja uma hospedagem alterada
            hospedagensAlteradas.splice(existeAlteradas, 1)
            setHospedagensAlteradas(hospedagensAlteradas)
        }
    }

    const handleSubmit: SubmitHandler = async (data, { reset }) => {
        try {
            const schema = Yup.object().shape({
                Cidade: Yup.string().required('O Campo Cidade é obrigatório!'),
                Referencia: Yup.string().required(
                    'O Campo Ponto de Referencia é obrigatório!'
                ),
                DataEntrada: Yup.date()
                    .nullable()
                    .required('O Campo Check-in é obrigatório!'),
                DataSaida: Yup.date()
                    .nullable()
                    .required('O Campo Check-out é obrigatório!')
            })
            await schema.validate(data, { abortEarly: false })

            const newHospedagens = [...hospedagens]
            const hospedagemData: any = {
                Cidade: formRef.current?.getFieldValue('Cidade'),
                Referencia: formRef.current?.getFieldValue('Referencia'),
                DataEntrada: formRef.current?.getFieldValue('DataEntrada'),
                DataSaida: formRef.current?.getFieldValue('DataSaida'),
                Observacao: formRef.current?.getFieldValue('Observacao'),
                IdSolicitacao: Id
            }
            if (editing && editingKey !== -1) {
                hospedagens[editingKey].DataEntrada = hospedagemData.DataEntrada
                hospedagens[editingKey].DataSaida = hospedagemData.DataSaida
                hospedagens[editingKey].Observacao = hospedagemData.Observacao
                hospedagemData.Id = hospedagens[editingKey].Id
                _alterarHospedagem(hospedagemData)
            } else {
                newHospedagens.push(hospedagemData)
                _adicionarHospedagem(hospedagemData)
            }
            setHospedagens([...newHospedagens])

            formRef.current?.setErrors({})
            reset()
            setEditing(false)
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                formRef.current?.setErrors(erros)
            }
        }
    }

    const SubmitInformation = async () => {
        try {
            hospedagensNovas.forEach(element => {
                post(ENDPOINTS.hospedagem, {
                    cidade: element.Cidade,
                    referencia: element.Referencia,
                    dataEntrada: element.DataEntrada,
                    dataSaida: element.DataSaida,
                    observacao: element.Observacao,
                    idSolicitacaoViagem: element.IdSolicitacao
                })
                    .then(res => {
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
            })
            hospedagensAlteradas.forEach(element => {
                put(ENDPOINTS.hospedagem, {
                    id: element.Id,
                    cidade: element.Cidade,
                    referencia: element.Referencia,
                    dataEntrada: element.DataEntrada,
                    dataSaida: element.DataSaida,
                    observacao: element.Observacao,
                    idSolicitacaoViagem: element.IdSolicitacao
                })
                    .then(res => {
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
            })
            hospedagensRemovidas.forEach(element => {
                destroy(`${ENDPOINTS.hospedagem}/${element.Id}`)
                    .then(res => {
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
            })
            history.push(`/solicitacoes/editar/${Id}`)
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                formRef.current?.setErrors(erros)
            }
        }
    }

    function getTabelaMobile() {
        return (
            <Center>
                <Box>
                    {hospedagens.length
                        ? hospedagens.map((tableItem, index) => {
                              if (tableItem) {
                                  return (
                                      <Quadro>
                                          <table style={{ width: '100%' }}>
                                              <TrMobile>
                                                  {getCampo(
                                                      index,
                                                      '#',
                                                      index + 1
                                                  )}
                                                  {getCampo(
                                                      index,
                                                      'Cidade',
                                                      tableItem.Cidade
                                                  )}
                                                  {getCampo(
                                                      index,
                                                      'Check-in',
                                                      tableItem.DataEntrada !==
                                                          undefined
                                                          ? _PipeCustomDate(
                                                                tableItem.DataEntrada.toString()
                                                            )
                                                          : 'Não possui data de entrada'
                                                  )}
                                                  {getCampo(
                                                      index,
                                                      'Check-out',
                                                      tableItem.DataSaida !==
                                                          undefined
                                                          ? _PipeCustomDate(
                                                                tableItem.DataSaida.toString()
                                                            )
                                                          : 'Não possui data de saída'
                                                  )}
                                                  {getCampo(
                                                      index,
                                                      'Ponto de Referência',
                                                      tableItem.Referencia
                                                  )}
                                                  {getCampo(
                                                      index,
                                                      'Observação',
                                                      tableItem.Observacao
                                                  )}
                                                  {getCampo(
                                                      index,
                                                      'Remover',
                                                      <Deletar
                                                          remover={() => {
                                                              const newHospedagens =
                                                                  hospedagens
                                                              newHospedagens.splice(
                                                                  index,
                                                                  1
                                                              )
                                                              setHospedagens([
                                                                  ...newHospedagens
                                                              ])
                                                          }}
                                                      />
                                                  )}
                                              </TrMobile>
                                          </table>
                                      </Quadro>
                                  )
                              }
                              return null
                          })
                        : getNotFound('Nenhuma hospedagem adicionada')}
                </Box>
            </Center>
        )
    }

    return (
        <BlankBoard>
            <Header
                title={
                    hospedagens?.length !== 0
                        ? 'Alteração de Hospedagem'
                        : 'Criação de Hospedagem'
                }
                actionArrowClick={() =>
                    history.push(`/solicitacoes/editar/${Id}`)
                }
            ></Header>
            <Space height="2rem"></Space>
            <Subtitle
                style={{
                    textAlign: 'center',
                    margin: '1rem 0'
                }}
            >
                Dados da Hospedagem
            </Subtitle>

            {showHospedagens ? (
                <>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Space height="1.3em"></Space>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <Input
                                    name="Cidade"
                                    placeholder="Em que cidade?"
                                    required={true}
                                    label="Cidade"
                                ></Input>
                            </div>
                            <div className="col-12 col-sm-6">
                                <Input
                                    name="Referencia"
                                    label="Ponto de referencia"
                                    required={true}
                                    placeholder="Ex. Próximo a havan vila velha"
                                ></Input>
                            </div>
                            <div className="col-12 col-sm-6">
                                <DatePicker
                                    name="DataEntrada"
                                    label="Check-in"
                                    minDate={new Date()}
                                    placeholderText="Data da entrada"
                                    required={true}
                                ></DatePicker>
                            </div>
                            <div className="col-12 col-sm-6">
                                <DatePicker
                                    name="DataSaida"
                                    label="Check-out"
                                    minDate={new Date()}
                                    placeholderText="Data da saída"
                                    required={true}
                                ></DatePicker>
                            </div>
                            <div className="col-12">
                                <Input
                                    style={{ height: '7.5rem' }}
                                    name="Observacao"
                                    label="Observação"
                                    type="textarea"
                                    placeholder="Digite a observação..."
                                    required={false}
                                ></Input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="d-flex justify-content-end align-items-center">
                                {editing ? (
                                    <div className="col-12 col-sm-3">
                                        <Flex justifyContent="flex-end">
                                            <Button
                                                style={{
                                                    width: '80%',
                                                    minWidth: 'auto'
                                                }}
                                                colorType="secondary"
                                                icon="ban"
                                                onClick={() => {
                                                    setEditing(false)
                                                    setEditingKey(-1)
                                                    formRef.current?.reset()
                                                }}
                                            >
                                                <b>Cancelar</b>
                                            </Button>
                                        </Flex>
                                    </div>
                                ) : null}

                                <div className="col-12 col-sm-3">
                                    <Flex justifyContent="flex-end">
                                        <DisableForMobile>
                                            <Button
                                                style={{
                                                    width: '80%',
                                                    minWidth: 'auto'
                                                }}
                                                colorType="quintiary"
                                                icon={editing ? 'save' : 'plus'}
                                            >
                                                {editing ? (
                                                    <b>Salvar</b>
                                                ) : (
                                                    <b>Adicionar</b>
                                                )}
                                            </Button>
                                        </DisableForMobile>
                                        <Center>
                                            <IsMobile>
                                                <Button
                                                    colorType="btnMobile"
                                                    icon={
                                                        editing
                                                            ? 'save'
                                                            : 'plus'
                                                    }
                                                >
                                                    {editing ? (
                                                        <b>Salvar</b>
                                                    ) : (
                                                        <b>Adicionar</b>
                                                    )}
                                                </Button>
                                            </IsMobile>
                                        </Center>
                                    </Flex>
                                </div>
                            </div>
                            <div className="col-6">
                                {messageError ? (
                                    <p
                                        className="danger"
                                        style={{ marginTop: '15px' }}
                                    >
                                        {messageError}
                                    </p>
                                ) : null}
                            </div>
                        </div>

                        <DisableForMobile>
                            {hospedagens?.length ? (
                                <div
                                    className="row"
                                    style={{ marginTop: '3rem' }}
                                >
                                    <div className="col-12">
                                        <label htmlFor=""></label>
                                        <div
                                            className="table-responsive"
                                            style={{
                                                border: 'solid 1px lightgray',
                                                borderRadius: '15px'
                                            }}
                                        >
                                            <table className="table">
                                                <thead
                                                    style={{
                                                        borderBottom:
                                                            'solid 1px lightgray'
                                                    }}
                                                >
                                                    <tr
                                                        style={{
                                                            border: '0 !important'
                                                        }}
                                                    >
                                                        <Th
                                                            className="col-1"
                                                            scope="col-1"
                                                        >
                                                            <Text>#</Text>
                                                        </Th>
                                                        <Th
                                                            scope="col"
                                                            className="col-2"
                                                        >
                                                            <Text>Cidade</Text>
                                                        </Th>
                                                        <Th
                                                            scope="col"
                                                            className="col-2"
                                                        >
                                                            <Text>
                                                                Ponto de
                                                                Referência
                                                            </Text>
                                                        </Th>
                                                        <Th
                                                            scope="col"
                                                            className="col-2"
                                                        >
                                                            <Text>
                                                                Check-in
                                                            </Text>
                                                        </Th>
                                                        <Th
                                                            scope="col"
                                                            className="col-2"
                                                        >
                                                            <Text>
                                                                Check-out
                                                            </Text>
                                                        </Th>

                                                        <Th
                                                            scope="col"
                                                            className="col-1"
                                                            style={{
                                                                textAlign:
                                                                    'center'
                                                            }}
                                                        >
                                                            <Text>Editar</Text>
                                                        </Th>
                                                        <Th
                                                            scope="col"
                                                            className="col-1"
                                                        >
                                                            <Text>Deletar</Text>
                                                        </Th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {hospedagens.map(
                                                        (item, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td className="col-1">
                                                                        <Text>
                                                                            {key +
                                                                                1}
                                                                        </Text>
                                                                    </td>
                                                                    <td className="col-2">
                                                                        <Text>
                                                                            {
                                                                                item.Cidade
                                                                            }
                                                                        </Text>
                                                                    </td>
                                                                    <td className="col-2">
                                                                        <Text>
                                                                            {
                                                                                item.Referencia
                                                                            }
                                                                        </Text>
                                                                    </td>
                                                                    <td className="col-2">
                                                                        <Text>
                                                                            {item.DataEntrada !==
                                                                            undefined
                                                                                ? _PipeCustomDate(
                                                                                      item.DataEntrada.toString()
                                                                                  )
                                                                                : 'Não possui data de entrada'}
                                                                        </Text>
                                                                    </td>
                                                                    <td className="col-2">
                                                                        <Text>
                                                                            {item.DataSaida !==
                                                                            undefined
                                                                                ? _PipeCustomDate(
                                                                                      item.DataSaida.toString()
                                                                                  )
                                                                                : 'Não possui data de saída'}
                                                                        </Text>
                                                                    </td>

                                                                    <td
                                                                        className="col-1 cursor-pointer"
                                                                        style={{
                                                                            textAlign:
                                                                                'center'
                                                                        }}
                                                                    >
                                                                        <span
                                                                            onClick={() => {
                                                                                setEditing(
                                                                                    true
                                                                                )
                                                                                setEditingKey(
                                                                                    key
                                                                                )

                                                                                _UpdateFormRef(
                                                                                    hospedagens[
                                                                                        key
                                                                                    ]
                                                                                )

                                                                                formRef.current?.setErrors(
                                                                                    {}
                                                                                )
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon icon="edit"></FontAwesomeIcon>
                                                                        </span>
                                                                    </td>
                                                                    <td className="col-1 danger cursor-pointer">
                                                                        <span
                                                                            onClick={() => {
                                                                                setEditingKey(
                                                                                    key
                                                                                )
                                                                                const newHospedagens =
                                                                                    [
                                                                                        ...hospedagens
                                                                                    ]

                                                                                const hospedagemRemovida =
                                                                                    newHospedagens.splice(
                                                                                        key,
                                                                                        1
                                                                                    )

                                                                                _removerHospedagem(
                                                                                    hospedagemRemovida[0]
                                                                                )

                                                                                setHospedagens(
                                                                                    newHospedagens
                                                                                )
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Empty text="Nenhuma hospedagem adicionada" />
                            )}
                        </DisableForMobile>
                        <IsMobile>{getTabelaMobile()}</IsMobile>
                    </Form>
                    <Space height="2rem"></Space>
                    <Center>
                        <DisableForMobile>
                            {hospedagens?.length > 0 ? (
                                <Button
                                    colorType="quaternary"
                                    icon="check"
                                    onClick={() => {
                                        SubmitInformation()
                                        history.push(
                                            `/solicitacoes/editar/${Id}`
                                        )
                                    }}
                                >
                                    Salvar
                                </Button>
                            ) : null}
                        </DisableForMobile>
                        <IsMobile>
                            {hospedagens?.length > 0 ? (
                                <Button
                                    colorType="btnMobile"
                                    icon="check"
                                    onClick={() => {
                                        SubmitInformation()
                                        history.push(
                                            `/solicitacoes/editar/${Id}`
                                        )
                                    }}
                                >
                                    Salvar
                                </Button>
                            ) : null}
                        </IsMobile>
                    </Center>
                </>
            ) : (
                <Alert
                    fixed={true}
                    icon="exclamation-circle"
                    type="primary"
                    message="Consulte as politicas de viagens"
                ></Alert>
            )}
        </BlankBoard>
    )
}

const Th = styled.th`
    vertical-align: middle;
    color: ${props => props.theme.colors.text};
    border-bottom-width: 0 !important;
    text-align: 'center';
`
const Text = styled.p``

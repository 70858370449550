import styled from 'styled-components'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

interface HeaderProps {
    title?: string
    actionArrowClick?: unknown
}

export const Header = ({ title, actionArrowClick }: HeaderProps) => {
    const history = useHistory()
    return (
        <Flex>
            {(() => {
                if (typeof actionArrowClick === 'string') {
                    return (
                        <Arrow onClick={() => history.push(actionArrowClick)}>
                            <FontAwesomeIcon icon="angle-left" />
                        </Arrow>
                    )
                } else if (typeof actionArrowClick === 'function') {
                    return (
                        <Arrow onClick={() => actionArrowClick()}>
                            <FontAwesomeIcon icon="angle-left" />
                        </Arrow>
                    )
                }
            })()}
            <Text>{title}</Text>
        </Flex>
    )
}
const Flex = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
`

const Arrow = styled.a`
    padding-bottom: 0.5rem;
    left: 2rem;
    position: absolute;
    font-size: 2.8rem;
    cursor: pointer;
    color: ${props => props.theme.colors.primary};

    @media (max-width: 720px) {
        font-size: 2.2rem;
        left: 0.5rem;
    }
    @media (max-width: 540px) {
        font-size: 1.8rem;
    }
    :hover {
        color: #002a5d;
    }
`

const Text = styled.h1`
    @media (max-width: 720px) {
        font-size: 1.5rem;
    }
    @media (max-width: 540px) {
        font-size: 1.1rem;
    }
`

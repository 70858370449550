import React, { useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Center } from '../../../core/components/Center'
import { Header } from '../../../core/components/Header/Header'
import { Input } from '../../../core/components/Form/Input'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { Button } from '../../../core/components/Button'
import { ENDPOINTS } from '../../../services/config-http'
import { put, get } from '../../../services/http'
import { Alert } from '../../../core/components/Alert'
import { Subtitle } from '../../../core/components/Subtitle'
import { Switch } from '../../../core/components/Switch'
import { UserContext } from '../../../contexts/UserContext'
import { Servico } from '../../../contexts/interfaces/servico'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { Toast } from '../../../core/components/Toast'

export const ServicoForm = () => {
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })
    const { Id } = useParams() as any
    const history = useHistory()
    const FormRef = useRef<FormHandles>(null)
    const [administrador, setAdministrador] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const [status, setStatus] = useState(false)
    const [exclusivoViagemGrupo, setExclusivoViagemGrupo] = useState(false)
    const [cotavel, setCotavel] = useState(false)

    const [id, setId] = useState(0)
    const [desc, setDesc] = useState('')

    const dadosUsuario = useContext(UserContext)

    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        getServico(Id)
    }, [])

    const getServico = async (Id: number) => {
        await get(`${ENDPOINTS.servico}/Id?Id=${Id}`)
            .then(({ data: { Model } }) => {
                setDesc(Model.Descricao)
                setId(Model?.Id)
                setStatus(true)
                setCotavel(Model.Cotavel === 1)
                setExclusivoViagemGrupo(Model.ExclusivoViagemGrupo === 1)
            })
            .catch(err => {
                console.log(err)
            })
    }
    const handleEdit: SubmitHandler = async (servico: Servico) => {
        try {
            const schema = Yup.object().shape({
                DescricaoServico: Yup.string().required(
                    'O Campo descricaoServico é obrigatório'
                )
            })

            await schema.validate(servico, { abortEarly: false })

            put(ENDPOINTS.servico, {
                Id: id,
                Descricao: servico.DescricaoServico,
                Cotavel: cotavel ? 1 : 0,
                ExclusivoViagemGrupo: exclusivoViagemGrupo ? 1 : 0,
                status: status ? 1 : 0
            })
                .then(() => {
                    history.push(`/cadastros/servico`)
                })
                .catch(({ data }) => {
                    setShowToast(true)
                    setToast({
                        message: 'Erro ao tentar alterar despesa',
                        type: 'error'
                    })
                })
        } catch (err) {
            console.log(err)
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                FormRef.current?.setErrors(erros)
            }
        }
    }

    return (
        <div>
            <BlankBoard>
                <Header
                    title="Editar serviço"
                    actionArrowClick="/cadastros/servico"
                ></Header>
                <Center>
                    <Subtitle>Dados do serviço</Subtitle>
                </Center>
                {administrador ? (
                    <>
                        <Form ref={FormRef} onSubmit={handleEdit}>
                            <div className="row">
                                <div className="col12 col-sm-4">
                                    <Input
                                        name="DescricaoServico"
                                        label="Descricao"
                                        required={true}
                                        value={desc}
                                        onChange={e => setDesc(e.target.value)}
                                        placeholder="Digite a descricao"
                                    ></Input>
                                </div>
                                <div className="col12">
                                    <Switch
                                        checked={status}
                                        onChange={e => {
                                            setStatus(!status)
                                        }}
                                        name="Status"
                                        label="Status"
                                    ></Switch>
                                </div>
                                <div className="col12">
                                    <Switch
                                        checked={cotavel}
                                        onChange={e => {
                                            setCotavel(!cotavel)
                                        }}
                                        name="Cotavel"
                                        label="Cotavel"
                                    ></Switch>
                                </div>
                                <div className="col12">
                                    <Switch
                                        checked={exclusivoViagemGrupo}
                                        onChange={e => {
                                            setExclusivoViagemGrupo(
                                                !exclusivoViagemGrupo
                                            )
                                        }}
                                        name="ExclusivoViagemGrupo"
                                        label="ExclusivoViagemGrupo"
                                    ></Switch>
                                </div>
                            </div>
                            <Center>
                                <Button
                                    icon="edit"
                                    colorType="quaternary"
                                    style={{ marginTop: '100px' }}
                                >
                                    Salvar
                                </Button>
                            </Center>
                        </Form>
                    </>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                )}
            </BlankBoard>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </div>
    )
}

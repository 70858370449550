import { ValidationError } from 'yup'

export function MapFormErrors(errors: ValidationError[]) {
    const errorMessages: any = {}

    errors.forEach((error: ValidationError) => {
        errorMessages[error.path as string] = error.message
    })

    return errorMessages
}

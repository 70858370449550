import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import React, { useEffect, useRef, useState } from 'react'
import { Input } from '../../../core/components/Form/Input'
import { LineSeparator } from '../../../core/components/LineSeparator'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { get, post, put } from '../../../services/http'
import { ENDPOINTS } from '../../../services/config-http'
import { PoliticaViagemProp } from '../../../contexts/interfaces/politicaviagem'
import { Toast } from '../../../core/components/Toast'
import { Modal } from '../../../core/components/Modal/Modal'
import { Center } from '../../../core/components/Center'
import { Button } from '../../../core/components/Button'

interface PoliticaProp {
    id: number
    show?: boolean
    setShow: any
}

export const ModalPoliticas = ({ show, setShow, id }: PoliticaProp) => {
    const formRef = useRef<FormHandles>(null)
    const [showToast, setShowToast] = useState(false)
    const [toast, setToast] = useState({
        message: '',
        type: ''
    })

    useEffect(() => {
        if (show) {
            document
                .getElementsByClassName('modal-aereo')[0]
                ?.classList.remove('hidden')

            formRef.current?.clearField('nome')
            formRef.current?.clearField('descricao')
            if (id !== 0) {
                const buscarPolitica = async () => {
                    await get(`${ENDPOINTS.politicaViagem}/${id}`)
                        .then(({ data: { Model } }) => {
                            const { Nome, Descricao } = Model
                            formRef.current?.setFieldValue('nome', Nome)
                            formRef.current?.setFieldValue(
                                'descricao',
                                Descricao
                            )
                        })
                        .catch(err => {
                            setShowToast(true)
                            setToast({
                                message: err.statusText,
                                type: 'error'
                            })
                            setTimeout(() => window.location.reload(), 3000)
                        })
                }
                buscarPolitica()
            }
        } else
            document
                .getElementsByClassName('modal-aereo')[0]
                ?.classList.add('hidden')
    }, [show])

    const handleSubmit: SubmitHandler = async (data: any) => {
        try {
            const schema = Yup.object().shape({
                nome: Yup.string().required('O Campo Nome é obrigatório'),
                descricao: Yup.string().required(
                    'O Campo Descricao é obrigatório'
                )
            })

            // Verificação - Caso exista algum erro, o fluxo cai no catch que fará a amostragem dos erros na tela
            await schema.validate(data, { abortEarly: false })

            if (id === 0) {
                await post(ENDPOINTS.politicaViagem, {
                    Nome: data.nome,
                    Descricao: data.descricao
                })
                    .then(() => {
                        setShow(false)
                        setShowToast(true)
                        setToast({
                            message: 'Politica cadastrada com sucesso',
                            type: 'success'
                        })
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000)
                    })
                    .catch(err => {
                        setShowToast(true)
                        setToast({
                            message: err.statusText,
                            type: 'error'
                        })
                        setTimeout(() => window.location.reload(), 2000)
                    })
            } else {
                await put(ENDPOINTS.politicaViagem, {
                    Id: id,
                    Nome: data.nome,
                    Descricao: data.descricao
                })
                    .then(() => {
                        setShow(false)
                        setShowToast(true)
                        setToast({
                            message: 'Politica alterada com sucesso',
                            type: 'success'
                        })
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000)
                    })
                    .catch(err => {
                        setShowToast(true)
                        setToast({
                            message: err.statusText,
                            type: 'error'
                        })
                        setTimeout(() => window.location.reload(), 2000)
                    })
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                formRef.current?.setErrors(erros)
            }
        }
    }

    return (
        <>
            <Modal
                title={
                    id === 0
                        ? 'Nova política de viagem'
                        : 'Alterar política de viagem'
                }
                setShow={setShow}
                height={'16rem'}
            >
                <Form
                    onSubmit={handleSubmit}
                    ref={formRef}
                    style={{ padding: 0 }}
                >
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <Input
                                name="nome"
                                label="Nome"
                                placeholder="Nome da política..."
                                required={true}
                            ></Input>
                        </div>
                        <div className="col-12 col-md-8">
                            <Input
                                name="descricao"
                                label="Descrição"
                                placeholder="Descrição da política...."
                                required={true}
                            ></Input>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '2rem'
                        }}
                    >
                        <Button
                            icon={id === 0 ? 'plus' : 'save'}
                            colorType="quaternary"
                            type="submit"
                        >
                            {id === 0 ? 'Cadastrar' : 'Salvar'}
                        </Button>
                    </div>
                </Form>
            </Modal>
            {showToast ? (
                <Toast
                    setShow={setShowToast}
                    message={toast.message}
                    type={toast.type}
                />
            ) : null}
        </>
    )
}

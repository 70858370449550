import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button'
import { ENDPOINTS } from '../../../services/config-http'
import { get, patch } from '../../../services/http'
import { DatePicker } from '../../../core/components/DatePicker'
import { useAuth } from '../../../contexts/Auth'
import CleanIcon from '../../../core/assets/filter_white.svg'
import { SelectRequester } from '../../../core/components/Form/SelectWithRequest'
import { Input } from '../../../core/components/Form/Input'
import {
    ConfigTable,
    TableRequester,
    Table,
    MapperItem
} from '../../../core/components/Table/v2/Table'
import { STATUS_SOLICITACAO } from '../../../contexts/enums/StatusSolicitacao'
import { Form } from '@unform/web'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Header } from '../../../core/components/Header/Header'
import { Flex } from '../../../core/components/Flex'
import { Modal } from '../../../core/components/Modal'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { Select } from '../../../core/components/Form/Select'
import { SelectMapper } from '../../../core/utils/SelectMapper'
import { Center } from '../../../core/components/Center'
import { Legenda } from '../../../util/legenda'
import styled from 'styled-components'

export const MinhasViagens = () => {
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const [idSolicitacao, setIdSolicitacao] = useState(0)
    const [buttonRef, setButtonOpen] = useState<HTMLButtonElement>()
    const { user } = useAuth()
    const [tiposSolicitacao, setTiposSolicitacao] = useState([])
    const [qtdViagens, setQtdViagens] = useState({
        count: 0,
        description: ''
    })

    useEffect(() => {
        get(`$${ENDPOINTS.usuarioByCodigo.replace(':Codigo', user.codigo)}`)
            .then(({ data }) => {
                if (data.Count === 0) {
                    setQtdViagens({
                        count: 0,
                        description: 'Nenhuma Viagem'
                    })
                } else if (data.Count === 1) {
                    setQtdViagens({
                        count: data.Count,
                        description: 'Viagem'
                    })
                } else if (data.Count > 1) {
                    setQtdViagens({
                        count: data.Count,
                        description: 'Viagens'
                    })
                }
            })
            .catch(err => {
                console.error(err)
            })

        const pesquisaTipoSolicitacao = async () => {
            await get(
                `${ENDPOINTS.usuarioByCodigo.replace(':Codigo', user.codigo)}`
            ).then(async ({ data: { Model } }) => {
                console.log(Model)
                const { IdPoliticaViagem } = Model
                await get(
                    `${ENDPOINTS.regrasPoliticaViagem}/BuscarPorIdPolitica/${IdPoliticaViagem}`
                )
                    .then(({ data: { Model } }) => {
                        setTiposSolicitacao(
                            SelectMapper(
                                Model,
                                'TipoSolicitacao',
                                'IdTipoSolicitacao'
                            )
                        )
                    })
                    .catch(err => {
                        console.error(err)
                    })
            })
        }
        pesquisaTipoSolicitacao()
    }, [])

    const [payloadSearch, setPayloadSearch] = useState<TableRequester>({
        endpoint: ENDPOINTS.pesquisaSolicitacaoViagem,
        method: 'GET',
        payload: {
            CodigoUsuario: user.codigo
        }
    })

    const mapper: MapperItem[] = [
        {
            field: 'IdSolicitacao',
            replaceTo: 'ID',
            position: 1,
            align: 'start'
        },

        {
            field: 'Viajantes',
            visualCustom: _PipelistPeople,
            position: 2,
            align: 'start'
        },
        { field: 'Tipo', position: 3, align: 'start' },
        {
            field: 'DataCadastro',
            replaceTo: 'Data do Cadastro',
            visualCustom: _PipeCustomDate,
            position: 4,
            align: 'start'
        },
        {
            field: 'ValorViagem',
            replaceTo: 'Valor Viagem',
            visualCustom: _PipeCustomCurrency,
            position: 5,
            align: 'start'
        },
        {
            field: 'Situacao',
            replaceTo: 'Situação',
            visualCustom: _PipeCustomStatus,
            position: 6,
            align: 'start'
        },
        { field: 'Etapas', delete: true },
        { field: 'IdSolicitacaoViagem', delete: true },
        { field: 'Viajante', delete: true },
        { field: 'ViajantePrincipal', delete: true },
        { field: 'FilialViajantePrincipal', delete: true },
        { field: 'CotacaoSolicitacaoViagem', delete: true }
    ]

    function _PipeCustomDate(value: string) {
        const date = new Date(value)
        return <>{date.toLocaleDateString()}</>
    }

    function _PipeCustomCurrency(value: number) {
        return (
            <>
                {'R$ ' +
                    value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
            </>
        )
    }

    const tableConfig: ConfigTable = {
        edit: false,
        delete: false,
        moreOptions: [
            {
                label: 'Continuar',
                icon: 'forward',
                conditional: (option: string, item: any) => {
                    return item.Situacao === 'Rascunho'
                },
                action: (item: any) => {
                    history.push('/solicitar-viagem/incluir-viajantes')
                }
            },
            {
                label: 'Editar',
                icon: 'pencil-alt',
                conditional: (option: string, item: any) => {
                    return (
                        option === 'Editar' &&
                        item.Situacao === 'Autorização pendente'
                    )
                },
                action: (item: any) => {
                    history.push(`/solicitacoes/editar/${item.IdSolicitacao}`)
                }
            },
            {
                label: 'Prestar Contas',
                icon: 'receipt',
                conditional: (option: string, item: any) => {
                    return (
                        option === 'Prestar Contas' &&
                        (item.Situacao === 'Aguardando prestação de contas' ||
                            item.Situacao === 'Reprovado: prestação de contas')
                    )
                },
                action: (item: any) => {
                    history.push(`/prestar-contas/${item.IdSolicitacao}/0`)
                }
            },
            {
                label: 'Nova Etapa',
                icon: 'plus',
                conditional: (option: string, item: any) => {
                    return (
                        option === 'Nova Etapa' &&
                        (item.Situacao === 'Em Cotação' ||
                            item.Situacao === 'Aguardando conferência' ||
                            item.Situacao === 'Aguardando prestação de contas')
                    )
                },
                action: (item: any) => {
                    history.push(`/nova-etapa/${item.IdSolicitacao}`)
                }
            },
            {
                label: 'Histórico',
                icon: 'history',
                action: (item: any) => {
                    history.push(`/historico/${item.IdSolicitacao}`)
                }
            },
            {
                label: 'Imprimir',
                icon: 'print',
                action: (item: any) => {
                    history.push(`/impressao/${item.IdSolicitacao}`)
                }
            },
            {
                label: 'Detalhes',
                icon: 'info',
                color: '#84b5ff',
                action: (item: any) => {
                    history.push(`/solicitacoes/detalhes/${item.IdSolicitacao}`)
                }
            },
            {
                label: 'Deletar',
                icon: 'trash-alt',
                color: '#ff8d86',
                conditional: (option: string, item: any) => {
                    return (
                        option === 'Deletar' &&
                        (item.Situacao === 'Autorização pendente' ||
                            item.Situacao === 'Cancelada' ||
                            item.Situacao === 'Finalizada')
                    )
                },
                action: async (item: any) => {
                    buttonRef?.click()
                    await setIdSolicitacao(item.IdSolicitacao)
                    deleteSolicitacao(item.IdSolicitacao)
                }
            }
        ]
        // editAction: item => {
        //     history.push(`/solicitacoes/editar/${item.IdSolicitacao}`)
        // }
    }

    const handleSubmit: SubmitHandler<FormData> = (data: any) => {
        Object.keys(data).forEach(
            k => !data[k] && data[k] !== undefined && delete data[k] // eliminando valores não preenchidos para envio da query de busca
        )
        data.page = 0
        data.lastCount = 0
        data.totalRegisterPage = 10
        data.CodigoUsuario = user.codigo
        const newPayloadSearch = { ...payloadSearch, payload: data }
        setPayloadSearch(newPayloadSearch)
    }
    function _PipelistPeople(
        value: Array<any> | string,
        index: number
    ): JSX.Element {
        if (typeof value == 'object' && value.length) {
            return (
                <>
                    <div className="btn-group">
                        <button
                            type="button"
                            className="form-select removed-focus"
                            style={{
                                fontSize: '0.77777rem',
                                border: 'none',
                                paddingLeft: '0',
                                justifyContent: 'flex-start'
                            }}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <div style={{ textAlign: 'left' }}>
                                {value[0].Nome}
                            </div>
                        </button>
                        <ul
                            className="dropdown-menu"
                            style={{ fontSize: '0.7777rem' }}
                        >
                            {value.map((i: any, index) => (
                                <li key={index}>
                                    <a
                                        className="dropdown-item"
                                        href="/"
                                        style={{
                                            pointerEvents: 'none',
                                            cursor: 'default'
                                        }}
                                    >
                                        {i.Nome}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )
        } else {
            return <>{value}</>
        }
    }

    function _PipeCustomStatus(value: string, index: number): JSX.Element {
        switch (value) {
            case STATUS_SOLICITACAO.FINALIZADA:
                return <>{Legenda(4, value)}</>
            case STATUS_SOLICITACAO.AUTORIZACAO_PENDENTE:
            case STATUS_SOLICITACAO.AGUARDANDO_CONFERENCIA:
            case STATUS_SOLICITACAO.AGUARDANDO_FINALIZAR_NA_TESOURARIA:
            case STATUS_SOLICITACAO.EM_COTACAO:
            case STATUS_SOLICITACAO.AGUARDANDO_PRESTACAO_DE_CONTAS:
                return (
                    <>
                        {value === 'Aguardando prestação de contas'
                            ? Legenda(2, 'Aguardando prest. contas')
                            : value === 'Aguardando finalização na tesouraria'
                            ? Legenda(2, 'Finalização tesouraria')
                            : Legenda(2, value)}
                    </>
                )
            case STATUS_SOLICITACAO.REPROVADO:
            case STATUS_SOLICITACAO.REPROVADO_PRESTACAO_DE_CONTAS:
                return (
                    <>
                        {value === 'Reprovado: prestação de contas'
                            ? Legenda(3, 'Reprovado: prest. contas')
                            : Legenda(3, value)}
                    </>
                )
            case STATUS_SOLICITACAO.CANCELADO:
                return <>{Legenda(5, value)}</>
            default:
                return (
                    <>
                        {value === 'Rascunho' ? (
                            Legenda(1, value)
                        ) : (
                            <i
                                className="text-danger"
                                style={{ margin: 'auto' }}
                            >
                                {value}
                            </i>
                        )}
                    </>
                )
        }
    }

    const deleteSolicitacao = (param: number) => {
        const data = {
            Id: param,
            Status: 0
        }
        patch(UrlMountQuery(data, ENDPOINTS.solicitarViagem), {})
            .then(() => {
                window.location.reload()
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <BlankBoard>
            <Header title="Minhas Viagens"></Header>
            <Center>
                <h4 style={{ color: '#5787D0' }}>
                    {`${qtdViagens.count === 0 ? '' : qtdViagens.count} ${
                        qtdViagens.description
                    }`}
                </h4>
            </Center>
            <Flex justifyContent="flex-end">
                <TitleDesktop>
                    <Button
                        icon="plus"
                        colorType="quaternary"
                        onClick={() =>
                            history.push('/solicitar-viagem/incluir-viajantes')
                        }
                    >
                        Criar Solicitação
                    </Button>
                </TitleDesktop>

                <TitleMobile>
                    <Button
                        icon="plus"
                        colorType="circular"
                        onClick={() =>
                            history.push('/solicitar-viagem/incluir-viajantes')
                        }
                    />
                </TitleMobile>
            </Flex>

            <Modal
                textOk="Deletar"
                textBack="Cancelar"
                setButtonOpen={setButtonOpen}
                title="Deletar Solicitação"
                text="Deseja realmente deletar esta solicitação de viagem?"
                classBtnOk="btn-danger"
            />

            <Form onSubmit={handleSubmit} ref={formRef} style={{ padding: 0 }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '1rem 0'
                    }}
                >
                    <Button
                        style={{ margin: '0 .2rem', padding: '0 15px' }}
                        onlyIcon={true}
                        icon="search"
                        title="Pesquisar"
                        colorType="primary"
                    ></Button>
                    <Button
                        onlyIcon={true}
                        customIcon={CleanIcon}
                        colorType="danger"
                        title="Limpar Filtros"
                        type="button"
                        onClick={() => {
                            formRef.current?.reset()
                        }}
                    ></Button>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-2">
                        <Input
                            placeholder="Digite a ID da solicitacao"
                            label="Id Solicitacão"
                            name="id"
                            maxLength={10}
                        ></Input>
                    </div>
                    <div className="col-12 col-sm-3">
                        <Select
                            placeholder="Selecione"
                            name="idTipoSolicitacao"
                            label="Tipo de Solicitação"
                            options={tiposSolicitacao}
                        ></Select>
                    </div>
                    <div className="col-12 col-sm-3">
                        {' '}
                        <SelectRequester
                            className="scale-in-ver-top"
                            request={{
                                endpoint: ENDPOINTS.situacao,
                                labelToSelect: 'Nome',
                                valueToSelect: 'Id'
                            }}
                            name="idSituacao"
                            label="Situação"
                        ></SelectRequester>
                    </div>
                    <div className="col-6 col-sm-2">
                        <DatePicker
                            name="dataInicial"
                            label="Data Início"
                        ></DatePicker>
                    </div>
                    <div className="col-6 col-sm-2">
                        <DatePicker
                            name="dataFinal"
                            label="Data Final"
                        ></DatePicker>
                    </div>
                </div>
            </Form>
            <Table
                requester={payloadSearch}
                mapper={mapper}
                config={tableConfig}
            ></Table>
        </BlankBoard>
    )
}

const TitleMobile = styled.span`
    display: none;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;

    position: fixed;
    z-index: 1000;
    @media (max-width: 800px) {
        display: block;
        left: 13rem;
        top: 32rem;
    }
`
const TitleDesktop = styled.span`
    @media (max-width: 768px) {
        display: none;
    }
`

import { Form } from '@unform/web'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { TipoDespesa } from '../../../../../contexts/interfaces/tipoDespesa'
import { BlankBoard } from '../../../../../core/components/BlankBoard'
import { Button } from '../../../../../core/components/Button'
import { Center } from '../../../../../core/components/Center'
import { Description } from '../../../../../core/components/Description'
import { Input } from '../../../../../core/components/Form/Input'
import { Select } from '../../../../../core/components/Form/Select'
import { Grid } from '../../../../../core/components/Grid'
import { Header } from '../../../../../core/components/Header/Header'
import { LineSeparator } from '../../../../../core/components/LineSeparator'
import { Subtitle } from '../../../../../core/components/Subtitle'
import { Switch } from '../../../../../core/components/Switch'
import { Alert } from '../../../../../core/components/Alert'
import { UserContext } from '../../../../../contexts/UserContext'
import { post } from '../../../../../services/http'
import { ENDPOINTS } from '../../../../../services/config-http'
import { useAlert } from '../../../../../hooks/AlertToast'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import { MoneyFormat } from '../../../../../core/utils/MoneyFormat'
import { MapFormErrors } from '../../../../../core/utils/MapFormErrors'
import { formataValor } from '../../../../../util/legenda/util'
import { useHistory } from 'react-router'
import { InputCurrency } from '../../../../../core/components/Form/InputCurrency'
import {
    DisableForMobile,
    IsMobile
} from '../../../../Viagem/Solicitacao/stepMobile'

const QuantificacaoOptions = [
    {
        value: '2',
        label: 'Valor'
    },
    {
        value: '1',
        label: 'Quantidade'
    }
]

const CadastroDespesa = () => {
    const FormRef = useRef<FormHandles>(null)
    const [numeroNota, setNumeroNota] = useState(false)
    const [anexo, setAnexo] = useState(false)
    const [justificativa, setJustificativa] = useState(false)
    const { setAlert, showAlert } = useAlert()
    const history = useHistory()
    const [valor, setValor] = useState({
        valorDespesa: 0
    })

    const handleSubmit: SubmitHandler = async (tipoDespesa: TipoDespesa) => {
        try {
            const schema = Yup.object().shape({
                Descricao: Yup.string().required('O Campo valor é obrigatório'),
                Quantificacao: Yup.string().required(
                    'O Campo descrição é obrigatório'
                ),
                ValorPadrao: Yup.string().required(
                    'O Campo descrição é obrigatório'
                )
            })

            await schema.validate(tipoDespesa, { abortEarly: false })
            tipoDespesa.Status = true
            tipoDespesa.NecessitaNumeroNota = numeroNota
            tipoDespesa.NecessitaAnexo = anexo
            tipoDespesa.NecessitaJustificativa = justificativa
            if (tipoDespesa.Quantificacao == '1')
                tipoDespesa.Quantificacao = 'Quantidade'
            else tipoDespesa.Quantificacao = 'Valor'
            showAlert(false)
            if (
                tipoDespesa?.ValorPadrao?.toString() === 'R$ 0.00' ||
                tipoDespesa?.ValorPadrao?.toString() === 'R$ 0,00'
            ) {
                setAlert({
                    type: 'error',
                    time: 5000,
                    title: 'Dados invalidos!',
                    subTitle: `Campo valor padrão é obrigatorio !`
                })
                showAlert(true)
                throw new Error('error')
            }
            post(ENDPOINTS.tipoDespesaIncluir, {
                Status: tipoDespesa?.Status,
                Descricao: tipoDespesa?.Descricao,
                Quantificacao: tipoDespesa?.Quantificacao,
                ValorPadrao: formataValor(tipoDespesa?.ValorPadrao),
                NecessitaNumeroNota: tipoDespesa?.NecessitaNumeroNota,
                NecessitaAnexo: tipoDespesa?.NecessitaAnexo,
                NecessitaJustificativa: tipoDespesa?.NecessitaJustificativa
            })
                .then(_ => {
                    setAlert({
                        type: 'success',
                        time: 5000,
                        title: 'Sucesso!',
                        subTitle: 'Tipo de despesa incluída!'
                    })
                    showAlert(true)
                    history.push(`/cadastros/tipos-despesa`)
                })
                .catch(err => console.error(err))
        } catch (err) {
            console.log(err)
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                FormRef.current?.setErrors(erros)
            }
        }
    }
    const [administrador, setAdministrador] = useState(false)

    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    function _pipeValor(value: number, index: number): JSX.Element {
        return <>{MoneyFormat(value || 0)}</>
    }

    return (
        <div>
            <BlankBoard>
                <Header
                    title="Cadastrar tipo de despesa"
                    actionArrowClick="/cadastros/tipos-despesa"
                ></Header>
                <Center>
                    <Subtitle>Dados da Despesa</Subtitle>
                </Center>
                {administrador ? (
                    <>
                        <Form ref={FormRef} onSubmit={handleSubmit}>
                            <Input
                                name="Descricao"
                                label="Descrição da despesa"
                                required={true}
                                placeholder="Digite uma descrição"
                            ></Input>
                            <Grid
                                sizeElements="49%"
                                spaceBetweenElements="1rem"
                            >
                                <Select
                                    name="Quantificacao"
                                    label="Quantificação"
                                    options={QuantificacaoOptions}
                                    required={true}
                                ></Select>
                                <InputCurrency
                                    onChange={e => {
                                        const valorReplace =
                                            e.currentTarget.value
                                                .replaceAll('.', '')
                                                .replaceAll(',', '.')
                                                .replaceAll('R$', '')
                                        const newValores = {
                                            ...valor
                                        }
                                        newValores.valorDespesa = parseFloat(
                                            valorReplace === ''
                                                ? '0'
                                                : valorReplace
                                        )
                                        setValor(newValores)
                                    }}
                                    width="col-12"
                                    label="Valor Padrão *"
                                    name="ValorPadrao"
                                    mask="999.999,99"
                                    required={true}
                                    defaultValue={MoneyFormat(0)}
                                />
                            </Grid>
                            <LineSeparator></LineSeparator>
                            <Center>
                                <Subtitle>
                                    Informações/Documentos Requeridos
                                </Subtitle>
                            </Center>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="col12 col-sm-11">
                                    <Description>Número da nota</Description>
                                </div>
                                <div className="col12 col-sm-1">
                                    <Switch
                                        name="NecessitaNumeroNota"
                                        checked={numeroNota}
                                        onChange={e => {
                                            setNumeroNota(!numeroNota)
                                        }}
                                    ></Switch>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="col12 col-sm-11">
                                    <Description>Anexo</Description>
                                </div>
                                <div className="col12 col-sm-1">
                                    <Switch
                                        name="anexo"
                                        checked={anexo}
                                        onChange={e => {
                                            setAnexo(!anexo)
                                        }}
                                    ></Switch>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="col12 col-sm-11">
                                    <Description>Justificativa</Description>
                                </div>
                                <div className="col12 col-sm-1">
                                    <Switch
                                        name="justificativa"
                                        checked={justificativa}
                                        onChange={e => {
                                            setJustificativa(!justificativa)
                                        }}
                                    ></Switch>
                                </div>
                            </div>

                            <Center>
                                <DisableForMobile>
                                    <Button
                                        icon="plus"
                                        colorType="quaternary"
                                        style={{ marginTop: '100px' }}
                                    >
                                        Cadastrar
                                    </Button>
                                </DisableForMobile>
                                <IsMobile>
                                    <Button
                                        icon="plus"
                                        colorType="btnMobile"
                                        style={{ marginTop: '100px' }}
                                    >
                                        Cadastrar
                                    </Button>
                                </IsMobile>
                            </Center>
                        </Form>
                        <LineSeparator></LineSeparator>
                    </>
                ) : (
                    <Alert
                        fixed={true}
                        icon="exclamation-circle"
                        type="primary"
                        message="Você não tem permissão de administrador"
                    ></Alert>
                )}
            </BlankBoard>
        </div>
    )
}

export default CadastroDespesa

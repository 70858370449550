import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../../core/components/Button'
import { LineSeparator } from '../../../core/components/LineSeparator'

interface ValidacaoProp {
    setShow: any
    handleConfirm: any
    buttonConfirm?: {
        text: string
        color: string
    }
    handleCancel?: any
    title?: {
        text: string
        color: string
    }
    text?: string
    height?: string
}

export const ModalValidacao = ({
    setShow,
    handleCancel,
    handleConfirm,
    height,
    text,
    title,
    buttonConfirm
}: ValidacaoProp) => {
    return (
        <ModalBack>
            <ModalContent style={{ height: height }}>
                <ModalHeader>
                    <div style={{ width: '5%' }}></div>
                    <h1
                        style={{
                            fontWeight: 'bold',
                            color: title?.color,
                            width: '90%',
                            textAlign: 'center'
                        }}
                    >
                        {title?.text}
                    </h1>
                    <CloseModal
                        onClick={e => setShow(false)}
                        style={{
                            width: '5%',
                            textAlign: 'center'
                        }}
                    >
                        <FontAwesomeIcon icon="times" />
                    </CloseModal>
                </ModalHeader>
                <div className="modalcotacaobody">
                    <LineSeparator style={{ margin: '0 2rem' }}></LineSeparator>
                    <span
                        style={{
                            fontSize: '1rem',
                            textAlign: 'center',
                            color: '#002A5D'
                        }}
                    >
                        {text}
                    </span>
                    <LineSeparator
                        style={{ margin: '6rem 2rem 1rem 2rem' }}
                    ></LineSeparator>
                    <div className="row">
                        <div
                            className="col-12 col-md-6"
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Button
                                icon=""
                                colorType="oitotiary"
                                onClick={handleCancel}
                            >
                                Cancelar
                            </Button>
                        </div>
                        <div
                            className="col-12 col-md-6"
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Button
                                icon=""
                                colorType="quaternary"
                                onClick={() => {
                                    setShow(false)
                                    handleConfirm()
                                }}
                            >
                                {buttonConfirm?.text}
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalContent>
        </ModalBack>
    )
}

const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    height: 4rem;
    padding: 1rem 0;
`

const ModalBack = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(82, 82, 82, 0.59);
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10000000000;
`
const ModalContent = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 0.5rem;
    margin: 5rem auto;
    width: 35rem;
    background-color: white;
    border-radius: 0.5rem;
`
const CloseModal = styled.button`
    border: none;
    position: relative;
    font-size: 1.5rem;
    background-color: white;
    font-weight: bold;
    color: #ff8686;
`

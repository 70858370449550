import { Form } from '@unform/web'
import React, { useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { FormHandles, SubmitHandler } from '@unform/core'
import { BlankBoard } from '../../../core/components/BlankBoard'
import { Button } from '../../../core/components/Button/index'
import { Center } from '../../../core/components/Center'
import { Header } from '../../../core/components/Header/Header'
import { Subtitle } from '../../../core/components/Subtitle'
import { MapFormErrors } from '../../../core/utils/MapFormErrors'
import { Input } from '../../../core/components/Form/Input'
import { Space } from '../../../core/components/Space'
import { put, get, post } from '../../../services/http'
import { ENDPOINTS } from '../../../services/config-http'
import { useParams } from 'react-router-dom'
import { Regiao } from '../../../contexts/interfaces/regiao'
import { UrlMountQuery } from '../../../core/utils/UrlMountQuery'
import { Alert } from '../../../core/components/Alert'
import { Disclaimer } from '../../../core/components/Disclaimer'
import { Switch } from '../../../core/components/Switch'
import { UserContext } from '../../../contexts/UserContext'
import { DisableForMobile, IsMobile } from '../../Viagem/Solicitacao/stepMobile'

export const RegiaoForm = () => {
    const FormRef = useRef<FormHandles>(null)
    const { Id } = useParams() as any
    const regiao: Regiao = {}
    const [Regiao, setRegiao] = useState({ status: false })

    const [showAlert, setShowAlert] = useState({
        status: false,
        message: '',
        type: 'danger'
    })

    const [administrador, setAdministrador] = useState(false)
    const dadosUsuario = useContext(UserContext)
    useEffect(() => {
        setAdministrador(dadosUsuario.Administrador === true)
    }, [dadosUsuario])

    useEffect(() => {
        if (Id !== undefined) {
            get(UrlMountQuery({ Id }, ENDPOINTS.regiaoPesquisarId))
                .then(res => {
                    const { Nome, Codigo, Descricao, Status } = res.data.Model
                    regiao.Id = Id
                    regiao.Nome = Nome
                    regiao.Codigo = Codigo
                    regiao.Descricao = Descricao
                    regiao.Status = Status
                    FormRef.current?.setData({
                        Nome: regiao.Nome,
                        Codigo: regiao.Codigo,
                        Descricao: regiao.Descricao,
                        Status: regiao.Status
                    })
                    setRegiao({ status: Status })
                })
                .catch(err => {
                    setShowAlert({
                        status: true,
                        message: err.data,
                        type: 'danger'
                    })
                })
        }
    }, [])

    const handleSubmit: SubmitHandler<Regiao> = async (
        dadosForm,
        { reset }
    ) => {
        try {
            const schema = Yup.object().shape({
                Nome: Yup.string().required('O Campo nome é obrigatório'),
                Codigo: Yup.string().required('O Campo código é obrigatório'),
                Descricao: Yup.string().required(
                    'O Campo Status é obrigatório'
                ),
                Status: Yup.boolean().required('O Campo Status é obrigatório')
            })

            await schema.validate(dadosForm, { abortEarly: false })

            FormRef.current?.setErrors({})
            if (Id !== undefined) {
                const { Nome, Codigo, Descricao, Status } = dadosForm
                regiao.Id = Id
                regiao.Nome = Nome
                regiao.Codigo = Codigo
                regiao.Descricao = Descricao
                regiao.Status = Status
                put(ENDPOINTS.regiao, regiao)
                    .then(res => {
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
            } else {
                post(ENDPOINTS.regiao, dadosForm)
                    .then(res => {
                        setShowAlert({
                            status: true,
                            message: res.data,
                            type: 'success'
                        })
                        reset()
                    })
                    .catch(err => {
                        setShowAlert({
                            status: true,
                            message: err.data.Text,
                            type: 'danger'
                        })
                    })
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)
                FormRef.current?.setErrors(erros)
            }
        }
    }
    return (
        <BlankBoard>
            <Alert
                message={showAlert.message}
                status={showAlert.status}
                type={showAlert.type as any}
            ></Alert>
            <Header
                title={
                    Id !== undefined
                        ? 'Alteração de Regiões'
                        : 'Cadastro de Regiões'
                }
                actionArrowClick="/cadastros/regiao"
            ></Header>
            <Center>
                <Subtitle>Dados da Região</Subtitle>
            </Center>
            {administrador ? (
                <Form ref={FormRef} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col12 col-sm-8">
                            <Input
                                name="Nome"
                                label="Nome"
                                required={true}
                                placeholder="Digite o nome"
                            ></Input>
                        </div>
                        <div className="col12 col-sm-4">
                            <Input
                                name="Codigo"
                                label="Código"
                                required={true}
                                placeholder="Digite o código"
                            ></Input>
                        </div>
                        <div className="col-12 col-sm-12">
                            <Input
                                name="Descricao"
                                label="Descrição"
                                required={true}
                                placeholder="Digite a descrição"
                            ></Input>
                        </div>
                        <div className="col-12">
                            <Switch
                                required={true}
                                onChange={() => {
                                    if (Regiao.status) {
                                        setRegiao({ status: false })
                                    } else {
                                        setRegiao({ status: true })
                                    }
                                }}
                                name="Status"
                                label="Status"
                            ></Switch>
                        </div>
                    </div>
                    <Space height="3rem"></Space>
                    <Center>
                        <DisableForMobile>
                            <Button
                                colorType="quaternary"
                                icon={Id !== undefined ? 'save' : 'plus'}
                            >
                                {Id !== undefined ? 'Salvar' : 'Incluir'}
                            </Button>
                        </DisableForMobile>
                        <IsMobile>
                            <Button
                                colorType="btnMobile"
                                icon={Id !== undefined ? 'save' : 'plus'}
                            >
                                {Id !== undefined ? 'Salvar' : 'Incluir'}
                            </Button>
                        </IsMobile>
                    </Center>
                </Form>
            ) : (
                <Alert
                    fixed={true}
                    icon="exclamation-circle"
                    type="primary"
                    message="Você não tem permissão de administrador"
                ></Alert>
            )}
        </BlankBoard>
    )
}

import { GlobalStateInterface } from '../../contexts/interfaces/global'
import { StepByStepItem } from '../../contexts/interfaces/_stepByStep'
import { STEP_BY_STEP } from './Actions'

/**
 * Reducer para manipulação do estado na sessão stepByStep
 * @param state estado global
 * @param data dados para manipulação do estado
 * @param action ação para definir o que será feito no estado
 * @returns estado global atualizado
 */
export const StepByStepReducer = (
    state: Partial<GlobalStateInterface>,
    data: Array<StepByStepItem>,
    action: string
): Partial<GlobalStateInterface> => {
    const stepByStep = [...(state.stepByStep || [])]
    switch (action) {
        case STEP_BY_STEP.INIT_STEP_BY_STEP:
            return { ...state, stepByStep: data }

        case STEP_BY_STEP.COMPLETE_ROTEIRO: {
            const newStepByStep = stepByStep.map(item =>
                item.name === 'Roteiro'
                    ? { ...item, completed: false, active: false }
                    : item
            )

            return { ...state, stepByStep: newStepByStep }
        }
        case STEP_BY_STEP.ACTIVE_ADIANTAMENTO: {
            const newStepByStep = stepByStep.map(item =>
                item.name === 'Adiantamento' ? { ...item, active: true } : item
            )
            return { ...state, stepByStep: newStepByStep }
        }
        case STEP_BY_STEP.COMPLETE_ADIANTAMENTO: {
            const newStepByStep = stepByStep.map(item =>
                item.name === 'Adiantamento'
                    ? { ...item, completed: false, active: false }
                    : item
            )
            return { ...state, stepByStep: newStepByStep }
        }

        default:
            return state
    }
}

const resetActive = (stepByStep: Array<StepByStepItem>) => {
    return stepByStep.map(item => ({ ...item, active: false }))
}

import { Form } from '@unform/web'
import React, { useRef, useEffect } from 'react'
import * as Yup from 'yup'
import { FormHandles, SubmitHandler } from '@unform/core'
import { MapFormErrors } from '../../../../core/utils/MapFormErrors'
import { BlankBoard } from '../../../../core/components/BlankBoard'
import { Space } from '../../../../core/components/Space'
import { Center } from '../../../../core/components/Center'
import { Button } from '../../../../core/components/Button'
import { Header } from '../../../../core/components/Header/Header'
import { DatePicker } from '../../../../core/components/DatePicker'
import { useHistory } from 'react-router-dom'
import { Subtitle } from '../../../../core/components/Subtitle'
import { useGlobalStates } from '../../../../state/InitialState'
import { StepByStep } from '../../../../core/components/StepByStep'
import { STEP_BY_STEP } from '../../../../state/StepByStep/Actions'
import { SOLICITACAO_VIAGEM } from '../../../../state/SolicitacaoViagem/Actions'
import { Input } from '../../../../core/components/Form/Input'
import { StepByStepItem } from '../../../../contexts/interfaces/_stepByStep'
import { Solicitacao } from '../../../../contexts/interfaces/solicitacao'

export const IdaVoltaForm = () => {
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()
    const {
        state: { solicitacao, stepByStep },
        setGlobalState
    } = useGlobalStates()

    useEffect(() => {
        if (solicitacao === undefined) {
            const sol = localStorage.getItem('solicitacao')
            if (sol !== null) {
                const solicitacaoStorage = JSON.parse(sol) as Solicitacao
                setGlobalState(
                    SOLICITACAO_VIAGEM.REDUCER_NAME,
                    SOLICITACAO_VIAGEM.ACTION_ADD_SOLICITACAO,
                    solicitacaoStorage
                )
                const trechos = solicitacaoStorage?.destinos || []
                if (solicitacaoStorage?.destinos?.length)
                    formRef.current?.setData(parseDate(trechos[0]))
            }
        } else {
            const trechos = solicitacao?.destinos || []
            if (solicitacao?.destinos?.length)
                formRef.current?.setData(parseDate(trechos[0]))
        }
    }, [])

    const formataData = (data: string) => {
        const dataArray = data.split('/')
        return `${dataArray[1]}/${dataArray[0]}/${dataArray[2]}`
    }

    const parseDate = (data: any) => ({
        ...data,
        dataInicio: new Date(formataData(data.dataInicio)),
        dataTermino: new Date(formataData(data.dataTermino))
    })

    const handleSubmit: SubmitHandler = async (data, { reset }) => {
        try {
            const schema = Yup.object().shape({
                destino: Yup.string().required('O Campo destino é obrigatório'),
                dataInicio: Yup.string().required(
                    'O Campo data de início é obrigatório'
                ),
                dataTermino: Yup.string().required(
                    'O Campo data de término é obrigatório'
                )
            })
            let { origem, destino, dataInicio, dataTermino } = data
            dataInicio =
                dataInicio === null ? '' : dataInicio?.toLocaleDateString()
            dataTermino =
                dataTermino === null ? '' : dataTermino?.toLocaleDateString()
            await schema.validate(
                { origem, destino, dataInicio, dataTermino },
                { abortEarly: false }
            )

            formRef.current?.setErrors({})
            reset()

            const destinos = [
                {
                    origem,
                    destino,
                    dataInicio,
                    dataTermino,
                    multiplosDestinos: false
                }
            ]
            setGlobalState(
                SOLICITACAO_VIAGEM.REDUCER_NAME,
                SOLICITACAO_VIAGEM.ACTION_ADD_ROTEIRO,
                destinos
            )
            setGlobalState(
                STEP_BY_STEP.REDUCER_NAME,
                STEP_BY_STEP.COMPLETE_ROTEIRO,
                true
            )

            setGlobalState(
                STEP_BY_STEP.REDUCER_NAME,
                STEP_BY_STEP.ACTIVE_ADIANTAMENTO,
                true
            )
            const sol = localStorage.getItem('solicitacao')
            if (sol !== null) {
                const solicitacaoObj = JSON.parse(sol) as Solicitacao
                solicitacaoObj.destinos = destinos
                localStorage.setItem(
                    'solicitacao',
                    JSON.stringify(solicitacaoObj)
                )
            } else {
                localStorage.setItem('solicitacao', JSON.stringify(solicitacao))
            }
            history.push('/solicitar-viagem/adiantamento')
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = MapFormErrors(err.inner)

                formRef.current?.setErrors(erros)
            }
        }
    }

    return (
        <BlankBoard>
            <Header
                title="Solicitação Viagem"
                actionArrowClick="/solicitar-viagem/tipo-roteiro"
            ></Header>
            <StepByStep items={mountStepItems()}></StepByStep>
            <Subtitle
                style={{
                    textAlign: 'center',
                    margin: '1rem 0'
                }}
            >
                Agora, vamos definir o <b>roteiro</b> da sua viagem.
            </Subtitle>
            <Form onSubmit={handleSubmit} ref={formRef}>
                <div className="row">
                    <div className="col-12">
                        <Input
                            name="destino"
                            label="Para onde?"
                            required={true}
                        ></Input>
                    </div>

                    <div className="col-12 col-sm-6">
                        <DatePicker
                            name="dataInicio"
                            label="Quando você vai?"
                            minDate={new Date()}
                            required={true}
                        ></DatePicker>
                    </div>
                    <div className="col-12 col-sm-6">
                        <DatePicker
                            name="dataTermino"
                            label="Quando você retorna?"
                            minDate={new Date()}
                            required={true}
                        ></DatePicker>
                    </div>
                </div>
                <Space height="4em"></Space>
                <Center>
                    <Button colorType="primary">Próximo</Button>
                </Center>
            </Form>
        </BlankBoard>
    )
}

const mountStepItems = (): StepByStepItem[] => [
    {
        name: 'Roteiro',
        active: true,
        completed: false,
        icon: 'map-marker-alt'
    },
    {
        name: 'Adiantamento',
        active: false,
        completed: false,
        icon: 'dollar-sign'
    },
    {
        name: 'Aéreo',
        active: false,
        completed: false,
        icon: 'plane'
    },
    {
        name: 'Hospedagem',
        active: false,
        completed: false,
        icon: 'building'
    },
    {
        name: 'Serviços',
        active: false,
        completed: false,
        icon: 'list-ul'
    },
    {
        name: 'Rateio',
        active: false,
        completed: false,
        icon: 'random'
    }
]

import { GlobalStateInterface } from '../../contexts/interfaces/global'
import { SOLICITACAO_VIAGEM } from './Actions'

/**
 * Reducer para manipulação do estado na sessão solicitacao
 * @param state estado global
 * @param data dados para manipulação do estado
 * @param action ação para definir o que será feito no estado
 * @returns estado global atualizado
 */
export const SolicitacaoViagemReducer = (
    state: Partial<GlobalStateInterface>,
    data: any,
    action: string
): Partial<GlobalStateInterface> => {
    const solicitacao = state.solicitacao || {}
    // const stepByStepItem = state.stepByStep || {}
    switch (action) {
        case SOLICITACAO_VIAGEM.ACTION_ADD_SOLICITACAO:
            solicitacao.idTipoSolicitacao = data.idTipoSolicitacao
            solicitacao.idMotivoViagem = data.idMotivoViagem
            solicitacao.descricao = data.descricao
            solicitacao.viajantes = data.viajantes
            solicitacao.destinos = data.destinos
            solicitacao.adiantamentos = data.adiantamentos
            solicitacao.hospedagem = data.hospedagem
            solicitacao.servico = data.servico
            solicitacao.rateio = data.rateio
            solicitacao.idUsuarioSolicitante = data.idUsuarioSolicitante
            return { ...state, solicitacao: solicitacao }
        case SOLICITACAO_VIAGEM.ACTION_ADD_INFO_VIAGEM:
            solicitacao.idTipoSolicitacao = data.idTipoSolicitacao
            solicitacao.idMotivoViagem = data.idMotivoViagem
            solicitacao.descricao = data.descricao
            return { ...state, solicitacao: solicitacao }
        case SOLICITACAO_VIAGEM.ACTION_ADD_NOVA_ETAPA:
            solicitacao.idViagemComEtapas = data
            return { ...state, solicitacao: solicitacao }
        case SOLICITACAO_VIAGEM.ACTION_ADD_VIAJANTES:
            solicitacao.viajantes = data
            return { ...state, solicitacao: solicitacao }
        case SOLICITACAO_VIAGEM.ACTION_ADD_VIAJANTE_PRINCIPAL:
            solicitacao.idUsuarioSolicitante = data
            return { ...state, solicitacao: solicitacao }
        case SOLICITACAO_VIAGEM.ACTION_ADD_ROTEIRO:
            solicitacao.destinos = data
            return { ...state, solicitacao: solicitacao }
        case SOLICITACAO_VIAGEM.ACTION_ADD_ADIANTAMENTO:
            solicitacao.adiantamentos = data
            return { ...state, solicitacao: solicitacao }
        case SOLICITACAO_VIAGEM.ACTION_ADD_AEREO:
            solicitacao.aereo = data
            return { ...state, solicitacao: solicitacao }
        case SOLICITACAO_VIAGEM.ACTION_ADD_HOSPEDAGEM:
            solicitacao.hospedagem = data
            return { ...state, solicitacao: solicitacao }
        case SOLICITACAO_VIAGEM.ACTION_ADD_SERVICO:
            solicitacao.servico = data
            return { ...state, solicitacao: solicitacao }
        case SOLICITACAO_VIAGEM.ACTION_ADD_RATEIO:
            solicitacao.rateio = data
            return { ...state, solicitacao: solicitacao }
        // case SOLICITACAO_VIAGEM.ACTION_ADD_STEP_BY_STEP_ITEM:
        //     stepByStepItem = data
        //     return { ...state, stepByStep: stepByStepItem }

        default:
            return state
    }
}

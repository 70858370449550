import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import './toast.css'

interface ToastProps1 {
    standard?: boolean
    message: string
    setShow: any
    type: string
}

interface ToastProps {
    texto: string
    setToastShow: any
}

export const Toast = ({
    message,
    setShow,
    type,
    standard = true
}: ToastProps1) => {
    const tempo = standard ? 2500 : 7000
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, tempo)
    }, [])

    return (
        <>
            <div className="toast-area">
                <div>
                    <div
                        style={(standard && containerToast) || containerToast2}
                    >
                        <div
                            style={ladoToast}
                            className={
                                type === 'success'
                                    ? 'background-success'
                                    : 'background-error'
                            }
                        ></div>
                        <div style={iconArea}>
                            <FontAwesomeIcon
                                style={icon}
                                icon={
                                    type === 'success'
                                        ? 'check-circle'
                                        : 'times-circle'
                                }
                                className={
                                    type === 'success'
                                        ? 'color-success'
                                        : 'color-error'
                                }
                            />
                        </div>
                        <div className="title-and-label">
                            <p style={title}>
                                {type === 'success' ? 'Sucesso!' : 'Falha'}
                            </p>
                            <p style={subText}>{message}</p>
                        </div>
                        <button
                            className="close-toast"
                            onClick={() => setShow(false)}
                        >
                            <FontAwesomeIcon
                                id="close-toast-btn"
                                icon={faTimes}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export const ToastError = ({ texto, setToastShow }: ToastProps) => {
    useEffect(() => {
        setTimeout(() => {
            setToastShow(false)
        }, 3000)
    }, [])
    return (
        <div>
            <div style={containerToast}>
                <div style={ladoToast}></div>
                <div style={leftArea}>
                    <FontAwesomeIcon style={icon} icon="times-circle" />
                </div>
                <div style={rightArea}>
                    <p style={title}>Erro!</p>
                    <p style={subText}>{texto}</p>
                </div>
            </div>
        </div>
    )
}

export const containerToast = {
    display: 'flex',
    backgroundColor: '#fff',
    width: '22rem',
    height: '5rem',
    margin: '0',
    padding: '0',
    borderRadius: '9px',
    boxShadow: '2px 0px 10px 0px rgba(0,0,0,0.20)'
}

export const containerToast2 = {
    display: 'flex',
    backgroundColor: '#fff',
    width: '22rem',
    height: '0 auto',
    margin: '0',
    padding: '2rem',
    borderRadius: '9px',
    boxShadow: '2px 0px 10px 0px rgba(0,0,0,0.20)'
}

export const iconArea = {
    display: 'flex',
    alignItems: 'center',
    padding: '0 1.5rem'
}

export const title = {
    width: '100%',
    height: '40%',
    color: '#6C7480',
    fontSize: '1rem',
    margin: '4% 0 1% 0',
    padding: '0 0 0 2%',
    display: 'flex',
    alignItems: 'flex-end'
}

export const subText = {
    width: '100%',
    height: '60%',
    color: '#b6b6b6',
    fontSize: '0.8rem',
    margin: '-1% 0 0 0',
    padding: '0 3% 2% 2%',
    lineHeight: '110%'
}

export const ladoToast = {
    width: '3%',
    height: '100%',
    borderBottomLeftRadius: '7px',
    borderTopLeftRadius: '7px'
}

const rightArea = {
    width: '75%',
    height: 'auto',
    margin: '0',
    padding: '0',
    display: 'inline-block'
}

const leftArea = {
    width: '22%',
    height: '100%',
    margin: '0',
    padding: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

export const icon = {
    display: 'flex',
    fontSize: '2rem'
}
